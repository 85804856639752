<div class="content-transferir" *ngIf="!finalizado && !loading">
  <!-- Sem chaves -->
  <div class="no-content" id="sem-chaves" *ngIf="!cadastro">
    <div class="arrow" (click)="goBack()" style="cursor: pointer;">
      <img class="arrow-img" src="/assets/svg/arrow-voltar-icon.svg" alt="arrow-voltar"> <span class="voltar">
        Voltar</span>
    </div>
    <div class="title">
      <span class="span-title"> Minhas chaves Pix </span>
      <span class="span-subtitle"> Qual chave você quer cadastrar? </span>
    </div>
    <div class="accounts-div">

      <div class="account" (click)="!limitKeys() ? handleLimitKey() : chooseKey('celular')" style="cursor: pointer;">
        <div class="account-img">
          <img src="/assets/svg/smartphone-icon.svg" alt="smartphone">
        </div>
        <div class="font">
          <span class="account-font"> Número de celular</span>
        </div>
      </div>
      <div class="account" (click)="!limitKeys() ? handleLimitKey() : chooseKey('documento')" style="cursor: pointer;">
        <div class="account-img">
          <img src="/assets/svg/document-icon.svg" alt="document">
        </div>
        <div class="font">
          <span class="account-font"> <span *ngIf="getAccountInformationSession[0].tipo_pessoa === 'PF'">CPF</span><span
              *ngIf="getAccountInformationSession[0].tipo_pessoa === 'PJ'">CNPJ</span></span>
        </div>
      </div>
      <div class="account" (click)="!limitKeys() ? handleLimitKey() : chooseKey('email')" style="cursor: pointer;">
        <div class="account-img">
          <img src="/assets/svg/email-icon.svg" alt="email">
        </div>
        <div class="font">
          <span class="account-font"> Endereço de e-mail</span>
        </div>
      </div>
      <div class="account" (click)="!limitKeys() ? handleLimitKey() : chooseKey('aleatorio')" style="cursor: pointer;">
        <div class="account-img">
          <img src="/assets/svg/aleatorio-icon.svg" alt="aleatorio">
        </div>
        <div class="font">
          <span class="account-font"> Chave Aleatória</span>
        </div>
      </div>
    </div>

  </div>

  <div class="content-smartphone" id="comchaves" *ngIf="cadastro">
    <div class="arrow" (click)="goBackToKeys()" style="cursor: pointer;">
      <img class="arrow-img" src="/assets/svg/arrow-voltar-icon.svg" alt="arrow-voltar"> <span class="voltar">
        Voltar</span>
    </div>
    <div class="form-cadastro" id="celular" *ngIf="cadastroCelular">
      <span class="form-title"> Informe um número de celular</span>
      <input type="text" maxlength="15" placeholder="(00) 00000-0000" mask = '(00) 00000-0000' [(ngModel)]="numCelular"  class="line-code-bar-blue" type="tel" [formControl]="phone" (keyup)="mphone()"
        name="numeroCel">
      <div class="timeout-div" *ngIf="isKeyCreated">
        <img src="/assets/svg/icon-info-red.svg" alt="info">
        <span class="timeout-warning"> Esta chave já está associada a esta conta.
        </span>
      </div>
      <button type="button" class="button-continue" [disabled]="!enableCel" (click)="sendToken('phone')">
        Cadastrar chave Pix
      </button>
    </div>
    <div class="form-cadastro" id="email" *ngIf="cadastroEmail">
      <span class="form-title"> Informe um endereço de email</span>
      <input  type="text"  placeholder="nome@site.com.br" class="line-code-bar-blue" name="email" (keyup)="validacaoEmail()" [(ngModel)]="email" ngModel maxlength="77">
      <div class="timeout-div" *ngIf="isKeyCreated">
        <img src="/assets/svg/icon-info-red.svg" alt="info">
        <span class="timeout-warning"> Esta chave já está associada a esta conta.
        </span>
      </div>
      <button type="button" class="button-continue" (click)="sendToken('email');" [disabled]="!validEmail">
        Cadastrar chave Pix
      </button>
    </div>
    <div class="form-cadastro" id="documento" *ngIf="cadastroDocumento">
      <span class="form-title"> Cadastrar chave em seu <span *ngIf="getAccountInformationSession[0].tipo_pessoa === 'PF'">CPF</span><span
          *ngIf="getAccountInformationSession[0].tipo_pessoa === 'PJ'">CNPJ</span></span>
      <div class="doc-div">
        <img src="/assets/svg/document-icon.svg" style="margin-right: 18px;">
        <span class="doc-font">{{getAccountInformationSession[0].cpfCnpj | cpfcnpj}}</span>
      </div>
      <div class="timeout-div" *ngIf="isKeyCreated">
        <img src="/assets/svg/icon-info-red.svg" alt="info">
        <span class="timeout-warning"> Esta chave já está associada a esta conta.
        </span>
      </div>
      <!-- <div class="doc-div" *ngIf="!isMyDocuments">
        <input type="text" class="line-code-bar-blue" (keyup)="mascaraCpfCnpj(document)" name="document" [(ngModel)]="document">
      </div> -->

      <button type="button" class="button-continue" (click)="cadastrarChave();">
        Cadastrar chave Pix
      </button>
    </div>
    <div class="form-cadastro" id="aleatorio" *ngIf="cadastroAleatorio" style="width: 427px;">
      <span class="form-title"> Cadastrar com chave aleatória</span>
      <span class="info-aleatorio"> A chave aleatória é automaticamente gerada com uma sequência de números e letras, mas
        sem precisar passar dados pessoais.</span>
      <button type="button" class="button-continue" (click)="cadastrarChave()">
        Cadastrar chave Pix
      </button>
    </div>
    <div class="avisos-div">
      <span class="font"> Importante! </span>
      <span class="font-dois"> Ao receber uma transferência, os dados abaixo ficam visíveis para o pagador:</span>
      <div class="avisos-marcadores">
        <div class="marcador">
          <img src="/assets/svg/circle-icon.svg" style="margin-right: 4px;"> <span class="font"> Seu nome completo
          </span>
        </div>
        <div class="marcador">
          <img src="/assets/svg/circle-icon.svg" style="margin-right: 4px;"> <span class="font"> Os primeiros três e os últimos
            dois números do seu CNPJ</span>
        </div>
        <div class="marcador">
          <img src="/assets/svg/circle-icon.svg" style="margin-right: 4px;"> <span class="font"> Nome da conta em que a chave
            está cadastrada (Banco Fibra, por exemplo)</span>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="content-transferir" *ngIf="loading">
  <div class="d-flex justify-content-center position loading-container">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<div class="content-transferir" *ngIf="finalizado && !loading && !isPortability">
  <div class="warnings">
    <div class="warning-div">
      <fibra-generic-info type="{{warnType}}" title="{{warnTitle}}" message="{{warnSubtitle}}">
      </fibra-generic-info>

      <div class="buttons">
        <button type="button" class="button-white" (click)="btnSeletedCArd(btnFunction1)">
          {{button1}}
        </button>
        <button type="button" class="button-continue" (click)="btnSeletedCArd(btnFunction2)" *ngIf="!isScheduling && !hideButton2">
          {{button2}}
        </button>
      </div>
    </div>

  </div>
</div>
<div class="content-handle-portability" *ngIf="isPortability && !loading">
  <div class="container-fluid">
    <div class="row d-flex justify-content-center body mt-4">
        <div class="col-12 d-flex justify-content-center my-5">
            <img class="image" src="/assets/svg/information.svg" alt="pix-image">
        </div>
        <div class="col-12 text-center body-text">
            <p class="information">Chave vinculada à conta {{isNumConta}}</p>
            <p class="my-4 information">Para utilizar a mesma chave, esta deverá ser excluída da outra conta. </p>
            <p class="information">Para dúvidas, envie um e-mail para <a class="details">relacionamento@bancofibra.com.br</a> <br>ou ligue para
                (11) 4130-7449 das 9h às 18h, de segunda a sexta-feira, <br> exceto feriados.</p>
        </div>
    </div>
  </div>
</div>

<div class="message-transaction" id="message">
  <img src="/assets/svg/warning.svg" />
  <div class="text">
    <h4 class="title">{{msgError}}</h4>
    <div class="msg" *ngIf="semPermissaoMsg">{{subTextError}}</div>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="closeError()">
    <img src="/assets/svg/icon_close_white.svg" />
  </button>
</div>

<div class="modal">
  <ng-template #contentSms let-modal>
    <div class="conteudo-modal">
      <div class="validacao-div">
        <span class="validacao-title"> Validação por SMS</span>
        <span class="validacao-text"> Insira o código que você recebeu por SMS no número {{numCelular}}</span>
        <div class="input-div">
          <input type="text" maxlength="6" placeholder="000000" [(ngModel)]="token" [textMask]="{
                mask: tokenMask,
                placeholderChar: '_',
                guide: false,
                modelClean: true
              }" [ngClass]="timeoutToken === true ? 'line-code-bar-red' : 'line-code-bar-blue'" name="tokenCel">
          <span [ngClass]="timeoutToken === true ? 'timer-red' : 'timer-blue'" style="display: flex;"> <span><img *ngIf="!timeoutToken"
                src="/assets/svg/time-elipse.png" style="margin-right: 5px; width: 16px;"> <img *ngIf="timeoutToken" src="/assets/svg/elipse-danger.svg"
                style="margin-right: 5px; width: 16px;"></span>
            {{minutes}}:{{counter >= 10 ? counter : "0"+counter}}</span>

        </div>
        <div class="timeout-div" *ngIf="timeoutToken">
          <img src="/assets/svg/icon-info-red.svg" alt="info">
          <span class="timeout-warning"> Tempo expirado. Toque no botão para receber <strong> novo código </strong>
          </span>
        </div>
        <div class="timeout-div" *ngIf="isCodInvalid">
          <img src="/assets/svg/icon-info-red.svg" alt="info">
          <span class="timeout-warning"> Código inválido. Digite o código novamente.
          </span>
        </div>
      </div>
      <div class="buttons" *ngIf="!timeoutToken">
        <button type="button" class="button-secondary" (click)="closeModal()">
          Cancelar
        </button>
        <button type="button" class="button-primary" (click)=cadastrarChave(); [disabled]="token.length  < 6"> Confirmar
        </button>
      </div>
      <div class="buttons">
        <button type="button" class="button-primary" (click)="sendToken('phone')" *ngIf="timeoutToken" style="margin-top: 0px !important;">
          Receber novo código </button>
      </div>
    </div>

  </ng-template>
</div>

<div class="modal">
  <ng-template #contentEmail let-modal>
    <div class="conteudo-modal">
      <div class="validacao-div">
        <span class="validacao-title"> Validação por Email</span>
        <span class="validacao-text"> Insira o código que você recebeu por Email no email: <strong>
            {{email}}</strong></span>
        <div class="input-div">
          <input type="text" maxlength="6" placeholder="000000" [textMask]="{
                mask: tokenMask,
                placeholderChar: '_',
                guide: false,
                modelClean: true
              }" [(ngModel)]="token" [ngClass]="timeoutToken === true ? 'line-code-bar-red' : 'line-code-bar-blue'" name="tokenCel">
          <span [ngClass]="timeoutToken === true ? 'timer-red' : 'timer-blue'" style="display: flex;"> <span><img *ngIf="!timeoutToken"
                src="/assets/svg/time-elipse.png" style="margin-right: 5px; width: 16px;"> <img *ngIf="timeoutToken" src="/assets/svg/elipse-danger.svg"
                style="margin-right: 5px; width: 16px;"></span>
            {{minutes}}:{{counter >= 10 ? counter : "0"+counter}}</span>
        </div>

      </div>
      <div class="timeout-div" *ngIf="timeoutToken">
        <img src="/assets/svg/icon-info-red.svg" alt="info">
        <span class="timeout-warning"> Tempo expirado. Toque no botão para receber <strong> novo código </strong>
        </span>
      </div>
      <div class="timeout-div" *ngIf="isCodInvalid">
        <img src="/assets/svg/icon-info-red.svg" alt="info">
        <span class="timeout-warning"> Código inválido. Digite o código novamente.
        </span>
      </div>

      <div class="buttons" *ngIf="!timeoutToken">
        <button type="button" class="button-secondary" (click)="closeModal()">
          Cancelar
        </button>
        <button type="button" class="button-primary" (click)=cadastrarChave(); [disabled]="token.length  < 6"> Confirmar
        </button>
      </div>
      <div class="buttons">
        <button type="button" class="button-primary" (click)="sendToken('email')" *ngIf="timeoutToken" style="margin-top: 0px !important;">
          Receber novo código </button>
      </div>
    </div>

  </ng-template>
</div>
<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
<fibra-modal-validation #content (responseValidation)="responseApiModal($event)" *ngIf="showModalValidation" [apiCallback]="apiCallback"
  [apiPayload]="payload" (closeEvent)="closeModalApi($event)">
</fibra-modal-validation>