<div class="main-selector" >
  <fibra-company-list></fibra-company-list>
  <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>

<div id="content-page-id" class="content-page" *fibraHasRole="'OP_CRED.CONS_OP_CRED'">
  <div class="div">
    <fibra-breadcrumb bottom="15" 
    roule="Operações de Crédito" 
    arrow="false" 
    [downloadDoc]="true"
    [exportButtonPlaceholder]="'Baixar informações da tela'"
    (infoReport)="setReportData($event)"
    >
    
  </fibra-breadcrumb>

  <div class="navbar-teste">
    <div *ngIf="showComponents" [class.item-selected]="contratos" [class.item-unselected]="!contratos" (click)="changeNavbarClass('contratos')" id="contratos-item">
      <span [class.item-font-selected]="contratos" [class.item-font-unselected]="!contratos" id="contratos-font"> Contratos </span>
    </div>
    <div *ngIf="showComponents" [class.item-selected]="posicaoDiaria" [class.item-unselected]="!posicaoDiaria" id="posicaoDiaria-item" (click)="changeNavbarClass('posicaoDiaria')">
      <span [class.item-font-selected]="posicaoDiaria" [class.item-font-unselected]="!posicaoDiaria" id="posicaoDiaria-font"> Posição diária </span>
    </div>
    <div *ngIf="showComponents && hasCheckCompanyAccount" [class.item-selected]="chequeEmpresa" [class.item-unselected]="!chequeEmpresa" id="chequeEmpresa-item" (click)="changeNavbarClass('chequeEmpresa')">
      <span [class.item-font-selected]="chequeEmpresa" [class.item-font-unselected]="!chequeEmpresa" id="chequeEmpresa-font"> Cheque Empresa </span>
    </div>
    <div *ngIf="!showComponents" [class.item-selected]="parcelas" [class.item-unselected]="!parcelas" id="parcelas-item" (click)="changeNavbarClass('parcelas')">
      <span [class.item-font-selected]="parcelas" [class.item-font-unselected]="!parcelas" id="parcelas-font"> Parcelas </span>
    </div>
    <div *ngIf="!showComponents" [class.item-selected]="informacaoContrato" [class.item-unselected]="!informacaoContrato" id="informacaoContrato-item" (click)="changeNavbarClass('informacaoContrato')">
      <span [class.item-font-selected]="informacaoContrato" [class.item-font-unselected]="!informacaoContrato" id="informacaoContrato-font"> Informações do Contrato</span>
    </div>
  </div>
  <section class="body-content">
    <fibra-contracts *ngIf="contratos" (numContractContractInformation)="callNavbar($event)"> </fibra-contracts>
    <fibra-daily-position *ngIf="posicaoDiaria"> </fibra-daily-position>
    <fibra-company-check *ngIf="chequeEmpresa"></fibra-company-check>
    <fibra-parcelas *ngIf="parcelas" [numContract]="numContrato" (showPageContractInformation)="callNavbar($event)">
    </fibra-parcelas>
    <fibra-contract-information *ngIf="informacaoContrato" [numContract]="numContrato" 
      (showPageContractInformation)="callNavbar($event)"></fibra-contract-information>
  </section>


  <section class="body-content report" id="body-report" *ngIf="report" style="position: absolute;">
    <fibra-loans-report id="report" (closeReport)="changeReportView($event)"></fibra-loans-report>
    </section>
</div>

