import { Injectable } from '@angular/core';
import { DownloadBillModelRepository } from '@src/core/repositories/charge/duplicate-bill.repository';
import { UseCase } from '@src/core/base/use-case';
import {
  DuplicateBillModel,
  DuplicateBillRequest,
} from '@src/core/domain/charge/duplicate-bill.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetDownloadBillUsecase
  implements UseCase<DuplicateBillRequest, DuplicateBillModel> {
  constructor(private downloadBillRepository: DownloadBillModelRepository) {}

  execute(params: DuplicateBillRequest): Observable<DuplicateBillModel> {
    return this.downloadBillRepository.getDownloadBill(params);
  }
}
