<div class="fibra-banner-container">
    <ngb-carousel #carousel class="fibra-banner">
        <ng-template ngbSlide>
            <img src="/assets/banner/Banner-Incentivo-Chat-IB.jpg" alt="Chat Banco Fibra. Atendimento humanizado, fácil e rápido para a sua empresa." class="img-banner">
        </ng-template>

        <ng-template ngbSlide *ngIf="fibraFacil">
            <img src="/assets/banner/Fibra-Facil-v3.jpg" alt="Fibra fácil" class="img-banner">
        </ng-template>

        <ng-template ngbSlide *ngIf="limiteFibraFacilAumentou">
            <a rel="noopener noreferrer" target="_blank" style="cursor: pointer" (click)="redirecionaParaChequeEmpresa($event)">
                <img src="/assets/banner/Banner-IB-Fibra-Fácil-v2.jpg" alt="Limite do Fibra Fácil aumentou" class="img-banner">
            </a>
        </ng-template>

        <ng-template ngbSlide *ngIf="limiteChequeEmpresaAumentou">
            <a rel="noopener noreferrer" target="_blank" style="cursor: pointer" (click)="redirecionaParaChequeEmpresa($event)">
                <img src="/assets/banner/Banner-IB-Cheque Empresa-v3.jpg" alt="Limite do Cheque Empresa aumentou" class="img-banner">
            </a>
        </ng-template>

        <ng-template ngbSlide *ngIf="!fibraFacil">
            <img src="/assets/banner/Banner0002-Novo-IB-v1.jpg" alt="Gestão facilitada! Consulte saldos e extratos a qualquer momento." class="img-banner">
        </ng-template>
        
        <ng-template ngbSlide>
            <a rel="noopener noreferrer" href="https://click.relacionamento-bancofibra.com.br/cadastroderegra" target="_blank">
                <img src="/assets/banner/banner-0003-Novo-IB-v1.png" alt="Cadastro de regras" class="img-banner">
            </a>
        </ng-template>

        <ng-template ngbSlide *ngIf="showBannerCapitalGiroEmpresa">
            <a rel="noopener noreferrer" target="_blank">
                <img src="/assets/banner/Banner-IB-Web-FGI-PEAC-Empresas-v1.png" alt="Capital de Giro FGI PEAC" class="img-banner">
            </a>
        </ng-template>

        <ng-template ngbSlide *ngIf="showBannerCapitalGiroPE">
            <a rel="noopener noreferrer" target="_blank">
                <img src="/assets/banner/Banner-IB-Web-FGI-PEAC-PE-v1.png" alt="Capital de Giro FGI PEAC" class="img-banner">
            </a>
        </ng-template>

    </ngb-carousel>
</div>