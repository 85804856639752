import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as $ from 'jquery';
import * as moment from 'moment';

@Component({
  selector: 'fibra-download-receipt',
  templateUrl: './download-receipt.component.html',
  styleUrls: ['./download-receipt.component.scss'],
})

// class QtdPageModel {
//   constructor(page1: number, page2: number) {
//     this.page1 = page1;
//     this.page2 = page2;
//   }
//   public page1: number;
//   public page2: number;
// } 

export class DownloadReceiptComponent implements OnInit {
  @Output() public closeReport = new EventEmitter();
  public dataGeral = [];
  public qtdPage1: number;
  public qtdPage2: number;
  public data;
  public qtd = 0;
  public approvalList;
  public accountType;
  public tabsType;
  public date;
  public hour;
  public diffDateDays;
  public date1;
  public date2;
  public quebrouPagina = false;
  public pageNumber = 1;
  public company;
  
  constructor() {}

  ngOnInit() {
    this.data = JSON.parse(localStorage.getItem('receipt-export-data'));
    this.getDate();
    this.qtd = 0;
    this.pageNumber = 1;
    this.quebrouPagina = false;

    this.company = JSON.parse(sessionStorage.getItem('defaultCompany'));
    if (this.company[0].modality_account.length === 11) {
      this.company[0].modality_account = this.company[0].modality_account.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      this.company[0].modality_account = this.company[0].modality_account.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, '$1.$2.$3/$4-$5');
    }

    let datas = this.getDates();
    datas.map((itemData) =>{
      let itens = this.getTransactionByDate(itemData);
      this.dataGeral.push(itemData)
      itens.map((itemComprovante)=>{
        this.dataGeral.push(itemComprovante);
      });
    });

    
    

    this.approvalList = this.data.data;
    this.tabsType = this.data.tabs;
    this.accountType = this.data.date.account;
    
  }
public indexCount = 0;

  getMarginLastNumber(i: number){
      if(i > 11 && ((i - 11) % 15 === 0)){
        return '276px'
      }
     return '0'; 
  }

  ngAfterViewInit() {
    this.qtd = 0;
    this.pageNumber = 1;
   this.generatePDFCustom('BancoFibra');
    // localStorage.removeItem('receipt-export-data');
    this.closeReport.emit('true');
  }


  addQtd(){
    if(this.indexCount > 0){
      this.indexCount +=1;
    }
  }

  checkQuebrouPagina(){
    if(this.quebrouPagina){
      this.quebrouPagina = false;
      return true;
    }
    return false;
  }

  getDates(){
    let datas = [...new Set(this.data.data.map(item => (moment(item.dt_transacao)).format("YYYY-MM-DD")       ))];
    datas.filter((n,i) => datas.indexOf(n) == i);
    return datas;
  }

  getDate() {
    const auxDate = new Date();
    this.date = Intl.DateTimeFormat('pt-BR').format(auxDate);
    this.hour = auxDate.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1');
    this.diffDays();
  }

  getTransactionByDate(dt: any){
    return this.data.data.filter(d => (moment(d.dt_transacao)).format("YYYY-MM-DD") == dt)
  }

  diffDays() {
    try{
      this.date1 = new Date(this.data.date.dt_Inicio);
      this.date2 = new Date(this.data.date.dt_Final);

      this.date1.setDate(this.date1.getDate() + 1);
      this.date2.setDate(this.date2.getDate() + 1);

      const timeDiff = Math.abs(this.date2.getTime() - this.date1.getTime());
      this.diffDateDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    }
    catch(error){
      
    }
  }

  public tratarBanco(banco: any){
    var retorno = banco.replace(/^([^-]+\-){1}/, '').trim();
    return retorno;
  }

  public generatePDFCustom(reportName: string) {
 
    const data = document.getElementById('body-content');

    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
    {
        html2canvas(data,{
          windowWidth: document.body.scrollWidth,
          windowHeight: document.body.scrollHeight,
          x: window.pageXOffset,
          y: window.pageYOffset,
        }).then((canvas) => {

        var imgWidth = 210;
        var pageHeight = 297;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        
        var doc = new jsPDF('p', 'mm');
        var position = 0;

        const imgData = canvas.toDataURL("image/jpeg")


        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        doc.save(reportName+'.pdf');   
        
      });
    }
    else
    {

      html2canvas(data).then((canvas) => {
      var imgWidth = 210;
      var pageHeight = 297;

      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      
      var doc = new jsPDF('p', 'mm');
      var position = 0;

      const imgData = canvas.toDataURL("image/jpg")

      doc.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      doc.save(reportName+'.pdf');   
      
    });
    }

    $("#ico-fechar").css({ display: "block" });
    this.goBack();

  }

  public goBack() {
    this.closeReport.emit('true');
  }
}
