import { Injectable } from '@angular/core';
import { PaymentRepository } from '@src/core/repositories/payment/payment.repository';
import { PaymentModel, PaymentBoletoRequest, PaymentRequest, PaymentDeleteRequest, PaymentMultipleRequest } from '@src/core/domain/payment/payment.model';
import { Observable, of, pipe, throwError } from 'rxjs';
import { PaymentRepositoryMapper } from '@src/data/repository/payment/payment-repository.mapper';
import { HttpClient } from '@angular/common/http';
import { PaymentEntity  } from '@src/data/repository/payment/payment.entity';
import { concatMap, delay, flatMap, map, retryWhen } from 'rxjs/operators';


// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class PaymentDataRepository extends PaymentRepository {
  mapper = new PaymentRepositoryMapper();

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getBoleto(params: PaymentBoletoRequest): Observable<PaymentModel> {
   
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<PaymentEntity>(
        `${environment.URLBase}v1/pagamentos/consultas/boletos/${params.codigoBoleto}/${params.dt_pagamento}`,
        httpOptions
        )
      .pipe(retryWhen(errors =>errors.pipe(concatMap((error, count) => {if (count < 3 && error.status === 504) {return of(error);}return throwError(error.error);}),delay(500))))
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }



    getStatusHourPayment(x: number): Observable<number> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
        .post<number>(
          `${environment.URLBase}v1/transacoes/ObtemStatusLimiteHorarioPagamento`, x,
        httpOptions
        )  
  }

  postBoleto(params: PaymentRequest): Observable<PaymentModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .post<PaymentEntity>(
        `${environment.URLBase}v1/pagamentos/efetivar`, params,
        httpOptions
        )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  saveBoletos(params: PaymentRequest): Observable<PaymentModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .post<PaymentEntity>(
        `${environment.URLBase}v1/pagamentos/salvar`, params,
        httpOptions
        )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  deleteBoletos(params: PaymentDeleteRequest): Observable<PaymentModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .delete<PaymentEntity>(
        `${environment.URLBase}v1/pagamentos/delete/${params.num_operacao}`,
        httpOptions
        )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  postMultipleBoleto(params: PaymentMultipleRequest): Observable<PaymentModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .post<PaymentEntity>(
        `${environment.URLBase}v1/pagamentos/efetivarmultiplo`, params,
        httpOptions
        )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }
}
