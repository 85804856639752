import { Injectable } from '@angular/core';
import { OtpRepository } from '@src/core/repositories/otp/otp.repository';
import { OtpModel, OtpRequest } from '@src/core/domain/otp/otp.model';
import { Observable } from 'rxjs';
import { OtpRepositoryMapper } from '@src/data/repository/otp/otp-repository.mapper';
import { HttpClient } from '@angular/common/http';
import { OtpEntity  } from '@src/data/repository/otp/otp.entity';
import { flatMap, map } from 'rxjs/operators';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root'
})


export class OtpDataRepository extends OtpRepository {
  mapper = new OtpRepositoryMapper();

  constructor(
    private http: HttpClient,
    private analytics: AngularFireAnalytics
  ) {
    super();
  }


  postOtp(params: OtpRequest): Observable< OtpModel > {
    const httpOptions = {
      headers: getHeader(),
    };
    this.analytics.logEvent('confirm_operation', {
      operation: params.api_callback,
      details: params.payload
    });
    return this.http
      .post< OtpEntity >(
        `${environment.URLBase}v1/token/validarotp`, params,
        httpOptions
        )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

}
