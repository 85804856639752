<div class="comprovante" id="comprovante" *ngIf="readyToLoad">
    <div class="close-div" id="fechar">
        <img src="/assets/svg/icon-fechar-black.svg" alt="fechar" id="ico-fechar" (click)="goBack()" style="display:none">
    </div>
    <div class="header">
        <div class="header-content">
            <img src="/assets/svg/logo_fibra.svg" alt="logo" style="width: 165px;height: 49px;">
            <span class="header-text"> Internet Banking - Banco Fibra - Empresa: {{company[0].name}} - CNPJ {{company[0].modality_account}} - {{day}} {{hours}}</span>
        </div>
        <span class="title-report">Operações de Crédito</span>
    </div>

    <!-- TíTulo Info Contrato -->
    <span class="report-title" style="margin-left: 40px;margin-top: 25px;" *ngIf="informations.name=='info-contrato'">{{informations.title}}  <span class="contrato">{{informations?.num_contract}} </span></span>

    <!-- SubTítulo Info Contrato -->
    <div class="div" style="margin-left: 40px;margin-bottom:15px;color:#495057; font-weight:500; font-size:14px" *ngIf="informations.name=='info-contrato'">
        <span class="report-subtitle">Informações do Contrato </span> <span class="report-subtitle-value"> </span>
    </div>

    <!-- TíTulo Cheque -->
    <span class="report-title" style="margin-left: 72px;margin-top: 20px;" *ngIf="informations.name=='cheque-empresa'">{{informations.title}} </span>

    <!-- Título Contratos -->
    <span *ngIf="informations.name=='contratos'" style="margin-left: 40px;margin-top: 25px;" class="report-title">{{informations.title}}</span>

    <!-- TíTulo Posição -->
    <span class="report-title" style="margin-left: 40px;margin-top: 25px;" *ngIf="informations.name=='daily-position'">{{informations.title}} </span>

    <!-- SubTítulo Posição Diaria -->
    <div class="div" style="margin-bottom: 10px;margin-left: 40px;" *ngIf="informations.name=='daily-position'">
        <span class="report-subtitle" style="color:#495057; "> Parcelas </span> <span class="report-subtitle-value"> </span>
        <span class="report-subtitle" style="color: #8F969D;"> {{ informations.filter }}</span> <span class="report-subtitle-value"> </span>
    </div>

    <!-- SubTítulo Contratos -->
    <div class="div" style="margin-bottom: 10px;margin-left: 40px;" *ngIf="informations.name=='contratos'">
        <span class="report-subtitle" style="color:#495057"> {{ informations.filter }} </span> <span class="report-subtitle-value"> </span>
        <span class="report-subtitle" style="color:#8F969D"> {{ informations.dt_ref | date: 'dd/MM/yyyy' }} </span> <span class="report-subtitle-value"> </span>
    </div>

    <!-- TíTulo PARCELAS -->
    <span class="report-title" style="margin-left: 40px;margin-top: 25px;" *ngIf="informations.name=='parcelas'">{{informations.title}}  <span class="contrato">{{informations?.num_contract}} </span></span>

    <!-- SubTítulo Posição Diaria -->
    <div class="div" style="margin-bottom: 10px;" *ngIf="informations.name=='parcelas'">
        <span class="report-subtitle" style="color:#495057; margin-left: 40px; "> Parcelas </span> <span class="report-subtitle-value"> </span>
        <span class="report-subtitle" style="color: #8F969D;"> {{ informations.filter }}</span> <span class="report-subtitle-value"> </span>
    </div>

    <!-- Contratos -->
    <div class="div" *ngIf="informations.name=='contratos' && contractsArray?.length > 0">
        <!-- <div class="datatable" id="contracts" *ngFor="let contract of contractsArray"> -->

        <div *ngFor="let contract of contractsArray;let i= index">

            <div class="card text-left" style="margin-left: 40px;margin-right: 40px; border-radius: 4px;" id="contracts">

                <div class="card-body">
                    <div class="date">
                        <span class="date-font"> Contrato: </span>
                        <span class="date-font"> {{contract.num_contrato}}</span>
                    </div>
                    <div class="table-row">
                        <div class="table-item">
                            <span class="item-font-gray">Produto</span>
                            <span class="item-font">{{contract.nome_produto}}</span>
                        </div>
                        <div class="table-item">
                            <span class="item-font-gray">Data do  contrato</span>
                            <span class="item-font">{{contract.dt_contratacao | date: 'dd/MM/yyyy'}}</span>
                        </div>

                        <div class="table-item">
                            <span class="item-font-gray">Vencimento do contrato</span>
                            <span class="item-font">{{contract.dt_fim | date: 'dd/MM/yyyy'}}</span>
                        </div>

                    </div>
                    <div class="table-row">
                        <div class="table-item">
                            <span class="item-font-gray">Valor do empréstimo</span>
                            <span class="item-font">{{contract.valor_principal | currency: 'BRL'}} </span>
                        </div>
                        <div class="table-item">
                            <span class="item-font-gray">Prazo </span>
                            <span class="item-font">{{contract.num_parcelas}} mes(es)</span>
                        </div>

                        <div class="table-item">
                            <span class="item-font-gray">Situação </span>
                            <span class="item-font">{{contract.qt_parcela_paga}} / {{contract.num_parcelas}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Espaço -->
            <div *ngIf="(i+1) < 6 && (i+1)%5==0">
                <div style="height: 180px;">
                </div>
            </div>
        </div>
    </div>

    <!-- POSIÇÃO DIARIA -->
    <div *ngIf="informations.name=='daily-position' && dailyPositionArray?.length > 0">
        <div id="daily-position" style="margin-right: 40px;margin-left: 40px;" *ngFor="let item of dailyPositionArray;let i= index">
            <div class="card text-left">

                <div class="card-body">

                    <div class="date">
                        <span class="date-font"> Produto: </span>
                        <span class="date-font"> {{item.nome_produto}}</span>
                    </div>
                    <div class="table-row">
                        <div class="table-item">
                            <span class="item-font-gray">Contrato</span>
                            <span class="item-font">{{ item.num_contrato }}</span>
                        </div>
                        <div class="table-item">
                            <span class="item-font-gray">N° da parcela</span>
                            <span class="item-font">{{ item.num_parcela }}</span>
                        </div>

                        <div class="table-item">
                            <span class="item-font-gray">Principal</span>
                            <span class="item-font">{{ item.valor_principal | currency: 'BRL' }}</span>
                        </div>

                        <div class="table-item">
                            <span class="item-font-gray">Valor à pagar</span>
                            <span class="item-font">{{ item.valor_a_pagar | currency: 'BRL' }}</span>
                        </div>

                        <div class="table-item">
                            <span class="item-font-gray">Situação</span>
                            <span class="item-font"> {{ item.cod_situacao }}</span>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-muted">
                    <div style="padding-left:50px;padding-right:50px;background-color: #F8F9FA;">
                        <div class="table-row">
                            <div class="table-item">
                                <span class="item-font-gray">Correção monetária</span>
                                <span class="item-font">{{item.valor_principal_cm | currency: 'BRL' }}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font-gray">Juros</span>
                                <span class="item-font">{{ item.valor_juros | currency: 'BRL' }}</span>
                            </div>

                            <div class="table-item">
                                <span class="item-font-gray">Juros de mora</span>
                                <span class="item-font">{{item.valor_mora | currency: 'BRL'}}</span>
                            </div>

                            <div class="table-item">
                                <span class="item-font-gray">Multa moratória</span>
                                <span class="item-font">{{item.valor_multa | currency: 'BRL' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Espaço -->
            <div *ngIf="(i+1) < 5 && (i+1)%4==0">
                <div style="height: 210px;">
                </div>
            </div>

            <div *ngIf="(i+1) > 6 && (i+1)%4==0">
                <div style="height: 450px;">
                </div>
            </div>

        </div>



        <div class="card text-left" style="margin-right: 40px;margin-left: 40px; border-radius: 0 0 8px 8px;">
            <div class="card-body" style="background-color: #4D86BF; color: white;">
                <div class="date">
                    <span class="date-font" style="color: white;"> Valores totais </span>
                    <span class="date-font"> </span>
                </div>
                <div class="table-row" style="color: white;">
                    <div class="table-item">
                        <span class="item-font-white">Principal</span>
                        <span class="item-font-white-values">{{ listValuesDailyPosition.totalPrincipal | currency: 'BRL'}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font-white">Correção monetária</span>
                        <span class="item-font-white-values">{{ listValuesDailyPosition.totalPrincipalCorrigido | currency: 'BRL'}}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-white">Juros</span>
                        <span class="item-font-white-values">{{ listValuesDailyPosition.totalJuros | currency: 'BRL'}}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-white">Juros de mora</span>
                        <span class="item-font-white-values">{{ listValuesDailyPosition.totalJurosDeMora | currency: 'BRL'}}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-white">Multa moratória</span>
                        <span class="item-font-white-values">{{ listValuesDailyPosition.totalMultaMoratoria | currency: 'BRL'}}</span>
                    </div>
                </div>


                <div class="table-row">
                    <div class="table-item">
                        <span class="item-font-white">IOF</span>
                        <span class="item-font-white-values">{{ listValuesDailyPosition.totalIof | currency: 'BRL'}}</span>
                    </div>
                    <div class="table-item">
                        <span class=item-font-white>Total à pagar</span>
                        <span class="item-font-white-values">{{ listValuesDailyPosition.totalAPagar | currency: 'BRL'}}</span>
                    </div>
                </div>
            </div>
        </div>
        <p class="texto-informativo-2">*Valores sujeitos a confirmação no momento da liquidação.</p>
    </div>

    <!-- PARCELAS -->
    <div *ngIf="informations.name=='parcelas' && parcelasArray?.length > 0">
        <div id="daily-position" style="margin-right: 40px;margin-left: 40px;" *ngFor="let item of parcelasArray; let i = index">
            <div class="card text-left">

                <div class="card-body">
                    <div *ngIf="i==0" class="date">
                        <span class="date-font"> Data de referência: </span>
                        <span class="date-font"> {{informations.dt_ref |date : 'dd/MM/yyyy' }}</span>
                    </div>
                    <div class="table-row">
                        <div class="table-item">
                            <span class="item-font-gray">N° da parcela</span>
                            <span class="item-font">{{  item.num_parcela }}</span>
                        </div>
                        <div class="table-item">
                            <span class="item-font-gray">Data do vencimento</span>
                            <span class="item-font">{{ item.dt_vencto | date : 'dd/MM/yyyy' }}</span>
                        </div>

                        <div class="table-item">
                            <span class="item-font-gray">Data do pagamento</span>
                            <span class="item-font">{{ item.dt_pagto | date : 'dd/MM/yyyy' }}</span>
                        </div>

                        <div class="table-item">
                            <span class="item-font-gray">Situação</span>
                            <span class="item-font">{{ item.cod_situacao }}</span>
                        </div>
                    </div>

                    <div class="table-row">
                        <div class="table-item">
                            <span class="item-font-gray">Principal</span>
                            <span class="item-font">{{ item.valor_principal | currency: 'BRL' }}</span>
                        </div>
                        <div class="table-item">
                            <span class="item-font-gray">Valor à pagar</span>
                            <span class="item-font">{{ item.valor_a_pagar | currency: 'BRL' }}</span>
                        </div>

                        <div class="table-item">
                            <span class="item-font-gray">Valor pago</span>
                            <span class="item-font">{{ item.valor_pago | currency: 'BRL' }}</span>
                        </div>


                    </div>
                </div>
                <div class="card-footer text-muted">

                    <div style="padding-left:50px;padding-right:50px;background-color: #F8F9FA;">
                        <div class="table-row">
                            <div class="table-item">
                                <span class="item-font-gray">Correção monetária</span>
                                <span class="item-font">{{item.valor_principal_cm | currency: 'BRL' }}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font-gray">Juros</span>
                                <span class="item-font">{{ item.valor_juros | currency: 'BRL' }}</span>
                            </div>

                            <div class="table-item">
                                <span class="item-font-gray">Juros de mora</span>
                                <span class="item-font">{{item.valor_mora | currency: 'BRL'}}</span>
                            </div>

                            <div class="table-item">
                                <span class="item-font-gray">Multa moratória</span>
                                <span class="item-font">{{item.valor_multa | currency: 'BRL' }}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <!-- Espaço -->
            <div *ngIf="(i+1) < 5 && (i+1)%3==0">
                <div style="height: 220px;">
                </div>
            </div>

            <div *ngIf="i > 2 && (i-2)%4==0">
                <div style="height: 300px;">
                </div>
            </div>
        </div>

        <div class="card text-left" style="margin-right: 40px;margin-left: 40px; border-radius: 0 0 8px 8px;">
            <div class="card-body" style="background-color: #4D86BF; color: white;">
                <div class="date">
                    <span class="date-font" style="color: white;"> Valores totais </span>
                    <span class="date-font"> </span>
                </div>
                <div class="table-row" style="color: white;">
                    <div class="table-item">
                        <span class="item-font-white">Principal</span>
                        <span class="item-font-white-values">{{ listValuesParcelas.totalPrincipal | currency: 'BRL'}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font-white">Correção monetária</span>
                        <span class="item-font-white-values">{{ listValuesParcelas.totalPrincipalCorrigido | currency: 'BRL'}}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-white">Juros</span>
                        <span class="item-font-white-values">{{ listValuesParcelas.totalJuros | currency: 'BRL'}}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-white">Juros de mora</span>
                        <span class="item-font-white-values">{{ listValuesParcelas.totalJurosDeMora | currency: 'BRL'}}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-white">Multa moratória</span>
                        <span class="item-font-white-values">{{ listValuesParcelas.totalMultaMoratoria | currency: 'BRL'}}</span>
                    </div>
                </div>


                <div class="table-row">
                    <div *ngIf="informations.filter=='Vencidas'" class="table-item">
                        <span class="item-font-white">IOF</span>
                        <span class="item-font-white-values">{{ listValuesParcelas.totalIof | currency: 'BRL'}}</span>
                    </div>
                    <div *ngIf="informations.filter!='Vencidas'" class="table-item">
                        <span class="item-font-white">IOF</span>
                        <span class="item-font-white-values">-</span>
                    </div>
                    <div *ngIf="informations.filter=='Vencidas'" class="table-item">
                        <span class=item-font-white>Total à pagar</span>
                        <span class="item-font-white-values">{{ listValuesParcelas.totalAPagarSeAbaVencida | currency: 'BRL'}}</span>
                    </div>
                    <div *ngIf="informations.filter!='Vencidas'" class="table-item">
                        <span class=item-font-white>Total à pagar</span>
                        <span class="item-font-white-values">{{ listValuesParcelas.totalAPagar | currency: 'BRL'}}</span>
                    </div>
                </div>
            </div>
        </div>
        <p class="texto-informativo-2">*Valores sujeitos a confirmação no momento da liquidação.</p>
    </div>

    <!-- Cheque Empresa -->
    <div class="div" *ngIf="informations.name=='cheque-empresa' && contractsCheckArray?.length > 0">
        <div class="card text-left" style="margin-left: 72px;margin-right: 40px; border-radius: 4px;" id="cheque-empresa" *ngFor="let check of contractsCheckArray">

            <div class="card-body">

                <div class="date">
                    <span class="date-font"> Conta: </span>
                    <span class="date-font"> {{check.nro_conta}} - {{ check.descricao_modalidade }}</span>
                </div>
                <div class="table-row">
                    <div class="table-item">
                        <span class="item-font-gray">Limite cheque empresa</span>
                        <span class="item-font">{{ check.valor_limite | currency: 'BRL' }}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-gray">Data da contratação</span>
                        <span class="item-font">{{ check.dt_contratacao | date: 'dd/MM/yyyy' }}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-gray">Data de vencimento</span>
                        <span class="item-font">{{ check.data_vencimento | date: 'dd/MM/yyyy' }}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-gray">Taxa de juros (%a.m.)</span>
                        <span class="item-font">{{ check.taxa_juros_mes.toString().replace(".",",") }}%</span>
                    </div>

                </div>
                <div class="table-row">

                    <div class="table-item">
                        <span class="item-font-gray">Taxa de juros (%a.a.)</span>
                        <span class="item-font">{{ check.taxa_juros_efetiva.toString().replace(".",",") }}%</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-gray">Alíquota de IOF diária</span>
                        <span class="item-font">{{ check.aliquota_iof_diaria.toString().replace(".",",") }}%</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-gray">Alíquota de IOF adicional</span>
                        <span class="item-font">{{ check.aliquota_iof_adicional.toString().replace(".",",") }}%</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-gray">Carência(dias)</span>
                        <span class="item-font">{{ check.num_dias_carencia }}</span>
                    </div>
                </div>

            </div>

        </div>
        <p class="texto-informativo" style="padding-left: 72px;">*Valores sujeitos a confirmação no momento da liquidação.</p>
    </div>

    <!-- Info Contract -->
    <div class="div" *ngIf="contractInfoArray?.length > 0">
        <!-- <div class="datatable" id="info-contracts" *ngFor="let item of contractInfoArray"> -->
        <div class="card text-left" style="margin-left: 40px;margin-right: 40px; border-radius: 4px;" id="info-cotract" *ngFor="let item of contractInfoArray">
            <div class="card-body">


                <div class="dateRefAlign">
                    <span class="dateRef">Data de referência: {{informations.dt_ref | date :'dd/MM/yyyy'}}</span>
                </div>
                <p class="dados-contrato" style="padding-left:30px">
                    Dados do contrato
                </p>

                <div class="table-row" style="padding-left:30px">
                    <div class="table-item">
                        <span class="item-font-gray">Dados da contratação</span>
                        <span class="item-font">{{item.dt_contratacao | date :'dd/MM/yyyy'}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font-gray">Valor do empréstimo</span>
                        <span class="item-font">{{item.valor_emprestimo | currency: 'BRL' }}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-gray">Principal</span>
                        <span class="item-font">{{item.valor_principal | currency: 'BRL' }}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-gray">Correção monetária</span>
                        <span class="item-font">{{item.valor_principal_cm | currency: 'BRL' }}</span>
                    </div>
                </div>



                <div class="table-row" style="padding-left:30px">
                    <div class="table-item">
                        <span class="item-font-gray">Taxa mensal</span>
                        <span class="item-font">{{item.taxa_juros_mes.toString().replace(".",",") }}%</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font-gray">Juros</span>
                        <span class="item-font">{{item.valor_juros | currency : 'BRL'}}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-gray">Juros de mora</span>
                        <span class="item-font">{{item.valor_mora | currency: 'BRL' }}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-gray">multa moratória</span>
                        <span class="item-font">{{item.valor_multa | currency: 'BRL' }}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-gray">IOF</span>
                        <span class="item-font">{{item.valor_iof | currency: 'BRL' }}</span>
                    </div>
                </div>

                <div class="row padding-top-25">
                </div>

                <p class="image-dados-contr">
                </p>



                <p class="dados-contrato padding-top-10" style="padding-left:30px">
                    Pagamento
                </p>




                <div class="table-row" style="padding-left:30px">
                    <div class="table-item">
                        <span class="item-font-gray">Prazo</span>
                        <span class="item-font">{{item.num_parcelas}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font-gray">Data fim</span>
                        <span class="item-font">{{item.dt_fim | date :'dd/MM/yyyy'}}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-gray">Data próximo vencimento</span>
                        <span class="item-font">{{item.dt_proximo_vencto | date :'dd/MM/yyyy'}}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-gray">Parcelas pagas</span>
                        <span class="item-font">{{item.qt_parcela_paga}}</span>
                    </div>
                </div>


                <div class="table-row" style="padding-left:30px">
                    <div class="table-item">
                        <span class="item-font-gray">Valor à pagar</span>
                        <span class="item-font">{{item.valor_a_pagar | currency: 'BRL' }}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-gray">Valor pago</span>
                        <span class="item-font">{{item.valor_pago | currency: 'BRL' }}</span>
                    </div>
                </div>
            </div>




        </div>

        <p class="texto-informativo">*Valores sujeitos a confirmação no momento da liquidação.</p>
    </div>

    <div class="card-content">
        <div class="card">
            <div class="html2pdf__page-break"></div>
            <br><br>
            <div class="card-body" #atendimento>
                <img class="icon" src="/assets/png/apoio 1.png" alt="Atendimento" />
                <div class="text">
                    <div class="title">Canais exclusivos</div>
                    <div class="desc">Dúvidas e apoio</div>
                </div>
            </div>
            <div class="card-bottom">
                <p>
                    <b>Corporate, Agro e PMEs</b>
                    (11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
                </p>
                <p>
                    <a href="mailto:relacionamento@bancofibra.com.br">relacionamento@bancofibra.com.br</a>
                </p>
            </div>
        </div>
        <div class="card">
            <div class="card-body" #sac>
                <img class="icon" src="/assets/png/telefone 1.png" alt="Atendimento" />
                <div class="text">
                    <div class="title">SAC</div>
                    <div class="desc">Sugestões, reclamações e elogios</div>
                </div>
            </div>
            <div class="card-bottom">
                <p>
                    <b>Canal de Atendimento ao Cliente</b>
                </p>
                <p>
                    0800 727 0132
                </p>
                <p>
                    <b>Canal exclusivo para deficientes auditivos ou de fala</b>
                </p>
                <p>
                    0800 200 6921
                </p>
            </div>
        </div>
        <div class="card">
            <div class="card-body" #ouvidoria>
                <img class="icon" src="/assets/png/alto-falante.png" alt="Atendimento" />
                <div class="text">
                    <div class="title">Ouvidoria</div>
                    <div class="desc">Demandas não solucionadas</div>
                </div>
            </div>
            <div class="card-bottom">
                <p>
                    0800 727 0132, das 9h às 18h, de segunda a sexta-feira, exceto feriados.<br><br>
                </p>
            </div>
        </div>
    </div>

    <!-- <div class="div" *ngIf="changeArray !== null ">
      <div class="datatable" id="cambio-pronto" *ngFor="let change of changeArray">
        <div class="date">
          <span class="date-font"> {{change.data}}</span>
        </div>
        <div class="table-title">
          <span class="title-font"> Contrato: {{change.num_contrato}} </span>
          <span class="title-font"> Tipo de operação: {{change.tipo}}</span>
        </div>
          <div class="table-row">
              <div class="table-item">
                <span class="item-font-gray">Data de contratação</span>
                <span class="item-font">{{change.dt_op}}</span>
              </div>
              <div class="table-item">
                <span class="item-font-gray">Data de liquidação</span>
                <span class="item-font">{{change.dt_liq}}</span>
              </div>
              
          </div>
          <div class="table-row">
            <div class="table-item">
              <span class="item-font-gray">Moeda estrangeira</span>
              <span class="item-font">USD: {{change.valorFormatadoUsd}} </span>
            </div>
            <div class="table-item">
              <span class="item-font-gray">Moeda nacional </span>
              <span class="item-font">BRL: {{change.valorFormatadoBrl}}</span>
            </div>
            <div class="table-item">
              <span class="item-font-gray">OP</span>
              <span class="item-font">-</span>
            </div>
            <div class="table-item">
              <span class="item-font-gray">Totais</span>
              <span class="item-font"></span>
            </div>
            
        </div>
        </div>
    </div>

    <div class="div" *ngIf="finimpArray !== null ">
      <div class="datatable" id="importacao-finimp" *ngFor="let finimp of finimpArray">
        <div class="table-title">
          <span class="title-font"> Contrato: {{finimp.num_contrato}}</span>
        </div>
          <div class="table-row">
              <div class="table-item">
                <span class="item-font-gray">Data de desembolso</span>
                <span class="item-font">{{finimp.dt_op}}</span>
              </div>
              <div class="table-item">
                <span class="item-font-gray">Data de vencimento</span>
                <span class="item-font">{{finimp.dt_vencto}}</span>
              </div>
              
          </div>
          <div class="table-row">
            <div class="table-item">
              <span class="item-font-gray">Juros %</span>
              <span class="item-font">{{finimp.taxa_en}} </span>
            </div>
            <div class="table-item">
              <span class="item-font-gray">Moeda </span>
              <span class="item-font">{{finimp.swift}}</span>
            </div>
            <div class="table-item">
              <span class="item-font-gray">Valor de Juros</span>
              <span class="item-font">{{finimp.encargo}}</span>
            </div>
            <div class="table-item">
              <span class="item-font-gray">Valor</span>
              <span class="item-font">{{finimp.usdFormatado}}</span>
            </div>
            
        </div>
        </div>
    </div>
       
    <div class="div" *ngIf="cobrancaArrayImport !== null ">
            <div class="datatable" id="importacao-cobranca"  *ngFor="let cob of cobrancaArrayImport">
              <div class="table-title">
                <span class="title-font"> Ref. cliente: {{cob.num_contrato}}</span>
              </div>
                <div class="table-row">
                    <div class="table-item">
                      <span class="item-font-gray">Ref. banco</span>
                      <span class="item-font">{{cob.num_ref}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Data de recepção</span>
                      <span class="item-font">{{cob.dt_reg}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Tipo de vencimento</span>
                      <span class="item-font">{{cob.dt_vencto}}</span>
                    </div>
                    
                </div>
                <div class="table-row">
                  <div class="table-item" style="width: 35% !important;">
                    <span class="item-font-gray">Banco emissor / Banco exportador </span>
                    <span class="item-font">{{cob.nome_banco_emissor}}</span>
                    <span class="item-font" style="margin-top: 4px;">{{cob.nome_exportador}}</span>
                  </div>
                  <div class="table-item" style="width: 15% !important">
                    <span class="item-font-gray">Moeda</span>
                    <span class="item-font">{{cob.swift}} </span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Valor</span>
                    <span class="item-font">{{cob.usdFormatado}}</span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Saldo</span>
                    <span class="item-font">{{cob.saldoFormatado}}</span>
                  </div>
                  
              </div>
              </div>
    </div>

    <div class="div" *ngIf="creditArrayImport !== null ">
            <div class="datatable" id="importacao-cartacredito"  *ngFor="let credit of creditArrayImport">
              <div class="table-title">
                <span class="title-font"> Exportador: {{credit.nome_contraparte}}</span>
              </div>
                <div class="table-row">
                    <div class="table-item">
                      <span class="item-font-gray">Ref. banco</span>
                      <span class="item-font">{{credit.num_contrato}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Data de abertura</span>
                      <span class="item-font">{{credit.dt_op}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Data de embarque</span>
                      <span class="item-font">{{credit.dt_liq_or}}</span>
                    </div>
                    
                </div>
                <div class="table-row">
                  <div class="table-item" style="width: 35% !important">
                    <span class="item-font-gray">Banco emissor / Banco avisador </span>
                    <span class="item-font">{{credit.nome_banco_avisador}}</span>
                  </div>
                  <div class="table-item" style="width:15% !important;">
                    <span class="item-font-gray">Moeda</span>
                    <span class="item-font">{{credit.swift}}</span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Saldo a utilizar</span>
                    <span class="item-font">{{credit.saldoAutilizarForm}}</span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Saldo utilizado</span>
                    <span class="item-font">{{credit.saldoUtilizadoForm}}</span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Saldo total</span>
                    <span class="item-font">000.000.000,00</span>
                  </div>
                  
              </div>
              </div>
    </div>
          

    <div class="div"  *ngIf="accAceArray !== null ">
            <div class="datatable" id="exportacao-accace" *ngFor="let acc of accAceArray">
              <div class="table-title">
                <span class="title-font"> Contrato: {{acc.num_contrato}}</span>
              </div>
                <div class="table-row">
                    <div class="table-item">
                      <span class="item-font-gray">Data de contratação</span>
                      <span class="item-font">{{acc.dt_op}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Data de entrega</span>
                      <span class="item-font">{{acc.dt_vencto}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Data de liquidação</span>
                      <span class="item-font">{{acc.dt_vencto2}}</span>
                    </div>
                    
                </div>
                <div class="table-row">
                  <div class="table-item" style="width: 18% !important">
                    <span class="item-font-gray" >Moeda </span>
                    <span class="item-font">{{acc.swift}}</span>
                    <span class="item-font" style="margin-top: 4px;">BRL</span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Valor do contrato</span>
                    <span class="item-font">{{acc.valorContratoUsd}} </span>
                    <span class="item-font" style="margin-top: 4px;">{{acc.valorContratoBrl}}</span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Saldo LAE (ACC)</span>
                    <span class="item-font">{{acc.saldoLaeUsd}}</span>
                    <span class="item-font" style="margin-top: 4px;">{{acc.saldoLaeBrl}}</span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Saldo LE (ACE)</span>
                    <span class="item-font">{{acc.saldoLeBrl}}</span>
                    <span class="item-font" style="margin-top: 4px;">{{acc.saldoLeUsd}}</span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Liquidado</span>
                    <span class="item-font">{{acc.liquidadoUsd}}</span>
                    <span class="item-font" style="margin-top: 4px;">{{acc.liquidadoBrl}}</span>
                  </div>
                  
              </div>
              </div>
    </div>
            

    <div class="div" *ngIf="cobrancaArrayExport !== null ">
            <div class="datatable" id="exportacao-cobranca"  *ngFor="let cob of cobrancaArrayExport">
              <div class="table-title">
                <span class="title-font"> Ref. cliente: {{cob.num_ref}}</span>
              </div>
                <div class="table-row">
                    <div class="table-item">
                      <span class="item-font-gray">Ref. banco</span>
                      <span class="item-font">{{cob.num_contrato}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Contrato ACE</span>
                      <span class="item-font">-</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Data de recepção</span>
                      <span class="item-font">{{cob.dt_op}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Data de vencimento</span>
                      <span class="item-font">{{cob.dt_vencto}}</span>
                    </div>
                    
                </div>
                <div class="table-row">
                  <div class="table-item" style="width: 50% !important">
                    <span class="item-font-gray">Banco importador </span>
                    <span class="item-font">{{cob.nome_importador}}</span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Moeda</span>
                    <span class="item-font">{{cob.swift}} </span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Saldo</span>
                    <span class="item-font">{{cob.saldoFormatado}}</span>
                  </div>       
              </div>
              </div>
    </div>
                
    <div class="div" *ngIf="creditArrayExport !== null ">
            <div class="datatable" id="exportacao-cartacredito"  *ngFor="let credit of creditArrayExport">
              <div class="table-title">
                <span class="title-font"> Carta de crédito: {{credit.cod}}</span>
              </div>
                <div class="table-row">
                    <div class="table-item">
                      <span class="item-font-gray">Ref. banco</span>
                      <span class="item-font">{{credit.num_ref}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Situação</span>
                      <span class="item-font">{{credit.tipo_lc}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Data de remessa docs</span>
                      <span class="item-font">{{credit.dt_me}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Data de vencimento</span>
                      <span class="item-font">{{credit.dt_vencto}}</span>
                    </div>
                    
                </div>
                <div class="table-row">
                  <div class="table-item" style="width: 65% !important">
                    <span class="item-font-gray">Banco emissor / Banco importador </span>
                    <span class="item-font" style="margin-bottom: 2px;">{{credit.nome_banco_emissor}}</span>
                    <span class="item-font">{{credit.nome_importador}}</span>
                  </div>
                  <div class="table-item" style="width: 15% !important">
                    <span class="item-font-gray">Moeda</span>
                    <span class="item-font">{{credit.swift}} </span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Saldo Total</span>
                    <span class="item-font">{{credit.saldoTotal}}</span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Saldo Utilizado</span>
                    <span class="item-font">{{credit.saldoUtilizado}}</span>
                  </div>
                  
              </div>
              <div class="table-row">
                <div class="table-item">
                  <span class="item-font-gray">Saldo disponível</span>
                  <span class="item-font">{{credit.saldoDisponivel}}</span>
                </div>
              </div>
              </div>
    </div>

    <div class="div" *ngIf="paymentArray !== null ">
            <div class="datatable" id="ordem-pgto"  *ngFor="let pay of paymentArray">
              <div class="table-title">
                <span class="title-font"> Ordenante: {{pay.nome_contraparte}}</span>
              </div>
                <div class="table-row">
                    <div class="table-item">
                      <span class="item-font-gray">Ref. banco</span>
                      <span class="item-font">{{pay.num_contrato}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Data de recepção</span>
                      <span class="item-font">{{pay.dt_op}}</span>
                    </div>    
                </div>
                <div class="table-row">
                  <div class="table-item">
                    <span class="item-font-gray">Moeda </span>
                    <span class="item-font">{{pay.swift}} </span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Valor</span>
                    <span class="item-font">{{pay.valorFormatado}} </span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Saldo</span>
                    <span class="item-font">{{pay.saldoFormatado}}</span>
                  </div>   
              </div>
              </div>
    </div>

    <div class="div" *ngIf="issuedArray !== null ">
            <div class="datatable" id="garantias-emitidas"  *ngFor="let issued of issuedArray">
              <div class="table-title">
                <span class="title-font"> Beneficiário: {{issued.nome_beneficiario}}</span>
              </div>
                <div class="table-row">
                    <div class="table-item">
                      <span class="item-font-gray">Ref. banco</span>
                      <span class="item-font">{{issued.num_contrato}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Data de emissão</span>
                      <span class="item-font">{{issued.dt_op}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Data de vencimento</span>
                      <span class="item-font">{{issued.dt_vencto}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Tipo</span>
                      <span class="item-font">{{issued.modalidade | titlecase}}</span>
                    </div>
                    
                </div>
                <div class="table-row">
                  <div class="table-item" style="width: 50% !important">
                    <span class="item-font-gray">Banco avisador </span>
                    <span class="item-font">{{issued.nome_banco_avisador}}</span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Moeda</span>
                    <span class="item-font">{{issued.swift}} </span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Valor</span>
                    <span class="item-font">{{issued.valorFormatado}}</span>
                  </div>    
              </div>
              </div>
    </div>

    <div class="div" *ngIf="receivedArray !== null ">
            <div class="datatable" id="garantias-recebidas"  *ngFor="let received of receivedArray">
              <div class="table-title">
                <span class="title-font"> Ref. banco: {{received.num_contrato}}</span>
              </div>
                <div class="table-row">
                    <div class="table-item">
                      <span class="item-font-gray">Data de recepção</span>
                      <span class="item-font">{{received.dt_op}}</span>
                    </div>
                    <div class="table-item">
                      <span class="item-font-gray">Data de vencimento</span>
                      <span class="item-font">{{received.dt_vencto}}</span>
                    </div>
                    
                </div>
                <div class="table-row">
                  <div class="table-item">
                    <span class="item-font-gray">Moeda</span>
                    <span class="item-font">{{received.moeda}} </span>
                  </div>
                  <div class="table-item">
                    <span class="item-font-gray">Valor</span>
                    <span class="item-font">{{received.valorFormatado}}</span>
                  </div>    
              </div>
              </div>
    </div> -->
</div>