import { Component, Input, OnInit } from '@angular/core';
import * as Util from '@src/shared/util-common';

@Component({
  selector: 'fibra-approval-receipt',
  templateUrl: './approval-receipt.component.html',
  styleUrls: ['./approval-receipt.component.scss'],
})
export class ApprovalReceiptComponent implements OnInit {
  public textReceipt;
  public textDate;
  public dateCompare = false;
  @Input() data;
  public agency;
  public account;
  public agend;

  removeMask = (value) => Util.removeMask(value);
  public generatePDF = (value?) => Util.generatePDF(value);

  constructor() {
    this.textReceipt = 'Comprovante';
    this.textDate = 'Data';
  }

  ngOnInit() {
    this.data = JSON.parse(localStorage.getItem('approval-receipt'));
    this.changeDateCompare(this.data.desc_status);
  }

  ngAfterViewInit() {
    this.generatePDF('transfer-receipt-content');
    localStorage.removeItem('approval-receipt');
  }

  resolveAgency(data) {
    if (data) {
      return data.split('|')[0];
    }
  }

  resolveAccount(data) {
    if (data) {
      return data.split('|')[1];
    }
  }

  changeDateCompare(compare?) {
    if (compare === 'AGENDADO') {
      this.textReceipt = 'Comprovante de agendamento';
      this.textDate = 'Data agendada';
      this.agend = true;
    } else {
      this.textReceipt = 'Comprovante';
      this.textDate = 'Data';
      this.agend = false;
    }
  }
}
