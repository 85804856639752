import { Injectable } from '@angular/core';
import { QueryRepository } from '@src/core/repositories/query/query.repository';
import {
  ListQueryRequest,
  instructionListModel,
} from '@src/core/domain/query/query.model';
import { from, Observable } from 'rxjs';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';

// header
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { ListQueryEntity } from '@src/data/repository/query/query-list/query.entity';
import { HttpClient } from '@angular/common/http';
import { InstructionRepository } from '@src/core/repositories/query/instruction.repository';
import { instructionListRepositoryMapper } from './list-instruction-repository-mapper';
import { instructionListEntity } from './instruction.entity';

// environment

@Injectable({
  providedIn: 'root',
})
export class InstructionDataRepository extends InstructionRepository {
  mapper = new instructionListRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getAllQueryInstruction(obj: ListQueryRequest): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = obj;

    return this.http
      .post<instructionListEntity>(
        `${environment.URLBase}v1/instrucoes`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getAuthorizedQueryInstruction(
    obj: ListQueryRequest
  ): Observable<instructionListModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = obj;

  

    return this.http
      .post<instructionListEntity>(
        `${environment.URLBase}v1/instrucoes/aprovados`,
        params,
        httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getPendingQueryInstruction(
    obj: ListQueryRequest
  ): Observable<instructionListModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = obj;

    return this.http
      .post<instructionListEntity>(
        `${environment.URLBase}v1/instrucoes/analise`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getRefusedQueryInstruction(
    obj: ListQueryRequest
  ): Observable<instructionListModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = obj;

  
    return this.http
      .post<instructionListEntity>(
        `${environment.URLBase}v1/instrucoes/recusados`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }
}
