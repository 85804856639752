<div class="confirmtransaction">
    <div class="row">
        <div class="col-sm-12">
            <section class="origin">
                <span style="color: #8F969D;">Para que possamos concluir o pagamento, iremos lhe redirecionar para a instituição: </span><span style="font-weight: bold;">{{consent.client_info.client_name}}</span>
            </section>

            <section>
                <h1 class="group-header">Valor da transação</h1>

                <label class="value">{{currencyStr}} {{consent.payment.amount | number: '1.2-2'}}</label>
            </section>

            <section>
                <h1 class="group-header">Dados do recebedor</h1>

                <div class="caption">Beneficiário</div>    
                <label class="value">{{consent.creditor.name}}</label>
                
                <div class="caption">{{consent.creditor.personType == 'PESSOA_NATURAL' ? 'CPF' : 'CNPJ'}}</div>    
                <label class="value">{{cpfCnpjCreditor}}</label>
            </section>

            <section>
                <h1 class="group-header">Dados do pagamento</h1>

                <div class="caption">Data de pagamento</div>    
                <label class="value">{{consent.payment.date | date: 'dd/MM/yyyy'}}</label>
                
                <div class="caption">Forma do pagamento</div>    
                <label class="value">{{paymentTypeStr}}</label>
            </section>

            <section>
                <h1 class="group-header">Conta para débito</h1>

                <div class="caption">Conta</div>    
                <label class="value">{{consent.selectedAccount.desc_conta}}</label>
                
                <div class="caption">Dados da conta</div>    
                <label class="value">AG: {{consent.selectedAccount.cod_agencia}} | CC: {{consent.selectedAccount.num_conta}}</label>
            </section>

            <section *ngIf="!unicoAprovador" class="autorizacao">
                <h1 class="group-header">Autorização</h1>

                <label class="value">Identificamos que será necessária uma ou mais autorizações para concluir a transação.</label>
            </section>

        </div>        
    </div>
    <div class="row buttons">
        <div class="col-sm-12">
            <button type="button" class="continue" (click)="ok()">{{busy ? '' : 'Confirmar pagamento'}}
                <div *ngIf="busy" class="spinner-border continue" role="status"></div>
            </button>
            <button type="button" class="cancel" (click)="cancel()">
                Voltar
            </button>            
        </div>
    </div>
</div>