<div class="form-row personal-information">
  <form [formGroup]="isDrawer ? drawerStep : formStep1">
    <fibra-section-content description="{{ description }}">
      <fibra-form-control-group
        [formGroupName]="isDrawer ? 'drawer' : 'personal'"
        ngDefaultControl
      >
        <fibra-form-control [style.width.px]="300" label="Tipo de pessoa">
          <div class="fb-box2">
            <div class="fb-box-title">
              <span></span>
            </div>
            <div class="fb-box-options">
              <div class="fb-selector" *ngFor="let item of isDrawer ? optionDrawer : optionPerson">
                <div class="fb-selector-input aqua">
                  <input  class="fibra-radio-custom"
                    type="radio"
                    [id]="isDrawer ? 'drawerRadion': 'personRadion'"
                    [name]="isDrawer ? 'drawerRadion': 'personRadion'"
                    [value]="item.value"
                    (change)="isDrawer ? setDrawerPhysical(item.value, 'Novo') : setPersonPhysical(item.value, 'Novo')"
                    [checked]="isDrawer ? verificaPessoaDrawer === item.value : verificaPessoa === item.value"
                  />
                  <label
                    class="fibra-radio-group fibra-radio"
                    [for]="item.option"
                  ></label>
                </div>
                <div class="fb-label">
                  <span>{{ item.option }} </span>
                </div>
              </div>
            </div>
          </div>

          <!-- <fibra-radiobutton
            [name]="isDrawer ? 'drawerRadion' : 'personalRadion'"
            (selected)="isDrawer ? setDrawerPhysical($event) :setPersonPhysical($event)"
            [option]="isDrawer ? optionDrawer : optionPerson"
            [check]="'F'"
          ></fibra-radiobutton> -->
        </fibra-form-control>
        
        <fibra-form-control [style.width.px]="207" label="CPF ou CNPJ">
          <div class="control-data">
            <input *ngIf="!isDrawer"
              formControlName="cpfCnpj"
              type="text"
              [style.width.px]="207"
              placeholder="CPF ou CNPJ"
              [textMask]="!isDrawer && verificaPessoa === 'F'
              ? {
                  mask: cpfMask,
                  placeholderChar: '_',
                  guide: false,
                  modelClean: true
                }
              : {
                  mask: cnpjMask,
                  placeholderChar: '_',
                  guide: false,
                  modelClean: true
                }
              "
            />

            <input *ngIf="isDrawer"
              formControlName="cpfCnpj"
              type="text"
              [style.width.px]="207"
              placeholder="CPF ou CNPJ"
              [textMask]="isDrawer && verificaPessoaDrawer === 'F'
              ? {
                  mask: cpfMask,
                  placeholderChar: '_',
                  guide: false,
                  modelClean: true
                }
              : {
                  mask: cnpjMask,
                  placeholderChar: '_',
                  guide: false,
                  modelClean: true
                }
              "
            />
            <div
              class="d-flex justify-content-center position"
              *ngIf="loadingCPF"
              style="margin: -27px -172px 0 0"
            >
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <ng-container
              *ngIf="!docValid && personControlIsInvalid('cpfCnpj')"
            >
              <small class="text-danger">
                Deve ser um
                {{ isPersonPhysical ? "CPF" : "CNPJ" }} válido.
              </small>
            </ng-container>
            <ng-container
              *ngIf="!docValid && drawerControlIsInvalid('cpfCnpj')"
            >
              <small class="text-danger">
                Deve ser um
                {{ isPersonPhysical ? "CPF" : "CNPJ" }} válido.
              </small>
            </ng-container>
          </div>
        </fibra-form-control>
        <fibra-form-control [style.max-width.px]="590">
          <label class="nome-label">{{isDrawer ? 'Nome do sacador' : 'Nome do pagador'}}</label>
          <div class="control-data">
            <input
              type='text'
              id="draweeName"
              pattern='^[ 0-9a-zA-Zàèìòùáéíóúâêîôûãõç\b./\-&]+$'
              formControlName="draweeName"
              [style.width.%]="100"
              placeholder="Informar o nome"
              (change)="setDraweeName($event)"
            />
            <ng-container *ngIf="personControlIsInvalid('draweeName')">
              <small
                class="text-danger"
                *ngIf="personalControlFirstError('draweeName').required"
              >
                Informe o nome corretamente.
              </small>
            </ng-container>
            <ng-container *ngIf="drawerControlIsInvalid('draweeName')">
              <small
                class="text-danger"
                *ngIf="drawerControlFirstError('draweeName').required"
              >
                Informe o nome corretamente.
              </small>
            </ng-container>
            <ng-container *ngIf="personControlIsInvalid('draweeName')">
              <small
                class="text-danger"
                *ngIf="!personalControlFirstError('draweeName').required"
              >
                Informe o nome corretamente.
              </small>
            </ng-container>
            <ng-container *ngIf="drawerControlIsInvalid('draweeName')">
              <small
                class="text-danger"
                *ngIf="!drawerControlFirstError('draweeName').required"
              >
                Informe o nome corretamente.
              </small>
            </ng-container>
          </div>
        </fibra-form-control>
      </fibra-form-control-group>
    </fibra-section-content>

    <div class="divisor"></div>

    <fibra-section-content description="Endereço">
      <fibra-form-control-group
        [formGroupName]="isDrawer ? 'drawerAddress' : 'address'"
      >
        <fibra-form-control [style.width.px]="155" label="CEP">
          <div class="control-data">
            <input
              id="cep"
              type='text'
              maxlength="9"
              [textMask]="{
                mask: cepMask,
                placeholderChar: '_',
                guide: false,
                modelClean: true
              }"
              formControlName="cep"
              [style.width.px]="155"
              placeholder="00000-000"
              (change)="setAddress($event.target.value, '', '', undefined)"
              required
            />
            <div
              class="d-flex justify-content-center position"
              *ngIf="loadingAddress"
              style="margin: -27px -122px 11px 0"
            >
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <ng-container *ngIf="addressControlIsInvalid('cep') || !isCepValid">
              <small
                class="text-danger"
                *ngIf="
                  addressControlFirstError('cep').required ||
                  addressControlFirstError('cep').cep
                "
              >
                O CEP deve ser informado.
              </small>

              <small
                class="text-warning"
                *ngIf="!isCepValid && !addressControlFirstError('cep').required"
              >
                Endereço não encontrado.
              </small>
            </ng-container>
            <ng-container *ngIf="addressDrawerControlIsInvalid('cep') || !isCepValid">
              <small
                class="text-danger"
                *ngIf="
                addressDrawerControlFirstError('cep').required ||
                addressDrawerControlFirstError('cep').cep
                "
              >
                O CEP deve ser informado.
              </small>

              <small
                class="text-warning"
                *ngIf="!isCepValid && !addressDrawerControlFirstError('cep').required"
              >
                Endereço não encontrado.
              </small>
            </ng-container>
          </div>
        </fibra-form-control>

        <fibra-form-control [style.width.px]="401" label="Logradouro">
          <div class="control-data">
            <fibra-input
              formControlName="street"
              [style.width.px]="401"
              placeholder="Informar logradouro"
            ></fibra-input>
            <ng-container *ngIf="addressControlIsInvalid('street')">
              <small
                class="text-danger"
                *ngIf="addressControlFirstError('street').required"
              >
                Informe seu logradouro.
              </small>
            </ng-container>
            <ng-container *ngIf="addressDrawerControlIsInvalid('street')">
              <small
                class="text-danger"
                *ngIf="addressDrawerControlFirstError('street').required"
              >
                Informe seu logradouro.
              </small>
            </ng-container>
          </div>
        </fibra-form-control>
        <fibra-form-control [style.width.px]="76" label="Número">
          <div class="control-data">
            <fibra-input
              id="number"
              onkeypress="return (!(event.charCode >= 33 && event.charCode <= 44) && !(event.charCode >= 58 && event.charCode <= 64) && !(event.charCode >= 128 && event.charCode <= 187))"
              formControlName="number"
              pattern='^[ 0-9SsNn\b/]+$'
              [style.width.px]="76"
            ></fibra-input>
            <ng-container *ngIf="addressControlIsInvalid('number')">
              <small
                class="text-danger"
                *ngIf="addressControlFirstError('number').required"
              >
                Informar Nº 
              </small>
            </ng-container>
            <ng-container *ngIf="addressDrawerControlIsInvalid('number')">
              <small
                class="text-danger"
                *ngIf="addressDrawerControlFirstError('number').required"
              >
                Informar Nº
              </small>
            </ng-container>
            <ng-container *ngIf="addressControlIsInvalid('number') || addressDrawerControlIsInvalid('number') " >
                <small
                  class="text-danger" *ngIf="!addressControlFirstError('number').required || !addressDrawerControlFirstError('number').required ">
                  Informar Nº válido
                </small>
              </ng-container>
          </div>
        </fibra-form-control>
        <fibra-form-control [style.width.px]="250" label="Complemento">
          <div class="control-data">
            <fibra-input
              formControlName="complement"
              pattern='^[ 0-9a-zA-Zàèìòùáéíóúâêîôûãõç\b./,()]+$'
              onkeypress="return (!(event.charCode >= 33 && event.charCode <= 44) && !(event.charCode >= 58 && event.charCode <= 64) && !(event.charCode >= 128 && event.charCode <= 187))"
              [style.width.px]="250"
            >
            </fibra-input>
            <ng-container *ngIf="addressControlIsInvalid('complement')">
              <small
                class="text-danger"
              >
                Informar Complemento válido 
              </small>
            </ng-container>
            <ng-container *ngIf="addressDrawerControlIsInvalid('complement')">
              <small
                class="text-danger"
              >
              Informar Complemento válido 
              </small>
            </ng-container>
          </div>
        </fibra-form-control>
      </fibra-form-control-group>

      <div class="mt-3">
        <fibra-form-control-group
          [formGroupName]="isDrawer ? 'drawerAddress' : 'address'"
        >
          <fibra-form-control [style.width.px]="204" label="Bairro">
            <div class="control-data">
              <fibra-input
                formControlName="neighborhood"
                pattern='^[ 0-9a-zA-Zàèìòùáéíóúâêîôûãõç\b./,()]+$'
                onkeypress="return (!(event.charCode >= 33 && event.charCode <= 44) && !(event.charCode >= 58 && event.charCode <= 64) && !(event.charCode >= 128 && event.charCode <= 187))"
                [style.width.px]="204"
                placeholder="Informar bairro"
              >
              </fibra-input>
              <ng-container *ngIf="addressControlIsInvalid('neighborhood')">
                <small
                  class="text-danger"
                  *ngIf="addressControlFirstError('neighborhood').required"
                >
                  Informar seu bairro.
                </small>
              </ng-container>
              <ng-container *ngIf="addressDrawerControlIsInvalid('neighborhood')">
                <small
                  class="text-danger"
                  *ngIf="addressDrawerControlFirstError('neighborhood').required"
                >
                  Informar seu bairro.
                </small>
              </ng-container>
              <ng-container *ngIf="addressControlIsInvalid('neighborhood')">
                <small
                  class="text-danger"
                >
                  Informar bairro válido 
                </small>
              </ng-container>
              <ng-container *ngIf="addressDrawerControlIsInvalid('neighborhood')">
                <small
                  class="text-danger"
                >
                Informar bairro válido 
                </small>
              </ng-container>
            </div>
          </fibra-form-control>
          <fibra-form-control [style.width.px]="151" label="Cidade">
            <div class="control-data">
              <fibra-input
                id="city"
                formControlName="city"
                pattern='^[ a-zA-Zàèìòùáéíóúâêîôûãõç\b./,()]+$'
                onkeypress="return (!(event.charCode >= 33 && event.charCode <= 44)&& !(event.charCode >= 47 && event.charCode <= 58)&& !(event.charCode >= 58 && event.charCode <= 64) && !(event.charCode >= 128 && event.charCode <= 187))"
                [style.width.px]="151"
                placeholder="Informar cidade"
              ></fibra-input>
              <ng-container *ngIf="addressControlIsInvalid('city')">
                <small
                  class="text-danger"
                  *ngIf="addressControlFirstError('city').required"
                >
                  Informar sua Cidade.
                </small>
              </ng-container>
              <ng-container *ngIf="addressDrawerControlIsInvalid('city')">
                <small
                  class="text-danger"
                  *ngIf="addressDrawerControlFirstError('city').required"
                >
                  Informar sua Cidade.
                </small>
              </ng-container>
              <ng-container *ngIf="addressControlIsInvalid('city')">
                <small
                  class="text-danger" *ngIf="!addressControlFirstError('city').required"
                >
                  Informar Cidade válida 
                </small>
              </ng-container>
              <ng-container *ngIf="addressDrawerControlIsInvalid('city')">
                <small *ngIf="!addressDrawerControlFirstError('city').required"
                  class="text-danger"
                >
                Informar Cidade válida 
                </small>
              </ng-container>
            </div>
          </fibra-form-control>
          <fibra-form-control label="Estado" [style.width]="'auto'">
            <fibra-dropdown
              id="state"
              formControlName="state"
              removeShadown="true"
              customClass="select-option"
              [options]="stateList"
            ></fibra-dropdown>
          </fibra-form-control>
        </fibra-form-control-group>
      </div>

      <div class="divisor" *ngIf="divisor"></div>
      <fibra-form-control-group>
        <div class="fibra-radio-outline" *ngIf="guarantor !== undefined">
          <label class="title-label">Possui sacador avalista?</label>
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn btn-outline-primary" [ngClass]="{'active': !guarantor}">
              <input
                type="radio"
                name="options"
                id="option3"
                autocomplete="off"
                value="Não"
                name="guarantor"
                (click)="setGuarantor($event.target.value)"
                [checked]="!guarantor"
              />
              Não
            </label>
            <label class="btn btn-outline-primary" [ngClass]="{'active': guarantor}">
              <input #garantorValue
                type="radio"
                name="options"
                id="option2"
                autocomplete="off"
                value="Sim"
                name="guarantor"
                (click)="setGuarantor($event.target.value)"
                [checked]="guarantor"
              />
              Sim
            </label>
          </div>
        </div>
      </fibra-form-control-group>
      <div class="step-component" *ngIf="guarantor">
        <fibra-charge-first-step
          description="Dados do sacador avalista"
          [divisor]="false"
          [guarantor]="undefined"
          [isDrawer]="true"
          (sendDrawer)="
            captureInfoDrawer($event, 'drawer');
            captureInfoDrawerAdress($event, 'drawer')
          "
        ></fibra-charge-first-step>
      </div>
    </fibra-section-content>
    <div class="col-12 buttons" *ngIf="submit">
      <div class="description">
        <div class="step-buttons-container offset-9">
          <button [hidden]="guarantor"
            type="button"
            style="float: right;"
            class="secondary-button next"
            [disabled]="!addressForm.valid || !personalForm.valid || this.btnAvancar === true "
          > Avançar
          </button>
          <button [hidden]="!guarantor"
          type="button"
          style="float: right;"
          class="secondary-button next"
          [disabled]="disabledAvalista()"
        > Avançar
        </button>
          </div>
        </div>
      </div>
      
  </form>
</div>
<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
