import { Component,  Input, OnInit, EventEmitter, Output, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { toTitleCase } from 'codelyzer/util/utils';
import { isArray } from 'util';
import { periods, TabsDataShare, TabsOnChange } from '@src/data/repository/data-share-repository/index';
import { optionTypeTransfer, optionTypeReceipt, titleValues } from '@src/data/repository/data-share-repository';
import { TransactionsDataShare } from '@src/core/usecases/data-share/transactions-data-share.service';
import { DropdownList } from '@src/core/domain/business-models';
import { GetAllAccountTypeUsecase } from '@src/core/usecases/account/get-all-accountTypes.usecase';
import { RolesService } from '@src/shared/services/roles.service';
import { Subscription } from 'rxjs';
import { WalletTypeRepository } from '@src/core/repositories/wallet/wallet-type.repository';

@Component({
  selector: 'fibra-filter-cobranca',
  templateUrl: './filter-cobranca.component.html',
  styleUrls: ['./filter-cobranca.component.scss'],
})
export class FilterCobrancaComponent implements OnInit, OnDestroy {
  private _contasHeader: [] = null;
  private _currentWallet: any = null;
  private _subTabs: Subscription;

  @ViewChild('datepicker', { static: true }) datepickerRef: NgbInputDatepicker;
  createDefaultDate: NgbDate = new NgbDate(2000, 1, 1);

  @Input() inputFilter;
  @Input() default;
  @Input() approval;
  @Input() receipt;
  @Input() isSchedules;
  @Input() approvalTransaction: boolean;
  @Input() abaTransacionais = false;
  @Input() isFrancesinha: boolean = false;
  @Input() isSegundaViaBoleto: boolean = false;
  @Input() isTransit: boolean = false;
  @Input() isNotCessao: boolean = false;
  @Input() isAdcInstrucao: boolean = false;
  @Input() isTransitAprovados: boolean = false;
  @Input() minDayCalendar: number = null;
  @Input() abaSelected = '';
  @Input() abaSelectedPosicaoCarteira = '';

  public showModalPeriodDatepicker = false;
  public titleValues = titleValues;
  public filter: FormGroup;
  public filterInput: FormGroup;
  public optionsProfile: DropdownList;
  public optionTypeTransfer = optionTypeTransfer;
  public optionTypeReceipt = optionTypeReceipt;
  public periods;
  public periodDays;
  public dataInicio;
  public dataIn;
  public dataFinal
  public dataFim;
  public period;
  public periodDate;
  public date = new Date();
  public type;
  public account;
  public cod_agencia;
  public wallets;
  public ourNumber;
  public yourNumber;
  public titleValue;
  public valorTitulo;
  public draweeName;
  public periodCalendar: any;
  public optionPeriod = [7, 15, 30, 90];
  public dataRequest = 7;
  public yesterday: NgbDate;
  public today: NgbDate;
  public hoje = new Date();
  public todayDate;
  public maxDay: NgbDate;
  public minDay: NgbDate;
  public dayMax: NgbDate;
  public dayMaxMinUndefined: NgbDate;
  public selected = 'Todas';
  public calendarActive = {
    active: false,
    dataStart: null,
    dataEnd: null,
  };
  public x = 0;
  public isGreaterThan730Days: boolean;
  public diasPermitidosAqruisicao: number = 90;
  public descriptionDateShow: boolean = false;
  filterDate
  public dataDe = [
    { name: 'Emissão', value: 'emissao' },
    { name: 'Envio', value: 'envio' }
  ];
  public tipoData;

  public dataDePosicao= [
    { name: 'Emissão', value: 'emissao' },
    { name: 'Movimento', value: 'movimento' }
  ];
  public tipoDataPosicao;

  @Output() emitFilter: EventEmitter<any>;
  @Output() emitFilterToast: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<{ from: NgbDate; to: NgbDate }>;

  constructor(
    public tab: TabsDataShare,
    private formBuild: FormBuilder,
    private getAllAccountTypes: GetAllAccountTypeUsecase,
    private transactionsDataShare: TransactionsDataShare,
    private WalletTypeDataRepository: WalletTypeRepository,
    private calendar: NgbCalendar,
    private rolesService: RolesService,
    private router: Router,
    private _tabsOnChange: TabsOnChange
  ) {
    this.filter = this.formBuild.group({
      wallet: [''],
      account: [''],
      tipoData: [''],
      tipoDataPosicao: [''],
      type: [''],
    });
    this.filterInput = this.formBuild.group({
      ourNumber: [''],
      yourNumber: [''],
      draweeName: [''],
      titleValue: [''],
      valorTitulo: [null]
    });

    this.filterInput.get('valorTitulo').valueChanges.subscribe(v => this.setValorTitulo(v));

    this.optionsProfile = [];
    this.periods = periods;
    this.emitFilter = new EventEmitter();
    this.today = calendar.getToday();
    this.maxDay = calendar.getNext(this.today, 'd', 180);
    this.yesterday = calendar.getPrev(this.today, 'd', 1);
    //this.minDay = calendar.getPrev(this.today, 'd', 730);
  }

  ngOnInit() {
    this.filterDate = this.today;
    this.descriptionShow();

    this.getAllWallet();

    this.changeMinDayCalendar();
    this.requestAccount();
    this.changePeriod(this.dataRequest);
    this.verifyMaxDay();
    this.getDateToday();

    this._subTabs = this._tabsOnChange.onTabChange.subscribe(t => {
      if (t) {
        this.abaSelected = t;
        this.dataRequest = 7;
        this.changePeriod(this.dataRequest);
        this.changeWallet(this._currentWallet);
      }
    })
  }

  ngOnDestroy(): void {
    if (this._subTabs) {
      this._subTabs.unsubscribe();
    }
  }

  getDateToday() {
    this.todayDate = moment(this.hoje).format('DD/MM/YYYY');
    console.log(this.todayDate);
  }

  public getAllWallet() {

    this.WalletTypeDataRepository.getWalletTypes().subscribe((res: any) => {

      if (isArray(res.data)) {
        this._contasHeader = res.data.map((e, _i) => e);

        this.changeWallet('');
      }
    });
  }

  private filtraCarteirasPorAcesso(contasHeader: Array<any>) {
    let roleObject = this.rolesService.getRoles();

    const result = [];

    contasHeader.forEach(c => {

      switch (this.abaSelected) {

        case "archive-list":

          roleObject.forEach(r => {

            if (r.Name == "COB.CONS_ARQ_REM" && (r.NumeroConta == c.numeroConta || r.NumeroConta == null)) {
              result.push({
                name: c.descricaoProduto,
                value: c.codEspecie + "," + c.codProduto + "," + c.numOperacao + "," + c.ctaHeader,
              });
            }
            return;
          }); break;
        case "query-transit":
          roleObject.forEach(r => {
            if (r.Name == "COB.CONS_ARQ_TRANS" && (r.NumeroConta == c.numeroConta || r.NumeroConta == null)) {
              result.push({
                name: c.descricaoProduto,
                value: c.codEspecie + "," + c.codProduto + "," + c.numOperacao + "," + c.ctaHeader,
              });
            }
            return;
          }); break;
        case "query-posicao-carteira":
          roleObject.forEach(r => {
            if (r.Name == "COB.CONS_POS_CART" && (r.NumeroConta == c.numeroConta || r.NumeroConta == null)) {
              result.push({
                name: c.descricaoProduto,
                value: c.codEspecie + "," + c.codProduto + "," + c.numOperacao + "," + c.ctaHeader,
              });
            }
            return;
          }); break;
        case "query-francesinha":
          roleObject.forEach(r => {
            if (r.Name == "COB.CONS_FRANC" && (r.NumeroConta == c.numeroConta || r.NumeroConta == null)) {
              result.push({
                name: c.descricaoProduto,
                value: c.codEspecie + "," + c.codProduto + "," + c.numOperacao + "," + c.ctaHeader,
              });
            }
            return;
          }); break;
        case "bill-list":
          roleObject.forEach(r => {
            if (r.Name == "COB.GER_SEG_BOL" && (r.NumeroConta == c.numeroConta || r.NumeroConta == null)) {
              result.push({
                name: c.descricaoProduto,
                value: c.codEspecie + "," + c.codProduto + "," + c.numOperacao + "," + c.ctaHeader,
              });
            }
            return;
          }); break;
        default:
          result.push({
            name: c.descricaoProduto,
            value: c.codEspecie,
          });
      }
    });

    return result;
    // case "query-posicao-carteira": this.diasPermitidosAqruisicao = 365; break;
    // case "query-transit": this.diasPermitidosAqruisicao = 90; break;
    // default: this.diasPermitidosAqruisicao = 90; break;

  }

  public getOptionWalletDistinctByName(carteiras: Array<any>): any {
    let result = []

    carteiras.forEach(item => {
      let duplicate = result.findIndex(_item => {
        return item.name == _item.name
      }) > -1

      if (!duplicate) {
        result.push(item)
      }
    });

    return result;
  }

  private getValidWallets(): any {
    let result = [{ name: 'Todas', value: '' }];

    if (!this._contasHeader) {
      return result;
    }

    result = [...result, ...this.filtraCarteirasPorAcesso(this._contasHeader)];

    return this.getOptionWalletDistinctByName(result);
  }

  public getOptionWallet(): { name: string, value: string }[] {
    let result = this.getValidWallets();

    if (this.isNotCessao) {
      result = result.filter(wallet =>
        !wallet.name.toLowerCase().includes("cessão") && !wallet.name.toLowerCase().includes("cessao")
      )
    }

    if (this.abaTransacionais) {
      result = result.filter(wallet => wallet.value != 'DES' || wallet.value != 'desconto');
    }

    return result;
  }

  selectDate(date: NgbDate) {
    this.filterDate = date;
    this.sendOptions();
  }

  public sendOptionsExtract() {
    this.tab.setValue({
      periodDays: this.periodDays,
      periodCalendar: this.periodCalendar,
    });
  }

  public initSchedules() {
    this.isSchedules = true;
    this.changePeriod(7);
  }

  public initAll() {
    this.isSchedules = false;
    this.changePeriod(7);
  }

  private requestAccount(): void {
    this.getAllAccountTypes
      .execute(null)
      .subscribe(this.requestSuccess, this.requestError);
  }

  private formatAccount = (account): string => {
    const desc = toTitleCase(account.exibicao);
    return `${account.num_conta} - ${desc}`;
  }

  private requestSuccess = (value) => {

    this.x = 0;
    this.optionsProfile = [];

    if (isArray(value.data)) {
      value.data.map((e, i) => {
        this.CarregaContaPorAcesso(e.num_indice, e, e.num_conta, e.cod_modalidade, e.cod_agencia);

        // this.optionsProfile.push({
        //   indice: e.num_indice,
        //   name: this.formatAccount(e),
        //   value: e.num_conta,
        //   modality_account: e.cod_modalidade,
        //   selected: false,
        // });
      });
      this.optionsProfile.unshift({
        indice: this.x++,
        name: 'Todas',
        value: '',
        modality_account: '',
        selected: true,
      });
    } else {
      // this.optionsProfile.push({
      //   indice: value.data.num_indice,
      //   name: this.formatAccount(value.data),
      //   value: value.data.num_conta,
      //   modality_account: value.data.cod_modalidade,
      //   selected: true,
      // });
    }
    if (this.optionsProfile.length === 1) {
      this.selected = this.optionsProfile[0].name;
    }
    this.requestComplete();
  }

  public CarregaContaPorAcesso(num_indice: any, name:any, num_conta: any, cod_modalidade: any, cod_agencia: any ) {
    let roleObject = this.rolesService.getRoles();
    this.cod_agencia = cod_agencia;

    switch (this.abaSelected) {

      case "archive-list":
        roleObject.forEach(r => {
          if ((r.Name == "COB.CONS_ARQ_REM") &&
            (r.NumeroConta == num_conta || r.NumeroConta == null)) {
            this.optionsProfile.push({
              indice: num_indice,
              name: this.formatAccount(name),
              value: num_conta,
              modality_account: cod_modalidade,
              selected: false,
            });

            this.x = num_indice;
          }
          return;
        }); break;
      case "query-transit":
        roleObject.forEach(r => {
          if (r.Name == "COB.CONS_ARQ_TRANS" &&
            (r.NumeroConta == num_conta || r.NumeroConta == null)) {
            this.optionsProfile.push({
              indice: num_indice,
              name: this.formatAccount(name),
              value: num_conta,
              modality_account: cod_modalidade,
              selected: false,
            });

            this.x = num_indice;
          }
          return;
        }); break;
      case "query-posicao-carteira":
        roleObject.forEach(r => {
          if (r.Name == "COB.CONS_POS_CART" &&
            (r.NumeroConta == num_conta || r.NumeroConta == null)) {
            this.optionsProfile.push({
              indice: num_indice,
              name: this.formatAccount(name),
              value: num_conta,
              modality_account: cod_modalidade,
              selected: false,
            });

            this.x = num_indice;
          }
          return;
        }); break;
      case "query-francesinha":
        roleObject.forEach(r => {
          if (r.Name == "COB.CONS_FRANC" &&
            (r.NumeroConta == num_conta || r.NumeroConta == null)) {
            this.optionsProfile.push({
              indice: num_indice,
              name: this.formatAccount(name),
              value: num_conta,
              modality_account: cod_modalidade,
              selected: false,
            });

            this.x = num_indice;
          }
          return;
        }); break;
      case "bill-list":
        roleObject.forEach(r => {
          if (r.Name == "COB.GER_SEG_BOL" &&
            (r.NumeroConta == num_conta || r.NumeroConta == null)) {
            this.optionsProfile.push({
              indice: num_indice,
              name: this.formatAccount(name),
              value: num_conta,
              modality_account: cod_modalidade,
              selected: false,
            });

            this.x = num_indice;
          }
          return;
        }); break;
      default:
        this.optionsProfile.push({
          indice: num_indice,
          name: this.formatAccount(name),
          value: num_conta,
          modality_account: cod_modalidade,
          selected: false,
        });

        this.x = num_indice;
    }
  }

  public async sendOptions() {
    let arrayParamWallet: ParamCarteira[] = new Array()
    let cont = 0;

    if (this.wallets && this.wallets.length) {
      this.wallets.forEach(e => {
        let quebrado = e.split(",");

        let paramCarteira: ParamCarteira;
        paramCarteira = new ParamCarteira();

        paramCarteira.CodEspecie = quebrado[0];
        paramCarteira.CodProduto = quebrado[1];
        paramCarteira.NumOperacao = quebrado[2];
        paramCarteira.CtaHeader = quebrado[3];

        arrayParamWallet.push(paramCarteira);
      });

      if (this.ourNumber || this.yourNumber || this.draweeName || this.titleValue || this.valorTitulo) {
        this.emitFilter.emit({
          account: await this.account,
          cod_agencia: await this.cod_agencia,
          dt_Inicio: await this.dataInicio,
          dt_Final: await this.dataFim,
          // codigo_especie: await this.wallet,
          draweeName: (await this.draweeName) || '',
          yourNumber: (await this.yourNumber) || '',
          ourNumber: (await this.ourNumber) || '',
          titleValue: (await this.titleValue) || '',
          valorTitulo: (await this.valorTitulo) || '',
          type: (await this.type) || '',
          periodCalendar: this.periodCalendar || '',
          param_carteira: await arrayParamWallet
        });
        return;
      } else if (this.isFrancesinha && this.account !== undefined) {
        this.emitFilter.emit({
          num_conta: await this.account,
          dt_cadastro: await this.formatNgbDate(this.filterDate),
          dt_Inicio: await this.dataInicio,
          dt_Final: await this.dataFim,
          // codigo_especie: await this.wallet,
          param_carteira: await arrayParamWallet
        });
        return;
      } else if (this.account !== undefined) {
        this.emitFilter.emit({
          account: await this.account,
          dt_Inicio: await this.dataInicio,
          dt_Final: await this.dataFim,
          periodCalendar: this.periodCalendar || '',
          cod_agencia: await this.cod_agencia,
          // codigo_especie: await this.wallet,
          param_carteira: await arrayParamWallet,
          tipo_data: await this.tipoData,
          tipo_data_posicao: await this.tipoDataPosicao
        });
      }
    }
  }

  private async requestComplete() {
    this.account = await this.optionsProfile[0].value;
    this.sendOptions();
  }

  private requestError = (err) => {
    if (err && err.status === 401) {
      console.log(err);
    }
  };

  public changeWallet(option) {

    this._currentWallet = option;

    var listParamsCarteira: string[] = new Array()

    if (option != undefined && option != null) {
      if (option == '') {
        const aux = this.getValidWallets();

        aux.forEach(wallet => {
          if (wallet.value != '') {
            listParamsCarteira.push(wallet.value)
          }
        });

        this.wallets = listParamsCarteira;
      } else {
        listParamsCarteira.push(option)

        this.wallets = listParamsCarteira;
      }
    } else {
      this.wallets = [];
    }

    this.sendOptions();
    this.sendOptionsExtract();
  }

  uniqueFilter(value, index, self) {
    return self.indexOf(value) === index;
  }

  public changeType(option) {
    this.type = option;
    this.filter.patchValue({
      type: option,
    });
    this.transactionsDataShare.setValue({
      type: option,
    });
  }

  public changeMinDayCalendar() {
    if (this.minDayCalendar) {
      this.minDay = this.calendar.getPrev(this.today, 'd', this.minDayCalendar);
    }
  }

  public setDraweeName(option) {
    this.draweeName = option;
    this.filterInput.patchValue({
      draweeName: option,
    });
  }

  public setYourNumber(option) {
    this.yourNumber = option;
    this.filterInput.patchValue({
      yourNumber: option,
    });
  }

  public setOurNumber(option) {
    this.ourNumber = option;
    this.filterInput.patchValue({
      ourNumber: option,
    });
  }

  public setValueTitle(option) {
    this.titleValue = option;
    this.filterInput.patchValue({
      titleValue: option,
    });
    this.sendOptions();
    this.sendOptionsExtract();
  }

  public setValorTitulo(option) {
    this.valorTitulo = option;
  }

  sendInfoFilter() {
    this.sendOptions();
    this.sendOptionsExtract();
  }

  public changeAccount(option) {
    this.account = option;
    this.filter.patchValue({
      account: option,
    });
    this.sendOptions();
    this.sendOptionsExtract();
  }

  
  public changeTipoData(option) {
    this.tipoData = option;
  }

  public formatDate(isSchedules?) {
    if (isSchedules) {
      let initialDate;
      let finalDate;
      this.period = this.periodDays;
      this.dataInicio = this.date.toISOString().slice(0, 10);
      initialDate = new Date(this.date);
      this.periodDate = initialDate.setDate(this.date.getDate() + this.period);
      finalDate = new Date(this.periodDate);
      this.dataFim = finalDate.toISOString().slice(0, 10);
      // this.sendOptions();
      // this.sendOptionsExtract();
      return;
    }

    this.period = this.periodDays;
    this.dataFim = this.date.toISOString().slice(0, 10);
    const auxDate = new Date(this.date);
    this.periodDate = auxDate.setDate(this.date.getDate() - this.period);
    const endDate = new Date(this.periodDate);
    this.dataInicio = endDate.toISOString().slice(0, 10);
  }

  public changePeriod(days: number) {
    this.periodDays = days;
    this.dataRequest = days;
    this.periodCalendar = undefined;
    if (this.isSchedules) {
      this.formatDate(this.isSchedules);
      this.sendOptions();
      return;
    }
    this.formatDate();
    this.sendOptions();
    this.sendOptionsExtract();
  }

  public closeEvent(close: any) {
    this.showModalPeriodDatepicker = false;
  }

  public changePeriodDate(period: { to: NgbDate; from: NgbDate }) {
    this.showModalPeriodDatepicker = false;
    this.dataRequest = null;
    this.dataInicio = `${period.from.year}-${this.adjustDate(
      period.from.month
    )}-${this.adjustDate(period.from.day)}`;

    this.dataFim = `${period.to.year}-${this.adjustDate(
      period.to.month
    )}-${this.adjustDate(period.to.day)}`;
    this.periodCalendar = this.convertPeriodCalendar(period);
    const dateTo = moment(this.dataFim);
    const dateFrom = moment(this.dataInicio);
    const today = moment(moment().format('YYYY-MM-DD'));
    const diffDays = dateTo.diff(dateFrom, 'days');
    this.isGreaterThan730Days = diffDays > 730 && true;
    /*if (this.isGreaterThan730Days) {
      this.emitFilterToast.emit('isGreaterThan730Days');
      return;
    }  else if (
      today.diff(dateFrom, 'days') <= 0 &&
      today.diff(dateTo, 'days') < -180
    ) {
      this.emitFilterToast.emit('outOfReach');
      return;
    } */

    switch (this.abaSelected) {
      case "query-posicao-carteira": this.diasPermitidosAqruisicao = 365; break;
      case "query-transit": this.diasPermitidosAqruisicao = 90; break;
      default: this.diasPermitidosAqruisicao = 90; break;
    }


    if (this.abaTransacionais) {

      if (diffDays <= this.diasPermitidosAqruisicao) {

        this.dataIn = moment(this.periodCalendar.dataInicio)
          .locale('pt-br')
          .format('DD MMM/yyyy');

        this.dataFinal = moment(this.periodCalendar.dataFinal)
          .locale('pt-br')
          .format('DD MMM/yyyy');
        this.sendOptions();
        return;
      } else {
        switch (this.diasPermitidosAqruisicao) {
          case 90: this.emitFilterToast.emit('isGreaterThan90Days'); break;
          case 365: this.emitFilterToast.emit('isGreaterThan365Days'); break;
          default: this.emitFilterToast.emit('isGreaterThan90Days'); break;
        }
        return;
      }
    }

    this.dataIn = moment(this.periodCalendar.dataInicio)
      .locale('pt-br')
      .format('DD MMM/yyyy');
    this.dataFinal = moment(this.periodCalendar.dataFinal)
      .locale('pt-br')
      .format('DD MMM/yyyy');
    this.sendOptions();
    //this.sendOptionsExtract();
  }

  public formatNgbDate(date: NgbDate) {
    return `${date.year}-${date.month}-${date.day}`
  }

  private convertPeriodCalendar(period) {
    return {
      dataInicio: `${period.from.year}-${this.adjustDate(
        period.from.month
      )}-${this.adjustDate(period.from.day)}`,
      dataFinal: `${period.to.year}-${this.adjustDate(
        period.to.month
      )}-${this.adjustDate(period.to.day)}`,
    };
  }

  private adjustDate(date) {
    if (Number(date) < 10) {
      return `0${date}`;
    } else {
      return date;
    }
  }

  verifyMaxDay() {
    if (this.abaSelected == "query-posicao-carteira") {
      /* switch (this.abaSelectedPosicaoCarteira) {
        case "posicao-carteiran-list-paid":
        this.dayMax = this.dayMaxMinUndefined;
        this.minDay = this.dayMaxMinUndefined; 
         break;
        case "posicao-carteira-list-overdue": 
        this.minDay = this.dayMaxMinUndefined;
        this.dayMax = this.yesterday; 
        break;
        case "posicao-carteira-list-downloaded": 
        this.dayMax = this.dayMaxMinUndefined;
        this.minDay = this.dayMaxMinUndefined; 
        break;
        case "posicao-carteira-list-expiring": 
        this.minDay = this.today; 
        this.maxDay = this.dayMaxMinUndefined;
        this.dayMax = this.dayMaxMinUndefined;
        break;
        default:  */
      this.dayMax = this.dayMaxMinUndefined;
      this.minDay = this.dayMaxMinUndefined;
      // break;
      // } 
    } else if (this.abaSelected == "archive-list") {
      this.minDay = this.dayMaxMinUndefined;
      this.dayMax = this.today;
    }
    else if (this.abaSelected == "query-transit") {
      this.minDay = this.dayMaxMinUndefined;
      this.dayMax = this.today;
    }
    /* this.dayMax = this.dayMaxMinUndefined;
    this.minDay = this.dayMaxMinUndefined;  */
  }

  public descriptionShow() {
    if(this.router.url === "/duplicate-bill") {
      this.descriptionDateShow = true;
    }
  }
  public periodModal() {
    this.showModalPeriodDatepicker = !this.showModalPeriodDatepicker;
  }

  public setPeriods() {
    this.dataRequest = 7;
    this.changePeriod(this.dataRequest);
  }

  public changeTipoDataPosicao(option) {
    this.tipoDataPosicao = option;
  }


}

class ParamCarteira {

  CodEspecie: string;
  NumOperacao: string;
  CodProduto: string;
  CtaHeader: string;
}

