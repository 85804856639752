import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PixComprovanteDataShare } from '@src/core/usecases/data-share/pix-comprovante-data-share.service';
import { TabListDataShare } from '@src/core/usecases/data-share/tab-list-data-share.service';
import { TransferReceiptDataRepository } from '@src/data/repository/receipt-repository/receipt.repository';
import * as moment from 'moment';
@Component({
  selector: 'fibra-pix-receipt',
  templateUrl: './pix-receipt.component.html',
  styleUrls: ['./pix-receipt.component.scss']
})
export class PixReceiptComponent implements OnInit {
  public transacaoPendente: boolean = false;
  public transacaoAgendada: boolean = false;
  public infoComprovantes;
  public repeatSchedulle;
  public flg_agendamento;
  constructor(
    public transferComprovante: PixComprovanteDataShare,
    private router: Router,
    private receiptService: TransferReceiptDataRepository,
    private setTab: TabListDataShare
  ) { }

  ngOnInit() {
    this.infoComprovantes = this.transferComprovante.getValue();
    if(this.infoComprovantes && this.infoComprovantes.erroTransfer){
      this.transacaoPendente = true;
    }else{
      this.transacaoPendente = false;
      this.flg_agendamento = this.infoComprovantes.data.obj_transf_pix.agendamento;
      let datePayment = this.infoComprovantes.data.obj_transf_pix.dataPagamento;
      datePayment = datePayment.split('T');
      let status = this.infoComprovantes.data.status;
      this.validationSchedulle(status, datePayment[0]);
    }
  }

  public downloadComprovante(){
    this.receiptService.getPdf(this.infoComprovantes.data.num_compromisso).subscribe((data:any) => {
      var oReq = new XMLHttpRequest();
      oReq.open("GET", data['data'].url, true);
      oReq.responseType = 'blob';
      oReq.onload = function (event) {
        let blob = new Blob([oReq.response], { type: 'application/pdf' });
        var file = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = data['data'].nome_arquivo;
        a.click();
      }
      oReq.send();
    });
  }

  public goBack(){
    localStorage.setItem('view-selecionada', 'transferir-transferir');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    localStorage.removeItem('buttomAcompanharTransacao');
    localStorage.removeItem('labelButtonAcompanharAgendamento');
  }

  public goToView = (route) => this.router.navigateByUrl(route);

  validationSchedulle(status, datePayment) {
    moment().locale('pt-br');
    const checkIfSchedulle = moment(moment()).isBefore(datePayment, 'day');
    if(this.flg_agendamento && this.infoComprovantes.data.obj_transf_pix.idStatusTransferenciaPix != 'AguardandoAprovacaoMaster') {
      this.setTab.setValue('AGENDADO');
      return;
    }

    if(checkIfSchedulle && (status === 1 || status === 5)) {
     this.transacaoAgendada = true;
     this.setTab.setValue('AGENDADO');
     this.repeatSchedulle = localStorage.getItem('labelButtonAcompanharAgendamento');
     return;
    }

    if(status === 4) {
      this.transacaoAgendada = false;
      this.setTab.setValue('PENDENTE');
    }
  }
}
