import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  CpfValidatorDirective,
  CnpjValidatorDirective,
  CpfCnpjValidatorDirective,
  MaskCurrencyDirective,
  PercentageDirective,
  DndDirective,
  OnlyNumberDirective
} from '@src/shared/directives';
import { TooltipModule } from '@src/shared/directives/tooltip';
import { CardsModule } from '@src/shared/components/cards';
import { GenericInfoComponent } from '@src/shared/components/generic-info/web/generic-info.component';
import { BalanceListComponent } from '@src/shared/components/balance-list/balance-list.component';

// pipes
import { MoneyPipe } from '@src/shared/pipes/money/index';
import { CpfCnpjPipe } from '@src/shared/pipes/cpfcnpj/index';
import { AccountPipe } from '@src/shared/pipes/account/index';
import { NegativeValue } from '@src/shared/pipes/negativeValue/index';
import { CepPipe } from '@src/shared/pipes/cep/index';
import { SortByPipe } from '@src/shared/pipes/sortBy/index';
import { BarcodePipe } from '@src/shared/pipes/barcode/index';
import {
  componentDeclarations,
  providerDeclarations,
} from '@src/shared/shared.common';
import { HttpClientModule } from '@angular/common/http';
import { filterBanksPipe } from './pipes/banks';
import { MascaraCPFCNPJDirective } from './directives/mascaraCpfCnpjDirective';
import { HasRoleDirective } from './directives/has-role/has-role.directive';
import { SafeHTMLPipe } from './pipes/safe-html';
import { NotifyControlDirective } from './directives/notify-control.directive';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    TooltipModule,
    CardsModule,
  ],
  declarations: [
    ...componentDeclarations,
    CpfValidatorDirective,
    CnpjValidatorDirective,
    CpfCnpjValidatorDirective,
    MaskCurrencyDirective,
    PercentageDirective,
    DndDirective,
    OnlyNumberDirective,
    HasRoleDirective,
    CepPipe,
    SortByPipe,
    filterBanksPipe,
    MoneyPipe,
    CpfCnpjPipe,
    BarcodePipe,
    AccountPipe,
    NegativeValue,
    GenericInfoComponent,
    BalanceListComponent,
    SafeHTMLPipe,
    NotifyControlDirective
  ],
  exports: [
    ...componentDeclarations,
    CpfValidatorDirective,
    CnpjValidatorDirective,
    CpfCnpjValidatorDirective,
    MaskCurrencyDirective,
    PercentageDirective,
    DndDirective,
    OnlyNumberDirective,
    HasRoleDirective,
    CommonModule,
    FormsModule,
    TooltipModule,
    GenericInfoComponent,
    BalanceListComponent,
    CardsModule,
    CepPipe,
    SortByPipe,
    filterBanksPipe,
    MoneyPipe,
    CpfCnpjPipe,
    BarcodePipe,
    AccountPipe,
    NegativeValue,
    SafeHTMLPipe,
    NotifyControlDirective
  ],
  providers: [...providerDeclarations], 
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule { }
