<div class="content-transferir" *ngIf="!showContent">
    <div *ngIf="restriction == 1">
        <div id="semrestricaocredito" *fibraHasRole="rolesSemRestricao">
            <div class="has-content" *ngIf="!loading && !hasError && !getAccWithNoTerms">
                <div class="title">
                    <span class="span-title" style="font-weight: bold; cursor: pointer;"> Selecione uma conta para continuar </span>
                </div>

                <div class="title" *ngIf="accountsWithPix && accountWithTermApprov.indexOf(true) !== -1">
                    <span class="span-title"> Contas que já usam o Pix</span>
                </div>
                <div class="accounts row">
                    <div *ngFor="let accs of accountsWithPix">
                        <div class="card-account col-3 mx-3" (click)="accs.status === 'APROVADO' ? getIdAndSwitch(accs.conta.num_indice, accs.conta) : selectedAccountNotTerms(accs, true)"
                             *ngIf="accs.status === 'APROVADO'">
                            <span style="cursor: pointer;" class="account-font" id="{{accs.conta.num_indice}}">
                                {{accs.conta.numero}} - {{accs.conta.exibicao}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="title" *ngIf="(accountsWithoutPix.length > 0 || accountWithTermPending.indexOf(true) !== -1) && (!accountsWithPix && accountWithTermApprov.indexOf(true) === -1)">
                    <span class="span-title"> Contas sem adesão ao Pix </span>
                </div>
                <div class="accounts row" *ngIf="!accountsWithPix && accountWithTermApprov.indexOf(true) === -1">

                    <div *ngFor="let accs of accountsWithoutPix">
                        <div class="card-account col-3 mx-3" (click)="isMaster ? openModal(content, accs) : selectedAccountNotTerms(accs, false);"
                             *ngIf="accs.status_aprovacao_adesao === 'PENDENTE' || accs.status_aprovacao_adesao === 'SEM_SOLICITACAO' || accs.status_aprovacao_adesao === 'REPROVADO'">
                            <span style="cursor: pointer;" class="account-font">
                                {{accs.num_conta}} - {{accs.exibicao}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="has-content" *ngIf="!loading && !hasError && getAccWithNoTerms">
                <div class="title" *ngIf="!noData">
                    <span class="span-title" style="font-weight: bold;"> Selecione uma conta continuar </span>
                </div>
                <div class="accounts-div" *ngIf="!noData">

                    <div class="account" *ngFor="let accs of accountsWithNoTerms" (click)="goToTerm(accs.num_indice, accs.num_conta)">
                        <span class="account-font" id="{{accs.num_indice}}"> {{accs.num_conta}} - {{accs.exibicao}} </span>
                    </div>
                </div>
                <div class="nodata-content" *ngIf="noData">
                    <span class="info-data"> Nenhuma conta sem adesão para ser exbida</span>
                </div>
            </div>

            <div class="has-content" *ngIf="loading && !hasError">
                <div class="d-flex justify-content-center position loading-container">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <div class="has-content" *ngIf="hasError && !loading" style="margin-top: 100px;">

                <fibra-generic-info type="error" title="Ocorreu um erro inesperado" message="Não foi possível encontrar contas!">
                </fibra-generic-info>
            </div>
        </div>
        <div id="comrestricaocredito" class="row d-flex justify-content-center alert-terms " *fibraHasRole="rolesComRestricao">
            <div class="col-5">
                <img src="assets/png/icon-pending.png" alt="" class="img-fluid">
                <p class="alert-title">Para acessar o menu de Transferência Pix, entre em contato com o seu gerente de relacionamento.</p>
            </div>
        </div>
    </div>

    <div *ngIf="restriction == 2">
        <div class="has-content" *ngIf="!loading && !hasError && !getAccWithNoTerms">
            <div class="title">
                <span class="span-title" style="font-weight: bold; cursor: pointer;"> Selecione uma conta para continuar </span>
            </div>

            <div class="title" *ngIf="accountsWithPix && accountWithTermApprov.indexOf(true) !== -1">
                <span class="span-title"> Contas que já usam o Pix</span>
            </div>
            <div class="accounts row">
                <div *ngFor="let accs of accountsWithPix">
                    <div class="card-account col-3 mx-3" (click)="accs.status === 'APROVADO' ? getIdAndSwitch(accs.conta.num_indice, accs.conta) : selectedAccountNotTerms(accs, true)"
                        *ngIf="accs.status === 'APROVADO'">
                        <span style="cursor: pointer;" class="account-font" id="{{accs.conta.num_indice}}">
                            {{accs.conta.numero}} - {{accs.conta.exibicao}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="title" *ngIf="accountsWithoutPix.length > 0 || accountWithTermPending.indexOf(true) !== -1">
                <span class="span-title"> Contas sem adesão ao Pix </span>
            </div>
            <div class="accounts row">
                <div *ngFor="let accs of accountsWithoutPix">
                    <div class="card-account col-3 mx-3" (click)="isMaster ? openModal(content, accs) : selectedAccountNotTerms(accs, false);"
                        *ngIf="accs.status_aprovacao_adesao === 'PENDENTE' || accs.status_aprovacao_adesao === 'SEM_SOLICITACAO' || accs.status_aprovacao_adesao === 'REPROVADO'">
                        <span style="cursor: pointer;" class="account-font">
                            {{accs.num_conta}} - {{accs.exibicao}}
                        </span>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="has-content" *ngIf="!loading && !hasError && getAccWithNoTerms">
            <div class="title" *ngIf="!noData">
                <span class="span-title" style="font-weight: bold;"> Selecione uma conta continuar </span>
            </div>
            <div class="accounts-div" *ngIf="!noData">

                <div class="account" *ngFor="let accs of accountsWithNoTerms" (click)="goToTerm(accs.num_indice, accs.num_conta)">
                    <span class="account-font" id="{{accs.num_indice}}"> {{accs.num_conta}} - {{accs.exibicao}} </span>
                </div>
            </div>
            <div class="nodata-content" *ngIf="noData">
                <span class="info-data"> Nenhuma conta sem adesão para ser exbida</span>
            </div>
        </div>

        <div class="has-content" *ngIf="loading && !hasError">
            <div class="d-flex justify-content-center position loading-container">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div class="has-content" *ngIf="hasError && !loading" style="margin-top: 100px;">

            <fibra-generic-info type="error" title="Ocorreu um erro inesperado" message="Não foi possível encontrar contas!">
            </fibra-generic-info>
        </div>
    </div>

    <div *ngIf="restriction == 3">
        <div *fibraHasRole="rolesSemRestricao">
            <div class="has-content" *ngIf="!loading && !hasError && !getAccWithNoTerms">
                <div class="title">
                    <span class="span-title" style="font-weight: bold; cursor: pointer;"> Selecione uma conta para continuar </span>
                </div>

                <div class="title" *ngIf="accountsWithPix && accountWithTermApprov.indexOf(true) !== -1">
                    <span class="span-title"> Contas que já usam o Pix</span>
                </div>
                <div class="accounts row">
                    <div *ngFor="let accs of accountsWithPix">
                        <div class="card-account col-3 mx-3" (click)="accs.status === 'APROVADO' ? getIdAndSwitch(accs.conta.num_indice, accs.conta) : selectedAccountNotTerms(accs, true)"
                             *ngIf="accs.status === 'APROVADO'">
                            <span style="cursor: pointer;" class="account-font" id="{{accs.conta.num_indice}}">
                                {{accs.conta.numero}} - {{accs.conta.exibicao}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="title" *ngIf="accountsWithoutPix.length > 0 || accountWithTermPending.indexOf(true) !== -1">
                    <span class="span-title"> Contas sem adesão ao Pix </span>
                </div>
                <div class="accounts row">
                    <div *ngFor="let accs of accountsWithoutPix">
                        <div class="card-account col-3 mx-3" (click)="isMaster ? openModal(content, accs) : selectedAccountNotTerms(accs, false);"
                             *ngIf="accs.status_aprovacao_adesao === 'PENDENTE' || accs.status_aprovacao_adesao === 'SEM_SOLICITACAO' || accs.status_aprovacao_adesao === 'REPROVADO'">
                            <span style="cursor: pointer;" class="account-font">
                                {{accs.num_conta}} - {{accs.exibicao}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="has-content" *ngIf="!loading && !hasError && getAccWithNoTerms">
                <div class="title" *ngIf="!noData">
                    <span class="span-title" style="font-weight: bold;"> Selecione uma conta continuar </span>
                </div>
                <div class="accounts-div" *ngIf="!noData">

                    <div class="account" *ngFor="let accs of accountsWithNoTerms" (click)="goToTerm(accs.num_indice, accs.num_conta)">
                        <span class="account-font" id="{{accs.num_indice}}"> {{accs.num_conta}} - {{accs.exibicao}} </span>
                    </div>
                </div>
                <div class="nodata-content" *ngIf="noData">
                    <span class="info-data"> Nenhuma conta sem adesão para ser exbida</span>
                </div>
            </div>

            <div class="has-content" *ngIf="loading && !hasError">
                <div class="d-flex justify-content-center position loading-container">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <div class="has-content" *ngIf="hasError && !loading" style="margin-top: 100px;">

                <fibra-generic-info type="error" title="Ocorreu um erro inesperado" message="Não foi possível encontrar contas!">
                </fibra-generic-info>
            </div>
        </div>
        <div class="row d-flex justify-content-center alert-terms " *fibraHasRole="rolesComRestricao">
            <div class="col-5">
                <img src="assets/png/icon-pending.png" alt="" class="img-fluid">
                <p class="alert-title">Para acessar o menu Devolver Pix, entre em contato com o seu gerente de relacionamento.</p>
            </div>
        </div>
    </div>
    
</div>

<div class="modal">
    <ng-template #content let-modal>
        <div class="conteudo-modal">
            <button type="button" class="close" aria-label="Close" (click)="closeModal($event)">
                <span aria-hidden="true">
                    <svg width="22"
                         height="21"
                         viewBox="0 0 22 21"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 20.0583L1.00091 1M21 1.00173L1 20.0583L21 1.00173Z"
                              stroke="#4D86BF"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                    </svg>
                </span>
            </button>
            <div class="generic-info">
                <img alt="iconFibra" src="/assets/png/icon-pending.png" style="width: 29px;height: 29px;" />
                <span class="info-title">Aceite de termos de adesão ao Pix</span>
                <span class="generic-info-message">Para aprovar o cadastro da chave, você precisa aceitar o Termo de Adesão ao Pix.</span>
            </div>
            <div class="buttons">
                <button type="button" class="button-secondary" (click)="showTerm()">
                    Ver Termo
                </button>
                <button type="button" class="button-primary" (click)="approveTerms()"> Aceitar Termo </button>
            </div>
            <div class="info-div">
                <img alt="iconFibra" class="info-img" src="/assets/svg/icon-info-blue.svg"> <span class="info-font">
                    Ao tocar no botão Aceitar Termo,
                    você concorda com o Termo de Adesão ao Pix do Banco Fibra.
                </span>
            </div>

        </div>

    </ng-template>
</div>

<fibra-modal-validation #content (responseValidation)="responseApiModal($event)" *ngIf="showModalValidation" [apiCallback]="apiCallback"
                        [apiPayload]="payload" (closeEvent)="closeModalApi($event)">
</fibra-modal-validation>

<div class="content-terms" *ngIf="showTerms">
    <fibra-pix-term (closeEvent)="closeModal($event)" (response)="responseApiModal($event)" [accountData]="selectedAccount" (setTerm)="changeTerm($event)">
    </fibra-pix-term>
</div>

<div class="row d-flex justify-content-center alert-terms " *ngIf="showCardSucess">
    <div class="col-4">
        <img src="assets/png/iconConfirm.png" alt="icon confirm" class="img-fluid">
        <p class="alert-title" style="color: #73B599;">Adesão ao Pix aprovada</p>
        <p class="alert-text">
            Agora você e os outros operadores já podem fazer tranferências ou cadastrar uma chave para a conta
            {{selectedAccount?.num_conta}} - {{selectedAccount?.exibicao}}.
    </div>

    <div class="col-12 ">
        <button class="btn-secondary" (click)="goBack()">Voltar</button>
    </div>
    <div class="col-12">
        <button class="btn-secondary" (click)="getIdAndSwitch(selectedAccount.num_indice, selectedAccount)">Cadastrar uma chave</button>
    </div>
    <div class="col-12">
        <button class=" btn-primary" (click)="goToTranfer()">Fazer uma transferência</button>
    </div>
</div>
<div class="row d-flex justify-content-center alert-terms " *ngIf="AlertCard">
    <div class="col-4">
        <img src="assets/png/icon-pending.png" alt="icon pending" class="img-fluid">
        <p class="alert-title"> Aceite ao Termo do Pix pendente</p>
        <p class="alert-text">Enviada para aprovação do(s) outro(s) master(s) da sua empresa.</p>
        <p class="warnings">
            <img alt="iconFibra" src="/assets/svg/info.svg" style="width: 11px;
      height: 11px;"> Já enviamos uma notificação para ele. Assim que ele aceitar o termo, você receberá um aviso e poderá
            fazer a transação normalmente.
        </p>
    </div>

    <div class="col-12 " *ngIf="AlertCard">
        <button class="btn-secondary" (click)="goBack()">Voltar</button>
    </div>
</div>

<div class="row d-flex justify-content-center alert-terms " *ngIf="AlertCardTerms">
    <div class="col-5">
        <img src="assets/png/icon-pending.png" alt="icon pending" class="img-fluid">
        <p class="alert-title"> Aceite ao Termo do Pix pendente</p>
        <p class="alert-text" style="border-bottom: 1px solid #D9DBE3; padding-bottom: 16px">
            Para fazer uma transferência o(s) master(s)
            da sua empresa precisa(m) aceitar o Termo de Adesão do Pix.
        </p>
        <img alt="iconFibra" src="/assets/svg/info.svg" style="width: 16px;
      height: 16px; margin-top: 16px;">
        <p class="warnings">
            Já enviamos uma notificação para ele. Assim que ele aceitar o termo, você receberá um aviso e poderá
            fazer a transação normalmente.
        </p>
    </div>

    <div class="col-12">
        <button class="btn-secondary" (click)="goBack()">Voltar</button>
    </div>
</div>

<div class="row d-flex justify-content-center alert-terms " *ngIf="AlertCardTermsKey">
    <div class="col-5">
        <img src="assets/png/icon-pending.png" alt="icon pending" class="img-fluid">
        <p class="alert-title"> Aceite ao Termo do Pix pendente</p>
        <p class="alert-text" style="border-bottom: 1px solid #D9DBE3; padding-bottom: 16px">Para cadastrar uma chave o(s) master(s) da sua empresa precisa(m) aceitar o Termo de Adesão do Pix.</p>
        <img alt="iconFibra" src="/assets/svg/info.svg" style="width: 16px;
      height: 16px; margin-top: 16px;">
        <p class="warnings">
            Já enviamos uma notificação para ele. Assim que ele aceitar o termo, você receberá um aviso e poderá
            fazer a transação normalmente.
        </p>
    </div>

    <div class="col-12">
        <button class="btn-secondary" (click)="goBack()">Voltar</button>
    </div>
</div>

<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
