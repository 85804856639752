<ng-template #content let-modal>
  <div class="parcelas-change-container">
    <div class="modal-header">
      <div class="generic-info">
        <img src="/assets/png/icon-pending.png" />
        <h1 [style.color]="'#D69D10'">Importante</h1>
        <div class="generic-info-message">{{ message }}</div>
        <div class="generic-info-message2"><br>{{ message2 }}</div>
      </div>
    </div>
    <div class="step-buttons-container">
      <button
        type="button"
        class="button-cancel"
        (click)="closeModal('cancelar')"
      >
        Cancelar
      </button>
      <button
        type="button"
        class="button-yes"
        (click)="closeModal('sim')"
      >
        Sim
      </button>
    </div>
  </div>
</ng-template>
