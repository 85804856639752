<div class="list-value">
  <table class="table">
    <thead class="table-header">
      <tr>
        <th
          scope="col"
          (click)="ordered('date')"
          *ngFor="let titleheader of option"
        >
          {{ titleheader.title }}
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.6">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.11394 0L8.22788 5.02282H0L4.11394 0Z"
                fill="#082A4D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.11394 13.0596L8.22788 8.03675H0L4.11394 13.0596Z"
                fill="#082A4D"
              />
            </g>
          </svg>
        </th>
        <th scope="col">Instruções</th>
        <th scope="col">Status</th>
      </tr>
    </thead>
    <tbody class="p-15" >
      <tr *ngFor="let item of transit">
        <td scope="row">{{ item.data_emissao | date: 'dd/MM/yyyy' }}</td>
        <td scope="row">{{ item.data_vencimento | date: 'dd/MM/yyyy' }}</td>
        <td>
          <ngb-highlight
            [result]="item.seu_numero"
            [term]="filter.value"
          ></ngb-highlight>
        </td>
        <td>
          <ngb-highlight
            [result]="item.pagador"
            [term]="filter.value"
          ></ngb-highlight>
        </td>
        <td>
          <ngb-highlight
            [result]="item.vlr_titulo | currency: 'BRL'"
            [term]="filter.value"
          ></ngb-highlight>
        </td>
        <td>
          <ngb-highlight
            [result]="item.instrucoes"
            [term]="filter.value"
          ></ngb-highlight>
        </td>
        <td class="util">
          <div
            [ngClass]="{
              pending: item.status == 'Em análise',
              authorized: item.status == 'Aprovado',
              refused: item.status == 'Recusado'
            }"
          ></div>
          <ngb-highlight
            [result]="item.status"
            [term]="filter.value"
          ></ngb-highlight>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center position" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="empty-info" *ngIf="hide">
    <fibra-generic-info
      type="warn"
      title="Você não possui itens"
      message="Não foi possível encontrar dados de movimentação!"
    >
    </fibra-generic-info>
  </div>
  
  <fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
