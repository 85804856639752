<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>

<div class="content-page">
  <!-- <fibra-breadcrumb
    bottom="-120px"
    infoAccount="Transações"
    roule="Transferências"
    arrow="false"
    downloadDoc="false"
  ></fibra-breadcrumb> -->
  <div class="body-content">
    <div id="transfer-confirm">
      <div id="transfer-confirm-content">
        <h1>Dados do beneficiário</h1>

        <p class="title">Nome</p>
        <p data-hj-suppress>
          {{
            beneficiaryData?.beneficiario.nome
              | titlecase
          }}
        </p>

        <p class="title">CNPJ / CPF</p>
        <p>{{ beneficiaryData?.beneficiario.cpf_cnpj_mascara }}</p>

        <p class="title">Instituição</p>
        <p>
          {{ beneficiaryData?.beneficiario.banco }}
        </p>

        <p class="title">Dados da conta</p>
        <p>
          {{ beneficiaryData?.beneficiario.agencia != '' ?  'AG:' : ''}} {{ beneficiaryData?.beneficiario.agencia != '' ?  beneficiaryData?.beneficiario.agencia : ''}} {{ beneficiaryData?.beneficiario.agencia != '' ?  '|' : ''}} CC:
          {{ beneficiaryData?.beneficiario.conta | account }}
        </p>

        <h1>Valor e data</h1>

        <p class="title">Valor da transferência</p>
        <p>
          {{
            beneficiaryData?.valor
              | currency: 'BRL'
          }}
        </p>

        <p class="title">{{ textDate }}</p>
        <p>
          {{beneficiaryData?.data | date: 'dd/MM/yyyy'}}
        </p>

        <div class="desc" *ngIf="beneficiaryData.repetir.semanaOuMes">
          <h1 class="margin_bottom">Repetir transferência Pix  em</h1>
          <p class="width_p" *ngIf="beneficiaryData?.repetir.semanaOuMes === 'semanal'">A transferência será realizada
            {{beneficiaryData?.repetir.dias === 'domingo' || beneficiaryData?.repetir.dias === 'sabado' ? 'todo ' : 'toda '}} {{ beneficiaryData?.stringDay }} a partir do dia {{ beneficiaryData?.data | date :'shortDate' }}
            até o dia {{ dateLabelRepeat }},mediante a disponibilidade de saldo na conta.
          </p>
          <p class="width_p" *ngIf="beneficiaryData?.repetir.semanaOuMes === 'mensal'">
            A transferência será realizada a cada 30 dias a partir do dia {{ beneficiaryData?.data | date :'shortDate' }}
            até o dia {{ dateLabelRepeat }}, mediante a disponibilidade de saldo na conta.
          </p>
        </div>

        <div *ngIf="beneficiaryData?.descricao" class="desc">
          <h1>Descrição</h1>
          <p>
            {{beneficiaryData?.descricao}}
          </p>
        </div>

        <h1>Conta para débito</h1>

        <p class="title">Conta</p>
        <p>{{beneficiaryData?.contaDebito.exibicao}}</p>

        <p class="title">Dados da conta</p>
        <p>AG: {{ beneficiaryData?.contaDebito.agencia }} | CC:
          {{ beneficiaryData?.contaDebito.conta | account }}</p>
      </div>

      <div class="transfer-confirm-footer">
        <button
          type="button"
          class="leaked-button preview"
          (click)="toast.callModalMessage('retorno')"
        >
          Voltar
        </button>

        <button type="button" class="secondary-button next" (click)="goNext()">
          {{ beneficiaryData?.flg_agendamento === true ? 'Confirmar agendamento' : 'Transferir'}}
        </button>
      </div>
    </div>
  </div>
  <fibra-toast-error-message [color]="'#ED6C6C'" (optionYes)="navigateBack()"></fibra-toast-error-message>
  <fibra-modal-validation
    *ngIf="showModalValidation"
    [apiCallback]="apiCallBack"
    (closeEvent)="closeModal()"
    [requestData]="requestDataApi"
    [apiPayload]="securityRequest()"
    (responseValidation)="securityResponse($event)">
  </fibra-modal-validation>
</div>

<ng-content></ng-content>
