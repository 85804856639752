<div class="main-selector">
    <fibra-company-list></fibra-company-list>
    <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>

<!-- Loading -->
<div class="d-flex justify-content-center align-items-center loadingView" *ngIf="loadingConfirmPayment">
    <div class="spinner-border text-primary" role="status"></div>
</div>

<div class="content-page" *fibraHasRole="'PIX.CONS_CHAVE,
    PIX.CAD_CHAVE,
    PIX.EXC_CHAVE,
    PIX.TRANSF_PIX_CHAVE,
    PIX.TRANSF_PIX_MSM_TIT,
    PIX.TRANS_PIX_OTR_TIT,
    PIX.TRANSF_PIX_MSM_TIT_BC,
    PIX.TRANSF_PIX_OTR_TIT_BC,
    PIX.CONS_SOLIC'">
    <div class="div">
        <fibra-breadcrumb bottom="0" infoAccount="Transações" roule="Pix" arrow="false" downloadDoc="">
        </fibra-breadcrumb>
        <div *ngIf="!showOnboarding" class="bnt d-flex justify-content-end px-3" style="position: relative; top: -50px;">
            <button class="button mr-3" (click)="clicaSaibaMais()">Saiba mais</button>
            <button class="button" (click)="openModalServicesChannels()">Canais de Atendimento</button>
        </div>
    </div>
    <fibra-pix-receipt *ngIf="pixComprovante"></fibra-pix-receipt>
    <div *ngIf="initPix">
        <section *ngIf="!showOnboarding && !isProof">
            <div class="content">
                <!-- TABS -->
                <div class="navbar-teste" [ngStyle]="{'justify-content' : isMaster ? 'space-between' : 'right'}">
                    <div [ngClass]="selectedNavBar === 'transferir' ? 'item-selected': 'item-unselected'" (click)="changeNavbarClass('transferir')"
                        *fibraHasRole="'PIX.TRANSF_PIX_CHAVE,
                        PIX.TRANSF_PIX_MSM_TIT,
                        PIX.TRANS_PIX_OTR_TIT,
                        PIX.TRANSF_PIX_MSM_TIT_BC,
                        PIX.TRANSF_PIX_OTR_TIT_BC'" id="transferir-item">
                        <span [ngClass]="selectedNavBar === 'transferir' ? 'item-font-selected': 'item-font-unselected'" id="transferir-font"> Transferir
                        </span>
                    </div>
                    <div [ngClass]="selectedNavBar === 'minhas-chaves' ? 'item-selected': 'item-unselected'" id="minhas-chaves-item" (click)="changeNavbarClass('minhas-chaves')"
                        *fibraHasRole="'PIX.CONS_CHAVE,
                        PIX.CAD_CHAVE,
                        PIX.EXC_CHAVE'">
                        <span [ngClass]="selectedNavBar === 'minhas-chaves' ? 'item-font-selected': 'item-font-unselected'" id="minhas-chaves-font">
                            Minhas Chaves </span>
                    </div>

                    <div *ngIf="isMaster" [ngClass]="selectedNavBar === 'solicitacoes' ? 'item-selected': 'item-unselected'" id="solicitacoes-item"
                        (click)="subMenuAprovacoes = null; changeNavbarClass('solicitacoes')">
                        <span [ngClass]="selectedNavBar === 'solicitacoes' ? 'item-font-selected': 'item-font-unselected'" class="item-font-unselected"
                            id="solicitacoes-font"> Aprovações de chaves <span class="notif-keys" *ngIf="temPendencias"></span></span>
                    </div>
                    <div *ngIf="isMaster" [ngClass]="selectedNavBar === 'termos' ? 'item-selected': 'item-unselected'" id="termos-item" (click)="changeNavbarClass('termos')">
                        <span [ngClass]="selectedNavBar === 'termos' ? 'item-font-selected': 'item-font-unselected'" id="termos-font"> Aderir
                            Termos <span class="notif" *ngIf="notif"></span></span>
                    </div>
                    <div *ngIf="isMaster" [ngClass]="selectedNavBar === 'limites' ? 'item-selected': 'item-unselected'" id="limites-item" (click)="changeNavbarClass('limites')">
                        <span [ngClass]="selectedNavBar === 'limites-font' ? 'item-font-selected': 'item-font-unselected'" id="limites-font"> Meus
                            limites Pix <span class="notif" *ngIf="notifLimites" style="right: -130px;"></span></span>
                    </div>
                    <div *ngIf="isSolicitacao" [ngClass]="selectedNavBar === 'solicitacoes-chaves' ? 'item-selected': 'item-unselected'" id="termos-item" (click)="changeNavbarClass('solicitacoes-chaves')">
                        <span [ngClass]="selectedNavBar === 'solicitacoes-chaves' ? 'item-font-selected': 'item-font-unselected'" id="termos-font">
                            Solicitações </span>
                    </div>
                    <div *fibraHasRole="'PIX.TRANSF_PIX_CHAVE,
                    PIX.TRANSF_PIX_MSM_TIT,
                    PIX.TRANS_PIX_OTR_TIT,
                    PIX.TRANSF_PIX_MSM_TIT_BC,
                    PIX.TRANSF_PIX_OTR_TIT_BC'" [ngClass]="selectedNavBar === 'refund' ? 'item-selected': 'item-unselected'" class="item-unselected" id="termos-item" (click)="changeNavbarClass('refund')">
                        <span [ngClass]="selectedNavBar === 'refund' ? 'item-font-selected': 'item-font-unselected'" id="termos-font"> Devolver
                            Pix
                        </span>
                    </div>
                </div>

                <div class="current-content" *ngIf="selectedNavBar === 'transferir'">
                    <fibra-pix-transferir [navBarSelected]="'transferir'" *ngIf="isAccounts" (handleGoto)="emitGotoPage($event)"></fibra-pix-transferir>
                    <fibra-pix-new-transfer *ngIf="pixNewTransfer"></fibra-pix-new-transfer>
                    <fibra-pix-favorite-beneficiaries *ngIf="pixBeneficiaries"></fibra-pix-favorite-beneficiaries>
                    <fibra-pix-confirm-transfer *ngIf="pixConfirm" (loadConfirm)="callLoading($event)"></fibra-pix-confirm-transfer>
                    <fibra-pix-agenciaconta-bancos-list *ngIf="pixBanks"></fibra-pix-agenciaconta-bancos-list>
                    <fibra-pix-agenciaconta-transfer-bancofibra *ngIf="pixFibra"></fibra-pix-agenciaconta-transfer-bancofibra>
                    <fibra-pix-agenciaconta-transfer-outrobanco *ngIf="pixOutroBanco"></fibra-pix-agenciaconta-transfer-outrobanco>
                </div>

                <div class="current-content" *ngIf="selectedNavBar === 'minhas-chaves'">
                    <div *ngIf="!readInfomationKeyPix">
                        <section class="body-content-intro-key">
                            <div class="pix-intro-div">
                                <div class="arrow">
                                    <img class="arrow-img" src="/assets/svg/arrow-pix-left.svg" alt="arrow" (click)="stepsInformationPixKey = 1 ">
                                </div>
                                <div [ngSwitch]="stepsInformationPixKey">
                                    <div class="pix-image-content" *ngSwitchCase="stepsInformationPixKey === 1">
                                        <div class="pix-image-div">
                                            <img class="image" src="/assets/svg/cell-phone-key.svg" alt="pix-image">
                                        </div>
                                        <div class="pix-info-div">
                                            <div class="pix-info-content">
                                                <span class="pix-content-info"> O registro da chave é necessário para receber
                                                    um Pix de forma prática. Com a chave, ao invés do pagador ter que digitar
                                                    todas as informações para identificar sua conta, bastará informar a chave
                                                    registrada que pode ser o número de celular, CNPJ, CPF, chave aleatória
                                                    ou e-mail.</span>
                                            </div>
                                        </div>
                                        <div class="pix-num-passo-div">
                                            <div class="passo-actual" id="passo1"></div>
                                            <div class="passo" id="passo2"></div>
                                        </div>
                                        <div class="pix-button-div">
                                            <button type="button" class="button-continue" (click)="finalizarIntroKeyPix()">
                                                Entendi
                                            </button>
                                        </div>
                                    </div>
                                    <div class="pix-image-content" *ngIf="stepsInformationPixKey === 2">
                                        <div class="pix-image-div">
                                            <img class="image" src="/assets/svg/keys.svg" alt="pix-image">
                                        </div>
                                        <div class="pix-info-div">
                                            <div class="pix-info-content">
                                                <span class="pix-content-info">Cada chave só poderá ser vinculada a uma única
                                                    conta. Cada pessoa física poderá cadastrar até 5 chaves para uma mesma
                                                    conta e cada pessoa jurídica até 20 chaves para uma mesma conta.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="pix-num-passo-div">
                                            <div class="passo" id="passo1"></div>
                                            <div class="passo-actual" id="passo2"></div>
                                        </div>
                                        <div class="pix-button-div">
                                            <button type="button" class="button-continue" (click)="finalizarIntroKeyPix()">
                                                Entendi
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="arrow">
                                    <img class="arrow-img" src="/assets/svg/arrow-pix-right.svg" alt="arrow" (click)="stepsInformationPixKey = 2">
                                </div>
                            </div>

                        </section>
                    </div>
                    <fibra-pix-transferir navBarSelected="minhas-chaves" *ngIf="isAccounts && readInfomationKeyPix" (handleGoto)="emitGotoPage($event)"></fibra-pix-transferir>
                    <fibra-pix-chaves *ngIf="pixKeys"></fibra-pix-chaves>
                    <fibra-pix-chaves-cadastro *ngIf="pixCadastro"> </fibra-pix-chaves-cadastro>
                </div>

                <div class="current-content" *ngIf="selectedNavBar === 'solicitacoes'">
                    <fibra-pix-keys-approvals *ngIf="isApprovalsKeys" 
                                              [temPendenciasChave]="temPendenciasChave" 
                                              [temPendenciasDoacao]="temPendenciasDoacao" 
                                              [temPendenciasRequisicao]="temPendenciasRequisicao"
                                              [subMenu]="subMenuAprovacoes"
                                              (onTemPendenciasChanged)="onTemPendenciasChanged($event)"> </fibra-pix-keys-approvals>
                </div>

                <div class="current-content" *ngIf="selectedNavBar === 'termos'">
                    <fibra-pix-terms-approvals [accountNum]="accountNumSelecionado" *ngIf="isTermApprovals" (handleGoto)="emitGotoPage($event)" (updateNotif)="isTermsPending()">
                    </fibra-pix-terms-approvals>
                </div>

                <div class="current-content" *ngIf="selectedNavBar === 'limites'">
                    <fibra-pix-limites [accountNum]="accountNumSelecionado" *ngIf="isLimits"></fibra-pix-limites>
                    <fibra-pix-limite-cadastro *ngIf="limiteCadastro"> </fibra-pix-limite-cadastro>
                </div>

                <div class="current-content" *ngIf="selectedNavBar === 'solicitacoes-chaves'">
                    <fibra-pix-solicitation></fibra-pix-solicitation>
                </div>

                <!-- DEVOLUÇÃO PIX -->
                <div class="current-content" *ngIf="selectedNavBar === 'refund'">
                    <fibra-pix-transferir [navBarSelected]="'refund'" *ngIf="isAccounts" (handleGoto)="emitGotoPage($event)"></fibra-pix-transferir>
                    <fibra-pix-refund *ngIf="refundOnOf" (controlShowHideComponent)="onOfComponent($event)"></fibra-pix-refund>
                    <fibra-pix-new-refund (controlShowHideNewRefund)="onOfNewRefundComponent($event)" *ngIf="newRefundOnOf"></fibra-pix-new-refund>
                    <fibra-pix-confirm-devolution *ngIf="confirmRefund"></fibra-pix-confirm-devolution>
                    <fibra-pix-receipt-devolution *ngIf="showReceiptDevolution"></fibra-pix-receipt-devolution>
                </div>

            </div>
        </section>
        
        <section id="revisao-transferencia" *ngIf="isProof">
            <div class="aviso">
                <img class="img" src="/assets/svg/icon-check.svg" alt="check">
                <span class="aviso-font"> A transação PIX foi realizada</span>
            </div>
            <div class="content">
                <div class="current-content">
                    <fibra-pix-transfer-proof></fibra-pix-transfer-proof>
                </div>
            </div>

        </section>

        <section class="body-content-intro" *ngIf="showOnboarding">
            <div class="pix-intro-div">
                <div class="arrow">
                    <img class="arrow-img" src="/assets/svg/arrow-pix-left.svg" alt="arrow" (click)="backward()">
                </div>
                <div [ngSwitch]="stepsInformationPix">
                    <div class="pix-image-content" *ngSwitchCase="stepsInformationPix === 1">
                        <div class="pix-image-div">
                            <img class="image" src="/assets/svg/pix-intro-1.svg" alt="pix-image">
                        </div>
                        <div class="pix-info-div">
                            <div class="pix-info-title">
                                <span class="pix-info-font"> O que é o Pix? </span>
                            </div>
                            <div class="pix-info-content">
                                <span class="pix-content-info">Com o Pix, você pode pagar, receber e transferir valores para
                                    qualquer conta. Tudo em poucos segundos, 24h por dia, 7 dias por semana, de forma gratuita
                                    para pessoa física. Para pessoa jurídica, consulte nossa Tabela de Tarifas disponível
                                    em <span class="email">www.bancofibra.com.br.</span></span>
                            </div>
                        </div>
                        <div class="pix-num-passo-div">
                            <div class="passo-actual" id="passo1"></div>
                            <div class="passo" id="passo2"></div>
                            <div class="passo" id="passo3"></div>
                            <div class="passo" id="passo4"></div>
                        </div>
                        <div class="pix-button-div">
                            <button type="button" class="button-continue" (click)="finalizarIntro()">
                                Entendi
                            </button>
                        </div>
                    </div>
                    <div class="pix-image-content" *ngIf="stepsInformationPix === 2">
                        <div class="pix-image-div">
                            <img class="image" src="/assets/svg/pix-intro-2.svg" alt="pix-image">
                        </div>
                        <div class="pix-info-div">
                            <div class="pix-info-title">
                                <span class="pix-info-font"> Facilidade e segurança em fazer um Pix
                                </span>
                            </div>
                            <div class="pix-info-content">
                                <span class="pix-content-info"> Você pode fazer um Pix para qualquer banco, em qualquer dia
                                    e horário, inclusive em feriados e finais de semana. O dinheiro cai na hora com total
                                    segurança.
                                </span>
                            </div>
                        </div>
                        <div class="pix-num-passo-div">
                            <div class="passo" id="passo1"></div>
                            <div class="passo-actual" id="passo2"></div>
                            <div class="passo" id="passo3"></div>
                            <div class="passo" id="passo4"></div>
                        </div>
                        <div class="pix-button-div">
                            <button type="button" class="button-continue" (click)="finalizarIntro()">
                                Entendi
                            </button>
                        </div>
                    </div>
                    <div class="pix-image-content" *ngIf="stepsInformationPix === 3">
                        <div class="pix-image-div">
                            <img class="image" src="/assets/svg/pix-intro-3.svg" alt="pix-image" style="margin-right: 42px;">
                        </div>
                        <div class="pix-info-div">
                            <div class="pix-info-title">
                                <span class="pix-info-font"> Quer receber uma transferência Pix? </span>
                            </div>
                            <div class="pix-info-content">
                                <span class="pix-content-info">Basta cadastrar uma chave Pix na sua conta. Depois é só compartilhar
                                    a chave com quem irá te enviar dinheiro. A chave pode ser seu CNPJ/CPF, número de celular,
                                    endereço de e-mail ou uma chave aleatória. E se preferir, você pode informar os dados
                                    da conta.</span>
                            </div>
                        </div>
                        <div class="pix-num-passo-div">
                            <div class="passo" id="passo1"></div>
                            <div class="passo" id="passo2"></div>
                            <div class="passo-actual" id="passo3"></div>
                            <div class="passo" id="passo4"></div>
                        </div>
                        <div class="pix-button-div">
                            <button type="button" class="button-continue" (click)="finalizarIntro()">
                                Entendi
                            </button>
                        </div>
                    </div>
                    <div class="pix-image-content" *ngIf="stepsInformationPix === 4">
                        <div class="pix-image-div">
                            <img class="image" src="/assets/svg/pix-intro-4.svg" alt="pix-image" style="margin-left: 22px;">
                        </div>
                        <div class="pix-info-div">
                            <div class="pix-info-title">
                                <span class="pix-info-font" style="width: 244px;text-align:center ;"> Fazer uma transferência
                                    pelo Pix também é muito fácil </span>
                            </div>
                            <div class="pix-info-content">
                                <span class="pix-content-info"> Informe a chave Pix do favorecido e faça a transferência
                                    em segundos, mas se quiser, você também pode usar os dados bancários do favorecido.
                                </span>
                            </div>
                        </div>
                        <div class="pix-num-passo-div">
                            <div class="passo" id="passo1"></div>
                            <div class="passo" id="passo2"></div>
                            <div class="passo" id="passo3"></div>
                            <div class="passo-actual" id="passo4"></div>
                        </div>
                        <div class="pix-button-div">
                            <button type="button" class="button-continue" (click)="finalizarIntro()">
                                Entendi
                            </button>
                        </div>
                    </div>
                </div>
                <div class="arrow">
                    <img class="arrow-img" src="/assets/svg/arrow-pix-right.svg" alt="arrow" (click)="forward()">
                </div>
            </div>

        </section>
    </div>

</div>

<div class="modal">
    <ng-template #content let-modal>
        <div class="content-modal">
            <div class="generic-title">
                <img src="assets/svg/icon-key.svg" alt="">
                <p *ngIf="solicitationKey.fl_posse">Reivindicação de chave recebida</p>
                <p *ngIf="solicitationKey.fl_portabilidade">Portabilidade de chave recebida</p>
                <span aria-hidden="true" style="cursor: pointer; font-size: 42px" (click)="closeModal($event)">&times;</span>
            </div>
            <div class="generic-info">
                <p *ngIf="solicitationKey.fl_posse" class="generic-info-message">Recebemos pedido de reivindicação de posse da chave Pix <span *ngIf="solicitationKey.tp_chave === 'CPF' || solicitationKey.tp_chave === 'CNPJ'">{{solicitationKey.chave
                        | cpfcnpj}}</span>
                    <span *ngIf="solicitationKey.tp_chave === 'PHONE'">{{solicitationKey.chave | mask: '+00 00 00000-0000'
                        }}
                    </span>
                    <span *ngIf="solicitationKey.tp_chave === 'EMAIL'">{{solicitationKey.chave}}</span>. Isso significa que outro usuário registrou pedido para que essa chave seja vinculada a outra conta. Para cancelar esse processo e para que você possa
                    continuar utilizando essa chave, é necessário que você faça nova validação de posse em até 7 (sete) dias.
                </p>
                <p *ngIf="solicitationKey.fl_portabilidade" class="generic-info-message">Recebemos o pedido de portabilidade da chave Pix <span *ngIf="solicitationKey.tp_chave === 'CPF' || solicitationKey.tp_chave === 'CNPJ'">{{solicitationKey.chave
                        | cpfcnpj}}</span>
                    <span *ngIf="solicitationKey.tp_chave === 'PHONE'">{{solicitationKey.chave | mask: '+00 00 00000-0000'
                        }}
                    </span>
                    <span *ngIf="solicitationKey.tp_chave === 'EMAIL'">{{solicitationKey.chave}}</span>.<br />
                    Para conclusão  desse processo é necessária a sua confirmação em até 7 (sete) dias.
                </p>
            </div>
            <div class="buttons">
                <button type="button" class="button-secondary" (click)=" selectConfirmationType(false); changeNavbarClass('solicitacoes');closeModal($event)">
                    Recusar
                </button>
                <button type="button" class="button-primary" (click)=" selectConfirmationType(true);changeNavbarClass('solicitacoes');closeModal($event)">Aprovar</button>
            </div>
        </div>

    </ng-template>
</div>