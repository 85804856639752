<div class="content-beneficiaries">
    <div class="terms">
        <div class="header">
            <div class="arrow">
                <img class="arrow-img" src="/assets/svg/arrow-voltar-icon.svg" alt="arrow-voltar" (click)="goBack()">
                <span class="voltar"> Voltar</span>
            </div>
            <div class="title-div">
                <span class="title-font"> Aderir ao Pix para a conta:</span>
                <span class="account"> {{accountData?.num_conta}} - {{accountData?.exibicao}}</span>
            </div>
        </div>
        <div class="term-content">
            <div class="term-title">
                <span class="term-font">Termo de adesão</span>
            </div>
            <div class="term-text">
                <p class="font">Pelo presente Termo de Adesão, a pessoa nomeada e representada na respectiva FICHA CADASTRAL
                    E PROPOSTA DE ABERTURA DE CONTA (doravante “FICHA CADASTRAL E PROPOSTA”), parte integrante, complementar
                    e inseparável das CONDIÇÕES GERAIS À FICHA CADASTRAL E PROPOSTA, doravante denominada “CLIENTE”, declara
                    ciência sobre as características e condições de uso dos serviços de pagamento prestados pelo BANCO FIBRA
                    S.A. (“FIBRA”) no âmbito do arranjo de pagamentos Pix, instituído pelo Banco Central do Brasil, nos termos
                    a seguir expostos:</p>
                <br>
                <span class="font-sub-title">1. DEFINIÇÃO DE PIX</span>
                <br>
                <span class="font">O Pix é o arranjo de pagamentos instantâneos instituído pelo Banco Central do Brasil,
                    disponível durante 24 (vinte e quatro) horas por dia e todos os dias no ano, que permite a transferência
                    imediata de valores entre diferentes instituições, em todos os horários e dias, entre pessoas físicas,
                    pessoas jurídicas ou entes governamentais.</span>
                <br>
                <span class="font-sub-title">2. ENVIO DE VALORES PELO PIX</span>
                <br>
                <span class="font">Para enviar valores de uma conta no FIBRA pelo Pix, o CLIENTE poderá: (i) por meio do
                    modelo de transferência, inserir os dados da conta do recebedor; ou (ii) por meio do modelo de transferência,
                    inserir a Chave Pix do recebedor (caso este tenha chave cadastrada).</span>
                <br>
                <span class="font-sub-title">3. RECEBIMENTO DE VALORES PELO PIX</span>
                <br>
                <span class="font">O CLIENTE poderá receber valores em sua conta de depósito através do Pix, das seguintes
                    formas: (i) por meio do modelo de transferência, através da inserção dos dados da conta do CLIENTE pelo
                    pagador; ou (ii) por meio do modelo de transferência, através da inserção da Chave Pix do CLIENTE pelo
                    pagador (caso tenha chave cadastrada).</span>
                <br>
                <span class="font-sub-title">4. DEVOLUÇÃO DE VALORES RECEBIDOS PELO PIX</span>
                <br>
                <span class="font">O CLIENTE poderá devolver um Pix recebido, parcial ou integralmente, por meio da iniciação
                    de uma devolução. A devolução de valores recebidos pode ocorrer no prazo de até 90 dias da transação,
                    sendo necessário para a conclusão que os dados da conta a ser creditada não tenham sofrido nenhuma alteração.</span>
                <br>
                <span class="font-sub-title">5. CHAVE PIX</span>
                <br>
                <span class="font">A Chave Pix é o dado de identificação de uma conta de depósito vinculada ao Pix, que permite
                    ao CLIENTE realizar transferências e pagamentos sem a necessidade de informar os dados da conta do recebedor,
                    ou ainda, receber valores sem a necessidade de informar todos os dados de sua conta de depósito.
                </span>
                <br>
                <span class="font">O CLIENTE poderá cadastrar as seguintes Chaves Pix: (i) número do CNPJ; (ii) número de
                    celular; (iii) endereço de e-mail; (iv) chave aleatória (sequência de letras e números gerados aleatoriamente
                    pelo Banco Central do Brasil).
                </span>
                <br>
                <span class="font">A quantidade de Chaves Pix cadastradas pelo CLIENTE é limitada por conta, sendo possível
                    no máximo 20 Chaves Pix para uma conta pessoa jurídica e 5 Chaves para uma conta pessoa física. Uma vez
                    cadastrada a Chave Pix em uma conta, esta não poderá ser cadastrada em outra conta, exceto se o CLIENTE
                    realizar a exclusão ou a portabilidade dessa chave para outra instituição financeira.
                </span>
                <br>
                <span class="font">Ao cadastrar uma Chave Pix no FIBRA, serão armazenados e disponibilizados aos usuários
                    que consultarem essa Chave, no momento de envio de um Pix, os seguintes dados do CLIENTE: (i) razão social;
                    (ii) CNPJ completo; (iii) o FIBRA como instituição detentora da conta.
                </span>
                <br>
                <span class="font">Todas as Chaves Pix são cadastradas no Diretório de Identificadores de Contas Transacionais
                    (DICT) regulado e gerido pelo Banco Central do Brasil.
                </span>
                <br>
                <span class="font">O CLIENTE declara-se ciente que a funcionalidade de Verificação de Chaves Pix Registradas,
                    permite que outros usuários finais tenham conhecimento da existência de suas Chaves Pix, quando cadastradas
                    nas modalidades número de telefone celular e endereço de e-mail.
                </span>
                <br>
                <span class="font-sub-title">6. EXCLUSÃO DE CHAVE PIX</span>
                <br>
                <span class="font">A Chave Pix poderá ser excluída mediante expressa solicitação do CLIENTE ao FIBRA. Sem
                    prejuízo da exclusão por solicitação do CLIENTE, o FIBRA poderá solicitar a exclusão da Chave Pix ao
                    DICT nas seguintes hipóteses:
                </span>
                <br>
                <span class="font">
                    <ol>
                        <li>(i) Encerramento da(s) conta(s) de depósito do CLIENTE;</li>
                        <li>(ii) Suspeita, tentativa ou efetivação de uso fraudulento da Chave Pix;</li>
                        <li>(iii) Inatividade de uso da Chave Pix por mais de 12 (doze) meses.</li>
                    </ol>
                </span>
                <br>
                <span class="font-sub-title">7. PORTABILIDADE DE CHAVE PIX</span>
                <br>
                <span class="font">O CLIENTE poderá portar a Chave Pix vinculada a sua conta para outra conta de sua titularidade,
                    exceto quando tratar-se de chave aleatória. Para tanto, o CLIENTE deverá solicitar a portabilidade na
                    instituição para a qual deseja cadastrar a Chave Pix.</span>
                <br>
                <span class="font-sub-title">8. REIVINDICAÇÃO DE POSSSE DE CHAVE PIX</span>
                <br>
                <span class="font">O CLIENTE poderá realizar a reivindicação de posse de Chave Pix já cadastrada em conta
                    de outro usuário, <b>desde que comprove sua posse</b>. Para tanto, o CLIENTE deverá solicitar a reivindicação
                    na instituição para a qual deseja cadastrar a Chave Pix. Podem ser reivindicados: número de telefone
                    celular e endereço de e-mail.</span>
                <br>
                <span class="font-sub-title">9. TARIFAS:</span>
                <br>
                <span class="font">Os CLIENTES pessoas físicas são isentos de cobrança de tarifas para: (i) enviar um Pix,
                    com finalidade de transferência e de compra; (ii) receber um Pix, com finalidade de transferência. As
                    situações em que os CLIENTES pessoas físicas poderão ser tarifados são as seguintes: (i) ao enviar um
                    Pix, quando utilizado canal de atendimento presencial ou pessoal da instituição; (ii) ao receber um Pix,
                    em contrapartida a vendas comerciais, conforme detalhado no site www.bcb.gov.br/estabilidadefinanceira/pix.
                    .
                </span>
                <br>
                <span class="font">Os CLIENTES pessoas jurídicas pagarão ao FIBRA as tarifas previstas na Tabela de Tarifas
                    Bancárias divulgada no site do FIBRA (<a href="www.bancofibra.com.br">www.bancofibra.com.br</a>).
                </span>
                <br>
                <span class="font-sub-title">10. USO DA MARCA PIX</span>
                <br>
                <span class="font">O CLIENTE está ciente que a marca Pix é de titularidade exclusiva do Banco Central do
                    Brasil e seu uso deverá estar em conformidade com os termos do Regulamento Pix anexo à Resolução BCB
                    nº 01 de 12.08.2020, com o Manual de Uso da Marca e demais normas aplicáveis, além de não acarretar dano
                    de nenhuma espécie, inclusive de imagem, ao Banco Central do Brasil ou ao Pix </span>
                <br>
                <span class="font">Caso o FIBRA tome conhecimento do uso indevido da marca ou de qualquer tentativa de cópia
                    ou infração aos direitos da marca deverá notificar o Banco Central do Brasil em até 7 (sete) dias, e
                    exigir que o uso indevido seja regularizado. A não regularização e ajuste em referida utilização indevida
                    poderá acarretar na suspensão da aceitação do Pix pelo CLIENTE ou no encerramento unilateral pelo FIBRA,
                    de acordo com as regras publicadas pelo Banco Central do Brasil.</span>
                <br>
                <span class="font">Na hipótese de o CLIENTE utilizar o Pix para exercer atividade empresarial, é necessário
                    que ao divulgar a aceitação do mesmo: (i) não veicule a marca Pix em dimensão inferior às marcas, aos
                    símbolos ou aos logotipos dos demais instrumentos de pagamento aceitos pelo CLIENTE; (ii) não transmita
                    a impressão de que o Pix possui aceitação mais restrita ou menos vantajosa do que os demais instrumentos
                    de pagamento aceitos pelo CLIENTE, quando tal impressão não corresponda à realidade ou não seja adequadamente
                    justificada por diferenças técnicas.</span>
                <br>
  
                <span class="font">Os canais de atendimento ao CLIENTE indicados no site do FIBRA (<a href="www.bancofibra.com.br">www.bancofibra.com.br</a>
                    ) estão a disposição para auxiliar em consultas, informações e denúncias.</span>
                <br>
  
                <span class="font-sub-title">11. BLOQUEIO CAUTELAR</span>
                <br>
                <span class="font">O CLIENTE pessoa física declara-se ciente que, em caso de suspeita de fraude, o FIBRA poderá bloquear 
                    cautelarmente recursos originários de um Pix realizado para a sua respectiva conta de depósito, mediante imediata comunicação. O bloqueio 
                    cautelar será efetivado simultaneamente ao crédito na conta de depósito, e poderá durar até 72 (setenta e duas) horas. Caso nesse período 
                    o FIBRA avalie que a transação tenha fundada suspeita de fraude, os recursos serão imediatamente devolvidos para o usuário pagador 
                    no âmbito do Mecanismo Especial de Devolução, com o que expressamente concorda o CLIENTE.
                </span>
                <br>
  
                <span class="font-sub-title">12. MECANISMO ESPECIAL DE DEVOLUÇÃO</span>
                <br>
                <span class="font">O CLIENTE está ciente que o FIBRA poderá utilizar-se do Mecanismo Especial de Devolução para devolver recursos 
                    creditados em sua conta de depósito por meio de Pix, caso exista fundada suspeita de uso do arranjo para a prática de fraude, 
                    ou nos casos em que se verifique falha operacional no Sistema de Tecnologia da Informação de qualquer dos participantes envolvidos 
                    na transação.
                </span>
                <br>
                
                <span class="font">O CLIENTE está ciente que não se incluem nas hipóteses de devolução: (i) as controvérsias relacionadas a aspecto do 
                    negócio jurídico subjacente à transação de pagamento; (ii) as transações com fundada suspeita de fraude em que os recursos forem 
                    destinados à conta de um terceiro de boa-fé.
                </span>
                <br>
  
                <span class="font">O CLIENTE concorda com a devolução de recursos no âmbito do Mecanismo Especial de Devolução, nas hipóteses 
                    previstas nesta cláusula, e está ciente que a devolução poderá ser debitada de sua conta de depósitos pelo FIBRA em uma ou mais 
                    parcelas, até o atingimento do valor total da transação.
                </span>
                <br>
  
                <span class="font-sub-title">13. SEGURANÇA E PROTEÇÃO DE DADOS</span>
                <br>
  
                <span class="font">O FIBRA declara que cumpre toda a legislação aplicável sobre segurança da informação,
                    privacidade e proteção de dados observando, quando aplicáveis, o Código de Defesa do Consumidor, o Marco
                    Civil da Internet, a Lei Geral de Proteção de Dados e demais legislações correlatas. </span>
                <br>
  
                <span class="font">O CLIENTE declara e garante ter lido e compreendido o AVISO DE PRIVACIDADE do FIBRA, devidamente
                    disponibilizado no endereço eletrônico <a href="www.bancofibra.com.br">www.bancofibra.com.br</a>, que
                    explica e informa como o FIBRA trata os dados pessoais coletados.</span>
                <br>
                
                <div class="actions-div">
                    <div class="check-div">
                        <input type="checkbox" class="checkbox" (click)="setCheckbox()" />
                        <span class="check-font">Li e concordo com o termo de adesão do débito autorizado.<span class="notif"></span>
                        </span>
                    </div>
                    <div class="buttons">
                        <button type="button" class="button-primary" [disabled]="!checked" (click)="openModal()">
                            Avançar
                        </button>
                    </div>
                </div>
            </div>
  
        </div>
    </div>
  
    <fibra-modal-validation #content (responseValidation)="responseApi($event)" *ngIf="showModalValidation" [apiCallback]="apiCallback"
        [apiPayload]="payload" (closeEvent)="closeModal($event)">
    </fibra-modal-validation>
  
  </div>