import { Environment } from '@src/environments/model';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// !Important this enviroment is based on enviroment.local.ts. Use this ref to add here.

export const environment: Environment = {
  production: true,
  env: 'development',
  URLBase: 'https://apidev.bancofibra.com.br/tidigital-ib/',
  clientId: '52782d14-0d53-4675-8844-a1d9ad865b4a',
  tenantId: '716d91dc-3b96-4947-88d4-dad4c32fd4a1',
  tenantUrl: 'loginhom.bancofibra.com.br',
  objectId: '',
  tokenApi: '',
  applicationId: '',
  redirectUri: 'https://ibdev.bancofibra.com.br',
  firebase: {
    apiKey: 'AIzaSyBFhUaKsVeAwMUqZiGMf1qsZQ2b4i4JrWk',
    authDomain: 'banco-fibra---mobile-banking.firebaseapp.com',
    databaseURL: 'https://banco-fibra---mobile-banking.firebaseio.com',
    projectId: 'banco-fibra---mobile-banking',
    storageBucket: 'banco-fibra---mobile-banking.appspot.com',
    messagingSenderId: '802539032539',
    appId: '1:802539032539:web:5b3113368c641ce66421fe',
    measurementId: 'G-0JM48YZT2S'
  },
  zendesk: {
    chatKey: '101dd759-b773-48bf-8387-f1dd3d755d72'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
