import * as Model from '@src/core/domain/admin-user/admin-user.model';
import { Observable } from 'rxjs';

export abstract class AdminUserRepository {
  abstract setUser(
      param: Model.AdminUserRequest
  ): Observable<Model.AdminUserModel>;

  abstract setStatusAdmUsers(
      param: Model.AdminUsersStatusRequest
  ): Observable<Model.AdminUserModel>;

  abstract deleteUser(
      id: number
  ): Observable<Model.AdminUserModel>;

  abstract updateUser(
      param: Model.AdminUserRequest
  ): Observable<Model.AdminUserModel>;

  abstract setGroups(
      param: Model.AdminGroupRequest
  ): Observable<Model.AdminUserModel>;

  abstract updateGroups(
      param: Model.AdminGroupsUpdateRequest
  ): Observable<Model.AdminUserModel>;

  abstract setStatusAdmGroups(
      param: Model.AdminGroupsStatusRequest
  ): Observable<Model.AdminUserModel>;

  abstract deleteGroup(
      id: number
  ): Observable<Model.AdminUserModel>;

  abstract setUserByAdiminGroup(
      param: Model.AdiminUserByGroupRequest
  ): Observable<Model.AdminUserModel>;

  abstract getUserByAdiminGroup(
      id: number
  ): Observable<Model.AdminUserModel>;

  abstract getUserDetails(
      param: Model.AdminDetailRequest
  ): Observable<Model.AdminUserModel>;

  abstract getGroupDetails(
      param: Model.AdminDetailRequest
  ): Observable<Model.AdminUserModel>;

  abstract getUsers(): Observable<Model.AdminUserListModel>;
  abstract getUserOffice(): Observable<Model.AdminUserModel>;
  abstract getGroups(): Observable<Model.AdminGroupListModel>;
  abstract getDepartments(): Observable<Model.AdminUserModel>;
  abstract getFunctionalities(): Observable<Model.AdminUserModel>;
  abstract getFunctionalitiesAccount(): Observable<Model.AdminUserModel>;
  abstract getFunctionalitiesNotAccount(): Observable<Model.AdminUserModel>;
  abstract postValidaDados(
    param: Model.AdminUserValidaDadosRequest
    ): Observable<Model.AdminUserValidaDadosModel>;
}
