<fibra-toast-error-message [color]="typToastColor"> </fibra-toast-error-message>
<div class="row" id="body-row" *ngIf="!mobile">
    <div id="sidebar-container" class="sidebar-expanded d-md-block">
        <!-- d-* hiddens the Sidebar in smaller devices. Its itens can be kept on the Navbar 'Menu' -->
        <!-- Bootstrap List Group -->
        <ul class="list-group">
            <!-- Logo -->
            <li class="list-group-item logo-separator d-flex justify-content-center">
                <span class="menu-collapsed">
                    <img class="logo-full" src="/assets/svg/logofibra.svg" alt="logo-fibra" />
                    <ul class="versao">
                        <p>Versão: {{version}}</p>
                    </ul>
                </span>
                <span><img class="menu-collapsed d-none logo-half" src="/assets/img/logof.svg" alt="logo-fibra-2" /></span>
            </li>



            <div *ngIf='!simulacao'>

                <!-- MENU HOME -->
                <a routerLink="/dashboard" class="list-group-item list-group-item-action" id="ItemHome">
                    <div class="d-flex w-100 justify-content-start align-items-center">
                        <span><img class="icones-menu home-white" src="/assets/img/inicio.svg" alt="imagem-inicio" /></span>
                        <span class="menu-collapsed item-domenu-collapsed">Home</span>
                    </div>
                </a>

                <!-- MENU EXTRATO -->

                <a *fibraHasRole="'!SEM_CONTA, QUALQUER_CONTA.EXT.CONS_EXT_SAL, EXT.CONS_EXT_SAL, !PROBLEMA_CREDITO'"
                   id="ItemExtrato" aria-expanded="false"
                   class="list-group-item list-group-item-action flex-column align-items-start" routerLink="/extract">
                    <div class="d-flex w-100 justify-content-space-around align-items-center width-menu-hover">
                        <span>
                            <img class="icones-menu extrato-withe" src="/assets/svg/faturas.svg" alt="imagem-faturas" />
                        </span>
                        <span class="menu-collapsed item-domenu-collapsed">Extrato</span>
                    </div>
                </a>

                <a *fibraHasRole="'!SEM_CONTA, PROBLEMA_CREDITO'"
                   id="ItemExtrato" aria-expanded="false"
                   class="list-group-item list-group-item-action flex-column align-items-start" (click)="suspenseAccount('/extract')">
                    <div class="d-flex w-100 justify-content-space-around align-items-center width-menu-hover">
                        <span>
                            <img class="icones-menu extrato-withe" src="/assets/svg/faturas.svg" alt="imagem-faturas" />
                        </span>
                        <span class="menu-collapsed item-domenu-collapsed">Extrato</span>
                    </div>
                </a>

                <!-- MENU TRANSACOES -->

                <a *fibraHasRole="'!SEM_CONTA,
          QUALQUER_CONTA.PIX.CONS_CHAVE,
          QUALQUER_CONTA.PIX.CAD_CHAVE,
          QUALQUER_CONTA.PIX.EXC_CHAVE,
          QUALQUER_CONTA.PIX.TRANSF_PIX_CHAVE,
          QUALQUER_CONTA.PIX.TRANSF_PIX_MSM_TIT,
          QUALQUER_CONTA.PIX.TRANS_PIX_OTR_TIT,
          QUALQUER_CONTA.PIX.TRANSF_PIX_MSM_TIT_BC,
          QUALQUER_CONTA.PIX.TRANSF_PIX_OTR_TIT_BC,
          QUALQUER_CONTA.PIX.CONS_SOLIC,
          QUALQUER_CONTA.TRANSF.TRANSF_MSM_TITU,
          QUALQUER_CONTA.TRANSF.TRANSF_OTR_TITU,
          QUALQUER_CONTA.TRANSF.EXC_BEN,
          QUALQUER_CONTA.TRANSF.TRANSF_MSM_TIT_OTR_BC,
          QUALQUER_CONTA.TRANSF.TRANSF_OTR_TIT_BC,
          QUALQUER_CONTA.PAG_BOL.REALIZAR_PAG_BOL,
          QUALQUER_CONTA.PAG_LOTE.TRANS_ARQ,
          QUALQUER_CONTA.PAG_LOTE.CONS_ARQ_REM,
          QUALQUER_CONTA.PAG_LOTE.CONS_ARQ_RET,
          QUALQUER_CONTA.PAG_LOTE.CONS_AR_LIQ,
          QUALQUER_CONTA.APROV_TRANS.CONS_TRANS,
          QUALQUER_CONTA.COMP.CONS_COMP,
          PIX.CONS_CHAVE,
          PIX.CAD_CHAVE,
          PIX.EXC_CHAVE,
          PIX.TRANSF_PIX_CHAVE,
          PIX.TRANSF_PIX_MSM_TIT,
          PIX.TRANS_PIX_OTR_TIT,
          PIX.TRANSF_PIX_MSM_TIT_BC,
          PIX.TRANSF_PIX_OTR_TIT_BC,
          PIX.CONS_SOLIC,
          TRANSF.TRANSF_MSM_TITU,
          TRANSF.TRANSF_OTR_TITU,
          TRANSF.EXC_BEN,
          TRANSF.TRANSF_MSM_TIT_OTR_BC,
          TRANSF.TRANSF_OTR_TIT_BC,
          PAG_BOL.REALIZAR_PAG_BOL,
          PAG_LOTE.TRANS_ARQ,
          PAG_LOTE.CONS_ARQ_REM,
          PAG_LOTE.CONS_ARQ_RET,
          PAG_LOTE.CONS_AR_LIQ,
          APROV_TRANS.CONS_TRANS,
          COMP.CONS_COMP,
          !REST_CRED.100,
          !REST_CRED.101
          '"
                   id="ItemTransferencia" data-submenu="submenu-transfer" aria-expanded="false"
                   class="list-group-item list-group-item-action flex-column align-items-start"
                   (click)="toggleSubmenu($event, '#submenu-transfer')">
                    <div class="d-flex w-100 justify-content-space-around align-items-center width-menu-hover">
                        <span>
                            <img class="icones-menu transactions-white" src="/assets/svg/transacoes.svg" alt="imagem-transações" />
                        </span>
                        <span class="menu-collapsed item-domenu-collapsed">
                            Transações
                            <span style="float: right">
                                <img class="classmudar2 setinha" src="assets/svg/arrow_down.svg" alt="imagem-seta-baixo" />
                            </span>
                        </span>
                    </div>
                </a>

                <div id="submenu-transfer" class="collapse sidebar-submenu">
                    <a routerLink="/pix" class="list-group-item list-group-item-action text-white submenus"
                       *fibraHasRole="'!SEM_CONTA,
          QUALQUER_CONTA.PIX.CONS_CHAVE,
          QUALQUER_CONTA.PIX.CAD_CHAVE,
          QUALQUER_CONTA.PIX.EXC_CHAVE,
          QUALQUER_CONTA.PIX.TRANSF_PIX_CHAVE,
          QUALQUER_CONTA.PIX.TRANSF_PIX_MSM_TIT,
          QUALQUER_CONTA.PIX.TRANS_PIX_OTR_TIT,
          QUALQUER_CONTA.PIX.CONS_SOLIC,
          QUALQUER_CONTA.PIX.LIMITE_PIX,
          PIX.CONS_CHAVE,
          PIX.CAD_CHAVE,
          PIX.EXC_CHAVE,
          PIX.TRANSF_PIX_CHAVE,
          PIX.TRANSF_PIX_MSM_TIT,
          PIX.TRANS_PIX_OTR_TIT,
          PIX.CONS_SOLIC,
          PIX.LIMITE_PIX,
          !REST_CRED.100,
          !REST_CRED.101
          '">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Pix
                        </span>
                    </a>

                    <a id="transferencia_com_restricao_credito" (click)="suspenseAccount('/transfer')" class="list-group-item list-group-item-action text-white submenus" *fibraHasRole="'!SEM_CONTA, PROBLEMA_CREDITO'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Transferência
                        </span>
                    </a>

                    <a id="transferencia_sem_restricao_credito" routerLink="/transfer" class="list-group-item list-group-item-action text-white submenus"
                       *fibraHasRole="'
            !SEM_CONTA,
            QUALQUER_CONTA.TRANSF.TRANSF_MSM_TITU,
            QUALQUER_CONTA.TRANSF.TRANSF_OTR_TITU,
            QUALQUER_CONTA.TRANSF.EXC_BEN,
            QUALQUER_CONTA.TRANSF.TRANSF_MSM_TIT_OTR_BC,
            QUALQUER_CONTA.TRANSF.TRANSF_OTR_TIT_BC,
            TRANSF.TRANSF_MSM_TIT_OTR_BC,
            TRANSF.TRANSF_OTR_TIT_BC,
            TRANSF.TRANSF_MSM_TITU,
            TRANSF.TRANSF_OTR_TITU,
            TRANSF.EXC_BEN,
            !REST_CRED.100,
            !REST_CRED.101,
            !PROBLEMA_CREDITO'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Transferência
                        </span>
                    </a>

                    <a id="pagamento_boleto_com_restricao_credito" (click)="suspenseAccount('/payment')" class="list-group-item list-group-item-action text-white submenus" *fibraHasRole="'!SEM_CONTA, PROBLEMA_CREDITO'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Pagamento de boletos
                        </span>
                    </a>

                    <a id="pagamento_boleto_sem_restricao_credito" routerLink="/payment" class="list-group-item list-group-item-action text-white submenus"
                       *fibraHasRole="'
            !SEM_CONTA,
            QUALQUER_CONTA.PAG_BOL.REALIZAR_PAG_BOL,
            PAG_BOL.REALIZAR_PAG_BOL,
            !PROBLEMA_CREDITO'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Pagamento de boletos
                        </span>
                    </a>

                    <a id="pagamento_lote_com_restricao_credito" (click)="suspenseAccount('/payment-batch')" class="list-group-item list-group-item-action text-white submenus" *fibraHasRole="'!SEM_CONTA, PROBLEMA_CREDITO'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Pagamento em lote
                        </span>
                    </a>

                    <a id="pagamento_lote_sem_restricao_credito" routerLink="/payment-batch" class="list-group-item list-group-item-action text-white submenus"
                       *fibraHasRole="'
            !SEM_CONTA,
            QUALQUER_CONTA.PAG_LOTE.TRANS_ARQ,
            QUALQUER_CONTA.PAG_LOTE.CONS_ARQ_REM,
            QUALQUER_CONTA.PAG_LOTE.CONS_ARQ_RET,
            QUALQUER_CONTA.PAG_LOTE.CONS_AR_LIQ,
            PAG_LOTE.TRANS_ARQ,
            PAG_LOTE.CONS_ARQ_REM,
            PAG_LOTE.CONS_ARQ_RET,
            PAG_LOTE.CONS_AR_LIQ,
            !PROBLEMA_CREDITO
            '">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Pagamento em lote
                        </span>
                    </a>

                    <a (click)="suspenseAccount('/transaction-approval')" class="list-group-item list-group-item-action text-white submenus"
                       *fibraHasRole="'
                !SEM_CONTA,
                QUALQUER_CONTA.APROV_TRANS.CONS_TRANS,
                APROV_TRANS.CONS_TRANS,
                !REST_CRED.100,
                !REST_CRED.101'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Aprovação de transações
                        </span>
                    </a>

                    <a routerLink="/receipt" class="list-group-item list-group-item-action text-white submenus"
                       *fibraHasRole="'!SEM_CONTA,
            QUALQUER_CONTA.COMP.CONS_COMP,
            COMP.CONS_COMP,
            !REST_CRED.100,
            !REST_CRED.101'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Comprovantes
                        </span>
                    </a>

                    <a (click)="suspenseAccount('/change-transactional-limits')" class="list-group-item list-group-item-action text-white submenus"
                    *fibraHasRole="'
             !SEM_CONTA,
             QUALQUER_CONTA.APROV_TRANS.CONS_TRANS,
             APROV_TRANS.CONS_TRANS,
             !REST_CRED.100,!OPERADOR,
             !REST_CRED.101'">
                     <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                        Alterar limites transacionais
                     </span>
                 </a>
                </div>

                <!-- MENU COBRANCA -->
                <a id="ItemCobranca" data-submenu="submenu-charge" aria-expanded="false"
                   class="list-group-item list-group-item-action flex-column align-items-start"
                   (click)="toggleSubmenu($event, '#submenu-charge')"
                   *fibraHasRole="'!SEM_CONTA,
          QUALQUER_CONTA.COB.CAD_TIT,
          QUALQUER_CONTA.COB.CONS_INST_ADIC,
          QUALQUER_CONTA.COB.CONS_POS_CART,
          QUALQUER_CONTA.COB.CONS_ARQ_TRANS,
          QUALQUER_CONTA.COB.GER_SEG_BOL,
          QUALQUER_CONTA.COB.TRANS_ARQ,
          QUALQUER_CONTA.COB.CONS_FRANC,
          QUALQUER_CONTA.COB.CONS_ARQ_REM,
          QUALQUER_CONTA.COB.CONS_ARQ_RET,
          COB.CAD_TIT,
          COB.CONS_INST_ADIC,
          COB.CONS_POS_CART,
          COB.CONS_ARQ_TRANS,
          COB.GER_SEG_BOL,
          COB.TRANS_ARQ,
          COB.CONS_FRANC,
          COB.CONS_ARQ_REM,
          COB.CONS_ARQ_RET'">
                    <div class="d-flex w-100 justify-content-space-around align-items-center width-menu-hover">
                        <span>
                            <img class="icones-menu cobranca-withe" src="/assets/svg/coin.svg" alt="imagem-moeda" />
                        </span>
                        <span class="menu-collapsed item-domenu-collapsed">
                            Cobrança
                            <span style="float: right">
                                <img class="classmudar setinha" src="assets/svg/arrow_down.svg" alt="imagem-seta-baixo" />
                            </span>
                        </span>
                    </div>
                </a>
                <div id="submenu-charge" class="collapse sidebar-submenu">
                    <a (click)="suspenseAccount('/charge-archive')" class="list-group-item list-group-item-action text-white submenus"
                       *fibraHasRole="'!SEM_CONTA,
          QUALQUER_CONTA.COB.TRANS_ARQ,
          QUALQUER_CONTA.COB.CONS_ARQ_REM,
          QUALQUER_CONTA.COB.CONS_ARQ_RET,
          COB.TRANS_ARQ,
          COB.CONS_ARQ_REM,
          COB.CONS_ARQ_RET'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Transferências de arquivos
                        </span>
                    </a>
                    <a routerLink="/charge" class="list-group-item list-group-item-action text-white submenus"
                       *fibraHasRole="'!SEM_CONTA,
          QUALQUER_CONTA.COB.CAD_TIT,
          COB.CAD_TIT'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Cadastrar título
                        </span>
                    </a>
                    <a routerLink="/query" class="list-group-item list-group-item-action text-white submenus"
                       *fibraHasRole="'!SEM_CONTA,
            QUALQUER_CONTA.COB.CONS_INST_ADIC,
            QUALQUER_CONTA.COB.CONS_POS_CART,
            QUALQUER_CONTA.COB.CONS_ARQ_TRANS,
            QUALQUER_CONTA.COB.CONS_FRANC,
            COB.CONS_INST_ADIC,
            COB.CONS_POS_CART,
            COB.CONS_ARQ_TRANS,
            COB.CONS_FRANC'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Consulta
                        </span>
                    </a>
                    <a routerLink="/duplicate-bill" class="list-group-item list-group-item-action text-white submenus"
                       *fibraHasRole="'!SEM_CONTA,
          QUALQUER_CONTA.COB.GER_SEG_BOL,
          COB.GER_SEG_BOL'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Segunda via de boleto
                        </span>
                    </a>
                </div>

                <!-- MENU PRODUTOS -->
                <a id="ItemProducts" data-submenu="submenu-products" aria-expanded="false"
                   class="list-group-item list-group-item-action flex-column align-items-start"
                   (click)="toggleSubmenu($event, '#submenu-products')"
                   *fibraHasRole="'CAP_AGIL.SIM_SOL_CONTR,CAP_AGIL.ACOMP_SOL,DER.CONS_DER,COMERCIO_EXTERIOR.CONSULTAR_POSICAO_CARTEIRA,COM_EXT.CONS_CAMB_PR,COM_EXT.CONS_IMP,COM_EXT.CONS_EXP,COM_EXT.CONS_PAG_REC,COM_EXT.CONS_GAR'">
                    <div class="d-flex w-100 justify-content-space-around align-items-center width-menu-hover">
                        <div class="products-icon">
                            <span class="bullets-more">
                                <img class="icones-menu products-withe" src="/assets/svg/icon_more.svg" alt="imagem-more" />
                                <img class="icones-menu products-withe margin-products" src="/assets/svg/icon_more.svg"
                                     alt="imagem-more" />
                                <img class="icones-menu products-withe" src="/assets/svg/icon_more.svg" alt="imagem-more" />
                            </span>
                            <span class="bullets-more paddings">
                                <img class="icones-menu products-withe" src="/assets/svg/icon_more.svg" alt="imagem-more" />
                                <img class="icones-menu products-withe margin-products" src="/assets/svg/icon_more.svg"
                                     alt="imagem-more" />
                                <img class="icones-menu products-withe" src="/assets/svg/icon_more.svg" alt="imagem-more" />
                            </span>
                            <span class="bullets-more">
                                <img class="icones-menu products-withe" src="/assets/svg/icon_more.svg" alt="imagem-more" />
                                <img class="icones-menu products-withe" src="/assets/svg/icon_more.svg" alt="imagem-more" />
                                <img class="icones-menu products-withe" src="/assets/svg/icon_more.svg" alt="imagem-more" />
                            </span>
                        </div>
                        <span class="menu-collapsed item-domenu-collapsed">
                            Produtos
                            <span style="float: right">
                                <img class="classmudar2 setinha" src="assets/svg/arrow_down.svg" alt="imagem-seta-baixo" />
                            </span>
                        </span>
                    </div>
                </a>
                <div id="submenu-products" class="collapse sidebar-submenu">
                    <a routerLink="/foreign-trade" class="list-group-item list-group-item-action text-white submenus"
                       *fibraHasRole="'COMERCIO_EXTERIOR.CONSULTAR_POSICAO_CARTEIRA,
            COM_EXT.CONS_CAMB_PR,
            COM_EXT.CONS_IMP,
            COM_EXT.CONS_EXP,
            COM_EXT.CONS_PAG_REC,
            COM_EXT.CONS_GAR'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">Comércio Exterior</span>
                    </a>
                    <a *fibraHasRole="'!SEM_CONTA,CAP_AGIL.SIM_SOL_CONTR,CAP_AGIL.ACOMP_SOL'" routerLink="/capital-agil"
                       class="list-group-item list-group-item-action text-white submenus">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">Capital Ágil</span>
                    </a>
                    <a routerLink="/derivative" class="list-group-item list-group-item-action text-white submenus"
                       *fibraHasRole="'DER.CONS_DER'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">Derivativos</span>
                    </a>
                </div>

                <!-- MENU INVESTIMENTOS -->
                <a href="#submenu6" id="ItemInvestimento" data-toggle="collapse" aria-expanded="false"
                   class="list-group-item list-group-item-action flex-column align-items-start" routerLink="/investments"
                   (click)="changeIcon('investments')"
                   *fibraHasRole="'INVEST.CONS_INV'">
                    <div class="d-flex w-100 justify-content-start align-items-center">
                        <span>
                            <img class="icones-menu investimento-withe" src="/assets/svg/investments.svg" alt="imagem-investimento" />
                        </span>
                        <span class="menu-collapsed item-domenu-collapsed">Investimentos</span>
                    </div>
                </a>

                <!-- MENU OPERAÇÕES DE CREDITO -->
                <a id="ItemLoans" routerLink="/loans" class="list-group-item list-group-item-action"
                   *fibraHasRole="'OP_CRED.CONS_OP_CRED,OP_CRED.MAP_GAR'">
                    <div class="d-flex w-100 justify-content-space-around align-items-center width-menu-hover">
                        <span><img class="icones-menu loan-white" src="/assets/svg/icon-loans.svg" alt="imagem-loans" /></span>
                        <span class="menu-collapsed item-domenu-collapsed">Operações de Crédito</span>
                    </div>
                </a>


                <!-- MENU MAIS -->
                <a id="ItemMais" data-submenu="submenu-more" aria-expanded="false"
                   class="list-group-item list-group-item-action flex-column align-items-start"
                   (click)="toggleSubmenu($event, '#submenu-more')"
                   *fibraHasRole="'INF_REND.CONS_INF_REND,PF'">
                    <div class="d-flex w-100 justify-content-space-around align-items-center width-menu-hover">
                        <span class="bullets-more">
                            <img class="icones-menu more-withe" src="/assets/svg/icon_more.svg" alt="imagem-more" />
                            <img class="icones-menu more-withe" src="/assets/svg/icon_more.svg" alt="imagem-more" />
                            <img class="icones-menu more-withe" src="/assets/svg/icon_more.svg" alt="imagem-more" />
                        </span>
                        <span class="menu-collapsed item-domenu-collapsed">
                            Mais
                            <span style="float: right">
                                <img class="classmudar setinha" src="assets/svg/arrow_down.svg" alt="imagem-seta-baixo" />
                            </span>
                        </span>
                    </div>
                </a>
                <div id="submenu-more" class="collapse sidebar-submenu">
                    <a routerLink="/income-report" class="list-group-item list-group-item-action text-white submenus"
                       *fibraHasRole="'INF_REND.CONS_INF_REND'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Informe de rendimentos
                        </span>
                    </a>

                    <a routerLink="/registrato" class="list-group-item list-group-item-action text-white submenus"
                       *fibraHasRole="'PF'">
                        <span class="menu-collapsed item-domenu-collapsed submenu-ativo-hover">
                            Registrato
                        </span>
                    </a>
                </div>


                <!-- MENU OPENBANKING -->
                <a id="ItemOpenBanking" aria-expanded="false" class="list-group-item list-group-item-action flex-column align-items-start" routerLink="/openbanking" [ngClass]="{'menu-active': openbanking.isActive || openbankingpaymentrequest.isActive }">
                    <div class="d-flex w-100 justify-content-space-around align-items-center width-menu-hover">
                        <span>
                            <img class="show-when-menu-collapsed d-none icones-menu openbanking-O" src="{{'/assets/svg/' + ((openbanking.isActive || openbankingpaymentrequest.isActive) ? 'openbanking-O-white' : 'openbanking-O') + '.svg'}}" alt="imagem-faturas" />
                            <img class="menu-collapsed d-none icones-menu openbanking-menu" src="{{'/assets/svg/' + ((openbanking.isActive || openbankingpaymentrequest.isActive) ? 'openfinancemenu-white' : 'openfinancemenu') + '.svg'}}" alt="imagem-faturas" />
                        </span>
                    </div>
                </a>
                <a routerLink="/openbanking" routerLinkActive #openbanking="routerLinkActive" style="display: none"></a>
                <a routerLink="/openbanking-paymentrequest" routerLinkActive #openbankingpaymentrequest="routerLinkActive" style="display: none"></a>


            </div>

        </ul>
    </div>
</div>