import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DropdownList } from '@src/core/domain/business-models';
import {
  AdminUserAccount,
  AdminUserAccountPermissoes,
  AdminUserFunctionalities,
  AdminUserFunctionalitiesChecked,
  AdminUserNotAccount,
  AdminUserModel,
  AdminUserListModel,
} from '@src/core/domain/admin-user/admin-user.model';
import { GetUserDetailsUseCase } from '@src/core/usecases/admin-user/get-user-details.usecase';
import { GetAllAccountTypeUsecase } from '@src/core/usecases/account/get-all-accountTypes.usecase';
import { GetFunctionalitiesAccountUseCase } from '@src/core/usecases/admin-user/get-functionalities-account.usecase';
import { GetFunctionalitiesNotAccountUseCase } from '@src/core/usecases/admin-user/get-functionalities-not-account.usecase';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { AccordionComponent } from '@src/presentation/web/components/accordion/accordion.component';
import { RegisterAdminPageComponent } from '@src/presentation/web/pages/register-admin/register-admin.component';
import { Observable, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { GetUsersUseCase } from '@src/core/usecases/admin-user/get-users.usecase';
import { GetGroupDetailsUseCase } from '@src/core/usecases/admin-user/get-group-details.usecase';
import { isArray } from 'rxjs/internal-compatibility';
import { AccountTypeModel } from '@src/core/domain/account/accountType.model';

@Component({
  selector: 'fibra-register-group-admin',
  templateUrl: './register-group-admin.component.html',
  styleUrls: ['./register-group-admin.component.scss'],
})
export class RegisterGroupAdminPageComponent implements OnInit {
  public footerOption: any;
  public countChecked = 0;
  public addedUserList: Array<any> = [];
  public isAccountChecked: boolean;
  public isUserChecked: boolean;
  public userList = [];
  public randHack: string;
  public model: any;
  public loading = false;
  public errorUserInput = true;
  public searchArr: boolean;
  public groupName: string;
  public step;
  public maxStep = 2;
  public currentStep = 1;
  public newAccount: any;
  public listAccount: any;
  public arrayAccount: any;
  public typeColorToast: string;
  public typeAccount: number;
  public qtdEmpty = 0;
  public qtdError = 0;
  public boolValidateStep1 = false;
  public arrayAccountPayload: Array<AdminUserAccount> = [];
  public arrayNotAccountPayload: Array<AdminUserAccountPermissoes> = [];
  public arrayFunctionalities: any;
  public arrayFunctionalitiesAccount: any;
  public arrayFunctionalitiesNotAccount: any;
  public showModalValidation = false;
  public optionsAccount: DropdownList;
  public isEditMode = false;
  public groupDetails: any;
  public accountsCheckbox: Array<any> = [];
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  public BLUECOLOR = '#6CBED9';
  public isStepOneOk = false;
  public isStepTwoOk = false;
  public apiCallback = 14;
  public buttonName = 'Cadastrar grupo';
  public isFirstName = true;
  public groupId;
  private _obsFunctionalitiesAccount: Observable<AdminUserModel>;
  private _obsFunctionalitiesNotAccount: Observable<AdminUserModel>;
  private _obsgetAllAccountTypes: Observable<AccountTypeModel>;
  private _obsgetAdminUserList: Observable<AdminUserListModel>;

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  @ViewChild(AccordionComponent, null) accordion: AccordionComponent;

  constructor(
    private getUserDetails: GetUserDetailsUseCase,
    private router: Router,
    private route: ActivatedRoute,
    private getAllAccountTypes: GetAllAccountTypeUsecase,
    private getFunctionalitiesAccount: GetFunctionalitiesAccountUseCase,
    private getFunctionalitiesNotAccount: GetFunctionalitiesNotAccountUseCase,
    private getAdminUserList: GetUsersUseCase,
    private getGroupDetails: GetGroupDetailsUseCase
  ) { }

  ngOnInit() {
    this.init();
  }

  private init() {
    this.route.params.subscribe(params => {
      this.groupName = params.nameGroup;
      this.groupId = params.id;
      this.isEditMode = params.editar;
      this.maxStep = 2;

      if (params.editar) {
        this.buttonName = 'Salvar e fechar';
      }
    });

    this.loading = true;
    this.step = [
      {
        step: 1,
        name: 'Contas e permissões',
      },
      {
        step: 2,
        name: 'Usuários',
      },
    ];
    this.listAccount = [];
    this.arrayAccount = [];
    this.optionsAccount = [];    
    this.requestFunctionalitiesAccount();
    this.requestFunctionalitiesNotAccount();
    this.requestAccount();
    this.requestAdminUserList();
    this.editMode();
  }

  private editMode = () => {
    this.route.params.subscribe(
      (params) => {
        this.groupName = params.nameGroup;
        this.groupId = params.id;
        this.isEditMode = params.editar;
        this.maxStep = 2;
        if (params.editar) {
          this.apiCallback = 15;
          this.buttonName = 'Salvar e fechar';
          this.getDetails(params.id);
        } else {
          this.loading = false;
        }
      },
      (error) => (this.isEditMode = false)
    );
  };

  private requestAdminUserList(): void {
    const success = (res) => {
      if (res && res.data && res.data.length > 0) {
        const list = res.data;
        list.map((e) => {
          this.userList.push({
            idUser: e.cod_pessoa,
            userName: e.nome_pessoa,
            userMail: e.email,
            checked: false,
          });
        });
      }
    };
    (this._obsgetAdminUserList = this.getAdminUserList.execute()).subscribe(success, this.requestError);
  }

  public getDetails = (id) => {
    const success = (res) => {
      if (res && res.data) {
        this.groupDetails = res.data;

        forkJoin([this._obsFunctionalitiesAccount, this._obsFunctionalitiesNotAccount, this._obsgetAdminUserList, this._obsgetAllAccountTypes]).subscribe(() => {
          this.mountListAccount();
          this.mountNotAccount();
          this.mountUsers();
        });
      }
      this.loading = false;
    };
    const error = (err) => {
      this.loading = false;
    };
    const params = {
      cod_pessoa: id,
    };
    this.getGroupDetails.execute(params).subscribe(success, error);
  };

  private requestFunctionalitiesAccount() {
    this.arrayFunctionalitiesAccount = [];

    (this._obsFunctionalitiesAccount = this.getFunctionalitiesAccount.execute())
      .subscribe(value => this.arrayFunctionalitiesAccount = value.data.map((e) => {
        return {
          cod_funcionalidade: e.cod_funcionalidade,
          desc_funcionalidade: e.desc_funcionalidade,
          operacoes: this.mountOperacoes(e.operacoes)
        }
      }));
  }

  private requestFunctionalitiesNotAccount() {
    const success = (value) => {
      this.arrayFunctionalitiesNotAccount = [];
      value.data.map((e, i) => {
        this.arrayFunctionalitiesNotAccount.push({
          cod_funcionalidade: e.cod_funcionalidade,
          desc_funcionalidade: e.desc_funcionalidade,
          operacoes: this.mountOperacoes(e.operacoes),
        });
      });
    };
    (this._obsFunctionalitiesNotAccount = this.getFunctionalitiesNotAccount.execute())
      .subscribe(success, this.requestError);
  }

  private mountUsers = () => {
    const pessoas = this.groupDetails.pessoas_relacionadas;
    if (isArray(pessoas) && pessoas.length > 0) {
      pessoas.map((e) => {
        this.addedUserList.push({
          idUser: e.cod_pessoa,
          userName: e.nome_pessoa,
          userMail: e.email || 'email@opah.com.br',
          checked: false,
        });
      });
    }
  };

  public mountListAccount() {
    if (this.isEditMode) {
      if (
        isArray(this.groupDetails.contas) &&
        this.groupDetails.contas.length > 0
      ) {
        this.groupDetails.contas.forEach((conta) => {
          // Mount account rules
          this.listAccount.push({
            account: {
              num_conta: conta.numero_conta,
              desc_conta: conta.descricao_conta,
              exibicao: conta.exibicao
            },
            functionalities: this.getFunctionalitiesChecked(conta.numero_conta, conta.descricao_conta, this.arrayFunctionalitiesAccount, conta.funcionalidades),
          });
        });
      }
    }
  }

  public mountNotAccount() {
    let checked = true;
    let funcionalidadeId: number;
    let operacaoId: number;
    let params: AdminUserNotAccount;
    this.groupDetails.permissoes_nao_atreladas.forEach((notAccount) => {
      checked = true;
      funcionalidadeId = notAccount.cod_funcionalidade;
      if (
        !notAccount.cod_funcionalidade ||
        notAccount.cod_funcionalidade === 0
      ) {
        return;
      }
      notAccount.operacao.forEach((op) => {
        operacaoId = op.cod_operacao;
        params = {
          checked,
          operacao_id: operacaoId,
          funcionalidade_id: funcionalidadeId,
        };
        this.changeRuleNotAccount(params);
      });
    });
  }

  public getFunctionalitiesChecked(numConta, tipo_conta, arrayFunctionalities, arrayFunctionalitiesChecked) {
    const params: AdminUserFunctionalitiesChecked = {};
    const newArrayFunctionalitiesChecked = [];

    if (!isArray(arrayFunctionalities)) {
      return;
    }

    arrayFunctionalities.forEach((functionality) => {
      if (functionality.cod_funcionalidade != 8 || tipo_conta == 'CHEQUE EMPRESA' || tipo_conta == 'CONTA CORRENTE') {
        newArrayFunctionalitiesChecked.push({
          cod_funcionalidade: functionality.cod_funcionalidade,
          desc_funcionalidade: functionality.desc_funcionalidade,
          operacoes: functionality.operacoes,
        });
      }
    });

    if (!isArray(newArrayFunctionalitiesChecked)) {
      return [];
    }

    newArrayFunctionalitiesChecked.forEach((newFunc) => {
      newFunc.operacoes.forEach((newOp) => {
        arrayFunctionalitiesChecked.forEach((checkFunc) => {
          if (newFunc.cod_funcionalidade === checkFunc.cod_funcionalidade) {
            checkFunc.operacao.forEach((operacao) => {
              if (newOp.cod_operacao === operacao.cod_operacao) {
                newOp.status = true;
                params.checked = true;
                params.operacao_id = newOp.cod_operacao;
                params.collapse_id = numConta;
                params.acessoPadrao = false; // TODO: Esse valor deve vim do serviço!
                params.funcionalidade_id = newFunc.cod_funcionalidade;
                // add rule payload
                this.changeRule(params);
              }
            });
          }
        });
      });
    });

    return newArrayFunctionalitiesChecked;
  }

  private backToTop() {
    window.scrollTo(0, 0);
  }

  public validateStepOne = () => {
    this.isFirstName = false;
    if (this.isStepOneOk) this.nextStep();
  }

  public nextStep() {
    this.backToTop();
    this.isFirstName = false;
    if (this.currentStep < this.maxStep) {
      this.currentStep++;

      if (this.currentStep === 3) {
        this.requestAccount();
      }
    }
  }

  public prevStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    } else {
      this.router.navigate(['/admin-user']);
    }
  }

  public requestAccount(): void {
    const success = (value) => {
      value.data.map((e, i) => {
        this.optionsAccount.push({
          name: RegisterAdminPageComponent.formatValue(e.num_conta, e.exibicao),
          value: e.num_conta,
          selected: e.selecionado,
        });

        if (e.selecionado) {
          this.typeAccount = e.num_conta;
        }
        this.arrayAccount.push({
          account: e,
          functionalities: this.arrayFunctionalitiesAccount,
        });
      });
    };
    (this._obsgetAllAccountTypes = this.getAllAccountTypes.execute())
      .subscribe(success, this.requestError);
  }

  private requestError = (err) => {
    if ((err && err.status === 401) || (err && err.status === 0)) {
      this.router.navigate(['/']);
    }
  };

  public mountOperacoes(operacoes) {
    const newOperacoes = [];
    operacoes.forEach((op) => {
      newOperacoes.push({
        cod_operacao: op.cod_operacao,
        desc_operacao: op.desc_operacao,
        status: false,
      });
    });
    return newOperacoes;
  }

  public addAccount() {
    let flag = false;

    if (!this.findAccount()) {
      this.arrayAccount.forEach(account => {
        account.functionalities = this.arrayFunctionalitiesAccount;

        if (account.account.desc_conta != 'CONTA CORRENTE' && account.account.desc_conta != 'CHEQUE EMPRESA') {
          account.functionalities = account.functionalities.filter(f => f.cod_funcionalidade != 8);
        }

        if (account.account.num_conta === this.newAccount) {
          this.listAccount.push(account);
          flag = true;
          this.validateStep1();
        }
      });
    }

    if (flag) {
      this.typeColorToast = this.BLUECOLOR;
      this.toast.callModalMessage(
        null,
        'Conta adicionada com sucesso.',
        null,
        false,
        true
      );
    }
  }

  private findAccount() {
    let flag = false;
    let qtdAccount = 0;
    this.listAccount.forEach((accounts) => {
      if (accounts.account.num_conta === this.newAccount) {
        qtdAccount++;
        flag = true;
      }
    });
    if (flag) {
      this.typeColorToast = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Conta já adicionada.',
        null,
        false,
        true
      );
    }
    return qtdAccount > 0;
  }

  public setDefaultAccount = (numConta, event) => {
    const checked = event.target.checked;
    if (checked) {
      this.arrayAccountPayload.forEach((account, a) => {
        account.acesso_padrao = account.numero_conta === numConta;
      });
    }
  };

  public setAccount(event) {
    const checked = event.target.checked;
    const numConta = event.target.value;
    if (checked) {
      this.accountsCheckbox.push({ num_conta: numConta });
    } else {
      this.accountsCheckbox.forEach((account, a) => {
        if (account.numConta === numConta) {
          this.accountsCheckbox.splice(a, 1);
        }
      });
    }
  }

  public delAccount(accountsCheckbox) {
    accountsCheckbox.forEach((item) => {
      this.listAccount.forEach((accounts, i) => {
        if (accounts.account.num_conta === item.num_conta) {
          this.listAccount.splice(i, 1);
        }
      });
      this.arrayAccountPayload.forEach((accounts, a) => {
        if (accounts.numero_conta === item.num_conta) {
          this.arrayAccountPayload.splice(a, 1);
        }
      });
    });
    this.accountsCheckbox = [];
  }

  public showPermitions(typeAccount, accountNumber) {
    if (typeAccount !== accountNumber) {
      RegisterAdminPageComponent.showBtnImport(accountNumber);
    }
  }

  public closeAccount(accountNumber) {
    RegisterAdminPageComponent.showBtnEdit(accountNumber);
  }

  public mountArrayOtherAccess() {
    return {
      permissoes: [
        {
          cod_funcionalidade: 0,
          operacoes: [
            {
              cod_operacao: 0,
            },
          ],
        },
      ],
    };
  }

  public importDefaultAccount(numConta: number) {
    let arrPermissions: any;
    let accounSelected: any;
    let index: number;
    let event: any;
    const newItensOnList = this.listAccount;
    this.arrayAccountPayload.forEach((account) => {
      if (account.acesso_padrao) {
        arrPermissions = account.permissoes;
      }
    });

    newItensOnList.map((e, i) => {
      if (e.account.num_conta === numConta) {
        e.functionalities.forEach(f => {
          f.operacoes.forEach(op => {
            op.status = false;
            event = {};
            event.checked = op.status;
            event.operacao_id = op.cod_operacao;
            event.collapse_id = numConta;
            event.acessoPadrao = false;
            event.funcionalidade_id = f.cod_funcionalidade;
            this.changeRule(event);
          });
        });
        e.functionalities.forEach(f => {
          arrPermissions.forEach(p => {
            if (f.cod_funcionalidade === p.cod_funcionalidade) {
              f.operacoes.forEach((op) => {
                p.operacoes.forEach((arrOp) => {
                  if (op.cod_operacao === arrOp.cod_operacao) {
                    op.status = true;
                    event = {};
                    event.checked = op.status;
                    event.operacao_id = op.cod_operacao;
                    event.collapse_id = numConta;
                    event.acessoPadrao = false;
                    event.funcionalidade_id = f.cod_funcionalidade;
                    this.changeRule(event);
                  }
                });
              });
            }
          });
        });
        index = i;
        accounSelected = e;
      }
    });
    this.listAccount.splice(index, 1, accounSelected);
  }

  public newArrayToSelected(numConta, obj) {
    let index: number;
    let accounSelected;
    this.listAccount.map((e, i) => {
      if (e.account.num_conta === numConta) {
        e.functionalities.forEach((f) => {
          if (f.cod_funcionalidade === obj.funcionalidade_id) {
            f.operacoes.forEach((op) => {
              if (op.cod_operacao === obj.operacao_id) {
                op.status = obj.checked;
              }
            });
          }
        });
        index = i;
        accounSelected = e;
      }
    });
    this.listAccount.splice(index, 1, accounSelected);
  }

  public closeModal = () => (this.showModalValidation = false);

  public securityRequest = () => {
    const userPayload = [];
    this.addedUserList.forEach((e) => {
      userPayload.push({ codigo_usuario: e.idUser });
    });

    const outrosAcessos =
      this.arrayNotAccountPayload.length > 0
        ? { permissoes: this.arrayNotAccountPayload }
        : {};

    return {
      desc_grupo: this.groupName,
      contas: this.arrayAccountPayload,
      outros_acessos: outrosAcessos,
      usuarios: userPayload,
      cod_grupo: this.groupId
    };
  };

  public sendRequest = () => {
    this.showModalValidation = true;
  };

  public securityResponse = (value) => {
    if (value.status === 'success') {
      this.successGroup(value);
    } else {
      this.errorGroup(value);
    }
  };

  private successGroup = (value) => {
    // this.router.navigate([
    //   '/admin-user',
    //   value && value.data && value.data.data,
    // ]);
    let status = 2;
    let msg = 'Grupo Cadastrado com sucesso.';
    if(this.isEditMode){
      msg = 'Grupo alterado com sucesso.'
    }
    if(value && value.data && value.data.data && value.data.data.status == 1){
      status = 1;
      if(this.isEditMode){
        msg = 'Alterado o grupo pendente. Os demais masters precisam aprovar o cadastro.'
      }else{
        msg = 'Cadastrado o grupo pendente. Os demais masters precisam aprovar o cadastro.'
      }
    }
    const userRoute = {
      page: '/admin-user',
      params: { type: 'user', status: status, msg }
    };
    this.router.navigate([userRoute.page, userRoute.params], { skipLocationChange: true, replaceUrl: false });
  };

  private errorGroup = (err) => {
    this.showModalValidation = false;
    if (err.error && err.error.message && err.error.message.length > 0) {
      this.toast.callModalMessage(null, `${err.error.message[0]}`);
    } else {
      this.toast.callModalMessage(null, 'Ocorreu um erro realizar a operação.');
    }
  };

  public changeRuleNew(event) {
    const checked = event.checked;
    const operacao_id = event.operacao_id;
    const numeroConta = event.collapse_id;
    const acessoPadrao = true;
    const funcionalidade_id = event.funcionalidade_id;
    this.listAccount.forEach((accounts, a) => {
      if (accounts.account.num_conta === numeroConta) {
        accounts.functionalities.forEach((funcionalidades, f) => {
          if (funcionalidades.cod_funcionalidade === funcionalidade_id) {
            funcionalidades.operacoes.forEach((operacoes, o) => {
              if (operacoes.cod_operacao === operacao_id) {
                operacoes.status = checked;
              }
            });
          }
        });
      }
    });
  }

  public isEmpty(campo) {
    if (this.boolValidateStep1) {
      if (typeof campo !== 'object') {
        if (Number.isInteger(campo)) {
          if (campo === 0) {
            this.qtdEmpty++;
            return true;
          }
        } else if (campo.trim() === '') {
          this.qtdEmpty++;
          return true;
        }
      }
    } else {
      return false;
    }
  }

  public isLength(campo, size) {
    if (this.boolValidateStep1 && !this.qtdEmpty) {
      if (campo.length >= size) {
        return false;
      } else {
        this.qtdError++;
        return true;
      }
    } else {
      this.qtdError++;
      return false;
    }
  }

  public changeRule(event) {
    const checked = event.checked;
    const operacao_id = event.operacao_id;
    const numeroConta = event.collapse_id;
    let acessoPadrao = false;
    const funcionalidadeId = event.funcionalidade_id;
    // this.newArrayToSelected(numeroConta, event);
    if (numeroConta === this.typeAccount) {
      acessoPadrao = true;
    }
    let existAccounts = false;
    let existOperacoes = false;
    let qtdOperacoes = 0;
    let qtdFuncionalidades = 0;
    let existFuncionalidades = false;
    this.arrayAccountPayload.forEach((accounts, a) => {
      if (accounts.numero_conta === numeroConta) {
        existAccounts = true;
        accounts.permissoes.forEach((permissao, p) => {
          qtdFuncionalidades++;
          if (permissao.cod_funcionalidade === funcionalidadeId) {
            existFuncionalidades = true;
            permissao.operacoes.forEach((operacao, o) => {
              qtdOperacoes++;
              if (operacao.cod_operacao === operacao_id) {
                existOperacoes = true;
                if (!checked) {
                  qtdOperacoes--;
                  this.arrayAccountPayload[a].permissoes[p].operacoes.splice(
                    o,
                    1
                  );
                }
              }
            });
            if (checked && !existOperacoes) {
              this.arrayAccountPayload[a].permissoes[p].operacoes.push({
                cod_operacao: operacao_id,
              });
            }
          }
          if (!checked && existFuncionalidades && !qtdOperacoes) {
            qtdFuncionalidades--;
            this.arrayAccountPayload[a].permissoes.splice(p, 1);
          }
        });
        if (checked && !existFuncionalidades) {
          this.arrayAccountPayload[a].permissoes.push({
            cod_funcionalidade: funcionalidadeId,
            operacoes: [
              {
                cod_operacao: operacao_id,
              },
            ],
          });
        }
      }
    });
    if (!existAccounts && !existOperacoes && !existFuncionalidades && checked) {
      this.arrayAccountPayload.push({
        numero_conta: numeroConta,
        acesso_padrao: acessoPadrao,
        permissoes: [
          {
            cod_funcionalidade: funcionalidadeId,
            operacoes: [
              {
                cod_operacao: operacao_id,
              },
            ],
          },
        ],
      });
    }
    this.validateStep1();
  }

  public changeRuleNotAccount(event) {
    const checked = event.checked;
    const funcionalidadeId = event.funcionalidade_id;
    const operacaoId = event.operacao_id;
    let existOperacao = false;
    let qtdOperacoes = 0;
    let qtdFuncionalidades = 0;
    let existFuncionalidade = false;
    this.arrayNotAccountPayload.forEach((permissao, p) => {
      qtdFuncionalidades++;
      if (permissao.cod_funcionalidade === funcionalidadeId) {
        existFuncionalidade = true;
        permissao.operacoes.forEach((operacao, o) => {
          qtdOperacoes++;
          if (operacao.cod_operacao === operacaoId) {
            existOperacao = true;
            if (!checked) {
              qtdOperacoes--;
              this.arrayNotAccountPayload[p].operacoes.splice(o, 1);
            }
          }
        });
        if (checked && !existOperacao) {
          this.arrayNotAccountPayload[p].operacoes.push({
            cod_operacao: operacaoId,
          });
        }
      }
      if (!checked && existFuncionalidade && !qtdOperacoes) {
        qtdFuncionalidades--;
        this.arrayNotAccountPayload.splice(p, 1);
      }
    });
    if (checked && !existFuncionalidade) {
      this.arrayNotAccountPayload.push({
        cod_funcionalidade: funcionalidadeId,
        operacoes: [
          {
            cod_operacao: operacaoId,
          },
        ],
      });
    }
    this.validateStep1();
  }

  public changeAllRule(event) {
    const acessoPadrao = true;
    const checked = event.checked;
    const numeroConta = event.collapse_id;
    const arrayFuncionalidade: Array<AdminUserFunctionalities> = [];

    let arrOperacoes: any = [];
    this.arrayAccountPayload.forEach((accounts, a) => {
      if (accounts.numero_conta === numeroConta) {
        this.arrayAccountPayload.splice(a, 1);
      }
    });
    if (checked) {
      this.listAccount.forEach((account) => {
        if (account.account.num_conta === numeroConta) {
          account.functionalities.forEach((funcionalidade) => {
            arrOperacoes = [];
            funcionalidade.operacoes.forEach((operacao) => {
              arrOperacoes.push({ cod_operacao: operacao.cod_operacao });
            });
            if (arrOperacoes.length) {
              arrayFuncionalidade.push({
                cod_funcionalidade: funcionalidade.cod_funcionalidade,
                operacoes: arrOperacoes,
              });
            }
          });
          this.arrayAccountPayload.push({
            numero_conta: numeroConta,
            acesso_padrao: acessoPadrao,
            permissoes: arrayFuncionalidade,
          });
        }
      });
    }
    
    this.validateStep1();
  }

  public search = (text$: Observable<string>) => {
    const text = text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => {
        this.errorUserInput = term === '' || term === null || term === ' ';
        const mapped =
          term === '' || term === null || term === ' '
            ? []
            : this.userList
              .filter((v) => {
                const arr = v.userMail.split('@');
                const userEmail = arr[0];
                return (
                  v.userName.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                  userEmail.toLowerCase().indexOf(term.toLowerCase()) > -1
                );
              })
              .slice(0, 10)
              .sort((a: any, b: any) => {
                if (a.userName < b.userName) {
                  return -1;
                }
                return 0;
              });
        this.searchArr = mapped.length > 0;
        return mapped;
      })
    );
    return text;
  };

  public formatter(x: { userName: string; userMail: string }) {
    return `${x.userName}`;
  }

  public selectDeleteFunction = () => {
    const { type, value } = this.footerOption;
    switch (type) {
      case 'SINGLEUSER':
        this.deleteUser(value);
        break;
      case 'MULTIPLEUSER':
        this.deleteUsers();
        break;
      case 'ACCOUNT':
        this.delAccount(value);
        break;
    }
  };

  public selectNoDeleteFunction = () => {
    const { type, value } = this.footerOption;
    this.listAccount.forEach(element => {
      var checkbox = document.getElementById("accountCheck-"+element.account.num_conta)
      checkbox['checked'] = false;
    });
    this.accountsCheckbox = [];
  };

  public validateInputUser = () => {
    this.errorUserInput = typeof this.model !== 'object' || !this.model;
  };

  public validateInputList() {
    return (
      !this.searchArr && typeof this.model === 'string' && this.model.length > 0
    );
  }

  public checkUser = () => {
    let flag = false;
    this.countChecked = 0;
    this.addedUserList.map((e) => {
      if (e.checked) {
        this.countChecked++;
        flag = true;
        this.isUserChecked = true;
      }
    });
    this.isUserChecked = flag;
    this.validateStep2();
  };

  public handleDelete = (value, type, text) => {
    this.footerOption = { value, type };
    this.typeColorToast = this.REDCOLOR;
    this.toast.dismissToast();
    this.toast.callModalMessage(null, text, null, true);
  };

  public addUser = () => {
    if (this.model && typeof this.model === 'object') {
      let flag = false;
      this.addedUserList.map((e) => {
        if (e.userMail === this.model.userMail) {
          flag = true;
        }
      });
      if (!flag) {
        this.addedUserList.push(this.model);
        this.typeColorToast = this.BLUECOLOR;
        this.toast.callModalMessage(
          null,
          'Usuário adicionado com sucesso.',
          null,
          false,
          true
        );
        this.model = null;
      } else {
        this.typeColorToast = this.REDCOLOR;
        this.toast.callModalMessage(
          null,
          'Usuário já adicionado.',
          null,
          false,
          true
        );
        this.model = null;
      }
    }
    this.validateStep2();
  };

  public deleteUser = (id) => {
    this.addedUserList.map((e, i) => {
      if (id === e.idUser) {
        this.addedUserList.splice(i, 1);
        if (this.isEditMode) {
          this.isStepTwoOk = true;
        }
        return;
      }
    });
    this.checkUser();
  };

  public deleteUsers = () => {
    if (this.addedUserList && this.addedUserList.length > 0) {
      for (let i = 0; i <= this.addedUserList.length - 1; i++) {
        if (this.addedUserList[i].checked) {
          this.addedUserList[i].checked = false;
          this.addedUserList.splice(i, 1);
          this.deleteUsers();
          if (this.isEditMode) {
            this.isStepTwoOk = true;
          }
          break;
        }
      }
    }
    this.checkUser();
  };

  public goToView = (view) => {
    this.router.navigate([view]);
  };

  public changeInputName = () => {
    this.isFirstName = false;
  }

  public validateStep1 = () => {
    let flagNotAccount = false;
    let flagAccount = false;
    if (this.groupName && this.groupName !== '') {
      flagNotAccount = this.arrayNotAccountPayload.length > 0;
      flagAccount =
        this.listAccount.length > 0 &&
        this.listAccount.length === this.arrayAccountPayload.length;
      if (flagNotAccount) {
        if (flagAccount) {
          this.isStepOneOk = true;
        } else {
          this.isStepOneOk = !flagAccount && this.listAccount.length === 0;
        }
      } else {
        if (flagAccount) {
          this.isStepOneOk = true;
        } else {
          this.isStepOneOk = false;
        }
        //this.isStepOneOk = flagAccount;
      }
    } else {
      this.isStepOneOk = false;
    }
  };

  public validateStep2 = () => {
    if (this.groupName && this.groupName !== '') {
      this.isStepTwoOk = this.addedUserList.length > 0;
    } else {
      this.isStepTwoOk = false;
    }
  };

  public difineTypeAction = (type, accounts) => {
    return type !== accounts.account.num_conta && this.listAccount.length > 1;
  };
}
