
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as $ from 'jquery';


// @Component({
//   selector: 'fibra-investiments-report',
//   templateUrl: './investiments-report.component.html',
//   styleUrls: ['./investiments-report.component.scss']
// })


// export class InvestimentsReportComponent implements OnInit, OnDestroy {

@Component({
  selector: 'investiments-report',
  templateUrl: './investiments-report.component.html',
  styleUrls: ['./investiments-report.component.scss']
})
export class InvestimentsReportComponent implements OnInit, OnDestroy {
  @Output() public closeReport = new EventEmitter();
  public reportData;
  public date = new Date();
  public day;
  public hours;
  public company;
  public hide = false;
  public readyToLoad = false;
  public listAllContracts = false;
  public informations;
  public dateReferenceToShow;
  public investimentsExtrato;
  public investimentsPosicao;


  constructor() { }

  ngOnInit() {

    //Inicializa Arrays
    this.investimentsExtrato = null;
    this.investimentsPosicao = null;

    this.hide = false;
    this.day = this.date.toLocaleDateString();
    this.hours = this.date.toLocaleTimeString();
    this.company = JSON.parse(sessionStorage.getItem('defaultCompany'));
    if (this.company[0].modality_account.length === 11) {
      this.company[0].modality_account = this.company[0].modality_account.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      this.company[0].modality_account = this.company[0].modality_account.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, '$1.$2.$3/$4-$5');
    }
    this.informations = JSON.parse(sessionStorage.getItem('informations_pdf'))
    this.investimentsExtrato = JSON.parse(sessionStorage.getItem('investiments_extrato_list_pdf'))
    this.investimentsPosicao = JSON.parse(sessionStorage.getItem('investiments_posicao_list_pdf'))
    console.log('this.investimentsPosicao: ',this.investimentsPosicao)

    this.readyToLoad = true;
    setTimeout(() => {
      this.generatePDF(this.informations.report_name)
    }, 2000);

  }

  ngOnDestroy() {
  }


  public generatePDF(reportName: string) {


    const data = document.getElementById('comprovante');


    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      html2canvas(data, {
        windowWidth: document.body.scrollWidth,
        windowHeight: document.body.scrollHeight,
        x: window.pageXOffset,
        y: window.pageYOffset,
      }).then((canvas) => {

        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF('p', 'mm');
        var position = 0;

        const imgData = canvas.toDataURL("image/jpeg")


        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        doc.save(reportName + '.pdf');

      });
    }
    else {

      html2canvas(data).then((canvas) => {
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF('p', 'mm');
        var position = 0;

        const imgData = canvas.toDataURL("image/png")


        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        doc.save(reportName + '.pdf');

      });

    }






    $("#ico-fechar").css({ display: "block" });
    this.goBack();

  }

  public goBack() {
    this.closeReport.emit('true');
  }
}

