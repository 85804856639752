import { Observable } from 'rxjs';
import {
  instructionListModel,
  ListQueryRequest,
} from '@src/core/domain/query/query.model';

export abstract class InstructionRepository {
  abstract getAllQueryInstruction(
    obj: ListQueryRequest
  ): Observable<instructionListModel>;
  abstract getAuthorizedQueryInstruction(
    obj: ListQueryRequest
  ): Observable<instructionListModel>;
  abstract getPendingQueryInstruction(
    obj: ListQueryRequest
  ): Observable<instructionListModel>;
  abstract getRefusedQueryInstruction(
    obj: ListQueryRequest
  ): Observable<instructionListModel>;
}
