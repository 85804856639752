import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountDataShare {
  public data: any;
  public currentAccount = undefined;

  public dataToShare = new BehaviorSubject(this.data);
  dataShared = this.dataToShare.asObservable();
  public accountShare = new BehaviorSubject(this.currentAccount);
  selectedAccountShared = this.accountShare.asObservable();

  constructor() {}

  setValue(data: any) {
    this.dataToShare.next(data);
  }

  setAccount(currentAccount: any) {
    this.accountShare.next(currentAccount);
  }

  clear() {
    this.dataToShare = new BehaviorSubject([]);
    this.dataShared = this.dataToShare.asObservable();
  }
}
