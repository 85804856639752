import { GetAllExtractsUsecase } from '@src/core/usecases/extract/get-all-extract.usecase';
import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnDestroy, ViewChild } from '@angular/core';
import { ExtractModel } from '@src/core/domain/extract/extract.model';
import * as Util from '@src/shared/util-common';
import * as moment from 'moment';
import { DailyPositionComponent } from '@src/presentation/web/pages/daily-position/daily-position.component';
import { truncateSync } from 'fs';
import { GetReceiptExtractPdfUsecase } from '@src/core/usecases/extract/get-receipt-extract-pdf-usecase';
import { ToastErrorMessageComponent } from '../../toast-error-message/toast-error-message.component';



@Component({
  selector: 'fibra-all-list',
  templateUrl: './all-list.component.html',
  styleUrls: ['./all-list.component.scss'],
})
export class AllListComponent implements OnChanges, OnDestroy {
  @Output() allEntries: EventEmitter<any>;
  @Input() params: string;
  @Input() balance: any;

  releases: Array<ExtractModel> = [];
  loading: boolean;
  hide: boolean;
  public iconPath = (cod: string, desc: string) => Util.iconPath(cod, desc);
  public data;
  public messageTransf = ''
  public conta = ''
  public agencia = ''
  public cpfCnpj=''
  public nomeRemet=''

  public formatCpfCnpj = (value: string) => Util.formatCpfCnpj(value);

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
    
  constructor(private getAllExtracts: GetAllExtractsUsecase, private getPdf: GetReceiptExtractPdfUsecase) {
    this.allEntries = new EventEmitter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.requestEntries();
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('extrato_all_pdf')
    sessionStorage.removeItem('informations_pdf')
  }

  /**
   * @description
   * Add a Title to componente on view
   */

  private requestEntries = () => {
    this.loading = true;
    this.getAllExtracts
      .execute(JSON.parse(this.params))
      .subscribe(this.requestSuccess, this.requestError, this.requestComplete);
  };

  private requestSuccess = (value: ExtractModel) => {
    this.releases = Util.verifyArray(value.data);
    this.allEntries.emit(value.data);
    this.hide = Util.isEmpty(this.releases);

    //PDF
    this.data = value.data
    sessionStorage.setItem('extrato_all_pdf', JSON.stringify(value.data));
    sessionStorage.setItem('informations_pdf', '{"exibir":"Tudo", "periodo": "'+Util.calcDiffInDays(this.params)+' dia(s)", "report_name":"Extrato","name":"extrato-all"}')

    //Excel
    let paramsFilter = JSON.parse(this.params)
    let dataInicioFilter  = paramsFilter.dataInicio;
    let dataFinalFilter = paramsFilter.dataFinal;

    let dataFinal = moment(dataFinalFilter, "YYYY-MM-DD").format("YYYY-MM-DD");
    let dataInicio = moment(dataInicioFilter, "YYYY-MM-DD").format("YYYY-MM-DD");
    sessionStorage.setItem('extract_excel', '{"tipo":"tudo", "dataInicio":"'+dataInicio+'", "dataFinal":"'+dataFinal+'"}')
    

  };

 

  public FormatcpfCnpj(v:string){
    
    v=v.replace(/\D/g,"")
    let v2= +(v)
    v = v2.toString();
    
    //Remove tudo o que não é dígito
    
 
    if (v.length <= 11) { //CPF

      

      v  = v.padStart(11,'0')
 
        //Coloca um ponto entre o terceiro e o quarto dígitos
        v=v.replace(/(\d{3})(\d)/,"$1.$2")
 
        //Coloca um ponto entre o terceiro e o quarto dígitos
        //de novo (para o segundo bloco de números)
        v=v.replace(/(\d{3})(\d)/,"$1.$2")
 
        //Coloca um hífen entre o terceiro e o quarto dígitos
        v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
 
    } else { //CNPJ

      v  = v.padStart(14,'0')
        
 
        //Coloca ponto entre o segundo e o terceiro dígitos
        v=v.replace(/^(\d{2})(\d)/,"$1.$2")
 
        //Coloca ponto entre o quinto e o sexto dígitos
        v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
 
        //Coloca uma barra entre o oitavo e o nono dígitos
        v=v.replace(/\.(\d{3})(\d)/,".$1/$2")
 
        //Coloca um hífen depois do bloco de quatro dígitos
        v=v.replace(/(\d{4})(\d)/,"$1-$2")
      
 
    }
 
    return v
 
  }

  

  public HasRemetInfo(txt:string, nomeRemet:string, cnpj:string,  agencia:string, conta:string)
  {
    
    if(txt.trim() !== "" && nomeRemet.trim() !== "" && cnpj.trim() !== "")
    {
      const splits = txt.split(' ')
      // this.agencia = "AG:"+splits[1] 
      // this.conta = "CC:"+txt// "CC:"+splits[0]
      this.agencia = "AG:"+agencia.padStart(4,'0') 
      this.conta = "CC:"+conta// "CC:"+splits[0]
      this.cpfCnpj = this.FormatcpfCnpj(cnpj) // cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
      this.nomeRemet = nomeRemet

      // if(splits[0].length > 1 &&
      //    splits[1].length > 2)
      //      return true
      // else
      //   return false

      return true
    }
    else
    {
      return false
    }
  }

  private requestError = (err: any) => {
    // PDF
    this.data = [];
    sessionStorage.setItem('extrato_all_pdf', JSON.stringify(this.data));
    sessionStorage.setItem('informations_pdf', '{"exibir":"Tudo", "periodo": "'+Util.calcDiffInDays(this.params)+' dia(s)", "report_name":"Extrato","name":"extrato-all"}');

    // Excel
    let paramsFilter = JSON.parse(this.params)
    let dataInicioFilter  = paramsFilter.dataInicio;
    let dataFinalFilter = paramsFilter.dataFinal;

    let dataFinal = moment(dataFinalFilter, "YYYY-MM-DD").format("YYYY-MM-DD");
    let dataInicio = moment(dataInicioFilter, "YYYY-MM-DD").format("YYYY-MM-DD");
    sessionStorage.setItem('extract_excel', '{"tipo":"tudo", "dataInicio":"'+dataInicio+'", "dataFinal":"'+dataFinal+'"}');
    this.hide = Util.isEmpty(this.releases);
    console.warn(err);
    this.loading = false;
  };

  private requestComplete = () => {
    this.hide = Util.isEmpty(this.releases);
    this.loading = false;
  };

  isNull = (value: any) => {
    return Util.isNull(value);
  };

  formatDate(date: string) {
    moment.locale('pt-br');
    const today = moment().startOf('day');
    const patternDate = moment(date.split('T')[0]);
    const diffDays = today.diff(patternDate, 'days');

    return diffDays === 0
      ? 'Hoje, ' + patternDate.format('LL')
      : diffDays === 1
      ? 'Ontem, ' + patternDate.format('LL')
      : diffDays > 1 && diffDays <= 7
      ? patternDate.format('dddd').charAt(0).toUpperCase() +
        patternDate.format('dddd').slice(1) +
        ', ' +
        patternDate.format('LL')
      : patternDate.format('DD') +
        ' de ' +
        patternDate.format('MMMM') +
        ' de ' +
        patternDate.format('YYYY');
  }

  public isPix(item): boolean {
    return (<string>item.desc_historico).toUpperCase().indexOf("PIX") > -1;
  }

  public temDescricao(item): boolean {
    return item.compl_det && item.compl_det.desc_nome && item.compl_det.desc_nome.trim() != '';
  }

  removeItem = (dsc) => {
    return dsc.toUpperCase().indexOf('DEB.PAGTO TITULO C/C') === -1
  }

  adjustDescriptionTransaction = (des) => {
    var rst;
    switch (des) {
      case 'Transação Pix Feita':
        rst = 'Pix enviado';
        break;
      case 'Transação Pix Recebida':
        rst =  'Pix recebido';
        break;
      case 'Transação Pix Agendada ':
        rst =  'Pix agendado';
        break;
      case 'Pagamento Pix Devolvido':
        rst =  'Pix - envio devolvido';
        break;      
      case 'Recebimento Pix Devolvido':
        rst =  'Pix - recebimento devolvido ';
        break; 
        case 'PIX-RECEBIMENTO DEV ESTORNADO':
          rst =  'Pix - Recebimento Devolvido Estornado';
          break;  
        case 'PIX- RECEBIMENTO DEV ESTORNADO':
          rst =  'Pix - Recebimento Devolvido Estornado';
          break;  
      default:
        rst = des;        
    }
    return rst;
  }

  public temInstituicao(item): boolean {
    return item.compl_det && item.compl_det.dados_conta && item.compl_det.dados_conta.nome_banco && item.compl_det.dados_conta.nome_banco != '0' && item.compl_det.dados_conta.nome_banco != '00';
  }

  public temComprovante(item): boolean {
    return false;
    //return this.isPix(item) && item.cod_deb_cred == 'D';
  }

  public temChave(item): boolean {
    return item.compl_det && item.compl_det.dados_conta && item.compl_det.dados_conta.chave;
  }

  public ehPagTit(item): boolean {
    return (item.desc_historico.toUpperCase().indexOf('PAGTO TIT') !== -1) || (item.desc_historico.toUpperCase().indexOf('LIQ.TIT') !== -1)
  }

  public formatAccountNumber(accNumber: string): string {
    return `${accNumber.substring(0, accNumber.length - 1)}-${accNumber[accNumber.length - 1]}`;
  }

  public sendReceipt(item) {
    this.getPdf
      .execute(item.num_movimento)
      .subscribe(this.successPdf, this.errorPdf);
  }

  private successPdf = (value) => {
    fetch(value.data.url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "Comprovante_transação.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
  };

  private errorPdf = (err) => {
    this.toast.callModalMessage(null, "Falha no download do comprovante tente novamente");
  };
}
