import { Component, OnInit,OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as models from '@src/data/repository/data-share-repository';
import { DerivativeDataRepository } from '@src/data/repository/derivative-repository/derivative.repository';
import { DerivativeEntity, DerivativeNotasEntity } from '@src/data/repository/derivative-repository/derivative.entity';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { PostExportExcelUsecase } from '@src/core/usecases/transfer-approval/post-export-approval-usecase';
import * as Util from '@src/shared/util-common';

@Component({
  selector: 'fibra-page-derivative',
  templateUrl: './derivative.component.html',
  styleUrls: ['./derivative.component.scss'],
})
export class DerivativePageComponent implements OnInit, OnDestroy {
  public token = undefined;
  
  public options;
  public routAccont: string;
  public derivativeFormGroup: FormGroup;
  public util = Util;
  public noData = true;
  public dataInvalida = false;
  public report = false
  public derivativeList = []
  public notasPdf: DerivativeNotasEntity[];
  public notasPdfComod: DerivativeNotasEntity[];
  public loadingNotas = false;
  selectedYear = 0;
  optionPeriod = [(new Date()).getFullYear(), (new Date()).getFullYear()-1, (new Date()).getFullYear()-2];
  maxDate: NgbDate;
  minDate: NgbDate;
  derivativeDate: Date;
  total: {
    net: number,
    credito: number,
    debito: number
  };

  derivative: DerivativeEntity[];
  
  loading = true;
  hasError = false;
  disableButton = false;
  
  constructor(
    private DerivativeService: DerivativeDataRepository,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private postExportExcel: PostExportExcelUsecase
  ) {
    this.minDate = this.calendar.getPrev(this.calendar.getToday(),'y',5);
  }

  ngOnInit() {
    this.derivativeFormGroup = this.formBuilder.group({
      derivativeDate: new FormControl('', [])
    });
    this.getDerivative(new Date());

    sessionStorage.setItem('derivativeDate',new Date().toString());

    
  }

  ngOnDestroy()
  {
    sessionStorage.removeItem('informations_pdf');
    sessionStorage.removeItem('derivative_list_pdf');
    sessionStorage.removeItem('derivativeDate');
  }

  selectDate(obj: NgbDate){
    this.dataInvalida = false;
    console.log('select');
    const jsDate = new Date(obj.year, obj.month-1, obj.day);
    sessionStorage.setItem('derivativeDate',jsDate.toString());
    this.derivativeFormGroup.patchValue({
      derivativeDate: jsDate,
    });
  }

  dateInvalid(obj: string){
    console.log('Data invalida');
    this.dataInvalida = true;
    this.noData = true;
    this.loading = false;
    this.hasError = false;
    this.derivative = [];
  }

  consultar(){
    console.log('consultar');
    if(this.derivativeFormGroup.get('derivativeDate').value != ""){
      this.getDerivative(this.derivativeFormGroup.get('derivativeDate').value)
    }
  }

  getTotal(){
    let net: number = 0;
    let credito: number = 0;
    let debito: number = 0;
    if(this.derivative){
      this.derivative.forEach(element => {
        if(element.tipo === 'debito'){
          debito += element.valor_liquido;
          net = net + element.valor_liquido;
        }
        else {
          credito += element.valor_liquido;
          net = net + element.valor_liquido;
        }
      });
    }
    this.total = {
      net,
      credito,
      debito
    };
  }

  getDerivative(dt: Date){
    this.loading = true;
    this.DerivativeService.getDerivative(dt).subscribe((data) => {
      this.derivative = data.data;
      this.derivativeList = data.data;
      //PDF
      sessionStorage.setItem('derivative_list_pdf', JSON.stringify(this.derivativeList));
      sessionStorage.setItem('informations_pdf', '{"title":"Derivativos", "name":"derivativos", "report_name":"Derivativos", "filter":"derivativos", "dt_ref":"'+dt.toString()+'"}')
      console.log('der:',this.derivative);
      if(this.derivative && this.derivative.length > 0){
        this.noData = false;
        this.loading = false;
        this.hasError = false;
      }else{
        this.noData = true;
        this.loading = false;
        this.hasError = false;
      }
      this.getTotal();
    }, () =>{
      this.hasError = true;
      this.noData = false;
      this.loading = false;
      this.derivative = [];
    });
    this.DerivativeService.getNotas(dt).subscribe((data) => {
      this.notasPdfComod = [];
      this.notasPdf = [];
      this.notasPdf = data.data;
      if(this.notasPdf && this.notasPdf.length > 0){
        this.loadingNotas = true;
        // this.notasPdf.forEach(element => {
        //   if(element.produto == "NDF COMOD"){
        //     this.notasPdfComod.push(element);
        //   }
        // });
      }
      console.log(this.notasPdf);
    }, () =>{
      this.loadingNotas = false;
      this.notasPdf = [];
    });
  }

  public exportScreen(value) {
    if (value === 1) {
      //this.navigateToResource('/derivative-export');
      this.report = true;
      Util.consoleDev('derivativeList:',this.derivative)
      return;
    }



    let dataDerivative = sessionStorage.getItem('derivativeDate')

    let errorMessage='Erro ao tentar fazer download do arquivo, tente mais tarde.'
    this.DerivativeService.postExcel(new Date(dataDerivative)).subscribe((res) => {
      Util.handleFile(res,"Derivativos")
      //this.successDownload(res);
    }, error => {
      alert(errorMessage)
      Util.consoleDev('Erro:', error)
    });
  }

  public changeReportView(event) {
    this.report = !this.report;
    this.resetArrays();
  }

  public resetArrays() {
    //this.derivative = null;
  }


  private successDownload = (value) => {
    if (value.status === 'success') {
      Util.handleFile(value.data, 'arquivo');
    }
  };

  private navigateToResource(router?: string): void {
    window.open(router);
  }

  downloadFile(notas: any){
    var i = 1;
    notas.forEach(element => {
      fetch(element.url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        if(element && element.produto != 'NDF COMOD'){
          a.download = "NOTA " + element.produto  +".pdf";
        }else{
          a.download = "NOTA " + i + " " + element.produto  +".pdf";
          i++;
        }
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
    });
  }

  downloadFileComod(notas: any){
    var i = 1;
    notas.forEach(element => {
      fetch(element.url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "NOTA " + element.produto  +".pdf";
        i++;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
    });
  }
}

