import { Injectable } from '@angular/core';
import { PredictedRepository, PredictedTotalizadoresRepository } from '@src/core/repositories/predicted/predicted.repository';
import { PredictedModel, PredictedRequest, PredictedTotalizadorModel } from '@src/core/domain/predicted/predicted.model';
import { Observable } from 'rxjs';
import { PredictedRepositoryMapper, PredictedTotalizadorRepositoryMapper } from '@src/data/repository/predicted-repository/predicted-repository.mapper';
import { HttpClient } from '@angular/common/http';
import { PredictedEntity, PredictedTotalizadorEntity } from '@src/data/repository/predicted-repository/predicted.entity';
import { flatMap, map } from 'rxjs/operators';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PredictedWebRepository extends PredictedRepository {

  mapper = new PredictedRepositoryMapper();

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getPredictedMovements(obj: PredictedRequest): Observable<PredictedModel> {
    const httpOptions = {
      headers: getHeader(),
      params: {...obj}
    };
    return this.http
      .get<PredictedEntity>(
        `${environment.URLBase}v1/movimentacoesprevistas`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }
}

export class getPredictedMovementsTotalizadores extends PredictedTotalizadoresRepository {
  mapper = new PredictedTotalizadorRepositoryMapper();

  constructor(private http: HttpClient){
    super()
  }

  getPredictedMovementsTotalizadores(): Observable<PredictedTotalizadorModel>{
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<PredictedTotalizadorEntity>(
        `${environment.URLBase}v1/titulos/Totalizadores`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }
}
