import {
  TransactionDetailModel,
  TransactionDetailRequest,
} from './../../domain/transaction/transaction.model';
import { Observable } from 'rxjs';

export abstract class TransactionDetailRepository {
  abstract getDetail(
    params: TransactionDetailRequest
  ): Observable<TransactionDetailModel>;
}
