<div *ngIf="!cadastro" class="content-transferir">
    <!-- Sem chaves -->
    <div *ngIf="!hasKeys && !loading && !hasError" class="no-content" id="sem-chaves">
        <div class="arrow" style="cursor: pointer;" (click)="goBack()">
            <img alt="arrow-voltar" class="arrow-img" src="/assets/svg/arrow-voltar-icon.svg"> <span class="voltar">
                Voltar</span>
        </div>
        <div class="title" style="margin:24px 0 24px 26px;">
            <span class="span-title"> Você ainda não possui nenhuma chave. Selecione uma chave abaixo para cadastrar.
            </span>
        </div>
        <div class="accounts-div">
            <div (click)="goToCadastroNoKeys('celular')" class="account">
                <div class="account-img">
                    <img alt="smartphone" src="/assets/svg/smartphone-icon.svg">
                </div>
                <div class="font">
                    <span class="account-font"> Número de celular</span>
                </div>
            </div>
            <div (click)="goToCadastroNoKeys('document')" class="account">
                <div class="account-img">
                    <img alt="smartphone" src="/assets/svg/document-icon.svg">
                </div>
                <div class="font">
                    <span class="account-font"> <span *ngIf="getAccountInformationSession[0].tipo_pessoa === 'PF'">CPF</span><span
                            *ngIf="getAccountInformationSession[0].tipo_pessoa === 'PJ'">CNPJ</span></span>
                </div>
            </div>
            <div (click)="goToCadastroNoKeys('email')" class="account">
                <div class="account-img">
                    <img alt="smartphone" src="/assets/svg/email-icon.svg">
                </div>
                <div class="font">
                    <span class="account-font"> Endereço de e-mail</span>
                </div>
            </div>
            <div (click)="goToCadastroNoKeys('aleatorio')" class="account">
                <div class="account-img">
                    <img alt="smartphone" src="/assets/svg/aleatorio-icon.svg">
                </div>
                <div class="font">
                    <span class="account-font"> Chave aleatoria</span>
                </div>
            </div>
        </div>
    </div>
    <!-- Com chaves -->
    <div *ngIf="hasKeys && !loading && !hasError" class="has-content" id="com-chaves">
        <div (click)="goBack()" class="arrow" style="cursor: pointer;">
            <img alt="arrow-voltar" class="arrow-img" src="/assets/svg/arrow-voltar-icon.svg"> <span class="voltar">
                Voltar</span>
        </div>
        <div class="header">
            <div class="title">
                <span class="span-title"> Minhas chaves Pix</span>
                <span class="span-subtitle"> {{totalKeys}} de {{getAccountInformationSession[0].tipo_pessoa === 'PF' ? 5
                    : 20}} chaves disponíveis </span>
            </div>
            <div class="button">
                <button (click)="goToCadastro()" class="button-continue" type="button">
                    Cadastrar nova chave
                </button>
            </div>
        </div>
        <div class="keys-div">
            <div class="key-div">
                <div class="key-title">
                    <div class="key-img">
                        <img alt="smartphone" src="/assets/svg/smartphone-icon.svg">
                    </div>
                    <div class="font">
                        <span class="key-font"> Número de celular</span>
                    </div>
                </div>

                <div *ngIf="smartPhoneKeys.length == 0" class="key-content">
                    <span class="content-font"> Não há chaves cadastradas

                    </span>
                </div>
                <div *ngIf="smartPhoneKeys.length > 0" class="key-content">
                    <div *ngFor="let phone of smartPhoneKeys" class="card">
                        <div class="card-key">
                            <span class="font"> Chave</span>
                            <span class="font-blue"> {{phone.chave | mask: '+00 00 00000-0000' }}</span>
                        </div>
                        <div class="card-status">
                            <span *ngIf="isActiveKey(phone.status)" class="status-font">Status <div class="circle-green">
                                </div> <span style="color: #73b599">Ativo</span>
                            </span>
                            <span *ngIf="phone.status ==='Chave não registrada no DICT aguardando usuário confirmar se quer fazer o processo de reivindicação' || phone.status ==='PSP reivindicador - Chave não cadastrada, está aguardando retorno do PSP doador sobre pedido de reivindicação' || phone.status ==='Chave ainda não cadastrada, sistema está aguardando usuário confirmar que ainda possui a posse da chave' || phone.status === 'Pedido de reivindicação pendente de envio ao DICT' || phone.status  === 'PSP reivindicador - Chave não cadastrada, está aguardando retorno do PSP doador sobre pedido de portabilidade'"
                                class="status-font" style="display: flex; align-items: center"> <span
                                    style="margin-right: 5px">Status</span>
                                <img src="/assets/svg/pending-watch.svg" alt="watch" style="margin-right: 5px">
                            </span>
                            <span *ngIf="phone.status === 'PENDENTE' && phone?.infoOperacaoChave?.ds_status_consolidado !=='IB_AGENDADO'" class="icon-alert">
                                <span [appTooltip]="tooltipAll" class="icon-info notification">
                                    <img alt="logo" src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 8px !important;" />
                                    <div #tooltipAll class="tooltip-custom">
                                        <p style="font-size: 14px;">Aguardando aprovação</p>
                                        <div class="masters" style="text-align: left">
                                            <div class="masters-title">
                                                <img src="/assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                                                <span class="master-font"> Masters </span>
                                            </div>
                                            <span class="master-sub" *ngFor="let item of phone.infoOperacaoChave.aprovacoes">
                                                <span *ngIf="item.status === 'PENDENTE'">{{item.pessoa.nome_pessoa}}</span></span>
                                        </div>
                                        <div class="arrow"></div>
                                    </div>
                                </span>
                            </span>
                            <span *ngIf="phone.status ==='PENDENTE' && phone?.infoOperacaoChave?.ds_status_consolidado ==='IB_AGENDADO'" class="icon-alert">
                                <span [appTooltip]="tooltipAll" class="icon-info notification">
                                    <img alt="logo" src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 8px;" />
                                    <div #tooltipAll class="tooltip-custom" style="height: 80px !important; padding: 10px  !important; text-align: initial !important">
                                        <p style="font-size: 14px;">Sua efetivação ocorrerá <span *ngIf="isToday(phone.infoOperacaoChave.data_agendamento_bacen) === false">amanhã</span><span
                                                *ngIf="isToday(phone.infoOperacaoChave.data_agendamento_bacen) === true">hoje</span>
                                            às 08:00.</p>
                                        <div class="arrow"></div>
                                    </div>
                                </span>
                            </span>
                            <span *ngIf="phone.status ==='Chave pendente de cadastro no DICT' || phone.status ==='Chave pendente de ser excluída no DICT'" class="icon-alert" style="margin-right: 8px;">
                                <span [appTooltip]="tooltipAll" class="icon-info notification">
                                    <img alt="logo" src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 8px !important;" />
                                    <div #tooltipAll class="tooltip-custom" style="height: 70px !important;">
                                        <p *ngIf="phone.status ==='Chave pendente de cadastro no DICT'" style="font-size: 14px;">
                                            Cadastro pendente no bacen</p>
                                        <p *ngIf="phone.status ==='Chave pendente de ser excluída no DICT'" style="font-size: 14px;">
                                            Exclusão pendente no bacen</p>
                                        <div class="arrow"></div>
                                    </div>
                                </span>
                            </span>
                            <div *ngIf="!isActiveKey(phone.status)">
                                <span class="status-font" style="color: #D69D10" *ngIf=" phone.status === 'Chave pendente de cadastro no DICT'">
                                    Cadastro de chave pendente de aprovação</span>
                                <span class="status-font" style="color: #D69D10" *ngIf="phone.status ==='Chave pendente de ser excluída no DICT'">
                                    Exclusão de chave pendente</span>
                                <span class="status-font" style="color: #D69D10" *ngIf="phone.status === 'PSP reivindicador - Chave não cadastrada, está aguardando retorno do PSP doador sobre pedido de portabilidade'">
                                    Aguardando confirmação de Portablidade</span>
                                <span class="status-font" style="color: #D69D10" *ngIf="phone.status === 'Chave excluída, pois usuário não indicou se tem ou não a posse da chave nos 7 dias do processo de reivindicação '">
                                    Chave excluída, pois usuário não indicou se tem ou não a posse da chave nos 7 dias do processo de reivindicação </span>
                                <span class="status-font" style="color: #D69D10" *ngIf="phone.status ==='PSP reivindicador - Chave não cadastrada, está aguardando retorno do PSP doador sobre pedido de reivindicação' || phone.status ==='Chave ainda não cadastrada, sistema está aguardando usuário confirmar que ainda possui a posse da chave' ||  phone.status ==='Chave não registrada no DICT aguardando usuário confirmar se quer fazer o processo de reivindicação' ||  phone.status === 'Pedido de reivindicação pendente de envio ao DICT'">
                                    Aguardando confirmação de reivindicação</span>
                            </div>
                        </div>

                        <div class="card-actions">
                            <img *ngIf="phone?.infoOperacaoChave?.ds_status_consolidado !=='IB_AGENDADO'" (click)="copy(phone.chave)" alt="copiar" class="hover-class"
                                style="cursor: pointer;" src="/assets/svg/icon-copiar.svg">
                            <img *ngIf="isActiveKey(phone.status)" (click)="openModal(content, phone)" alt="deletar" class="hover-class"
                                style="cursor: pointer;" src="/assets/svg/icon-deletar.svg" style="margin-left: 35px; cursor: pointer;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="key-div">
                <div class="key-title">
                    <div class="key-img">
                        <img alt="smartphone" src="/assets/svg/email-icon.svg">
                    </div>
                    <div class="font">
                        <span class="key-font"> Endereço de e-mail</span>
                    </div>
                </div>
                <div *ngIf="emailKeys.length == 0" class="key-content">
                    <span class="content-font"> Não há chaves cadastradas
                    </span>
                </div>
                <div *ngIf="emailKeys.length > 0" class="key-content">
                    <div *ngFor="let email of emailKeys" class="card">
                        <div class="card-key">
                            <span class="font"> Chave</span>
                            <span class="font-blue"> {{email.chave}}</span>
                        </div>
                        <div class="card-status">
                            <span *ngIf="isActiveKey(email.status)" class="status-font">Status 
                                <div class="circle-green">
                                </div> <span style="color: #73b599">Ativo</span>
                            </span>
                            <span *ngIf="email.status ==='Chave não registrada no DICT aguardando usuário confirmar se quer fazer o processo de reivindicação' || email.status ==='PSP reivindicador - Chave não cadastrada, está aguardando retorno do PSP doador sobre pedido de reivindicação' || email.status ==='Chave ainda não cadastrada, sistema está aguardando usuário confirmar que ainda possui a posse da chave' ||  email.status ==='Pedido de reivindicação pendente de envio ao DICT'"
                                class="status-font" style="display: flex; align-items: center"> <span
                                    style="margin-right: 5px">Status</span>
                                <img src="/assets/svg/pending-watch.svg" alt="watch" style="margin-right: 5px">
                            </span>
                            <span *ngIf="email.status ==='PENDENTE' && email.infoOperacaoChave.ds_status_consolidado !='IB_AGENDADO'" class="icon-alert">
                                <span [appTooltip]="tooltipAll" class="icon-info notification">
                                    <img alt="logo" src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 8px !important;" />
                                    <div #tooltipAll class="tooltip-custom">
                                        <p style="font-size: 14px;">Aguardando aprovação</p>
                                        <div class="masters" style="text-align: left">
                                            <div class="masters-title">
                                                <img src="/assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                                                <span class="master-font"> Masters </span>
                                            </div>
                                            <span class="master-sub" *ngFor="let item of email.infoOperacaoChave.aprovacoes">
                                                <span *ngIf="item.status === 'PENDENTE'">{{item.pessoa.nome_pessoa}}</span></span>
                                        </div>
                                        <div class="arrow"></div>
                                    </div>
                                </span>
                            </span>
                            <span *ngIf="email.status ==='PENDENTE' && email.infoOperacaoChave.ds_status_consolidado === 'IB_AGENDADO'" class="icon-alert">
                                <span [appTooltip]="tooltipAll" class="icon-info notification">
                                    <img alt="logo" src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 8px;" />
                                    <div #tooltipAll class="tooltip-custom" style="height: 80px !important; padding: 10px  !important; text-align: initial !important">
                                        <p style="font-size: 14px;">Sua efetivação ocorrerá <span *ngIf="isToday(email.infoOperacaoChave.data_agendamento_bacen) === false">amanhã</span><span
                                                *ngIf="isToday(email.infoOperacaoChave.data_agendamento_bacen) === true">hoje</span>
                                            às 08:00.</p>
                                        <div class="arrow"></div>
                                    </div>
                                </span>
                            </span>
                            <span *ngIf="email.status ==='Chave pendente de cadastro no DICT' || email.status ==='Chave pendente de ser excluída no DICT'" class="icon-alert" style="margin-right: 8px;">
                                <span [appTooltip]="tooltipAll" class="icon-info notification">
                                    <img alt="logo" src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 8px !important;" />
                                    <div #tooltipAll class="tooltip-custom" style="height: 70px !important;">
                                        <p *ngIf="email.status ==='Chave pendente de cadastro no DICT'" style="font-size: 14px;">
                                            Cadastro pendente no bacen</p>
                                        <p *ngIf="email.status ==='Chave pendente de ser excluída no DICT'" style="font-size: 14px;">
                                            Exclusão pendente no bacen</p>
                                        <div class="arrow"></div>
                                    </div>
                                </span>
                            </span>
                            <div *ngIf="!isActiveKey(email.status)">
                                <span class="status-font" style="color: #D69D10" *ngIf="email.status ==='Chave pendente de cadastro no DICT'">
                                    Cadastro de chave pendente</span>
                                <span class="status-font" style="color: #D69D10" *ngIf="email.status ==='Chave pendente de ser excluída no DICT'">
                                    Exclusão de chave pendente</span>
                                <span class="status-font" style="color: #D69D10" *ngIf="email.status === 'PSP reivindicador - Chave não cadastrada, está aguardando retorno do PSP doador sobre pedido de portabilidade'">
                                    Aguardando confirmação de Portablidade</span>
                                <span class="status-font" style="color: #D69D10" *ngIf="email.status ==='PSP reivindicador - Chave não cadastrada, está aguardando retorno do PSP doador sobre pedido de reivindicação' || email.status ==='Chave ainda não cadastrada, sistema está aguardando usuário confirmar que ainda possui a posse da chave' ||  email.status ==='Chave não registrada no DICT aguardando usuário confirmar se quer fazer o processo de reivindicação' || email.status ==='Chave ainda não cadastrada, sistema está aguardando usuário confirmar que ainda possui a posse da chave' ||  email.status ==='Pedido de reivindicação pendente de envio ao DICT'">
                                    Aguardando confirmação de reivindicação</span>
                            </div>
                        </div>
                        <div class="card-actions">
                            <img *ngIf="email?.infoOperacaoChave?.ds_status_consolidado !=='IB_AGENDADO'" (click)="copy(email.chave)" alt="copiar" class="hover-class"
                                style="cursor: pointer;" src="/assets/svg/icon-copiar.svg">
                            <img *ngIf="isActiveKey(email.status)" (click)="openModal(content,email)" alt="deletar" class="hover-class"
                                src="/assets/svg/icon-deletar.svg" style="margin-left: 35px; cursor: pointer;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="key-div">
                <div class="key-title">
                    <div class="key-img">
                        <img alt="smartemail" src="/assets/svg/document-icon.svg">
                    </div>
                    <div class="font">
                        <span class="key-font"> <span *ngIf="getAccountInformationSession[0].tipo_pessoa === 'PF'">CPF</span><span
                                *ngIf="getAccountInformationSession[0].tipo_pessoa === 'PJ'">CNPJ</span></span>
                    </div>
                </div>
                <div *ngIf="documentKeys.length == 0" class="key-content">
                    <span class="content-font"> Não há chaves cadastradas
                    </span>
                </div>
                <div *ngIf="documentKeys.length > 0" class="key-content">
                    <div *ngFor="let doc of documentKeys" class="card">
                        <div class="card-key">
                            <span class="font"> Chave</span>
                            <span class="font-blue"> {{doc.chave | cpfcnpj}}</span>
                        </div>
                        <div class="card-status">
                            <span *ngIf="isActiveKey(doc.status)" class="status-font">Status <div class="circle-green">
                                </div> <span style="color: #73b599">Ativo</span>
                            </span>
                            <span *ngIf="doc.status ==='Chave não registrada no DICT aguardando usuário confirmar se quer fazer o processo de reivindicação' || doc.status ==='PSP reivindicador - Chave não cadastrada, está aguardando retorno do PSP doador sobre pedido de reivindicação' || doc.status ==='Chave ainda não cadastrada, sistema está aguardando usuário confirmar que ainda possui a posse da chave' || doc.status === 'PSP reivindicador - Chave não cadastrada, está aguardando retorno do PSP doador sobre pedido de portabilidade'"
                                class="status-font" style="display: flex; align-items: center"> <span
                                    style="margin-right: 5px">Status</span>
                                <img src="/assets/svg/pending-watch.svg" alt="watch" style="margin-right: 5px">
                            </span>
                            <span *ngIf="doc.status ==='PENDENTE' || doc.status ==='Chave pendente de cadastro no DICT' ||  doc.status ==='Chave pendente de ser excluída no DICT'"
                                class="status-font" style="margin-right: 8px;">
                                Status</span>
                            <span *ngIf="doc.status ==='PENDENTE' && doc.infoOperacaoChave.ds_status_consolidado !='IB_AGENDADO'" class="icon-alert">
                                <span [appTooltip]="tooltipAll" class="icon-info notification">
                                    <img alt="logo" src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 8px !important;" />
                                    <div #tooltipAll class="tooltip-custom">
                                        <p style="font-size: 14px;">Aguardando aprovação</p>
                                        <div class="masters" style="text-align: left">
                                            <div class="masters-title">
                                                <img src="/assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                                                <span class="master-font"> Masters </span>
                                            </div>
                                            <span class="master-sub" *ngFor="let item of doc.infoOperacaoChave.aprovacoes">
                                                <span *ngIf="item.status === 'PENDENTE'">{{item.pessoa.nome_pessoa}}</span></span>
                                        </div>
                                        <div class="arrow"></div>
                                    </div>
                                </span>
                            </span>
                            <span *ngIf="doc.status ==='PENDENTE' && doc.infoOperacaoChave.ds_status_consolidado === 'IB_AGENDADO'" class="icon-alert">
                                <span [appTooltip]="tooltipAll" class="icon-info notification">
                                    <img alt="logo" src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 8px;" />
                                    <div #tooltipAll class="tooltip-custom" style="height: 80px !important; padding: 10px  !important; text-align: initial !important">
                                        <p style="font-size: 14px;">Sua efetivação ocorrerá <span *ngIf="isToday(doc.infoOperacaoChave.data_agendamento_bacen) === false">amanhã</span><span
                                                *ngIf="isToday(doc.infoOperacaoChave.data_agendamento_bacen) === true">hoje</span>
                                            às 08:00.</p>
                                        <div class="arrow"></div>
                                    </div>
                                </span>
                            </span>
                            <span *ngIf="doc.status ==='Chave pendente de cadastro no DICT' || doc.status ==='Chave pendente de ser excluída no DICT'"
                                class="icon-alert" style="margin-right: 8px;">
                                <span [appTooltip]="tooltipAll" class="icon-info notification">
                                    <img alt="logo" src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 8px !important;" />
                                    <div #tooltipAll class="tooltip-custom" style="height: 70px !important;">
                                        <p *ngIf="doc.status ==='Chave pendente de cadastro no DICT'" style="font-size: 14px;">
                                            Cadastro pendente no bacen</p>
                                        <p *ngIf="doc.status ==='Chave pendente de ser excluída no DICT'" style="font-size: 14px;">
                                             Exclusão pendente no bacen</p>
                                        <div class="arrow"></div>
                                    </div>
                                </span>
                            </span>
                            <div *ngIf="!isActiveKey(doc.status)">
                                <span class="status-font" style="color: #D69D10" *ngIf="doc.status ==='Chave pendente de cadastro no DICT'">
                                    Cadastro de chave pendente</span>
                                <span class="status-font" style="color: #D69D10" *ngIf="doc.status ==='Chave pendente de ser excluída no DICT'">
                                    Exclusão de chave pendente</span>
                                <span class="status-font" style="color: #D69D10" *ngIf="doc.status === 'PSP reivindicador - Chave não cadastrada, está aguardando retorno do PSP doador sobre pedido de portabilidade'">
                                    Aguardando confirmação de Portablidade</span>
                                <span class="status-font" style="color: #D69D10" *ngIf="doc.status === 'Pedido de portabilidade pendente de envio ao DICT'">
                                    Pedido de portabilidade pendente de envio ao BACEN</span>
                                <span class="status-font" style="color: #D69D10" *ngIf="doc.status ==='PSP reivindicador - Chave não cadastrada, está aguardando retorno do PSP doador sobre pedido de reivindicação' || doc.status ==='Chave ainda não cadastrada, sistema está aguardando usuário confirmar que ainda possui a posse da chave' ||  doc.status ==='Chave não registrada no DICT aguardando usuário confirmar se quer fazer o processo de reivindicação'">
                                    Aguardando confirmação de reivindicação</span>
                            </div>
                        </div>
                        <div class="card-actions">
                            <img *ngIf="doc?.infoOperacaoChave?.ds_status_consolidado !=='IB_AGENDADO'" (click)="copy(doc.chave)" alt="copiar" class="hover-class"
                                style="cursor: pointer;" src="/assets/svg/icon-copiar.svg">
                            <img *ngIf="isActiveKey(doc.status)" (click)="openModal(content, doc)" alt="deletar" class="hover-class" src="/assets/svg/icon-deletar.svg"
                                style="margin-left: 35px;cursor: pointer;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="key-div">
                <div class="key-title">
                    <div class="key-img">
                        <img alt="smartphone" src="/assets/svg/aleatorio-icon.svg">
                    </div>
                    <div class="font">
                        <span class="key-font"> Chave Aleatória </span>
                    </div>
                </div>
                <div *ngIf="randomKeys.length == 0" class="key-content">
                    <span class="content-font"> Não há chaves cadastradas

                    </span>
                </div>
                <div *ngIf="randomKeys.length > 0" class="key-content">
                    <div *ngFor="let random of randomKeys">
                        <div class="card" *ngIf="isActiveKey(random.status)">
                            <div class="card-key">
                                <span class="font"> Chave</span>
                                <span class="font-blue"> {{random.chave}}</span>
                            </div>
                            <div class="card-status">
                                <span class="status-font">Status <div class="circle-green">
                                    </div> <span style="color: #73b599">Ativo</span>
                                </span>
                            </div>
                            <div class="card-actions">
                                <img (click)="copy(random.chave)" alt="copiar" class="hover-class" style="cursor: pointer;" src="/assets/svg/icon-copiar.svg">
                                <img *ngIf="isActiveKey(random.status) ||  random.status ==='IB_APROVACAO_PENDENTE'" (click)="openModal(content,random)"
                                    alt="deletar" class="hover-class" src="/assets/svg/icon-deletar.svg" style="margin-left: 35px; cursor: pointer;">
                            </div>
                        </div>
                        <div class="card-pedding" *ngIf="random.status ==='PENDENTE' || random.status ==='Chave pendente de cadastro no DICT' || random.status ==='Chave pendente de ser excluída no DICT'">

                            <div class="card-actions">
                                <div style="display: flex; align-items: center">
                                    <span class="status-font" style="margin-right: 8px;"> Status:</span>
                                    <span class="icon-alert" *ngIf="random.status ==='PENDENTE' && random.infoOperacaoChave.ds_status_consolidado !== 'IB_AGENDADO'">
                                        <span [appTooltip]="tooltipAll" class="icon-info notification">
                                            <img alt="logo" src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 8px !important;" />
                                            <div #tooltipAll class="tooltip-custom">
                                                <p style="font-size: 14px;">Aguardando aprovação</p>
                                                <div class="masters" style="text-align: left">
                                                    <div class="masters-title">
                                                        <img src="/assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                                                        <span class="master-font"> Masters </span>
                                                    </div>
                                                    <span class="master-sub" *ngFor="let item of random.infoOperacaoChave.aprovacoes">
                                                        <span *ngIf="item.status === 'PENDENTE'">{{item.pessoa.nome_pessoa}}</span></span>
                                                </div>
                                                <div class="arrow"></div>
                                            </div>
                                        </span>
                                    </span>
                                    <span *ngIf="random.status ==='PENDENTE' && random.infoOperacaoChave.ds_status_consolidado === 'IB_AGENDADO'" class="icon-alert">
                                        <span [appTooltip]="tooltipAll" class="icon-info notification">
                                            <img alt="logo" src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 8px;" />
                                            <div #tooltipAll class="tooltip-custom" style="height: 80px !important; padding: 10px  !important; text-align: initial !important">
                                                <p style="font-size: 14px;">Sua efetivação ocorrerá <span *ngIf="isToday(random.infoOperacaoChave.data_agendamento_bacen) === false">amanhã</span><span
                                                        *ngIf="isToday(random.infoOperacaoChave.data_agendamento_bacen) === true">hoje</span>
                                                    às 08:00.</p>
                                                <div class="arrow"></div>
                                            </div>
                                        </span>
                                    </span>
                                    <span *ngIf="random.status ==='Chave pendente de cadastro no DICT' || random.status ==='Chave pendente de ser excluída no DICT'"
                                        class="icon-alert">
                                        <span [appTooltip]="tooltipAll" class="icon-info notification">
                                            <img alt="logo" src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 8px;" />
                                            <div #tooltipAll class="tooltip-custom" style="height: 70px !important;">
                                                <p *ngIf="random.status ==='Chave pendente de cadastro no DICT'" style="font-size: 14px;">
                                                    Cadastro pendente no bacen</p>
                                                <p *ngIf="random.status ==='Chave pendente de ser excluída no DICT'" style="font-size: 14px;">
                                                    Exclusão pendente no bacen</p>
                                                <div class="arrow"></div>
                                            </div>
                                        </span>
                                    </span>
                                    <div>
                                        <span class="status-font" style="color: #D69D10" *ngIf="random.status ==='Chave pendente de cadastro no DICT'">
                                            Cadastro de chave pendente</span>
                                        <span class="status-font" style="color: #D69D10" *ngIf="random.status ==='Chave pendente de ser excluída no DICT'">
                                            Exclusão de chave pendente</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loading" class="d-flex justify-content-center position loading-container">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div *ngIf="hasError && !loading" class="has-content" style="margin-top: 100px;">
        <fibra-generic-info message="Não foi possível realizar a ação solicitada" title="Ocorreu um erro inesperado" type="error">
        </fibra-generic-info>
    </div>
</div>
<div *ngIf="cadastro" class="content-transferir">
</div>


<div class="modal">
    <ng-template #content let-modal>
        <div class="content-modal">
            <div class="generic-info">
                <p class="info-title">Excluir chave Pix</p>
                <p class="generic-info-message">Depois de excluída, a chave não fica mais disponível para receber pagamentos.
                    Deseja excluir esta chave Pix?</p>
            </div>
            <div class="buttons">
                <button type="button" class="button-secondary" (click)="deleteSelectedKey();closeModal($event)">
                    Excluir
                </button>
                <button type="button" class="button-primary" (click)="closeModal($event)"> Cancelar</button>
            </div>
        </div>

    </ng-template>
</div>

<fibra-modal-validation #content (responseValidation)="responseApiModal($event)" *ngIf="showModalValidation" [apiCallback]="apiCallback"
    [apiPayload]="payload" (closeEvent)="closeModalApi($event)">
</fibra-modal-validation>

<div class="message-transaction" id="message" [style.background-color]="color">
    <img src="/assets/svg/warning.svg" />
    <div class="text">
        <h4 class="title">{{msgError}}</h4>
        <div class="msg">{{subTextError}}</div>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="closeError()">
        <img src="/assets/svg/icon_close_white.svg" />
    </button>
</div>