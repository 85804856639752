import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { RolesService } from "@src/shared/services/roles.service";

@Injectable()
export class OpenbankingPaymentRequestCanActivate implements CanActivate {
    
    constructor(private _rolesService: RolesService) {

    }

    canActivate(): boolean {

        const session = this._rolesService.getOpenFinanceSession();

        if (session == null) {
            return false;
        }

        return true;
    }
}