<div class="main-selector" *ngIf="!report">
    <fibra-company-list></fibra-company-list>
    <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>
<div class="content-page" id="foreign"
    *fibraHasRole="'COMERCIO_EXTERIOR.CONSULTAR_POSICAO_CARTEIRA,
    COM_EXT.CONS_CAMB_PR,
    COM_EXT.CONS_IMP,
    COM_EXT.CONS_EXP,
    COM_EXT.CONS_PAG_REC,
    COM_EXT.CONS_GAR'"
>
    <div class="navbar-div">
        <fibra-breadcrumb bottom="0" roule="Comércio Exterior" arrow="false" [downloadDoc]="true" [exportButtonPlaceholder]="'Baixar informações da tela'" (infoReport)="setReportData($event)" (click)="changeClasses()"></fibra-breadcrumb>
        <div class="navbar-teste justify-content-left">
            <div class="item-selected" (click)="changeNavbarClass('cambio')" id="cambio-item"
                *fibraHasRole="'COM_EXT.CONS_CAMB_PR'"
            >
                <span class="item-font-selected" id="cambio-font"> Câmbio pronto </span>
            </div>
            <div class="item-unselected" id="importacao-item" (click)="changeNavbarClass('importacao')"
                *fibraHasRole="'COM_EXT.CONS_IMP'"
            >
                <span class="item-font-unselected" id="importacao-font"> Importação </span>
            </div>
            <div class="item-unselected" id="exportacao-item" (click)="changeNavbarClass('exportacao')"
                *fibraHasRole="'COM_EXT.CONS_EXP'"
            >
                <span class="item-font-unselected" id="exportacao-font"> Exportação </span>
            </div>
            <div class="item-unselected" id="op-item" (click)="changeNavbarClass('op')"
                *fibraHasRole="'COM_EXT.CONS_PAG_REC'"
            >
                <span class="item-font-unselected" id="op-font"> Ordem de pagamento recebida </span>
            </div>
            <div class="item-unselected" id="garantias-item" (click)="changeNavbarClass('garantias')"
                *fibraHasRole="'COM_EXT.CONS_GAR'"
            >
                <span class="item-font-unselected" id="garantias-font"> Garantias </span>
            </div>
        </div>
    </div>
    <section class="body-content" id="body">
        <fibra-ready-change *ngIf="cambio"> </fibra-ready-change>
        <fibra-import *ngIf="importacao"> </fibra-import>
        <fibra-export *ngIf="exportacao"></fibra-export>
        <fibra-received-payment-orders *ngIf="ordemPagamentos"> </fibra-received-payment-orders>
        <fibra-warranties *ngIf="garantias" id="warranties"></fibra-warranties>
    </section>
    <section class="body-content report" id="body-report" *ngIf="report" style="position: absolute;">
        <fibra-foreign-trade-report id="report" [changeArray]="changeArray" [finimpArray]="finimpArray" [cobrancaArrayImport]="cobrancaArrayImport" [creditArrayImport]="creditArrayImport" [accAceArray]="accAceArray" [cobrancaArrayExport]="cobrancaArrayExport"
            [creditArrayExport]="creditArrayExport" [paymentArray]="paymentArray" [receivedArray]="receivedArray" [issuedArray]="issuedArray" (closeReport)="changeReportView($event)"></fibra-foreign-trade-report>
    </section>
</div>