<div class="list-value-matrix">
  <div class="box-header">
    <div class="first-stage" [ngClass]="{ active: active == 'first' }"
      *fibraHasRole="'MTZ_APROV.VISU_REGRAS,MTZ_APROV.EDIT_EXCL_REG,!PF'"
      (click)="handleTabs('first')">
      <label class="stage-label">Regras</label>
    </div>
    <div class="second-stage" [ngClass]="{ active: active == 'second' }" (click)="handleTabs('second')"
      *fibraHasRole="'MTZ_APROV.CAD_REGRAS,!PF'">
      <label class="stage-label">Nova regra</label>
    </div>
  </div>
  <div class="d-flex justify-content-center position" *ngIf="!hide && loading">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
  <div class="empty-info" *ngIf="active !== 'second' && hide && !loading">
    <div *ngIf="!err">
      <fibra-generic-info type="warn" title="Você não possui itens"
        message="Não foi possível encontrar dados de regras!">
      </fibra-generic-info>
    </div>
    <div *ngIf="err">
      <fibra-generic-info type="error" title="Ocorreu um erro"
        message="Não foi possível encontrar regras. Tente novamente mais tarde.">
      </fibra-generic-info>
    </div>
  </div>
  <div class="body-list-matrix" *ngIf="active !== 'second' && !hide && !loading">
    <table class="table">
      <thead>
        <tr class="header-list">
          <th scope="col">
            <div class="text" (click)="sortList('nome_regra')">
              Regra
              <span class="icon">
                <img src="/assets/svg/sort-list.svg" />
              </span>
            </div>
          </th>
          <th scope="col">
            <div class="text" (click)="sortList('vlr_permitido')">
              Valor até
              <span class="icon">
                <img src="/assets/svg/sort-list.svg" />
              </span>
            </div>
          </th>
          <th scope="col">
            <div class="text">Operadores</div>
          </th>

          <th scope="col">
            <div class="text">Masters</div>
          </th>

          <th scope="col">
            <div class="text">Ações</div>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of rules; index as i" class="row">
          <div class="panel-group" id="accordion">
            <div class="panel-list">
              <td scope="row data-hj-suppress">
                <label> {{ item.nome_regra | titlecase }}</label>
              </td>
              <td class="item-data">
                <label>R$ {{ item.vlr_permitido || 0 | number: '1.2-2' }}</label>
              </td>
              <td>
                <label data-hj-suppress>{{ item.qtd_operadores }}</label>
              </td>
              <td>
                {{ item.qtd_masters }}
              </td>
              <td>
                <span [appTooltip]="tooltipAll" *ngIf="item.status === 0 && item.pendent">
                  <img src="/assets/svg/tooltip-alert-orange.svg" alt="logo" />
                  <div class="tooltip" #tooltipAll>
                    <h1>Aguardando Aprovação</h1>
                    <ul>
                      <li>
                        <div class="title">
                          <div></div>
                          Masters
                        </div>
                        <div class="data-hj-suppress" *ngFor="let approve of item.regras_pessoas">
                          <p *ngIf="approve.master && approve.status === 0">
                            {{ approve.nome_pessoa }}
                          </p>
                        </div>
                      </li>
                    </ul>
                    <div class="arrow"></div>
                  </div>
                </span>
                <span *ngIf="item.status === 0 && item.pendent" class="m-pendent">
                  Pendente
                </span>
                <span class="data-hj-suppress" *ngIf="item.status !== 0" [ngClass]="{ red: item.status == 2, green: item.status == 1 }">
                  {{ item.status == 2 ? 'Reprovada' : 'Aprovada' }}
                </span>
                <span *ngIf="!item.pendent && item.status === 0">
                  <a (click)="actionRule({ code: item.cod_regra, id: 2 })"><img class="actBtns"
                      src="/assets/png/icon_delete_rule.png" /></a>
                  <a (click)="actionRule({ code: item.cod_regra, id: 1 })"><img class="actBtns"
                      src="/assets/png/icon_approval_green.png" /></a>
                </span>
              </td>
              <span class="item-icon" (click)="toogleIconArrow(i, item.cod_regra)" data-toggle="collapse"
                attr.href="{{ '#collapse' + i }}">
                <img id="{{ i }}" src="/assets/svg/arrow_down.svg" class="" alt="Seta para dropdown" />
              </span>
            </div>
            <div id="{{ 'collapse' + i }}" class="collapse" data-parent="#accordion">
              <div class="loading-new-rule" *ngIf="loadingItemList">
                <div class="d-flex justify-content-center position">
                  <div class="spinner-border text-primary" role="status">
                  </div>
                </div>
              </div>
              <div class="panel-body" *ngIf="!loadingItemList">
                <div class="operators">

                  <!-- Valor -->
                  <label class="label-input label-input-operator">Valor até R$</label>

                  <!-- Valores -->
                  <div class="operatorsRow">
                    <input class="input-operador" type="text" [(ngModel)]="vlrPermitido"
                      name="vlr_permitido-{{ randHack }}" id="vlr_permitido-{{ randHack }}"
                      [attr.disabled]="temPermissao ? null : 'true'"
                      placeholder="R$ 00.000.000,00" currencyMask maxlength="24" [options]="{
                        prefix: 'R$ ',
                        thousands: '.',
                        decimal: ',',
                        align: 'left'
                      }" />

                    <button type="button" class="btn btn-rule"
                      [ngClass]="{ 'btn-rule-active': vlrPermitido === '100.000,00' }"
                      (click)="onClick($event, 'R$ 100.000,00')"
                      [attr.disabled]="temPermissao ? null : 'true'">
                      100.000,00
                    </button>

                    <button type="button" class="btn btn-rule"
                      [ngClass]="{ 'btn-rule-active': vlrPermitido === '500.000,00' }"
                      (click)="onClick($event, 'R$ 500.000,00')"
                      [attr.disabled]="temPermissao ? null : 'true'">
                      500.000,00
                    </button>

                    <button type="button" class="btn btn-rule"
                      [ngClass]="{ 'btn-rule-active': vlrPermitido === '1.000.000,00' }"
                      (click)="onClick($event, 'R$ 1.000.000,00')"
                      [attr.disabled]="temPermissao ? null : 'true'">
                      1.000.000,00
                    </button>

                  </div>

                  <label class="label-input label-input-operator master-list">Operadores</label>
                  <div class="operatorsRow" data-hj-suppress>
                    <input class="input-operador" [resultFormatter]="formatter" [inputFormatter]="formatter"
                      [ngbTypeahead]="search" [(ngModel)]="model" name="operator-edit-rule-{{ randHack }}"
                      [attr.disabled]="temPermissao ? null : 'true'"
                      id="operator-edit-rule-{{ randHack + i }}" type="text"
                      autocomplete="operator-edit-rule-{{ randHack }}"
                      placeholder="Digite o nome ou e-mail do operador" />
                    <button [disabled]="errorOperator" (click)="addOperatorRule()" class="button-add" type="reset" [attr.disabled]="temPermissao ? null : 'true'">
                      Adicionar
                    </button>
                  </div>
                  <div class="operators-itens">
                    <div class="deleteAllOperator" (click)="deleteMessageOperator()" *ngIf="checkOperators.length > 0 && temPermissao">
                      <span>{{
                        checkDeleteOperators()
                        ? 'Excluir usuários selecionados'
                        : 'Excluir usuário selecionado'
                        }}</span>
                    </div>
                    <div class="row wrapper margin0">
                      <ul>
                        <ng-container *ngIf="addOperators.length > 0">
                          <li *ngFor="let operator of addOperators">
                            <div class="row">
                              <div class="col-lg-5 col-md-5 d-flex justify-content-start">
                                <input [attr.id]="i" id="operatorCheck{{ i }}" type="checkbox" class="checkbox" [attr.disabled]="temPermissao ? null : 'true'"
                                  (click)="checkOperator(operator)" />
                                <span class="operatorText" data-hj-suppress>{{
                                  operator.nome_pessoa | titlecase
                                  }}</span>
                              </div>
                              <div class="col-lg-6 col-sm-6 d-flex justify-content-start">
                                <span class="operatorText">{{
                                  operator.email
                                  }}</span>
                              </div>
                              <div class="col-lg-1 col-sm-1 d-flex justify-content-start">
                                <a *ngIf="checkLengthOperators() && temPermissao" (click)="removeOperator(operator)"><img
                                    class="actBtns" src="/assets/png/icon_delete_rule.png" /></a>
                              </div>
                            </div>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>

                  <div class="master-list">
                    <div class="row">
                      <div class="col-lg-6">
                        <h5>Masters</h5>
                      </div>
                      <div class="col-lg-6 d-flex justify-content-end masterCheckboxView">
                        <input #checkMaster [attr.id]="i" type="checkbox" class="checkbox" id="master" [attr.disabled]="temPermissao ? null : 'true'"
                          [(ngModel)]="item.master" />
                        <p>Os masters deverão aprovar as transações</p>
                      </div>
                      <div>
                        <ul *ngFor="
                            let pessoa of item.regras_pessoas
                              | sortBy: 'asc':'nome_pessoa';
                            let i = index
                          ">
                          <!--<li *ngIf="pessoa.status == 1; else pendente">-->
                          <li *ngIf="pessoa.master === true" data-hj-suppress>
                            {{ pessoa.nome_pessoa | titlecase }}
                            <span *ngIf="pessoa.status == 0" class="op-pendent">Pendente</span>
                            <span *ngIf="pessoa.status == 1" class="op-approved">Aprovou</span>
                            <span *ngIf="pessoa.status == 2" class="op-repproved">Reprovou</span>
                            <span *ngIf="pessoa.status == 3"> - </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accountView">
                    <label class="label-input">Contas</label>
                    <div class="accountRow">
                      <fibra-form-control [style.width.%]="100">
                        <fibra-dropdown [attr.id]="i" placeholder="Selecionar conta" removeShadown="true" [attr.disabled]="temPermissao ? null : 'true'"
                          customClass="select-option" [options]="countOptionsRule" label=""
                          (emitChange)="changeCounter($event)" id="optionsCounter"></fibra-dropdown>
                      </fibra-form-control>
                      <button class="button-add negativeTop" type="reset" (click)="addAccount()"
                        [disabled]="!temPermissao">
                        Adicionar
                      </button>
                    </div>
                    <div class="deleteAllUsers" (click)="deleteMessageAccount()" *ngIf="checkAccounts.length > 0">
                      <span>{{
                        checkAccounts.length > 1
                        ? 'Excluir contas selecionadas'
                        : 'Excluir conta selecionada'
                        }}</span>
                    </div>
                    <div class="accountsView">
                      <div class="row wrapper margin0">
                        <ul>
                          <li *ngFor="let account of accountGroup; let i = index">
                            <div class="row">
                              <div class="col-lg-11 col-sm-11 d-flex justify-content-start">
                                <input [attr.id]="i" id="accountCheck{{ i }}" type="checkbox" class="checkbox"
                                  (click)="checkAccount(account)" [attr.disabled]="temPermissao ? null : 'true'" />
                                <span class="operatorText">{{ account }}</span>
                              </div>
                              <div class="col-lg-1 col-sm-1 d-flex justify-content-start">
                                <a *ngIf="checkAccounts.length === 0 && temPermissao"
                                  (click)="deleteAccount(account)"><img class="actBtns"
                                    src="/assets/png/icon_delete_rule.png" /></a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="actions-rules">
                    <div class="row">
                      <div class="col-lg-8 col-sm-8">
                        <button class="act-delete" type="button" (click)="deleteRule(item)"
                          *fibraHasRole="'MTZ_APROV.EDIT_EXCL_REG,PJ'">
                          Excluir regra
                        </button>
                      </div>
                      <div class="col-lg-4 col-sm-4 d-flex justify-content-end">
                        <button [ngClass]="
                            addAccArr.length > 0 ||
                            delAccArr.length > 0 ||
                            addOprArr.length > 0 ||
                            delOprArr.length > 0 ||
                            initialMaster !== this.checkMaster ||
                            initialVlrPermitido !== this.vlrPermitido
                              ? 'act-save-enable'
                              : 'act-save'
                          " (click)="actionCancel()" class="act-cancel" type="button">
                          Cancelar
                        </button>
                        <button class="act-save-enable" *fibraHasRole="'MTZ_APROV.EDIT_EXCL_REG,PJ'"
                          type="submit" (click)="actionEdit(item)">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="body-list-add" *ngIf="active !== 'first'">
    <fibra-new-rule-matrix></fibra-new-rule-matrix>
  </div>
  <div class="box-options" *ngIf="showAction">
    <div class="info"></div>
    <div class="actions">
      <div class="text">{{ textAlertRule }}</div>
      <div class="buttons">
        <div class="cancel" (click)="cancelDeleteRule(); showAction = false">
          Cancelar
        </div>
        <div class="approval" (click)="bottomFunctions(selectedBottomFunction)">
          Sim
        </div>
      </div>
    </div>
  </div>
  <fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>
  <fibra-modal-validation *ngIf="securityAcction.show" [apiCallback]="apiCallback" [apiPayload]="securityRequest()"
    (closeEvent)="closeModal()" (responseValidation)="securityResponse($event)">
  </fibra-modal-validation>
</div>