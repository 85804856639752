import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';

import { GetDuplicateBillUsecase } from '@src/core/usecases/charge/get-duplicateBill.usecase';
import { DuplicateBillEntity } from '@src/data/repository/charge/duplicate-bill/duplicate-bill.entity';
import * as Util from '@src/shared/util-common';
import { Subscription } from 'rxjs';

// model
import { DuplicateBillRequest } from '@src/core/domain/charge/duplicate-bill.model';
import {
  DuplicateBillDataShare,
  FilterBillDataShare,
} from '@src/data/repository/data-share-repository';
import { GetDownloadBillUsecase } from '@src/core/usecases/charge/get-downloadBill.usecase';
import { handleFile } from '@src/shared/util-common';
import { ToastErrorMessageComponent } from '../../toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-duplicates-list',
  templateUrl: './duplicates-list.component.html',
  styleUrls: ['./duplicates-list.component.scss'],
})
export class DuplicatesListComponent implements OnInit {

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;
  private subscriptions: Subscription[] = [];

  params: any;
  hide;
  duplicates: Array<DuplicateBillEntity> = [];
  loading: boolean;

  constructor(
    private getDuplicateBill: GetDuplicateBillUsecase,
    private getDownloadBill: GetDownloadBillUsecase,
    private shareFilterBill: FilterBillDataShare
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.shareFilterBill.dataShared.subscribe((filterParams) => {
      console.log(filterParams)
      this.loading = true;
      this.hide = false;
      setTimeout(() => {
        this.request(filterParams);
      }, 700);
    }));
  
  }

  // ngOnDestroy() {
  //   this.shareFilterBill.dataToShare.unsubscribe();
  // }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  private ShowToast(err: any): void {
    const isMessageServer: boolean = !!(err && err.error && err.error.message && err.error.message.length > 0);
    isMessageServer && this.toast && this.toast.callModalMessage(null, err.error.message[0], null, null, null);
  }

  request(filterParams: any) {
    if (!filterParams) { return; }
    const params: any = {
      dt_final: filterParams.dt_Final,
      dt_inicio: filterParams.dt_Inicio,
      nome_sacado: filterParams.draweeName,
      num_carteira: filterParams.codigo_especie ? filterParams.codigo_especie : '',
      num_conta: filterParams.account,
      num_nosso_numero: filterParams.ourNumber,
      num_seu_numero: filterParams.yourNumber,
      vlr_titulo: filterParams.titleValue ? filterParams.titleValue : '0',
      param_carteira: filterParams.param_carteira
    };

    this.getDuplicateBill
      .execute(params)
      .subscribe(this.success, this.error, this.requestComplete);
  }

  private success = (value: DuplicateBillEntity) => {
    if (value.status === 'success') {
      this.duplicates = Util.verifyArray(value.data);
      this.loading = false;

      console.log(this.duplicates)
    }
  };

  public requestDownloadBill(obj) {
    const params: DuplicateBillRequest = {
      num_cpf_cnpj: obj.num_cpf_cnpj,
      num_seu_numero: obj.num_seu_numero,
      num_nosso_numero: obj.num_nosso_numero,
    };
    this.getDownloadBill
      .execute(params)
      .subscribe(this.successDownloadBill, this.error);
  }

  private successDownloadBill = (value: DuplicateBillEntity) => {
    if (value.status === 'success') {
      let fileUrl = value.data.split("u0026").join("&");
      window.open(fileUrl, "_blank");
    }
  };

  private error = (error) => {
    console.log('Error :\n ', error);
    this.loading = false;
    this.duplicates = [];
    this.hide = true
    this.ShowToast(error);
  };
  private requestComplete = () => {
    if (this.duplicates && this.duplicates.length > 0)
      this.hide = false
    else
      this.hide = true
  };

  isNull = (value) => Util.isNull(value);
}

