import { Component, OnInit, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FavoritesEnum } from '@src/data/repository/transfer/beneficiaries/beneficiaries.entity';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileListComponent } from '../../components/profile-list/profile-list.component';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';

// Datashare
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import {
  AccountDataShare,
  BankDataShare,
  SelectBeneficiaryDataShare,
  TransferReceiptDataShare,
} from '@src/data/repository/data-share-repository';
import { PixGetBeneficiariesDataRepository } from '@src/data/repository/pix/beneficiaries/pix-get-beneficiaries-repository';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, concat, forkJoin, Observable, of, Subscription } from 'rxjs';
import { GetTransactionalLimitAccount } from '@src/core/usecases/transactional-limit/get-transactional-limit-usecase';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'fibra-change-transactional-limit',
  templateUrl: './change-transactional-limit.component.html',
  styleUrls: ['./change-transactional-limit.component.scss']
})

export class ChangeTransactionalLimitComponent implements OnInit  {

  @ViewChild(ProfileListComponent, null) profile: ProfileListComponent;
  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  // New implementation
  setTab: string = 'limits';
  editTimeCall: boolean = false;
  timeNight: number = 2000;
  timeDay: number = 1959;
  allowLimit: number;
  loadingLimit: boolean = false;
  accounts = [];
  beneficiaryData: FavoritesEnum[];
  hide;
  loading;
  search;
  paramDeleteAccount;
  paramDeleteBeneficiary;
  typeColorToast: string;
  isCadastro: boolean
  exitComponent: boolean
  maxLimiteAlert: boolean = false;

  // Variaveis para o componente <fibra-transactional-limits>
  editCall: boolean = false;
  onSaveData: boolean = false;
  ValueFormOnSave: any;
  formChanged: boolean = false;
  statusForm: boolean = false;
  cancelButtonPress: boolean = false;
  public showModalValidation: boolean = false;
  numberAccountSelected: string;
  apiCallback: number;
  arrToSave = [];

  dataLimits: Observable<{}>;
  dataLimitsTEF: Observable<{}>;
  dataLimitsTED: Observable<{}>;
  dataLimitsBoleto: Observable<{}>;
  initialValueForm = [];
  globalSuccessCheck: boolean = false;
  
  formAllErrorsMax: BehaviorSubject<{}>;
  formAllErrorsMin: BehaviorSubject<{}>;
  valuesToPayload: BehaviorSubject<{}> = new BehaviorSubject<[]>([]);

  checkErrorMaxTef = [];
  checkErrorMaxTed = [];
  checkErrorMaxBoleto = [];

  checkErrorMinTef = [];
  checkErrorMinTed = [];
  checkErrorMinBoleto = [];

  receivErrorMax: boolean;
  receivErrorMin: boolean;

  temporaryDataLimit = [];
  _unSubscribe: Subscription;
  _unSubscribeBehavious: Subscription;

  constructor(
    private router: Router,
    private shareBank: BankDataShare,
    private shareAccount: AccountDataShare,
    private shareCompany: CompanyDataShare,
    private shareBeneficiary: SelectBeneficiaryDataShare,
    private shareTransferReceipt: TransferReceiptDataShare,
    public getBeneficiariesService: PixGetBeneficiariesDataRepository,
    private route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private getAccountLimit: GetTransactionalLimitAccount
  ) { }
  

  ngOnInit() {
    const dataLimitTransacional = this.route.snapshot.data['resolve'];
    this.profile.requestAccount();
    this.clearDatashare();
    this.listAccounts(dataLimitTransacional);

    //Controla a exibição dos erros de formulário
    this.controlShowErros();    
    
  }

  mountArray(arrReq) {

    const groupByCategory = arrReq.valores.reduce((group, tipo) => {
      const { tipoTransacao } = tipo;
      group[tipoTransacao] = group[tipoTransacao] || [];
      group[tipoTransacao].push(tipo);
      return group;
    }, {});

    const maxLimit = this.allowLimit;
    const tefDiurnoMesma = this.mountTransacPeriod(groupByCategory.TEF, 'DIURNO', 'MESMA_TITULARIDADE');
    const tefDiurnoOutraPj = this.mountTransacPeriod(groupByCategory.TEF, 'DIURNO', 'OUTRA_TITULARIDADE_PJ');
    const tefDiurnoOutraPf = this.mountTransacPeriod(groupByCategory.TEF, 'DIURNO', 'OUTRA_TITULARIDADE_PF');
    const tefNoturnoMesma = this.mountTransacPeriod(groupByCategory.TEF, 'DIURNO', 'MESMA_TITULARIDADE');
    const tefNoturnoOutraPj = this.mountTransacPeriod(groupByCategory.TEF, 'DIURNO', 'OUTRA_TITULARIDADE_PJ');
    const tefNoturnoOutraPf = this.mountTransacPeriod(groupByCategory.TEF, 'DIURNO', 'OUTRA_TITULARIDADE_PF');

    const tedDiurnoMesma = this.mountTransacPeriod(groupByCategory.TED, 'DIURNO', 'MESMA_TITULARIDADE');
    const tedDiurnoOutraPj = this.mountTransacPeriod(groupByCategory.TED, 'DIURNO', 'OUTRA_TITULARIDADE_PJ');
    const tedDiurnoOutraPf = this.mountTransacPeriod(groupByCategory.TED, 'DIURNO', 'OUTRA_TITULARIDADE_PF');
    const tedNoturnoMesma = this.mountTransacPeriod(groupByCategory.TED, 'DIURNO', 'MESMA_TITULARIDADE');
    const tedNoturnoOutraPj = this.mountTransacPeriod(groupByCategory.TED, 'DIURNO', 'OUTRA_TITULARIDADE_PJ');
    const tedNoturnoOutraPf = this.mountTransacPeriod(groupByCategory.TED, 'DIURNO', 'OUTRA_TITULARIDADE_PF');

    const boletoDiurnoMesma = this.mountTransacPeriod(groupByCategory.BOLETO, 'DIURNO', 'MESMA_TITULARIDADE');
    const boletoDiurnoOutraPj = this.mountTransacPeriod(groupByCategory.BOLETO, 'DIURNO', 'OUTRA_TITULARIDADE_PJ');
    const boletoDiurnoOutraPf = this.mountTransacPeriod(groupByCategory.BOLETO, 'DIURNO', 'OUTRA_TITULARIDADE_PF');
    const boletoNoturnoMesma = this.mountTransacPeriod(groupByCategory.BOLETO, 'DIURNO', 'MESMA_TITULARIDADE');
    const boletoNoturnoOutraPj = this.mountTransacPeriod(groupByCategory.BOLETO, 'DIURNO', 'OUTRA_TITULARIDADE_PJ');
    const boletoNoturnoOutraPf = this.mountTransacPeriod(groupByCategory.BOLETO, 'DIURNO', 'OUTRA_TITULARIDADE_PF');

    const dta = [
      {
        maxLimiteValue: maxLimit,
        title: tefDiurnoMesma[0].tipoTransacao,
        diurnoMesma: tefDiurnoMesma[0].valorMaximoTotal,
        diurnoOutraPJ: tefDiurnoOutraPj[0].valorMaximoTotal,
        diurnoOutraPF: tefDiurnoOutraPf[0].valorMaximoTotal,
        noturnoMesma: tefNoturnoMesma[0].valorMaximoTotal,
        noturnoOutraPJ: tefNoturnoOutraPj[0].valorMaximoTotal,
        noturnoOutraPF: tefNoturnoOutraPf[0].valorMaximoTotal,
        transacaoDiurnaMesma: 0,
        transacaoDiurnaOutraPJ: 0,
        transacaoDiurnaOutraPF: 0,
        transacaoNoturnaMesma: 0,
        transacaoNoturnaOutraPJ: 0,
        transacaoNoturnaOutraPF: 0,
      },
      {
        maxLimiteValue: maxLimit,
        title: tedDiurnoMesma[0].tipoTransacao,
        diurnoMesma: tedDiurnoMesma[0].valorMaximoTotal,
        diurnoOutraPJ: tedDiurnoOutraPj[0].valorMaximoTotal,
        diurnoOutraPF: tedDiurnoOutraPf[0].valorMaximoTotal,
        noturnoMesma: tedNoturnoMesma[0].valorMaximoTotal,
        noturnoOutraPJ: tedNoturnoOutraPj[0].valorMaximoTotal,
        noturnoOutraPF: tedNoturnoOutraPf[0].valorMaximoTotal,
        transacaoDiurnaMesma: 0,
        transacaoDiurnaOutraPJ: 0,
        transacaoDiurnaOutraPF: 0,
        transacaoNoturnaMesma: 0,
        transacaoNoturnaOutraPJ: 0,
        transacaoNoturnaOutraPF: 0,
      },
      {
        maxLimiteValue: maxLimit,
        title: boletoDiurnoMesma[0].tipoTransacao,
        diurnoMesma: boletoDiurnoMesma[0].valorMaximoTotal,
        diurnoOutraPJ: boletoDiurnoOutraPj[0].valorMaximoTotal,
        diurnoOutraPF: boletoDiurnoOutraPf[0].valorMaximoTotal,
        noturnoMesma: boletoNoturnoMesma[0].valorMaximoTotal,
        noturnoOutraPJ: boletoNoturnoOutraPj[0].valorMaximoTotal,
        noturnoOutraPF: boletoNoturnoOutraPf[0].valorMaximoTotal,
        transacaoDiurnaMesma: 0,
        transacaoDiurnaOutraPJ: 0,
        transacaoDiurnaOutraPF: 0,
        transacaoNoturnaMesma: 0,
        transacaoNoturnaOutraPJ: 0,
        transacaoNoturnaOutraPF: 0,
      }];

    return dta;
  }

  mountReturnArray(arrReq) {

    const maxLimit = this.allowLimit;

    const dta = [
      {
        maxLimiteValue: maxLimit,
        title: 'TEF',
        diurnoMesma: arrReq.tefDiurnoMesma,
        diurnoOutraPJ: arrReq.tefDiurnoOutraPJ,
        diurnoOutraPF: arrReq.tefDiurnoOutraPF,
        noturnoMesma: arrReq.tefNoturnoMesma,
        noturnoOutraPJ: arrReq.tefNoturnoOutraPJ,
        noturnoOutraPF: arrReq.tefNoturnoOutraPF,
        transacaoDiurnaMesma: 0,
        transacaoDiurnaOutraPJ: 0,
        transacaoDiurnaOutraPF: 0,
        transacaoNoturnaMesma: 0,
        transacaoNoturnaOutraPJ: 0,
        transacaoNoturnaOutraPF: 0,
      },
      {
        maxLimiteValue: maxLimit,
        title: 'TED',
        diurnoMesma: arrReq.tedDiurnoMesma,
        diurnoOutraPJ: arrReq.tedDiurnoOutraPJ,
        diurnoOutraPF: arrReq.tedDiurnoOutraPF,
        noturnoMesma: arrReq.tedNoturnoMesma,
        noturnoOutraPJ: arrReq.tedNoturnoOutraPJ,
        noturnoOutraPF: arrReq.tedNoturnoOutraPF,
        transacaoDiurnaMesma: 0,
        transacaoDiurnaOutraPJ: 0,
        transacaoDiurnaOutraPF: 0,
        transacaoNoturnaMesma: 0,
        transacaoNoturnaOutraPJ: 0,
        transacaoNoturnaOutraPF: 0,
      },
      {
        maxLimiteValue: maxLimit,
        title: 'BOLETO',
        diurnoMesma: arrReq.boletoDiurnoMesma,
        diurnoOutraPJ: arrReq.boletoDiurnoOutraPJ,
        diurnoOutraPF: arrReq.boletoDiurnoOutraPF,
        noturnoMesma: arrReq.boletoNoturnoMesma,
        noturnoOutraPJ: arrReq.boletoNoturnoOutraPJ,
        noturnoOutraPF: arrReq.boletoNoturnoOutraPF,
        transacaoDiurnaMesma: 0,
        transacaoDiurnaOutraPJ: 0,
        transacaoDiurnaOutraPF: 0,
        transacaoNoturnaMesma: 0,
        transacaoNoturnaOutraPJ: 0,
        transacaoNoturnaOutraPF: 0,
      }];

    return dta;
  }

  mountTransacPeriod(data: any, periodo: string, titularidade: string) {
    return data.filter(r => r.tipoPeriodo === periodo && r.tipoTitularidade === titularidade);
  }

  readExitComponent(evt): void {
    this.editTimeCall = evt;
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  clearDatashare() {
    this.shareBank.clear();
    this.shareAccount.clear();
    this.shareCompany.clear();
    this.shareBeneficiary.clear();
    this.shareTransferReceipt.clear();
  }

  newBeneficiary() {
    this.router.navigate(['/bank']);
  }

  // New implementatios
  toggleTab(tab: string): void {
    this.setTab = tab;
  }

  listAccounts(acc): void {
    const accounts = acc.data.filter(r => r.cod_modalidade !== '61' && r.cod_modalidade !== '8');
    accounts.forEach(cnt => {
      this.accounts.push({
        indice: cnt.num_indice,
        name: `${cnt.num_conta} - ${this.titleCaseWord(cnt.desc_conta)}`,
        value: cnt.num_conta,
        modality_account: cnt.exibicao,
        selected: cnt.num_indice === 1 ? true : false
      })
    })
  }

  // Request do limite diário
  accountSelected(evt): void {
    this.numberAccountSelected = evt;
    this.loadingLimit = true;

    if(this._unSubscribe){
      this._unSubscribe.unsubscribe()
    }

    this._unSubscribe = this.getAccountLimit.execute(evt).subscribe((rs: any) => {
      this.loadingLimit = false;
      let limitReady: any = rs.data.find((e:any) => e.idStatus === 2 && e.tipoAlteracao === 'LIMITE' && !e.pix);
      this.allowLimit = limitReady.limiteDiario;

      this.dataLimits = new Observable<{}>((obs) => {
        obs.next([this.mountArray(limitReady)]);
        this.initialValueForm = [this.mountArray(limitReady)]
      });
    })
  }

  public titleCaseWord(word: string) {
    var splitStr = word.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  editar(): void {
    this.editCall = true;
  }

  cancelEdit(): void {
    this.toast.callModalMessage(null, 'Ao cancelar você perderá tudo que foi preenchido.', 'Deseja mesmo cancelar?', true, false)
  }

  saveChangeLimit(): void {
    this.onSaveData = true;
    this.saveLimits();
  }


  getValuesFormOnChange(evt): void {

    let addKeyLimitMax = Object.assign(evt, { maxLimiteValue: this.allowLimit });
    this.ValueFormOnSave = addKeyLimitMax;

    this.temporaryDataLimit.push(addKeyLimitMax);
    this.valuesToPayload.next(this.temporaryDataLimit);

    this.dataLimits = new Observable<{}>((obs) => {
      obs.next([this.mountReturnArray(addKeyLimitMax)])
    });

    concat(this.getValueToPayload()).subscribe((r: any) => {
      if(r.length >= 1){
        this.arrToSave = r;
        this.apiCallback = 125;
        this.securityRequest();
        this.showModalValidation = true;
      }
    })

    setTimeout(() => {
      /* this.editCall = false;
      this.onSaveData = false; */
      this.temporaryDataLimit = []; //Esse atribuição ficará após o evio do payload
    }, 1000);
  }

  callBackIfChangeForm(evt: boolean): void {
    this.formChanged = evt;
  }

  forStatusObserveMax(evt): void {
    let err = [evt];

    err.forEach((key) => {
      if(key.max) this.checkErrorMaxTed.push(key.max);
      if(!key.max) this.checkErrorMaxTed = [false];
    })

    this.formAllErrorsMax.next([...this.checkErrorMaxTef, ...this.checkErrorMaxTed, ...this.checkErrorMaxBoleto]);
  }

  forStatusObserveMin(evt): void {
    let err = [evt];

    err.forEach((key) => {
      if(key.min) this.checkErrorMinTed.push(key.min);
      if(!key.min) this.checkErrorMinTed = [false];
    })

    this.formAllErrorsMin.next([...this.checkErrorMinTed, ...this.checkErrorMinTef, ...this.checkErrorMaxBoleto]);
  }

  getValueErrorMax() {
    return this.formAllErrorsMax.asObservable();
  }

  getValueErrorMin() {
    return this.formAllErrorsMin.asObservable();
  }

  getValueToPayload() {
    return this.valuesToPayload.asObservable();
  }

  public confirmeCancelChange() {
    this.editCall = false;
    this.cancelButtonPress = true;
    this.formAllErrorsMax.next([false]);
    this.formAllErrorsMin.next([false]);
  }

  cancelarTime(): void {
    this.editTimeCall = false;
  }

  editTimeLimit(): void {
    this.editTimeCall = true;
  }

  saveTimeLimit(): void {
    this.showModalValidation = true;
  }

  // Metodos do modal de credenciais
  responseApiModal(_$event) {
    this.showModalValidation = false;
  }

  securityRequest = () => {
    let token = sessionStorage.getItem('accessToken');
    const decoded: any = jwt_decode(token);
    const tipoAcesso = localStorage.getItem('acessoMaster') === 'true' ? 1 : 2;

    return {
      nrConta: this.numberAccountSelected,
      codEmpresa: 0,
      emailOperador: decoded.email,
      empresaId: 0,
      nomeSolicitante: decoded.name,
      pix: false,
      limiteDiario: this.allowLimit,
      valores: [
        {
          idTipoTransacao: 2,
          tipoTransacao: "TED",
          idTipoTitularidade: 1,
          tipoTitularidade: "MESMA_TITULARIDADE",
          idTipoPeriodo: 1,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.arrToSave[0].tedDiurnoMesma,
          valorMaximoTransacao: 0
        },
        {
          idTipoTransacao: 2,
          tipoTransacao: "TED",
          idTipoTitularidade: 2,
          tipoTitularidade: "OUTRA_TITULARIDADE_PJ",
          idTipoPeriodo: 1,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.arrToSave[0].tedDiurnoOutraPJ,
          valorMaximoTransacao: 0,
        },
        {
          idTipoTransacao: 2,
          tipoTransacao: "TED",
          idTipoTitularidade: 3,
          tipoTitularidade: "OUTRA_TITULARIDADE_PF",
          idTipoPeriodo: 1,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.arrToSave[0].tedDiurnoOutraPF,
          valorMaximoTransacao: 0,
        },        
        {
          idTipoTransacao: 2,
          tipoTransacao: "TED",
          idTipoTitularidade: 1,
          tipoTitularidade: "MESMA_TITULARIDADE",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.arrToSave[0].tedNoturnoMesma,
          valorMaximoTransacao: 0,
        },
        {
          idTipoTransacao: 2,
          tipoTransacao: "TED",
          idTipoTitularidade: 2,
          tipoTitularidade: "OUTRA_TITULARIDADE_PJ",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.arrToSave[0].tedNoturnoOutraPJ,
          valorMaximoTransacao: 0,
        },
        {
          idTipoTransacao: 2,
          tipoTransacao: "TED",
          idTipoTitularidade: 3,
          tipoTitularidade: "OUTRA_TITULARIDADE_PF",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.arrToSave[0].tedNoturnoOutraPF,
          valorMaximoTransacao: 0,
        },
        {
          idTipoTransacao: 3,
          tipoTransacao: "TEF",
          idTipoTitularidade: 1,
          tipoTitularidade: "MESMA_TITULARIDADE",
          idTipoPeriodo: 1,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.arrToSave[0].tefDiurnoMesma,
          valorMaximoTransacao: 0,
        },
        {
          idTipoTransacao: 3,
          tipoTransacao: "TEF",
          idTipoTitularidade: 2,
          tipoTitularidade: "OUTRA_TITULARIDADE_PJ",
          idTipoPeriodo: 1,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.arrToSave[0].tefDiurnoOutraPJ,
          valorMaximoTransacao: 0,
        },
        {
          idTipoTransacao: 3,
          tipoTransacao: "TEF",
          idTipoTitularidade: 3,
          tipoTitularidade: "OUTRA_TITULARIDADE_PF",
          idTipoPeriodo: 1,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.arrToSave[0].tefDiurnoOutraPF,
          valorMaximoTransacao: 0,
        },        
        {
          idTipoTransacao: 3,
          tipoTransacao: "TEF",
          idTipoTitularidade: 1,
          tipoTitularidade: "MESMA_TITULARIDADE",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.arrToSave[0].tefNoturnoMesma,
          valorMaximoTransacao: 0
        },
        {
          idTipoTransacao: 3,
          tipoTransacao: "TEF",
          idTipoTitularidade: 2,
          tipoTitularidade: "OUTRA_TITULARIDADE_PJ",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.arrToSave[0].tefNoturnoOutraPJ,
          valorMaximoTransacao: 0
        },
        {
          idTipoTransacao: 3,
          tipoTransacao: "TEF",
          idTipoTitularidade: 3,
          tipoTitularidade: "OUTRA_TITULARIDADE_PF",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.arrToSave[0].tefNoturnoOutraPF,
          valorMaximoTransacao: 0
        },
        {
          idTipoTransacao: 4,
          tipoTransacao: "BOLETO",
          idTipoTitularidade: 1,
          tipoTitularidade: "MESMA_TITULARIDADE",
          idTipoPeriodo: 2,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.arrToSave[0].boletoDiurnoMesma,
          valorMaximoTransacao: 0,
        },
        {
          idTipoTransacao: 4,
          tipoTransacao: "BOLETO",
          idTipoTitularidade: 2,
          tipoTitularidade: "OUTRA_TITULARIDADE_PJ",
          idTipoPeriodo: 1,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.arrToSave[0].boletoDiurnoOutraPJ,
          valorMaximoTransacao: 0,
        },
        {
          idTipoTransacao: 4,
          tipoTransacao: "BOLETO",
          idTipoTitularidade: 3,
          tipoTitularidade: "OUTRA_TITULARIDADE_PF",
          idTipoPeriodo: 1,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.arrToSave[0].boletoDiurnoOutraPF,
          valorMaximoTransacao: 0,
        },        
        {
          idTipoTransacao: 4,
          tipoTransacao: "BOLETO",
          idTipoTitularidade: 1,
          tipoTitularidade: "MESMA_TITULARIDADE",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.arrToSave[0].boletoNoturnoMesma,
          valorMaximoTransacao: 0
        },
        {
          idTipoTransacao: 4,
          tipoTransacao: "BOLETO",
          idTipoTitularidade: 2,
          tipoTitularidade: "OUTRA_TITULARIDADE_PJ",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.arrToSave[0].boletoNoturnoOutraPJ,
          valorMaximoTransacao: 0
        },
        {
          idTipoTransacao: 4,
          tipoTransacao: "BOLETO",
          idTipoTitularidade: 3,
          tipoTitularidade: "OUTRA_TITULARIDADE_PF",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.arrToSave[0].boletoNoturnoOutraPF,
          valorMaximoTransacao: 0
        }
      ],
      aprovadores: [
        {
          idPessoa: 0,
          nomeAprovador: decoded.name,
          idTipoAcesso: tipoAcesso,
        }
      ]
    }
  }

  public closeModalApi(_$event) {

    // GARANTE QUE AO FECHAR O MODAL DE TOKEN
    // OS VALORES DO FORMULÁRIO RETORNAM AO VALOR ANTERIOR
    if(!this.globalSuccessCheck){
      this.dataLimits = new Observable<{}>((obs) => {
        obs.next(this.initialValueForm);
      });
    }

    this.editCall = false;
    this.onSaveData = false;

    this.showModalValidation = false;
    this.modalService.dismissAll();
  }

  public securityResponse = value => {    
    this.valuesToPayload.next({});

    this.requestSeccess(value);
    this.requestError(value);
  }

  private requestError = (err) => {
    
    if(err.error && err.error.code === 500){
      console.log('pendente')
      this.typeColorToast = '#ED6C6C';
      this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar alterar o limite.', ' Tente novamente.', false, true);
      return;
    }

    if(err.error && err.error.code === 404 && err.error.status === 'error'){
      this.typeColorToast = '#ED6C6C';
      this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar alterar o limite.', ' Tente novamente.', false, true);
      return;
    }
  }

  private requestSeccess = (sc) => {  
    
    if(sc.code === 200 && sc.data.indexOf('Alterado') !== -1){
      this.globalSuccessCheck = true;
      setTimeout(() => {
        this.typeColorToast = '#73B599';
        this.toast.callModalMessage(null, sc.data, null, false, true);     
      }, 600);
      return
    }

    if(sc.code === 200 && sc.data.indexOf('pendente') !== -1){
      this.globalSuccessCheck = true;
      setTimeout(() => {
        this.typeColorToast = '#D69D10';
        this.toast.callModalMessage(null, sc.data, null, false, true);     
      }, 600);
      return      
    }
  }

  gotoLimitPix() {
    localStorage.setItem('redirectPixLimits', 'redirectPixLimits')
    localStorage.setItem('redirectPixLimitsTab', 'redirectPixLimitsHorario');
    this.router.navigate(['/pix']);
  }

  controlShowErros(){
    this.formAllErrorsMax = new BehaviorSubject<[]>([]);
    this.formAllErrorsMin = new BehaviorSubject<[]>([]);

    // Observa os erros do tipo MAX 
    this.getValueErrorMax().subscribe((rs: [{}]) => {
      this.receivErrorMax = rs.includes(true) ? true : false;
    });

    // Observa os erros do tipo Min 
    this.getValueErrorMin().subscribe((rs: [{}]) => {
      this.receivErrorMin = rs.includes(true) ? true : false;

      if(this.receivErrorMin) this.toast.callModalMessage(null, 'Valor inválido. Informe um valor maior que zero.', null, null, true);
      if(!this.receivErrorMin) this.toast.closeModal();
    });
  }

  saveLimits(){
    concat(this.getValueToPayload()).subscribe((r: any) => {
      if(r.length === 3){
        this.arrToSave = r;
        this.showModalValidation = true;
      }
    })
  }
  

}
