<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <div class="main-divisor"></div>
  <fibra-profile-list></fibra-profile-list>
</div>

<div class="content-page">
  <fibra-breadcrumb
    bottom="-120px"
    infoAccount=""
    roule="Pagamento de boletos"
    arrow="false"
    downloadDoc="false"
  ></fibra-breadcrumb>
  <div class="body-content">
    <div class="payment-pending">
      <div class="payment-pending-content">
        <div class="row">
          <div class="col-12">
            <img src="/assets/png/iconWarningOrange.png" />
          </div>
        </div>

        <div class="row">
          <div class="col-12 padding-top">
            <label class="payment-title-warning">Pagamento pendente</label>
          </div>
        </div>

        <div class="row padding-top">
          <div class="col-12">
            <label>Enviado para aprovação do autorizador da sua empresa.</label>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <button
              type="button"
              (click)="goToView('/payment')"
              class="btn btn btn-outline-primary"
            >
              Voltar
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <button
              type="button"
              (click)="goToView('/payment')"
              class="btn btn btn-outline-primary"
            >
              Novo Pagamento
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <button
              type="button"
              (click)="goToView('/transaction-approval')"
              class="btn btn btn-primary"
            >
              Acompanhar transação
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
