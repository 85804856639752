<div *ngIf="loading" class="card-body body-accordion">
    <div class="resume" [ngSwitch]="identificationPage">
      <span *ngSwitchCase="'PIX-LIMITE'" (click)="onClick()">Resumo da alteração PIX</span>
      <span *ngSwitchCase="'CHANGE-TRANSACTION'">Resumo da alteração</span>
    </div>
    <div class="logo_fibra">
      <img alt="Logo-fibra" src="/assets/svg/logo_fibra.svg" />
    </div>
      <div *ngIf="data.tipoAlteracao !== 'HORARIO'">
        <div class="resume_dice_description" [ngSwitch]="identificationPage">
          <div class="loyalty">
            <div class="title">
              <span>Tipo de Transferência</span>
            </div>
            <div *ngSwitchCase="'PIX-LIMITE'" class="loyalty-span">
              <div class="limits" *ngIf="data.tipoAlteracao == 'LIMITE'">
                <div class="limits-item">
                  <div class="itens-hours">PIX</div>
                  <p>Diurno</p>
                  <p>Noturno</p>
                  <p>Por transação diurna</p>
                  <p>Por transação noturna</p>
                </div>
  
                <div class="limits-item">
                  <div class="itens-hours ">Mesma titularidade</div>
                  <div  *ngFor="let item of data.valores">
                    <p *ngIf="item.tipoTransacao == 'PIX' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'MESMA_TITULARIDADE'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    <p *ngIf="item.tipoTransacao == 'PIX' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'MESMA_TITULARIDADE'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    <p *ngIf="item.tipoTransacao == 'PIX' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'MESMA_TITULARIDADE'">{{  item.valorMaximoTransacao | currency: 'BRL' }}</p>
                    <p *ngIf="item.tipoTransacao == 'PIX' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'MESMA_TITULARIDADE'">{{  item.valorMaximoTransacao | currency: 'BRL' }}</p>
                  </div>
                </div>
  
                <div class="limits-item">
                  <div class="itens-hours">Outra titularidade PJ</div>
                  <div  *ngFor="let item of data.valores">
                    <p *ngIf="item.tipoTransacao == 'PIX' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PJ'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    <p *ngIf="item.tipoTransacao == 'PIX' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PJ'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    <p *ngIf="item.tipoTransacao == 'PIX' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PJ'">{{  item.valorMaximoTransacao | currency: 'BRL' }}</p>
                    <p *ngIf="item.tipoTransacao == 'PIX' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PJ'">{{  item.valorMaximoTransacao | currency: 'BRL' }}</p>
                  </div>
                </div>
  
                <div class="limits-item">
                  <div class="itens-hours"><span class="loyalty-span">Outra titularidade PF</span></div>
                  <div  *ngFor="let item of data.valores">
                    <p *ngIf="item.tipoTransacao == 'PIX' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PF'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    <p *ngIf="item.tipoTransacao == 'PIX' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PF'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    <p *ngIf="item.tipoTransacao == 'PIX' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PF'">{{  item.valorMaximoTransacao | currency: 'BRL' }}</p>
                    <p *ngIf="item.tipoTransacao == 'PIX' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PF'">{{  item.valorMaximoTransacao | currency: 'BRL' }}</p>
                  </div>
                </div>
              </div>
            </div>
  
            <div *ngSwitchCase="'CHANGE-TRANSACTION'">
              <div class="limits loyalty-span">
                <div class="limits-item">
                    <div class="itens-hours width-column">TED</div>
                    <p>Diurno</p>
                    <p>Noturno</p>
                </div>
    
                <div class="limits-item">
                    <div class="itens-hours ">Mesma titularidade</div>
                    <div  *ngFor="let item of data.valores">
                      <p *ngIf="item.tipoTransacao == 'TED' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'MESMA_TITULARIDADE'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                      <p *ngIf="item.tipoTransacao == 'TED' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'MESMA_TITULARIDADE'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    </div>
                </div>
    
                <div class="limits-item">
                    <div class="itens-hours">Outra titularidade PJ</div>
                    <div  *ngFor="let item of data.valores">
                      <p *ngIf="item.tipoTransacao == 'TED' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PJ'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                      <p *ngIf="item.tipoTransacao == 'TED' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PJ'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    </div>
                </div>
    
                <div class="limits-item">
                    <div class="itens-hours"><span class="loyalty-span">Outra titularidade PF</span></div>
                    <div  *ngFor="let item of data.valores">
                      <p *ngIf="item.tipoTransacao == 'TED' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PF'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                      <p *ngIf="item.tipoTransacao == 'TED' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PF'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    </div>
                </div>
              </div>
    
              <div class="limits loyalty-span marge-top-30">
                <div class="limits-item">
                    <div class="itens-hours width-column">Transferência (TEF)</div>
                    <p>Diurno</p>
                    <p>Noturno</p>
                </div>
    
                <div class="limits-item">
                    <div class="itens-hours ">Mesma titularidade</div>
                    <div  *ngFor="let item of data.valores">
                      <p *ngIf="item.tipoTransacao == 'TEF' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'MESMA_TITULARIDADE'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                      <p *ngIf="item.tipoTransacao == 'TEF' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'MESMA_TITULARIDADE'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    </div>
                </div>
    
                <div class="limits-item">
                    <div class="itens-hours">Outra titularidade PJ</div>
                    <div  *ngFor="let item of data.valores">
                      <p *ngIf="item.tipoTransacao == 'TEF' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PJ'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                      <p *ngIf="item.tipoTransacao == 'TEF' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PJ'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    </div>
                </div>
    
                <div class="limits-item">
                    <div class="itens-hours"><span class="loyalty-span">Outra titularidade PF</span></div>
                    <div  *ngFor="let item of data.valores">
                      <p *ngIf="item.tipoTransacao == 'TEF' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PF'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                      <p *ngIf="item.tipoTransacao == 'TEF' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PF'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    </div>
                </div>
              </div>
    
              <div class="limits loyalty-span marge-top-30">
                <div class="limits-item">
                    <div class="itens-hours width-column">Pagamento de Boleto</div>
                    <p>Diurno</p>
                    <p>Noturno</p>
                </div>
    
                <div class="limits-item">
                    <div class="itens-hours ">Mesma titularidade</div>
                    <div  *ngFor="let item of data.valores">
                      <p *ngIf="item.tipoTransacao == 'BOLETO' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'MESMA_TITULARIDADE'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                      <p *ngIf="item.tipoTransacao == 'BOLETO' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'MESMA_TITULARIDADE'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    </div>
                </div>
    
                <div class="limits-item">
                    <div class="itens-hours">Outra titularidade PJ</div>
                    <div  *ngFor="let item of data.valores">
                      <p *ngIf="item.tipoTransacao == 'BOLETO' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PJ'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                      <p *ngIf="item.tipoTransacao == 'BOLETO' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PJ'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    </div>
                </div>
    
                <div class="limits-item">
                    <div class="itens-hours"><span class="loyalty-span">Outra titularidade PF</span></div>
                    <div  *ngFor="let item of data.valores">
                      <p *ngIf="item.tipoTransacao == 'BOLETO' && item.tipoPeriodo == 'DIURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PF'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                      <p *ngIf="item.tipoTransacao == 'BOLETO' && item.tipoPeriodo == 'NOTURNO' && item.tipoTitularidade == 'OUTRA_TITULARIDADE_PF'">{{  item.valorMaximoTotal | currency: 'BRL' }}</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </div>
      
      <div class="resume_dice_description" *ngIf="identificationPage == 'PIX-LIMITE' && data.tipoAlteracao == 'HORARIO'">
        <div class="loyalty">
          <div class="title">
            <span>Horário</span>
          </div>
          <div class="limits loyalty-span">
            <div class="limits-item">
                <div class="itens-hours-hours">Horário Diurno</div>
                  <p>{{  data.horarioDiurnoInicio }} às {{  data.horarioDiurnoFim  }}</p>
            </div>

            <div class="limits-item-hours">
                <div class="itens-hours-hours">Horário Noturno</div>
                  <p>{{  data.horarioNoturnoInicio }} às {{  data.horarioNoturnoFim }}</p>
            </div>

            <div class="limits-item-hours">
                <div class="itens-hours-hours margin-top-20"></div>
                <p>*Horário de Brasília</p>
            </div>
          </div>
        </div>
      </div>

      <div class="approvers">
        <div class="recipient-dice-account content-space-between paymentView">
          <div class="recipient">
            <div class="margin-top-26"></div>
            <div class="operator">
              <label>Operador responsável</label>
              <span>{{ data.nomeSolicitante }}</span>
            </div>
          </div>
        </div>
        <div class="recipient-dice-account content-space-between paymentView">
          <div class="recipient">
            <div class="margin-top-32"></div>
            <div class="operator">
              <label>Autorizado por</label>
              <div class="permission-transaction-box">
                <div class="transaction-column">
                  <div
                  class="transaction-item"
                  *ngFor="let approvers of data.aprovadores"
                    >
                    <p *ngIf="approvers.idTipoAcesso == 1">Operador</p>
                    <p *ngIf="approvers.idTipoAcesso == 2">Master</p>
                    <p
                      [ngClass]="{
                        red: approvers?.status == 'Recusado',
                        green: approvers?.status === 'Aprovado',
                        yellow: approvers?.status === 'Pendente' || approvers?.status === 'Aguardando'
                      }"
                    >
                      <img
                        [src]="
                          approvers?.status === 'Recusado'
                            ? 'assets/png/refusedIcon.png'
                            : approvers?.status === 'Aprovado' 
                            ? 'assets/png/approvedIcon.png'
                            : 'assets/svg/tooltip-alert-orange.svg'
                        " alt=""
                      />
                      {{ approvers?.status }}
                    </p>
                    <p class="font-size"
                      [ngClass]="{
                        red: approvers?.status == 'Recusado',
                        green: approvers?.status === 'Aprovado',
                        yellow: approvers?.status === 'Pendente' || approvers?.status === 'Aguardando'
                      }"
                    >
                        {{ approvers?.nomeAprovador }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="observation" *ngIf="identificationPage == 'PIX-LIMITE' && data.tipoAlteracao == 'HORARIO' && data.idStatus === 1">
          <p>*O horário alterado ficará vigente para todas as transações: TED, Transferência (TEF), Pagamento de Boleto e Transferência Pix.</p>
          <p>**Ao solicitar a alteração do horário será necessário aguardar de 24 a 48 horas, após a aprovação de todos os masters, para que seja efetivada.</p>
        </div>
        <div class="observation" *ngIf="identificationPage == 'PIX-LIMITE' && data.tipoAlteracao == 'HORARIO' && data.idStatus === 2">
          <p>*Necessário aguardar de 24 a 48 horas, após a aprovação de todos os masters, para que a alteração seja efetivada.</p>
        </div>
        <div class="observation" *ngIf="data.tipoAlteracao == 'LIMITE' && data.tipoAlteracao !== 'HORARIO' && data.idStatus === 1">
          <p>*No caso de aumento do limite será necessário aguardar de 24 a 48 horas, após a aprovação de todos os masters, para utilizá-lo.</p>
          <p>**Serão considerados os limites vigentes no momento da liquidação das transações. </p>
        </div>
        <div class="observation" *ngIf="data.tipoAlteracao == 'LIMITE' && data.tipoAlteracao !== 'HORARIO' && data.idStatus === 2">
          <p>*Necessário aguardar de 24 a 48 horas, após a aprovação de todos os masters, para que a alteração seja efetivada.</p>
        </div>

      </div>
  </div>
  <div
    *ngIf="!loading"
    class="d-flex justify-content-center position loading-container"
  >
    <div class="spinner-border text-primary" role="status"></div>
  </div>
  