<div id="transferInfo" class="box-content" [formGroup]="transferData">
  <div class="content-same">
    <div class="arrow" (click)="goBack()" style="cursor: pointer;">
      <img class="arrow-img" src="/assets/svg/arrow-voltar-icon.svg" alt="arrow-voltar"> <span class="voltar">
        Voltar</span>
    </div>
    <ng-container *ngIf="favorito">
      <div class="titulo">
        <div class="div-redondo-1"><span>1</span></div>
        <span class="titulo-text">Dados do beneficiário </span>
      </div>

      <div class="divisor-2"></div>

      <div class="info-beneficiaries">
        <div class="info-box">
          <div class="info-person">
            <div class="initial-letters" [id]="'background'">
              {{ initialLetters(beneficiaryData?.beneficiario.beneficiarioInfo.nome_cliente) }}
            </div>
            <div class="text">
              <label data-hj-suppress>{{
                beneficiaryData?.beneficiario.beneficiarioInfo.nome_cliente | titlecase
                }}</label>
              <span>CNPJ:
                {{
                removeMask(beneficiaryData?.beneficiario.beneficiarioInfo.num_cpf_cnpj)
                | cpfcnpj
                }}</span>
            </div>
          </div>
          <div class="divisor-3"></div>
          <div class="info-bank">
            <div class="initial-letters" [id]="'background'" *ngIf="beneficiaryData?.quote.cod_banco !== 58616418">
              {{ initialLetters(beneficiaryData?.quote.nome_banco) }}
            </div>
            <div class="initial-letters" *ngIf="beneficiaryData?.quote.cod_banco === 58616418">
              <img class="fibra-owner" src="assets/png/icon-owner-fibra.png" alt="Logotipo Banco Fibra" />
            </div>
            <div class="text">
              <label>
                {{ beneficiaryData?.quote.cod_banco }}
                {{ beneficiaryData?.quote.nome_banco }}
              </label>
              <span>AG: {{ beneficiaryData?.beneficiario.chaveInfo.agencia_beneficiario }} | CC:
                {{ beneficiaryData?.beneficiario.chaveInfo.num_conta_beneficiario | account }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!favorito">
      <div class="trocar_banco_noinfo">
        <div class="initial-letters">
          <img class="fibra-owner" src="assets/png/icon-owner-fibra.png" alt="Logotipo Banco Fibra" />
        </div>
        <span class="nome-banco">
          {{ bank?.cod_banco }} {{ bank?.nome_banco }}</span>
        <button (click)="trocaBanco()" class="btn-troca">
          <img src="/assets/svg/transf.svg" alt="Ion" />
          Trocar instituição
        </button>
      </div>

      <div class="divisor-2" *ngIf="!favorito"></div>

      <div class="dado-1" *ngIf="!favorito">
        <div class="titulo">
          <div class="div-redondo-1"><span>1</span></div>
          <span class="titulo-text">Dados do beneficiário </span>
        </div>

        <div class="outra_titularidade">
          <div class="titularidade" [class.active]="ownership === 'same'" (click)="changeOwnership('same')"
            *fibraHasRole="'TRANSF.TRANSF_MSM_TITU'">
            <span class="text_span-titularidade">Mesma titularidade</span>
          </div>
          <div class="titularidade" [class.active]="ownership === 'other'" (click)="changeOwnership('other')"
            *fibraHasRole="'TRANSF.TRANSF_OTR_TITU'">
            <span class="text_span-titularidade">Outra titularidade</span>
          </div>
        </div>

        <div *ngIf="ownership === 'same'">
          <div class="selecionar_conta">
            <div class="dados_beneficiario">
              <div class="dados-mesmo-banco">
                <div class="auxiliar">
                  <div class="dados_beneficiario-nome">
                    <label data-hj-suppress>Nome do Beneficiário</label>
                    <span>{{ companyData?.name || ' ' }}</span>
                  </div>

                  <div class="dados_beneficiario-nome">
                    <label class="">CNPJ/CPF</label>
                    <span class="">{{
                      removeMask(companyData?.modality_account) | cpfcnpj
                      }}</span>
                  </div>
                </div>
                <fibra-slide-account-selector-pix [label]="'Selecione a conta do Beneficiário'" [data]="accountData"
                  (emitAccount)="setAccountOrigin($event)" (emitIndex)="setBlockAccountFibra($event)"
                  [bank]="this.beneficiaryData.quote.cod_banco" [ownership]="this.ownership" [isBeneficiary]="true"
                  [debitAccount]="debitAccount" [isEqualCnpj]="isEqualCnpj">
                </fibra-slide-account-selector-pix>
              </div>
            </div>
          </div>
        </div>

        <div class="other" *ngIf="ownership === 'other'">
          <div class="dados_banco-conta" [formGroup]="outraTitularidade">
            <label>Número da conta com o dígito</label>
            <input type="text" class="form-control" placeholder="0000000000" formControlName="conta" maxlength="11" [ngClass]="{ 'account-input': !errorAccoutInput, 'account-input-warning': errorAccoutInput }" />
          </div>
          <div class="dados_banco-cpf">
            <label>Nome do beneficiário</label>
            <div class="loading" *ngIf="loading">
              
              <div class="d-flex justify-content-center position loading-container">
                <div class="spinner-border text-primary" role="status"></div>
              </div>
              <span>Buscando beneficiário...</span>
            </div>

            <span *ngIf="accountFibra" class="data-hj-suppress">{{
              (accountFibra?.nome_beneficiario | titlecase) || '' }}</span>
          </div>
          <div class="dados_banco-cpf">
            <label>CNPJ/CPF</label>
            <div class="loading" *ngIf="loading">
              
              <div class="d-flex justify-content-center position loading-container">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <span>Buscando CNPJ/CPF...</span>
            </div>
            <span *ngIf="accountFibra">{{
              removeMask(accountFibra?.num_cpfcnpj) || '' | cpfcnpj
              }}</span>
          </div>
        </div>
      </div>
      <div class="box-erro">
        <img *ngIf="errorAccoutInput" src="/assets/png/iconErro.png" alt="icon error"/>
        <label *ngIf="errorAccoutInput">Conta inválida.</label>
      </div>
      <div class="form-check mb-5">
        <input type="checkbox" class="form-check-input" id="exampleCheck1" (click)="setFavorite()" />
        <label class="form-check-label" for="exampleCheck1">Salvar dados da conta</label>
      </div>
    </ng-container>

    <div class="divisor-2"></div>

    <div class="dado-1">
      <div class="titulo">
        <div class="div-redondo-1"><span>2</span></div>
        <span class="titulo-text">Valor e data</span>
      </div>

      <div class="valor_data">
        <fibra-form-control label="Valor a ser transferido">
          <div class="control-data valor_data-valores">
            <input class="form-control" formControlName="ammount" currencyMask
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false}" [(ngModel)]="transferValue"
              (keyup)="changeAmmount()" [ngClass]="{ 'valor-zero': !transferValue }" min="0" maxlength="18" />
          </div>
        </fibra-form-control>

        <fibra-form-control label="Data de transferência">
          <fibra-datepicker placement="top" formControlName="dateTrasfer"
            (dateSelected)="changeDate($event)">
          </fibra-datepicker>
        </fibra-form-control>

        <div class="warning-div-sched" *ngIf="errDates" style="margin-left: 8px;">
          <img class="info-img" src="/assets/svg/icon-info-red.svg" style="margin-right: 8px;" alt="icon info red">
        </div>
        <span *ngIf="errDates" class="warning-font">{{ errDateType }}</span>
      
      </div>
    </div>


    <div class="dado-1 marginTop30  ">
      <input type="checkbox" class="checkbox" name="checkbox" formControlName="checkRepeat" (click)="repeatTransaction()" />
      <span class="check-font"> Repetir transferência</span>
      <div *ngIf="transferData.value.checkRepeat"  class="row marginTop20">
        <div class="col-2 maxWidth11">
          <input
            class="pix-radio"
            checked
            type="radio"
            name="pix-radio"
            value="mensal"
            (click)="radioWeekOrMonth('mensal')"
          />
          <label class="payment-txt-small"> Mensal</label>
        </div>

        <div class="col-3">
          <input
            class="pix-radio"
            type="radio"
            name="pix-radio"
            value="semanal"
            (click)="radioWeekOrMonth('semanal')"
          />
          <label class="payment-txt-small"> Semanal<br/>
          </label>
        </div>
    </div>
    </div>

    <div *ngIf="radioWeek && transferData.value.checkRepeat" class="dado-1 displayFlex marginTop20">
          <div class="col-2 margin-radio">
            <input
            class="pix-radio"
            type="radio"
            checked
            name="semana-radio"
            (click)="radioWeekSelected('segunda')"
          />
            <span class="payment-txt-small"> Segunda-feira</span>  
          </div>

          <div class="col-2 margin-radio">
            <input
            class="pix-radio"
            type="radio"
            name="semana-radio"
            (click)="radioWeekSelected('terca')"
          />
            <span class="payment-txt-small"> Terça-feira</span>
          </div> 

          <div class="col-2 margin-radio">
            <input
            class="pix-radio"
            type="radio"
            name="semana-radio"
            (click)="radioWeekSelected('quarta')"
          />
            <span class="payment-txt-small"> Quarta-feira</span>
          </div> 

          <div class="col-2 margin-radio">
            <input
            class="pix-radio"
            type="radio"
            name="semana-radio"
            (click)="radioWeekSelected('quinta')"
          />
            <span class="payment-txt-small"> Quinta-feira</span>
          </div> 

          <div class="col-2 margin-radio">
            <input
            class="pix-radio"
            type="radio"
            name="semana-radio"
            (click)="radioWeekSelected('sexta')"
          />
            <span class="payment-txt-small"> Sexta-feira</span>
          </div> 

          <div class="col-2 margin-radio">
            <input
            class="pix-radio"
            type="radio"
            name="semana-radio"
            (click)="radioWeekSelected('sabado')"
          />
            <span class="payment-txt-small"> Sábado</span>
          </div> 

          <div class="col-2 margin-radio">
            <input
            class="pix-radio"
            type="radio"
            name="semana-radio"
            (click)="radioWeekSelected('domingo')"
          />
            <span class="payment-txt-small">Domingo</span>
          </div> 
    </div>

    <div *ngIf="transferData.value.checkRepeat && showCheckRepeatTransaction" class="dado-1 displayFlex marginTop30">
      <span class="titulo-text marginRight8" style="line-height: 35px;"> Repetir até</span>

      <fibra-datepicker [maxDate]="maxDate" [date]="startDate"  [minDate]="minDate" placement="top" (dateSelected)="changeDateRepetir($event)">
      </fibra-datepicker>

      <div *ngIf="errDatesRepeat" class="warning-div-sched" style="margin-left: 8px;">
        <img class="info-img" src="/assets/svg/icon-info-red.svg" style="margin-right: 8px;" alt="icon info red">
      </div>
      <span *ngIf="errDatesRepeat" class="warning-font">{{ errDateTypeRepeat }}</span>
    </div>
    <div *ngIf="dateRepeat && !errDatesRepeat" class="row dado-1 marginTop20" style="width: 700px;">
      <div class="col-12">
           <div *ngIf="resultWeekOrMonth === 'semanal'" class="rest-cred">A transferência será realizada {{resultDayofWeek === 'sabado' || resultDayofWeek === 'domingo' ? 'todo ' : 'toda '}} {{ stringDay }} a partir do dia {{ date | date :'shortDate' }}
               até o dia {{ labelDateRepeat }}, mediante a disponibilidade de saldo na conta.
           </div>
           <div *ngIf="resultWeekOrMonth === 'mensal'" class="rest-cred"> A transferência será realizada a cada 30 dias a partir do dia {{ date | date :'shortDate' }}
               até o dia {{ labelDateRepeat }}, mediante a disponibilidade de saldo na conta.
           </div>
      </div>
   </div>

    <div class="divisor-2"></div>

    <div class="dado-1">
      <div class="titulo">
        <div class="div-redondo-1"><span>3 </span></div>
        <span class="titulo-text">Descrição</span>
      </div>

      <div class="finalidade_descricao">
        <div class="finalidade_descricao-descricao">
          <div class="infos">
            <label>Descrição</label>
            <span class="counter" [ngClass]="{ redCounter: char == 0 }">{{ char }} caracteres</span>
          </div>
          <textarea placeholder="" formControlName="description" [ngClass]="" [(ngModel)]="trasnferDescription" (keyup)="
              changeDescription($event.target.value);
              limitTextarea($event.target.value)
            " [maxLength]="40"></textarea>
        </div>
      </div>
    </div>

    <div class="divisor-2"></div>
    
    <div class="dado-1">
      <div class="titulo">
        <div class="div-redondo-1">
          <span>4</span>
        </div>
        <span class="titulo-text">Conta para débito</span>
      </div>

      <div class="valor_transf">
        <label>Valor a ser transferido</label>
        <span>{{ transferValue | currency: 'BRL' }}</span>
      </div>

      <fibra-slide-account-selector-pix [active]="contaSelecionada" [label]="'Selecione a conta a ser debitada'" [data]="accountDataDest"
        (emitAccount)="setAccountDebit($event)" [desable]="indexAccount" [bank]="this.beneficiaryData.quote.cod_banco"
        [ownership]="this.ownership" [destiny]="beneficiaryData?.quote.num_conta | account"
        [accountOrigin]="accountOrigin" [isBeneficiary]="false" [debitAccount]="debitAccount"
        [isEqualCnpj]="isEqualCnpj" (emitLimite)="erroLimite($event)" (checkQtyAccount)="checkQuantityAccount($event)"></fibra-slide-account-selector-pix>

    </div>
    <div class="text_btn">
      <div class="disclaimer-flex" *ngIf="showInfoDisclaimer">
        <span class="limit-disclaimer" *ngIf="ownership === 'same' && showDisclaimerWhenOneAccountDebit">
            <p class="title_limits">Transação Pix para Mesma Titularidade</p>
            <p *ngIf="showInfoDisclaimer[0].tipoPeriodo === 'DIURNO'">Das {{ showInfoDisclaimer[0].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[0].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[0].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
            <p *ngIf="showInfoDisclaimer[0].tipoPeriodo === 'NOTURNO'">Das {{ showInfoDisclaimer[0].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[0].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[0].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
            <p *ngIf="showInfoDisclaimer[0].tipoPeriodo === 'DIURNO'">Por transação das {{ showInfoDisclaimer[0].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[0].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[0].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
            <p *ngIf="showInfoDisclaimer[0].tipoPeriodo === 'NOTURNO'">Por transação das {{ showInfoDisclaimer[0].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[0].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[0].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
        </span>
        <span class="limit-disclaimer" *ngIf="ownership === 'other' && accountCPF == 'CPF' && showDisclaimerWhenOneAccountDebit">
            <p class="title_limits">Transação Pix para Outra Titularidade PF</p>
            <p *ngIf="showInfoDisclaimer[1].tipoPeriodo === 'DIURNO'">Das {{ showInfoDisclaimer[1].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[1].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[1].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
            <p *ngIf="showInfoDisclaimer[1].tipoPeriodo === 'NOTURNO'">Das {{ showInfoDisclaimer[1].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[1].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[1].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
            <p *ngIf="showInfoDisclaimer[1].tipoPeriodo === 'DIURNO'">Por transação das {{ showInfoDisclaimer[1].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[1].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[1].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
            <p *ngIf="showInfoDisclaimer[1].tipoPeriodo === 'NOTURNO'">Por transação das {{ showInfoDisclaimer[1].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[1].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[1].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
        </span>
        <span class="limit-disclaimer" *ngIf="ownership === 'other' && accountCNPJ == 'CNPJ' && showDisclaimerWhenOneAccountDebit">
            <p class="title_limits">Transação Pix para Outra Titularidade PJ</p>
            <p *ngIf="showInfoDisclaimer[2].tipoPeriodo === 'DIURNO'">Das {{ showInfoDisclaimer[2].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[2].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[2].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
            <p *ngIf="showInfoDisclaimer[2].tipoPeriodo === 'NOTURNO'">Das {{ showInfoDisclaimer[2].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[2].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[2].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
            <p *ngIf="showInfoDisclaimer[2].tipoPeriodo === 'DIURNO'">Por transação das {{ showInfoDisclaimer[2].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[2].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[2].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
            <p *ngIf="showInfoDisclaimer[2].tipoPeriodo === 'NOTURNO'">Por transação das {{ showInfoDisclaimer[2].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[2].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[2].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
        </span>
      </div>
      <button type="button" class="btn btn-primary btn-continuar" (click)="goNext()" [disabled]="validationInfo()">
        Continuar
      </button>
    </div>
  </div>
  <fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
</div>

<fibra-modal-cheque-empresa-pix *ngIf="chequeEmpresaPix" (closeEvent)="closeModalPix($event)" [valor]="valorChequeEmpresa" [conta]="this.contaToDebit?.exibicao"></fibra-modal-cheque-empresa-pix>
