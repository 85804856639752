<div class="comprovante" id="comprovante" *ngIf="readyToLoad">
    <div class="close-div" id="fechar">
        <img src="/assets/svg/icon-fechar-black.svg" alt="fechar" id="ico-fechar" (click)="goBack()" style="display:none">
    </div>
    <div class="header">
        <div class="header-content">
            <img src="/assets/svg/logo_fibra.svg" alt="logo" style="width: 165px;height: 49px;">
            <span class="header-text"> Internet Banking - Banco Fibra - Empresa: {{company[0].name}} - CNPJ {{company[0].modality_account}} - {{day}} {{hours}}</span>
        </div>
        <span class="title-report">Investimentos</span>
    </div>

    <!-- TíTulo Investimentos Table -->
    <span class="report-title" style="margin-left: 40px;margin-top: 25px;" *ngIf="informations.name=='investiments-posicao'">{{informations.title}}</span>

    <!-- SubTítulo Investimentos Table -->
    <div class="div" style="margin-bottom: 15px;margin-left: 40px; margin-top:12px" *ngIf="informations.name=='investiments-posicao'">
        <span class="report-subtitle" style="color:#8F969D"> Data da posição {{ informations.dt_ref | date: 'dd/MM/yyyy' }} </span> <span class="report-subtitle-value"> </span>
    </div>

    <!-- SubTítulo Investimentos -->
    <div class="div" style="margin-bottom: 5px;margin-left: 80px; margin-top:18px" *ngIf="informations.name=='investiments-extract'">
        <span class="report-subtitle" style="color:#8F969D"> Data emissão{{ informations.dt_ref | date: 'dd/MM/yyyy' }} </span> <span class="report-subtitle-value"> </span>
    </div>


    <!-- Investimentos Extratos -->
    <div class="div" *ngIf="informations.name=='investiments-extract'">
        <div class="lado-a-lado">
            <div class="datatable">
                <div class="table-title">
                    <div class="table-item">
                        <span class="item-font">Produto</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">Data de emissão</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">Data de Vencimento</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">Data de movimentação</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">Históricos</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font">Valor Aplicado</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font">Número Conta</span>
                    </div>

                </div>
                <div class="table-body">
                    <div class="table-content" *ngFor="let item of investimentsExtrato; let i = index">
                        <div class="table-row">
                            <div class="table-item">
                                <span class="item-font">{{item.produto }}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font">{{item.dat_ope | date: 'dd/MM/yyyy' }}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font">{{ item.dat_vencto | date: 'dd/MM/yyyy' }}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font">{{ item.dat_mov | date: 'dd/MM/yyyy' }}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font">{{ item.historico }}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font">R$ {{ item.val_aplic | number: '1.2-2' }}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font">{{ item.numero_nota  }}</span>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- Investimentos - Posição Consolidada -->
    <div *ngIf="informations.name=='investiments-posicao'">
        <div id="daily-position" style="margin-right: 40px;margin-left: 40px;" *ngFor="let item of investimentsPosicao.data;let i= index">
            <div class="card text-left">

                <div class="card-body">

                    <div class="date">
                        <span class="date-font"> Produto: </span>
                        <span class="date-font"> {{item.cod_prod}}</span>
                    </div>
                    <div class="table-row">
                        <div class="table-item">
                            <span class="item-font-gray">Data de emissão</span>
                            <span class="item-font">{{ item.dat_emissao | date : 'dd/MM/yyyy' }}</span>
                        </div>

                        <div class="table-item">
                            <span class="item-font-gray">Data de vencimento</span>
                            <span class="item-font">{{ item.dat_vencto | date : 'dd/MM/yyyy' }}</span>
                        </div>


                        <div class="table-item">
                            <span class="item-font-gray">Valor aplicado</span>
                            <span class="item-font">{{ item.val_aplic | currency: 'BRL' }}</span>
                        </div>



                        <div class="table-item">
                            <span class="item-font-gray">Rendimento bruto</span>
                            <span class="item-font">{{ item.rend_bruto | currency: 'BRL' }}</span>
                        </div>

                        <div class="table-item">
                            <span class="item-font-gray">Valor líquido</span>
                            <span class="item-font">{{ item.val_liquido | currency: 'BRL' }}</span>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-muted">
                    <div style="padding:20px;background-color: #F8F9FA;">

                        <span style="color:#082A4D;font-size:18px;font-weight: 500;">Características da operação</span>
                        <div class="table-row">
                            <div class="table-item">
                                <span class="item-font-gray">Produto</span>
                                <span class="item-font">{{ item.cod_prod  }}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font-gray">Emissor</span>
                                <span class="item-font"> {{ item.emissor }}  </span>
                            </div>

                            <div class="table-item">
                                <span class="item-font-gray">Quantidade</span>
                                <span class="item-font">{{ item.quantidade }}</span>
                            </div>

                            <div class="table-item">
                                <span class="item-font-gray">Data de emissão</span>
                                <span class="item-font">{{item.dat_emissao | date : 'dd/MM/yyyy'  }}</span>
                            </div>
                        </div>

                        <div class="table-row">
                            <div class="table-item">
                                <span class="item-font-gray">Data de vencimento</span>
                                <span class="item-font">{{ item.dat_vencto | date : 'dd/MM/yyyy' }}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font-gray">Taxa de operação</span>
                                <span class="item-font">{{ item.tax_oper | number: '1.2-2' }}</span>
                            </div>

                            <div class="table-item">
                                <span class="item-font-gray">Percentual</span>
                                <span class="item-font"> {{ item.porc_indx_oper }}%</span>
                            </div>

                            <div class="table-item">
                                <span class="item-font-gray">Indexdor</span>
                                <span class="item-font"> {{ item.indexador }} </span>
                            </div>

                            <div class="table-item">
                                <span class="item-font-gray">Valor aplicado</span>
                                <span class="item-font">{{ item.val_aplic | number: '1.2-2' }}</span>
                            </div>
                        </div>

                        <div class="table-row">
                            <div class="table-item">
                                <span class="item-font-gray">Rendimento Bruto</span>
                                <span class="item-font">{{ item.rend_bruto | number: '1.2-2' }}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font-gray">IR</span>
                                <span class="item-font">{{ item.impostos  | number: '1.2-2'  }}</span>
                            </div>

                            <div class="table-item">
                                <span class="item-font-gray">IOF</span>
                                <span class="item-font">{{ item.iof  | number: '1.2-2' }}</span>
                            </div>

                            <div class="table-item">
                                <span class="item-font-gray">Valor Bruto</span>
                                <span class="item-font">{{item.val_bruto | number: '1.2-2' }}</span>
                            </div>

                            <div class="table-item">
                                <span class="item-font-gray">Valor líquido</span>
                                <span class="item-font">{{item.val_liquido | number: '1.2-2' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Espaço -->
            <div *ngIf="(i+1) < 3 && (i+1)%2==0">
                <div style="height: 200px; ">
                </div>
            </div>
            <div *ngIf="(i+1) >= 3 && (i+1)%2==0">
                <div style="height: 460px;">
                </div>
            </div>
        </div>



        <div class="card text-left" style="margin-right: 40px;margin-left: 40px; border-radius: 0 0 8px 8px;">
            <div class="card-body" style="background-color: #4D86BF; color: white; padding: 0;">
                <div class="table-row" style="color: white;">
                    <div class="table-item">
                        <span class="date-font" style="color: white; margin-left: 20px;"> Valor total </span>
                    </div>
                    <div class="table-item">
                        <span class="item-font-white">Valor Aplicado</span>
                        <span class="item-font-white-values">{{ investimentsPosicao.valorAplicado | currency: 'BRL'}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font-white">Rendimento Bruto</span>
                        <span class="item-font-white-values">{{ investimentsPosicao.rendBruto | currency: 'BRL'}}</span>
                    </div>

                    <div class="table-item">
                        <span class="item-font-white">Valor Líquido</span>
                        <span class="item-font-white-values">{{ investimentsPosicao.valorLiq | currency: 'BRL'}}</span>
                    </div>


                </div>

            </div>
        </div>
        <div class="footer">
            <p>*Alíquota de IR</p>
            <div class="info col-12">
                <div class="col-3">
                    de 1 a 180 dias <span>22,5%</span>
                </div>
                <div class="col-3">
                    de 181 a 360 dias <span>20%</span>
                </div>
                <div class="col-3">
                    de 361 a 720 dias <span>17,5%</span>
                </div>
                <div class="col-3">
                    Acima de 720 <span>15%</span>
                </div>
            </div>
        </div>
        <p class="footer-explain">**Incidência sobre aplicações com prazo inferior à 30 dias. IOF regressivo conforme portaria n° 264 da Receita Federal.</p>
    </div>

    <div class="card-content">
        <div class="card">
            <div class="html2pdf__page-break"></div>
            <br><br>
            <div class="card-body" #atendimento>
                <img class="icon" src="/assets/png/apoio 1.png" alt="Atendimento" />
                <div class="text">
                    <div class="title">Canais exclusivos</div>
                    <div class="desc">Dúvidas e apoio</div>
                </div>
            </div>
            <div class="card-bottom">
                <p>
                    <b>Corporate, Agro e PMEs</b>
                    (11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
                </p>
                <p>
                    <a href="mailto:relacionamento@bancofibra.com.br">relacionamento@bancofibra.com.br</a>
                </p>
            </div>
        </div>
        <div class="card">
            <div class="card-body" #sac>
                <img class="icon" src="/assets/png/telefone 1.png" alt="Atendimento" />
                <div class="text">
                    <div class="title">SAC</div>
                    <div class="desc">Sugestões, reclamações e elogios</div>
                </div>
            </div>
            <div class="card-bottom">
                <p>
                    <b>Canal de Atendimento ao Cliente</b>
                </p>
                <p>
                    0800 727 0132
                </p>
                <p>
                    <b>Canal exclusivo para deficientes auditivos ou de fala</b>
                </p>
                <p>
                    0800 200 6921
                </p>
            </div>
        </div>
        <div class="card">
            <div class="card-body" #ouvidoria>
                <img class="icon" src="/assets/png/alto-falante.png" alt="Atendimento" />
                <div class="text">
                    <div class="title">Ouvidoria</div>
                    <div class="desc">Demandas não solucionadas</div>
                </div>
            </div>
            <div class="card-bottom">
                <p>
                    0800 727 0132, das 9h às 18h, de segunda a sexta-feira, exceto feriados.<br><br>
                </p>
            </div>
        </div>
    </div>

</div>