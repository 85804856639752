<fibra-toast-error-message [color]="typToastColor"> </fibra-toast-error-message>
<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>
<div class="content-page" 
  *fibraHasRole="'COB.TRANS_ARQ, COB.CONS_ARQ_REM, COB.CONS_ARQ_RET, QUALQUER_CONTA.COB.TRANS_ARQ, QUALQUER_CONTA.COB.CONS_ARQ_REM, QUALQUER_CONTA.COB.CONS_ARQ_RET'"
>
  <ng-container>
    <fibra-breadcrumb bottom="-90" infoAccount="Cobrança / Arquivo de cobrança" roule="Arquivo de cobrança" arrow="false"></fibra-breadcrumb>

    <div class="subtitle">
      <h3>Histórico dos arquivos</h3>
    </div>

    <div class="archive-history">
      <div *fibraHasRole="'COB.CONS_ARQ_REM, COB.CONS_ARQ_RET, QUALQUER_CONTA.COB.CONS_ARQ_REM, QUALQUER_CONTA.COB.CONS_ARQ_RET'" class="archive-history-content">
        <div class="filter">
          <fibra-filter-cobranca
            [default]="true"
            (emitFilter)="setFilter($event)"  
            [abaTransacionais]="true"
            (emitFilterToast)="verifyCalendar($event)"
            [abaSelected]="'archive-list'"   
            [isNotCessao]="true"
          ></fibra-filter-cobranca>
        </div>
        <div class="archive-list">
          <fibra-list-archive></fibra-list-archive>
        </div>
      </div>

      <aside class="archive-history-sidebar">
        <div *fibraHasRole="'COB.TRANS_ARQ, QUALQUER_CONTA.COB.TRANS_ARQ'" class="sidebar-inner">
          <fibra-drop-archive (emitFilterToast)="validaArquivo($event)"></fibra-drop-archive>
        </div>
      </aside>
    </div>
  </ng-container>
</div>
