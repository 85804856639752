<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>

<div class="content-page">
  <div class="body-content">
    <div id="transfer-confirm">
      <div id="transfer-confirm-content">
        <h1 class="first-title ">Valor recebido</h1>

        <div class="margin-16"></div>

        <p class="valDataPagador">{{refundData.showInScreen.pagador.dataRefund[0].valorTransferencia | currency: 'BRL'}}</p>

        <h1>Data e hora</h1>

        <div class="margin-16"></div>
        
        <p class="valDataPagador">{{refundData.showInScreen.data | date: 'dd/MM/yyyy'}}</p>

        <div class="margin-16"></div>

        <p class="valDataPagador">{{refundData.showInScreen.data | date: 'H:mm:ss'}}</p>

        <h1>Dados do pagador</h1>

        <p class="title">Nome</p>
        <p>{{refundData.showInScreen.pagador.dataRefund[0].nomePagador}}</p>
        
        <div class="margin-16"></div>

        <p class="title">CNPJ / CPF</p>
        <p>{{htmlShowCpf(refundData.showInScreen.pagador.dataRefund[0].cpfcnpjPagador)}}</p>

        <div class="margin-16">

        <p class="title">Instituição</p>
        <p>{{ refundData.showInScreen.pagador.dataRefund[0].bancoPagador }}</p>

        <div class="margin-16">
        
        <p class="title">Dados da conta</p>
        <p>AG: {{ returnAgencyFormat(refundData.showInScreen.pagador.dataRefund[0].numeroAgenciaPagador)}} |
           CC: {{ returAccountFormat(refundData.showInScreen.pagador.dataRefund[0].numeroContaPagador)  | account}}</p>

        <div class="margin-16">
        
        <!--p class="title">Chave</p>
        <p>sem informação</p-->

        <h1>Conta a ser debitada</h1>

        <div class="margin-16"></div>
        
        <p class="title">Conta</p>
        <p>{{refundData.conta_debito.desc_modalidade === 'FIBRA FACIL' ? 'FIBRA FACIL' : refundData.showInScreen.pagador.accountRefund[0].desc_conta}}</p>

        <div class="margin-16"></div>
        
        <p class="title">Dados da conta</p>
        <p>AG: {{refundData.showInScreen.pagador.accountRefund[0].cod_agencia || '0001'}} | CC: {{refundData.showInScreen.pagador.accountRefund[0].num_conta | account}} </p>

        <h1>Dados da devolução</h1>

        <div class="margin16"></div>

        <p class="title">Valor a ser devolvido</p>
        <p class="valDataPagador">{{refundData.showInScreen.valor | currency: 'BRL'}} </p>

        <div class="desc">
          <p class="title" *ngIf="refundData.showInScreen.descricao">Motivo da devolução</p>
          <p>
            {{refundData.showInScreen.descricao}}
          </p>
        </div>
      <div class="transfer-confirm-footer">
        <button
          type="button"
          class="leaked-button preview"
          (click)="toast.callModalMessage('retorno')"
        >
          Voltar
        </button>

        <button type="button" class="secondary-button next" (click)="goNext()">
          {{ textBtn }}
        </button>
      </div>
    </div>
  </div>
  <fibra-toast-error-message #toast [color]="'#ED6C6C'" (optionYes)="navigateBack()"></fibra-toast-error-message>
  <fibra-modal-validation
    *ngIf="showModalValidation"
    [apiCallback]="apiCallBack"
    (closeEvent)="closeModal()"
    [requestData]="requestDataApi"
    [apiPayload]="securityRequest()"
    (responseValidation)="securityResponse($event)">
  </fibra-modal-validation>
</div>

<ng-content></ng-content>
