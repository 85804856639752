import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import { ShowValuesDashboardDataShare } from '@src/core/usecases/data-share/show-values-dashboard-data-share.service';
import { SelectorDataShare } from '@src/core/usecases/data-share/selector-data-share.service';
import { OpenBankingPendency, PendencyModel, WarrantyModel } from '@src/core/domain/dashboard/dashboard.model';
import { GetAccompanimentUsecases } from '@src/core/usecases/dashboard/get-accompaniment.usecase';
import { GetPredictedTotalizadoresUsecase } from '@src/core/usecases/predicted/get-predicted-movements-totalizadores.usecase';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GetWarrantyUsecases } from '@src/core/usecases/dashboard/get-warranty.usecase';
import { GetPendencyUsecases } from '@src/core/usecases/dashboard/get-pendency.usecase';
import { InvestimentsDataRepository } from '@src/data/repository/investiments/investiments.repository';
import { BalancesEnum, IChequeEmpresa } from '@src/core/domain/balance/balances.model';
import * as Util from '@src/shared/util-common';
import { isArray } from 'util';
import { ProfileAccessProvider } from '@src/data/repository/perfilamento/profile.repository';
import { DerivativeDataRepository } from '@src/data/repository/derivative-repository/derivative.repository';
import { LoansRepository } from '@src/core/repositories/loans/loans-repository';
import { RolesService } from '@src/shared/services/roles.service';
import { PendencyDataRepository } from '@src/data/repository/dashboard/pendency/pendency.repository';
import { GetOpenBankingPendenciesUsecase } from '@src/core/usecases/dashboard/get-openbanking-pendencies.usecase';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';
import { BalancesService } from '@src/data/repository/balances-repository/balances.repository';
import { OpenBankingRepository } from '@src/data/repository/openbanking/openbanking.repository';
import { AccountsEntity } from '@src/data/repository/pix/accounts/pix-accounts-entity';
import { toTitleCase } from 'codelyzer/util/utils';
import { GetTemFibraFacilUseCase } from '@src/core/usecases/dashboard/get-temfibrafacil.usecase';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'fibra-page-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public token = undefined;
  public dataAccompaniment;
  public dataWarranty;
  public dataPendency;
  public dataOpenBankingPendencies: OpenBankingPendency[];
  public showData: boolean;
  public hidePendency = true;
  public hideOpenBankingPendencies = true;
  public dataAccount;
  public balance: BalancesEnum;
  public investimentsBalance: number;
  public derivativePendency = false;
  public opCreditoList;
  public hasNotificationPix = false;
  public showButton: boolean = false
  public totalPendencias = 0
  public chequeEmpresa: IChequeEmpresa = {} as IChequeEmpresa;
  public chequeEmpresaList: AccountsEntity[] = [] as AccountsEntity[];
  public chequeEmpresaListCount: number = 0;
  public toTitleCase = toTitleCase;
  public temFibraFacil: boolean = false;
  public loadingBannerInfo: boolean = true;

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  constructor(
    private DerivativeService: DerivativeDataRepository,
    private shareSelector: SelectorDataShare,
    private getAcompaniment: GetAccompanimentUsecases,
    private getPredictedTotalizadoresUsecase: GetPredictedTotalizadoresUsecase,
    private balancesService: BalancesService,
    private getWarranty: GetWarrantyUsecases,
    private getPendency: GetPendencyUsecases,
    private getOpenBankingPendencies: GetOpenBankingPendenciesUsecase,
    private shareShowValues: ShowValuesDashboardDataShare,
    private shareCompany: CompanyDataShare,
    private accessProvider: ProfileAccessProvider,
    private InvestimentsService: InvestimentsDataRepository,
    private loansRepository: LoansRepository,
    private rolesService: RolesService,
    private pendencyServices: PendencyDataRepository,
    private openBankingRepository: OpenBankingRepository,
    private getTemFibraFacil: GetTemFibraFacilUseCase
  ) { }

  ngOnInit() {

    this.openBankingRepository.getAccounts().subscribe((list: AccountsEntity[]) => {
      this.chequeEmpresaList = list.map((item) => {
        if (item.desc_conta === 'CHEQUE EMPRESA') {
          const saldo = item.saldo as any;
          const vlr_limite = saldo.vlr_limite || 0;
          this.chequeEmpresaListCount = this.chequeEmpresaListCount + 1;

          item.vlr_dashboard_cheque_empresa = saldo.vlr_cheque_disponivel || 0;

          if (vlr_limite == 0) {
            item.vlr_dashboard_cheque_empresa = 0;
          }
        }
        return item;
      });
    })

    this.init();
    this.getHasNotificationPix();
  }

  private init = () => {

    // get Roles
    let roles = this.rolesService.get();

    roles.forEach(role => {

      if (role == "OP_CRED.CONS_OP_CRED") {
        this.getAllContractsOp();
      }

      if (role == "DER.CONS_DER") {
        this.getDerivative(new Date());
      }

      if (role == "INVEST.CONS_INV") {
        this.getInvestimentsBalance();
      }

    });


    //////////
    this.shareSelector.setValue('dashboard');
    this.requestAccompaniment();
    // this.requestWarranty();
    this.requestPendency();
    this.requestOpenBankingPendencies();
    this.showValuesShare();
    this.requestBalances();
    this.verificaSeTemFibraFacil();

    this.shareCompany.dataShared.subscribe((res) => {
      if (res !== undefined && isArray(res.companyList)) {
        this.dataAccount = res.companyList.filter((e) => e.selected);
      }
    });

    //

    var erroDashboard = sessionStorage.getItem('erroDashboard');

    if (erroDashboard) {
      this.toast.callModalMessage(null, erroDashboard);
      sessionStorage.removeItem('erroDashboard');
    }
  }

  getHasNotificationPix() {
    this.pendencyServices.getPendencyPix().subscribe(data => {
      this.hasNotificationPix = true;
      this.totalPendencias = this.totalPendencias + 1
      this.carregaBotao();
    }, error => {
      this.hasNotificationPix = false
    })
  }
  getInvestimentsBalance() {
    var dt = new Date();
    this.InvestimentsService.getInvestiments(dt, null, true).subscribe((data) => {
      this.investimentsBalance = data.data['tot_liquido']
    }, () => {
      this.investimentsBalance = 0;
    });
  }

  showValuesShare() {
    this.shareShowValues.dataShared.subscribe((res) => {
      this.showData = res && res.iconEyesOpen;
    });
  }

  public isHideAcompaniment = () => {
    return !this.dataAccompaniment || !this.dataWarranty;
  }

  requestBalances = () => {
    this.balancesService.getChequeEmpresa().subscribe(({ data }: any) => {
      if (data && data.vlr_limite) {
        this.chequeEmpresa = data;
      }
    });
  }

  // tslint:disable-next-line:align
  public requestAccompaniment = () => {
    this.getPredictedTotalizadoresUsecase
      .execute()
      .subscribe(this.requestSuccess, this.requestError);
  }

  private requestSuccess = (value) => {
    if (value.status === 'success') {
      this.dataAccompaniment = value.data;
    }
  }

  private requestError = (err) => {
    return console.log(err);
  }

  private requestWarranty = () => {
    this.getWarranty
      .execute()
      .subscribe(this.requestSuccessWarranty, this.requestError);
  }

  private requestSuccessWarranty = (value: WarrantyModel) => {
    if (value.status === 'success') {
      this.dataWarranty = value.data;
    }
  }

  private requestPendency = () => {
    this.getPendency
      .execute()
      .subscribe(
        this.requestSuccessPendency,
        this.requestError,
        this.requestPendencyComplete
      );
  }

  private requestOpenBankingPendencies = () => {
    this.getOpenBankingPendencies
      .execute()
      .subscribe(
        this.requestSuccessOpenBankingPendencies,
        this.requestError,
        this.requestOpenBankingPendenciesComplete
      );
  }

  private requestSuccessPendency = (value: PendencyModel) => {
    if (value.status === 'success') {
      this.dataPendency = Util.verifyArray(value.data);
      this.hidePendency = Util.isEmpty(this.dataPendency);
      var dataPendencyQtd = this.dataPendency ? this.dataPendency.length : 0
      this.totalPendencias = this.totalPendencias + dataPendencyQtd;
      this.carregaBotao();
    }
  }

  private requestSuccessOpenBankingPendencies = (value: OpenBankingPendency[]) => {
    console.log(value);
    this.dataOpenBankingPendencies = Util.verifyArray(value);
    this.hideOpenBankingPendencies = Util.isEmpty(this.dataOpenBankingPendencies);
    var dataPendenciesOpenBankingQtd = this.dataOpenBankingPendencies ? this.dataOpenBankingPendencies.length : 0
    this.totalPendencias = this.totalPendencias + dataPendenciesOpenBankingQtd
    this.carregaBotao();
  }

  private requestPendencyComplete = () => {
    this.hidePendency = Util.isEmpty(this.dataPendency);
  }

  private requestOpenBankingPendenciesComplete = () => {
    this.hideOpenBankingPendencies = Util.isEmpty(this.dataOpenBankingPendencies);
  }

  getDerivative(dt: Date) {
    this.DerivativeService.getDerivative(dt).subscribe((data) => {
      if (data.data && data.data.length > 0) {
        this.derivativePendency = true;
        this.totalPendencias = this.totalPendencias + 1
      } else {
        this.derivativePendency = false
      }
      this.carregaBotao();
    }, () => {
      this.derivativePendency = false;
    });
  }

  public parcelaVencendo = false;
  public parcelaVencida = false;
  public parcelaAvencer = false;
  getAllContractsOp() {

    const params = {}

    this.loansRepository.getAllLoans(params).subscribe((contratos) => {
      this.opCreditoList = contratos.data

      this.opCreditoList.forEach(element => {
        if (element.qt_parcela_vencida > 0) {
          this.parcelaVencida = true
          this.totalPendencias = this.totalPendencias + 1
        }
        else if (element.qt_parcela_vencendo > 0) {
          this.parcelaVencendo = true
          this.totalPendencias = this.totalPendencias + 1
        }
        else if (element.qt_parcela_a_vencer > 0) {
          this.parcelaAvencer = true
          this.totalPendencias = this.totalPendencias + 1
        }
      });
      this.carregaBotao();
    }, (err) => {
    })
  }
  scrollBy(side) {
    if (side === 'right') {
      document.querySelector('#cardpendency').scrollBy({
        left: 410,
        behavior: 'smooth',
      });
    }
    if (side === 'left') {
      document.querySelector('#cardpendency').scrollBy({
        left: -410,
        behavior: 'smooth',
      });
    }
  }

  carregaBotao() {
    if (window.innerWidth <= 890) {
      this.showButton = this.totalPendencias > 1 ? true : false;
    } else if (window.innerWidth > 890 && window.innerWidth < 1366) {
      this.showButton = this.totalPendencias > 2 ? true : false;
    } else if (window.innerWidth >= 1366 && window.innerWidth <= 1850) {
      this.showButton = this.totalPendencias > 3 ? true : false;
    } else if (window.innerWidth > 1850 && window.innerWidth < 2300) {
      this.showButton = this.totalPendencias > 4 ? true : false;
    } else {
      this.showButton = this.totalPendencias > 5 ? true : false;
    }
  }

  private verificaSeTemFibraFacil() {
    this.getTemFibraFacil.execute().pipe(finalize(() => this.loadingBannerInfo = false)).subscribe(result => this.temFibraFacil = result);
  }
}
