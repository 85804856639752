<div class="list-value">
  <table class="table">
    <thead class="table-header">
      <tr>
        <th scope="col" (click)="ordered('date')" *ngFor="let titleheader of option">
          {{ titleheader.option }}
          <!-- <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.11394 0L8.22788 5.02282H0L4.11394 0Z" fill="#082A4D" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.11394 13.0596L8.22788 8.03675H0L4.11394 13.0596Z" fill="#082A4D" />
            </g>
          </svg> -->
        </th>
      </tr>
    </thead>
    <div *ngIf="transit && !loading">

      <tbody class="p-15">
        <tr *ngFor="let itemFrancesinha of transit">
          <td>
            <ngb-highlight [result]="itemFrancesinha.seu_numero" [term]="filter.value"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="itemFrancesinha.nosso_numero" [term]="filter.value"></ngb-highlight>
          </td>
          <td data-hj-suppress>
            <ngb-highlight [result]="itemFrancesinha.nome_pagador" [term]="filter.value"></ngb-highlight>
          </td>
          <td scope="row">
            {{ itemFrancesinha.dt_vencimento | date: 'dd/MM/yyyy' }}
          </td>
          <td scope="row">
            {{ itemFrancesinha.dt_referencia | date: 'dd/MM/yyyy' }}
          </td>
          <td scope="row">
            {{ itemFrancesinha.dt_lancamento | date: 'dd/MM/yyyy' }}
          </td>
          <td>
            <ngb-highlight [result]="itemFrancesinha.vlr_titulo | currency: 'BRL'" [term]="filter.value"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="itemFrancesinha.historico" [term]="filter.value"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="itemFrancesinha.vlr_outros | currency: 'BRL'" [term]="filter.value"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="itemFrancesinha.vlr_liquido | currency: 'BRL'" [term]="filter.value"></ngb-highlight>
          </td>
        </tr>
      </tbody>
    </div>
  </table>
  <div class="d-flex justify-content-center position" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="empty-info" *ngIf="show && !loading">
    <fibra-generic-info type="warn" title="Nenhum resultado encontrado" message="Tente uma nova pesquisa inserindo novos parâmetros.">
    </fibra-generic-info>
  </div>
</div>
<div class="info-value" *ngIf="!loading">
  <div class="group-value">
    <div class="text">Valores Totais</div>
    <div class="divisor"></div>
    <div class="box-value">
      <div class="text-value">Saldo anterior</div>
      <div class="value">
        <div *ngIf="resume;then c_vlr_saldo_anterior else other_vlr_saldo_anterior"></div>
        <ng-template #c_vlr_saldo_anterior>{{resume.vlr_saldo_anterior | currency: 'BRL'}}</ng-template>
        <ng-template #other_vlr_saldo_anterior>{{0 | currency: 'BRL'}}</ng-template>
      </div>
    </div>
  </div>
  <div class="box-value">
    <div class="text-value">Valor entrada</div>
    <div class="value">
      <div *ngIf="resume;then c_vlr_entradas else other_vlr_entradas"></div>
      <ng-template #c_vlr_entradas>{{resume.vlr_entradas | currency: 'BRL'}}</ng-template>
      <ng-template #other_vlr_entradas>{{0 | currency: 'BRL'}}</ng-template>
    </div>
    </div>
  <div class="box-value">
    <div class="text-value">Valor baixas</div>
    <div class="value">
      <div *ngIf="resume;then c_vlr_baixas else other_vlr_baixas"></div>
      <ng-template #c_vlr_baixas>{{resume.vlr_baixas | currency: 'BRL'}}</ng-template>
      <ng-template #other_vlr_baixas>{{0 | currency: 'BRL'}}</ng-template>
    </div>
  </div>
  <div class="box-value">
    <div class="text-value">Saldo atual</div>
    <div class="value">
      <div *ngIf="resume;then c_vlr_saldo_atual else other_vlr_saldo_atual"></div>
      <ng-template #c_vlr_saldo_atual>{{resume.vlr_saldo_atual | currency: 'BRL'}}</ng-template>
      <ng-template #other_vlr_saldo_atual>{{0 | currency: 'BRL'}}</ng-template>
    </div>
  </div>
  <div class="box-value">
    <div class="text-value">Quantidade títulos</div>
    <div class="value">
      <div *ngIf="resume;then c_qtd_titulos else other_qtd_titulos"></div>
      <ng-template #c_qtd_titulos>{{resume.qtd_titulos}}</ng-template>
      <ng-template #other_qtd_titulos>0</ng-template>
    </div>
  </div>
</div>

<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>