import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment-timezone';
moment.tz.setDefault('America/Sao_Paulo');
import { environment as env } from '@src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {
  public hideMain: boolean;
  public receipt = false;
  public screenSizeOb = false;
  public arrow1: boolean;
  public arrow2: boolean;
  public arrow3: boolean;
  public isMobile: boolean = false;

  constructor(private actRoute: ActivatedRoute) { }

  ngOnInit() {
    this.actRoute.queryParams.subscribe(params => {
      if (window.innerWidth < 1024 && params && params.login_id) {
        this.screenSizeOb = true;
        this.isMobile = false;
      } else if (document.documentElement.clientWidth <= 768) {
        this.isMobile = true;
      } else if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.isMobile = true;
      }

      console.log(env.env)
    });
    this.hideMain = this.isHideMain();
  }

  public isHideMain() {
    const refs: any = window.location.href.split('/');
    const url: string = refs[refs.length - 1];
    let hide: boolean;
    hide =
      url === '' ||
      url.includes('login') ||
      url === 'approval-receipt' ||
      url === 'approval-export' ||
      url === 'no-permission' ||
      url === 'derivative-export';
    window.innerWidth <= 768;
    return hide;
  }

  public redirect() {
    window.location.href = '/';
  }

  public arrowControl(el: HTMLDivElement) {
    const next = el.nextElementSibling;
    const isHidden = next.getAttribute('open');

    if (isHidden === 'true') {
      next.setAttribute('open', 'false');
      next.setAttribute('style', 'display:block');
    }
    if (isHidden === 'false') {
      next.setAttribute('open', 'true');
      next.setAttribute('style', 'display:none');
    }
  }
}
