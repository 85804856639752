<ng-template #content let-modal style="top: 100px;">
  <div class="period-datepicker-container">
    <div class="modal-header">
      <div class="generic-info">
          <div class="input-group">
            <div style="width:30%" class="dropdown-input">
              <span>&nbsp;&nbsp;De</span>
              <input #dpFromDate
                  class="form-control" placeholder="dd/mm/aaaa"
                  name="dpFromDate"
                  [textMask]="addMask(dateMask)"
                  [value]="dataI"
                  (input)="dataChanged($event)"
                  (change)='todate($event.target.value)'
                  (blur)="onBlur($event.target.value)"
                  [style.width.px]="180">
            </div>
            <div style="width:35%"></div>
            <div style="width:30%" class="dropdown-input">
              <span style="text-align: left">&nbsp;&nbsp;Até</span>
              <input #dpToDate
                  class="form-control" placeholder="dd/mm/aaaa"
                  name="dpToDate"
                  [textMask]="addMask(dateMask)"
                  [value]="dataF"
                  (input)="dataChanged($event)"
                  (change)='todate($event.target.value)'
                  (blur)="onBlurDataF($event.target.value)"
                  [style.width.px]="180">
            </div>
          </div>
      </div>
    </div>
    <br>
    <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" 
      [displayMonths]="2" 
      [dayTemplate]="t" 
      outsideDays="hidden" 
      navigation="1" 
      [maxDate]="maxDate"
      [minDate]="minDate"
      style="position: relative; border: 0px;"></ngb-datepicker>
    <ng-template #t let-date let-focused="focused">
        <span *ngIf="abaSelected === undefined" class="custom-day"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        [class.init]="isDateInit(date)"
        [class.end]="isDateEnd(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
        [class.past-date]="isPastDate(date)"
      >   
       {{ date.day }}
    </span>
      <span *ngIf="abaSelected !== undefined"  class="custom-day"
      [class.range]="isRange(date)"
      [class.faded]="isHovered(date) || isInside(date)"
      [class.init]="isDateInit(date)"
      [class.end]="isDateEnd(date)"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
      [class.past-date]="isPastDatePosicaoCarteira(date)"
            >
        {{ date.day }}
      </span>
    </ng-template>
    <div class="period-center">
        <button
          type="button"
          class="button-cancel"
          (click)="closeModal('limpar')">
          Limpar
        </button>&nbsp;
        <button
          type="button"
          class="button-yes"
          (click)="closeModal('buscar')">
          Buscar
        </button>
    </div>
  </div>
</ng-template>
<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
