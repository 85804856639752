import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { flatMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as Mapper from './admin-repository.mapper';
import * as Model from '@src/core/domain/admin-user/admin-user.model';
import { AdminUserRepository } from '@src/core/repositories/admin-user/admin-user.repository';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';
import * as Entity from './admin-user.entity';

@Injectable({
  providedIn: 'root',
})
export class AdminUserDataRepository extends AdminUserRepository {
  private userMapper = new Mapper.AdminUserRepositoryMapper();
  private groupListMapper = new Mapper.AdminGroupRepositoryMapper();
  private userListMapper = new Mapper.AdminUserListRepositoryMapper();
  private userDadosMapper = new Mapper.AdminUserDadosRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  public getUsers(): Observable<Model.AdminUserListModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    return this.http
      .get<Entity.AdminUserListEntity>(
        `${environment.URLBase}v1/matrizacessos/listarpessoas`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.userListMapper.mapFrom));
  }

  public getUserDetails(obj: Model.AdminDetailRequest): Observable<Model.AdminUserListModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = {...obj};

    return this.http
      .post<Entity.AdminUserListEntity>(
        `${environment.URLBase}v1/matrizacessos/acessos/funcionalidades/detalhe`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.userListMapper.mapFrom));
  }

  public setUser(obj: Model.AdminUserRequest): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { ...obj };
    return this.http
        .post<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/ativarcadastroindividual`,
            params,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
  }

  public updateUser(obj: Model.AdminUserRequest): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { ...obj };
    return this.http
        .post<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/pessoas/${obj.id}`,
            params,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
  }

  public deleteUser(id: number): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    return this.http
        .delete<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/pessoas/${id}`,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
  }

  public getDepartments(): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
        .get<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/departamentos`,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
  }

  public getFunctionalities(): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
        .get<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/acessos/funcionalidades`,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
  }

  public getFunctionalitiesAccount(): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
        .get<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/acessos/funcionalidadescomconta?atreladoConta=true`,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
  }

  public getFunctionalitiesNotAccount(): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
        .get<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/acessos/funcionalidadescomconta?atreladoConta=false`,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
  }

  public getUserOffice(): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
        .get<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/cargos`,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
  }

  public setStatusAdmUsers(obj: Model.AdminUsersStatusRequest): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = { ...obj };
    return this.http
        .post<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/pessoasaprovacao`,
            params,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
  }

  public getGroups(): Observable<Model.AdminGroupListModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
        .get<Entity.AdminGroupListEntity>(
            `${environment.URLBase}v1/matrizacessos/listargrupos`,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.groupListMapper.mapFrom));
  }

  public getGroupDetails(obj: Model.AdminDetailRequest): Observable<Model.AdminUserListModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const { cod_pessoa } = obj;

    return this.http
      .get<Entity.AdminUserListEntity>(
        `${environment.URLBase}v1/matrizacessos/grupo/${cod_pessoa}/detalhes`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.userListMapper.mapFrom));
  }

  public setGroups(obj: Model.AdminGroupRequest): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { ...obj };
    return this.http
        .post<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/ativarcadastrogrupo`,
            params,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
  }

  public updateGroups(obj: Model.AdminGroupsUpdateRequest): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { ...obj };
    return this.http
        .put<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/atualizargrupo`,
            params,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
  }

  public setStatusAdmGroups(obj: Model.AdminGroupsStatusRequest): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = { ...obj };
    return this.http
        .post<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/grupoaprovacao`,
            params,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
  }

  public setUserByAdiminGroup(obj: Model.AdiminUserByGroupRequest): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
        .post<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/grupo/${obj.group_id}/adicionar-pessoa/${obj.user_id}`,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
  }

  public getUserByAdiminGroup(id: number): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
        .get<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/grupo/${id}/pessoas`,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
  }

  public deleteGroup(id: number): Observable<Model.AdminUserModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    return this.http
        .delete<Entity.AdminUserEntity>(
            `${environment.URLBase}v1/matrizacessos/deletargrupo/${id}`,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.userMapper.mapFrom));
        
  }

  public postValidaDados(obj: Model.AdminUserValidaDadosRequest): Observable<Model.AdminUserValidaDadosModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = {...obj};

    return this.http
      .post<Entity.AdminUserDadosEntity>(
        `${environment.URLBase}v1/matrizacessos/pessoas/validaDados`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.userDadosMapper.mapFrom));
  }
}
