import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { AccountResponse, AccountWithNoTermResponse, PixAccountResponse } from './pix-accounts-entity';
import { getHeader } from '@src/core/base/header';

@Injectable({
  providedIn: 'root',
})
export class PixGetAccountsDataRepository {

  constructor(private http: HttpClient) {}

  public getAccounts() :Observable<PixAccountResponse> {

    const httpOptions = {
        headers: getHeader(),
      };
   
      return this.http.get<PixAccountResponse>(`${environment.URLBase}v1/pix/chave/contas`, httpOptions);
  }

  public getAccountsWithSaldo() :Observable<AccountResponse> {

    const httpOptions = {
        headers: getHeader(),
      };
   
      return this.http.get<AccountResponse>(`${environment.URLBase}v1/contas`, httpOptions);
  }

  public changeSelectedAccount(id:string) :Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
 
    return this.http.get<any>(`${environment.URLBase}v1/contas/selecionar?indice=${id}`, httpOptions);
}

public getAccountsWithNoTerms() :Observable<AccountWithNoTermResponse> {

  const httpOptions = {
      headers: getHeader(),
    };
 
    return this.http.get<AccountWithNoTermResponse>(`${environment.URLBase}v1/termoaceite/contas-sem-termo`, httpOptions);
}
}
