<div class="benefit-content">
  <div class="list-value">
    <!-- Lista de Beneficiários vazio -->
    <div class="empty-info" *ngIf="hide && !loading">
      <fibra-generic-info
        type="warn"
        [title]="genericTitle"
        [message]="genericMessage"
      >
      </fibra-generic-info>
    </div>

    <!-- Loading -->
    <div class="d-flex justify-content-center loading" *ngIf="loading && !hide">
      <div class="spinner-border text-primary" role="status"></div>
    </div>

    <!-- Lista de Beneficiários -->
    <ul
      class="col-lg-12 list-group list-group-flush p-0"
      *ngIf="!loading && !hide"
    >
      <li
        *ngFor="
          let item of data;
          index as i
          
        "
        class="row-list"
      >
        <div class="panel-group" id="accordion">
          <table>
            <tr>
              <td class="td-1">
                <div
                  class="initial-letters show-initial-letters"
                  [id]="'background' + i"
                >
                  {{ initialLetters(item.nome_beneficiario) }}
                </div>
              </td>
              <td class="td-2">
                <div class="col-name">
                  <small>Nome</small>
                  <span data-hj-suppress>{{ item.nome_beneficiario | titlecase }}</span>
                </div>
              </td>
              <td class="td-3">
                <div
                  class="col-cpf"
                  [ngStyle]="
                    item.contas.length > 1 && { 'white-space': 'nowrap' }
                  "
                >
                  <small>CNPJ/CPF</small>
                  <span>{{ removeMask(item.num_cpf_cnpj) | cpfcnpj }}</span>
                </div>
              </td>
              <td class="td-4">
                <div class="col-bank-number" *ngIf="item.contas.length === 1">
                  <small>Número da instituição</small>
                  <span> {{ pad(item.contas[0].cod_banco, 3) }} </span>
                </div>
              </td>
              <td class="td-5">
                <div class="col-fibra-bank" *ngIf="item.contas.length === 1">
                  <img
                    *ngIf="item.contas[0].cod_banco == 224"
                    class="ico-fibra"
                    src="assets/svg/logo-blue-small.svg"
                    alt="Conta do Banco Fibra"
                  />
                  <div class="col-bank-name" *ngIf="item.contas.length > 0">
                    <small>Nome da instituição</small>
                    <span> {{ item.contas[0].nome_banco }} </span>
                  </div>
                </div>
              </td>
              <td class="td-6">
                <div class="col-agency" *ngIf="item.contas.length === 1">
                  <small>Agência</small>
                  <span> {{ item.contas[0].num_agencia }} </span>
                </div>
              </td>
              <td class="td-7">
                <div class="col-account" *ngIf="item.contas.length === 1">
                  <small>Conta</small>
                  <span> {{ item.contas[0].num_conta | account }} </span>
                </div>
              </td>
              <td class="td-8">
                <div class="col-select-btn">
                  <button
                    type="button"
                    class="button"
                    (click)="selectBeneficiary(item, item.contas[0])"
                    *ngIf="item.contas.length === 1"
                  >
                    Selecionar
                  </button>
                </div>
                <div
                  *ngIf="item.contas.length > 1"
                  class="severalAccounts"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  [attr.href]="'#collapse' + i"
                >
                  {{ item.contas?.length }} Contas
                  <img
                    src="/assets/svg/arrow_down_circle.svg"
                    alt="Mostrar Detalhes"
                    (click)="openDetails()"
                  />
                </div>
              </td>
              <td class="td-9">
                <div class="col-new-account-btn">
                  <button
                    type="button"
                    class="button"
                    (click)="newBeneficiary(item)"
                  >
                    Nova Conta
                  </button>
                </div>
              </td>
              <td class="td-10">
                <div class="col-trash-btn item-icon" id="rotate" *fibraHasRole="'TRANSF.EXC_BEN'">
                  <div
                    style="width: 19px"
                    [hidden]="item.contas.length === 1"
                  ></div>
                  <img
                    [hidden]="item.contas.length !== 1"
                    src="/assets/svg/trash.svg"
                    alt="Excluir Favorito"
                    (click)="emitDeleteBeneficiary.emit(item)"
                  />
                </div>
              </td>
            </tr>
          </table>

          <div
            [id]="'collapse' + i"
            class="panel-collapse collapse in"
            *ngIf="item.contas.length > 1"
          >
            <div class="panel-body" *ngIf="item.contas.length > 1">
              <fibra-details-list-beneficiaries
                [balanceData]="item"
                [indice]="i"
                (emitBeneficiary)="setBeneficiary($event)"
                (emitDelete)="emitDeleteAccount.emit($event)"
                (emitToast)="emitToastDetails($event)"
              ></fibra-details-list-beneficiaries>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<!-- <fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message> -->