import { Mapper } from '@src/core/base/mapper';
import { instructionListModel } from '@src/core/domain/query/query.model';
import { instructionListEntity } from './instruction.entity';

export class instructionListRepositoryMapper extends Mapper<
  instructionListEntity,
  instructionListModel
> {
  mapFrom(param: instructionListEntity): instructionListModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: instructionListModel): instructionListEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
