import { ToastErrorMessageComponent } from './../toast-error-message/toast-error-message.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RemoveClassDataShare } from '@src/core/usecases/data-share/remove-class-data-share.service';
import { environment } from '@src/environments/environment';
import { version } from '../../../../../package.json';
import * as $ from 'jquery';
import { ProfileAccessProvider } from '@src/data/repository/perfilamento/profile.repository';
import { UserService } from '@src/shared/services/user.service';
import { Injectable } from '@angular/core';
import { throwIfEmpty } from 'rxjs/operators';

@Component({
    selector: 'fibra-sidemenu',
    templateUrl: './sidemenu.component.html',
    styleUrls: ['./sidemenu.component.scss'],
})


@Injectable({
    providedIn: 'root'
})

export class SideMenuComponent implements OnInit {
    public version;
    private currentOpenSubmenu;
    private currentOpenSubmenuArrow;
    simulacao: boolean;
    public mobile: boolean = false;
    @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
    public routeWay: any;
    public REDCOLOR = '#ED6C6C';
    public typToastColor;

    constructor(
        private route: Router,
        private actRoute: ActivatedRoute,
        private removeClass: RemoveClassDataShare,
        private acessProvider: ProfileAccessProvider,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.mobile = this.isResponsive()
        this.actRoute.queryParams.subscribe(params => {
            if (params && params.login_id) {
                sessionStorage.setItem('loginId', params.login_id);
            }
        });
        this.version = version;
        this.initInHome();
        this.simulacao = this.route.url === '/impersonate';
        this.removeClass.dataToShare.subscribe((res) => {
            if (res === true) {
                this.removeSelector();
                this.resetIcons();
            }
        });

        this.route.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                const ne = event as NavigationEnd;
                this.changeIcon(ne.urlAfterRedirects);
                this.simulacao = this.route.url === '/impersonate';
            }
        });

        $('#sidebar-container').hover(() => {
            const submenu = $('.submenu-open');
            submenu && submenu.slideToggle();
        });
    }

    removeSelector(): void {
        $('#ItemHome').removeClass('menu-active');
        $('#ItemExtrato').removeClass('menu-active');
        $('#ItemCobranca').removeClass('menu-active');
        $('#ItemTransferencia').removeClass('menu-active');
        $('#ItemProducts').removeClass('menu-active');
        $('#ItemMais').removeClass('menu-active');
        $('#ItemInvestimento').removeClass('menu-active');
        $('#ItemLoans').removeClass('menu-active');
    }

    changeIcon(chooseMenu) {
        this.typToastColor = this.REDCOLOR;
        this.resetIcons();
        switch (chooseMenu) {
            case '/dashboard':
                this.removeSelector();
                $('#ItemHome').addClass('menu-active');
                return $('.home-white').attr('src', '/assets/svg/inicio_white.svg');
            case '/extract':
                this.removeSelector();
                $('#ItemExtrato').addClass('menu-active');
                return $('.extrato-withe').attr('src', '/assets/svg/extrato_white.svg');
            case '/investments':
                this.removeSelector();
                $('#ItemInvestimento').addClass('menu-active');
                return $('.investimento-withe').attr('src', '/assets/svg/investments.svg');
            case '/transfer':
            case '/transfer-confirm':
            case '/transfer-pending':
            case '/transfer-receipt':
            case '/payment':
            case '/bank':
            case '/ownership':
            case '/same-ownership':
            case '/new-ownership':
            case '/transaction-approval':
            case '/pix':
                this.removeSelector();
                $('#ItemTransferencia').addClass('menu-active');
                return $('.transactions-white').attr('src', '/assets/svg/transactions_white.svg');
            case '/charge':
            case '/charge-archive':
            case '/query':
            case '/duplicate-bill':
                this.removeSelector();
                $('#ItemCobranca').addClass('menu-active');
                return $('.cobranca-withe').attr('src', '/assets/svg/coin_white.svg');
            case '/foreign-trade':
                this.removeSelector();
                $('#ItemProducts').addClass('menu-active');
                return $('.products-withe').attr('src', '/assets/svg/icon_more_white.svg');
            case '/income-report':
            case '/registrato':
            case 'more':
                this.removeSelector();
                $('#ItemMais').addClass('menu-active');
                return $('.more-withe').attr('src', '/assets/svg/icon_more_white.svg');
            case '/loans':
                this.removeSelector();
                $('#ItemLoans').addClass('menu-active');
                return $('.loan-white').attr(
                    'src',
                    '/assets/svg/icon-loans-white.svg'
                );
            case 'more':
                this.removeSelector();
                $('#ItemMais').addClass('menu-active');
                return $('.more-withe').attr('src', '/assets/svg/icon_more_white.svg');
        }
    }

    resetIcons() {
        $('.home-white').attr('src', '/assets/svg/inicio.svg');
        $('.extrato-withe').attr('src', '/assets/svg/faturas.svg');
        $('.transactions-white').attr('src', '/assets/svg/transacoes.svg');
        $('.cobranca-withe').attr('src', '/assets/svg/coin.svg');
        $('.more-withe').attr('src', '/assets/svg/icon_more.svg');
        $('.products-withe').attr('src', '/assets/svg/icon_more.svg');
        $('.loan-white').attr('src', '/assets/svg/icon-loans.svg');
    }

    toggleSubmenu(event, submenuId) {
        const arrow = $('#' + event.currentTarget.id).find('.setinha').first();
        const submenu = $(submenuId);

        if ((this.currentOpenSubmenu
            && this.currentOpenSubmenu[0].id !== submenu[0].id)
            && this.currentOpenSubmenu.hasClass('submenu-open')
        ) {
            this.currentOpenSubmenu.slideToggle();
            this.currentOpenSubmenu.toggleClass('submenu-open');
            this.currentOpenSubmenuArrow.toggleClass('rotate-arrow');
        }

        this.currentOpenSubmenu = submenu;
        this.currentOpenSubmenuArrow = arrow;

        submenu.slideToggle();
        submenu.toggleClass('submenu-open');
        arrow.toggleClass('rotate-arrow');
    }

    initInHome(): void {
        this.changeIcon(this.route.url);
    }

    isPf() {
        return this.userService.isPf();
    }

    public isResponsive = () => {
        let width = window.innerWidth <= 768;

        if (width) {
            return true;
        }
        return false;
    };

    suspenseAccount(way) {
        switch (way) {
            case '/extract':      
                    this.toast.callModalMessage(null, "Para acessar o menu de Extrato, entre em contato com o seu gerente de relacionamento.", '', false, true);
                    return false;                             
            case '/transfer':
                    this.toast.callModalMessage(null, "Para acessar o menu de Transferência, entre em contato com o seu gerente de relacionamento.", '', false, true);
                    return false;                              
            case '/payment':           
                    this.toast.callModalMessage(null, "Para acessar o menu de Pagamento de Boletos, entre em contato com o seu gerente de relacionamento.", '', false, true);
                    return false;
            case '/payment-batch':            
                    this.toast.callModalMessage(null, "Para acessar o menu de Pagamento em Lotes, entre em contato com o seu gerente de relacionamento.", '', false, true);
                    return false;              
        }
        this.route.navigate([way]);
    }
}
