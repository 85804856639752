import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import {
  instructionListModel,
  ListQueryRequest,
} from '@src/core/domain/query/query.model';
import { InstructionRepository } from '@src/core/repositories/query/instruction.repository';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class getAllQueryInstructionUsecase
  implements UseCase<ListQueryRequest, instructionListModel> {
  constructor(private instructionRepository: InstructionRepository) {}

  execute(params: ListQueryRequest): Observable<instructionListModel> {
    return this.instructionRepository.getAllQueryInstruction(params);
  }
}
