import { Component, OnInit, ViewChild } from '@angular/core';
import { PixGetKeysDataRepository } from '@src/data/repository/pix/keys/pix-get-keys-repository';

@Component({
  selector: 'fibra-pix-solicitation',
  templateUrl: './pix-solicitation.component.html',
  styleUrls: ['./pix-solicitation.component.scss']
})
export class PixSolicitationComponent implements OnInit {

  @ViewChild('content', { static: true }) content: HTMLElement;
  @ViewChild('contentSms', { static: true }) contentSms: HTMLElement;
  @ViewChild('contentEmail', { static: true }) contentEmail: HTMLElement;
  public enable = false;

  public selectedNavBar: string = 'keys';
  public seletedFilter: string = 'pending';

  public payload: any;
  public loading = false


  public keyapproved = [];
  public keysRefused = [];
  public keyPending = [];
  public keys;

  public userEmail;

  constructor(
    public getKeys: PixGetKeysDataRepository,
  ) { }

  ngOnInit() {
    this.getKeysApproved();
    this.userEmail = localStorage.getItem('useremail');
  }

  handleSelecterdNav(type: any) {
    this.selectedNavBar = type;
  }

  handleFilter(type: any) {
    this.seletedFilter = type;
  }


  getKeysApproved() {
    this.loading = true;
    this.getKeys.getKeysApproved().subscribe(data => {
      this.keys = data['data'];
      this.keys.map((key: any) => {
        if (key.status === 'APROVADO') {
          this.keyapproved.push(key)
        }
        if (key.status === 'PENDENTE') {
          this.keyPending.push(key)
          console.log(this.keyPending)
        }
        if (key.status === 'REPROVADO') {
          this.keysRefused.push(key)
        }
      })
      this.loading = false
    }, error => {
      this.loading = false
    })
  }

  isPeddingotherMaster(approveds) {
    let isOtherMaster = false;
    approveds.map(data => {
      if (data.status === 'PENDENTE' && this.userEmail === data.pessoa.email) {
        isOtherMaster = true
      }
    })
    return isOtherMaster
  }

}
