<div *ngIf="duplicates != undefined && !loading">
  <ul class="duplicate-item-list" *ngFor="let duplicate of duplicates">
    <li class="list-group-item duplicate">
      <div class="duplicate-name-number">
        <svg
          width="25"
          height="20"
          viewBox="0 0 25 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.16667 0H0.833333C0.373333 0 0 0.373333 0 0.833333V4.16667C0 4.62667 0.373333 5 0.833333 5C1.29333 5 1.66667 4.62667 1.66667 4.16667V1.66667H4.16667C4.62667 1.66667 5 1.29333 5 0.833333C5 0.373333 4.62667 0 4.16667 0Z"
            fill="#082A4D"
          />
          <path
            d="M24.1667 15C23.7067 15 23.3333 15.3733 23.3333 15.8333V18.3333H20.8333C20.3733 18.3333 20 18.7067 20 19.1667C20 19.6267 20.3733 20 20.8333 20H24.1667C24.6267 20 25 19.6267 25 19.1667V15.8333C25 15.3733 24.6267 15 24.1667 15Z"
            fill="#082A4D"
          />
          <path
            d="M24.1667 0H20.8333C20.3733 0 20 0.373333 20 0.833333C20 1.29333 20.3733 1.66667 20.8333 1.66667H23.3333V4.16667C23.3333 4.62667 23.7067 5 24.1667 5C24.6267 5 25 4.62667 25 4.16667V0.833333C25 0.373333 24.6267 0 24.1667 0Z"
            fill="#082A4D"
          />
          <path
            d="M4.16667 18.3333H1.66667V15.8333C1.66667 15.3733 1.29333 15 0.833333 15C0.373333 15 0 15.3733 0 15.8333V19.1667C0 19.6267 0.373333 20 0.833333 20H4.16667C4.62667 20 5 19.6267 5 19.1667C5 18.7067 4.62667 18.3333 4.16667 18.3333Z"
            fill="#082A4D"
          />
          <path
            d="M4.16634 3.33325C3.70634 3.33325 3.33301 3.70659 3.33301 4.16659V15.8333C3.33301 16.2933 3.70634 16.6666 4.16634 16.6666C4.62634 16.6666 4.99968 16.2933 4.99968 15.8333V4.16659C4.99968 3.70659 4.62634 3.33325 4.16634 3.33325Z"
            fill="#082A4D"
          />
          <path
            d="M7.50032 3.33325C7.04033 3.33325 6.66699 3.70659 6.66699 4.16659V12.4999C6.66699 12.9599 7.04033 13.3333 7.50032 13.3333C7.96032 13.3333 8.33366 12.9599 8.33366 12.4999V4.16659C8.33366 3.70659 7.96032 3.33325 7.50032 3.33325Z"
            fill="#082A4D"
          />
          <path
            d="M10.8333 3.33325C10.3733 3.33325 10 3.70659 10 4.16659V12.4999C10 12.9599 10.3733 13.3333 10.8333 13.3333C11.2933 13.3333 11.6667 12.9599 11.6667 12.4999V4.16659C11.6667 3.70659 11.2933 3.33325 10.8333 3.33325Z"
            fill="#082A4D"
          />
          <path
            d="M14.1663 3.33325C13.7063 3.33325 13.333 3.70659 13.333 4.16659V15.8333C13.333 16.2933 13.7063 16.6666 14.1663 16.6666C14.6263 16.6666 14.9997 16.2933 14.9997 15.8333V4.16659C14.9997 3.70659 14.6263 3.33325 14.1663 3.33325Z"
            fill="#082A4D"
          />
          <path
            d="M17.5003 3.33325C17.0403 3.33325 16.667 3.70659 16.667 4.16659V12.4999C16.667 12.9599 17.0403 13.3333 17.5003 13.3333C17.9603 13.3333 18.3337 12.9599 18.3337 12.4999V4.16659C18.3337 3.70659 17.9603 3.33325 17.5003 3.33325Z"
            fill="#082A4D"
          />
          <path
            d="M20.8333 3.33325C20.3733 3.33325 20 3.70659 20 4.16659V15.8333C20 16.2933 20.3733 16.6666 20.8333 16.6666C21.2933 16.6666 21.6667 16.2933 21.6667 15.8333V4.16659C21.6667 3.70659 21.2933 3.33325 20.8333 3.33325Z"
            fill="#082A4D"
          />
          <path
            d="M7.53307 15H7.51641C7.05641 15 6.69141 15.3733 6.69141 15.8333C6.69141 16.2933 7.07307 16.6667 7.53307 16.6667C7.99307 16.6667 8.36641 16.2933 8.36641 15.8333C8.36641 15.3733 7.99307 15 7.53307 15Z"
            fill="#082A4D"
          />
          <path
            d="M10.8671 15H10.8504C10.3904 15 10.0254 15.3733 10.0254 15.8333C10.0254 16.2933 10.4071 16.6667 10.8671 16.6667C11.3271 16.6667 11.7004 16.2933 11.7004 15.8333C11.7004 15.3733 11.3271 15 10.8671 15Z"
            fill="#082A4D"
          />
          <path
            d="M17.5331 15H17.5164C17.0564 15 16.6914 15.3733 16.6914 15.8333C16.6914 16.2933 17.0731 16.6667 17.5331 16.6667C17.9931 16.6667 18.3664 16.2933 18.3664 15.8333C18.3664 15.3733 17.9931 15 17.5331 15Z"
            fill="#082A4D"
          />
        </svg>
        <div class="duplicate-group">
          <span class="duplicate-small">Nome do pagador</span>
          <span class="duplicate-name" data-hj-suppress>{{ duplicate.nome_sacado }}</span>
        </div>
      </div>
      <div class="duplicate-group">
        <span class="duplicate-small">Carteira</span>
        <span class="duplicate-month">
          {{ duplicate.num_carteira }}
        </span>
        <span class="duplicate-your-number">
          Seu número: {{ duplicate.num_seu_numero }}
        </span>
      </div>
      <div class="duplicate-due-date">
        <span class="duplicate-small">Vencimento</span>
        <span class="duplicate-date-final">
          {{ duplicate.dt_vencimento | date: 'd/M/yyyy' }}
        </span>
        <span class="duplicate-our-number">
          Nosso número: {{ duplicate.num_nosso_numero }}
        </span>
      </div>
      <div class="duplicate-total">
        <span class="duplicate-total-money"
          >R$
          {{
            isNull(duplicate.vlr_titulo)
              ? '0,0'
              : (duplicate.vlr_titulo | money)
          }}
        </span>
        <span class="duplicate-our-number-corresp">
          Nosso número corresp.: {{ duplicate.num_nosso_numero_corresp }}
        </span>
      </div>

      <button
        type="button"
        (click)="requestDownloadBill(duplicate)"
        class="leaked-button"
      >
        Gerar segunda via
      </button>
    </li>
  </ul>
</div>

<div class="empty-info" *ngIf="hide">
  <fibra-generic-info
    type="warn"
    title="Nenhum resultado encontrado"
    message="Tente uma nova pesquisa inserindo novos parâmetros."
  >
  </fibra-generic-info>
</div>

<div class="d-flex justify-content-center position loading" *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>