import { SelectorDataShare } from '@src/core/usecases/data-share/selector-data-share.service';
import { ShowValuesDashboardDataShare } from '@src/core/usecases/data-share/show-values-dashboard-data-share.service';
import { Component, OnInit, AfterViewInit, Input, ElementRef, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs/operators';
import * as $ from 'jquery';
import { RemoveClassDataShare } from '@src/core/usecases/data-share/remove-class-data-share.service';
import {toTitleCase} from 'codelyzer/util/utils';
import { ToastErrorMessageComponent } from '../toast-error-message/toast-error-message.component';
import { environment } from '@src/environments/environment';
import * as signalR from "@microsoft/signalr";

@Component({
  selector: 'fibra-main-bank',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  
})

export class MainComponent implements OnInit, AfterViewInit {

  @ViewChild(ToastErrorMessageComponent, {static: false}) toast!: ToastErrorMessageComponent;

  public name: string;
  public avatar: string;
  @Input() hideMenu?: boolean;
  @Input() hideMain?: boolean;
  @Input() hideProfile;
  @Input() hideCompany;
  public iconEyesOpen = true;
  public toogleShowIconEyes: boolean;
  public loading: boolean;
  public divisor;
  public showCtrlNtfy: boolean = false;
  public colorToast = '#6CBED9';
  public control = false;
  currentState: boolean;
  
  mockNotification = [
    {
      id: 1,
      title: 'Transação pix feita com sucesso',
      body: 'Você possui regra pendente de aprovação. <botão Ir para Matriz de aprovação>' 
    },
    {
      id: 2,
      title: 'Transação pix feita com sucesso',
      body: 'O cadastro da regra <nome da regra> foi aprovado.' 
    },
    {
      id: 3,
      title: 'Transação pix feita com sucesso',
      body: 'A exclusão da regra <nome da regra> foi reprovada' 
    },
    {
      id: 4,
      title: 'Transação pix feita com sucesso',
      body: 'Você possui solicitação Pix pendente de aprovação <botão Ir para Pix>' 
    }
  ];

  constructor(
    private router: Router,
    private authService: MsalService,
    private sharedSelector: SelectorDataShare,
    private sharedShowValues: ShowValuesDashboardDataShare,
    private shareRemoveClass: RemoveClassDataShare,
    private el: ElementRef
  ) {
    this.showValues();

    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(event => {
      if (event instanceof NavigationStart ) {
        this.toogleShowIconEyes = (event.url.indexOf('extract') !== -1 || event.url.indexOf('dashboard') !== -1)
      }
    });
    this.currentState = false;
  }

  ngOnInit() {
    this.loading = true;
    this.hideCompany = false;
    this.hideProfile = true;
    setTimeout(() => this.userName(), 3000);
    this.showValues();
    //this.callSignalREvent();
  }

  clickBell(evt){
    this.currentState = !this.currentState;
  }
  

  ngAfterViewInit() {
    setTimeout(() => this.userName(), 3000);
  }

  showValues() {
    this.iconEyesOpen = !this.iconEyesOpen;
    this.sharedShowValues.setValue({
      iconEyesOpen: this.iconEyesOpen
    });
  }

  userName = () => {
    this.name = localStorage.getItem('username');
    if (this.name) {
      this.name = this.name.split(' ')[0];
    }
    this.avatar = this.name && this.name.charAt(0).toUpperCase();
    this.loading = false;
  }

  callSignalREvent(){
    const url = `${environment.URLBase}v1/NotificacaoHub`;
    const options = { accessTokenFactory: () => sessionStorage.getItem('x-ibpj-session') }
    let connection = new signalR.HubConnectionBuilder()
    .withUrl(url)
    .build();

    connection
    .start()
    .then(() => console.log('Conexao iniciada'))
    .catch(err => console.log('Erro: ' + err));
  }

  logout() {
    sessionStorage.clear();
    this.authService.logout();
    localStorage.clear();
  }

  navMatrix() {
    this.router.navigate(['/approval-matrix']);
    this.shareRemoveClass.setValue(true);
  }

  navAdmin() {
    this.router.navigate(['/admin-user']);
  }

  openModal() {
    $(document).ready(() => {
      $('#open').click();
    });
    $(document).ready(() => {
      $('#open').click();
    });
  }

  callNotifyEvent(evt, i){
    this.showCtrlNtfy = evt;
  }

  markRead(ind, imgEl: HTMLElement){
    imgEl.classList.add('imgCtrl');
    imgEl.setAttribute('src', './assets/svg/icon-aprovar.svg')
  }

  removeNotify(ind, id, el: HTMLElement, circle: HTMLElement, sino: HTMLElement){
    el.classList.add('animate__animated');
    el.classList.add('animate__backOutLeft');

    circle.classList.add('animate__animated');
    circle.classList.add('animate__rubberBand');

    setTimeout(() => {
      this.mockNotification.splice(ind, 1);
      this.toast.callModalMessage(false, 'Notificação excluída', null, null, true);

      circle.classList.remove('animate__animated');
      circle.classList.remove('animate__rubberBand');
      circle.classList.remove('animate__repeat-1');
    }, 500);
  }

}

