import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { scan } from 'rxjs/operators';
import {ReplaySubject} from 'rxjs';
import {consoleDev} from '@src/shared/util-common';
import jwt_decode from 'jwt-decode';

export interface Role {
  name: string;
}

@Injectable()
export class RolesService {

  // A stream that exposes all the roles a user has
  private tag: string = "fibra-session";

  constructor() {  }

  private RemoveArrayFromArray(a: string[], b: string[]){
    b.forEach((value)=>{
      this.RemoveElementFromStringArray(a, value);
    });
  }

  private RemoveElementFromStringArray(a: string[], element: string) {
    a.forEach((value,index)=>{
        if(value==element) a.splice(index,1);
    });
  }

  public get(): string[]{
    let token = this.getSession();
    return this.getRolesFromToken(token);
  }

  public getRoles(): Roles[]{
    let token = this.getSession();

    return this.getRolesObjectFromToken(token);
  }

  private getRolesFromToken(token: string): string[]{
    var decoded: UserToken = jwt_decode(token); 
    var roles = [];
    if(decoded && decoded.UserRoles){
      decoded.UserRoles.forEach(role => {
        roles.push(role.Name)
      });
    }
    return roles;
  }
  
  private getRolesObjectFromToken(token: string): Roles[]{
    var decoded: UserToken = jwt_decode(token); 
    var roles: Roles[];
    roles = [];
    if(decoded && decoded.UserRoles){
      decoded.UserRoles.forEach(role => {
        if(role.NumeroConta)
        {
          role.NumeroConta.forEach(conta => { 
            roles.push(new Roles (role.Name, conta))
          });
        }
        else
          roles.push(new Roles (role.Name, null))

      });
    }
    return roles;
  }


  public setSession(token: string){
    sessionStorage.setItem(this.tag, token);
  }

  public getSession(){
    let token = sessionStorage.getItem(this.tag);
    return token;
  }

  public setOpenFinanceSession(login_id: string, login_state: string, data: any) {
    sessionStorage.setItem('fibra-openfinance-session-login-id', login_id);
    sessionStorage.setItem('fibra-openfinance-session-login-state', login_state);
    sessionStorage.setItem('fibra-openfinance-session-data', JSON.stringify(data));
  }

  public getOpenFinanceSession(): { login_id: string, login_state: string, data: any } {

    const login_id = sessionStorage.getItem('fibra-openfinance-session-login-id');
    const login_state = sessionStorage.getItem('fibra-openfinance-session-login-state');
    const data = sessionStorage.getItem('fibra-openfinance-session-data');

    if (login_id && login_state && data) {
      return {
        login_id: login_id,
        login_state: login_state,
        data: data
      }
    }

    return null;
  }

  public clearOpenFinanceSession() {
    sessionStorage.removeItem('fibra-openfinance-session-login-id');
    sessionStorage.removeItem('fibra-openfinance-session-login-state');
    sessionStorage.removeItem('fibra-openfinance-session-data');
  }
}


class UserToken {
  PublicData: any;
  SessionId: string;
  UserId: string;
  UserRoles: UserRole[]
}

class UserRole {
  Name: string;
  NumeroConta: string[];
}

export class Roles {
  Name: string;
  NumeroConta?: string;

  constructor(Name:string, NumeroConta?: string ) { 
    this.Name = Name;
    this.NumeroConta = NumeroConta 
 }  
}
