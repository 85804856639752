import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import {isArray} from 'util';
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import {environment} from '@src/environments/environment';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Injectable } from "@angular/core";
import * as moment from 'moment';

export const consoleDev = (str?, value?) => {
  if (environment.env === 'local') {
    // return console.log(str, value);
  }
};

export const verifyArray = (value) => {
  return isArray(value) ? value : [value];
};

export const isEmpty = (value) => {
  return !(Array.isArray(value) && value.length);
};

export const isNull = (value) => {
  return value === 0 || value === undefined || value === null;
};

export const getOS = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }
  return os;
};

export const isDevice = () => {
  const device = getOS();
  return device === 'Android' || device === 'iOS';
};

export function formatCPFMask(rawValue) {
  const numbers = rawValue.match(/\d/g);
  let numberLength = 0;

  if (numbers) {
    numberLength = numbers.join('').length;
  }
  if (numberLength <= 11) {
    return cpfMask;
  }
}

export function formatIdMask(rawValue) {
  const numbers = rawValue.match(/\d/g);
  let numberLength = 0;

  if (numbers) {
    numberLength = numbers.join('').length;
  }

  if (numberLength <= 8) {
    return idMask;
  }
}

export function calcDiffInDays(params)
  {
    var params = JSON.parse(params)
    var dataInicio  = params.dataInicio;
    var dataFinal = params.dataFinal;

    var given = moment(dataFinal, "YYYY-MM-DD");
    var current = moment(dataInicio, "YYYY-MM-DD").startOf('day');
    let days = moment.duration(given.diff(current)).asDays();

    return days;
  }


export function formatLettersNumbersMask(rawValue) {
  const numbers = rawValue.match(/\d/g);
  let numberLength = 0;

  if (numbers) {
    numberLength = numbers.join('').length;
  }

  if (numberLength <= 20) {
    return lettersNumbersMask;
  }
}

export function formatMobileMask(rawValue) {
  if (rawValue) {
    const numbers = rawValue.match(/\d/g);
    let numberLength = 0;
    let mask;
    if (numbers) {
      numberLength = numbers.join('').length;
    }
    if (numberLength <= 30) {
      mask =  cellPhoneMask15;
    }

    return mask;
  } else {
    return false;
  }
}

export function formatMobileInternacionalMask(rawValue) {
  if (rawValue) {
    const numbers = rawValue.match(/\d/g);
    let numberLength = 0;
    let mask;
    if (numbers) {
      numberLength = numbers.join('').length;
    }
    if (numberLength <= 19) {
      mask =  cellInternacionalPhoneMask;
    }

    return mask;
  } else {
    return false;
  }
}

export function formatCnhMask(rawValue) {
  if (rawValue) {
    const numbers = rawValue.match(/\d/g);
    let numberLength = 0;
    let mask;
    if (numbers) {
      numberLength = numbers.join('').length;
    }
    if (numberLength <= 19) {
      mask =  cnhMask;
    }

    return mask;
  } else {
    return false;
  }
}

export function formatCPMask(rawValue) {
  if (rawValue) {
    const numbers = rawValue.match(/\d/g);
    let numberLength = 0;
    let mask;
    if (numbers) {
      numberLength = numbers.join('').length;
    }
    if (numberLength <= 7) {
      mask =  cpMask;
    }

    return mask;
  } else {
    return false;
  }
}

export function formatRneMask(rawValue) {
  if (rawValue) {
    const numbers = rawValue.match(/\d/g);
    let numberLength = 0;
    let mask;
    if (numbers) {
      numberLength = numbers.join('').length;
    }
    if (numberLength <= 9) {
      mask =  rneMask;
    }

    return mask;
  } else {
    return false;
  }
}

export function formatEmitterMask(rawValue) {

  if (rawValue) {
    const numbers = rawValue.match(/\d/g);
    let numberLength = 0;
    let mask;
    if (numbers) {
      numberLength = numbers.join('').length;
    }
    if (numberLength <= 19) {
      mask =  emitterMask;
    }

    return mask;
  } else {
    return false;
  }
}

export function cpfcnpjmask(rawValue?) {
  if (rawValue) {
    const numbers = rawValue.match(/\d/g);
    let numberLength = 0;

    if (numbers) {
      numberLength = numbers.join('').length;
    }

    if (numberLength <= 11) {
      return cpfMask;
    } else {
      return cnpjMask;
    }
  } else {
    return false;
  }
}

export function formatCpfCnpj(value: string) {
    
  value = (+(value.replace(/\D/g, ""))).toString();

  if (value.length <= 11) { //CPF
    
    value = value.padStart(11,'0')

    //Coloca um ponto entre o terceiro e o quarto dígitos
    value = value.replace(/(\d{3})(\d)/,"$1.$2")

    //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    value = value.replace(/(\d{3})(\d)/,"$1.$2")

    //Coloca um hífen entre o terceiro e o quarto dígitos
    value = value.replace(/(\d{3})(\d{1,2})$/,"$1-$2")

  } else { //CNPJ
    value = value.padStart(14,'0')
      
    //Coloca ponto entre o segundo e o terceiro dígitos
    value = value.replace(/^(\d{2})(\d)/,"$1.$2")

    //Coloca ponto entre o quinto e o sexto dígitos
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")

    //Coloca uma barra entre o oitavo e o nono dígitos
    value = value.replace(/\.(\d{3})(\d)/,".$1/$2")

    //Coloca um hífen depois do bloco de quatro dígitos
    value = value.replace(/(\d{4})(\d)/,"$1-$2")
  }

  return value
}

export const cepMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
export const cnhMask = [/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];
export const cpMask = [/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];

export const codeBarMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const yourNumber = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const yourText = [
  /[A-Za-z0-9_@.#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]/,
  /[A-Za-z0-9_@.#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]/,
  /[A-Za-z0-9_@.#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]/,
  /[A-Za-z0-9_@.#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]/,
  /[A-Za-z0-9_@.#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]/,
  /[A-Za-z0-9_@.#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]/,
  /[A-Za-z0-9_@.#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]/,
  /[A-Za-z0-9_@.#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]/,
  /[A-Za-z0-9_@.#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]/,
  /[A-Za-z0-9_@.#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]/,
];

export const fiscalKey = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const billKey = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export const moneyMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

export const cpfMask = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const accountMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const cnpjMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const cellPhoneMask = ['(', /[0-9]/, /[0-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const cellPhoneMask15 = ['(', /[0-9]/, /[0-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
// export const cellInternacionalPhoneMask = ['+',/[0-9]/, /[0-9]/, ' ','(', /[0-9]/, /[0-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const cellInternacionalPhoneMask = ['(', /[0-9]/, /[0-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const emitterMask = [ /[A-Za-z]/,/[A-Za-z]/,/[A-Za-z]/,'/',/[A-Za-z]/,/[A-Za-z]/ ];
export const rneMask = [ /[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,'-',/[A-Za-z0-9]/ ];
export const lettersNumbersMask = [ /[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/ ];

export const idMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
];

export function handleFile(res: any, fileName: string, ext: string = 'xls') {
  const file = new Blob([res], {
    type: res.type, 
  });

  // IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file);
    return;
  }

  //Se Firefox Adiciona a extensão
  if (window.navigator.userAgent.indexOf("Firefox")) {
    fileName = fileName + `.${ext}`
  }

  const blob = window.URL.createObjectURL(file);

  const link = document.createElement('a');
  link.href = blob;
  link.download = fileName;

  // link.click();
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  setTimeout(() => {
    // firefox
    window.URL.revokeObjectURL(blob);
    link.remove();
  }, 100);
}

export function handleError(error: HttpErrorResponse) {
  let errorMessage = '';
  if (error.error instanceof HttpErrorResponse) {
    // Erro ocorreu no lado do client
    errorMessage = error.error.message;
  } else {
    // Erro ocorreu no lado do servidor
    errorMessage =
      `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
  }
  return throwError(errorMessage);
}

export function getOrderedDate(array) {
  array.sort(compare);

  function compare(a, b) {
    if (a.data_emissao < b.data_emissao) {
      return -1;
    }
    if (a.data_emissao > b.data_emissao) {
      return 1;
    }
    return 0;
  }
}

export function getOrderedPayer(array) {
  array.sort(compare);

  function compare(a, b) {
    if (a.pagador < b.pagador) {
      return -1;
    }
    if (a.pagador > b.pagador) {
      return 1;
    }
    return 0;
  }
}

export function getOrderedValue(array) {
  array.sort(compare);

  function compare(a, b) {
    if (a.vlr_titulo < b.vlr_titulo) {
      return -1;
    }
    if (a.vlr_titulo > b.vlr_titulo) {
      return 1;
    }
    return 0;
  }
}

export function formatNegativeMoney(num) {
  const decimalPart = num.toFixed(2).split('.')[1];
  return (
    num
      .toString()
      .split('.')[0]
      .split('')
      .reverse()
      .map((n, index, array) => {
        return array[index + 1] && array[index + 1] === '-'
          ? n
          : (index + 1) % 3 === 0 && array[index + 1]
          ? `.${n}`
          : n;
      })
      .reverse()
      .join('') +
    ',' +
    decimalPart
  );
}

export function getOrderedDataAccount(array) {
  array.sort(compare);

  function compare(a, b) {
    if (a.cod_agencia < b.cod_agencia) {
      return -1;
    }
    if (a.cod_agencia > b.cod_agencia) {
      return 1;
    }
    if (a.num_conta < b.num_conta) {
      return -1;
    }
    if (a.num_conta > b.num_conta) {
      return 1;
    }
    return 0;
  }
}

export function getOrderedTypeAccount(array) {
  array.sort(compare);

  function compare(a, b) {
    if (a.desc_conta < b.desc_conta) {
      return -1;
    }
    if (a.desc_conta > b.desc_conta) {
      return 1;
    }
    return 0;
  }
}

export function formatString(str, positions?) {
  const pos = positions || 25;
  if (str.length > pos) {
    return str.substring(0, pos) + '...';
  }
  return str;
}

export function compareDate(value: string) {
  const date = new Date(value.split('/').reverse().join('/'));
  const novaData = new Date();

  return (date > novaData);
}

export function sortList(value, array?) {
  const originalArray = [...array];

  orderArray(value, true, array);
  for (let i = 0; i < array.length; ++i) {
    if (array[i] !== originalArray[i]) {
      return;
    }
  }
  orderArray(value, false, array);
}

export function orderArray(value, ascending, array?) {
  let sorted: boolean;
  const x = ascending ? 1 : -1;
  const y = ascending ? -1 : 1;
  sorted = !sorted;

  array.sort((a, b) => {

    let aValue;
    let bValue;

    if(value == "vlr_transacao"){
      aValue = a[value];
      bValue = b[value];
    }
    // else if(value === "tipo_transacao"){
    //   aValue = transferTypeToNumber(a[value].toString().toUpperCase().trim());
    //   bValue = transferTypeToNumber(b[value].toString().toUpperCase().trim());
    // }
    else{
      aValue = a[value].toString().toUpperCase().trim();
      bValue = b[value].toString().toUpperCase().trim();
  
      if(value == "Banco"){
        aValue =  aValue.replace(/^([^-]+\-){1}/, '').trim();
        bValue =  bValue.replace(/^([^-]+\-){1}/, '').trim();
      }
    }
    
    if (aValue < bValue) { return !sorted ? x : y; }
    if (aValue > bValue) { return !sorted ? y : x; }
    return 0;
  });
}

export function transferTypeToNumber(value) {
  if (value === 'DOC') {
    return 1;
  }
  if (value === 'PAGAMENTO') {
    return 2;
  }
  if (value === 'TED') {
    return 3;
  }
  if (value === 'TEF') {
    return 4;
  }
}

export function verifyTransferType(value) {
  if (value === 'DOC') {
    return 1;
  }
  if (value === 'TED') {
    return 2;
  }
  if (value === 'TEF') {
    return 3;
  }
}

export function formatHour(date) {
  const newDate = date.split('T');
  return newDate[1];
}

export function historyState(callBackAction) {
  window.addEventListener(
    'popstate',
    (event) => {
      history.pushState(null, null, window.location.pathname);
      callBackAction();
    },
    false
  );
}

export function boletoMask(rawValue?: any) {
  if (rawValue) {
    const numbers = rawValue.replace(/[^0-9]/g, '');

    const firstNumber = numbers.substr(0, 1).toString();

    if (firstNumber === '8') {
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
      ];
    } else {
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }
  } else {
    return false;
  }
}
export function removeMask(value: string) {
  return value.replace(/[^0-9]/g, '');
}

export function iconPath(cod: string, desc: string | string[]): string {
  const pathEstono = '/assets/svg/icon_estorno.svg';
  const pathBill = '/assets/svg/icon_boleto.svg';
  const pathTransfer = '/assets/svg/icon_transferencia.svg';
  const pathOthersCredit = '/assets/svg/icon_credito.svg';
  const pathOthersDebit = '/assets/svg/icon_debito.svg';

  if (cod === 'C' && desc === 'Outros') {
    return pathOthersCredit;
  }
  if (cod === 'D' && desc === 'Outros') {
    return pathOthersDebit;
  }
  if (cod && desc === 'Transferencias') {
    return pathTransfer;
  }
  if (cod && desc === 'Pagamento de boleto') {
    return pathBill;
  }
  if (cod && desc === 'Estono') {
    return pathEstono;
  }
}

export function generatePDF(body: string) {
  const data = document.getElementById(body);

  html2canvas(data).then((canvas) => {
    window.scrollTo(0, 0);
    var imgWidth = 210;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    
    var doc = new jsPDF('p', 'mm');
    var position = 0;

    const imgData = canvas.toDataURL("image/png")

    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight,undefined,'FAST');
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight,undefined,'FAST');
      heightLeft -= pageHeight;
    }
    doc.save('BancoFibra.pdf');
  });
}

export function generateDerivativePDF(body: string) {
  const data = document.getElementById(body);

  html2canvas(data).then((canvas) => {
    const imgWidth = 208;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const heightLeft = imgHeight;

    const contentDataURL = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const position = 0;
    pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    pdf.save('BancoFibraDerivativo.pdf');
  });
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = "/";

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? (date.day < 10 ? "0" + date.day : date.day) +
          this.DELIMITER +
          (date.month < 10 ? "0" + date.month : date.month) +
          this.DELIMITER +
          date.year
      : "";
  }
}

export function mascaraCpfCnpj(value: string): string {
  let input = value.replace(/\D/g, '');

  if (input.length === 0) {
      input = '';
  } else if (input.length <= 3) {
      input = input.replace(/^(\d{0,3})/, '$1');
  } else if (input.length <= 6) {
      input = input.replace(/^(\d{0,3})(\d{0,3})/, '$1.$2');
  } else if (input.length <= 9) {
      input = input.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '$1.$2.$3');
  } else if (input.length <= 11) {
      input = input.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1.$2.$3-$4');
  } else if (input.length === 11) {
      input = input.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1.$2.$3-$4');
  } else if (input.length <= 12) {
      input = input.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})/, '$1.$2.$3/$4');
  } else {
      input = input.substring(0, 14);
      input = input.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/, '$1.$2.$3/$4-$5');
  }

  if (input.length == 14) {
    return `***${input.substring(3, 12)}**`;
  } else {
    return `**${input.substring(2, 16)}**`;
  }  
}

export function protectShowCpf(val){
  return val.length === 14 ? '***'+val.substring(3, 12)+'**' : val;
}


export function downloadPdf(data){
    var oReq = new XMLHttpRequest();
    oReq.open("GET", data['data'].url, true);
    oReq.responseType = 'blob';
    oReq.onload = function (event) {
      let blob = new Blob([oReq.response], { type: 'application/pdf' });
      var file = URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = data['data'].nome_arquivo;
      a.click();
    }
    oReq.send();
}

export function clearSpecialChar(evt) {
  evt.srcElement.value = evt.srcElement.value.replace(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, '');
  return evt.srcElement.value = evt.srcElement.value.replace(/[^a-zA-Z0-9záàâãéèêíïóôõöúçñ. -]/gi, '');
}


export function formatValueAccount(account) {
  if(account.length == 7) {
    account = `000${account}`;
  }

  if(account.length == 8) {
    account = `00${account}`;
  }

  if(account.length == 9) {
    account = `0${account}`;
  }

  if(account.length >= 10) {
    account = account;
  }
  return account;
}

export function returnAgency(agency) {
  if(agency.length == 1) {
    agency = `000${agency}`;
  }
  if(agency.length == 2) {
    agency = `00${agency}`;
  }
  if(agency.length == 3) {
    agency = agency;
  }
  return agency;
}

export function cpfOrcnpj(value) {
  let result = "";
  if(value.length === 11) {
    result = 'CPF';
  }

  if(value.length === 14) {
    result = 'CNPJ';
  }
  return result;
}