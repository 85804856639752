import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PixDeleteKeysDataRepository } from '@src/data/repository/pix/keys/pix-delete-keys-repository';
import { PixGetKeysDataRepository } from '@src/data/repository/pix/keys/pix-get-keys-repository';
import { KeysEntity } from '@src/data/repository/pix/keys/pix-key-entity';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';
import * as moment from 'moment';


@Component({
  selector: 'fibra-pix-chaves',
  templateUrl: './pix-chaves.component.html',
  styleUrls: ['./pix-chaves.component.scss']
})
export class PixChavesComponent implements OnInit {

  public cadastro = false;
  public enable = false;
  public loading = false;
  public key = '(11) 95270-9639';
  public date = new Date();
  public semChaves = false;
  public hasKeys;
  public totalKeys;
  public emailKeys: KeysEntity[];
  public documentKeys: KeysEntity[];
  public randomKeys: KeysEntity[];
  public smartPhoneKeys: KeysEntity[];
  public hasError = false;
  @Output() changeToCadastro = new EventEmitter();
  public arrayChaves;
  public toast: ToastErrorMessageComponent;
  public selectedkey;

  public showModalValidation = false
  public payload: any;
  public apiCallback: any;

  isMaster = false;
  getAccountInformationSession: any;

  public msgError = "Ocorreu um erro no envio";
  public subTextError = "Tente novamente!"

  public isPending = false;
  public color = '#6CBED9';


  public TodayDate = moment(new Date).format('DD/MM/YYYY');

  constructor(public getKeys: PixGetKeysDataRepository,
    public deleteKeyService: PixDeleteKeysDataRepository,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.getAccountInformationSession = JSON.parse(sessionStorage.getItem('defaultCompany'));
    this.loading = true;
    // this.getKeys.getKeysIb().subscribe((data: any) => {
    //   this.getKeysOnInit(data['data'].keys);
    // }, error => {
    //   this.getKeysOnInit([]);
    // })

    this.getKeysOnInit();


  }
  public change() {
    this.cadastro = true;
  }

  public goToCadastro() {
    localStorage.setItem('view-selecionada', 'minhaschaves-cadastro');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  public goBack() {
    localStorage.setItem('view-selecionada', 'minhaschaves-transferir');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  public goToCadastroNoKeys(key: string) {
    localStorage.setItem('cadastro-atual', key);
    localStorage.setItem('view-selecionada', 'minhaschaves-cadastro');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }
  //metodo para deletar um chave, para exclusão de chave ativa é usado o apiccallbacl 24 - na definição atual apenas chaves ativas são excluídas 
  public deleteSelectedKey() {
    this.apiCallback = this.selectedkey.status === 'PENDENTE' ? 91 : 24;
    let bacenpayload = {
      chave: this.selectedkey.chave,
      chave_tipo: this.selectedkey.chave_tipo,
      tp_operacao_chave: "EXCLUIR",
      portabilidade: false,
      cod_validacao: "",
      token_validacao: ""
    }


    this.payload = this.selectedkey.status === 'PENDENTE' ? this.selectedkey.infoOperacaoChave.cod_operacao_chave : bacenpayload;


    this.closeModal('');
    this.showModalValidation = true;
  }
  //metodo para copiar chave
  public copy(data) {
    const texto = data;
    const chave = document.createElement('input');
    chave.value = texto;
    document.body.appendChild(chave);
    chave.select();
    document.execCommand('copy');
    document.body.removeChild(chave);
  }

  //metodo para trazer chaves ativas no bacen ou com pendencia no bacen
  public getKeysOnInit() {
    // let kyesIb = keys
    this.loading = true;
    this.getKeys.getKeys().subscribe((keys: any) => {
      if (keys.data.keys.length > 0) {
        const data = keys.data;
        let object = data['keys']
        this.emailKeys = new Array();
        this.documentKeys = new Array();
        this.randomKeys = new Array();
        this.smartPhoneKeys = new Array();
        object.forEach((res) => {
          // let existDoubleKey = kyesIb.find(k => k.chave === res.chave) === undefined ? false : true;
          // if (existDoubleKey && res.status != 'PENDENTE') {

          // }
          // else 
          if (res.chave_tipo === 'EMAIL') {
            this.date = new Date(res.data_criacao);
            res.dataHoraFormatada = `${this.date.toLocaleDateString()}  ${this.date.toLocaleTimeString()}`;
            this.emailKeys.push(res);
          } else if (res.chave_tipo === 'CPF' || res.chave_tipo === 'CNPJ') {
            this.date = new Date(res.data_criacao);
            res.dataHoraFormatada = `${this.date.toLocaleDateString()}  ${this.date.toLocaleTimeString()}`;
            this.documentKeys.push(res);
          } else if (res.chave_tipo === 'EVP') {
            this.date = new Date(res.data_criacao);
            res.dataHoraFormatada = `${this.date.toLocaleDateString()}  ${this.date.toLocaleTimeString()}`;
            this.randomKeys.push(res);
          } else {
            this.date = new Date(res.data_criacao);
            res.dataHoraFormatada = `${this.date.toLocaleDateString()}  ${this.date.toLocaleTimeString()}`;
            this.smartPhoneKeys.push(res);
          }
        })
        this.totalKeys = this.emailKeys.length + this.documentKeys.length + this.randomKeys.length + this.smartPhoneKeys.length;
        this.hasKeys = true;
        this.loading = false;
      } else {

        this.hasKeys = false;
        this.loading = false;
      }

    }, (err) => {
      if (err['status'] === 404) {
        this.hasKeys = false;
        this.loading = false;
        this.hasError = false;

      } else {
        this.hasKeys = false;
        this.loading = false;
        this.hasError = true;
      }

    })

  }

  openModal(content, key): void {
    this.showModalValidation = false;
    this.selectedkey = key;
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

  public closeModal($event): void {
    this.modalService.dismissAll();
  }

  public closeModalApi($event) {
    this.showModalValidation = false;
  }

  //resposta para delete de chaves
  public responseApiModal($event) {
    //se ocorre um delete exclusão do horário
    if ($event.code === 200 && $event['data'].ds_status_consolidado === 'IB_AGENDADO') {
      this.color = '#D69D10';
      const message = document.getElementById('message');
      message.classList.add('show');
      this.msgError = "Exclusão pendente";
      this.subTextError = "Sua efetivação ocorrerá dentro do horário de funcionamento do BACEN."
      //se ocorre uma exclusão normal
    } else if ($event.code === 200 && $event['data'].status === 'APROVADO') {
      this.color = '#6CBED9';
      const message = document.getElementById('message');
      message.classList.add('show');
      this.msgError = "A chave foi excluída";
      this.subTextError = "Para continuar recebendo escolha uma outra chave ou cadastre novas chaves.!"

    }
    //exclusão pendente de aprovação de outro master
    else if ($event.code === 200 && $event['data'].status === 'PENDENTE') {
      this.color = '#D69D10';
      const message = document.getElementById('message');
      message.classList.add('show');
      this.msgError = "Exclusão pendente";
      this.subTextError = "Solicitação enviada para aprovação do(s) master(s) da sua empresa."
    }
    else if ($event.code === 200) {
      this.color = '#6CBED9';
      const message = document.getElementById('message');
      message.classList.add('show');
      this.msgError = "A chave foi excluída";
      this.subTextError = "Para continuar recebendo escolha uma outra chave ou cadastre novas chaves.!"
    }
    //erro generico
    else if ($event.status === 400) {
      this.color = '#ed6c6c';
      const message = document.getElementById('message');
      message.classList.add('show');
      this.msgError = "Ocorreu um erro no envio";
      this.subTextError = "Tente novamente!"
    }

    this.loading = true;
    //atualiza a lista de chaves
    this.getKeysOnInit();

    // this.getKeys.getKeysIb().subscribe((data: any) => {
    //   this.getKeysOnInit(data['data'].keys);
    // }, error => {
    //   this.getKeysOnInit([]);
    // })

  }

  public closeError() {
    this.msgError = "Ocorreu um erro no envio";
    const message = document.getElementById('message');
    message.classList.remove('show');
  }

  //verifica se a data do agendamento é hoje
  isToday(date): boolean {
    return moment().isSame(moment(date), 'day')
  }


  public isActiveKey(status: string): boolean {
    if (status && status.trim() == 'Chave ativa no sistema' || status.trim() == 'Chave está ativa e o sistema está aguardando o usuário confirmar que quer fazer a portabilidade da chave para outro PSP') {
      return true;
    }

    return false;
  }
}


