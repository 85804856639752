import { Observable } from 'rxjs';
import { PaymentModel, PaymentRequest, PaymentBoletoRequest, PaymentDeleteRequest, PaymentMultipleRequest  } from '@src/core/domain/payment/payment.model';

export abstract class PaymentRepository {
  abstract getBoleto(obj: PaymentBoletoRequest): Observable<PaymentModel>;
  abstract postBoleto(obj: PaymentRequest): Observable<PaymentModel>;
  abstract saveBoletos(obj: PaymentRequest): Observable<PaymentModel>;
  abstract deleteBoletos(obj: PaymentDeleteRequest): Observable<PaymentModel>;
  abstract postMultipleBoleto(obj: PaymentMultipleRequest): Observable<PaymentModel>;
  abstract getStatusHourPayment(x: number): Observable<any>;
}

