import {GetAllRulesUsecase} from '@src/core/usecases/approval-matrix/get-all-rules.usecase';
import {GetAllPeopleUsecase} from '@src/core/usecases/approval-matrix/get-all-people.usercase';
import {GetPeopleUsecase} from '@src/core/usecases/approval-matrix/get-people.usecase';
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as Util from '@src/shared/util-common';
import {formatString} from '@src/shared/util-common';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import * as $ from 'jquery';
import {ApprovalMatrixModel, PeopleList, RuleEditRequest} from '@src/core/domain/approval-matrix/approval-matrix.model';
import {DeleteRuleUsecase} from '@src/core/usecases/approval-matrix/delete-rule.usercase';
import {GetAllAccountTypeUsecase} from '@src/core/usecases/account/get-all-accountTypes.usecase';
import {GetRulesIdUsecase} from '@src/core/usecases/approval-matrix/get-rules-id.usecase';
import {ToastErrorMessageComponent} from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import {isObject, isString} from 'util';
import {toTitleCase} from 'codelyzer/util/utils';
import { RolesService } from '@src/shared/services/roles.service';


interface SecurityAction {
  typeAction?: string;
  show: boolean;
  code?: number;
  id?: number;
  cod_regra?: number;
  nome_regra?: string;
  vlr_permitido?: any;
  master?: boolean;
}

@Component({
  selector: 'fibra-list-matrix',
  templateUrl: './list-matrix.component.html',
  styleUrls: ['./list-matrix.component.scss'],
})
export class ListMatrixComponent implements OnInit {

  constructor(
    private formBuild: FormBuilder,
    private getAllRules: GetAllRulesUsecase,
    private getRulesId: GetRulesIdUsecase,
    private getAllAccountTypes: GetAllAccountTypeUsecase,
    private deleteRuleItem: DeleteRuleUsecase,
    private getAllPeople: GetAllPeopleUsecase,
    private getPeople: GetPeopleUsecase,
    private role: RolesService,
  ) {
    this.flagAccount = {
      bool: false,
      value: 0
    };
    this.flagOperator = {
      bool: false,
      value: 0
    };
    this.formEditRule = this.formBuild.group({
      operator: ['', []],
      area: ['', []],
    });
  }
  public indexSelected: any;
  public formEditRule: FormGroup;
  public securityAcction: SecurityAction;
  private screenWidth;
  private noOperator: boolean;
  private ruleItem: any;
  public searchOperator: any;
  public hide: boolean;
  public loading: boolean;
  public loadingItemList: boolean;
  public rulesData: any;
  public errorOperator = true;
  public params;
  public sorted: boolean;
  public err: boolean;
  public active;
  public typeColorToast: string;
  public list = [];
  public ruleId;
  public master = 2;
  public showAction = false;
  public textAlertRule = '';
  public confirmAction = false;
  public addOperators = [];
  public rules: Array<ApprovalMatrixModel> = [];
  public people: Array<PeopleList> = [];
  public operators: Array<PeopleList>;
  public data = [];
  public optionsCounter = [];
  public countOptionsRule = [];
  public accountGroup = [];
  public model;
  private typeMessage: number;
  public selectedAccount;
  public flagAlreadyExist: boolean;
  public flagAlreadyExistOperator: boolean;
  public checkAccounts = [];
  public checkOperators = [];
  public flagAccount;
  public flagOperator;
  public allAccounts = [];
  public randHack: string;
  public addAccArr = [];
  public delAccArr = [];
  public addOprArr = [];
  public delOprArr = [];
  public selectedBottomFunction;
  public flagAlreadyDelete: boolean;  
  public indexList: number;
  public apiCallback: number;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  public vlrPermitido: any;
  public temPermissao: boolean = false;

  public initialVlrPermitido: any;
  public initialOperators: Array<PeopleList>;
  public initialMaster: boolean;
  public initialAccountGroup = [];
  public checkMaster : any;
  public valueAccount: any;
  
  accountMatrix: any = {
    account: null,
    vlr_permitido: null
  };
  public accountArray = [];
  public accountCurrent: any;
  public blockedAdd: boolean = true;

  public newValue: any;
  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  private static scrollBy() {
    document.querySelector('#body-scroll-matrix').scrollBy({
      top: 410,
      behavior: 'smooth',
    });
  }

  ngOnInit(): void {
    var permissoes = this.role.get();
    if(permissoes.includes('MTZ_APROV.EDIT_EXCL_REG')){
      this.temPermissao = true;
    }
    this.randHack = (Math.floor((Math.random() * 9999999999999))).toString();
    this.handleTabs('first');
    this.requestRules();
  }

  public verifyValue = () => {
    if (this.vlrPermitido === 0 || this.vlrPermitido == null || this.vlrPermitido === '' || this.vlrPermitido === undefined ) {
      this.toast.callModalMessage(null, 'O campo Valor Até R$ deve ser preenchido com valor superior a zero.');
      return true;
    }
    return false;
  }

  public onClick(event, value) {
    event.preventDefault();
    this.newValue = value;
    this.vlrPermitido = '';
    this.vlrPermitido = this.formatVlrPermitido(this.newValue);
  }

  public formatVlrPermitido = value => {
    if (value.length >= 12) {
    // tslint:disable-next-line:radix
      return parseInt(value.split(' ')[1]
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.'));
    }
    // tslint:disable-next-line:radix
    return parseInt(value.split(' ')[1]
        .replace('.', '')
        .replace(',', '.'));
  }

  public formatVlr(vlr) {
    if (isNaN(vlr)) {
      return vlr.replace('.', '').replace(',', '');
    } else {
      return vlr;
    }
  }

  private requestAccount(): void {
    this.loadingItemList = true;
    this.getAllAccountTypes
      .execute(null)
      .subscribe(
        this.requestAccountSuccess,
        this.requestAccountError,
        this.requestComplete
      );
  }

  private requestStatusRule(code, type) {
    this.typeMessage = type;
    const obj = {
      regra_id: code,
      status: type
    };
    return obj;
  }

  private requestSuccessStatusRule = response => {
    this.typeColorToast = this.typeMessage === 1 ?
        this.GREENCOLOR : this.REDCOLOR;
    this.toast.callModalMessage(null, response.data);
  }

  private requestErrorStatusRule = error => {
    this.typeColorToast = this.REDCOLOR;
    this.toast.callModalMessage(null, 'Ocorreu um erro ao aprovar a regra.');
  }

  private responseStatus = value => {
    this.loadingItemList = false;
    this.requestRules();
    if (value.status === 'success') {
      this.requestSuccessStatusRule(value);
      return;
    }
    this.requestErrorStatusRule(value);
  }

  private requestAccountError = (err) => {
    this.hide = true;
    this.loadingItemList = false;
  }

  private requestAccountSuccess = (value) => {
    this.allAccounts = value.data;
    value.data.map(e => {
      this.countOptionsRule.push({
        name: `${e.num_conta} - ${toTitleCase(e.desc_conta)}`,
        value: e.num_conta,
      });
    });
  }

  private requestAllOperators = () => {
    this.getAllPeople
      .executeApproval({ id: 1 })
      .subscribe(this.requestAllOperatorsSuccess, this.requestAllOperatorsError);
  }

  private requestAllOperatorsSuccess = res => {
    this.operators = res.data;
    //console.log('Operadores:',this.operators)
  }

  private requestAllOperatorsError = err => {
    this.operators = [];
    console.log('Operadores deu erro:',this.operators)
  }

  private requestRules = () => {
    this.loading = true;
    this.resetList();
    this.getAllRules
      .execute()
      .subscribe(this.requestSuccess, this.requestError, this.requestComplete);

  }

  private requestSuccess = (value: ApprovalMatrixModel) => {
    let isReproved: boolean;
    let rulesFormated: Array<any>;
    this.requestAllOperators();
    this.rulesData = value.data;
    rulesFormated = this.rulesData;
    rulesFormated.map(e => {
      const email = localStorage.getItem('useremail');
      let userApproved: boolean;
      let pendent: boolean;
      let ruleApproved: boolean;
      let userMaster: boolean;
      isReproved = e.regras_pessoas.some(r => r.status === 2);
      userApproved = e.regras_pessoas.some(user => user.email === email && user.status !== 0) || false;
      userMaster = e.regras_pessoas.some(user => user.email === email && user.master);
      pendent = e.regras_pessoas.some(p => p.status === 0 && p.master === true);
      ruleApproved = e.regras_pessoas.every(rule => rule.status === 1);
      e.regras_pessoas = e.regras_pessoas.map(pessoas => {
        if (isReproved && pessoas.status === 0) {
          pessoas.status = 3;
        }
        return pessoas;
      });
      if (userMaster) {
        e.pendent = (pendent && userApproved);
      } else {
        e.pendent = true;
      }
      if (isReproved) {
        return e.status = 2;
      }
      if (ruleApproved) { e.status = 1; }
      this.accountMatrix = {
          account: e.regras_contas.map(p => p.conta.numero),
          vlr_permitido: e.vlr_permitido,
      }
      this.accountArray.push(this.accountMatrix);
      return e;
    });

    this.rules = rulesFormated;
    this.hide = Util.isEmpty(this.rules);
    this.formatList(this.rules);
  }

  private requestError = (err) => {
    this.rules = this.data;
    if (Util.isEmpty(this.rules)) {
      this.hide = true;
    } else {
      this.err = true;
    }
    this.loading = false;
  }

  private requestComplete = () => {
    this.hide = Util.isEmpty(this.rules);
    this.loading = false;
  }

  private requestOperater = (param) => {
    this.loadingItemList = true;
    this.getPeople
      .execute(param)
      .subscribe(this.requestOperaterSuccess, this.requestOperaterError, this.completeOperator);
  }

  private requestOperaterSuccess = (value: ApprovalMatrixModel) => {
    const returnPeaple = Util.verifyArray(value.data);
    this.people = returnPeaple.filter(e => e.criador === false);
    this.addOperators = this.people;
  }

  private requestOperaterError = (err) => {
    this.people = this.data;
    this.loadingItemList = false;
  }

  private completeOperator = () => {
    this.loadingItemList = false;
  }

  public search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => {
        this.errorOperator = term === '' || term === null || term === ' ';
        // return term === '' || term === null || term === ' '
        //     ? []
        //     : this.operators
        //         .filter(
        //             (v) => {
        //               const arr = v.email.split('@');
        //               const userEmail = arr[0];
        //               return v.nome_pessoa.toLowerCase().indexOf(term.toLowerCase()) > -1
        //                   || userEmail.toLowerCase().indexOf(term.toLowerCase()) > -1;
        //             }
        //         )
        //         .slice(0, 10).sort((a: any, b: any) => {
        //           return a.nome_pessoa < b.nome_pessoa ? -1 : 1;
        //         });
        if(term === '' || term === null || term === ' '){
          return[];
        }
        else{
          var operadores= this.operators
            .filter(
              (v) => {
                const arr = v.email.split('@');
                const userEmail = arr[0];
                const userEmailDomain = arr[1];
                return v.nome_pessoa.toLowerCase().indexOf(term.toLowerCase()) > -1
                || userEmail.toLowerCase().indexOf(term.toLowerCase()) > -1
                || userEmailDomain.toLowerCase().indexOf(term.toLowerCase()) > -1
                || v.email.toLowerCase().indexOf(term.toLowerCase()) > -1;
              }
          );

          operadores = operadores.slice(0, 10);
          operadores = operadores.sort((a: any , b: any) => {
              if (a.nome_pessoa < b.nome_pessoa) {
                return -1;
              }
              else if(a.nome_pessoa > b.nome_pessoa){
                return 1;
              }
              return 0;
            } );

            return operadores;
          }
      })
    );
  }

  public formatter = (x: { nome_pessoa: string, email }) => {
    this.searchOperator = x;
    return `${x.nome_pessoa} - ${x.email}`;
  }

  private formatList = (value) => {
    value.map( e => {
        this.optionsCounter.push({
          name: this.formatValue(e),
          value: e,
        });
    });
  }

  public mountAccount(idRule) {
   setTimeout(()=> {
    this.accountGroup = [];
    this.rulesData.forEach(a => {
      if (a.cod_regra === idRule) {
        a.regras_contas && a.regras_contas.forEach(c => {
          this.allAccounts.map((e) => {
           if(e.num_conta == c.conta.numero) {
            c.conta.desc_conta = e.desc_conta;
            console.log(e.desc_conta)
            return e.desc_conta;
           }
        });
          this.accountGroup.push(`${c.conta.numero} - ${ toTitleCase(c.conta.desc_conta)}`);
        });
      }
    });
   }, 2000);
  }

  private formatValue(value): string {
    return this.screenWidth <= 1130 ? formatString(value, 27) : value;
  }

  public changeCounter(event) {
    this.selectedAccount = null;
    this.selectedAccount = event.name;
  }

  public addAccount() {
    if (!this.selectedAccount) {
      this.typeColorToast = this.REDCOLOR;
      this.toast.callModalMessage(null, 'Favor selecionar ao menos uma conta.');
      return;
    }
    this.flagAlreadyExist = false;
    let numberAccount = this.selectedAccount;
    numberAccount = numberAccount.split(' - ');
    this.accountCurrent = this.accountArray.find((e) => e.account == numberAccount[0] && e.vlr_permitido == this.vlrPermitido);
    if(this.accountCurrent) {
      this.toast.callModalMessage(null, 'Esta conta já faz parte de uma regra.');
      return
    }else {
    this.accountGroup.map(e => {
      if (String(e) === String(this.selectedAccount)) {
        this.flagAlreadyExist = true;
      }
    });
    if (!this.flagAlreadyExist) {
      this.accountGroup.push(this.selectedAccount);
      this.addAccArr.push(this.selectedAccount);
      this.delAccArr.map((e, i) => {
        if (e.num_conta === this.selectedAccount) {
          this.addAccArr.splice(i, 1);
        }
      });
    } else {
      this.typeColorToast = this.REDCOLOR;
      this.toast.callModalMessage(null, 'Esta conta já faz parte da regra.');
    }
    }

    this.selectedAccount = this.selectedAccount.split(" - ")
    let arrayAccount = {
      account: [this.selectedAccount[0]],
      vlr_permitido: this.vlrPermitido
    }
    this.accountArray.push(arrayAccount);
    this.accountArray.push(arrayAccount);
  }

  public createItemList = param => {
    this.checkAccounts = [];
    this.checkOperators = [];
    this.requestOperater({idRule: param.idRule, idPeopleType: param.idPeopleType});
    this.mountAccount(param.idRule);
    this.accountGroup.forEach(conta =>{
      this.initialAccountGroup.push(conta);
    });
    
  }

  public toogleIconArrow(index, idRule) {
     if (this.indexList !== index) {
      this.indexList = index;
      $('.collapse').removeClass('show');
      $(`.item-icon img`).removeClass('img-arrow');
    }
     this.rulesData.map(e => {
      if (idRule === e.cod_regra) {
        this.initialMaster = e.master;
        this.initialOperators = e.regras_pessoas;
        this.initialAccountGroup = this.accountGroup;
        this.initialVlrPermitido = e.vlr_permitido;
        this.vlrPermitido = e.vlr_permitido;

      }
    });
     this.createItemList({idRule, idPeopleType: 1 });
     this.ruleItem = {idRule, idPeopleType: 1 };
     document.getElementById(index).classList.toggle('img-arrow');
     $('input').val('');
     $('option').attr('selected', false);
     if (this.allAccounts.length === 0) {
      this.allAccounts = [];
      this.requestAccount();
     }
     this.selectedAccount = null;
  }

  public actionCancel = () => {
    this.people = [];
    this.addOperators = [];
    this.addAccArr = [];
    this.delAccArr = [];
    this.addOprArr = [];
    this.delOprArr = [];
    this.model = null;
    this.createItemList(this.ruleItem);
  }

  public handleTabs(stage) {
    this.active = stage;
    this.loading = true;
    this.toast.dismissToast();

    if (stage === 'first') {
      this.list = [];
      this.requestRules();
      return;
    }

    if (stage === 'second') {
      this.list = [];
      this.loading = false;
      return;
    }
  }

  public addOperatorRule() {
    let operatorName: string;
    const isOperator = this.operators.some(e => {
       if (this.searchOperator !== undefined) {
         return e.cod_pessoa === this.searchOperator.cod_pessoa;
       }
       return false;
     });
    this.flagAlreadyExistOperator = false;
    this.noOperator = this.operators.some(e => {
       if (isString(this.model)) {
         return e.nome_pessoa.toUpperCase() === this.model.toUpperCase()
             || e.email.toUpperCase() === this.model.toUpperCase();
       }
       if (isObject(this.model)) {
         return e.nome_pessoa.toUpperCase() === this.model.nome_pessoa.toUpperCase()
             || e.email.toUpperCase() === this.model.email.toUpperCase();
       }
      });
    if (isOperator && this.noOperator) {
      this.addOperators.map(e => {
        if (e.email === this.searchOperator.email) {
          this.flagAlreadyExistOperator = true;
        }
      });
      this.people.map(e => {
        if (e.email === this.searchOperator.email) {
          this.flagAlreadyExistOperator = true;
        }
      });
      if (!this.flagAlreadyExistOperator) {
        this.addOprArr.push(this.searchOperator.cod_pessoa);
        this.addOperators.push(this.searchOperator);
        this.model = null;
      }
      this.searchOperator = [];
     }
    if (!isOperator && this.noOperator || this.flagAlreadyExistOperator) {
       operatorName = isObject(this.model) ? this.model.nome_pessoa : this.model;
       this.toast.callModalMessage(null, `O operador ${operatorName} já está relacionado a esta regra`);
       this.model = null;
     }
    if ((isOperator && !this.noOperator) || (!isOperator && !this.noOperator) ) {
       this.toast.callModalMessage(null, 'Nenhum registro encontrado.');
     }

  }

  public removeOperator(i) {
    if (this.addOperators.length === 1) {
      this.toast.callModalMessage(null, 'A regra deve conter ao menos um operador.');
      return;
    }

    this.indexSelected = i;
    this.deleteMessageOperator();

    // const index: number = this.addOperators.indexOf(i);
    // if (index !== -1) {
    //   this.addOperators.splice(index, 1);
    //   this.checkOperators.slice(index, 1);
    //   this.toast.callModalMessage(null, `Usuário excluído`);
    // }
    // const index2: number = this.people.indexOf(i);
    // if (index2 !== -1) {
    //   this.people.splice(index2, 1);
    // }
    // this.delOprArr.push(i.cod_pessoa);
    // if (this.addOperators.length === 0) {
    //   this.checkOperators = [];
    // }
  }

  public deleteRule(item) {
    ListMatrixComponent.scrollBy();
    this.ruleId = item.cod_regra;
    this.showAction = true;
    this.selectedBottomFunction = 'rule';
    this.textAlertRule = 'Deseja realmente excluir esta regra?';

    return this.textAlertRule;
  }

  public deleteAccount(account) {
    if (this.accountGroup.length === 1) {
      this.toast.callModalMessage(null, 'A regra deve conter ao menos uma conta.');
      return;
    }

    this.indexSelected = account;
    this.deleteMessageAccount();
  }

  private requestDeleteRule = (item) => {
    this.loading = true;
    return item;
  }

  private requestDeleteSuccess = (res) => {
    this.requestRules();
    this.confirmAction = false;
    this.typeColorToast = this.GREENCOLOR;
    this.toast.callModalMessage(null, 'Regra excluída com sucesso.');
  }

  private requestDeleteError = (err) => {
    this.loading = false;
    this.showAction = false;
    this.confirmAction = false;
    this.typeColorToast = this.REDCOLOR;
    setTimeout(() => {
      this.toast.callModalMessage(null, 'Não foi possível excluir a regra.');
    }, 1000);
  }

  private responseDelete = value => {
    this.apiCallback = 6;
    this.loading = false;
    this.showAction = false;
    if (value.status === 'success') {
      this.requestDeleteSuccess(value);
      return;
    }
    this.requestDeleteError(value);
  }

  public cancelDeleteRule() {
    this.showAction = false;
  }

  private requestEdit(value) {
    const {
      cod_regra,
      nome_regra,
      vlr_permitido,
      master,
    } = value;
    // tslint:disable-next-line:variable-name
    const regras_contas = [];
    // tslint:disable-next-line:variable-name
    const regras_contas_remover = [];
    // tslint:disable-next-line:variable-name
    const regras_aprovacao = [];
    // tslint:disable-next-line:variable-name
    const regras_aprovacao_remover = [];
    this.addAccArr.map(e => {
      const cc = String(e).split(' - ');
      regras_contas.push({
        conta: {
          numero: cc[0],
          desc_conta: cc[1]
        }
      });
    });
    this.delAccArr.map(e => {
      const cc = String(e).split(' - ');
      regras_contas_remover.push({
        conta: {
          numero: cc[0],
          desc_conta: cc[1]
        }
      });
    });
    this.addOprArr.map(e => {
      regras_aprovacao.push({
        pessoa_id: e,
        master: false
      });
    });
    this.delOprArr.map(e => {
      regras_aprovacao_remover.push({
        pessoa_id: e,
        master: false
      });
    });
    const params: RuleEditRequest = {
      cod_regra,
      nome_regra,
      vlr_permitido : this.formatVlr(this.vlrPermitido),
      master,
      regras_contas,
      regras_contas_remover,
      regras_aprovacao,
      regras_aprovacao_remover,
    };
    return params;
  }

  private requestEditSuccess = res => {
    this.typeColorToast = this.GREENCOLOR;
    this.toast.callModalMessage(null, 'Regra atualizada com sucesso.');
    setTimeout(() => this.requestRules(), 600);
  }

  private requestEditError = err => {
    this.typeColorToast = this.REDCOLOR;
    if (err.error.message && err.error.message.length > 0) {
      this.toast.callModalMessage(null, `${err.error.message[0]}`);
    } else {
      this.toast.callModalMessage(null, 'Houve um erro ao atualizar a regra.');
    }
  }

  private responseEdit = value => {
    if (value.status === 'success') {
      this.requestEditSuccess(value);
      return;
    } else {
      this.requestEditError(value);
    }
  }

  public sortList(value) {
    this.sorted = !this.sorted;
    this.rules.sort((a, b) => {
      if (a[value] < b[value]) {
        if (!this.sorted) {
          return -1;
        } else {
          return 1;
        }
      }
      if (a[value] > b[value]) {
        if (!this.sorted) {
          return 1;
        } else {
          return -1;
        }
      }
    });
  }

  public checkAccount(value) {
    this.indexSelected = null;
    this.flagAccount = {
      bool: false,
      value: 0
    };
    this.checkAccounts.map((e, i) => {
      if (e === value) {
        this.flagAccount = {
          bool: true,
          value: i,
        };
      }
    });
    if (!this.flagAccount.bool) {
      this.checkAccounts.push(value);
    } else {
      this.checkAccounts.splice(this.flagAccount.value, 1);
    }
  }

  public deleteCheckAccounts() {
    this.showAction = false;
    this.flagAlreadyDelete = false;
    if(this.indexSelected != null)
    {
      const index: number = this.accountGroup.indexOf(this.indexSelected);
      if (index !== -1) {
        this.accountGroup.splice(index, 1);
        this.toast.callModalMessage(null, `Conta excluída`); 
        //////
        this.valueAccount = this.indexSelected.split(' - ');
        let indx =[];
        this.accountArray.find((p, i) => {
         let indice = p.account == this.valueAccount[0] && p.vlr_permitido == this.vlrPermitido;
          if(indice) { 
            indx.push(i);
            return;
          }
        });
          this.accountArray.splice(indx[1], 1);
          this.accountArray.splice(indx[0], 1);
        ////////
      }
      this.addAccArr.map((e, i) => {
        if (e === this.indexSelected) {
          this.addAccArr.splice(i, 1);
          this.toast.callModalMessage(null, `Conta excluída`);
          ///////////
          e = e.split(' - ');
          let index = [];
          this.accountArray.find((p, i) => {
            let indice = p.account == e[0] && p.vlr_permitido == this.vlrPermitido;
            if(indice) { 
              index.push(i);
              return;
            }
          });
          this.accountArray.splice(index[1]);
          this.accountArray.splice(index[0]);
          ///////
        }
      });
      this.delAccArr.push(this.indexSelected);
      this.indexSelected = null;
    }
    this.checkAccounts.map(e => {
      const index: number = this.accountGroup.indexOf(e);
      if (index !== -1) {
        this.accountGroup.splice(index, 1);
        if(this.checkAccounts.length > 1){
          this.toast.callModalMessage(null, `Contas excluídas`);
        }
        else{
          this.toast.callModalMessage(null, `Conta excluída`);
          console.log(e);
        }
      }
      this.addAccArr.map((f, i) => {
        if (f === e) {
          this.addAccArr.splice(i, 1);
          if(this.checkAccounts.length > 1){
            this.toast.callModalMessage(null, `Contas excluídas`);
          }
          else{
            this.toast.callModalMessage(null, `Conta excluída`);
          }
        }
      });
      this.delAccArr.push(e);
    });
    this.checkAccounts = [];
  }

  public checkOperator(value) {
    this.indexSelected = null;
    this.flagOperator = {
      bool: false,
      value: 0
    };
    this.checkOperators.map((e, i) => {
      if (e === value) {
        this.flagOperator = {
          bool: true,
          value: i,
        };
      }
    });
    if (!this.flagOperator.bool) {
      this.checkOperators.push(value);
    } else {
      this.checkOperators.splice(this.flagOperator.value, 1);
    }
  }

  public deleteCheckOperators() {
    this.showAction = false;
    if(this.indexSelected != null){
      const index: number = this.addOperators.indexOf(this.indexSelected);
      if (index !== -1) {
        this.addOperators.splice(index, 1);
        //this.toast.callModalMessage(null, `Usuário excluído`);
      }
      const index2: number = this.people.indexOf(this.indexSelected);
      if (index2 !== -1) {
        this.people.splice(index2, 1);
      }
      if (this.addOperators.length === 0) {
        this.checkOperators = [];
      }
      this.delOprArr.push(this.indexSelected.cod_pessoa);
      this.checkOperators = [];
      this.indexSelected = null;
    }
    else{
      this.checkOperators.map(e => {
        const index: number = this.addOperators.indexOf(e);
        if (index !== -1) {
          this.addOperators.splice(index, 1);
        }
        const index2: number = this.people.indexOf(e);
        if (index2 !== -1) {
          this.people.splice(index2, 1);
          if(this.checkOperators.length > 1){
            //this.toast.callModalMessage(null, `Usuários excluídos`);
            }
            else{
             // this.toast.callModalMessage(null, `Usuário excluído`);
            }
        }
        const index3: number = this.addOprArr.indexOf(e.cod_pessoa);
        if (index3 !== -1) {
          this.addOprArr.splice(index3, 1);
          if(this.checkOperators.length > 1){
            //this.toast.callModalMessage(null, `Usuários excluídos`);
            }
            else{
              //this.toast.callModalMessage(null, `Usuário excluído`);
            }
        }
        this.delOprArr.push(e.cod_pessoa);
      });
      this.checkOperators = [];
    }
  }

  public deleteMessageOperator() {
    if (this.people.length === 1 && this.addOperators.length === 0 ||
        this.people.length === 0 && this.addOperators.length === 1 ||
        this.people.length + this.addOperators.length === this.checkOperators.length) {
      this.toast.callModalMessage(null, 'A regra deve conter ao menos um operador.');
      return;
    }
    this.showAction = true;
    this.selectedBottomFunction = 'operator';
    this.textAlertRule = this.checkOperators.length > 1 ?
        'Deseja realmente excluir os usuários selecionados?' :
        'Deseja realmente excluir o usuário selecionado?';
    return this.textAlertRule;
  }
  public deleteMessageAccount() { 
    if (this.accountGroup.length === 1 ||
        this.accountGroup.length === this.checkAccounts.length) {
      this.toast.callModalMessage(null, 'A regra deve conter ao menos uma conta.');
      return;
    }
    this.showAction = true;
    this.selectedBottomFunction = 'account';
    this.textAlertRule = this.checkAccounts.length > 1 ?
        'Deseja realmente excluir as contas selecionadas?' :
        'Deseja realmente excluir a conta selecionada?';
    return this.textAlertRule;
  }

  public bottomFunctions(func): void {
    switch (this.selectedBottomFunction || func) {
      case 'rule':
        this.confirmDelete();
        break;
      case 'account':
        this.deleteCheckAccounts();
        break;
      case 'operator':
        this.deleteCheckOperators();
        break;
    }
  }

  public actionEdit = (param) => {
    if(this.isValidFormData(param))
    {
      this.apiCallback = 5;
      this.securityAcction = {
        typeAction: 'EDIT',
        show: true,
        ...param
      };
    }
  }

  public isValidFormData(param: any): boolean{
    if(this.verifyValue()){
      return;
    }    
    
    if(this.initialVlrPermitido !== this.vlrPermitido){
      return true;
    }

    if(this.initialMaster !== param.master){
      return true;
    }

    var newOperators = this.addOperators.filter(op => !op.master).map(x => x.cod_pessoa);
    var oldOperators = this.initialOperators.filter(op => !op.master).map(x => x.cod_pessoa);
    var operadoresIsChange = this.validarList(newOperators, oldOperators); 
    if(!operadoresIsChange){
      return true;
    }

    var contasIsChange = this.validarList(this.accountGroup,  this.initialAccountGroup);
    if(!contasIsChange){
      return true;
    }
   
    this.toast.callModalMessage(null, 'Nenhuma alteração necessária.');
    return false;
  }

  validarList (newArray:any, oldArray:any) {
    // if the other array is a falsy value, return
    if (!oldArray)
        return false;
    // compare lengths - can save a lot of time 
    if (newArray.length != oldArray.length)
        return false;
    for (var i = 0, l=newArray.length; i < l; i++) {
        // Check if we have nested arrays
        if (newArray[i] instanceof Array && oldArray[i] instanceof Array) {
            // recurse into the nested arrays
            if (!newArray[i].equals(oldArray[i]))
                return false;       
        }           
        else if (newArray[i] != oldArray[i]) { 
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;   
        }           
    }       
    return true;
}

  public confirmDelete = () =>  {
    this.apiCallback = 6;
    this.securityAcction = {
      typeAction: 'DELETE',
      show: true,
    };
  }

  public actionRule = (param) => {
    this.apiCallback = 8;
    this.securityAcction = {
      typeAction: 'ACTION',
      show: true,
      ...param
    };
  }

  public securityRequest() {
    const obj = this.securityAcction;
    switch (obj.typeAction) {
      case 'EDIT':
        return this.requestEdit(obj);
        break;
      case 'DELETE' :
        return this.requestDeleteRule(this.ruleId);
        break;
      case 'ACTION':
        return this.requestStatusRule(obj.code, obj.id);
        break;
    }
  }

  public securityResponse(res): void {
    switch (this.apiCallback) {
      case 5 :
        this.responseEdit(res);
        break;
      case 6 :
        this.responseDelete(res);
        break;
      case 8 :
        this.responseStatus(res);
        break;
    }
  }

  public resetList = () => {
    this.securityAcction = {
      show: false,
    };
    this.list = [];
    this.addOperators = [];
    this.rules = [];
    this.people = [];
    this.operators = [];
    this.data = [];
    this.optionsCounter = [];
    this.countOptionsRule = [];
    this.accountGroup = [];
    this.checkAccounts = [];
    this.checkOperators = [];
    this.allAccounts = [];
    this.addAccArr = [];
    this.delAccArr = [];
    this.addOprArr = [];
    this.delOprArr = [];
  }
  public closeModal = () => {
    this.securityAcction.show = false;
    this.showAction = false;
    this.loading = false;
  }

  public checkLengthOperators = () => {
    return this.checkOperators.length <= 1 || this.addOperators.length < 2;
  }

  public checkDeleteOperators = () => {
    return (this.checkOperators.length > 1 && this.addOperators.length > 1);
  }
}
