import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ComexReportDataShare {
  public data: any;
  public dataReport;
  public currentAccount = undefined;

  constructor() {}

  setValue(data: any) {
   this.data = data;
  }

  getValue() {
      return this.data;
  }

  setReportInfo(data: any) {
    this.dataReport = data;
   }
 
  getReportInfo() {
       return this.dataReport;
   }
}
