import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataModule } from '@src/data/data.module';
import { CoreModule } from '@src/core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from '@src/presentation/web/app-routing.module';
import { AppComponent } from '@src/presentation/web/app.component';
import { HttpInterceptorService } from '@src/core/interceptors/http-interceptor.service';
import { InvestimentsReportComponent } from '@src/presentation/web/components/investiments-report/investiments-report.component';

// modules
import { SharedModule } from '@src/shared';
import { PresentationModule } from '@src/presentation/presentation.module';
import { InvestmentsComponent } from './pages/investments/investments.component';
import { InvestmentsTableComponent } from './pages/investments-table/investments-table.component';
import { InvestmentsExtratoTableComponent } from './pages/investments-extrato-table/investments-extrato-table.component';
import { TransactionApprovalBatchComponent } from './pages/transaction-approval-batch/transaction-approval-batch.component';
import { PaymentBatchCnab240ReviewComponent } from './payment-batch-cnab240-review/payment-batch-cnab240-review.component';
import { NoPermissionComponent } from './pages/no-permission/no-permission.component';
import { NoPermissionModule } from './pages/no-permission/no-permission.module';

@NgModule({
  declarations: [
    AppComponent,
    InvestmentsComponent,
    InvestimentsReportComponent,
    InvestmentsTableComponent,
    InvestmentsExtratoTableComponent,
    TransactionApprovalBatchComponent,
    PaymentBatchCnab240ReviewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    DataModule,
    PresentationModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NoPermissionModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
