import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import * as $ from 'jquery';
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { CpfCnpjValidator } from '@src/shared';
import { cepMask, cnpjMask, cpfMask } from '@src/shared/util-common';

import { GetDocumentCheckUsecase } from '@src/core/usecases/charge/get-documentTypes.usecase';
import { GetAddressTypeUsecase } from '@src/core/usecases/charge/get-addressTypes.usecase';
import { AddressTypeModel } from '@src/core/domain/charge/address.model';

import { stateList } from '@src/data/repository/data-share-repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

interface OptionsRadio {
  option: string;
  value: any;
}

@Component({
  selector: 'fibra-charge-first-step',
  templateUrl: './charge-first-step.component.html',
  styleUrls: ['./charge-first-step.component.scss'],
})
export class ChargeFirstStepComponent implements OnInit {
  public option: boolean;
  public docValid: boolean;
  public formStep1: FormGroup;
  public isPersonPhysical = true;
  public optionPerson: OptionsRadio[] = [];
  public optionDrawer: OptionsRadio[] = [];
  public cpfMask = cpfMask;
  public cnpjMask = cnpjMask;
  public cepMask = cepMask;
  public stateList = stateList;
  btnAvancar = true;

  // Form Validate
  public isCepValid = true;
  public currentCep: string;
  public verificaPessoa: string;
  public verificaPessoaDrawer: string;

  public loadingCPF = false;
  public loadingAddress = false;
  public invalidFormDrawer = false;

  @Input() guarantor = false;
  @Input() divisor: boolean;
  @Input() description: string;
  @Input() submit: boolean;
  @Output() sendDrawer: EventEmitter<any>;
  @Output() isPersonalValid = new EventEmitter()
  @Output() isAddressalValid = new EventEmitter()
  @Output() isDrawerValid = new EventEmitter()
  @Output() isDrawerAddressValid = new EventEmitter()
  @Output() alterarGuarantor = new EventEmitter()
  @Input() draweeNameEmpty = false;
  @Input() isDrawer;
  @Input() drawerStep: FormGroup;

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  private ShowToast(value: any): void {
    const isMessageServer: boolean = !!(value && value.code && value.message && value.message.length > 0);
    isMessageServer && this.toast && this.toast.callModalMessage(null, value.message[0], null, null, null);
  }

  public get personalForm() {
    return this.formStep1.get('personal');
  }

  public get addressForm() {

    return this.formStep1.get('address');
  }

  public get drawerForm() {
    return this.drawerStep.get('drawer');
  }

  public get drawerAddressForm() {

    return this.drawerStep.get('drawerAddress');
  }

  constructor(
    private formBuild: FormBuilder,
    private getAddress: GetAddressTypeUsecase,
    private getDocumentCheck: GetDocumentCheckUsecase
  ) {
    this.formStep1 = this.formBuild.group({
      personal: this.formBuild.group({
        personType: ['', [Validators.required]],
        cpfCnpj: [
          '',
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(20),
            CpfCnpjValidator,
          ],
        ],
        draweeName: ['', Validators.required],
      }),
      address: this.formBuild.group({
        cep: ['', [Validators.required, Validators.minLength(8)]],
        street: ['', Validators.required],
        number: ['', Validators.required],
        complement: [''],
        neighborhood: ['', Validators.required],
        city: ['', Validators.required],
        state: [''],
      }),
      guarantor: ['Não', Validators.required],
    });

    this.drawerStep = this.formBuild.group({
      drawer: this.formBuild.group({
        personType: ['F', [Validators.required]],
        cpfCnpj: [
          '',
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(20),
            CpfCnpjValidator,
          ],
        ],
        draweeName: ['', Validators.required],
      }),

      drawerAddress: this.formBuild.group({
        cep: ['', [Validators.required, Validators.minLength(8)]],
        street: ['', Validators.required],
        number: ['', Validators.required],
        complement: [''],
        neighborhood: ['', Validators.required],
        city: ['', Validators.required],
        state: [''],
      }),
    });

    this.divisor = true;
    this.description = 'Informações Pessoais';
    this.sendDrawer = new EventEmitter();
    this.initDrawerStep(localStorage.getItem('drawer_step_edit'));
    this.initStep(localStorage.getItem('first_step_edit'));
  }

  ngOnInit(): void {
    this.optionPerson = [
      { option: 'Pessoa Física', value: 'F' },
      { option: 'Pessoa Jurídica', value: 'J' },
    ];

    this.optionDrawer = [
      { option: 'Pessoa Física', value: 'F' },
      { option: 'Pessoa Jurídica', value: 'J' },
    ];

    let tpPessoa = this.formStep1.controls['personal'].value;
    if (tpPessoa.personType !== "" && tpPessoa.cpfCnpj !== "") this.setPersonPhysical(tpPessoa.personType, 'Edicao');
    else this.setPersonPhysical('F');

    tpPessoa = this.drawerStep.controls['drawer'].value;
    if (tpPessoa.personType !== "" && tpPessoa.cpfCnpj !== "") this.setDrawerPhysical(tpPessoa.personType, 'Edicao');
    else this.setDrawerPhysical('F');

    this.personalForm
      .get('cpfCnpj')
      .valueChanges.subscribe((cpfCnpj: string) => {
        const value = this.personalForm.get('cpfCnpj').value;
        if (cpfCnpj.length > 10) {
          this.setDraweeName(cpfCnpj);
        }
      });

    this.personalForm
      .get('cpfCnpj')
      .valueChanges.subscribe((cpfCnpj: string) => {
        if (!cpfCnpj) {
          this.clearDraweeName();
        }
      });

    this.drawerForm.get('cpfCnpj').valueChanges.subscribe((cpfCnpj: string) => {
      const value = this.drawerForm.get('cpfCnpj').value;
      if (cpfCnpj.length > 10) {
        this.setDraweeName(cpfCnpj);
      };
    });

    this.drawerForm.get('cpfCnpj').valueChanges.subscribe((cpfCnpj: string) => {
      if (!cpfCnpj) {
        this.clearDrawerDraweeName();
      }
    });

    this.drawerForm.valueChanges.subscribe(() => {
      this.verifyDrawerValid();
    });

    this.drawerAddressForm.valueChanges.subscribe(() => {
      this.verifyDrawerAdressValid();
    });

    this.configForm();
    this.formOnChange(this.isDrawer);
    this.verifyChanges();
  }

  @ViewChild('garantorValue', { static: true }) garantorValue: ElementRef;

  public formOnChange(value) {
    if (!value) {
      this.formStep1.valueChanges.subscribe((res) => {
        localStorage.setItem('first_step', JSON.stringify(res));
      });
    }
    if (value) {
      this.drawerStep.valueChanges.subscribe((res) => {
        localStorage.setItem('drawer_step', JSON.stringify(res));
      });
    }
  }

  public initDrawerStep(Storage) {
    if (Storage) {
      const editedStep = JSON.parse(Storage);
      this.drawerStepEdit(editedStep);
    }
  }

  public initStep(Storage) {
    if (Storage) {
      const editedStep = JSON.parse(Storage);
      this.stepEdit(editedStep);
    }
  }

  public stepEdit(data) {
    this.formStep1.get('personal').setValue(data.personal);
    this.setAddress(data.address.cep, data.address.number, data.address.complement, false)
    this.formStep1.get('guarantor').setValue(data.guarantor);
  }

  public drawerStepEdit(data) {
    if (data && data.drawer) {
      this.setGuarantor("Sim")
      this.drawerStep.get('drawer').setValue(data.drawer);
      this.drawerStep.get('drawerAddress').setValue(data.drawerAddress);
      this.setAddress(data.drawerAddress.cep, data.drawerAddress.number, data.drawerAddress.complement, true)
    }
  }

  public verifyChanges() {
    this.drawerForm.valueChanges.subscribe(() => {
      this.verifyDrawerValid();
    });

    this.drawerAddressForm.valueChanges.subscribe(() => {
      this.verifyDrawerAdressValid();
    });
  }

  public configForm() {
    /*  $('#draweeName').keypress((e) => {
      const keyCode = e.keyCode ? e.keyCode : e.which;
      if (keyCode > 47 && keyCode < 58) {
        e.preventDefault();
      }
    });  */
    $('#city').keypress((e) => {
      const keyCode = e.keyCode ? e.keyCode : e.which;
      if (keyCode > 47 && keyCode < 58) {
        e.preventDefault();
      }
    });
    /*   $('#number').keypress((e) => {
        const keyCode = e.keyCode ? e.keyCode : e.which;
        if (!(keyCode > 47 && keyCode < 58)) {
          e.preventDefault();
        }
      }); */
  }

  public setGuarantor(guarantor) {
    let bool;
    bool = guarantor === 'Sim';

    if (bool !== this.guarantor) {
      this.guarantor = bool;
      this.formStep1.patchValue({
        guarantor,
      });
    } else {
      this.guarantor = bool;
    }

    if (guarantor === 'Não') {
      localStorage.removeItem('drawer_step');
      localStorage.removeItem('drawer_step_edit');
    }
    this.alterarGuarantor.emit(guarantor);
  }

  public verifyDrawer(value) {
    if (value.toString() === 'Sim') {
      this.guarantor = true;
    }
  }

  public verifyDrawerValid() {
    this.sendDrawer.emit(this.personalForm.value);
    this.sendDrawer.emit(this.drawerForm.value);
  }

  public verifyDrawerAdressValid() {
    this.sendDrawer.emit(this.addressForm.value);
    this.sendDrawer.emit(this.drawerAddressForm.value);
  }

  public captureInfoDrawer(info, key) {
    // console.log(info, key)
    if (info && key) {
      this.drawerStep.patchValue({
        drawer: info,
      });
    }
  }

  public captureInfoDrawerAdress(info, key) {
    //  console.log(info, key)
    if (info && key) {
      this.drawerStep.patchValue({
        drawerAddress: info,
      });
    }
  }

  public setIsCpfCnpjValid(bool: boolean) {
    this.docValid = bool;
  }

  public setPersonPhysical(typePerson, controleLimparCampos?: string) {
    let bool;
    this.verificaPessoa = typePerson;

    if (controleLimparCampos !== 'Edicao' && controleLimparCampos !== undefined)
      this.clearDraweeName();

    if (typePerson === 'F') {
      bool = true;
      this.personalForm.patchValue({
        personType: typePerson,
      });
    } else {
      bool = false;
      this.personalForm.patchValue({
        personType: typePerson,
      });
    }
    if (bool !== this.isPersonPhysical) {
      this.isPersonPhysical = bool;
      this.personalForm.patchValue({
        personType: typePerson,
      });
    }

    this.btnAvancar = false;
  }

  public setDrawerPhysical(typePerson, controleLimparCampos?: string) {
    let bool;
    this.verificaPessoaDrawer = typePerson;

    if (controleLimparCampos !== 'Edicao' && controleLimparCampos !== undefined)
      this.clearDrawerDraweeName();

    if (typePerson === 'F') {
      bool = true;
      this.drawerForm.patchValue({
        personType: typePerson,
      });
    } else {
      bool = false;

      this.drawerForm.patchValue({
        personType: typePerson,
      });
    }
    if (bool !== this.isPersonPhysical) {
      this.isPersonPhysical = bool;
      this.drawerForm.patchValue({
        personType: typePerson,
      });
    }
    this.btnAvancar = false;
  }

  //#region Manage drawer methods forms
  public drawerControl(name: string) {
    this.isDrawerValid.emit(this.drawerForm.valid)
    return this.drawerForm.get(name);
  }

  public drawerControlIsInvalid(name: string) {
    const control = this.drawerControl(name);
    return control.invalid && control.touched;
  }
  public verifyDrawerControlValid(type: string, name: string) {
    const control =
      type === 'drawerAddress'
        ? this.addressDrawerControl(name)
        : this.drawerControl(name);
    return control && !control.invalid;
  }

  public drawerControlFirstError(name: string): any {
    const control = this.drawerControl(name);

    if (control.invalid && (control.touched || control.dirty)) {
      return this.getErrorsControl(control);
    }
  }

  //#region Manage personal methods forms
  public personalControl(name: string) {
    this.isPersonalValid.emit(this.personalForm.valid)
    return this.personalForm.get(name);

  }

  public personControlIsInvalid(name: string) {
    const control = this.personalControl(name);   
    return control.invalid && control.touched;
  }
  public verifyControlValid(type: string, name: string) {
    const control =
      type === 'address'
        ? this.addressControl(name)
        : this.personalControl(name);
    return control && !control.invalid;
  }

  public personalControlFirstError(name: string): any {
    const control = this.personalControl(name);

    if (control.invalid && (control.touched || control.dirty)) {
      return this.getErrorsControl(control);
    }
  }

  //#region

  //#region Manage address methods forms
  public addressControl(name: string) {
    this.isAddressalValid.emit(this.addressForm.valid)
    if (this.isDrawer) {
      return this.addressForm.get(name) && this.drawerAddressForm.get(name);
    }
    return this.addressForm.get(name);
  }

  public addressControlIsInvalid(name: string) {
    const control = this.addressControl(name);
    return control.invalid && (control.touched || control.dirty);
  }

  public addressControlFirstError(name: string): any {
    const control = this.addressControl(name);
    return this.getErrorsControl(control);
  }

  //#region Manage address drawer methods forms
  public addressDrawerControl(name: string) {
    this.isDrawerAddressValid.emit(this.drawerAddressForm.valid)
    if (this.isDrawer) {
      return this.addressForm.get(name) && this.drawerAddressForm.get(name);
    }
    return this.drawerAddressForm.get(name);
  }

  public addressDrawerControlIsInvalid(name: string) {
    const control = this.addressDrawerControl(name);

    this.drawerAddressForm.get('number').setValidators([Validators.required, Validators.pattern(/^[0-9SsNn\b/]+$/)]);
    this.drawerAddressForm.get('number').updateValueAndValidity();

    return control.invalid && (control.touched || control.dirty);
  }

  public addressDrawerControlFirstError(name: string): any {
    const control = this.addressDrawerControl(name);
    return this.getErrorsControl(control);
  }
  //#region

  public guarantorControl(name: string) {
    return this.formStep1.get(name);
  }

  setAddress(cep: string, number?: string, complement?: string, avalista?: boolean) {
    // Replace "_" to ""
    const requestCep: string = cep.replace(/[.\-]/g, '');
    this.currentCep = "";

    // Service Object
    const obj: any = {
      cep: requestCep,
    };

    // Test if diferent CEP
    this.loadingAddress = true;
    if (this.currentCep !== requestCep) {
      // Set current CEP
      this.currentCep = requestCep;

      // Expected format 00000-000
      if (requestCep.length >= 8) {
        this.isCepValid = true;

        // Call service
        this.getAddress.execute(obj).subscribe(
          (value: AddressTypeModel) => {
            if (avalista !== undefined) {
              let verificaAvalista: boolean
              if (avalista) verificaAvalista = true;
              else verificaAvalista = false;

              if (this.isDrawer === avalista) verificaAvalista = this.isDrawer

              if (value.data.cep === requestCep && verificaAvalista) {
                this.drawerAddressForm.patchValue(
                  {
                    street: value.data.street,
                    neighborhood: value.data.neighborhood,
                    city: value.data.city,
                    state: value.data.uf,
                    number: number,
                    complement: complement
                  },
                  { eventEmitter: false }
                );
                this.loadingAddress = false;
                return;
              } else {
                // Clear all values of Address
                this.resetStep1();

                this.addressForm.patchValue(
                  {
                    cep: value.data.cep,
                    street: value.data.street,
                    neighborhood: value.data.neighborhood,
                    city: value.data.city,
                    state: value.data.uf,
                    number: number,
                    complement: complement
                  },
                  { eventEmitter: false }
                );
              }
              this.loadingAddress = false;

            } else if (value.data.cep === requestCep && this.isDrawer) {
              this.drawerAddressForm.patchValue(
                {
                  street: value.data.street,
                  neighborhood: value.data.neighborhood,
                  city: value.data.city,
                  state: value.data.uf,
                  number: number,
                  complement: complement
                },
                { eventEmitter: false }
              );
              this.loadingAddress = false;
              return;
            } else {
              // Clear all values of Address
              this.resetStep1();

              this.addressForm.patchValue(
                {
                  cep: value.data.cep,
                  street: value.data.street,
                  neighborhood: value.data.neighborhood,
                  city: value.data.city,
                  state: value.data.uf,
                  number: number,
                  complement: complement
                },
                { eventEmitter: false }
              );
            }
            this.loadingAddress = false;
          },
          (err) => {
            this.ShowToast(err);
            this.addressForm.patchValue(
              {
                street: '',
                neighborhood: '',
                city: '',
                state: '-',
                number: ''
              },
              { eventEmitter: false }
            );
            this.isCepValid = false;
            this.loadingAddress = false;
          }
        );
      } else {
        this.resetStep1();

        this.addressForm.patchValue({
          cep: requestCep,
        });

        this.isCepValid = false;
        this.loadingAddress = false;
      }
    }
  }

  private formatDocument(doc) {
    return this.isPersonPhysical
      ? doc.replace(/[.\-]/g, '')
      : doc.replace(/[^\d]+/g, '');
  }

  setDraweeName(cpfCnpj: string) {
    const requestDoc = this.formatDocument(cpfCnpj);
    const obj: any = {
      documento: requestDoc,
    };
    if (
      ((this.isPersonPhysical) && requestDoc.length < 11) ||
      ((!this.isPersonPhysical) && requestDoc.length < 14)
    ) {
      return;
    }

    this.loadingCPF = true;

    this.getDocumentCheck.execute(obj).subscribe(
      (value) => {
        if (value.data.draweeName && this.isDrawer) {
          this.drawerStep.patchValue({
            draweeName: value.data.draweeName,
          });
          this.setIsCpfCnpjValid(true);
        }

        if (value.data.draweeName) {
          this.personalForm.patchValue({
            draweeName: value.data.draweeName,
          });
          this.setIsCpfCnpjValid(true);
        }
      },
      (error) => {
        this.ShowToast(error);
        console.log(error);
        this.loadingCPF = false;
      },
      () => (this.loadingCPF = false)
    );
  }

  clearDrawerDraweeName() {
    this.drawerForm.patchValue({
      cpfCnpj: '',
      draweeName: '',
    }, {
      emitEvent: false
    });
  }

  clearDraweeName() {
    this.personalForm.patchValue({
      cpfCnpj: '',
      draweeName: '',
    });
  }

  clearDrawerAddress() {
    this.drawerForm.patchValue({
      cep: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
    });
  }

  clearAddress() {
    this.addressForm.patchValue({
      cep: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
    });
  }

  disabledAvalista() {
    if (!this.addressForm.valid || !this.personalForm.valid || this.btnAvancar === true || !this.drawerForm.valid || !this.drawerAddressForm.valid) {

      return true
    }
    return false
  }

  resetStep1() {
    this.isCepValid = true;
    this.addressForm.reset();
  }

  private getErrorsControl(control: AbstractControl) {
    const list =
      control.errors !== null ? Object.getOwnPropertyNames(control.errors) : [];
    if (list.length > 1) {
      const error = {};
      error[list[0]] = control.errors[list[0]];
      return error;
    }
    return control.errors || false;
  }
}