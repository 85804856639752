import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'fibra-transfer-approval-detail',
  templateUrl: './transfer-approval-detail.component.html',
  styleUrls: ['./transfer-approval-detail.component.scss'],
})
export class TransferApprovalDetailComponent implements OnChanges, OnInit {
  @Input() data: any;
  @Input() index;
  @Input() loading;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  public refusedOrApproved = (flag): boolean => {
    return flag !== 'RECUSADO' && flag !== 'APROVADO';
  }

  public benefitType = (benefit, account) => {
    if (!benefit || !account) {
      return;
    }

    if (benefit.num_cpf_cnpj === account.num_cpf_cnpj) {
      return 'Mesma titularidade';
    }
    return 'Outra titularidade';
  }

  public showPaymentDate = (status) => {
    return (status !== 'AGENDADO' && status !== 'PENDENTE');
  }

}
