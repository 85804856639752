import { Component, Input, OnInit } from '@angular/core';
import { getOrderedDataAccount, getOrderedTypeAccount } from '@src/shared/util-common';
import { GetAllAccountTypeUsecase } from '@src/core/usecases/account/get-all-accountTypes.usecase';
import { AccountDataShare } from '@src/data/repository/data-share-repository';
@Component({
  selector: 'fibra-list-balance-extract',
  templateUrl: './balance-list.component.html',
  styleUrls: ['./balance-list.component.scss'],
})
export class BalanceExtractListComponent implements OnInit {
  @Input() data = [];
  @Input() showData;
  public loading: boolean;
  public options;
  public profileData;
  public getOrderedDataAccount: any;
  public getOrderedTypeAccount: any;
  public hide: boolean;
  constructor(
    private shareAccount: AccountDataShare,
    private getAllAccountTypes: GetAllAccountTypeUsecase,
  ) { }

  ngOnInit(): void {
    this.requestAccount();
  }

  toogleIconArrow(index) {
    document.getElementById(index).classList.toggle('img-arrow');
  }

  private requestAccount(): void {
    this.loading = true;
    this.getAllAccountTypes
      .execute(null)
      .subscribe(
        this.requestAccountSuccess,
        this.requestAccountError,
        this.requestComplete
      );
  }

  private requestAccountError = (err) => {
    this.hide = true;
    this.loading = false;
    console.warn(err);
  }

  private requestAccountSuccess = (value) => {
    this.returnData(value);
  }

  private requestComplete = () => this.loading = false;

  private returnData(value): void {
    value.data.map((e, i) => {
      e.mostrar_bloqueado = (e.desc_conta.toUpperCase() === 'CHEQUE EMPRESA');
    });
    this.profileData = value.data || [];
    this.shareAccount.setValue(value.data);
    this.hide = (value.data.length === 0);
  }

  sortList(type) {
    switch (type) {
      case 'Conta':
        return (this.getOrderedTypeAccount = getOrderedTypeAccount(this.profileData));
      case 'Dados da conta':
        return (this.getOrderedDataAccount = getOrderedDataAccount(this.profileData));
    }
  }

}


