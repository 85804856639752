<div>
  <div class="card-list" id="box">
    <fibra-card-value
      *ngIf="balances?.block_value !== 0"
      titleCard="Saldo em conta"
      valueType="number"
      [value]="balances?.balance_value + balances?.block_value || 0"
      hasToggleVisible="true"
      backgroundColor="accent"
    >
    </fibra-card-value>
    <fibra-card-value
      *ngIf="balances?.cheque_value <= 0 || balances?.limit_value <= 0"
      [titleCard]="'Saldo disponível' + (balances?.exibicao == 'CONTA GARANTIDA' ? '*' : '')"
      valueType="number"
      [value]="balances?.balance_value || 0"
      hasToggleVisible="true"
      backgroundColor="accent"
      [warn]="balances?.exibicao == 'CONTA GARANTIDA' ? '*Mediante análise de crédito e/ou constituição de garantia' : null"
    >
    </fibra-card-value>
    <fibra-card-value-cheque
      *ngIf="balances?.cheque_value > 0 && balances?.limit_value > 0"
      titleCard="Saldo disponível"
      valueType="number"
      [value]="balances?.balance_value"
      [valueCheque]="balances?.cheque_value"
      hasToggleVisible="true"
      backgroundColor="accent"
      [exibicao]="balances?.exibicao"
    ></fibra-card-value-cheque>
    <ng-container>
      <fibra-card-value-two-rows
        *ngIf="(balances?.limit_value> 0) && (balances?.cheque_used > 0 || balances?.cheque_available > 0)"
        [titleCard]="toTitleCase(balances?.exibicao)"
        subtitle1Card="Utilizado"
        subtitle2Card="Disponível"
        valueType="number"
        [value1]="balances?.limit_value > 0 ? balances?.cheque_used : 0"
        [value2]="balances?.limit_value > 0 ? balances?.cheque_available : 0"
        hasToggleVisible="true"
      ></fibra-card-value-two-rows>
      <fibra-card-value-two-rows
        id="limiteContaGarantida"
        *ngIf="balances?.garantida_limite > 0"
        titleCard="Limite"
        subtitle1Card="Contratado"
        subtitle2Card="Utilizado"
        valueType="number"
        [value1]="balances?.garantida_limite"
        [value2]="balances?.garantida_utilizado"
        hasToggleVisible="true"
      ></fibra-card-value-two-rows>
      <div class="encargos">
        <fibra-card-value
          id="encargosContaGarantida"
          *ngIf="balances?.exibicao == 'CONTA GARANTIDA'"
          titleCard="Previsão de encargos até {{ today | date:'dd/MM/yyyy' }}*"
          valueType="number"
          [value]="(balances?.garantida_encargos * -1) || 0"
          hasToggleVisible="true"
          warn="*O débito dos encargos ocorre no 1º dia útil de cada mês."
        >
        </fibra-card-value>
      </div>      
      <!--<fibra-card-value
        *ngIf="balances?.cheque_used > 0"
        titleCard="Cheque empresa utilizado"
        valueType="number"
        [value]="balances?.cheque_used || 0"
        id="chequeEmpresaUtilizado"
      ></fibra-card-value>
      <fibra-card-value
        *ngIf="balances?.cheque_available > 0"
        titleCard="Cheque empresa disponível"
        valueType="number"
        [value]="balances?.cheque_available || 0"
        id="chequeEmpresaDisponivel"
      ></fibra-card-value>-->
      <fibra-card-value
        *ngIf="balances?.block_value !== 0"
        titleCard="Saldo bloqueado*"
        valueType="number"
        [value]="balances?.block_value || 0"
        warn="*Entre em contato com o nosso atendimento"
        id="saldoBloqueado"
      ></fibra-card-value>
    </ng-container>
  </div>
  <div class="arrow" *ngIf="qtdCards > 2">
    <div>
      <img
        (click)="scrollBy('left')"
        class="margin-icon"
        src="/assets/svg/arrow-left.svg"
        alt=""
      />
    </div>
    <div>
      <img
        (click)="scrollBy('right')"
        src="/assets/svg/arrow-rigth.svg"
        alt=""
      />
    </div>
  </div>
</div>
