export const pequenasEmpresasCapitalGiro = ['2124354000173',
'96217336000180',
'13861389000101',
'12921394000108',
'11502665000110',
'1516770000154',
'2387834000127',
'1506884000113',
'13615449000106',
'7206858000156',
'13085979000190',
'10989053000130',
'2344455000150',
'7900000160',
'1971326000129',
'10623187000133',
'10761441000160',
'10824099000108',
'10828365000162',
'10840442000108',
'11625490000138',
'12966634000182',
'12971013000197',
'13084468000153',
'13708810000149',
'13731601000116',
'13913191000124',
'14474343000100',
'24513701000192',
'10279367000140',
'7270545000167',
'34099932000155',
'18782723000155',
'31145120000100',
'380828000112',
'10779998000128',
'11052285000120',
'11578645000122',
'28906000114',
'10281824000130',
'13838799000131',
'10395610000195',
'3307193000116',
'13409993000100',
'1284630000106',
'11271758000180',
'12466674000165',
'11760617000121',
'11423812000166',
'10526349000115',
'13737054000186',
'1610288000189',
'12593290000103',
'11596211000155',
'1528534000158',
'13004618000171',
'11847807000180',
'10882872000184',
'12855223000110',
'1783788000112',
'10836901000171',
'192426000194',
'3425715000184',
'1302616000180',
'11714389000153',
'10306453000102',
'1519952000189',
'1837030000110',
'3508825000100',
'13910055000180',
'12576072000160',
'11000626000114',
'13960340000106',
'11731860000111',
'10227937000158',
'12801355000169',
'11753741000160',
'10725956000104',
'14239778000162',
'1430372000110',
'4322785000170',
'5190146000160',
'13429977000170',
'10337216000109',
'1492182000128',
'3094476000127',
'12791157000161',
'11165166000184',
'14584586000193',
'11088859000110',
'12138246000103',
'10980064000150',
'1298206000102',
'11381221000173',
'12887210000122',
'10778270000181',
'10958546000103',
'14453029000133',
'10965561000189',
'15079192000140',
'14760080000198',
'14835878000151',
'10276622000109',
'14200001000194',
'12609359000140',
'12917956000131',
'12832964000185',
'11998373000110',
'14601436000140',
'21309313000104',
'30655525000126']