<div class="newtransaction">
    <div class="row">
        <div class="col-sm-12">
            <section *ngIf="!disabled" class="origin">
                <span style="color: #8F969D;">Para que possamos concluir o pagamento, iremos lhe redirecionar para a instituição: </span><span style="font-weight: bold;">{{consent.client_info.client_name}}</span>
            </section>

            <section>
                <h1 class="group-header">Valor da transação</h1>

                <label *ngIf="!disabled" class="value">{{currencyStr}} {{consent.payment.amount | number: '1.2-2'}}</label>
                <label *ngIf="disabled" class="value">***********************</label>
            </section>

            <section>
                <h1 class="group-header">Dados do recebedor</h1>

                <div class="caption">Beneficiário</div>    
                <label *ngIf="!disabled" class="value">{{consent.creditor.name}}</label>
                <label *ngIf="disabled" class="value">**********************************************</label>
                
                <div class="caption">{{consent.creditor.personType == 'PESSOA_NATURAL' ? 'CPF' : 'CNPJ'}}</div>    
                <label *ngIf="!disabled" class="value">{{cpfCnpjCreditor}}</label>
                <label *ngIf="disabled" class="value">***********************</label>        
            </section>

            <section>
                <h1 class="group-header">Dados do pagamento</h1>

                <div class="caption">Data de pagamento</div>    
                <label *ngIf="!disabled" class="value">{{consent.payment.date | date: 'dd/MM/yyyy'}}</label>
                <label *ngIf="disabled" class="value">***********************</label>
                
                <div class="caption">Forma do pagamento</div>    
                <label *ngIf="!disabled" class="value">{{paymentTypeStr}}</label>
                <label *ngIf="disabled" class="value">***********************</label>
            </section>

            <section *ngIf="!disabled">
                <div class="row">
                    <div class="col-sm-12">
                        <h1 class="group-header">Conta para débito</h1>
                    </div>
                </div>
                <fibra-slide-account-selector *ngIf="!loadingAccounts" [data]="accounts.items" [active]="active" [showButton]="showButton" (emitAccount)="selectAccount($event)"></fibra-slide-account-selector>
                <div *ngIf="loadingAccounts"  class="d-flex justify-content-center position loading-container">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </section>

            <section *ngIf="!disabled" class="importante">
                <span class="importante">Importante:</span><span> ao confirmar o pagamento você será redirecionado para a Instituição Iniciadora de Pagamento.</span>
            </section>
        </div>        
    </div>
    <div *ngIf="!disabled" class="row buttons">
        <div class="col-sm-12">
            <button type="button" class="continue" [class.busy]="busy" [disabled]="!(accounts?.items.length > 0) || selectedAccount == null" (click)="ok()">{{busy ? '' : 'Continuar'}}
                <div *ngIf="busy" class="spinner-border continue" role="status"></div>
            </button>
            <button type="button" class="cancel" (click)="cancel()">
                Cancelar operação
            </button>
        </div>
    </div>
</div>
<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
<fibra-modal-cheque-empresa-pix *ngIf="chequeEmpresaPix" (closeEvent)="closeModalPix($event)" [valor]="valorChequeEmpresa"></fibra-modal-cheque-empresa-pix>