import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import * as models from '@src/data/repository/data-share-repository';
import { SelectorDataShare, ShowValuesDashboardDataShare } from '@src/data/repository/data-share-repository';
import { AccountDataShare } from '@src/core/usecases/data-share/account-data-share.service';
import { TabsDataShare } from '@src/data/repository/data-share-repository';
import * as Util from '@src/shared/util-common';
import { ExtractDataRepository } from '@src/data/repository/extract-repository/extract.repository';
import { ExtractRequest } from '@src/core/domain/extract/extract.model';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';
import { RolesService } from '@src/shared/services/roles.service';
import { GetNewTokenExtractUsecase } from '@src/core/usecases/token/get-new-token-extract-usecase';
import { TokenTypeModel } from '@src/core/domain/token/tokenType.model';
import { ListComponent } from '@src/presentation/web/components/lists/list.component';
// import { JsonFormatter } from 'tslint/lib/formatters';

@Component({
  selector: 'fibra-page-extract',
  templateUrl: './extract.component.html',
  styleUrls: ['./extract.component.scss'],
})
export class ExtractComponent implements AfterViewInit {
  private _showData = false;
  public loading: boolean = true;
  public token = undefined;
  public valueBlocked: boolean;
  public balance;
  public optionsSideCard;
  public options;
  public routAccont: string;
  public selectedTab: string;
  public report =false;

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  @ViewChild(ListComponent, null) list: ListComponent;

  constructor(
    private tabsDataShared: TabsDataShare,
    private authService: MsalService,
    private shareAccount: AccountDataShare,
    private shareSelector: SelectorDataShare,
    private extractRepository: ExtractDataRepository,
    private rolesService: RolesService,
    private getNewTokenExtract: GetNewTokenExtractUsecase,
    private _shareShowValues: ShowValuesDashboardDataShare
  ) {
    this.optionsSideCard = models.optionsSideCard[0];
  }

  ngOnInit() {
    this._shareShowValues.dataShared.subscribe( res => {
      this._showData = res &&  res.iconEyesOpen
    });

    this.init();
  }

  ngAfterViewInit() {
  }

  private init = () => {
    if (this.rolesService.get().includes('EXT.CONS_EXT_SAL') && !this.rolesService.get().includes('MASTER')) {
      this.getNewTokenExtract.execute().pipe(finalize(() => this.loading = false)).subscribe((response: TokenTypeModel) => {
        sessionStorage.setItem('x-ibpj-session', response.data.sessionToken);
      });
    } else {
      this.loading = false;
    }

    this.options = models.optionExtract;
    this.valueBlocked = true;
    this.reviewToken();
    this.shareSelector.setValue('extract');
    this.shareAccount.selectedAccountShared.subscribe((account) => {
      account !== undefined
          ? (this.routAccont = `Extrato / ${account}`)
          : (this.routAccont = ' ');
    });
    this.tabsDataShared.dataShared.subscribe((res) => {
      this.selectedTab = (res && res.typeList) || 'extract-list-all';
    });

    var erroAprovacao = sessionStorage.getItem('erroAprovacao');

    if (erroAprovacao) {
      this.toast.callModalMessage(null, erroAprovacao, '', null, true);
      sessionStorage.removeItem('erroAprovacao');
    }
  }

  private reviewToken() {
    this.token = sessionStorage.getItem('accessToken');
    if (!this.token) {
      this.authService.logout();
    }
  }

  public exportScreen(value) {

    if (value === 1) {      
      this.exportPDF();
    } else {
      this.exportExcel()
    }
  }

  public exportPDF() {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    };

    const locale = 'pt-br';
    let dateReport = new Date().toLocaleDateString(locale, options);

    let filter = JSON.parse(sessionStorage.getItem('extract_excel'));

    const params: ExtractRequest = {
      dataInicio: filter.dataInicio,
      dataFinal: filter.dataFinal,
      tipo: filter.tipo,
      showAmmouts: this._showData ? 'true' : 'false',
      periodShow: this.list.periodShow ? 'true' : 'false'
    };

    let errorMessage = 'Erro ao tentar fazer download do arquivo, tente mais tarde.';

    this.extractRepository.getPDF(params).subscribe(
      res => {
        Util.handleFile(res, `Extrato-${dateReport}`, 'pdf');
      }, 
      () => {
        alert(errorMessage)
      });
  }

  public exportExcel() {

      const options = {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    }
      const locale = 'pt-br'
      let dateReport = new Date().toLocaleDateString(locale, options)
      
    
      //let filter = sessionStorage.getItem('extract_excel')
      let filter = JSON.parse(sessionStorage.getItem('extract_excel'));
      const param: ExtractRequest = {
        dataInicio: filter.dataInicio,
        dataFinal: filter.dataFinal,
      }
      let errorMessage='Erro ao tentar fazer download do arquivo, tente mais tarde.'
      switch (filter.tipo) {
        case 'tudo':
          this.extractRepository.postExportExcelTotal(param).subscribe((res) => {
            Util.consoleDev('res', res)
            Util.handleFile(res, `Extrato-${dateReport}`);
          }, error => {
            alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;

        case 'saida':
          this.extractRepository.postExportExcelSaida(param).subscribe((res) => {
            Util.handleFile(res, `Extrato-${dateReport}`);
          }, error => {
            alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;
        case 'entrada':
          this.extractRepository.postExportExcelEntrada(param).subscribe((res) => {
            Util.handleFile(res, `Extrato-${dateReport}`);
          }, error => {
            alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;

        case 'agendadas':
          this.extractRepository.postExportExcelAgendadas(param).subscribe((res) => {
            Util.handleFile(res, `Extrato-${dateReport}`);
          }, error => {
            alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;


        default:
          this.extractRepository.postExportExcelTotal(param).subscribe((res) => {
            Util.handleFile(res, `Extrato-${dateReport}`);
          }, error => {
            alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;
      }
  }

  public changeReportView(event) {
    this.report = !this.report;
  }

}
