import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription, timer } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { OpenBankingTransactionStatusEnum } from '@src/core/domain/openbanking/openbanking.transaction.status.enum';
import { IAcceptDoc } from '@src/data/repository/accept-doc/accept-doc.entity';
import { OpenBankingRepository } from '@src/data/repository/openbanking/openbanking.repository';
import { AcceptDocPageComponent } from '../../components/accept-doc/accept-doc.component';
import { AlertComponent } from '../../components/alert/alert.component';
import { PixAccountEntity } from '@src/data/repository/pix/accounts/pix-accounts-entity';
import { IOpenBankingTransaction } from '@src/core/domain/openbanking/openbanking.transaction.model';
import { RolesService } from '@src/shared/services/roles.service';
import { OpenbankingPaymentRequestNewTransactionComponent } from './newtransaction/openbanking-paymentrequest-newtransaction.component';
import { OpenbankingPaymentRequestConfirmTransactionComponent } from './confirmtransaction/openbanking-paymentrequest-confirmtransaction.component';
import { OpenbankingOnboardingComponent } from '../../components/openbanking-onboarding/openbanking-onboarding.component';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';

@Component({
    selector: 'fibra-openbanking-paymentrequest',
    templateUrl: './openbanking-paymentrequest.component.html',
    styleUrls: ['./openbanking-paymentrequest.component.scss']
})
export class OpenbankingPaymentRequestComponent implements OnInit, OnDestroy {

    private _sub: Subscription;
    private _subCancel: Subscription;
    
    public status: OpenBankingTransactionStatusEnum = OpenBankingTransactionStatusEnum.InProgress;
    public session: { login_id: string, login_state: string, data: any };
    public transaction: IOpenBankingTransaction;
    public transactionWasSuccessful: boolean;
    public accounts: { items: PixAccountEntity[] } = { items: [] };

    public readonly ONBOARDING = 0;
    public readonly TERMSOFUSE = 1;
    public readonly NEWTRANSACTION = 2;
    public readonly CONFIRMTRANSACTION = 3;
    public readonly REDIRECT = 4;
    
    public currentPresentation: number;

    public get title(): string | null {
        switch (this.currentPresentation) {
            case this.ONBOARDING: return "Onboarding";
            case this.TERMSOFUSE: return "Termo de Uso"
            case this.NEWTRANSACTION: return "Nova transação";
            case this.CONFIRMTRANSACTION: return "Confirmação de solicitação de pagamento";
            default: return "Nova transação";
        }
    }

    public termsOfUse: IAcceptDoc = {
        docTitle: 'Termo de uso',
        docUrl: 'term-user-openbanking',
        buttonTittle: 'Aderir',
        checkTitle: 'Li e concordo com o Termo de Uso.',
        readOnly: false
    };

    public get self() {
        return this;
    }    

    public securityValidationDialogVisible: boolean = false;

    public get apiPayload(): any {
        return {
            num_canal_pagamento: 3, 
            valor: this.transaction.consentimento.payment.amount,
            data_transferencia: this.transaction.consentimento.payment.date,
            descricao: `Transação Openbaking`,
            conta_origem: {
                agencia_conta: this.transaction.consentimento.selectedAccount.cod_agencia,
                num_conta: this.transaction.consentimento.selectedAccount.num_conta,
            },
            conta_destino: {
                cod_banco: this.transaction.consentimento.payment.details.creditorAccount.ispb,
                agencia_conta: this.transaction.consentimento.payment.details.creditorAccount.issuer,
                num_conta: this.transaction.consentimento.payment.details.creditorAccount.number,
                cpf_cnpj: this.transaction.consentimento.creditor.cpfCnpj.replace(/[^\d]+/g, ''),
                nome: this.transaction.consentimento.creditor.name,
            },
            login_id: this.session.login_id,
            login_state: this.session.login_state,
            consent_id: this.transaction.consentimento.consentId
        };
    }

    //#region alert
    
    public alertVisible: boolean = false;
    public alertShowIcon: boolean = true;
    public alertPrimaryMsg: string;
    public alertSecondaryMsg: string;
    public alertPrimaryAction: { caption: string, action: () => void };
    public alertSecondaryAction: { caption: string, action: () => void };

    //#endregion

    //#region error
    
    public errorVisible: boolean = false;
    public errorShowIcon: boolean = true;
    public errorPrimaryMsg: string;
    public errorSecondaryMsg: string;
    public errorAction: { caption: string, action: () => void };
    public errorIcon: string;

    //#endregion

    //#region cancelation dialog

    public cancelationDialogVisible: boolean = false;

    //#endregion

    private _toast: ToastErrorMessageComponent;
    @ViewChild(ToastErrorMessageComponent, null) public set toast(value: ToastErrorMessageComponent) {
        this._toast = value;
    }

    private _onBoardingComponent: OpenbankingOnboardingComponent;
    @ViewChild(OpenbankingOnboardingComponent, null) public set onBoardingComponent(value: OpenbankingOnboardingComponent) {
        this._onBoardingComponent = value;
    }

    private _acceptDocPageComponent: AcceptDocPageComponent;
    @ViewChild(AcceptDocPageComponent, null) public set acceptDocPageComponent(value: AcceptDocPageComponent) {
        this._acceptDocPageComponent = value;
    }

    private _newTransPageComponent: OpenbankingPaymentRequestNewTransactionComponent;
    @ViewChild(OpenbankingPaymentRequestNewTransactionComponent, null) public set newTransPageComponent(value: OpenbankingPaymentRequestNewTransactionComponent) {
        this._newTransPageComponent = value;
    }

    private _confirmPaymentPageComponent: OpenbankingPaymentRequestConfirmTransactionComponent;
    @ViewChild(OpenbankingPaymentRequestConfirmTransactionComponent, null) public set confirmPaymentPageComponent(value: OpenbankingPaymentRequestConfirmTransactionComponent) {
        this._confirmPaymentPageComponent = value;
    }

    private _alertDialog: AlertComponent;
    @ViewChild('alert', null) public set alertDialog(value: AlertComponent) {
        this._alertDialog = value;
    }

    private _errorDialog: AlertComponent;
    @ViewChild('error', null) public set errorDialog(value: AlertComponent) {
        this._errorDialog = value;
    }

    private _cancelOperationDialog: AlertComponent;
    @ViewChild('cancel', null) public set cancelOperationDialog(value: AlertComponent) {
        this._cancelOperationDialog = value;
    }

    constructor(private _repository: OpenBankingRepository, private _router: Router, private _rolesService: RolesService) {

    }

    private gotoNewTransactionPage(): void {
        this.currentPresentation = this.NEWTRANSACTION;
        this.showWarning('Tempo limite para a transação', 
            '<span>Esta operação tem um limite de até <strong>5 minutos</strong> para ser confirmada. Caso contrário, a operação será cancelada</span>', 
            { caption: 'Continuar com o acesso', action: () => { 
                this.alertVisible = false 
            } }, 
            { caption: 'Cancelar operação', action: () => this.showCancelationDialog() });
    }

    public okOnboarding(): void {
        if (!this.transaction.termodeuso) {
            this.currentPresentation = this.TERMSOFUSE;
        } else {
            const done: Subject<boolean> = new Subject<boolean>();            

            this._repository.setUserDoneOnboarding()
                .pipe(finalize(() => {
                    this._onBoardingComponent.busy = false;
                    done.next();
                    done.complete();
                }))
                .subscribe(() => this.gotoNewTransactionPage(), err => done.error(err));

            timer(100).pipe(takeUntil(done)).subscribe(() => {
                this._onBoardingComponent.busy = true;
            });
        }
    }

    public acceptTermsOfUse(): void {
        const done: Subject<boolean> = new Subject<boolean>();

        this._repository.setUserDoneOnboardingAndTermsOfUse()
            .pipe(finalize(() => {
                this._acceptDocPageComponent.showSpinner = false;
                done.next();
                done.complete();
            }))
            .subscribe(() => this.gotoNewTransactionPage(), err => done.error(err));

        timer(100).pipe(takeUntil(done)).subscribe(() => {
            this._acceptDocPageComponent.showSpinner = true;
        });
    }

    public backToOnboarding(): void {
        this.currentPresentation = this.ONBOARDING;
    }

    public okNewTransaction(selectedAccount: PixAccountEntity): void {

        const nextPage = () => {
            this.transaction.consentimento.selectedAccount = selectedAccount;
            this.currentPresentation = this.CONFIRMTRANSACTION;
            this.goToTop();
        }

        if (this.transaction.consentimento.debtorAccount != null) {
            nextPage();
        } else {
            this._newTransPageComponent.busy = true;

            this._repository.validateAccount(selectedAccount.num_indice, this.transaction.consentimento.payment.amount)
                .pipe(finalize(() => this._newTransPageComponent.busy = false))
                .subscribe(result => {
                    if (!result.pode_aprovar) {
                        this.showWarning('Você não está autorizado a fazer essa operação', 
                            '<span>Para ter acesso a essa funcionalidade, solicite a liberação ao(s) usuário(s) master(s) da sua empresa.</span>', 
                            { caption: 'Voltar', action: () => this.alertVisible = false });
                    // } else if (!result.possui_termo_aceite_pix) {
                    //     this.showError('Não foi possível prosseguir com a solicitação do pagamento.', 
                    //         'Para prosseguir, é necessário que a conta escolhida para o pagamento tenha aderido ao Pix.',
                    //         { caption: 'Voltar', action: () => this.errorVisible = false });
                    } else {
                        this.transaction.unico_aprovador = result.unico_aprovador;
                        nextPage();
                    }
                });
        }
    }

    public backToNewTransaction(): void {
        this.currentPresentation = this.NEWTRANSACTION;
        this.goToTop();
    }

    //#region Cancel Operation Dialog

    public doCancelTransaction = (dialog: AlertComponent) => {
        if (this._subCancel) {
            this._subCancel.unsubscribe();
        }

        if (dialog) {
            dialog.busy = true;
        }

        this._subCancel = this._repository.cancelTransaction(this.transaction.consentimento.consentId, this.session.login_state)
            .pipe(finalize(() => {
                if (dialog) {
                    dialog.busy = false;
                }                
            }))
            .subscribe(result => {                
                if (result.timeout) {
                    this.alertVisible = false;
                    this.cancelationDialogVisible = false;
                    this.showError(
                        'Tempo limite para transação expirado', 
                        '<span>Sua transação não foi realizada.<br>O tempo para realizar a solicitação do pagamento expirou.</span>', 
                        { caption: 'Sair', action: () => this.redirectToDashboard() }, 
                        'alert');
                } else {
                    this.status = OpenBankingTransactionStatusEnum.Canceled;                    
                    this.transaction.consentimento.client_info.client_uri = result.redirect_to;
                    this.redirect(false);
                }                
            });
    }

    private redirect(success: boolean) {
        this._rolesService.clearOpenFinanceSession();
        this.cancelationDialogVisible = false;
        this.alertVisible = false;
        this.errorVisible = false;
        this.transactionWasSuccessful = success;
        this.currentPresentation = this.REDIRECT;
    }

    private redirectToDashboard() {
        this._rolesService.clearOpenFinanceSession();
        this.cancelationDialogVisible = false;
        this.alertVisible = false;
        this.status = OpenBankingTransactionStatusEnum.Canceled;
        this._router.navigate(['dashboard']);
    }

    public showCancelationDialog(): void {
        this.cancelationDialogVisible = true;
    }

    public cancelationConfirmed(): void {
        this.doCancelTransaction(this._cancelOperationDialog);
    }

    public cancelationCanceled(): void {
        this.cancelationDialogVisible = false;
    }

    //#endregion

    public confirmPayment(): void { 

        let primaryMsg = `<span class="header">Deseja confirmar a solicitação do pagamento?</span>`;
        let secondaryMsg = '<span>Você será redirecionado e seu pagamento efetivado!</span>';
        const m = moment(this.transaction.consentimento.payment.date);

        if (!this.transaction.unico_aprovador) {
            primaryMsg += `<br><br><br>Os aprovadores tem até <strong>${m.format("DD/MM/YYYY")} às 23:59</strong> para autorizar a transação. Após essa data, deverá ser feita uma nova ordem de pagamento.`;
            secondaryMsg = '<span>A transação será efetivada após a autorização de todos os aprovadores.</span>';
        }

        this.showWarning(primaryMsg, secondaryMsg, 
            { 
                caption: 'Confirmar pagamento', 
                action: () => {
                    this._alertDialog.busy = true;

                    this._repository.validaEscalonamento(this.transaction.consentimento.consentId)
                        .pipe(finalize(() => this._alertDialog.busy = false))
                        .subscribe(result => {                        
                            if (result) {
                                this.alertVisible = false;
                                this.showError('Não foi possível prosseguir com a solicitação do pagamento.', `<span>${result}</span>`, { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._errorDialog) });
                            } else {
                                this.alertVisible = false;
                                this.securityValidationDialogVisible = true;
                            }
                        });
                }
            }, 
            { 
                caption: 'Voltar', 
                action: () => { this.alertVisible = false; } 
            }, 
            false);
    }

    private goToTop(): void {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    //#region alert

    private showWarning(primaryMsg: string, secondaryMsg: string, primaryAction: { caption: string, action: () => void }, secondaryAction?: { caption: string, action: () => void }, showIcon?: boolean) {
        this.alertPrimaryMsg = primaryMsg;
        this.alertSecondaryMsg = secondaryMsg;
        this.alertPrimaryAction = primaryAction;
        this.alertSecondaryAction = secondaryAction;
        this.alertShowIcon = showIcon != null ? showIcon : true;
        this.alertVisible = true;
    }

    public confirmAlert() {
        this.alertPrimaryAction.action();
    }

    public cancelAlert() {
        this.alertSecondaryAction.action();
    }

    //#endregion

    //#region error

    private showError(primaryMsg: string, secondaryMsg: string, action: { caption: string, action: () => void }, icon: string = 'frown') {
        this.errorPrimaryMsg = primaryMsg;
        this.errorSecondaryMsg = secondaryMsg;
        this.errorAction = action;
        this.errorVisible = true;
        this.errorIcon = icon;
    }

    public confirmError() {
        this.errorAction.action();
    }

    //#endregion

    //#region Security Validation

    public responseSecurityValidation(response) {

        if (response.status === 'success') {
            if (response.data.timeout) {
                this.showError(
                    'Tempo limite para transação expirado', 
                    '<span>Sua transação não foi realizada.<br>O tempo para realizar a solicitação do pagamento expirou.</span>', 
                    { caption: 'Sair', action: () => this.redirectToDashboard() }, 
                    'alert');
            } else {
                this.transaction.consentimento.client_info.client_uri = <string>response.data.redirect_to;
                this.status = OpenBankingTransactionStatusEnum.Confirmed;
                this.redirect(true);
            }            
        } else {
            if (response.error && response.error.message && response.error.message instanceof Array && response.error.message.length > 0) {
                this._toast.callModalMessage(null, `${response.error.message[0]}`);
            } else if(response.error && response.error.message && response.error.message.length > 0){
                this._toast.callModalMessage(null, 'Transação indisponivel. Tente novamente mais tarde.');
            } else {
                this._toast.callModalMessage(null, 'Ocorreu um erro ao realizar a operação.');
            } /*else {
                this.status = OpenBankingTransactionStatusEnum.Error;
                this.transactionWasSuccessful = false;
    
                if (true) {
                    this.currentPresentation = this.REDIRECT;
                } else {
                    this._router.navigate(['/dashboard'])
                }
            }*/                     
        }
    }

    public closeSecurityValidationDialog($event) {
        this.securityValidationDialogVisible = false;
    }

    //#endregion

    ngOnInit(): void {
        this.session = this._rolesService.getOpenFinanceSession();

        if (this.session) {
            this.transaction = <IOpenBankingTransaction>JSON.parse(this.session.data);
        
            if (this.transaction.erroescalonamento) {
                this.currentPresentation = this.NEWTRANSACTION;
                this.showError('Não foi possível prosseguir com a solicitação do pagamento.', `<span>${this.transaction.erroescalonamento}</span>`, { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._errorDialog) });
            } else if (this.transaction.contanaotemvinculocomusuario) {
                this.currentPresentation = this.NEWTRANSACTION;
                this.showError(
                    'Não foi possível prosseguir com a solicitação do pagamento.', 
                    '<span>Você não possui vínculo com a conta escolhida para realizar o pagamento.</span>', 
                    { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._errorDialog) });
            } else if (this.transaction.naopodeaprovarvalor) {
                this.currentPresentation = this.NEWTRANSACTION;
                this.showWarning('Você não está autorizado a fazer essa operação', 
                    '<span>Para ter acesso a essa funcionalidade, solicite a liberação ao(s) usuário(s) master(s) da sua empresa.</span>', 
                    { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._alertDialog) });
            } else if (this.transaction.contasemtermoaceitepix) {
                this.currentPresentation = this.NEWTRANSACTION;
                this.showError(
                    'Não foi possível prosseguir com a solicitação do pagamento.', 
                    '<span>Para prosseguir, é necessário que a conta escolhida para o pagamento tenha aderido ao Pix.</span>', 
                    { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._errorDialog) });
            } else if (this.transaction.masterfaltaaceitartermo) {
                this.currentPresentation = this.NEWTRANSACTION;
                this.showWarning('Aceite ao Termo de Uso pendente', 
                    '<span>Para realizar uma transação, o(s) usuário(s) master(s) da sua empresa precisa(m) aceitar o Termo de Uso. Foi enviada uma notificação para o(s) usuário(s) master(s). Quando o termo de uso for aceito pelo(s) usuário(s) master(s), você receberá um aviso e poderá fazer a transação normalmente.</span>', 
                    { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._alertDialog) });
            } else if (!this.transaction.onboarding) {
                this.currentPresentation = this.ONBOARDING;
            } else if (!this.transaction.termodeuso) {
                this.currentPresentation = this.TERMSOFUSE;
            } else {
                this.currentPresentation = this.NEWTRANSACTION;
                this.showWarning('Tempo limite para a transação', 
                    '<span>Esta operação tem um limite de até <strong>5 minutos</strong> para ser confirmada. Caso contrário, a operação será cancelada</span>', 
                    { caption: 'Continuar com o acesso', action: () => { 
                        this.alertVisible = false;
                    } }, 
                    { caption: 'Cancelar operação', action: () => this.showCancelationDialog() });
            }

        } else {
            this.redirectToDashboard();
        }
    }

    ngOnDestroy(): void {
        if (this._sub) {
            this._sub.unsubscribe();
        }

        if (this._subCancel) {
            this._subCancel.unsubscribe();
        }
    }
}
