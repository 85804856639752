<div class="content-transferir" *ngIf="!finalizado && !loading">
  <!-- Sem chaves -->
  <div class="no-content" id="sem-chaves" *ngIf="!cadastro">
    <div class="arrow" (click)="goBack()" style="cursor: pointer;">
      <img class="arrow-img" src="/assets/svg/arrow-voltar-icon.svg" alt="arrow-voltar"> <span class="voltar">
        Voltar</span>
    </div>
    <div class="title">
      <span class="span-title"> Alterando o limite das transações </span>
      <span class="span-subtitle"> Qual chave você quer cadastrar? </span>
    </div>
    <div class="accounts-div">

      <div class="form-cadastro" id="celular">
          <span class="form-title">Novo Limite</span>
          <input type="text" maxlength="15" placeholder="(11) 00000-0000" [(ngModel)]="numCelular" (keyup)="mphone(numCelular)" class="line-code-bar-blue"
            name="numeroCel">
          <button type="button" class="button-continue" (click)="sendToken('phone')">
            Alterar e salvar    
          </button>
        </div>
    </div>
  </div>
</div>
<div class="content-transferir" *ngIf="loading">
  <div class="d-flex justify-content-center position loading-container">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<div class="content-transferir" *ngIf="finalizado && !loading">
  <div class="warnings">
    <div class="warning-div">
      <fibra-generic-info type="{{warnType}}" title="{{warnTitle}}" message="{{warnSubtitle}}">
      </fibra-generic-info>

      <div class="buttons">
        <button type="button" class="button-white" (click)="btnSeletedCArd(btnFunction1)">
          {{button1}}
        </button>
        <button type="button" class="button-continue" (click)="btnSeletedCArd(btnFunction2)">
          {{button2}}
        </button>
      </div>
    </div>

  </div>
</div>

<div class="message-transaction" id="message">
  <img src="/assets/svg/warning.svg" />
  <div class="text">
    <h4 class="title">{{msgError}}</h4>
    <div class="msg">{{subTextError}}</div>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="closeError()">
    <img src="/assets/svg/icon_close_white.svg" />
  </button>
</div>

<div class="modal">
  <ng-template #contentSms let-modal>
    <div class="conteudo-modal">
      <div class="validacao-div">
        <span class="validacao-title"> Validação por SMS</span>
        <span class="validacao-text"> Insira o código que você recebeu por SMS no número {{numCelular}}</span>
        <div class="input-div">
          <input type="text" maxlength="6" placeholder="000000" [(ngModel)]="token" [textMask]="{
                mask: tokenMask,
                placeholderChar: '_',
                guide: false,
                modelClean: true
              }" [ngClass]="timeoutToken === true ? 'line-code-bar-red' : 'line-code-bar-blue'" name="tokenCel">
          <span [ngClass]="timeoutToken === true ? 'timer-red' : 'timer-blue'" style="display: flex;"> <span><img *ngIf="!timeoutToken"
                src="/assets/svg/time-elipse.png" style="margin-right: 5px; width: 16px;"> <img *ngIf="timeoutToken" src="/assets/svg/elipse-danger.svg"
                style="margin-right: 5px; width: 16px;"></span>
            {{minutes}}:{{counter >= 10 ? counter : "0"+counter}}</span>

        </div>

      </div>
      <div class="buttons" *ngIf="!timeoutToken">
        <button type="button" class="button-secondary" (click)="closeModal()">
          Cancelar
        </button>
        <button type="button" class="button-primary" (click)=cadastrarChave(); [disabled]="token.length  < 6"> Confirmar
        </button>
      </div>
      <div class="buttons">
        <button type="button" class="button-primary" (click)="sendToken('phone')" *ngIf="timeoutToken" style="margin-top: 0px !important;">
          Receber novo código </button>
      </div>
    </div>

  </ng-template>
</div>

<div class="modal">
  <ng-template #contentEmail let-modal>
    <div class="conteudo-modal">
      <div class="validacao-div">
        <span class="validacao-title"> Validação por Email</span>
        <span class="validacao-text"> Insira o código que você recebeu por Email no email: <strong>
            {{email}}</strong></span>
        <div class="input-div">
          <input type="text" maxlength="6" placeholder="000000" [textMask]="{
                mask: tokenMask,
                placeholderChar: '_',
                guide: false,
                modelClean: true
              }" [(ngModel)]="token" [ngClass]="timeoutToken === true ? 'line-code-bar-red' : 'line-code-bar-blue'" name="tokenCel">
          <span [ngClass]="timeoutToken === true ? 'timer-red' : 'timer-blue'" style="display: flex;"> <span><img *ngIf="!timeoutToken"
                src="/assets/svg/time-elipse.png" style="margin-right: 5px; width: 16px;"> <img *ngIf="timeoutToken" src="/assets/svg/elipse-danger.svg"
                style="margin-right: 5px; width: 16px;"></span>
            {{minutes}}:{{counter >= 10 ? counter : "0"+counter}}</span>
        </div>

      </div>
      <div class="timeout-div" *ngIf="timeoutToken">
        <img src="/assets/svg/icon-info-red.svg" alt="info">
        <span class="timeout-warning"> Tempo expirado. Toque no botão para receber <strong> novo código </strong>
        </span>
      </div>

      <div class="buttons" *ngIf="!timeoutToken">
        <button type="button" class="button-secondary" (click)="closeModal()">
          Cancelar
        </button>
        <button type="button" class="button-primary" (click)=cadastrarChave(); [disabled]="token.length  < 6"> Confirmar
        </button>
      </div>
      <div class="buttons">
        <button type="button" class="button-primary" (click)="sendToken('email')" *ngIf="timeoutToken" style="margin-top: 0px !important;">
          Receber novo código </button>
      </div>
    </div>

  </ng-template>
</div>

<fibra-modal-validation #content (responseValidation)="responseApiModal($event)" *ngIf="showModalValidation" [apiCallback]="apiCallback"
  [apiPayload]="payload" (closeEvent)="closeModalApi($event)">
</fibra-modal-validation>