import { Router } from '@angular/router';
import { TabsDataShare } from '@src/data/repository/data-share-repository';
import {
  Component,
  EventEmitter,
  Input,
  ViewChild,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as $ from 'jquery';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

// models
import { OptionList, DropdownList } from '@src/core/domain/business-models';
import {
  NgbDate,
  NgbDateStruct,
  NgbCalendar,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { TabListDataShare } from '@src/core/usecases/data-share/tab-list-data-share.service';
@Component({
  selector: 'fibra-tabs-list',
  templateUrl: './tabs-list.component.html',
  styleUrls: ['./tabs-list.component.scss'],
})
export class TabsListComponent implements OnChanges {
  @Input() options: OptionList[];
  @Input() filter;
  @Input() public tooltipTitle: HTMLElement;
  @Input() showTooltipPortfolioPosition: string;
  @Input() tabListConsultaPosicaoCarteira?: any;
  @Output() selected: EventEmitter<any>;
  @Output() itemTab: EventEmitter<any>;
  public notification: boolean;
  public periods: DropdownList;
  public periodDays: number;
  public typeList;
  public periodCalendar: any;
  public minDate: NgbDate;
  optionPeriod = [7, 15, 30, 90];
  dataRequest = 7;
  openModal = false;
  today: NgbDate;
  maxDay: NgbDate;
  isGreaterThan730Days: boolean;
  public calendarActive = {
    active: false,
    dataStart: null,
    dataEnd: null,
  };

  tabs = [
    { idOption: 'Tudo'},
    { idOption: 'PENDENTE'},
    { idOption: 'LIQUIDADO'},
    { idOption: 'RECUSADO'},
    { idOption: 'AGENDADO'},
    { idOption: 'VENCIDO'}
  ];
  constructor(public tab: TabsDataShare, calendar: NgbCalendar,
    private route: Router, private setTab: TabListDataShare) {
    this.selected = new EventEmitter();
    this.itemTab = new EventEmitter();

    this.today = calendar.getToday();
    this.maxDay = calendar.getNext(this.today, 'd', 180);
    this.minDate = calendar.getPrev(calendar.getToday(), 'y', 2);

    this.periods = [
      { name: '7 dias', value: 7 },
      { name: '15 dias', value: 15 },
      { name: '30 dias', value: 30 },
      { name: '90 dias', value: 90 },
    ];

    sessionStorage.removeItem('extrato-data');
    
  }

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  ngAfterViewInit(): void {
    this.setTab.dataShared.subscribe(r => {
      if(this.tabs.map(e => e.idOption).indexOf(r) !== -1) {
        if(typeof r !== 'undefined') {
          this.changeType(r);
          this.emitTabs(this.options);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.typeList = this.options[0].idOption;
    this.periodDays = this.periods[0].value;
    this.notification = false;
    this.sendOptionsExtract();
  }

  public changeType(typeList: string) {
    this.typeList = typeList;
    this.periodDays = 7;
    this.periodCalendar = undefined;
    this.dataRequest = 7;

    this.tab.setValue({
      periodCalendar: undefined,
    });
    this.sendOptionsExtract();
  }

  public emitTabs(event) {
    this.itemTab.emit(event);
  }

  public changePeriod(days: number) {
    this.periodDays = days;
    this.dataRequest = days;
    this.tab.setValue({
      periodCalendar: undefined,
    });
    this.periodCalendar = undefined;
    this.sendOptionsExtract();
    this.changeIconCalendar();
  }

  public changePeriodDate(period: { to: NgbDate; from: NgbDate }) {

    //Tira filtro ativo periodo de datas
    this.changePeriod(0)


    const dateFrom = moment(
      `${period.from.year}-${
      period.from.month < 10 ? '0' + period.from.month : period.from.month
      }-${period.from.day < 10 ? '0' + period.from.day : period.from.day}`
    );
    const dateTo = moment(
      `${period.to.year}-${
      period.to.month < 10 ? '0' + period.to.month : period.to.month
      }-${period.to.day < 10 ? '0' + period.to.day : period.to.day}`
    );
    const today = moment(moment().format('YYYY-MM-DD'));
    this.periodCalendar = this.convertPeriodCalendar(period);
    if (this.typeList == 'extract-list-scheduling') {
      sessionStorage.setItem('extrato-data', '{"dataInicio":"' + this.periodCalendar.dataInicio + '", "dataFinal":"' + this.periodCalendar.dataFinal + '"}')
    }
    else
      sessionStorage.setItem('extract_excel', '{"dataInicio":"' + this.periodCalendar.dataInicio + '", "dataFinal":"' + this.periodCalendar.dataFinal + '", "tipo":"'+ this.formatarTipoExtrato(this.typeList) +'"}')

    const diffDays = dateTo.diff(dateFrom, 'days');
    this.isGreaterThan730Days = diffDays > 730 && true;

    this.sendOptionsExtract();
    if (this.isGreaterThan730Days) {
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'O extrato é disponível para operações dentro do intervalo de 730 dias.',
        null,
        null
      );
    } else if (
      today.diff(dateFrom, 'days') <= 0 &&
      today.diff(dateTo, 'days') < -180
    ) {
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'O extrato é disponível para operações dentro do intervalo de 180 dias.',
        null,
        null
      );
    }

  }

  private formatarTipoExtrato(value): string {
    if (value.indexOf('-all') > -1)
      return 'tudo'
    else if (value.indexOf('-exit') > -1)
      return 'saida'
    else if (value.indexOf('-entrie') > -1)
      return 'entrada'
    else if (value.indexOf('-scheduling') > -1)
      return 'agendadas'
    else
      return 'tudo'
  }

  private adjustDate(date) {
    if (Number(date) < 10) {
      return `0${date}`;
    } else {
      return date;
    }
  }

  private convertPeriodCalendar(period) {
    return {
      dataInicio: `${period.from.year}-${this.adjustDate(
        period.from.month
      )}-${this.adjustDate(period.from.day)}`,
      dataFinal: `${period.to.year}-${this.adjustDate(
        period.to.month
      )}-${this.adjustDate(period.to.day)}`,
    };
  }

  public sendOptionsExtract() {
    if (!this.isGreaterThan730Days)
      if (!this.tabListConsultaPosicaoCarteira) {
        this.tab.setValue({
          typeList: this.typeList,
          periodDays: this.periodDays,
          periodCalendar: this.periodCalendar,
        });
      }
  }

  handleModal(type) {
    this.openModal = !this.openModal;
  }

  changeIconCalendar() {
    if (
      this.optionPeriod.includes(this.dataRequest) ||
      this.dataRequest == null
    ) {
      this.calendarActive.active = false;
      return $('#calendar').attr('src', '/assets/svg/icon_calendar_gray.svg');
    } else {
      this.showDateCalendar();
      return $('#calendar').attr('src', '/assets/svg/icon_calendar.svg');
    }
  }

  showDateCalendar() {
    this.calendarActive.active = true;
  }
}
