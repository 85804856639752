import { Component, EventEmitter, OnInit,OnDestroy, Output } from '@angular/core';
import { GetAllLoansUsecase } from '@src/core/usecases/loans/get-all-loans';
import { LoansModel, LoansRequest, LoansRequestByDate, LoansRequestContractInfo, MapaGarantia } from '@src/core/domain/loans/loan-model';
import { GetContractByDateLoansUsecase } from '@src/core/usecases/loans/get-contract-by-date';
import * as moment from 'moment';
import { GetTotalOperationsUsecase } from '@src/core/usecases/loans/get-total-operations';
import { GetWarrantyMapUsecase } from '@src/core/usecases/loans/get-warranty-map';
import { LoansDateParameterDataShare } from '@src/core/usecases/data-share/loans-date-param';
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import * as Util from '@src/shared/util-common';
import { isArray } from 'rxjs/internal-compatibility';
import { LoansDataRepository } from '@src/data/repository/loans-repository/loans-repository';
import { Observable, Subscription } from 'rxjs';


@Component({
  selector: 'fibra-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss'],
})
export class ContractsComponent implements OnInit, OnDestroy {

  public loading: boolean;
  public loansList;
  public totalOperations;
  public warrantyMap: MapaGarantia;
  public hasData: boolean = false;
  public dateReferenceToShow=null
  public showMoreInfo:boolean=false;
  public meses = [{ name: 'Janeiro', value: '01' }, { name: 'Fevereiro', value: '02' }, { name: 'Março', value: '03' }, { name: 'Abril', value: '04' },
  { name: 'Maio', value: '05' }, { name: 'Junho', value: '06' }, { name: 'Julho', value: '07' }, { name: 'Agosto', value: '08' }, { name: 'Setembro', value: '09' }, { name: 'Outubro', value: '10' }, { name: 'Novembro', value: '11' }, { name: 'Dezembro', value: '12' }]

  public ano = [] //[{ name: '2021', value: '2021' }, { name: '2020', value: '2020' }, { name: '2019', value: '2019' }, { name: '2018', value: '2018' }, { name: '2017', value: '2017' }]

  public currentTab: string = 'Contratos'
  public dataAccount;
  public month = null;
  public year = null;
  public rotateArrow1 = false;
  public rotateArrow2 = false;
  public data;
  public hide: boolean;
  public dataAll: any[];
  public hasError = false;


  public checkedList = [];
  public action: string;
  public total;
  public params;
  public type;
  public count = 0;
  public dataDetail;
  public loadingDetail: boolean;
  public util = Util;
  public selectedTab: string;
  public showCheckbox: boolean;
  public contractSubscription: Subscription;
  public totalOpSubscription: Subscription;
  public recebiveisSupervenienciaInsu: boolean;
  public recebiveisSupervenienciaInsuValue;
  public loadingWarranty: boolean = true;
  public errWarranty: boolean = false;
  public warranttResult:Observable<LoansModel>;
  public radioPeriod = true;
  public parcelaVencida=false;
  public getLoansSub:Subscription;
  public getTotalOpSub:Subscription;
  public getWarrantyMapSub:Subscription;
  public parcelasVencidasSub:Subscription;
  public progressBar20 = 20
  public progressBar80 = 80

  constructor(
    private getLoansByDate: GetContractByDateLoansUsecase,
    private getAllLoans: GetAllLoansUsecase,
    private getTotalOperations: GetTotalOperationsUsecase,
    public dateShare: LoansDateParameterDataShare,
    private getWarrantyMap: GetWarrantyMapUsecase

  ) { }


  @Output() numContractContractInformation = new EventEmitter<any>();
  @Output() showPageParcela = new EventEmitter<any>();


    getYears()
    {
      let currentYear = moment().year();
      this.ano.push({ name: currentYear.toString(), value: currentYear.toString() })        
      for (let index = 1; index <= 4; index++) {
       this.ano.push({ name: (currentYear-index).toString(), value: (currentYear-index).toString() })        
      }

    }

  ngOnInit(): void {

    //Obtem os ultimos 5 anos
    this.getYears();

    const params: LoansRequest = {}

    //Mapa de Garantia        
    this.getWarrantyMapSub = this.getWarrantyMap.execute(params)
    .subscribe((warrantiesMap) => {
      this.loadingWarranty = true
      this.warrantyMap = warrantiesMap.data
      this.recebiveisSupervenienciaInsu = this.warrantyMap.total_exigivel_recebiveis - this.warrantyMap.fluxo_mes_anterior >= 0 ? true : false
      this.recebiveisSupervenienciaInsuValue = this.warrantyMap.total_exigivel_recebiveis - this.warrantyMap.fluxo_mes_anterior
      this.loadingWarranty = false
    }, (err) => {
      this.loadingWarranty=false
      this.errWarranty = true
    })

    this.getAllContracts()
   

    //TotalOperations
    this.getTotalOpSub = this.getTotalOperations.execute(params).subscribe((data) => {
      this.totalOperations = data.data
    }, (err) => {
      this.hasError = true
    })

  }

  getAllContracts() {

    const params: LoansRequest = {}

    this.loading = true
    this.getLoansSub = this.getAllLoans.execute(params).subscribe((contratos) => {
      this.loansList = contratos.data

      this.checkHasData()

      this.selectedTab = 'contracts';
      this.requestSuccess(contratos.data);

      if(this.hasData)
      {
        //Seta data de referencia, se houver

        const dataReferencia = {
          dat_ref: this.loansList[0].data_geracao,
          userPassValue:false
        }
        this.dateShare.setValue(dataReferencia)
        this.dateReferenceToShow = this.loansList[0].data_geracao

        sessionStorage.setItem('dt_ref_download',this.loansList[0].data_geracao)

        //Export Excel
        let dt_ref = this.dateReferenceToShow;
        sessionStorage.setItem('dt_ref', null);


        //PDF
        sessionStorage.setItem('contracts_list_pdf', JSON.stringify(this.loansList));
        sessionStorage.setItem('informations_pdf', '{"title":"Contratos", "name":"contratos", "report_name":"Contratos", "filter":"Posição Atual", "dt_ref":"'+this.dateReferenceToShow+'"}')
      }     


      //Limpa filtro de data se tiver
      this.year=null
      this.month=null
      this.radioPeriod = true

      this.loading = false
    }, (err) => {
      this.loading = false
      this.hasData = false
      this.hasError = true
      this.requestError(err)
    })
  }


  private requestSuccess = (value: any) => {
    //this.verifySuccess(value);
    this.dataAll = this.data;
    localStorage.setItem(
      'contracts-export-data',
      JSON.stringify({
        data: this.loansList,
        type: this.type,
        date: this.params,
        tabs: this.selectedTab,
      })
    );
  };

  private requestError = (err) => {
    this.loading = false;
    this.hasData = false;
    this.data = [];
    this.hide = Util.isEmpty(this.data);
  };

  public checkStatus = (data) => {
    const numArrayToAllow = [];
    if (data !== undefined) {
      if (isArray(data)) {
        data.forEach((e) => {
          if (e.desc_status === 'LIQUIDADO') {
            numArrayToAllow.push(e);
          }
        });
      }
      this.showCheckbox = data.length !== numArrayToAllow.length;
      return;
    }
    this.showCheckbox = true;
  };

  private verifySuccess(value) {
    const data = value.data.filter((e) => {
      if (e.desc_status === this.selectedTab || this.selectedTab === 'Tudo') {
        return e;
      }
    });
    if (value.data === '') {
      this.data = [];
    } else {
      this.data = Util.verifyArray(data);
      this.data.sort((a, b) =>
        a.dt_transacao > b.dt_transacao
          ? -1
          : b.dt_transacao > a.dt_transacao
            ? 1
            : 0
      );
    }
  }


  rotate1() {
    if (this.rotateArrow1)
      this.rotateArrow1 = false
    else
      this.rotateArrow1 = true
  }

  rotate2() {
    if (this.rotateArrow2)
      this.rotateArrow2 = false
    else
      this.rotateArrow2 = true
  }




  checkHasData() {
    if (this.loansList.length > 0)
      this.hasData = true
    else
      this.hasData = false
  }


  dateFormat() {
    const day = moment(`${this.year}-${this.month}`).endOf('month').toDate().getDate()
    const month = moment(`${this.year}-${this.month}`).endOf('month').toDate().getMonth() + 1
    const year = moment(`${this.year}-${this.month}`).endOf('month').toDate().getFullYear()
    return `${year}-${month}-${day}`
  }

  
  dateFormatToShowScreen() {
    const day = moment(`${this.year}-${this.month}`).endOf('month').toDate().getDate()
    const month = moment(`${this.year}-${this.month}`).endOf('month').toDate().getMonth() + 1
    const year = moment(`${this.year}-${this.month}`).endOf('month').toDate().getFullYear()
    return `${day}/${month}/${year}`
  }



  changeSelectMonth(event: any) {
    this.month = event

    if (this.year !== null) {


      const params: LoansRequestByDate = { dt_ref: this.dateFormat() }
      this.loading = true
      this.getLoansByDate.execute(params).subscribe((data) => {
        this.loansList = data.data

        this.requestSuccess(this.loansList)

        this.checkHasData()
        this.loading = false
      },(err) =>
      {
        this.loading=false
        this.hasData = false
        this.hasError = true
      })
      let date = this.dateFormat()

      //Export Excel
      let dt_ref = date;
      sessionStorage.setItem('dt_ref', dt_ref);

       //PDF
       sessionStorage.setItem('contracts_list_pdf', JSON.stringify(this.loansList));
       sessionStorage.setItem('informations_pdf', '{"title":"Contratos", "name":"contratos", "report_name":"Contratos", "filter":"Período", "dt_ref":"'+dt_ref+'"}')


      const dataReferencia = {
        dat_ref: date,
        userPassValue:true
      }
      
      this.dateShare.setValue(dataReferencia)
      this.dateReferenceToShow = date
      this.radioPeriod = false
    }
  }

  changeSelectYear(event: any) {
    this.year = event


    if (this.month !== null) {

      const params: LoansRequestByDate = { dt_ref: this.dateFormat() }

      this.loading = true
      this.getLoansByDate.execute(params).subscribe((data) => {
        this.loansList = data.data


        this.requestSuccess(this.loansList)

        this.checkHasData()
        this.loading = false
      },(err) =>
      {
        this.loading=false
        this.hasData = false
        this.hasError = true
      })

      let date = this.dateFormat()

       //Export Excel
       sessionStorage.setItem('dt_ref', date);

       //PDF
       sessionStorage.setItem('contracts_list_pdf', JSON.stringify(this.loansList));
       sessionStorage.setItem('informations_pdf', '{"title":"Contratos", "name":"contratos", "report_name":"Contratos", "filter":"Período", "dt_ref":"'+date+'"}')

      const dataReferencia = {
        dat_ref: date,
        userPassValue:true
      }
      this.dateShare.setValue(dataReferencia)
      this.dateReferenceToShow = date 
      this.radioPeriod = false
    }

  }

  changePeriod()
  {
    this.radioPeriod = false
  }

  

  

  contractInfo(numContrato: any) {

    this.numContractContractInformation.emit({ "numContrato": numContrato, "page": "informacaoContrato" })
    this.showPageParcela.emit({ "numContrato": numContrato, "page": "informacaoContrato" })

  }


  ngOnDestroy()
  {
    this.getLoansSub.unsubscribe()
    this.getTotalOpSub.unsubscribe()
    this.getWarrantyMapSub.unsubscribe()
    sessionStorage.removeItem('contracts_list_pdf')
    sessionStorage.removeItem('informations_pdf')
  }


}
