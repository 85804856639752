<div class="list-value">
    <ul class="col-lg-12 list-group list-group-flush p-0">
        <!-- Time course -->
        <section *ngIf="periodShow">
            <span class="txt-interval">Período: {{ dataInicio }} à {{ dataFinal }}</span
      >
    </section>

    <!-- All -->
    <section *ngIf="typeList == 'extract-list-all'">
      <fibra-all-list
        params="{{ params }}"
        balance="{{ balance }}"
      ></fibra-all-list>
    </section>

    <!-- Entries -->
    <section *ngIf="typeList == 'extract-list-entrie'">
      <fibra-input-list
        params="{{ params }}"
        balance="{{ balance }}"
      ></fibra-input-list>
    </section>

    <!-- Exit -->
    <section *ngIf="typeList == 'extract-list-exit'">
      <fibra-exit-list
        params="{{ params }}"
        balance="{{ balance }}"
      ></fibra-exit-list>
    </section>

    <!-- Scheduling -->
    <section *ngIf="typeList == 'extract-list-scheduling'">
      <fibra-scheduling-list
        params="{{ params }}"
        balance="{{ balance }}"
      ></fibra-scheduling-list>
    </section>

    <!-- Blocked -->
    <!-- <section *ngIf="typeList == 'extract-list-block'">
      <fibra-block-list
        params="{{ params }}"
        balance="{{ balance }}"
      ></fibra-block-list>
    </section> -->

    <!-- duplicate bill -->
    <section *ngIf="typeList == 'bill-list'">
      <fibra-duplicates-list params="{{ params }}"></fibra-duplicates-list>
    </section>

    <!-- query transit all -->

    <!-- query portfolio all -->
    <section *ngIf="typeList == 'portfolio-list-all'">
      <fibra-portfolio-all-list
        params="{{ params }}"
        (emitItem)="showDetail($event)"
      ></fibra-portfolio-all-list>
    </section>

    <!-- query portfolio authorized -->
    <section *ngIf="typeList == 'portfolio-list-authorized'">
      <fibra-portfolio-authorized-list
        params="{{ params }}"
      ></fibra-portfolio-authorized-list>
    </section>

    <!-- query portfolio pending -->
    <section *ngIf="typeList == 'portfolio-list-pending'">
      <fibra-portfolio-pending-list
        params="{{ params }}"
      ></fibra-portfolio-pending-list>
    </section>

    <!-- query portfolio refused -->
    <section *ngIf="typeList == 'portfolio-list-refused'">
      <fibra-portfolio-authorized-list
        params="{{ params }}"
      ></fibra-portfolio-authorized-list>
    </section>

    <!-- matrix list rules -->
    <section *ngIf="typeList == 'rule-list'">
      <fibra-list-matrix params="{{ params }}"></fibra-list-matrix>
    </section>
  </ul>
</div>