<fibra-toast-error-message [color]="typToastColor"> </fibra-toast-error-message>
<div [class]="identificationPage == 'CHANGE-TRANSACTION' ? 'sub-options-div margin-align' : 'sub-options-div'">
    <span [class]="tabMenu == 'all' ? 'option-selected' : 'option-unselected'"
        (click)="selectedMenu('all')" style="margin-right: 17px; cursor: pointer;"> Tudo
    </span>
    <div class="separator"></div>
    <span [class]="tabMenu == 'pendente' ? 'option-selected' : 'option-unselected'"
        (click)="selectedMenu('pendente')" style="margin-right: 17px; margin-left: 16px; cursor: pointer;">Pendente
    </span>
    <div class="separator"></div>
    <span [class]="tabMenu == 'aprovado' ? 'option-selected' : 'option-unselected'"
        (click)="selectedMenu('aprovado')" style="margin-right: 17px; margin-left: 16px; cursor: pointer;">Aprovado
    </span>
    <div class="separator"></div>
    <span [class]="tabMenu == 'recusado' ? 'option-selected' : 'option-unselected'"
        (click)="selectedMenu('recusado')" style="margin-left: 16px; cursor: pointer;"> Recusado
    </span>
</div>

<div class="content-page" id="content-body body-content" id="body-scroll" *fibraHasRole="'APROV_TRANS.CONS_TRANS,APROV_TRANS.APROV_REPROV_TRANS'">
  <div class="body-content">
    <div class="wraper-list-value">
      <header class="wraper-list-header">
        <div class="check-all">
          <input *ngIf="!hideCheCheckBoxAll" type="checkbox" class="checkbox" id="checkAll" (change)="checked('all')" [disabled] = "disableCheckBox"
             />
        </div>
        <div class="items">
          <div class="col-date">
            <span>Data e hora</span>
            <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('dataInclusao', inputData)" />
          </div>
          <div class="col-account">
            <span>Conta</span>
            <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('nrConta', inputData)" />
          </div>
          <div class="col-status">
            <span>Status</span>
            <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('idStatus', inputData)" />
          </div>
          <div class="col-action">
            <span>Ações</span>
          </div>
        </div>
      </header>
      
      <div class="list-value">
        <div *ngIf="loading" class="d-flex justify-content-center align-items-center" style="height: calc(70vh - 130px)">
          <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div class="empty-info paddingTopGeneric" *ngIf="isError">
          <fibra-generic-info type="warn" title="{{ 'Você não possui alterações de' }}" title2="{{'limites disponíveis para aprovação.'}}">
          </fibra-generic-info>
      </div>
        <div *ngIf="!isError && !loading">
          <div class="panel-group" id="accordion">
            <div class="list-content" *ngFor="let item of inputData; index as i">
              <div class="panel-list" >
                <div class="check-all">
                  <div class="checkbox">
                    <input *ngIf="item.idStatus == 1" type="checkbox" class="checkbox" [id]="i" (change)="checked(i, item)"[disabled]="disableCheckBox"/>
                  </div>
                </div>
                <div class="col-date">
                  <span>
                    {{ item?.dataInclusao | date: 'dd/MM/yyyy h:mm' }}h
                  </span>
                </div>
                <div class="col-transaction">
                  <span class="title-desc" *ngIf="item.tipoAlteracao == 'LIMITE'">Alteração Limite - Conta {{ item?.nrConta }}</span>
                  <span class="title-desc" *ngIf="item.tipoAlteracao == 'HORARIO'">Horário - Conta {{ item?.nrConta }}</span>
                </div>

                <div class="col-status">
                  <div class="item-status util">

                    <div *ngIf="item?.idStatus === 2">
                      <span class="icon-info notification app-tooltip">
                        <div class="authorized-icon"></div>
                      </span><span class="title-desc">Aprovado</span>
                    </div>
                    
                    <div *ngIf="item.idStatus === 1">
                      <span *ngIf="!showPending(item.aprovadores)" class="icon-info notification" [appTooltip]="tooltipAll">
                        <img width="10" height="10" src="/assets/svg/tooltip-alert-orange.svg" alt="picture orange.svg" />
                        <div #tooltipAll [ngClass]="
                            i <= 2 ? 'tooltip-custom-down' : 'tooltip-custom'
                          ">
                          <h3>Aguardando aprovação</h3>
                          <div class="info">
                            <ul class="info-list" *ngIf="showPendingMasterToolTip(item.aprovadores)">
                              <li class="header">
                                <img src="../../../../assets/img/icon-dot.svg" alt="icon" />
                                Masters
                              </li>
                              <li class="item-mt" *ngFor="let master of item.aprovadores">
                                <div>
                                  <span *ngIf="master.status == 'Pendente' && master.idTipoAcesso == 2">{{  master.nomeAprovador }}</span>
                                </div>
                              </li>
                            </ul>
                            <ul class="info-list" *ngIf="showPendingOperatorToolTip(item.aprovadores)" >
                              <li class="header">
                                <img src="../../../../assets/img/icon-dot.svg" alt="" /> Operador
                              </li>
                              <li class="item-op" *ngFor="let operator of item.aprovadores">
                                <div *ngIf="operator.status == 'Pendente' && operator.idTipoAcesso == 1">
                                  {{ operator.nomeAprovador }}
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div [ngClass]="i <= 2 ? 'arrow-top' : 'arrow'"></div>
                        </div>
                      </span>
                      <span *ngIf="showPending(item.aprovadores) && lenghtPendingMaster() <= 1" class="icon-info notification">
                        <img width="10" height="10" src="/assets/svg/tooltip-alert-orange.svg" alt="logo" />
                      </span>
                    </div>
                    <div class="text" *ngIf="item.idStatus === 1">
                        Pendente
                    </div>

                    <div *ngIf="item.idStatus === 3">
                      <span class="icon-info notification" [appTooltip]="tooltipAll">
                        <span class="icon-info notification app-tooltip">
                          <div class="refused-icon"></div>
                        </span>
                        <div #tooltipAll [ngClass]="
                            i <= 2 ? 'tooltip-custom-down' : 'tooltip-custom'
                          ">
                          <h3 class="font-color-red ">Recusado por</h3>
                          <div class="info">
                            <ul class="info-list" *ngIf="showRefundMasterToolTip(item.aprovadores)">
                              <li class="header font-color-red ">
                                <img src="../../../../assets/img/icon-dot.svg" alt="icon" />
                                Masters
                              </li>
                              <li class="item-mt" *ngFor="let master of item.aprovadores">
                                <div>
                                  <span *ngIf="master.status == 'Recusado' && master.idTipoAcesso == 2">{{  master.nomeAprovador }}</span>
                                </div>
                              </li>
                            </ul>
                            <ul class="info-list" *ngIf="showRefundOperatorToolTip(item.aprovadores)" >
                              <li class="header font-color-red ">
                                <img src="../../../../assets/img/icon-dot.svg" alt="icon" /> Operador
                              </li>
                              <li class="item-op" *ngFor="let operator of item.aprovadores">
                                <div *ngIf="operator.status == 'Recusado' && operator.idTipoAcesso == 1">
                                  {{ operator.nomeAprovador }}
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div [ngClass]="i <= 2 ? 'arrow-top' : 'arrow'"></div>
                        </div>
                      </span>
                    </div>
                    <div *ngIf="item?.idStatus === 3">
                        <span class="title-desc">Recusado</span>
                    </div>
                  </div>

                </div>
                <div class="">
                  <div class="item-action">

                    <div *ngIf="item.idStatus === 1 && isApprovalPermission(item.aprovadores)" class="item-refused">
                      <span>
                        <img src="/assets/svg/close.svg" alt="download-accent"  (click)="approvalOrRefuseIndividual('REFUSE', item)"/>
                      </span>
                    </div>

                    <div class="item-approval">
                        <span *ngIf="(item.idStatus === 1 && compareApprovals(item.aprovadores)=== false) && isApprovalPermission(item.aprovadores)">
                          <img src="/assets/svg/approval.svg" alt="Approval Icon"
                            (click)="approvalOrRefuseIndividual('APPROVAL', item)" />
                        </span>
                    </div>

                    <span class="item-icon" 
                    data-toggle="collapse" data-parent="#accordion" (click)="toogleDetail(i, item.id)" 
                      [attr.href]="'#collapse' + i">
                      <img id="{{ i }}" src="/assets/svg/dropdown.svg" class="" alt="Seta para dropdown" />
                    </span>
                  </div>
                </div>
              </div>
              <div [id]="'collapse' + i" class="panel-collapse collapse in">
                <div class="panel-body">
                  <fibra-limit-detail [index]="i" [data]="dataDetail" [identificationPage] = "identificationPage" [loading]="loadingDetail">
                  </fibra-limit-detail>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-options" *ngIf="approvalOrRefuseList.length >= 1">
        <div class="info">
          <div class="ticket-info">
            <div class="tickets">
              <img alt="Comprovante" src="assets/svg/consultas_icon.svg">
              <a> {{ approvalOrRefuseList.length }} {{ approvalOrRefuseList.length > 1 ? 'registros' : 'registro' }}</a>
            </div>
          </div>
        </div>
        <div class="actions">
          <div class="action-radios">
            <div class="custom-control custom-radio custom-control-inline" *ngIf="handleTypeConfirm() === true; else other_content">
              <fibra-radiobutton [option]="[
                      { option: 'Recusar', value: 10 }
                    ]" (selected)="changeTypeApprove($event)">
              </fibra-radiobutton>
            </div>

            <ng-template #other_content>
              <div class="custom-control custom-radio custom-control-inline">
                <fibra-radiobutton [option]="[
                          { option: 'Aprovar', value: 9 },
                          { option: 'Recusar', value: 10 }
                        ]" (selected)="changeTypeApprove($event)">
                </fibra-radiobutton>
              </div>
            </ng-template>
          </div>
          <div class="buttons">
            <button class="confirm" [disabled]="actionRefuseOrApproval == ''" (click)="confirmAction()">
              Confirmar
            </button>
            <div class="cancel" (click)="cancelConfirmation()">
              Remover seleções
            </div>
          </div>
        </div>
      </div>

      <div class="box-options" *ngIf="showIndividualAceite || showIndividualRecusa">
        <div *ngIf="showIndividualAceite" class="actionsAprovarRecusar">
          Deseja aprovar esta alteração?
          <div class="buttons">
            <div class="cancel" (click)="cancelConfirmation()">
              Cancelar
            </div>
            <button class="confirm" (click)="getModalToken('APPROVAL')">
              Aprovar
            </button>
          </div>
        </div>
        <div *ngIf="showIndividualRecusa" class="actionsAprovarRecusar">
          Deseja recusar esta alteração?
          <div class="buttons">
            <div class="cancel" (click)="cancelConfirmation()">
              Cancelar
            </div>
            <button class="confirm" (click)="getModalToken('REFUSE')">
              Recusar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<fibra-modal-validation *ngIf="actionConfirmed" [apiCallback]="callBack" [apiPayload]="securityRequest()" [handleInternalError]="false" (closeEvent)="closeModal()" (responseValidation)="securityResponse($event)">
</fibra-modal-validation>