import {
  AdminUserListModel,
  AdminUserListRequest,
} from './../../domain/admin-user/admin-user.model';
import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { AdminUserRepository } from '@src/core/repositories/admin-user/admin-user.repository';

@Injectable({
  providedIn: 'root',
})
export class GetUsersUseCase
  implements UseCase<AdminUserListRequest, AdminUserListModel> {
  constructor(private adminUserRepository: AdminUserRepository) {}

  execute(): Observable<AdminUserListModel> {
    return this.adminUserRepository.getUsers();
  }
}
