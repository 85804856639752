<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>
<div class="content-page">
  <fibra-breadcrumb bottom="-20" infoAccount="Mais" roule="Administração de usuários"></fibra-breadcrumb>
  <div class="arrowView" (click)="currentStep === 1 ? goToView('/admin-user') : prevStep()">
    <img class="arrowBack" src="/assets/svg/arrow_page.svg" alt="setaAvancar" />
    <span class="textBack">Voltar</span>
  </div>
  <div *ngIf="!isEditMode && !loading">
    <div class="titleView">
      <span class="titleRegister">Cadastro de grupo</span>
    </div>
    <div class="groupNameView border-box-card">
      <label>Nome do grupo</label>
      <input [(ngModel)]="groupName" placeholder="Novo grupo" type="text"
        (keyup)="validateStep1(); validateStep2(); changeInputName()" />
      <span class="error-msg" *ngIf="(groupName === '' || !groupName) && !isFirstName">* Campo <strong>nome do
          grupo</strong> é obrigatório</span>
    </div>
  </div>
  <div *ngIf="isEditMode">
    <div class="titleView">
      <span class="titleEdit">Editando grupo: </span>
      <span class="titleName">{{ groupName }}</span>
    </div>
  </div>
  <ng-template #firstStep class="fibra-card-step-group">
    <div class="d-flex justify-content-center position loading-container" *ngIf="isEditMode && loading">
      <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="step1" *ngIf="!loading">
      <div class="admin-title">
        <span>Adicionar conta</span>
      </div>
      <div class="box-account">
        <div class="container p-0">
          <div class="row">
            <div class="col document-inputs">
              <fibra-dropdown [(ngModel)]="newAccount" [options]="optionsAccount" [style.width.px]="575"
                customClass="select-option select-option-blue" placeholder="Selecionar conta" removeShadown="true">
              </fibra-dropdown>
            </div>
            <div class="col">
              <button (click)="addAccount()" [disabled]="!newAccount" class="btn-full btn-adicionar">
                Adicionar
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="accountsCheckbox.length > 1" class="dell-selected-box">
          <div class="row">
            <div class="col">
              <div (click)="
                  handleDelete(
                    accountsCheckbox,
                    'ACCOUNT',
                    'Deseja realmente excluir as contas selecionadas?'
                  )
                " class="btn-delete">
                <img class="trashIconButton" src="/assets/svg/trash.svg" />
                <span class="text">Excluir selecionados</span>
              </div>
            </div>
          </div>
        </div>
        <div *ngFor="let accounts of listAccount" class="list-account container">
          <div class="row list-account-row" id="row-edit-{{ accounts.account.num_conta }}">
            <div class="col-6 col-lg-4 d-flex justify-content-start align-items-center">
              <input (click)="setAccount($event)" [value]="accounts.account.num_conta" name="accounts"
                type="checkbox" id="accountCheck-{{ accounts.account.num_conta }}"/>
              <span class="account-text">
                {{ accounts.account.num_conta }} - {{ (accounts.account.exibicao || accounts.account.desc_conta)  | titlecase }}
              </span>
            </div>
            <div class="col-6 col-lg-8 d-flex justify-content-between align-items-center">
              <div *ngIf="typeAccount === accounts.account.num_conta"
                class="col-5 d-flex justify-content-start align-items-center">
                <input [(ngModel)]="typeAccount" [value]="accounts.account.num_conta" autocomplete="off" checked
                  name="type_account" type="radio" type="radio" />
                <span class="account-type">
                  Conta Padrão
                </span>
              </div>
              <div *ngIf="typeAccount !== accounts.account.num_conta"
                class="col-5 d-flex justify-content-start align-items-center">
                <input (click)="
                    setDefaultAccount(accounts.account.num_conta, $event)
                  " [(ngModel)]="typeAccount" [value]="accounts.account.num_conta" autocomplete="off"
                  name="type_account" type="radio" type="radio" />
                <span class="account-type">
                  Tornar conta padrão
                </span>
              </div>
              <div class="col-6 d-flex document-inputs">
                <div (click)="
                    showPermitions(typeAccount, accounts.account.num_conta)
                  " [attr.data-target]="
                    '#collapseAccount' + accounts.account.num_conta
                  " aria-controls="collapseAccount{{
                    accounts.account.num_conta
                  }}" aria-expanded="false" class="btn-edit" data-toggle="collapse"
                  id="btn-edit-{{ accounts.account.num_conta }}">
                  <span>Editar permissões</span>
                  <img class="arrowIcon" src="/assets/png/dropdown.png" />
                </div>
              </div>
              <div *ngIf="accountsCheckbox.length <= 1" class="col-1 d-flex justify-content-end align-self-center">
                <img (click)="
                    handleDelete(
                      [{ num_conta: accounts.account.num_conta }],
                      'ACCOUNT',
                      'Deseja realmente excluir a conta selecionada?'
                    )
                  " src="/assets/svg/trash.svg" />
              </div>
            </div>
          </div>
          <div class="row list-account-row-import row-hide" id="row-import-{{ accounts.account.num_conta }}">
            <div class="col account-dark">
              {{ accounts.account.num_conta }} -
              {{ accounts.account.desc_conta }}
            </div>
            <div class="col-auto align-middle document-inputs">
              <div (click)="importDefaultAccount(accounts.account.num_conta)"
                *ngIf="difineTypeAction(typeAccount, accounts)" class="btn-edit btn-hide"
                id="btn-import-{{ accounts.account.num_conta }}">
                <span>Importar permissões da conta padrão</span>
              </div>
            </div>
          </div>
          <div class="list-account-box collapse" id="collapseAccount{{ accounts.account.num_conta }}">
            <div class="row list-account-row">
              <div class="col">
                <fibra-accordion (emitAllChange)="changeAllRule($event)" (emitChange)="changeRule($event)"
                  [collapseID]="accounts.account.num_conta" [listAccount]="arrayAccountPayload"
                  [listPermissions]="accounts.functionalities" [showSelectAll]="true"></fibra-accordion>
              </div>
            </div>
            <div class="row list-account-row">
              <div class="col"></div>
              <!-- TODO: Habilitar somente no editar! -->
              <div *ngIf="1 < 0" class="col-auto">
                <button [attr.data-target]="
                    '#collapseAccount' + accounts.account.num_conta
                  " aria-controls="collapseAccount{{
                    accounts.account.num_conta
                  }}" aria-expanded="false" class="btn-formatted btn-out btn-cancel" data-toggle="collapse">
                  Cancelar
                </button>
              </div>
              <div class="col-auto">
                <button (click)="closeAccount(accounts.account.num_conta)" [attr.data-target]="
                    '#collapseAccount' + accounts.account.num_conta
                  " aria-controls="collapseAccount{{
                    accounts.account.num_conta
                  }}" aria-expanded="false" class="btn-formatted btn-dark btn-salvar-fechar" data-toggle="collapse">
                  Finalizar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="admin-title">
        <span>Permissões não atreladas a contas</span>
      </div>
      <div class="admin-subtitle">
        <span>As permissões abaixo são atreladas somente a funcionalidades e não a
          uma conta</span>
      </div>
      <fibra-accordion (emitChange)="changeRuleNotAccount($event)" [collapseID]="0"
        [listNotAccount]="arrayNotAccountPayload" [listPermissions]="arrayFunctionalitiesNotAccount"
        [showSelectAll]="false">
      </fibra-accordion>
    </div>
    <div class="footer-button" *ngIf="!loading">
      <button (click)="prevStep()" class="btn-formatted btn-cancel-step2 preview">
        Cancelar
      </button>

      <button (click)="validateStepOne()"
        class = "btn-formatted btn-advance-step2 next">
        Avançar
      </button>
    </div>
  </ng-template>
  <ng-template #secondStep>
    <div>
      <div class="infoGroupAccount">
        <div class="accountInputs" data-hj-suppress>
          <input [inputFormatter]="formatter" [ngbTypeahead]="search" [resultFormatter]="formatter"
            autocomplete="operador-{{ randHack }}" class="input-operador" id="operator-{{ randHack }}"
            name="operator-{{ randHack }}" placeholder="Digite o nome ou e-mail do usuário" type="text"
            [(ngModel)]="model" />
          <button [disabled]="errorUserInput" (click)="addUser()" class="button-add" type="reset">
            Adicionar
          </button>
        </div>
        <div *ngIf="validateInputList()" class="emptyUserList">
          <span>Nenhum resultado encontrado.</span>
        </div>
      </div>
    </div>
    <div (click)="
        handleDelete(
          '',
          'MULTIPLEUSER',
          'Deseja realmente excluir os usuários selecionados?'
        )
      " *ngIf="isUserChecked && countChecked > 1" class="btnDelete">
      <img class="trashIconButton" src="/assets/svg/trash.svg" />
      <span class="text">Excluir selecionados</span>
    </div>
    <div *ngFor="let user of addedUserList; index as i" class="accountList">
      <div>
        <input (change)="checkUser()" [(ngModel)]="user.checked" class="checkbox" type="checkbox" />
        <label class="userListName" data-hj-suppress>{{ user.userName }}</label>
        <label class="userListMail">{{ user.userMail }}</label>
      </div>
      <div class="accountListEnd">
        <img (click)="
            handleDelete(
              user.idUser,
              'SINGLEUSER',
              'Deseja realmente excluir o usuário selecionado?'
            )
          " class="trashIcon" src="/assets/svg/trash.svg" />
      </div>
    </div>
    <div class="footer-button mt-5">
      <button (click)="prevStep()" class="btn-formatted btn-cancel-step2 preview">
        Cancelar
      </button>
      <button (click)="sendRequest()" [disabled]="!isStepTwoOk" class="btn-formatted btn-dark btn-cadastrar-usuario">
        {{ buttonName }}
      </button>
    </div>
  </ng-template>
  <fibra-steps [currentStep]="currentStep" [firstStep]="firstStep" [secondStep]="secondStep" [showHeader]="true"
    [titleSans]="true" [stepObj]="step"></fibra-steps>
</div>
<fibra-modal-validation (closeEvent)="closeModal()" (responseValidation)="securityResponse($event)"
  *ngIf="showModalValidation" [apiCallback]="apiCallback" [apiPayload]="securityRequest()">
</fibra-modal-validation>
<fibra-toast-error-message (optionNo)="selectNoDeleteFunction()" (optionYes)="selectDeleteFunction()" [color]="typeColorToast">
</fibra-toast-error-message>