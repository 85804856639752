<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <div class="main-divisor"></div>
  <fibra-profile-list></fibra-profile-list>
</div>

<!-- Loading -->
<div class="d-flex justify-content-center position loading-container loadingView" *ngIf="loadingPage">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="content-page">
  <fibra-breadcrumb
    bottom="-120px"
    infoAccount=""
    roule="Pagamento de boletos"
    arrow="false"
    downloadDoc="false"
  ></fibra-breadcrumb>
  <div class="body-content">
    <div class="listCard">
      <div class="topTexts">
        <span class="title">Transações</span>
      </div>
      <div class="list-value">
        <!-- Loading -->
        <div
          class="d-flex justify-content-center position loading-container"
          *ngIf="loading"
        >
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="empty-info" *ngIf="!loading && error.active">
          <fibra-generic-info
            [type]="error.type"
            [title]="error.title"
            [message]="error.message"
          >
          </fibra-generic-info>
        </div>
        <table class="table" *ngIf="!loading && !error.active">
          <thead>
            <tr class="header-list">
              <th scope="col">
                <div class="text" (click)="sortList('nome_beneficiario')">
                  Nome
                  <span class="icon">
                    <img src="/assets/svg/sort-list.svg" />
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="text" (click)="sortList('num_cpf_cnpj')">
                  CNPJ/CPF
                  <span class="icon">
                    <img src="/assets/svg/sort-list.svg" />
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="text" (click)="sortList('vlr_pagamento')">
                  Valor
                  <span class="icon">
                    <img src="/assets/svg/sort-list.svg" />
                  </span>
                </div>
              </th>
              <th scope="col" class="lastTh">
                <div class="text" (click)="sortList('dt_vencimento')">
                  Data de vencimento
                  <span class="icon">
                    <img src="/assets/svg/sort-list.svg" />
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody style="display: flex; flex-direction: column">
            <tr *ngFor="let item of paymentMultiple; index as i">
              <div class="panel-group" id="accordion">
                <div class="panel-list">
                  <td scope="row">
                    <label class="label-list">{{
                      item.nome_beneficiario &&
                        removeString('Favorecido: ', item.nome_beneficiario) | titlecase
                    }}</label>
                  </td>
                  <td scope="row">
                    <label class="label-list">{{
                      item.num_cpf_cnpj &&
                        removeString('CPF/CNPJ: ', item.num_cpf_cnpj) | cpfcnpj
                    }}</label>
                  </td>
                  <td scope="row">
                    <label class="label-list"
                      >R$ {{ item.vlr_pagamento | number: '1.2-2' }}</label
                    >
                  </td>
                  <td scope="row">
                    <label class="label-list-date">
                      {{ item.dt_vencimento }}</label
                    >
                  </td>
                  <div class="icons">
                    <div class="item-refused" (click)="deleteBoleto(item.num_operacao)">
                      <img src="/assets/svg/close.svg" alt="download-accent" />
                    </div>
                    <span class="item-icon" (click)="closeArrow(i)">
                      <img
                        id="{{ i }}"
                        src="/assets/png/arrowuppayment.png"
                        [ngClass]="arrowArr[i] ? 'arrowuppayment' : 'arrowdownpayment'"
                        alt="Seta para dropdown"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        [attr.href]="'#collapse' + i"
                      />
                    </span>
                  </div>
                </div>
                <div [id]="'collapse' + i" class="panel-collapse collapse in">
                  <fibra-payment-multiple-detail
                    [data]="item"
                  ></fibra-payment-multiple-detail>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="info-total">
        <div class="info-value">
          <div class="count">Total das transações: {{ paymentMultiple.length }}</div>
          <div class="count">
            Valor total: R$ {{ totalPay | number: '1.2-2' }}
          </div>
        </div>

        <div class="action">
          <div class="info-hours">
            <p>Horário e limites:</p>
            <p>
              Pagamento de boletos e compensação somente das 08:30h às 20:00h e
              até R$ 7.000.000,00.
            </p>
          </div>

          <div class="buttons">
            <div class="cancel" (click)="onNav('/payment')">Cancelar</div>
            <div class="pay" (click)="onPay()">Pagar</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <fibra-modal-validation
      *ngIf="showModalValidation"
      [apiCallback]="apiCallback"
      [apiPayload]="postBoleto()"
      [handleInternalError]="false"
      (closeEvent)="closeModal()"
      (responseValidation)="responseApi($event)"
  >
  </fibra-modal-validation>
</div>

<fibra-generic-toaster
  [title]="toasterTitle"
  [text]="toasterMessage"
  [show]="showToaster"
  (action)="closeToaster()"
></fibra-generic-toaster>
