import { FilterComponent } from '@src/presentation/web/components/filter/filter.component';
import { TransactionDetailRequest } from '@src/core/domain/transaction/transaction.model';
import { GetTransactionDetailUsecase } from '@src/core/usecases/transaction/get-transaction-detail-usecases';
import { ReceiptandRefuseRequest, ReceiptList, ReceiptRequest, TransferReceiptModel } from '@src/core/domain/transfer-receipt/receipt.model';
import { Component, EventEmitter, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as Util from '@src/shared/util-common';
import { TransactionsDataShare } from '@src/core/usecases/data-share/transactions-data-share.service';
import { TabsDataShare } from '@src/data/repository/data-share-repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { isArray } from 'rxjs/internal-compatibility';
import * as $ from 'jquery';
import { startWith } from 'rxjs/operators';
import { TransferReceiptDataRepository } from '@src/data/repository/receipt-repository/receipt.repository';
import * as moment from 'moment';
import { PostExportExcelUsecase } from '@src/core/usecases/transfer-approval/post-export-approval-usecase';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fibra-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit, OnDestroy {
  constructor( 
    private getDetail: GetTransactionDetailUsecase,
    private transactionsDataShare: TransactionsDataShare,
    private tabsDataShared: TabsDataShare,
    private receiptService: TransferReceiptDataRepository,
    private postExportExcel: PostExportExcelUsecase,
  ) { }
  public report = false;
  public data: ReceiptList[];
  public filter = new FormControl('');
  public loading: boolean = true;
  public hide: boolean;
  public shown = [];
  public shownAll: boolean;
  public dataAll: ReceiptList[];
  public actionConfirmed: boolean;
  public showConfirmation: boolean;
  public showConfirmationRadio: boolean;
  public checkedArrayIsNotEmpty: boolean;
  public checkedArrayIsExpiredTicket: boolean;
  public oneOrMorePendingChecked: boolean;
  public oneOrMorePendingExpiredChecked: boolean;
  public isPoolPenddingselected: boolean;
  public isExpiredTicketSelected: boolean;
  public showRefusedOrApproveAction: boolean;
  public receiptList = [];
  public checkedList = [];
  public selecionados = [];
  public action: string;
  public total;
  public params;
  public searchStringValue;
  public searchButtonEnable: boolean;
  public multiSelected: boolean;
  public type;
  public count = 0;
  public dataDetail;
  public loadingDetail: boolean;
  public util = Util;
  public selectedTab: string;
  public status: string;
  public options = [];
  public actionAproveOrRefuse = false;
  public channelEnum;
  public objRequest: ReceiptandRefuseRequest;
  public varSubscriptTabs: Subscription;
  public varSubscriptTransacton: Subscription;
  public varSubscribeReceipt: Subscription;
  public isSchedules = false;;
  public typToastColor;
  public indexList: boolean;
  public isPendent: boolean;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  public AMBARCOLOR = '#D69D10';
  public paramsExport;
  public showCheckbox: boolean;
  public selected = [];

  @Output() emitTransferData: EventEmitter<any> = new EventEmitter();

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  @ViewChild(FilterComponent, null) filterComp: FilterComponent;

  public generatePDF = (value?) => Util.generatePDF(value);
  public removeMask = (value?) => Util.removeMask(value);
  public userEmail;

  ngOnInit(): void {
    this.init();
    this.userEmail = localStorage.getItem('useremail')
  }

  ngOnDestroy() {
    sessionStorage.removeItem('tab');
    this.varSubscriptTabs.unsubscribe();
    this.varSubscriptTransacton.unsubscribe();
   
  }

  private init = () => {
    this.typToastColor = this.REDCOLOR;
    this.options = [
      { idOption: 'LIQUIDADO', option: 'Liquidados' },
      { idOption: 'AGENDADO', option: 'Agendados' },
    ];
  };

  ngAfterViewInit() {
    this.varSubscriptTabs = this.tabsDataShared.dataShared.subscribe((res) => {
      if (res && res.typeList) {
        this.selectedTab = (res && res.typeList);
        this.resetList();
         if (this.selectedTab === 'AGENDADO' || this.selectedTab === 'PENDENTE') {
          this.filterComp.initSchedules();
        } else {
          this.filterComp.initDiffSchedules();
        }
      }
    });
  };

  private resolveStatus(status?) {
    switch (status) {
      case 'AGENDADO':
        return '1';
      case 'LIQUIDADO':
        return '5';
      default:
        return '';
    }
  }

  private isPaymentBatch() {
    return typeof this.searchStringValue === 'string' && this.searchStringValue.substr(0, 3) === 'IB_';
  }

  private requestSearch(filter?, type?, tabs?) {
    localStorage.removeItem('receipt-export-data');
    this.multiSelected = false;
    this.receiptList = [];
    this.checkedList = [];
    this.selected = [];
    this.shown = [];
    this.shownAll = false;
    startWith('');
    this.loading = true;
    this.resetList();
    this.hide = false;
    const params: ReceiptRequest = {
      dt_inicial: this.params.dt_Inicio,
      dt_final: this.params.dt_Final,
      num_conta: filter.account === 'Todos' ? '' : filter.account,
      status: tabs,
      tipo_transacao: type === 'Todos' || type === undefined ? '' : type,
      num_cpf_cnpj: '',
      cod_empresa: 0,
      search_string:  this.isPaymentBatch() ? '' : this.searchStringValue,
      ref_lote: this.isPaymentBatch() ? this.searchStringValue : '',
    };
    
    this.paramsExport = params;
    if(this.varSubscribeReceipt !== undefined)
      this.varSubscribeReceipt.unsubscribe();

    this.varSubscribeReceipt = this.receiptService.getList(params)
      .subscribe(
        this.requestSuccess,
        this.requestError,
        () => (this.loading = false)
      );
  }

  private verifySuccess(value) {
    const data = value.data.filter((e) => {
      if (e.desc_status === this.selectedTab || this.selectedTab === 'Tudo') {
        return e;
      }
    });
    if (value.data === '') {
      this.data = [];
    } else {
      this.data = Util.verifyArray(data);

      this.data.sort((a, b) =>
        a.dt_transacao > b.dt_transacao
          ? -1
          : b.dt_transacao > a.dt_transacao
            ? 1
            : 0
      );
    }
  }

  getTransactionByDate(dt: string){
    return this.data.filter(d => (moment(d.dt_transacao)).format("YYYY-MM-DD") == dt)
    //return this.data.filter(d => d.dt_transacao == dt)
  }

  getDates(){
    let datas = [...new Set(this.data.map(item => (moment(item.dt_transacao)).format("YYYY-MM-DD")       ))];
    //let datas = [...new Set(this.data.map(item => item.dt_transacao      ))];
    datas.filter((n,i) => datas.indexOf(n) == i);
    return datas;
  }

  public changeValue(newValue){
    if(newValue.length > 4){
      this.searchButtonEnable = true;
      this.searchStringValue = newValue
    }
    else{
      this.searchButtonEnable = false;
      this.searchStringValue = "";
    }
  }

  private requestSuccess = (value: TransferReceiptModel) => {
    this.verifySuccess(value);
    this.data = value.data;
    for(let i=0;i<this.data.length;i++){
      if (!(this.data[i].tipo_transacao || '').toLocaleLowerCase().includes('open')) {
        this.data[i].tipo_transacao = this.transferTypeToNumber(this.data[i].tipo_transacao);
      }
      if ((this.data[i].tipo_transacao || '').toLocaleLowerCase().includes('open')) {
        this.data[i].tipo_transacao = ' '+this.data[i].tipo_transacao;
      }
    }
    this.dataAll = this.data;
    localStorage.setItem(
      'receipt-export-data',
      JSON.stringify({
        data: this.data,
        type: this.type,
        date: this.params,
        tabs: this.selectedTab,
      })
    );
    this.hide = Util.isEmpty(this.data);
    this.checkStatus(this.data);
    this.loading = false;
  };

  private transferTypeToNumber(value) {
    if (value === 'DOC') {
      return "1-"+value;
    }
    if (value === 'PAGAMENTO') {
      return "2"+value;
    }
    if(value === "PIX"){
      return "3"+value;
    }
    if (value === 'TED') {
      return "4"+value;
    }
    if (value === 'TEF') {
      return "5"+value;
    }
    if(value === 'Devolução PIX') {
      return "6"+value;
    }
  }

  private requestError = (err) => {
    this.loading = false;
    this.data = [];
    this.hide = Util.isEmpty(this.data);
  };



  private totalValue() {
    this.total = [];
    this.multiSelected = false;
    for (const item of this.receiptList) {

      const element = item.cod_transacao;
      this.total.push(element);

    }

    if(this.total.length > 1){
      this.multiSelected = true;
    }
  }

  private resetAction() {
    this.checkedArrayIsNotEmpty = false;
    this.checkedArrayIsExpiredTicket = false;
    this.oneOrMorePendingChecked = false;
    this.isPoolPenddingselected = false;
    this.showConfirmationRadio = false;
    this.isExpiredTicketSelected = false;
    this.showRefusedOrApproveAction = false;
    this.oneOrMorePendingExpiredChecked = false;
    this.showConfirmation = false;
  }

  private resetList() {
    this.data = [];
    this.receiptList = [];
    this.checkedList = [];
  }

  private resetCheckBox() {
    this.checkedList = [];
    this.receiptList = [];
    this.total = 0;
    this.shownAll = true;
    this.checked('all');
  }

  private navigateToResource(router?: string): void {
    window.open(router);
  }

  public async tabsSelect(tabs) {
    this.isSchedules = tabs === 'AGENDADO' ;
    this.selectedTab = tabs;
    await this.requestSearch(
      this.params,
      this.type,
      this.resolveStatus(tabs)
    );
  }

  public changeReportView(event) {
    this.report = false;
  }

  public exportScreen(value) {
    if (value === 1) {
      this.report = true;
      // this.navigateToResource('/receipt-export');
      return;
    }

    this.postExportExcel.executeReceipt(this.paramsExport).subscribe((res) => {
      //this.successDownload(res);
      var currentdate = new Date();
      Util.handleFile(res, 'comprovantes_' + currentdate.getDate().toString() + '/' + (currentdate.getMonth() + 1).toString() + '/' + currentdate.getFullYear().toString());
      console.log(res);
    }, error => {
      Util.consoleDev('Erro:', error)
      var currentdate = new Date();
      Util.handleFile(error.error.text, 'comprovantes_' + currentdate.getDate().toString() + '/' + (currentdate.getMonth() + 1).toString() + '/' + currentdate.getFullYear().toString());
    });
  }

  public async searchString(value) {
    this.searchStringValue = value;
  }

  public async searchStringCommand() {
    var t = this.searchStringValue;
    let regexCPF = new RegExp("^([0-9]){3}\.([0-9]){3}\.([0-9]){3}\-([0-9]){2}$")
    let regexCNPJ = new RegExp("^([0-9]){2}\.([0-9]){3}\.([0-9]){3}\/([0-9]){4}\-([0-9]){2}$")
    let retCPF = regexCPF.exec(this.searchStringValue.trim())
    let retCNPJ = regexCNPJ.exec(this.searchStringValue.trim())
    if(retCPF != null || retCNPJ != null){
      this.searchStringValue = this.searchStringValue.replaceAll(".", "").replaceAll("-", "").replaceAll("/","");
    }
    await this.requestSearch(
      this.params,
      this.type,
      this.resolveStatus(this.selectedTab)
    );
  }

  public checkStatus = (data) => {
    const numArrayToAllow = [];
    if (data !== undefined) {
      if (isArray(data)) {
        data.forEach((e) => {
          if (e.desc_status === 'LIQUIDADO') {
            numArrayToAllow.push(e);
          }
        });
      }
      this.showCheckbox = data.length > 1;
      return;
    }
    this.showCheckbox = true;
  };


  
  public checked(idx, data?: ReceiptList) {
   
    if (idx !== 'all') {
      if (this.selected.find(x => x == data.cod_transacao) != null) {
        let index = this.selected.findIndex(x => x == data.cod_transacao);
        this.selected.splice(index, 1);
      } else {
        this.selected.push(data.cod_transacao);
      }

      this.showRefusedOrApproveAction = false;

      if (this.receiptList.indexOf(data) === -1) {
        this.checkedList.push(data.cod_transacao);
        this.receiptList.push(data);
      } else {
        this.receiptList.splice(this.receiptList.indexOf(data), 1);
        this.checkedList.splice(this.checkedList.indexOf(data.cod_transacao), 1);
      }

      if(this.receiptList.length < this.data.length){
        this.shownAll = false;
      }
      else{
        this.shownAll = true;
      }
    } else {
      this.shownAll = !this.shownAll;
      this.receiptList = [];
      this.checkedList = [];
      this.selected = [];
      this.data.map((item, index) => {
        const elem = document.getElementById(
          item.cod_transacao.toString()
        ) as any;
        elem.checked = this.shownAll;
        this.shown[index] = this.shownAll;

        if(this.shownAll){
          let transaction = elem.dataId;
          this.receiptList.push(transaction);
        }
        this.totalValue();
      });
    }
    this.objRequest = {
      lista_transacoes: this.checkedList,
    };
    if (this.objRequest && this.objRequest.lista_transacoes.length == 0) {
      this.resetAction();
    }
    this.totalValue();
  }

  public closeModal = () => {
    this.actionConfirmed = false;
    this.cancelConfirmation();
  };

  public changeTypeApprove(value) {
    this.actionAproveOrRefuse = value;
    this.channelEnum = value;
  }

  

  public cancelConfirmation() {
    this.showRefusedOrApproveAction = false;
    this.actionAproveOrRefuse = false;
    this.resetAction();
    this.resetCheckBox();
  }

  public confirmAction() {
    this.actionConfirmed = true;
    this.resetAction();
  }

  public downloadReceipt(item) {
    this.receiptService.getPdf(item.cod_transacao).subscribe((data:any) => {
      var oReq = new XMLHttpRequest();
      oReq.open("GET", data['data'].url, true);
      oReq.responseType = 'blob';
      oReq.onload = function (event) {
        let blob = new Blob([oReq.response], { type: 'application/pdf' });
        var file = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = data['data'].nome_arquivo;
        a.click();
      }
      oReq.send();
      this.typToastColor = this.GREENCOLOR;
      this.toast.callModalMessage(null, 'Comprovante baixado com sucesso.');
    },(err) =>{
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o comprovante.');
    });
  }

  public multiDownloadPdf() {
    this.receiptService.getPdfList(this.total).subscribe((data:any) => {
      var oReq = new XMLHttpRequest();
      oReq.open("GET", data['data'].url, true);
      oReq.responseType = 'blob';
      oReq.onload = function (event) {
        let blob = new Blob([oReq.response], { type: 'application/pdf' });
        var file = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = data['data'].nome_arquivo;
        a.click();
      }
      oReq.send();
    });
  }

  public setFilter(event?) {
    this.count = 0;
    if(this.varSubscriptTransacton !== undefined)
      this.varSubscriptTransacton.unsubscribe();
      
    this.varSubscriptTransacton = this.transactionsDataShare.dataShared.subscribe((res) => {
      startWith('');
      this.type = res.type;
      this.params = event;
      this.requestSearch(
        this.params,
        this.type,
        this.resolveStatus(this.selectedTab)
      );
    });
  }

  private successDownload = (value) => {
    if (value.status === 'success') {
      // const blob = new Blob([value.data], { type: 'application/octet-stream' });
      Util.handleFile(value.data, 'arquivo');
    }
  };

  public verifyCalendar(event) {
    if (event === 'isGreaterThan730Days') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 730 dias.',
        null,
        null
      );
      return;
    }

    if (event === 'outOfReach') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 180 dias.',
        null,
        null
      );
      return;
    }
  }

  public tratarTipoTransacao(tipoTransacao: any){
    if(tipoTransacao === undefined)
      return '';
    return tipoTransacao.substring(1);
  }

  public tratarBanco(banco: any){
var retorno = banco.replace(/^([^-]+\-){1}/, '').trim();
    return retorno;
  }

  public showReceiptMaster(obj: any) {
    this.isPendent = obj.every((e) => e.tipo_acesso === 'Master').length > 1;
    return obj.some((e) => e.tipo_acesso === 'Master');
  }

  public showReceiptOperator(obj: any) {
    return obj.some((e) => e.tipo_acesso === 'Operador');
  }
  public checkOperator(obj: any) {
    return obj.find((x: { tipo_acesso: string; }) => x.tipo_acesso === 'Operador') != null;
  }
  verificaSelected(i: any) {
    return this.selected.find(x => x == i) != null;
  }
}
