<div class="card-content" id="test">
    <div *fibraHasRole="'APROV_TRANS.CONS_TRANS, APROV_TRANS.APROV_REPROV_TRANS,'" class="display-flex-card">
        <div class="fibra-card" *ngFor="let item of dataPendency">
            <div class="fibra-card-content">
                <div class="fibra-card-icon">
                    <img src="/assets/png/icon-ellipse-transfer.png" *ngIf="item.tipo_pendencia.toUpperCase() === 'EMISSÃO DE TÍTULOS'" />
                    <img src="/assets/svg/payment.svg" *ngIf="item.tipo_pendencia.toUpperCase() === 'BOLETO'" />
                    <img src="/assets/svg/icon-pgto-pendente.svg" *ngIf="item.tipo_pendencia.toUpperCase() === 'PAGAMENTO' || item.tipo_pendencia.toUpperCase() === 'PAGAMENTOS'" />
                    <img src="/assets/png/icon-ellipse-transfer.png" *ngIf="
            item.tipo_pendencia.toUpperCase() === 'TEF' || item.tipo_pendencia === 'TEFs' ||
            item.tipo_pendencia.toUpperCase() === 'TED' || item.tipo_pendencia === 'TEDs' ||
            item.tipo_pendencia.toUpperCase() === 'DOC'
          " />
                    <img src="/assets/svg/adhesion.svg" *ngIf="item.tipo_pendencia.toUpperCase() === 'ADESÃO AO DDA'" />
                    <img src="/assets/svg/payment.svg" *ngIf="item.tipo_pendencia.toUpperCase() === 'SALDO'" />
                    <img src="/assets/svg/pix.svg" *ngIf="item.tipo_pendencia.toUpperCase() === 'PIX'"/>
                    <img src="/assets/svg/openbanking-pendency.svg" *ngIf="item.tipo_pendencia.toUpperCase() === 'OPEN FINANCE PIX'"/>
                </div>
                <div class="fibra-card-title">
                    Você tem {{ item.num_pendencias }} {{item.num_pendencias > 1 ? 'transações' : 'transação'}} {{ item.tipo_pendencia }} {{item.num_pendencias > 1 ? 'pendentes' : 'pendente'}} de aprovação
                </div>
            </div>

            <div class="fibra-card-info" (click)="linkRedirect()">
                <label>Mais informações</label>
            </div>
        </div>
    </div>

    <div *fibraHasRole="'DER.CONS_DER'">
        <div class="fibra-card" *ngIf="derivativePendency">
            <div class="fibra-card-content">
                <div class="fibra-card-icon">
                    <img src="/assets/svg/dashDerivativo.svg" />
                </div>
                <div class="fibra-card-title">
                    Você tem operação de derivativo vencendo hoje.
                </div>
            </div>
            <div class="fibra-card-info" routerLink="/derivative">
                <label>Mais informações</label>
            </div>
        </div>
    </div>

    <div *fibraHasRole="'OP_CRED.CONS_OP_CRED,OP_CRED.MAP_GAR'">
        <div class="fibra-card" *ngIf="parcelaVencendo">
            <div class="fibra-card-content">
                <div class="fibra-card-icon">
                    <img src="/assets/png/op-credito.png" />
                </div>
                <div class="fibra-card-title">
                    Você tem parcela de empréstimo vencendo hoje.
                </div>
            </div>
            <div class="fibra-card-info" routerLink="/loans">
                <label>Mais informações</label>
            </div>
        </div>
    </div>

    <div *fibraHasRole="'OP_CRED.CONS_OP_CRED,OP_CRED.MAP_GAR'">
        <div class="fibra-card" *ngIf="parcelaVencida">
            <div class="fibra-card-content">
                <div class="fibra-card-icon">
                    <img src="/assets/png/op-credito.png" />
                </div>
                <div class="fibra-card-title">
                    Você tem parcela de empréstimo vencida.
                </div>
            </div>
            <div class="fibra-card-info" routerLink="/loans">
                <label>Mais informações</label>
            </div>
        </div>
    </div>
    
    <div *fibraHasRole="'OP_CRED.CONS_OP_CRED,OP_CRED.MAP_GAR'">
        <div class="fibra-card" *ngIf="parcelaAvencer">
            <div class="fibra-card-content">
                <div class="fibra-card-icon">
                    <img src="/assets/png/op-credito.png" />
                </div>
                <div class="fibra-card-title">
                    Você tem parcela de empréstimo que vencerá em breve.
                </div>
            </div>
            <div class="fibra-card-info" routerLink="/loans">
                <label>Mais informações</label>
            </div>
        </div>
    </div>

    <div>
        <div class="fibra-card" *ngIf="hasNotificationPix">
            <div class="fibra-card-content">
                <div class="fibra-card-icon">
                    <img src="/assets/svg/pix.svg" />
                </div>
                <div class="fibra-card-title">
                    Você possui solicitação Pix pendente de aprovação. 
                </div>
            </div>
            <div class="fibra-card-info" routerLink="/pix">
                <label>Mais informações</label>
            </div>
        </div>
    </div>

    <div *fibraHasRole="'APROV_TRANS.CONS_TRANS, APROV_TRANS.APROV_REPROV_TRANS,'" class="display-flex-card">
        <div class="fibra-card" *ngFor="let item of dataPendenciesOpenBanking">
            <div class="fibra-card-content">
                <div class="fibra-card-icon">
                    <img src="/assets/svg/openbanking-pendency.svg"/>
                </div>
                <div class="fibra-card-title">
                    {{ item.descricao }}
                </div>
            </div>
            <div class="fibra-card-info" [routerLink]="item.tipo === 0 ? '/transaction-approval' : '/openbanking'">
                <label>Mais informações</label>
            </div>
        </div>
    </div>
</div>










<div class="buttons-scroll" *ngIf="showButton">
    <div class="button">
        <div (click)="scrollBy('left')">
            <img src="/assets/svg/deriva-seta-left.svg" />
        </div>
    </div>
    <div class="button">
        <div (click)="scrollBy('right')">
            <img src="/assets/svg/deriva-seta-right.svg" />
        </div>
    </div>
</div>