<div style="margin-top:10vh">
    <div class="row mt-25">
        <div class="cheque-empresa-table">

            <div class="company-check-content">

                <div *ngIf="loading" class="loading-content">
                    <div class="text-center loading">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Carregando dados...</span>
                        </div>
                    </div>
                </div>


                <div *ngIf="hasData" class="company-check-header">
                    <div class="col-header width-25">Conta</div>
                    <div class="col-header width-15">Limite cheque empresa</div>
                    <div class="col-header width-10">Data de contratação</div>
                    <div class="col-header width-10">Data de vencimento</div>
                    <div class="col-header numeric width-8">Taxa de juros efetiva(%a.m.)</div>
                    <div class="col-header numeric width-8">Taxa de juros efetiva(%a.a.)</div>
                    <div class="col-header numeric width-8">Alíquota de IOF diária</div>
                    <div class="col-header numeric width-8" style="margin-left: 2px;">Alíquota de IOF adicional</div>
                    <div class="col-header numeric width-8">Carência (dias)</div>
                </div>

                <div *ngIf="!loading && !hasData && !hasError" class="no-show-information">
                    <fibra-generic-info
                    type="warn"
                    title="Nenhum resultado encontrado"
                    message="Não há informações a serem exibidas"
                  ></fibra-generic-info>
                </div>
                <div class="empty-info no-show-information" *ngIf="hasError">
                    <fibra-generic-info
                    type="error"
                    title="Ocorreu um erro"
                    message="Não foi possível carregar suas operações. Tente novamente mais tarde."
                  ></fibra-generic-info>
                </div>


                <div class="company-check-body" *ngIf="hasData">
                    
                    <table class="table" *ngIf="hasData" class="table company-check-table">

                        <tbody style="line-height:1.5">
                            <tr *ngFor="let item of loansList">
                                <td class="col-value width-25">{{ item.nro_conta }} - {{ returnChequeEmpresa(item.descricao_modalidade) }}</td>
                                <td class="col-value width-15">{{ item.valor_limite | currency: 'BRL' }}</td>
                                <td class="col-value width-10">{{ item.dt_contratacao | date: 'dd/MM/yyyy' }}</td>
                                <td class="col-value width-10">{{ item.data_vencimento | date: 'dd/MM/yyyy' }}</td>
                                <td class="col-value numeric width-8">{{ item.taxa_juros_mes.toString().replace(".",",") }}%</td>
                                <td class="col-value numeric width-8">{{ item.taxa_juros_efetiva.toString().replace(".",",") }}%</td>
                                <td class="col-value numeric width-8">{{ item.aliquota_iof_diaria.toString().replace(".",",") }}%</td>
                                <td class="col-value numeric width-8">{{ item.aliquota_iof_adicional.toString().replace(".",",") }}%</td>
                                <td class="col-value numeric width-8"><span>{{ item.num_dias_carencia }}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="hasData" class="texto-informativo">
            *Valores sujeitos a confirmação no momento da liquidação.
        </div>
    </div>

</div>
