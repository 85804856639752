import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import { DropdownItem } from '@src/core/domain/business-models';
import { isArray } from 'util';
import { empresasCapitalGiro } from '@src/presentation/web/components/banner/banner.capitalgirao.empresas';
import { pequenasEmpresasCapitalGiro } from '@src/presentation/web/components/banner/banner.capitalgirao.pe';

@Component({
  selector: 'fibra-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  providers: [NgbCarouselConfig]
})
export class BannerComponent implements OnInit {

  private _company: DropdownItem;
  @Input() public fibraFacil: boolean = false;

  public get limiteFibraFacilAumentou(): boolean {
    return false; // ['19572597000177', '02456955000183'].indexOf(this._company.cpfCnpj) > -1;
  }

  public get limiteChequeEmpresaAumentou(): boolean {
    return false; // ['06128996000100', '75284224000171', '08826385000152', '28347519000160', '14591542000190', '01025974000192'].indexOf(this._company.cpfCnpj) > -1;
  }

  public get showBannerCapitalGiroEmpresa(): boolean {
    if (this._company && this._company.cpfCnpj) {
      return empresasCapitalGiro.indexOf(this._company.cpfCnpj) > -1;
    }

    return false;
  }

  public get showBannerCapitalGiroPE(): boolean {
    if (this._company && this._company.cpfCnpj) {
      return pequenasEmpresasCapitalGiro.indexOf(this._company.cpfCnpj) > -1;
    }

    return false;
  }

  constructor(config: NgbCarouselConfig, private _router: Router, private _sharedCompany: CompanyDataShare) {
    config.interval = 10000;
    config.keyboard = false;
    config.showNavigationArrows = false;
    config.pauseOnHover = true;
  }

  redirecionaParaChequeEmpresa(e) {
    this._router.navigate(['/loans'], { queryParams: { chequeempresa: true }});
  }

  ngOnInit(): void {
    this._sharedCompany.dataShared.subscribe((res) => {
      if (res !== undefined && isArray(res.companyList)) {
        this._company = res.companyList.filter((e) => e.selected)[0];
      }
    });
  }
}

