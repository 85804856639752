
  <div class="modal-header" style="border: none; padding:10px 10px ;">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true" style="color: #082A4D;">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="padding: 0">
    <div class="row">
      <div class="col-12 d-flex justify-content-center my-4">
          <img class="image" src="/assets/svg/information.svg" alt="pix-image">
      </div>
      <div class="col-12 px-5 text-center text-information">
        <p>Antes de realizar uma reclamação no Banco Central do Brasil, tente resolver o problema junto à nossa área de atendimento.</p>
      </div>
      <div class="col-12 d-flex justify-content-center mt-4">
        <button class="button-primary " (click)=" goToSiteBacen()"><span> Canal de Atendimento</span> <br><span> Banco Central do Brasil</span></button>
      </div>
      <div class="col-12 d-flex justify-content-center my-4">
        <button class="button-secondary" (click)="openChat()"><span> Canal de Atendimento</span> <br><span> Banco Fibra</span></button>
      </div>
    </div>
  </div>
