<div *ngIf="exits != undefined && !loading">
  <ul *ngFor="let exit of exits" class="row-list">
    <li class="date-sale row">
      <span class="date col-lg-5">{{ formatDate(exit.data) }}</span>
      <span class="sale col-lg-7">Saldo do dia:
        <strong>R$ {{ !parseValue(exit.vlr_saldo_dia) ? "0,0" : (exit.vlr_saldo_dia | number: "1.2-2") }}
        </strong></span>
    </li>
    <li class="list-group-item" style="height: auto !important;" *ngFor="let items of exit.lancamentos">
      <p class="date-list">
        <img [src]="iconPath(items.cod_deb_cred, items.desc_tipo_transacao)" alt="" /> {{ items.desc_historico | titlecase
        }}
        <span class="value red">R$ -{{ items.vlr_lancamento | money }}
          <!-- <img
            src="assets/svg/comprovante.svg"
            alt="Comprovante"
            class="ckeck"
          /> -->
        </span>
        <span style="display:block;font-size:13px;margin-top: 5px;margin-left:2.0%;color: #8F969D; display: block">
          <span *ngIf="temDescricao(items) && HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)"
            class="text-desc data-hj-suppress">{{ items.compl_det.desc_nome }}</span>
          <br *ngIf="temDescricao(items) && HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)"
          />
          <span class="text-desc" *ngIf="items.desc_historico.toUpperCase().indexOf('PAGTO TIT') !== -1">{{ items.compl_det.desc_finalidade
            }}</span>
          <br *ngIf="items.desc_historico.toUpperCase().indexOf('PAGTO TIT') !== -1" />
          <span class="text-desc" *ngIf="HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)">CPF/CNPJ:
            {{ formatCpfCnpj(items.num_cpf_cnpj) }}</span>
          <br *ngIf="HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)"
          />
          <span *ngIf="temInstituicao(items) && HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)"
            class="text-desc">Instituição: {{ items.compl_det.dados_conta.nome_banco }}
          </span>
          <span class="text-desc" *ngIf="HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)">CC: {{ formatAccountNumber(items.compl_det.dados_conta.num_conta) }} | AG: {{ items.compl_det.dados_conta.cod_agencia?.padStart(4,
            '0') }}</span>
          <span *ngIf="temChave(items)" class="text-desc">Chave: {{ items.compl_det.dados_conta.chave }}</span>
        </span>
      </p>
    </li>
  </ul>
</div>

<!-- Loading -->
<div class="d-flex justify-content-center position loading-container" *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="empty-info" *ngIf="hide && !loading">
  <fibra-generic-info type="warn" title="Nenhum resultado encontrado" message="Tente uma nova pesquisa inserindo novos parâmetros.">
  </fibra-generic-info>
</div>