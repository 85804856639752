<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>
<div class="content-page">
  <fibra-breadcrumb routeBack="/admin-user" bottom="-20" infoAccount="Mais" roule="Administração de usuários">
  </fibra-breadcrumb>
  <div class="arrowView" (click)="prevStep()">
    <img class="arrowBack" src="/assets/svg/arrow_page.svg" alt="setaAvancar" />
    <span class="textBack">Voltar</span>
  </div>
  <div *ngIf="!isEditMode">
    <div class="titleView">
      <span class="titleRegister">Cadastro de usuário</span>
    </div>
  </div>
  <div class="title-edit-box" *ngIf="isEditMode">
    <span class="title-edit-sub">Editando usuário: </span> <span
      class="title-edit-name" data-hj-suppress>{{dataPersonal.nm_pessoa}}</span>
  </div>
  <ng-template #firstStep>
    <div *ngIf="!loading">
      <div class="info_personal">
        <div class="info_personal-title">
          <span>Dados pessoais</span>
        </div>
        <div class="info_personal-user">
          <label>Nome do usuário</label>
          <input [(ngModel)]="dataPersonal.nm_pessoa" placeholder="Digite o nome completo" type="text"
            (keyup)="validateEmptyInputs()" />
          <span class="error-msg" *ngIf="isEmpty(dataPersonal.nm_pessoa)"><strong>Nome do usuário</strong> é
            de preenchimento obrigatório</span>
          <span class="error-msg" *ngIf="isLength(dataPersonal.nm_pessoa, 3)">* Campo <strong>nome do usuário</strong> é
            inválido</span>
        </div>
        <div class="info_personal-birth">
          <label>Data de nascimento</label>
          <fibra-datepicker [(date)]="date" (dateSelected)="changeDate($event)" [minDate]="minDate" [maxDate]="maxDate"
            placement="top"></fibra-datepicker>
          <span class="error-msg" *ngIf="isEmpty(dataPersonal.dt_nascimento)"><strong>Data de
              nascimento</strong>
            é de preenchimento obrigatório</span>
          <span class="error-msg" *ngIf="!validateBirthday(dataPersonal.dt_nascimento)">Idade do usuário deve ser maior
            que 18 anos</span>

        </div>
        <div class="info_personal-cpf">
          <label>CPF</label>
          <input [(ngModel)]="dataPersonal.num_cpf" [textMask]="addMask(cpfMask)" class="form-control" id="cpf" maxlength="14" name="cpf" placeholder="000.000.000-00" type="text" (keyup)="validateEmptyInputs()" [attr.disabled]="isEditMode ? 'true' : null"/>
          <span class="error-msg" *ngIf="isEmpty(dataPersonal.num_cpf)"><strong>CPF</strong> é de preenchimento obrigatório</span>
          <span class="error-msg" *ngIf="!validateCPF(dataPersonal.num_cpf)">* Campo <strong>CPF</strong> é inválido</span>
          <span class="error-msg" *ngIf="cpfDuplicado"><strong>CPF</strong> já cadastrado</span>
        </div>
        <!--<div class="row info_personal-document">
          <div class="col">
            <label>Documento</label>
          </div>
        </div>
        <div class="row">
          <div class="col-auto document-inputs document-inputs-error">
            <fibra-dropdown [(ngModel)]="dataPersonal.tipo_documento" [options]="arrTypeDocuments"
              [style.width.px]="328" customClass="select-option" placeholder="Selecionar documento" removeShadown="true"
              (callback)="validateEmptyInputs()">
            </fibra-dropdown>
            <span class="error-msg" *ngIf="isEmpty(dataPersonal.tipo_documento)"> <strong>Tipo do
                documento</strong> é de preenchimento obrigatório</span>
          </div>
          <div *ngIf="dataPersonal.tipo_documento === 1" class="col-auto">
            <input [(ngModel)]="dataPersonal.desc_orgao_emissor" [textMask]="addMask(emitterMask)" class="form-control"
              placeholder="Orgão emissor" maxlength="6" type="text" (keyup)="validateEmptyInputs()" />
            <span class="error-msg" *ngIf="isEmpty(dataPersonal.desc_orgao_emissor)"><strong>Orgão
                emissor</strong> é de preenchimento obrigatório</span>
            <span class="error-msg" *ngIf="isLength(dataPersonal.desc_orgao_emissor, 3)">* Campo <strong>Orgão
                emissor</strong> é inválido</span>
          </div>
          <div *ngIf="dataPersonal.tipo_documento === 1" class="col-auto">
            <input [(ngModel)]="dataPersonal.num_rg" [textMask]="addMask(lettersNumbersMask)" maxlength="14"
              class="form-control" placeholder="Número do documento" type="text" (keyup)="validateEmptyInputs()" />
            <span class="error-msg" *ngIf="isEmpty(dataPersonal.num_rg)"><strong>Número do documento</strong> é de
              preenchimento obrigatório</span>
            <span class="error-msg" *ngIf="isLength(dataPersonal.num_rg, 7)">* Campo <strong>Número do
                documento</strong>
              é inválido</span>
          </div>
          <div *ngIf="dataPersonal.tipo_documento === 2" class="col-auto">
            <input [(ngModel)]="dataPersonal.cnh" [textMask]="addMask(cnhMask)" maxlength="11" class="form-control"
              placeholder="CNH" type="text" (keyup)="validateEmptyInputs()">
            <span class="error-msg" *ngIf="isEmpty(dataPersonal.cnh)"><strong>CNH</strong> é de preenchimento
              obrigatório</span>
            <span class="error-msg" *ngIf="isLength(dataPersonal.cnh, 11)">* Campo <strong>CNH</strong> é
              inválido</span>
          </div>
          <div *ngIf="dataPersonal.tipo_documento === 3" class="col-auto">
            <input [(ngModel)]="dataPersonal.rne" maxlength="9" [textMask]="addMask(rneMask)" class="form-control"
              placeholder="RNE" type="text" (keyup)="validateEmptyInputs()">
            <span class="error-msg" *ngIf="isEmpty(dataPersonal.rne)"><strong>RNE</strong> é de preenchimento
              obrigatório</span>
            <span class="error-msg" *ngIf="isLength(dataPersonal.rne, 9)">* Campo <strong>RNE</strong> é inválido</span>

          </div>
          <div *ngIf="dataPersonal.tipo_documento === 4" class="col-auto">
            <input [(ngModel)]="dataPersonal.cif" [textMask]="addMask(lettersNumbersMask)" maxlength="20"
              [style.width.px]="400" class="form-control" placeholder="Carteira de Identidade Funcional" type="text"
              (keyup)="validateEmptyInputs()">
            <span class="error-msg" *ngIf="isEmpty(dataPersonal.cif)"><strong>Carteira de Identidade
                Funcional</strong> é de preenchimento obrigatório</span>
          </div>
          <div *ngIf="dataPersonal.tipo_documento === 5" class="col-auto">
            <input [(ngModel)]="dataPersonal.cp" maxlength="7" [textMask]="addMask(cpMask)" class="form-control"
              placeholder="Carteira Profissional" type="text" (keyup)="validateEmptyInputs()">
            <span class="error-msg" *ngIf="isEmpty(dataPersonal.cp)"><strong>Carteira Profissional</strong> é de
              preenchimento obrigatório</span>
            <span class="error-msg" *ngIf="isLength(dataPersonal.cp, 6)">* Campo <strong>Carteira Profissional</strong>
              é inválido</span>
          </div>
        </div>-->
      </div>
      <div class="divisor2"></div>
      <div class="info_contact">
        <div class="info_contact-title">
          <span>Contatos</span>
        </div>
        <div class="info_contact-number">
          <label>Número do celular</label>
          <input [(ngModel)]="dataPersonal.num_celular" [textMask]="addMask(mobileMask)" maxlength="19" placeholder="(00) 00000-0000" type="text" id="cellphone" name="cellphone" (keyup)="validateEmptyInputs()" [attr.disabled]="isEditMode ? 'true' : null" />
          <span class="error-msg" *ngIf="isEmpty(dataPersonal.num_celular)"><strong>Número do celular</strong> é de preenchimento obrigatório</span>
          <span class="error-msg" *ngIf="isLength(dataPersonal.num_celular, 15)">* Campo <strong>Número do celular</strong> é inválido</span>
          <span class="error-msg" *ngIf="celularDuplicado"><strong>Número de celular</strong> já cadastrado</span>
        </div>
        <div class="info_contact-email">
          <label>E-mail</label>
          <input [(ngModel)]="dataPersonal.desc_email" name="email" placeholder="usuario@empresa.com.br" type="email" maxlength="77" (keyup)="validateEmptyInputs()" [attr.disabled]="isEditMode ? 'true' : null"/>
          <span class="error-msg" *ngIf="isEmpty(dataPersonal.desc_email)"><strong>E-mail</strong> é de preenchimento obrigatório</span>
          <span class="error-msg" *ngIf="!validEmail(this.dataPersonal.desc_email)">* Campo <strong>E-mail</strong> é inválido</span>
          <span class="error-msg" *ngIf="emailDuplicado"><strong>E-mail</strong> já cadastrado</span>
        </div>
      </div>
      <div class="divisor2"></div>
      <div class="info_professionals">
        <div class="info_professionals-title">
          <span>Dados profissionais</span>
        </div>
        <div class="row info_professionals-departament">
          <div class="col">
            <label>Departamento</label>
          </div>
        </div>
        <div class="row">
          <div class="col  document-inputs document-inputs-error">
            <fibra-dropdown [(ngModel)]="dataPersonal.cod_departamento" [options]="arrDepartment"
              [style.width.px]="400" customClass="select-option" placeholder="Selecionar o departamento"
              removeShadown="true" (callback)="validateEmptyInputs()">
            </fibra-dropdown>
            <span class="error-msg" *ngIf="isEmpty(dataPersonal.cod_departamento)"><strong>Departamento</strong>
              é de preenchimento obrigatório</span>
          </div>
        </div>
        <div class="info_professionals-office row">
          <div class="col">
            <label>Cargo</label>
          </div>
        </div>
        <div class="row">
          <div class="col document-inputs document-inputs-error">
            <fibra-dropdown [(ngModel)]="dataPersonal.cod_cargo" [options]="arrCargo" [style.width.px]="400"
              customClass="select-option" placeholder="Selecionar o cargo" removeShadown="true"
              (callback)="validateEmptyInputs()"></fibra-dropdown>
            <span class="error-msg" *ngIf="isEmpty(dataPersonal.cod_cargo)"><strong>Cargo</strong> é de preenchimento
              obrigatório</span>
          </div>
        </div>
      </div>
      <div class="footer-button">
        <button (click)="prevStep()" class="btn-formatted btn-cancel preview">Cancelar</button>
        <button (click)="validateStep1()" class="btn-formatted btn-dark btn-advance next"
          [ngClass]="buttonEnabled ? 'btn-formatted btn-dark btn-advance next' : 'btn-formatted btn-dark btn-advance next btn-disabled'">Avançar</button>
      </div>
    </div>

    <div *ngIf="loading" class="d-flex justify-content-center position loading-container">
      <div class="spinner-border text-primary" role="status"></div>
    </div>

  </ng-template>

  <!-- STEP / 2 -->
  <ng-template #secondStep>
    <div class="check_selector">
      <div class="form-check check_individual radio">
        <input checked [(ngModel)]="stepFlow" class="form-check-input" id="individualRadio" name="stepFlow" type="radio"
          value="Individual" />
        <label class="form-check-label" for="individualRadio">
          Individual
        </label>
        <div class="check_individual-span">
          Adicione contas e permissões individuais para o usuário.
        </div>
      </div>
      <div class="form-check check_group">
        <input [(ngModel)]="stepFlow" class="form-check-input" id="grupoRadio" name="stepFlow" type="radio"
          value="Grupo" />
        <label class="form-check-label" for="grupoRadio"> Grupo </label>
        <div class="check_group-span">
          Adicione o usuário a grupos com contas e permissões já pre-definidas.
        </div>
      </div>
    </div>
    <div class="footer-button">
      <button (click)="prevStep()" class="btn-formatted btn-cancel-step2 preview">Voltar</button>
      <button (click)="nextStep()" class="btn-formatted btn-advance-step2 next">Avançar</button>
    </div>
  </ng-template>

  <!-- STEP / 3 -->
  <ng-template #thirdStep>
    <div *ngIf="stepFlow === 'Individual'" class="step3">
      <div class="admin-title">
        <span>Adicionar conta</span>
      </div>
      <div class="box-account">
        <div class="container p-0">
          <div class="row">
            <div class="col document-inputs">
              <fibra-dropdown [(ngModel)]="newAccount" [options]="optionsAccount" [style.width.px]="575"
                customClass="select-option select-option-blue" placeholder="Selecionar conta" removeShadown="true">
              </fibra-dropdown>
            </div>
            <div class="col">
              <button (click)="addAccount()" [disabled]="!newAccount" class="btn-full btn-adicionar">
                Adicionar
              </button>
            </div>
          </div>
        </div>
        <div class="dell-selected-box" *ngIf="accountsCheckbox.length > 1">
          <div class="row">
            <div class="col">
              <div class="btn-delete" (click)="delAccount(accountsCheckbox)">
                <img class="trashIconButton" src="/assets/svg/trash.svg" />
                <span class="text">Excluir selecionados</span>
              </div>
            </div>
          </div>
        </div>
        <div *ngFor="let conta of listAccount" class="list-account container">
          <div class="row list-account-row" id="row-edit-{{conta.account.num_conta}}">
            <div class="col-auto justify-content-center align-self-center">
              <input name="accounts" [value]="conta.account.num_conta" (click)="setAccount($event)" type="checkbox">
            </div>
            <div class="col justify-content-center align-self-center">
              {{conta.account.num_conta}} - {{(conta.account.exibicao || conta.account.desc_conta) | titlecase}}
            </div>
            <div class="action-list-account">
              <div id="btn-radio-{{conta.account.num_conta}}" *ngIf="typeAccount === conta.account.num_conta"
                class="col justify-content-center align-self-center">
                <input type="radio" [(ngModel)]="typeAccount" name="type_account" autocomplete="off"
                  [value]="conta.account.num_conta" checked type="radio"> Conta
                Padrão
              </div>
              <div id="btn-radio-{{conta.account.num_conta}}" *ngIf="typeAccount !== conta.account.num_conta"
                class="col justify-content-center align-self-center">
                <input type="radio" [(ngModel)]="typeAccount" name="type_account" autocomplete="off"
                  [value]="conta.account.num_conta" (click)="setDefaultAccount(conta.account.num_conta, $event)"
                  type="radio"> Tornar conta
                padrão
              </div>
              <div class="col-auto align-middle document-inputs">
                <div id="btn-edit-{{conta.account.num_conta}}"
                     (click)="showPermissions(typeAccount, conta.account.num_conta)" class="btn-edit" data-toggle="collapse"
                     [attr.data-target]="'#collapseAccount' + conta.account.num_conta" aria-expanded="false"
                     aria-controls="collapseAccount{{conta.account.num_conta}}">
                  <span>Editar permissões</span>
                  <img src="/assets/png/dropdown.png" class="arrowIcon" />
                </div>
              </div>
              <div *ngIf="accountsCheckbox.length <= 1" class="col-auto justify-content-center align-self-center">
                <img (click)="delAccount([{ num_conta: conta.account.num_conta}])" src="/assets/svg/trash.svg">
              </div>
            </div>
          </div>

          <div class="row row-hide list-account-row" id="row-edit-close-{{conta.account.num_conta}}">
            <div class="col-auto justify-content-center align-self-center">
              <input name="accounts" [value]="conta.account.num_conta" (click)="setAccount($event)" type="checkbox">
            </div>
            <div class="col justify-content-center align-self-center">
              {{conta.account.num_conta}} - {{conta.account.desc_conta | titlecase}}
            </div>
            <div class="action-list-account">
              <div class="col justify-content-center align-self-center">

              </div>
              <div class="col justify-content-center align-self-center">

              </div>
              <div class="col-auto align-middle document-inputs">
                <div id="btn-edit-{{conta.account.num_conta}}" (click)="closeAccount(conta.account.num_conta)"
                  class="btn-edit" data-toggle="collapse"
                  [attr.data-target]="'#collapseAccount' + conta.account.num_conta" aria-expanded="false"
                  aria-controls="collapseAccount{{conta.account.num_conta}}">
                  <span>Editar Fechar</span>
                  <img src="/assets/png/dropdown.png" class="arrowIcon" />
                </div>
              </div>
              <div *ngIf="accountsCheckbox.length <= 1" class="col-auto justify-content-center align-self-center">
                <img (click)="delAccount([{ num_conta: conta.account.num_conta}])" src="/assets/svg/trash.svg">
              </div>
            </div>
          </div>

          <div id="row-import-{{conta.account.num_conta}}" class="row list-account-row-import row-hide">
            <div class="col account-dark">
              {{conta.account.num_conta}} - {{conta.account.desc_conta | titlecase}}
            </div>
            <div class="col-auto align-middle document-inputs">
              <div id="btn-import-{{conta.account.num_conta}}" class="btn-edit btn-hide"
                *ngIf="typeAccount !== conta.account.num_conta" (click)="importDefaultAccount(conta.account.num_conta)">
                <span>Importar permissões da conta padrão</span>
              </div>
            </div>
          </div>
          <div class="list-account-box  collapse" id="collapseAccount{{conta.account.num_conta}}">
            <div class="row list-account-row">
              <div class="col">
                <fibra-accordion [collapseID]="conta.account.num_conta" [listPermissions]="conta.functionalities"
                  [listAccount]="arrayAccountPayload" [showSelectAll]="true" (emitChange)="changeRule($event)"
                  (emitAllChange)="changeAllRule($event)"></fibra-accordion>
              </div>
            </div>
            <div class="row list-account-row">
              <div class="col"></div>
              <div class="col-auto" *ngIf="isEditMode">
                <button class="btn-formatted btn-out btn-cancel" data-toggle="collapse"
                  [attr.data-target]="'#collapseAccount' + conta.account.num_conta" aria-expanded="false"
                  aria-controls="collapseAccount{{conta.account.num_conta}}">Cancelar</button>
              </div>
              <div class="col-auto">
                <button (click)="closeAccount(conta.account.num_conta)"
                  class="btn-formatted btn-dark btn-salvar-fechar " data-toggle="collapse"
                  [attr.data-target]="'#collapseAccount' + conta.account.num_conta" aria-expanded="false"
                  aria-controls="collapseAccount{{conta.account.num_conta}}">Finalizar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="admin-title">
        <span>Permissões não atreladas a contas</span>
      </div>
      <div class="admin-subtitle">
        <span>As permissões abaixo são atreladas somente a funcionalidades e não a uma conta</span>
      </div>
      <fibra-accordion [collapseID]="0" [listPermissions]="arrayFunctionalitiesNotAccount"
        [listNotAccount]="arrayNotAccountPayload" [showSelectAll]="false" (emitChange)="changeRuleNotAccount($event)">
      </fibra-accordion>
    </div>
    <div *ngIf="stepFlow === 'Grupo'" class="step3">
      <div class="box-account">
        <div class="container p-0 groupInputView">
          <div class="row">
            <div class="col document-inputs">
              <fibra-dropdown [(ngModel)]="selectedGroup" [options]="listGroup" [style.width.px]="575"
                customClass="select-option" placeholder="Selecionar grupo" removeShadown="true">
              </fibra-dropdown>
            </div>
            <div class="col">
              <button (click)="addGroup()" [disabled]="!selectedGroup" class="btn-full btn-adicionar">
                Adicionar
              </button>
            </div>
          </div>
        </div>
        <div class="dell-selected-box" *ngIf="countCheckedGroups > 1">
          <div class="row">
            <div class="col">
              <div class="btn-delete" (click)="deleteGroups()">
                <img class="trashIconButton" src="/assets/svg/trash.svg" />
                <span class="text">Excluir selecionados</span>
              </div>
            </div>
          </div>
        </div>
        <div *ngFor="let group of addedGroups" class="groupCard">
          <div class="groupCardContent">
            <div class="justify-content-center align-self-center groupCheck">
              <input name="groups" type="checkbox" [value]="group.cod_grupo" (click)="setGroup($event)">
            </div>
            <div class="justify-content-center align-self-center groupName">
              {{group.desc_grupo}}
            </div>
            <div class="align-middle document-inputs groupUsers">
              <span>{{group.qtd_usuarios}} usuário{{group.qtd_usuarios > 1 ? 's' : ''}}</span>
            </div>
          </div>
          <div class="groupCardContent">
            <div class="justify-content-center align-self-center">
              <img (click)="deleteGroup(group.cod_grupo)" src="/assets/svg/trash.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-button mt-5">
      <button (click)="prevStep()" class="btn-formatted btn-cancel-step2 preview">Voltar</button>
      <div *ngIf="stepFlow === 'Grupo'; else StepFlowIndividual">
        <button (click)="register()" class="btn-formatted btn-dark btn-cadastrar-usuario"
          [ngClass]="addedGroups.length > 0 ? 'btn-formatted btn-dark btn-cadastrar-usuario' : 'btn-formatted btn-dark btn-cadastrar-usuario btn-disabled'">Cadastrar
          usuário</button>
      </div>
      <ng-template #StepFlowIndividual>
        <button (click)="register()" *ngIf="isEditMode" class="btn-formatted btn-dark btn-cadastrar-usuario ">Atualizar
          usuário</button>
        <button (click)="register()" *ngIf="!isEditMode" class="btn-formatted btn-dark btn-cadastrar-usuario ">Cadastrar
          usuário</button>
      </ng-template>
    </div>
  </ng-template>
  <fibra-steps *ngIf="!isEditMode" [currentStep]="currentStep" [firstStep]="firstStep" [secondStep]="secondStep"
    [showHeader]="true" [stepObj]="step" [titleSans]="true" [thirdStep]="thirdStep">
  </fibra-steps>
  <fibra-steps *ngIf="isEditMode" [currentStep]="currentStep" [firstStep]="firstStep" [secondStep]="thirdStep"
    [showHeader]="true" [titleSans]="true" [stepObj]="stepEdit">
  </fibra-steps>
  <fibra-modal-validation 
    *ngIf="showModalValidation" 
    [apiCallback]="apiCallback" 
    (closeEvent)="closeModal()"
    [apiPayload]="securityRequest()" 
    (responseValidation)="securityResponse($event)">
  </fibra-modal-validation>
  <fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>
</div>
