import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ComexReportDataShare } from '@src/core/usecases/data-share/comex-report-data-share.service';
import { AccAceEntity } from '@src/data/repository/export-repository/export.entity';
import { CobrancaEntity, CreditoEntity, FinimpEntity } from '@src/data/repository/import-repository/import.entity';
import { ChangeEntity } from '@src/data/repository/ready-change-repository/ready-change.entity';
import { ReceivedPaymentEntity } from '@src/data/repository/receveid-payment/received-payment.entity';
import { IssuedWarrantyEntity, ReceivedWarrantyEntity } from '@src/data/repository/warranties/warranties.entity';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as $ from 'jquery'; 

@Component({
  selector: 'fibra-foreign-trade-report',
  templateUrl: './foreign-trade-report.component.html',
  styleUrls: ['./foreign-trade-report.component.scss']
})
export class ForeignTradeReportComponent implements OnInit, OnDestroy{
  @Input() public changeArray: ChangeEntity[] ;
  @Input() public finimpArray: FinimpEntity[] ;
  @Input() public cobrancaArrayImport: CobrancaEntity[] ;
  @Input() public creditArrayImport: CreditoEntity[] ;
  @Input() public accAceArray: AccAceEntity[] ;
  @Input() public cobrancaArrayExport: CobrancaEntity[] ;
  @Input() public creditArrayExport: CreditoEntity[] ;
  @Input() public paymentArray: ReceivedPaymentEntity[] ;
  @Input() public receivedArray: ReceivedWarrantyEntity[] ;
  @Input() public issuedArray: IssuedWarrantyEntity[] ;
  @Output() public closeReport = new EventEmitter();
  public reportData;
  public date = new Date();
  public day;
  public hours;
  public company;
  public hide = false;
  public readyToLoad= false;
  public changeTotais;

  constructor(public comexReport: ComexReportDataShare) { }

  ngOnInit() {
    this.hide = false;
    this.day = this.date.toLocaleDateString();
    this.hours = this.date.toLocaleTimeString();

    this.changeTotais = JSON.parse(sessionStorage.getItem('ready-change-total'))
    this.company = JSON.parse(sessionStorage.getItem('defaultCompany'));
    if (this.company[0].modality_account.length === 11) {
      this.company[0].modality_account = this.company[0].modality_account.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      this.company[0].modality_account = this.company[0].modality_account.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, '$1.$2.$3/$4-$5');
    }
    this.reportData = this.comexReport.getReportInfo();
    this.readyToLoad = true;
    setTimeout(() => {
    this.generatePDF()
    }, 2000); 
   
  }

  ngOnDestroy() {
  }


  public generatePDF() {
   

    const data = document.getElementById('comprovante');
    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
    {
      html2canvas(data,{
        windowWidth: document.body.scrollWidth,
        windowHeight: document.body.scrollHeight,
        x: 0,
        y: window.pageYOffset,
      }).then((canvas) => {
        let imgWidth = 210;
        let pageHeight = 295;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        const imgData = canvas.toDataURL('image/jpeg');
        
        let doc = new jsPDF('p', 'mm', 'a4');
        let position = 0;
        
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        
        while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        }
        doc.save('fibra-comercio-exterior.pdf');
      });
    }
    else
    {
      html2canvas(data).then((canvas) => {
        let imgWidth = 210;
        let pageHeight = 295;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        const imgData = canvas.toDataURL('image/jpeg');
        
        let doc = new jsPDF('p', 'mm', 'a4');
        let position = 0;
        
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        
        while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        }
        doc.save('fibra-comercio-exterior.pdf');
      });
    }
    $("#ico-fechar").css({ display: "block" });
    this.goBack();
 
  }

  public goBack() {
    this.closeReport.emit('true');
  }
}

