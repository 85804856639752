import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IOpenBankingConsent } from '@src/core/domain/openbanking/openbanking.consent.model';
import { PixAccountEntity } from '@src/data/repository/pix/accounts/pix-accounts-entity';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { PixGetAccountsDataRepository } from '@src/data/repository/pix/accounts/pix-get-accounts-repository';

@Component({
    selector: 'fibra-openbanking-paymentrequest-newtransaction',
    templateUrl: './openbanking-paymentrequest-newtransaction.component.html',
    styleUrls: ['./openbanking-paymentrequest-newtransaction.component.scss']
})
export class OpenbankingPaymentRequestNewTransactionComponent implements OnInit, OnDestroy {
    
    private _subAccounts: Subscription;
    public loadingAccounts: boolean = false;

    public chequeEmpresaPix: boolean = false;
    public saldoDisponivel: boolean = true;
    public valorChequeEmpresa: number;
    public active: number = null;
    public showButton: boolean = true;

    @Input() public accounts: { items: PixAccountEntity[] };
    @Input() public selectedAccount: PixAccountEntity;
    @Input() public consent: IOpenBankingConsent;
    @Input() public disabled: boolean;
    @Input() public busy: boolean;
    @Output() public onOk: EventEmitter<PixAccountEntity> = new EventEmitter<PixAccountEntity>();
    @Output() public onCancel: EventEmitter<void> = new EventEmitter<void>();    
  
    public get currencyStr(): string {
        switch (this.consent.payment.currency) {
            case "BRL": return "R$";
            default: return "";
        }
    }

    public get paymentTypeStr(): string {
        switch (this.consent.payment.type) {
            case "PIX": return "Pix - Open Finance";
            default: return ""
        }
    }

    public get cpfCnpjCreditor(): string {
        if (this.consent.creditor && this.consent.creditor.personType && this.consent.creditor.cpfCnpj) {
            let result = this.consent.creditor.cpfCnpj.replace(/\./g, '').replace('-', '').replace('/', '');

            if (this.consent.creditor.personType == 'PESSOA_NATURAL') {
                result = '***.' + result.substr(3, 3) + '.' + result.substr(6, 3) + '-**';
            } else {
                result = result.substr(0, 2) + '.' + result.substr(2, 3) + '.' + result.substr(5, 3) + '/' + result.substr(8, 4) + '-' + result.substr(12, 2);
            }

            return result;
        }

        return null;
    }

    @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
    
    constructor(public getAccService: PixGetAccountsDataRepository) {
        
    }

    public UsaChequeEmpresa(){
        if (this.consent.payment.amount > this.selectedAccount.saldo.vlr_saldo_calculado) {
            const valorMenosSaldo = this.consent.payment.amount - this.selectedAccount.saldo.vlr_saldo_calculado;

            if (valorMenosSaldo > 0 && this.selectedAccount.saldo.vlr_cheque_disponivel < valorMenosSaldo) {
                this.toast.callModalMessage(null, 'Saldo Indisponivel!', null);
                this.saldoDisponivel = false;
                return false;
            } else if (valorMenosSaldo == 0) {
                this.saldoDisponivel = true;
                return false;
            } else {
                this.valorChequeEmpresa = valorMenosSaldo;
                this.saldoDisponivel = true;
                return true;
            }
        }
    }

    public closeModalPix(value){
        if (value == 'sim'){
            this.onOk.emit(this.selectedAccount);
        } else if (value == 'cancelar') {
            this.chequeEmpresaPix = false;
        }
    }    

    public ok(): void {        
        if (!this.busy) {
            if (this.selectedAccount.desc_conta.toUpperCase() == 'CHEQUE EMPRESA') {
                this.chequeEmpresaPix = this.UsaChequeEmpresa()
            }
    
            if (!this.chequeEmpresaPix && this.saldoDisponivel) {
                this.onOk.emit(this.selectedAccount);
            }                        
        }        
    }
    
    public cancel(): void {
        this.onCancel.emit();
    }

    private loadAccounts(): void {
        this.loadingAccounts = true;                    

        this._subAccounts = this.getAccService.getAccounts()
            .pipe(finalize(() => this.loadingAccounts = false))
            .subscribe(result => {
                this.accounts.items = result.data;

                if (this.consent.debtorAccount != null) {
                    this.accounts.items = this.accounts.items.filter(a => a.cod_agencia.substring(1, 5) == this.consent.debtorAccount.issuer && a.num_conta.replace('-', '') == this.consent.debtorAccount.number.replace('-', ''));
                    this.active = this.accounts.items[0].num_indice;
                    this.showButton = false;
                    this.selectedAccount = this.accounts.items[0];
                }                
            });
    }

    public selectAccount(account: PixAccountEntity): void {
        this.selectedAccount = account;
    }

    ngOnInit(): void {
        if (this.accounts.items.length == 0) {
            this.loadAccounts();            
        } else {
            this.showButton = this.consent.debtorAccount == null;
        }
    }

    ngOnDestroy(): void {
        if (this._subAccounts) {
            this._subAccounts.unsubscribe();
        }        
    }
}
