<div class="main-selector">
    <fibra-company-list [readonly]="true"></fibra-company-list>
</div>
<div class="content-page" id="content-body body-content" id="body-scroll">
    <fibra-breadcrumb infoAccount="Open Finance" [roule]="title" arrow="false"></fibra-breadcrumb>

    <div [ngSwitch]="currentPresentation">
        <div class="onboarding" *ngSwitchCase="ONBOARDING">
            <fibra-openbanking-onboarding (onOk)="okOnboarding()" (onCancel)="showCancelationDialog()"></fibra-openbanking-onboarding>
        </div>
        <div class="termsofuse" *ngSwitchCase="TERMSOFUSE">
            <div class="box">
                <img class="termsofuse-arrow" src="/assets/svg/openbankin-termsofuse-backarrow.svg" (click)="backToOnboarding()">
                <span style="margin-left: 5px;">Voltar</span>
            </div>
            <fibra-accept-doc class="termsofuseviewer" [doc]="termsOfUse" (confirm)="acceptTermsOfUse()"></fibra-accept-doc>
        </div>
        <div class="newtransaction" *ngSwitchCase="NEWTRANSACTION">
            <fibra-openbanking-paymentrequest-newtransaction [consent]="transaction.consentimento" [accounts]="accounts" [selectedAccount]="transaction.consentimento.selectedAccount" (onOk)="okNewTransaction($event)" (onCancel)="showCancelationDialog()"></fibra-openbanking-paymentrequest-newtransaction>
        </div>
        <div class="confirmtransaction" *ngSwitchCase="CONFIRMTRANSACTION">
            <fibra-openbanking-paymentrequest-confirmtransaction [consent]="transaction.consentimento" [unicoAprovador]="transaction.unico_aprovador" (onOk)="confirmPayment()" (onCancel)="backToNewTransaction()"></fibra-openbanking-paymentrequest-confirmtransaction>
        </div>
        <div class="redirect" *ngSwitchCase="REDIRECT">
            <fibra-openbanking-paymentrequest-redirect [consent]="transaction.consentimento" [transactionWasSuccessful]="transactionWasSuccessful"></fibra-openbanking-paymentrequest-redirect>
        </div>
    </div>
</div>
<fibra-openbanking-paymentrequest-beforeunload [component]="self"></fibra-openbanking-paymentrequest-beforeunload>
<fibra-alert #alert
    [open]="alertVisible"
    [showIcon]="alertShowIcon"
    [data]="{
        iconPNG: 'icon-pending',
        type: 'warning',
        title: alertPrimaryMsg,
        okButtonTitle: alertPrimaryAction?.caption, 
        cancelButtonTitle: alertSecondaryAction?.caption}" 
    (confirm)="confirmAlert()" 
    (cancel)="cancelAlert()">
    <div [innerHTML]="alertSecondaryMsg"></div>
</fibra-alert>
<fibra-alert #error
    [open]="errorVisible"
    [showIcon]="true"
    [data]="{
        iconPNG: errorIcon,
        type: 'danger',
        title: errorPrimaryMsg,
        okButtonTitle: errorAction?.caption }"
    (confirm)="confirmError()">
    <div [innerHTML]="errorSecondaryMsg"></div>
</fibra-alert>
<fibra-alert #cancel
    [open]="cancelationDialogVisible"
    [data]="{
        type: 'danger',
        title: 'Você está prestes a cancelar a operação',
        okButtonTitle: 'Confirmar cancelamento', 
        cancelButtonTitle: 'Voltar'}" 
    (confirm)="cancelationConfirmed()" 
    (cancel)="cancelationCanceled()">
    Ao confirmar o cancelamento, você perderá os dados correntes e voltará ao ambiente da iniciadora.
</fibra-alert>
<fibra-modal-validation (responseValidation)="responseSecurityValidation($event)" *ngIf="securityValidationDialogVisible" 
    [apiCallback]="120" [apiPayload]="apiPayload" (closeEvent)="closeSecurityValidationDialog($event)"></fibra-modal-validation>
<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>