<fibra-toast-error-message></fibra-toast-error-message>
<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>
<div class="content-page" style="background-size: 260em">
  <div class="content-container">
    <!-- Banner -->
    <div class="fibra-banner">
      <fibra-banner *ngIf="!loadingBannerInfo" [fibraFacil]="temFibraFacil"></fibra-banner>
    </div>


    <div id="problema_credito" *fibraHasRole="'PROBLEMA_CREDITO, REST_CRED.100, REST_CRED.101'">
        <div class="rest-cred">Para acessar os menus de Extrato, Transferência, Pix Transferência e Pagamento de boleto, entre em contato com seu gerente de relacionamento.</div>
    </div>

    <!-- Breadcrumb -->
    <fibra-breadcrumb roule="Início"></fibra-breadcrumb>
    <div class="fibra-card-balance">
        <div *ngFor="let chequeEmpresaItem of chequeEmpresaList">
            <div *ngIf="chequeEmpresaItem?.desc_conta==='CHEQUE EMPRESA'">
                <fibra-card-balance *fibraHasRole="'!PROBLEMA_CREDITO, !REST_CRED.100, !REST_CRED.101, QUALQUER_CONTA.EXT.CONS_EXT_SAL, EXT.CONS_EXT_SAL'"
                                    [titleCard]="toTitleCase(chequeEmpresaItem.exibicao) + ' disponível'" 
                                    valueType="number" 
                                    [value]="chequeEmpresaItem?.vlr_dashboard_cheque_empresa || 0"
                                    [showData]="showData" 
                                    [accountNumber]="(chequeEmpresaListCount > 1) ? chequeEmpresaItem?.num_conta : ''"
                                    [accountName]="(chequeEmpresaListCount > 1) ? chequeEmpresaItem?.exibicao : ''" 
                                    id="saldoChequeEmpresaDisponivel">
                </fibra-card-balance>
            </div>
        </div>
        <fibra-card-balance titleCard="Investimentos" valueType="number" [value]="investimentsBalance || 0"
                            [showData]="showData" id="saldoInvestimentos" *fibraHasRole="'INVEST.CONS_INV'">

        </fibra-card-balance>

     
    </div>
    <div class="fibra-card-pendency" id="cardpendency">
        <!-- SERIA MELHOR USAR TUDO JUNTO NO COMPONENTE, SE FIZER ISSO PRECISA AJUSTAR A CONTAGEM DE CARDS PARA SABER QUANDO HABILITAR O BOTAO DE SCROLL, SE FOR USAR DESCOMENTAR ESSE CODIGO E COMENTAR OS DA SESSAO 1 -->
        <!-- <fibra-card-pendency [showData]="showData" [parcelaVencendo]="parcelaVencendo" [parcelaVencida]="parcelaVencida" [dataPendency]="dataPendency" [parcelaAvencer]="parcelaAvencer" [derivativePendency]="derivativePendency" [hasNotificationPix]="hasNotificationPix"
    [dataPendenciesOpenBanking]="dataOpenBankingPendencies"
    ></fibra-card-pendency> -->
        <!-- INICIO SESSAO 1 -->
        <fibra-card-pendency *fibraHasRole="'!PROBLEMA_CREDITO'" [hidden]="hidePendency && !derivativePendency" [dataPendency]="dataPendency" [showData]="showData" [derivativePendency]="derivativePendency"></fibra-card-pendency>
        <fibra-card-pendency *fibraHasRole="'!PROBLEMA_CREDITO'" [hidden]="!parcelaVencendo" [parcelaVencendo]="parcelaVencendo"></fibra-card-pendency>
        <fibra-card-pendency *fibraHasRole="'!PROBLEMA_CREDITO'" [hidden]="!parcelaVencida" [parcelaVencida]="parcelaVencida"></fibra-card-pendency>
        <fibra-card-pendency *fibraHasRole="'!PROBLEMA_CREDITO'" [hidden]="!parcelaAvencer" [parcelaAvencer]="parcelaAvencer"></fibra-card-pendency>
        <fibra-card-pendency *fibraHasRole="'!PROBLEMA_CREDITO'" [hidden]="!hasNotificationPix" [hasNotificationPix]="hasNotificationPix"></fibra-card-pendency>
        <fibra-card-pendency *fibraHasRole="'!PROBLEMA_CREDITO'" [hidden]="hideOpenBankingPendencies && !derivativePendency" [dataPendenciesOpenBanking]="dataOpenBankingPendencies" [showData]="showData" [derivativePendency]="derivativePendency"></fibra-card-pendency>
        <!-- FIM SESSAO 1 -->
    </div>
    <div class="buttons-scroll" *ngIf="showButton">
      <div class="button">
        <img src="/assets/svg/deriva-seta-left.svg" (click)="scrollBy('left')" />
      </div>
      <div class="button">
        <img src="/assets/svg/deriva-seta-right.svg" (click)="scrollBy('right')" />
      </div>
    </div>

    <div *fibraHasRole="'POSSUI_CONTA'">

        <div *fibraHasRole="'QUALQUER_CONTA.EXT.CONS_EXT_SAL,EXT.CONS_EXT_SAL'" class="balance">
            <div *fibraHasRole="'!PROBLEMA_CREDITO, !REST_CRED.100, !REST_CRED.101'"class="list">
                <div class="title">Saldo</div>
                <div class="list-balance">
                    <fibra-list-balance-extract [showData]="showData"></fibra-list-balance-extract>
                </div>
            </div>

            <fibra-quick-access-go-along [dataAccompaniment]="dataAccompaniment"></fibra-quick-access-go-along>
        </div>
    </div>
    <div *fibraHasRole="'POSSUI_CONTA'">
      <div *fibraHasRole="'TRANSF.TRANSF_MSM_TITU, TRANSF.TRANSF_OTR_TITU, PAG_BOL.REALIZAR_PAG_BOL, !REST_CRED.100, !PROBLEMA_CREDITO, !REST_CRED.101'" class="fibra-transactions">
        <fibra-quick-access-transactions [data]="dataAccount"></fibra-quick-access-transactions>
      </div>
    </div>
    <!--
    <div *fibraHasRole="'POSSUI_CONTA'" class="fibra-receipts">
      <fibra-quick-access-receipts></fibra-quick-access-receipts>
    </div>
    -->
  </div>
  <!-- Hotjar Tracking Code for https://ibhom.bancofibra.com.br/ -->
<script>
  (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:2876507,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
</script>
</div>