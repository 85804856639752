import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';
import { LoansRepository } from '@src/core/repositories/loans/loans-repository';
import { LoansRepositoryMapper } from './loans-repository-mapper';
import { LoansEntity, LoansExportExcelRequest, TotalOperation } from './loans.entity';
import { LoansModel, LoansRequest, LoansRequestByContractAndDate, LoansRequestByDate, LoansRequestCompanyCheck, LoansRequestContractInfo, LoansRequestDailyPosition, LoansRequestExcelPost } from '@src/core/domain/loans/loan-model';

@Injectable({
  providedIn: 'root',
})
export class LoansDataRepository extends LoansRepository {


  mapper = new LoansRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }


  getAllLoans(obj: LoansRequest): Observable<LoansModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };

    // http://localhost:3001/contratos

    return this.http
      .get<LoansEntity>(`${environment.URLBase}v1/CreditoOperacoes/Contratos`, httpOptions)
  }

  getTotalOperations(obj: LoansRequest): Observable<LoansModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };

    return this.http
      .get<LoansEntity>(`${environment.URLBase}v1/CreditoOperacoes/posicaodiaria/consolidada`, httpOptions)

  }




  postExportExcelContracts(obj: LoansExportExcelRequest): Observable<any> {

    const params = { ...obj };
    return this.http
      .post<any>(
        `${environment.URLBase}v1/creditooperacoes/contratos/excel`,
        params,
        {
          headers: getHeader(),
          responseType: 'blob' as 'json'
        }
      )

  }



  PostExcel(obj: any, url: string): Observable<any> {
    const params = { ...obj };
    return this.http
      .post<any>(
        `${environment.URLBase}${url}`,
        params,
        {
          headers: getHeader(),
          responseType: 'blob' as 'json'
        }
      )
  }

  postExportExcelCheckCompany(obj: LoansExportExcelRequest): Observable<LoansModel> {
    return this.PostExcel(obj, 'v1/creditooperacoes/cheque-empresa/excel');
  }
  postExportExcelInfoContract(obj: LoansExportExcelRequest): Observable<LoansModel> {
    return this.PostExcel(obj, 'v1/creditooperacoes/info-contrato/excel');
  }
  postExportExcelParcela(obj: LoansExportExcelRequest): Observable<LoansModel> {
    return this.PostExcel(obj, 'v1/creditooperacoes/parcelas/excel');
  }

  postExportExcelDailyPosition(obj: LoansExportExcelRequest): Observable<LoansModel> {
    return this.PostExcel(obj, 'v1/creditooperacoes/posicao-diaria/excel');
  }


  getWarrantyMap(obj: LoansRequest): Observable<LoansModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };

    return this.http
      .get<LoansEntity>(`${environment.URLBase}v1/dashboard/mapagarantias`, httpOptions)

  }



  getParcela(obj: LoansRequestContractInfo): Observable<LoansModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };

    let uri = `${environment.URLBase}v1/CreditoOperacoes/contrato/${encodeURIComponent(obj.numContrato)}/parcela`

    return this.http
      .get<LoansEntity>(uri, httpOptions)

  }

  getParcelaByDate(obj: LoansRequestByContractAndDate): Observable<LoansModel> {
    const httpOptions = {
      headers: getHeader(),
    };

  
  let uri = `${environment.URLBase}v1/CreditoOperacoes/contrato/${encodeURIComponent(obj.numContrato)}/parcela?dt_ref=${obj.dt_ref}`

    return this.http.get<LoansEntity>(uri, httpOptions)
  }

  getContractInfo(obj: LoansRequestContractInfo): Observable<LoansModel> {
    const httpOptions = {
      headers: getHeader()
    };


        let uri = `${environment.URLBase}v1/CreditoOperacoes/contrato/${encodeURIComponent(obj.numContrato)}?dt_ref=${obj.dt_ref}`

    return this.http
      .get<LoansEntity>(uri, httpOptions)

  }

  getCompanyCheck(obj: LoansRequestCompanyCheck): Observable<LoansModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };

    return this.http
      .get<LoansEntity>(`${environment.URLBase}v1/CreditoOperacoes/ChequeEmpresa`, httpOptions)
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getDailyPositionAVencer(obj: LoansRequestDailyPosition): Observable<LoansModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };

    return this.http
      .get<LoansEntity>(`${environment.URLBase}v1/CreditoOperacoes/PosicaoDiaria/AVencer`, httpOptions)
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getDailyPositionVencendo(obj: LoansRequestDailyPosition): Observable<LoansModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };

    return this.http
      .get<LoansEntity>(`${environment.URLBase}v1/CreditoOperacoes/PosicaoDiaria/Vencendo`, httpOptions)
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getDailyPositionVencida(obj: LoansRequestDailyPosition): Observable<LoansModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };

    return this.http
      .get<LoansEntity>(`${environment.URLBase}v1/CreditoOperacoes/PosicaoDiaria/Vencida`, httpOptions)
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getContractByDate(obj: LoansRequestByDate): Observable<LoansModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };

    return this.http
      .get<LoansEntity>(`${environment.URLBase}v1/CreditoOperacoes/Contratos`, httpOptions)
  }

}
