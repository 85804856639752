import { GetRefusedQueryInstructionUsecase } from '@src/core/usecases/query/instruction-list/get-refused-query.usecase';
import { getAllQueryInstructionUsecase } from '@src/core/usecases/query/instruction-list/get-all-query.usecase';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  getOrderedDate,
  getOrderedPayer,
  getOrderedValue,
} from '@src/shared/util-common';
import * as Util from '@src/shared/util-common';

import { GetAuthorizedQueryInstructionUsecase } from '@src/core/usecases/query/instruction-list/get-authorized-query.usecase';
import { GetPendingQueryInstructionUsecase } from '@src/core/usecases/query/instruction-list/get-pending-query.usecase';
import {
  FilterQueryDataShare,
  TabsDataShare,
} from '@src/data/repository/data-share-repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-query-instruction-list',
  templateUrl: './instruction-list.component.html',
  styleUrls: ['./instruction-list.component.scss'],
})
export class InstructionListComponent implements OnInit {
  transit;
  filter = new FormControl('');
  getOrderedDate;
  getOrderedPayer;
  getOrderedValue;
  theaderOptions;
  @Input() params;
  hide;
  loading;
  option;

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  private ShowToast(value: any): void {    
    const isMessageServer: boolean = !!(value && value.code && value.message && value.message.length > 0);
    isMessageServer && this.toast && this.toast.callModalMessage(null, value.message[0], null, null, null);
  }

  constructor(
    private getAllInstructionList: getAllQueryInstructionUsecase,
    private getAuthorizedInstructionList: GetAuthorizedQueryInstructionUsecase,
    private getPendingInstructionList: GetPendingQueryInstructionUsecase,
    private getRefusedInstructionList: GetRefusedQueryInstructionUsecase,
    private sharedFilter: FilterQueryDataShare,
    private shareTab: TabsDataShare
  ) {
    this.transit = [];
    this.loading = false;
    this.hide = false;
    this.option = [
      { title: 'Data da emissão', value: 1 },
      { title: 'Data de vencimento', value: 2 },
      { title: 'Seu número', value: 3 },
      { title: 'Pagador', value: 4 },
      { title: 'Valor do título', value: 5 },
    ];
  }

  ngOnInit() {
    this.shareTab.dataShared.subscribe((res) => {
      this.initList(res.typeList);
    });
  }

  private initList(tab) {
    this.sharedFilter.dataShared.subscribe(
      (res: { account: string; dt_Final: string; dt_Inicio: string }) => {
        this.params = {
          num_conta: res.account = undefined? "":res.account,
          dt_inicial: res.dt_Inicio,
          dt_final: res.dt_Final,
        };
      this.transit = [];
      this.hide = false;
      this.loading = true;
      setTimeout(() => {
        this.switList(tab, this.params);
        this.loading = false;
      }, 300);
    });
  }

  private switList(list, param?) {
    switch (list) {
      case 'instruction-list-all':
        return this.requestQueryListAll(param);
      case 'instruction-list-authorized':
        return this.requestQueryListAuthorized(param);
      case 'instruction-list-refused':
        return this.requestQueryListRefused(param);
      case 'instruction-list-pending':
        return this.requestQueryListPending(param);
    }
  }

  private requestQueryListAll(param?) {
    if (param) {
      this.getAllInstructionList
        .execute(param)
        .subscribe(
          this.requestSuccess,
          this.requestError,
          this.requestComplete
        );
    }
  }
  private requestQueryListAuthorized(param?) {
    if (param) {
      this.getAuthorizedInstructionList
        .execute(param)
        .subscribe(
          this.requestSuccess,
          this.requestError,
          this.requestComplete
        );
    }
  }
  private requestQueryListRefused(param?) {
    if (param) {
      this.getPendingInstructionList
        .execute(param)
        .subscribe(
          this.requestSuccess,
          this.requestError,
          this.requestComplete
        );
    }
  }
  private requestQueryListPending(param?) {
    if (param) {
      this.getRefusedInstructionList
        .execute(param)
        .subscribe(
          this.requestSuccess,
          this.requestError,
          this.requestComplete
        );
    }
  }

  private requestSuccess = (value) => {
    this.transit = Util.verifyArray(value.data);
    this.hide = Util.isEmpty(this.transit);
  };

  private requestError = (err) => {
    this.ShowToast(err);

    this.loading = false;
    this.hide = true;
    return console.log(err);
  };

  private requestComplete = () => {
    this.hide = Util.isEmpty(this.transit);
  };

  isNull = (value) => {
    return Util.isNull(value);
  };

  ordered(value) {
    switch (value) {
      case 'date':
        return (this.getOrderedDate = getOrderedDate(this.transit));
      case 'payer':
        return (this.getOrderedPayer = getOrderedPayer(this.transit));
      case 'value':
        return (this.getOrderedValue = getOrderedValue(this.transit));
    }
  }
}

