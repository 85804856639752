import { PostTitleInformationUsecase } from '@src/core/usecases/charge/post-titleInformation.usecase';
import { Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TitleInformationEntity } from '@src/data/repository/charge/title-information/title-information.entity';

// model
import { StepperModel } from '@src/core/domain/business-models';
import { TitleInformationRequest } from '@src/core/domain/charge/titleinformation.model';
import { SelectorDataShare } from '@src/data/repository/data-share-repository';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';
interface PayerModel {
  tipo_pessoa: string;
  num_cpf_cnpj: string;
  nome_sacado: string;
  desc_logradouro: string;
  cep: string;
  desc_numero: string;
  desc_complemento: string;
  desc_bairro: string;
  desc_cidade: string;
  uf: string;
  ativo: number;
}

@Component({
  selector: 'fibra-page-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;
  public step: StepperModel[] = [];
  step1: any;
  step2: any;
  message: any;
  drawer: any = null;
  limitDiscountDate: any;
  dueDate: any;
  createDate: any;
  especie: string;

  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  public typeColorToast = this.REDCOLOR;
  public showModalValidation = false;
  public requestDataApi;
  public apiCallback = 0;

  constructor(
    private route: Router,
    private postTitleInformation: PostTitleInformationUsecase,
    private shareSelector: SelectorDataShare
  ) {
    this.step = [
      {
        step: 1,
        name: 'Dados do pagador',
      },
      {
        step: 2,
        name: 'Dados do Título',
      },
    ];
    this.drawer = {
      tipo_pessoa: '',
      num_cpf_cnpj: '',
      cep: '',
      desc_logradouro: '',
      desc_complemento: '',
      desc_numero: '',
      desc_bairro: '',
      desc_cidade: '',
      nome_sacado: '',
      ativo: 1,
      uf: '',
    };
  }

  ngOnInit() {

    this.step1 = JSON.parse(localStorage.getItem('first_step'));
    this.step2 = JSON.parse(localStorage.getItem('second_step'));

    if (localStorage.getItem('drawer_step') !== null) {
      this.drawer = JSON.parse(localStorage.getItem('drawer_step'));
    }

    if (this.step2) {
      this.limitDiscountDate = this.resolveDate(
        this.step2.titleInformation.limitDiscountDate
      );
      this.dueDate = this.resolveDate(this.step2.titleInformation.dueDate);
      this.createDate = this.resolveDate(this.step2.titleInformation.createAt);

      this.message = this.step2.titleInformation.message1 + this.step2.titleInformation.message2 +
      this.step2.titleInformation.message3 + this.step2.titleInformation.message4 + this.step2.titleInformation.message5; 

      if (this.step2.titleInformation && this.step2.titleInformation.especie) {
        switch (this.step2.titleInformation.especie) {
          case '01':
            this.especie = 'Duplicata Mercantil';
            break;
          case '02':
            this.especie = 'Recibo';
            break;
          case '03':
            this.especie = 'Contrato';
            break;
          case '04':
            this.especie = 'Duplicata de prestação de serviço';
            break;
          case '05':
            this.especie = 'Mensalidade escolar';
            break;
        }
      }
    }
    this.shareSelector.setValue('summary');

  }

  private ShowToast(err: any): void {
    const isMessageServer: boolean = !!(err && err.error && err.error.message && err.error.message.length > 0);
    isMessageServer && this.toast && this.toast.callModalMessage(null, err.error.message[0], null, null, null);
  }

  resolveDate(date: any) {
    if (date) {
      let dateAux: any

      if (date.year) {
        dateAux = new Date(date.year, date.month - 1, date.day);
      } else {
        const splitDate = date.split('/')
        dateAux = new Date(splitDate[2], splitDate[1] - 1, splitDate[0])
      }

      const format = new Intl.DateTimeFormat('pt-BR').format(dateAux);

      return format;
    }
  }

  sendInfo() {

    const payer: PayerModel = {
      tipo_pessoa: this.step1.personal.personType,
      num_cpf_cnpj: this.resolveMask(this.step1.personal.cpfCnpj),
      cep: this.resolveMask(this.step1.address.cep),
      desc_logradouro: this.step1.address.street,
      desc_complemento: this.step1.address.complement,
      desc_numero: this.step1.address.number,
      desc_bairro: this.step1.address.neighborhood,
      desc_cidade: this.step1.address.city,
      nome_sacado: this.step1.personal.draweeName,
      ativo: 1,
      uf: this.step1.address.state,
    };

    let drawer: PayerModel;

    if (this.drawer !== null) {
      // segundo if feito porque, antes ele n verificava se this.drawer.drawer estava
      // preenchido e assim não deixando avançar para fazer a chamada do back
      if (this.drawer.drawer !== undefined) {
        drawer = {
          ativo: 1,
          cep: this.resolveMask(this.drawer.drawerAddress.cep),
          desc_bairro: this.drawer.drawerAddress.neighborhood,
          desc_cidade: this.drawer.drawerAddress.city,
          desc_complemento: this.drawer.drawerAddress.complement,
          desc_logradouro: this.drawer.drawerAddress.street,
          desc_numero: this.drawer.drawerAddress.number,
          nome_sacado: this.drawer.drawer.draweeName,
          tipo_pessoa: this.drawer.drawer.personType,
          uf: this.drawer.drawerAddress.state,
          num_cpf_cnpj: this.resolveMask(this.drawer.drawer.cpfCnpj)
        }
      } else {
        drawer = null
      }
    } else {
      drawer = null
    }

    const params: TitleInformationRequest = {

      pagador: payer,
      sacador_avalista: this.drawer === null ? null : drawer,
      num_conta: this.step2.titleInformation.conta,
      num_conta_header: this.step2.titleInformation.conta,
      banco_correspondente: this.step2.titleInformation.banco,
      desc_carteira: this.step2.titleInformation.carteira,
      dt_emissao: this.resolveSendDate(this.step2.titleInformation.createAt),
      dt_vencimento: this.resolveSendDate(this.step2.titleInformation.dueDate),
      vlr_desconto: this.step2.titleInformation.discount,
      chave_nota_fiscal: this.step2.titleInformation.fiscalKey.replace(/ /g, ""),
      cod_moeda: this.step2.titleInformation.coin,
      cod_especie: this.step2.titleInformation.especie,
      meu_numero: this.step2.titleInformation.yourNumber,
      dt_limite: this.resolveSendDate(
        this.step2.titleInformation.limitDiscountDate
      ),
      // tslint:disable-next-line: radix
      qtd_dias_protesto: parseInt(this.step2.titleInformation.valueDays === '' ? 0 : this.step2.titleInformation.valueDays),
      desc_mensagem: this.message,
      //protestar: 1,
      protestar: this.step2.titleInformation.protest,
      vlr_mora: this.step2.titleInformation.mora,
      dt_inicio_protesto: this.resolveStrDate(
        this.step2.titleInformation.protestDate
      ),
      vlr_titulo: this.step2.titleInformation.titleValue,
      vlr_multa: this.resolveMoney(this.step2.titleInformation.tax),
      ativo: 1,
      tipo_multa: this.step2.titleInformation.typeTax,
    };

    this.requestSendTitleInformation(params);

    localStorage.removeItem('first_step');
    localStorage.removeItem('second_step');
    localStorage.removeItem('drawer_step');
  }

  securityRequest(value) {
    console.log(value)
    const params = {};
    return params;
  }

  securityResponse(value) {
    console.log(value)
  }

  closeModal() {
    this.showModalValidation = false;
  }

  editData() {
    localStorage.setItem('first_step_edit', JSON.stringify(this.step1));
    localStorage.setItem('second_step_edit', JSON.stringify(this.step2));
    localStorage.setItem('drawer_step_edit', JSON.stringify(this.drawer));

    localStorage.removeItem('first_step');
    localStorage.removeItem('second_step');
    localStorage.removeItem('drawer_step');

    this.route.navigate(['/charge-edit']);
  }

  resolveSendDate(ngbDate) {
    if (ngbDate) {
      let jsDate: any
      if (ngbDate.year) {
        jsDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
      } else {
        const splitDate = ngbDate.split('/')
        jsDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0])
      }

      const format = jsDate.toISOString().slice(0, 19);
      return format;
    }
  }

  resolveStrDate(date: string) {
    if (date == "")
      return null;
    const auxDate = date.split('/');
    const dateFormat = new Date(
      Number(auxDate[2]),
      Number(auxDate[1]) - 1,
      Number(auxDate[0])
    )
      .toISOString()
      .slice(0, 19);

    return dateFormat;
  }

  resolveMask(str) {
    return str.replace(/[^0-9]+/g, '');
  }

  resolveMoney(str) {
    if (str) {

      let index = str.indexOf('R$') !== -1;
      if (index) {
        let compativelComParseFloat = str.replace('R$ ', '');
        compativelComParseFloat = compativelComParseFloat.replace(',', '.');
        const valor = parseFloat(compativelComParseFloat);
        return valor;
      } else {
        let compativelComParseFloat = str.replace('$ ', '');
        compativelComParseFloat = compativelComParseFloat.replace(',', '.');
        const valor = parseFloat(compativelComParseFloat);

        return valor;
      }

    }

  }

  private success = (value: TitleInformationEntity) => {
    localStorage.removeItem('first_step');
    localStorage.removeItem('second_step');
    localStorage.removeItem('drawer_step');
    this.route.navigate(['/charge']);
  };

  private error = (err) => {

    this.ShowToast(err);
    return console.log(err);
  };

  requestSendTitleInformation = (params: TitleInformationRequest) => {
    // this.postTitleInformation
    //   .execute(params)
    //   .subscribe(this.success, this.error);

    this.requestDataApi = params;
    this.apiCallback = 61;
    this.securityValidation();
  };

  public securityValidation = () => {
    this.showModalValidation = true;
  };

  responseApi(value: any) {
    if (value.status === 'success') {
      this.success(value);
    } else {
      this.error(value);
    }
  }
}

