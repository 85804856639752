<div class="content-approval" *ngIf="!showTerms">
    <div class="content-options" *ngIf="showKeys">
        <div class="options-div">
            <span [ngClass]="selectedNavBar === 'keys' ? 'option-selected': 'option-unselected'" (click)="handleSelecterdNav('keys'); getKeysApproved();" style="margin-right: 17px; cursor: pointer;">
                Chaves <span class="notif-keys" *ngIf="temPendenciasChave"></span>
            </span>
            <div class="separator"></div>
            <span [ngClass]="selectedNavBar === 'sendSolicitation' ? 'option-selected': 'option-unselected'" (click)="handleSelecterdNav('sendSolicitation'); getSolicitaionsSend()" style="margin-right: 17px; margin-left: 16px; cursor: pointer;">
                Solicitações enviadas <span class="notif-solicitation-send" *ngIf="temPendenciasRequisicao"></span>
            </span>
            <div class="separator"></div>
            <span [ngClass]="selectedNavBar === 'receiveRequest' ? 'option-selected': 'option-unselected'" (click)="handleSelecterdNav('receiveRequest');getRequestReceived()" style="margin-left: 16px; cursor: pointer;">
                Solicitações recebidas <span class="notif-solicitation-receive" *ngIf="temPendenciasDoacao"></span>
            </span>
        </div>
        <div class="sub-options-div">
            <span [ngClass]="seletedFilter === 'pending' ? 'option-selected': 'option-unselected'"
                (click)="handleFilter('pending')" style="margin-right: 17px; cursor: pointer;"> Pendentes</span>
            <div class="separator"></div>
            <span [ngClass]="seletedFilter === 'approved' ? 'option-selected': 'option-unselected'"
                (click)="handleFilter('approved')" style="margin-right: 17px; margin-left: 16px; cursor: pointer;">
                Aprovadas</span>
            <div class="separator"></div>
            <span [ngClass]="seletedFilter === 'refused' ? 'option-selected': 'option-unselected'"
                (click)="handleFilter('refused')" style="margin-left: 16px; cursor: pointer;"> Recusadas</span>
        </div>
    </div>
    <div class="keys-content" [ngSwitch]="seletedFilter" *ngIf="!loading && selectedNavBar === 'keys'">
        <div *ngIf="showKeys">
            <div class="cards approved" *ngSwitchCase="'approved'">
                <div class="card-item" *ngFor="let key of keyapproved">
                    <div class="status">
                        <span class="status-font"> Status</span>
                        <div class="circle-green"> </div>
                        <img src="/assets/svg/pending-watch.svg" alt="watch" style="margin-right: 8px;"
                            *ngIf="key.status.includes('pendente')">
                        <span class="status-message-green"> <span *ngIf="key.tp_operacao === 'CRIAR'">
                                Cadastro de chave aprovado</span><span *ngIf="key.tp_operacao === 'EXCLUIR'">
                                Exclusão de chave aprovada</span></span>
                    </div>
                    <div class="card-content">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value">
                                <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ? key.chave : 'Chave aleatória'}}</span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}</span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '+00 00 00000-0000' }}</span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}}</span>
                            </span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Conta</span>
                            <span class="acc-value" *ngIf="key.conta">{{key.conta.num_conta}} - {{key.conta.exibicao}}</span>
                        </div>
                        <div class="date">
                            <span class="date-title"> Data e hora</span>
                            <span class="date-time">{{key.data_criacao | date:'dd/MM/yyyy HH:mm' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cards refused" *ngSwitchCase="'refused'">
                <div class="card-item" *ngFor="let key of keysRefused">
                    <div class="status">
                        <span class="status-font"> Status</span>
                        <div class="circle-red" *ngIf="true"> </div>
                        <span *ngIf="false" class="icon-info notification" [appTooltip]="tooltipAll">
                            <img src="/assets/png/info-damage.png" style="margin-right: 4px !important;" alt="logo" />
                            <div #tooltipAll class="tooltip-custom d-flex align-items-center">
                                <div class="text d-flex align-items-center">
                                    <div class="row">
                                        <div class="col-12 text-left">
                                            <p class="text"> A pessoa que cadastrou esta chave confirmou que ainda a
                                                usa.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="arrow"></div>
                            </div>


                        </span>
                        <span class="status-message-red"> <span> <span *ngIf="key.tp_operacao === 'CRIAR'">
                                    Cadastro de chave recusada</span><span *ngIf="key.tp_operacao === 'EXCLUIR'">
                                    Exclusão de chave recusada</span></span>
                        </span>
                    </div>
                    <div class="card-content">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value">
                                <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ? key.chave : 'Chave aleatória'}}</span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}</span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '+00 00 00000-0000' }}</span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}}</span>
                            </span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Conta</span>
                            <span class="acc-value" *ngIf="key.conta">{{key.conta.num_conta}} - {{key.conta.exibicao}}</span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Recusado por</span>
                            <ng-container
                                *ngIf="key.ds_status_consolidado === 'CHAVELIMPA_REPROVADO_BACEN'; else elseTemplate">
                                <div>
                                    <p class="acc-value">Bacen</p>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplate>
                                <div *ngFor="let item of key.aprovacoes">
                                    <p class="acc-value" *ngIf="item.status === 'REPROVADO'">{{item.pessoa.nome_pessoa}}
                                    </p>
                                </div>
                            </ng-template>
                        </div>
                        <div class="date">
                            <span class="date-title"> Data e hora</span>
                            <ng-container
                                *ngIf="key.ds_status_consolidado === 'CHAVELIMPA_REPROVADO_BACEN'; else elseDate">
                                <span class="date-time">{{key.data_erro_efetivacao | date:'dd/MM/yyyy HH:mm' }}</span>
                            </ng-container>
                            <ng-template #elseDate>
                                <span *ngFor="let item of  key.aprovacoes">
                                    <span class="date-time" *ngIf="item.status === 'REPROVADO'">{{item.dt_aprovacao |
                                        date:'dd/MM/yyyy
                                        HH:mm' }}</span>
                                </span>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cards pending" *ngSwitchCase="'pending'">
                <div class="card-item" *ngFor="let key of keyPending">
                    <div class="status">
                        <span class="status-font" *ngIf="true"> Status :</span>
                        <div class="circle-orange"
                            *ngIf="isPeddingotherMaster(key.aprovacoes) && (key.ds_status_consolidado === 'IB_APROVACAO_PENDENTE')">
                        </div>
                        <span *ngIf="key.ds_status_consolidado === 'IB_AGENDADO'" class="icon-info notification"
                            [appTooltip]="tooltipAll">
                            <img src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 4px !important;"
                                alt="logo" />
                            <div #tooltipAll class="tooltip-custom d-flex align-items-center">
                                <div class="text d-flex align-items-center">
                                    <div class="row">
                                        <div class="col-12 text-left">
                                            <p style="font-size: 14px; color: #D69D10">Sua efetivação ocorrerá <span
                                                    *ngIf="isToday(key.data_agendamento_bacen) === false">amanhã</span><span
                                                    *ngIf="isToday(key.data_agendamento_bacen) === true">hoje</span> às
                                                08:00.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="arrow"></div>
                            </div>
                        </span>
                        <span *ngIf="key.ds_status_consolidado === 'CHAVELIMPA_PENDENTE_BACEN'"
                            class="icon-info notification" [appTooltip]="tooltipAll">
                            <img src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 4px !important;"
                                alt="logo" />
                            <div #tooltipAll class="tooltip-custom d-flex align-items-center">
                                <div class="text d-flex align-items-center">
                                    <div class="row">
                                        <div class="col-12 text-left">
                                            <p style="font-size: 14px; color: #D69D10">Chave pendente de cadastro no
                                                bacen</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="arrow"></div>
                            </div>
                        </span>
                        <span class="icon-alert">
                            <span class="icon-info notification" [appTooltip]="tooltipAll"
                                *ngIf="(!isPeddingotherMaster(key.aprovacoes)) && (key.ds_status_consolidado === 'IB_APROVACAO_PENDENTE')">
                                <img src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 4px !important;"
                                    alt="logo" />
                                <div #tooltipAll class="tooltip-custom-alert">
                                    <p style="font-size: 14px;" class="text-center">Aguardando aprovação</p>
                                    <div class="master" style="text-align: start;
                                margin-left: 22px;">
                                        <div class="masters-title" style="font-size: 12px; margin-top: 5px;">
                                            <img src="/assets/svg/marcador-icon.svg">
                                            <span class="master-font"> Masters </span>
                                        </div>

                                        <span class="master-sub" style="color: #8F969D;
                                    font-size: 12px; margin-top: 5px;" *ngFor="let item of key.aprovacoes">
                                            <p *ngIf="item.status === 'PENDENTE'" style="margin:0px">
                                                {{item.pessoa.nome_pessoa}}</p>
                                        </span>

                                    </div>
                                    <div class="arrow"></div>
                                </div>
                            </span>
                        </span>
                        <img src="/assets/svg/pending-watch.svg" alt="watch" style="margin-right: 8px;" *ngIf="false">
                        <span class="status-message-orange"
                            *ngIf="key.ds_status_consolidado === 'IB_APROVACAO_PENDENTE'"> <span
                                *ngIf="isPeddingotherMaster(key.aprovacoes)"><span *ngIf="key.tp_operacao === 'CRIAR'">
                                    Cadastro de chave pendente de aprovação</span><span
                                    *ngIf="key.tp_operacao === 'EXCLUIR'">
                                    Exclusão de chave pendente de aprovação</span></span><span
                                *ngIf="!isPeddingotherMaster(key.aprovacoes)"><span *ngIf="key.tp_operacao === 'CRIAR'">
                                    Cadastro de chave pendente de aprovação</span><span
                                    *ngIf="key.tp_operacao === 'EXCLUIR'">
                                    Exclusão de chave pendente de aprovação</span></span>
                        </span>
                        <span class="status-message-orange"
                            *ngIf="key.ds_status_consolidado !== 'IB_APROVACAO_PENDENTE'"><span
                                *ngIf="key.tp_operacao === 'CRIAR'">
                                Cadastro de chave pendente</span><span *ngIf="key.tp_operacao === 'EXCLUIR'">
                                Exclusão de chave pendente</span></span>
                    </div>
                    <div class="card-content">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value">
                                <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ? key.chave : 'Chave aleatória'}}</span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}</span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '+00 00 00000-0000' }}</span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}}</span>
                            </span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Conta</span>
                            <span class="acc-value" *ngIf="key.conta">{{key.conta.num_conta}} - {{key.conta.exibicao}}</span>
                        </div>
                        <div class="date">
                            <span class="date-title"> Data e hora</span>
                            <span class="date-time">{{key.data_criacao | date:'dd/MM/yyyy HH:mm' }}</span>
                        </div>
                        <div class="card-options" *ngIf="isPeddingotherMaster(key.aprovacoes)">
                            <img src="/assets/svg/fechar.svg" alt="fechar" style="margin-right: 32px; cursor: pointer;"
                                (click)="openModal(contentReprove, key)">
                            <img src="/assets/svg/aprovar.svg" alt="aprovar" style="cursor: pointer;"
                                (click)="approveKeys(key)">
                        </div>
                    </div>
                    <div class="card-content-two" *ngIf="enable">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value"> email@empresa.com.br</span>
                        </div>
                        <div class="text-div">
                            <span class="text-font">
                                Recebemos pedido de reivindicação de posse da chave Pix nome@empresa.com.br. Isso
                                significa que outro usuário registrou pedido
                                para que essa chave seja vinculada a outra conta. Para cancelar esse processo e para que
                                você possa continuar utilizando essa chave, é necessário que você faça nova validação de
                                posse até o dia 24/12/2020
                            </span>
                        </div>
                        <div class="buttons">
                            <button type="button" class="button-secondary">
                                Recusar
                            </button>
                            <button type="button" class="button-primary"> Aprovar </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="keys-content" [ngSwitch]="seletedFilter" *ngIf="!loading && selectedNavBar === 'sendSolicitation'">
        <div *ngIf="showKeys">
            <div class="cards approved" *ngSwitchCase="'approved'">
                <div class="card-item" *ngFor="let key of keyapprovedSolicitationSend">
                    <div class="status">
                        <span class="status-font"> Status</span>
                        <div class="circle-green"> </div>
                        <img src="/assets/svg/pending-watch.svg" alt="watch" style="margin-right: 8px;"
                            *ngIf="key.status.includes('pendente')">
                        <span class="status-message-green">
                            <span *ngIf="key.fl_portabilidade">
                                Solicitação de portabilidade de chave aprovada</span>
                            <span *ngIf="key.fl_posse">
                                Solicitação de reivindicação de chave aprovada</span>
                        </span>
                    </div>
                    <div class="card-content">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value">
                                <span>{{maskKeys(key)}}</span>
                            </span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Conta</span>
                            <span class="acc-value" *ngIf="key.conta">{{key.conta.num_conta}} -
                                {{key.conta.desc_conta}}</span>
                        </div>
                        <div class="date">
                            <span class="date-title"> Data e hora</span>
                            <span class="date-time">{{key.data_criacao | date:'dd/MM/yyyy HH:mm' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cards refused" *ngSwitchCase="'refused'">
                <div class="card-item" *ngFor="let key of keysRefusedSolicitationSend">
                    <div class="status">
                        <span class="status-font"> Status</span>
                        <div class="circle-red" *ngIf="key.ds_status_consolidado === 'BACEN_REIVINDICPOSSE_CANCELADAAVULSO_REIVINDICADOR' 
                    || key.ds_status_consolidado === 'IB_APROVACAO_REPROVADA' 
                    || key.ds_status_consolidado === 'BACEN_REIVINDICPOSSE_REPROVADO_BACEN'
                    || key.ds_status_consolidado === 'BACEN_REIVINDICPORTAB_REVOGADA_DOADOR'
                    || key.ds_status_consolidado === 'BACEN_REIVINDICPORTAB_CANCELADA_REIVINDICADOR' ">
                        </div>
                        <span *ngIf="key.ds_status_consolidado === 'BACEN_REIVINDICPOSSE_REVOGADA_DOADOR'"
                            class="icon-info notification" [appTooltip]="tooltipAll">
                            <img src="/assets/png/info-damage.png" style="margin-right: 4px !important;" alt="logo" />
                            <div #tooltipAll class="tooltip-custom d-flex align-items-center">
                                <div class="text d-flex align-items-center">
                                    <div class="row">
                                        <div class="col-12 text-left">
                                            <p class="text" *ngIf="false"> A pessoa que cadastrou esta chave confirmou
                                                que
                                                ainda a usa.</p>
                                            <p class="text" *ngIf="true"> Recusada pela outra instituição</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="arrow"></div>
                            </div>


                        </span>
                        <span class="status-message-red">
                            <span
                                *ngIf="key.fl_portabilidade && key.ds_status_consolidado !== 'BACEN_REIVINDICPOSSE_REVOGADA_DOADOR'">
                                Solicitação de portabilidade recusada</span>
                            <span
                                *ngIf="key.fl_posse && key.ds_status_consolidado !== 'BACEN_REIVINDICPOSSE_REVOGADA_DOADOR'">
                                Solicitação de reivindicação recusada</span>
                            <span
                                *ngIf="key.fl_portabilidade && key.ds_status_consolidado === 'BACEN_REIVINDICPOSSE_REVOGADA_DOADOR'">
                                Solicitação de portabilidade recusada</span>
                            <span
                                *ngIf="key.fl_posse && key.ds_status_consolidado === 'BACEN_REIVINDICPOSSE_REVOGADA_DOADOR'">
                                Solicitação de reivindicação recusada</span>

                        </span>
                    </div>
                    <div class="card-content">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value">
                                <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ? key.chave : 'Chave aleatória'}}</span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}</span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '+00 00 00000-0000' }}</span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}}</span>
                            </span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Conta</span>
                            <span class="acc-value" *ngIf="key.conta">{{key.conta.num_conta}} - {{key.conta.exibicao}}</span>
                            <!-- <span class="acc-value">{{key.num_conta}} </span> -->
                        </div>
                        <div class="account" *ngIf="key.ds_status_consolidado === 'IB_APROVACAO_REPROVADA'">
                            <span class="acc-title"> Recusado por</span>
                            <div *ngFor="let item of key.aprovacoes">
                                <p class="acc-value" *ngIf="item.status === 'REPROVADO'">{{item.pessoa.nome_pessoa}}</p>
                            </div>
                        </div>
                        <div class="account"
                            *ngIf="key.ds_status_consolidado === 'BACEN_REIVINDICPOSSE_REPROVADO_BACEN'">
                            <span class="acc-title"> Recusado por</span>
                            <div>
                                <p class="acc-value">Bacen</p>
                            </div>
                        </div>
                        <div class="account"
                            *ngIf="key.ds_status_consolidado === 'BACEN_REIVINDICPOSSE_CANCELADAAVULSO_REIVINDICADOR'">
                            <span class="acc-title"> Recusado por</span>
                            <div>
                                <!-- <p class="acc-value">Abobrinha</p> -->
                                <p class="acc-value">{{ getAprovadores(key.aprovacoes) }}</p>
                            </div>
                        </div>
                        <div class="date">
                            <!-- <span class="date-title"> Data e hora</span> -->
                            <span class="date-title"> Data</span>
                            <span>
                                <span class="date-time" *ngIf="key.ds_status_consolidado === 'BACEN_REIVINDICPOSSE_CANCELADAAVULSO_REIVINDICADOR' 
                                || key.ds_status_consolidado === 'BACEN_REIVINDICPORTAB_CANCELADA_REIVINDICADOR' ">
                                    <!-- {{key.data_cancelamentoavulso_reivindicador | date:'dd/MM/yyyy HH:mm' }} -->
                                    {{key.data_cancelamentoavulso_reivindicador | date:'dd/MM/yyyy ' }}
                                </span>
                                <span class="date-time" *ngIf="key.ds_status_consolidado === 'BACEN_REIVINDICPORTAB_REVOGADA_DOADOR'">
                                    {{key.data_aprovacao_ou_recusa | date:'dd/MM/yyyy ' }}
                                </span>
                                <span class="date-time" *ngIf="key.ds_status_consolidado === 'IB_APROVACAO_REPROVADA'">
                                    <span *ngFor="let item of key.aprovacoes">
                                        <span *ngIf="item.status === 'REPROVADO'">
                                            {{item.dt_aprovacao | date:'dd/MM/yyyy '}}
                                        </span>
                                    </span>
                                </span>
                                <span class="date-time"
                                    *ngIf="key.ds_status_consolidado === 'BACEN_REIVINDICPOSSE_REPROVADO_BACEN'">
                                    {{key.data_erro_efetivacao | date:'dd/MM/yyyy '}}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cards pending" *ngSwitchCase="'pending'">
                <div class="card-item" *ngFor="let key of keyPendingSolicitationSend">
                    <div class="status">
                        <span class="status-font"
                            *ngIf="key.ds_status_consolidado === 'IB_APROVACAO_PENDENTE' || key.ds_status_consolidado === 'IB_AGENDADO'">
                            Status :</span>
                        <div class="circle-orange"
                            *ngIf="isPeddingotherMaster(key.aprovacoes) && key.ds_status_consolidado === 'IB_APROVACAO_PENDENTE' ">
                        </div>
                        <span *ngIf="key.ds_status_consolidado === 'IB_AGENDADO'" class="icon-info notification"
                            [appTooltip]="tooltipAll">
                            <img src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 4px !important;"
                                alt="logo" />
                            <div #tooltipAll class="tooltip-custom d-flex align-items-center">
                                <div class="text d-flex align-items-center">
                                    <div class="row">
                                        <div class="col-12 text-left">
                                            <p style="font-size: 14px; color: #D69D10">Sua efetivação ocorrerá <span
                                                    *ngIf="isToday(key.data_agendamento_bacen) === false">amanhã</span><span
                                                    *ngIf="isToday(key.data_agendamento_bacen) === true">hoje</span> às
                                                08:00.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="arrow"></div>
                            </div>


                        </span>
                        <span class="icon-alert">
                            <span class="icon-info notification" [appTooltip]="tooltipAll"
                                *ngIf="!isPeddingotherMaster(key.aprovacoes) && key.ds_status_consolidado === 'IB_APROVACAO_PENDENTE'">
                                <img src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 4px !important;"
                                    alt="logo" />
                                <div #tooltipAll class="tooltip-custom-alert">
                                    <p style="font-size: 14px;" class="text-center">Aguardando aprovação</p>
                                    <div class="master" style="text-align: start;
                                margin-left: 22px;">
                                        <div class="masters-title" style="font-size: 12px; margin-top: 5px;">
                                            <img src="/assets/svg/marcador-icon.svg">
                                            <span class="master-font"> Masters </span>
                                        </div>

                                        <span class="master-sub" style="color: #8F969D;
                                    font-size: 12px; margin-top: 5px;" *ngFor="let item of key.aprovacoes">
                                            <p *ngIf="item.status === 'PENDENTE'" style="margin:0px">
                                                {{item.pessoa.nome_pessoa}}</p>
                                        </span>

                                    </div>
                                    <div class="arrow"></div>
                                </div>
                            </span>
                        </span>
                        <img src="/assets/svg/pending-watch.svg" alt="watch" style="margin-right: 8px;"
                            *ngIf="key.ds_status_consolidado === 'BACEN_REIVINDICPORTAB_PENDENTE_DOADOR' || key.ds_status_consolidado === 'BACEN_REIVINDICPOSSE_PENDENTE_DOADOR'">
                        <span class="status-message-orange">
                            <span *ngIf="key.fl_portabilidade && !key.fl_processado_bacen">
                                Solicitação de portabilidade de chave pendente</span>
                            <span *ngIf="key.fl_posse && !key.fl_processado_bacen">
                                Solicitação de reivindicação de chave pendente</span>
                            <span *ngIf="key.fl_portabilidade && key.fl_processado_bacen">
                                Aguardando confirmação de portabilidade</span>
                            <span *ngIf="key.fl_posse && key.fl_processado_bacen">
                                Aguardando confirmação de reivindicação </span>
                        </span>
                    </div>
                    <div class="card-content"
                        *ngIf="key.ds_status_consolidado === 'IB_APROVACAO_PENDENTE' || key.ds_status_consolidado === 'IB_AGENDADO' ">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value">
                                <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ? key.chave : 'Chave aleatória'}}</span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}</span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '+00 00 00000-0000' }}</span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}}</span>
                            </span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Conta</span>
                            <span class="acc-value" *ngIf="key.conta">{{key.conta.num_conta}} - {{key.conta.exibicao}}</span>
                        </div>
                        <div class="date">
                            <span class="date-title"> Data e hora</span>
                            <span class="date-time">{{key.data_criacao | date:'dd/MM/yyyy HH:mm' }}</span>
                        </div>
                        <div class="card-options" *ngIf="isPeddingotherMaster(key.aprovacoes)">
                            <img src="/assets/svg/fechar.svg" alt="fechar" style="margin-right: 32px; cursor: pointer;"
                                (click)="openModal(contentReprove, key)">
                            <img src="/assets/svg/aprovar.svg" alt="aprovar" style="cursor: pointer;"
                                (click)="approveKeys(key);isConfirmationSendSolitation()">
                        </div>
                    </div>
                    <div class="card-content-two"
                        *ngIf="key.ds_status_consolidado === 'BACEN_REIVINDICPOSSE_PENDENTE_DOADOR' || key.ds_status_consolidado ===  'BACEN_REIVINDICPORTAB_PENDENTE_DOADOR'">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value">
                                <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ? key.chave : 'Chave aleatória'}}</span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}</span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '+00 00 00000-0000' }}</span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}}</span>
                            </span>
                        </div>
                        <div class="text-div">
                            <span class="text-font" *ngIf="key.fl_portabilidade && key.fl_processado_bacen">
                                Seu pedido de portabilidade da chave <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ?
                                    key.chave
                                    : 'Chave aleatória'}} </span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}
                                </span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '+00 00 00000-0000' }}
                                </span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}} </span> para o Banco Fibra foi
                                enviado. Você tem até o dia {{key.data_limite_confirm_doador | date:'dd/MM/yyyy'}} às
                                {{key.data_limite_confirm_doador | date:'HH:mm'}}h para confirmar o pedido
                                no aplicativo da instituição em que a chave está atualmente contratada.
                            </span>
                            <span class="text-font" *ngIf="key.fl_posse && key.fl_processado_bacen">
                                Seu pedido de reivindicação da chave <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ?
                                    key.chave
                                    : 'Chave aleatória'}} </span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}
                                </span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '+00 00 00000-0000' }}
                                </span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}} </span> para a sua conta
                                {{key.conta.num_conta}} {{key.conta.desc_conta}} foi enviado. O usuário de posse da
                                chave tem até o dia {{key.data_limite_confirm_doador | date:'dd/MM/yyyy' }} às
                                {{key.data_limite_confirm_doador
                                | date:'HH:mm'}} para confirmar o pedido no aplicativo da instituição em que a chave
                                está atualmente contratada.
                            </span>
                        </div>
                        <div class="buttons d-flex justify-content-center">
                            <button type="button " class="button-secondary w-100 m-0" (click)="cancelSolictionKey(key)">
                                Cancelar pedido
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="keys-content" [ngSwitch]="seletedFilter" *ngIf="!loading && selectedNavBar === 'receiveRequest'">
        <div *ngIf="showKeys">
            <div class="cards approved" *ngSwitchCase="'approved'">
                <div class="card-item" *ngFor="let key of keyapprovedReceivedRequest">
                    <div class="status">
                        <span class="status-font"> Status</span>
                        <div class="circle-green"> </div>
                        <img src="/assets/svg/pending-watch.svg" alt="watch" style="margin-right: 8px;"
                            *ngIf="key.status.includes('pendente')">
                        <span class="status-message-green">
                            <span *ngIf="key.fl_portabilidade">
                                Portabilidade de chave aprovada</span>
                            <span *ngIf="key.fl_posse">
                                Reivindicação de chave aprovada</span>
                        </span>
                    </div>
                    <div class="card-content">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value">
                                <span>{{maskKeys(key)}}</span>
                            </span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Conta</span>
                            <span class="acc-value">{{key?.conta?.num_conta}} - {{key?.conta?.desc_conta}}</span>
                        </div>
                        <div class="date">
                            <span class="date-title"> Data e hora</span>
                            <span class="date-time">{{key.data_aprovacao_ou_recusa | date:'dd/MM/yyyy HH:mm' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cards refused" *ngSwitchCase="'refused'">
                <div class="card-item" *ngFor="let key of keysRefusedReceivedRequest">
                    <div class="status">
                        <span class="status-font"> Status</span>
                        <div class="circle-red" *ngIf="key.status === 'REPROVADO'"> </div>
                        <!-- <span *ngIf="key.ds_status_consolidado === 'BACEN_REIVINDICPORTAB_REVOGADA_DOADOR'" class="icon-info notification" [appTooltip]="tooltipAll">
                            <img src="/assets/png/info-damage.png" style="margin-right: 4px !important;" alt="logo" />
                            <div #tooltipAll class="tooltip-custom d-flex align-items-center">
                                <div class="text d-flex align-items-center">
                                    <div class="row">
                                        <div class="col-12 text-left">
                                            <p class="text" *ngIf="false"> A pessoa que cadastrou esta chave confirmou que
                                                ainda a usa.</p>
                                            <p class="text" *ngIf="true"> Recusada pela outra instituição</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="arrow"></div>
                            </div>


                        </span> -->
                        <span class="status-message-red">
                            <span *ngIf="key.fl_portabilidade">
                                Portabilidade de chave recusada</span>
                            <span *ngIf="key.fl_posse">
                                Reivindicação de chave recusada</span>
                        </span>
                    </div>
                    <div class="card-content">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value">
                                <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ? key.chave : 'Chave aleatória'}}</span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}</span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '+00 00 00000-0000' }}</span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}}</span>
                            </span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Conta</span>
                            <span class="acc-value" *ngIf="key.conta">{{key.conta.num_conta}} - {{key.conta.exibicao}}</span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Recusado por</span>
                            <div *ngFor="let item of key.aprovacoes">
                                <p class="acc-value" *ngIf="item.status === 'REPROVADO'">{{item.pessoa.nome_pessoa}}</p>
                            </div>
                        </div>
                        <div class="date">
                            <span class="date-title"> Data e hora</span>
                            <span *ngFor="let item of  key.aprovacoes">
                                <span class="date-time" *ngIf="item.status === 'REPROVADO'">{{item.dt_aprovacao |
                                    date:'dd/MM/yyyy
                                    HH:mm' }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cards pending" *ngSwitchCase="'pending'">
                <div class="card-item" *ngFor="let key of keyPendingReceivedRequest">
                    <div class="status">
                        <span class="status-font"
                            *ngIf="(!isPeddingotherMaster(key.aprovacoes) && key.ds_status_consolidado === 'IB_APROVACAO_PENDENTE') || (!isPeddingotherMaster(key.aprovacoes) && key.ds_status_consolidado === 'IB_AGENDADO')">
                            Status :</span>
                        <span *ngIf="key.ds_status_consolidado === 'IB_AGENDADO'" class="icon-info notification"
                            [appTooltip]="tooltipAll">
                            <img src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 4px !important;"
                                alt="logo" />
                            <div #tooltipAll class="tooltip-custom d-flex align-items-center">
                                <div class="text d-flex align-items-center">
                                    <div class="row">
                                        <div class="col-12 text-left">
                                            <p style="font-size: 14px; color: #D69D10">Sua efetivação ocorrerá <span
                                                    *ngIf="isToday(key.data_agendamento_bacen) === false">amanhã</span><span
                                                    *ngIf="isToday(key.data_agendamento_bacen) === true">hoje</span> às
                                                08:00.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="arrow"></div>
                            </div>


                        </span>
                        <span class="icon-alert">
                            <span class="icon-info notification" [appTooltip]="tooltipAll"
                                *ngIf="!isPeddingotherMaster(key.aprovacoes) && key.ds_status_consolidado === 'IB_APROVACAO_PENDENTE'">
                                <img src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 4px !important;"
                                    alt="logo" />
                                <div #tooltipAll class="tooltip-custom-alert">
                                    <p style="font-size: 14px;" class="text-center">Aguardando aprovação</p>
                                    <div class="master" style="text-align: start;
                                    margin-left: 22px;">
                                        <div class="masters-title" style="font-size: 12px; margin-top: 5px;">
                                            <img src="/assets/svg/marcador-icon.svg">
                                            <span class="master-font"> Masters </span>
                                        </div>

                                        <span class="master-sub" style="color: #8F969D;
                                        font-size: 12px; margin-top: 5px;" *ngFor="let item of key.aprovacoes">
                                            <p *ngIf="item.status === 'PENDENTE'" style="margin:0px">
                                                {{item.pessoa.nome_pessoa}}</p>
                                        </span>

                                    </div>
                                    <div class="arrow"></div>
                                </div>
                            </span>
                        </span>
                        <img src="/assets/svg/pending-watch.svg" alt="watch" style="margin-right: 8px;"
                            *ngIf="isPeddingotherMaster(key.aprovacoes)">
                        <span class="status-message-orange">
                            <span *ngIf="key.fl_portabilidade && !key.fl_processado_bacen">
                                Aprovação de portabilidade de chave pendente</span>
                            <span *ngIf="key.fl_posse && !key.fl_processado_bacen">
                                Aprovação de reivindicação de chave pendente</span>
                            <span *ngIf="key.fl_portabilidade && key.fl_processado_bacen">
                                Aprovação de portabilidade de chave pendente</span>
                            <span *ngIf="key.fl_posse && key.fl_processado_bacen">
                                Aprovação de reivindicação de chave pendente</span>
                        </span>
                    </div>
                    <div class="card-content" *ngIf="!isPeddingotherMaster(key.aprovacoes)">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value">
                                <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ? key.chave : 'Chave aleatória'}}</span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}</span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '+00 00 00000-0000' }}</span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}}</span>
                            </span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Conta</span>
                            <span class="acc-value" *ngIf="key.conta">{{key.conta.num_conta}} - {{key.conta.exibicao}}</span>
                        </div>
                        <div class="date">
                            <span class="date-title"> Data e hora</span>
                            <span class="date-time">{{key.data_criacao | date:'dd/MM/yyyy HH:mm' }}</span>
                        </div>
                        <!-- <div class="card-options" *ngIf="isPeddingotherMaster(key.aprovacoes)">
                            <img src="/assets/svg/fechar.svg" alt="fechar" style="margin-right: 32px; cursor: pointer;" (click)="openModal(contentReprove, key)">
                            <img src="/assets/svg/aprovar.svg" alt="aprovar" style="cursor: pointer;" (click)="approveKeys(key)">
                        </div> -->
                    </div>
                    <div class="card-content-two" *ngIf="isPeddingotherMaster(key.aprovacoes)">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value">
                                <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ? key.chave : 'Chave aleatória'}}</span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}</span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '+00 00 00000-0000' }}</span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}}</span>
                            </span>
                        </div>
                        <div class="text-div">
                            <span class="text-font" *ngIf="key.fl_portabilidade">

                                Recebemos o pedido de portabilidade da chave Pix <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ? key.chave : 'Chave aleatória'}} </span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}</span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '+00 00 00000-0000' }}</span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}} </span>. Para conclusão desse processo é necessária
                                a sua confirmação até o dia {{key.data_limite_confirm_doador | date:'dd/MM/yyyy' }}.
                            </span>
                            <span class="text-font" *ngIf="key.fl_posse">
                                Recebemos pedido de reivindicação de posse da chave Pix <span
                                    *ngIf="key.tp_chave === 'EVP'">{{key.chave
                                    ? key.chave : 'Chave aleatória'}} </span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}
                                </span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '+00 00 00000-0000' }}
                                </span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}} </span>. Isso significa que outro
                                usuário registrou pedido para que essa chave seja vinculada a outra conta. Para cancelar
                                esse processo e para que você possa continuar utilizando essa chave,
                                é necessário que você faça nova validação de posse até o dia
                                {{key.data_limite_confirm_doador | date:'dd/MM/yyyy' }}.
                            </span>
                        </div>
                        <div class="buttons">
                            <button type="button" class="button-secondary"
                                (click)="refuseKeyPort(key);isApproved = false">
                                Recusar
                            </button>
                            <button type="button" class="button-primary" (click)="approveKeys(key)"> Aprovar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row d-flex justify-content-center alert-information warnings" *ngIf="warnRefused">
        <div class="col-4">
            <img src="assets/png/icon-error.png" alt="" class="img-fluid">
            <p class="alert-title alert-title-damage" style="color:  #ed6c6c"> {{warnTitle}}</p>
            <p class="alert-text" style="color : #8F969D, 100%">{{warnSubtitle}}.</p>
        </div>
        <div class="col-12 buttons">
            <button class="button-white" (click)='goBack()'>Fechar</button>
        </div>

    </div>
    <div class="warnings" *ngIf="warnPending">
        <div class="warning-div">
            <fibra-generic-info type="warn" title="{{warnTitle}}" message="{{warnSubtitle}}">
            </fibra-generic-info>

            <div class="buttons">
                <button type="button" class="button-white" (click)='goBack()'>
                    {{textBntPrimary}}
                </button>
                <button class="button-continue" style="margin-top: 10px" *ngIf="showBtnSecondary"
                    (click)="goToKeys(responseKey.num_conta)">
                    {{texBntSecondary}}
                </button>
            </div>
        </div>
    </div>
    <div class="warnings" *ngIf="warnSucces">
        <div class="warning-div">
            <fibra-generic-info type="success" title="{{warnTitle}}" message="{{warnSubtitle}}">
            </fibra-generic-info>

            <div class="buttons">
                <button type="button" class="button-white" (click)='goBack()'>
                    {{textBntPrimary}}
                </button>
                <button class="button-continue" style="margin-top: 10px" *ngIf="showBtnSecondary">
                    {{texBntSecondary}}
                </button>
            </div>
        </div>
    </div>


    <div class="keys-content" style="height: 200px;justify-content: center;align-items: center;"
        *ngIf="!loading && selectedNavBar === 'keys'">
        <div>
            <span class="no-content" *ngIf="keyapproved.length === 0 && seletedFilter === 'approved'">
                Nenhuma solicitação de cadastro de chave aprovada ainda.
            </span>
            <span class="no-content" *ngIf="keyPending.length === 0 && seletedFilter === 'pending'">
                Nenhuma solicitação de cadastro de chave pendente ainda.
            </span>
            <span class="no-content" *ngIf="keysRefused.length === 0 && seletedFilter === 'refused'">
                Nenhuma solicitação de cadastro de chave recusada ainda.
            </span>
        </div>
    </div>


    <div class="keys-content" style="height: 200px;justify-content: center;align-items: center;"
        *ngIf="!loading && selectedNavBar === 'sendSolicitation'">
        <span class="no-content" *ngIf="keyPendingSolicitationSend.length === 0 &&  seletedFilter === 'pending'">
            Nenhuma solicitação de portabilidade/reivindicação de chave pendente ainda.
        </span>
        <span class="no-content" *ngIf="keyapprovedSolicitationSend.length === 0 && seletedFilter === 'approved'">
            Nenhuma solicitação de portabilidade/reivindicação de chave aprovada ainda.
        </span>
        <span class="no-content" *ngIf="keysRefusedSolicitationSend.length === 0 && seletedFilter === 'refused'">
            Nenhuma solicitação de portabilidade/reivindicação de chave recusada ainda.
        </span>

    </div>

    <div class="keys-content" style="height: 200px;justify-content: center;align-items: center;"
        *ngIf="!loading && selectedNavBar === 'receiveRequest'">
        <span class="no-content" *ngIf="keyPendingReceivedRequest.length === 0 &&  seletedFilter === 'pending'">
            Nenhuma solicitação de portabilidade/reivindicação de chave pendente ainda.
        </span>
        <span class="no-content" *ngIf="keyapprovedReceivedRequest.length === 0 && seletedFilter === 'approved'">
            Nenhuma solicitação de portabilidade/reivindicação de chave aprovada ainda.
        </span>
        <span class="no-content" *ngIf="keysRefusedReceivedRequest.length === 0 && seletedFilter === 'refused'">
            Nenhuma solicitação de portabilidade/reivindicação de chave recusada ainda.
        </span>

    </div>


    <div class="keys-content" *ngIf="loading" style="height: 200px;justify-content: center;align-items: center;">
        <div class="d-flex justify-content-center position loading-container">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>

    <!-- <div class="content-key" *ngIf="loading">
        <div class="d-flex justify-content-center position loading-container">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div> -->

</div>

<!-- modal adesão -->
<div class="modal">
    <ng-template #content let-modal>
        <div class="conteudo-modal-confirmation">
            <div class="generic-info">
                <img src="/assets/png/icon-pending.png" style="width: 29px;height: 29px;" />
                <span class="info-title">Aceite de termos de adesão ao Pix</span>
                <span class="generic-info-message">Para aprovar o cadastro da chave, você precisa aceitar o Termo de
                    Adesão
                    ao Pix.</span>
            </div>
            <div class="buttons">
                <button type="button" class="button-secondary" (click)="openTerm()">
                    Ver Termo
                </button>
                <button type="button" class="button-primary"> Aceitar Termo </button>
            </div>
            <div class="info-div">
                <img class="info-img" src="/assets/svg/icon-info-blue.svg"> <span class="info-font"> Ao tocar no botão
                    Aceitar
                    Termo, você concorda com o Termo de Adesão ao Pix do Banco Fibra.</span>
            </div>
        </div>

    </ng-template>
</div>





<!-- modal token -->
<fibra-modal-validation #content (responseValidation)="responseApiModal($event)" *ngIf="showModalValidation"
    [apiCallback]="apiCallback" [apiPayload]="payload" (closeEvent)="closeModalApi($event)">
</fibra-modal-validation>

<div class="content-terms" *ngIf="showTerms">
    <fibra-pix-term (closeEvent)="closeModal($event)" (response)="responseApiModalTerms($event)"
        [accountData]="selectedAccount" (setTerm)="changeTerm($event)">
    </fibra-pix-term>
</div>


<div class="modal">
    <ng-template #contentSms let-modal>
        <div class="conteudo-modal-validation">
            <div class="validacao-div">
                <span class="validacao-title"> Validação por SMS</span>
                <span class="validacao-text"> Insira o código que você recebeu por SMS no número {{numCelular}}</span>
                <div class="input-div">
                    <input type="text" maxlength="6" placeholder="000000" [(ngModel)]="token" [textMask]="{
                  mask: tokenMask,
                  placeholderChar: '_',
                  guide: false,
                  modelClean: true
                }" [ngClass]="timeoutToken === true ? 'line-code-bar-red' : 'line-code-bar-blue'" name="tokenCel">
                    <span [ngClass]="timeoutToken === true ? 'timer-red' : 'timer-blue'" style="display: flex;">
                        <span><img *ngIf="!timeoutToken" src="/assets/svg/time-elipse.png"
                                style="margin-right: 5px; width: 16px;"> <img *ngIf="timeoutToken"
                                src="/assets/svg/elipse-danger.svg" style="margin-right: 5px; width: 16px;"></span>
                        {{minutes}}:{{counter >= 10 ? counter : "0"+counter}}</span>

                </div>
                <div class="timeout-div" *ngIf="timeoutToken">
                    <img src="/assets/svg/icon-info-red.svg" alt="info">
                    <span class="timeout-warning"> Tempo expirado. Toque no botão para receber <strong> novo código
                        </strong>
                    </span>
                </div>
                <div class="timeout-div" *ngIf="isCodInvalid">
                    <img src="/assets/svg/icon-info-red.svg" alt="info">
                    <span class="timeout-warning"> Código inválido. Digite o código novamente.
                    </span>
                </div>
            </div>
            <div class="buttons" *ngIf="!timeoutToken">
                <button type="button" class="button-secondary" (click)="closeModal($event)">
                    Cancelar
                </button>
                <button type="button" class="button-primary"
                    (click)="isApproved ? approveKeys(selectedKEy) :  refusedKey()" [disabled]="token.length  < 6">
                    Confirmar
                </button>
            </div>
            <div class="buttons">
                <button type="button" class="button-primary" (click)="sendToken(selectedKEy)" *ngIf="timeoutToken"
                    style="margin-top: 0px !important;">
                    Receber novo código </button>
            </div>
        </div>

    </ng-template>
</div>

<div class="modal">
    <ng-template #contentEmail let-modal>
        <div class="conteudo-modal-validation">
            <div class="validacao-div">
                <span class="validacao-title"> Validação por Email</span>
                <span class="validacao-text"> Insira o código que você recebeu por Email no email: <strong>
                        {{email}}</strong></span>
                <div class="input-div">
                    <input type="text" maxlength="6" placeholder="000000" [textMask]="{
                  mask: tokenMask,
                  placeholderChar: '_',
                  guide: false,
                  modelClean: true
                }" [(ngModel)]="token" [ngClass]="timeoutToken === true ? 'line-code-bar-red' : 'line-code-bar-blue'"
                        name="tokenCel">
                    <span [ngClass]="timeoutToken === true ? 'timer-red' : 'timer-blue'" style="display: flex;">
                        <span><img *ngIf="!timeoutToken" src="/assets/svg/time-elipse.png"
                                style="margin-right: 5px; width: 16px;"> <img *ngIf="timeoutToken"
                                src="/assets/svg/elipse-danger.svg" style="margin-right: 5px; width: 16px;"></span>
                        {{minutes}}:{{counter >= 10 ? counter : "0"+counter}}</span>
                </div>

            </div>
            <div class="timeout-div" *ngIf="timeoutToken">
                <img src="/assets/svg/icon-info-red.svg" alt="info">
                <span class="timeout-warning"> Tempo expirado. Toque no botão para receber <strong> novo código
                    </strong>
                </span>
            </div>
            <div class="timeout-div" *ngIf="isCodInvalid">
                <img src="/assets/svg/icon-info-red.svg" alt="info">
                <span class="timeout-warning"> Código inválido. Digite o código novamente.
                </span>
            </div>

            <div class="buttons" *ngIf="!timeoutToken">
                <button type="button" class="button-secondary" (click)="closeModal($event)">
                    Cancelar
                </button>
                <button type="button" class="button-primary"
                    (click)="isApproved ? approveKeys(selectedKEy) :  refusedKey()" [disabled]="token.length  < 6">
                    Confirmar
                </button>
            </div>
            <div class="buttons">
                <button type="button" class="button-primary" (click)="sendToken(selectedKEy)" *ngIf="timeoutToken"
                    style="margin-top: 0px !important;">
                    Receber novo código </button>
            </div>
        </div>

    </ng-template>
</div>

<div class="message-transaction" id="message">
    <img src="/assets/svg/warning.svg" />
    <div class="text">
        <h4 class="title">{{msgError}}</h4>
        <div class="msg">{{subTextError}}</div>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="closeError()">
        <img src="/assets/svg/icon_close_white.svg" />
    </button>
</div>

<div class="modal">
    <ng-template #contentReprove let-modal>
        <div class="conteudo-modal-reprovar">
            <div class="generic-info">
                <span class="info-title">Recusar Cadastro de Chave</span>
                <span class="generic-info-message">Você gostaria mesmo de recusar <span *ngIf="selectedKEy.tp_operacao === 'CRIAR'">o cadastramento</span><span *ngIf="selectedKEy.tp_operacao === 'EXCLUIR'">a exclusão</span> da chave
                    <bold>
                        <span *ngIf="selectedKEy.tp_chave === 'EVP'">{{ selectedKEy.chave ? selectedKEy.chave : 'aleatória' }}</span>
                        <span *ngIf="selectedKEy.tp_chave === 'CPF' || selectedKEy.tp_chave === 'CNPJ'">{{ selectedKEy.chave | cpfcnpj }}</span>
                        <span *ngIf="selectedKEy.tp_chave === 'PHONE'">{{ selectedKEy.chave | mask: '+00 00 00000-0000' }}</span>
                        <span *ngIf="selectedKEy.tp_chave === 'EMAIL'">{{ selectedKEy.chave}}</span>
                    </bold>?
                </span>
            </div>
            <div class="buttons">
                <button type="button" class="button-secondary" (click)="closeModal($event)">Não</button>
                <button type="button" class="button-primary" (click)="refusedKey()">Sim</button>
            </div>
        </div>

    </ng-template>
</div>