<div class="comprovante" id="comprovante" *ngIf="readyToLoad">
    <div class="close-div" id="fechar">
        <img src="/assets/svg/icon-fechar-black.svg" alt="fechar" id="ico-fechar" (click)="goBack()" style="display:none">
    </div>
    <div class="header">
        <div class="header-content">
            <img src="/assets/svg/logo_fibra.svg" alt="logo" style="width: 165px;height: 49px;">
            <span class="header-text"> Internet Banking - Banco Fibra - Empresa: {{company[0].name}} - CNPJ {{company[0].modality_account}} - {{day}} {{hours}}</span>
        </div>
        <span class="title-report"> Comércio Exterior</span>
    </div>

    <div class="report-content">
        <div class="report-title-div">
            <span class="report-title"> {{reportData.title}}</span>
            <div class="div" style="margin-bottom: 16px;">
                <span class="report-subtitle" style="margin-right: 16px;"> {{reportData.subtitle1}} </span> <span class="report-subtitle-value"> {{reportData.subTitle1Value}}</span>

            </div>
            <div class="div">
                <span class="report-subtitle" *ngIf="reportData.subtitle2 !== null" style="margin-right: 16px;"> {{reportData.subtitle2}} </span> <span class="report-subtitle-value"> {{reportData.subtitle2Value}}</span>

            </div>
        </div>
    </div>
    <div class="div" style="display: flex;" *ngIf="changeArray !== null">
        <div>
            <div class="datatable" id="cambio-pronto" *ngFor="let change of changeArray">
                <div class="date">
                    <span class="date-font"> {{change.data}}</span>
                </div>
                <div class="table-title">
                    <span class="title-font"> Contrato: {{change.num_contrato}} </span>
                    <span class="title-font"> Tipo de operação: {{change.tipo}}</span>
                </div>
                <div class="table-row">
                    <div class="table-item">
                        <span class="item-font-gray">Data de contratação</span>
                        <span class="item-font">{{change.dt_op}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font-gray">Data de liquidação</span>
                        <span class="item-font">{{change.dt_liq}}</span>
                    </div>

                </div>
                <div class="table-row">
                    <div class="table-item">
                        <span class="item-font-gray">Moeda estrangeira</span>
                        <span class="item-font">USD: {{change.valorFormatadoUsd}} </span>
                    </div>
                    <div class="table-item">
                        <span class="item-font-gray">Moeda nacional </span>
                        <span class="item-font">BRL: {{change.valorFormatadoBrl}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font-gray">OP</span>
                        <span class="item-font">-</span>
                    </div>


                </div>
            </div>
        </div>


        <div style="width: 500px ; height: 222px; margin:0px 10px 10px 10px">
            <section class="sideCard">
                <h1 class="title">Totais</h1>
                <div class="side-card-content">
                    <div *ngFor="let value of changeTotais">
                        <div class="list" id="sideCardFirstRow">
                            <div class="item">
                                <p class="margin-bottom-8">
                                    <span>{{value.swift}}</span>
                                </p>
                                <p class="margin-bottom-8">
                                    {{ (value.total | number: '1.2-2' ) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="loading-container d-flex justify-content-center align-items-center" *ngIf="loading">
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div> -->
            </section>
        </div>

        <!-- <div id="cambio-pronto" class="card" style="
        width: 286px;
        height: 242px;
        left: 593px;
        top: 468px;">
            <div>Totais</div>
            <div>EUR</div>
            <div>10,000.00</div>

            <div>USD</div>
            <div>10,000.00</div>
        </div> -->
    </div>

    <div class="div" *ngIf="finimpArray !== null ">
        <div class="datatable" id="importacao-finimp" *ngFor="let finimp of finimpArray">
            <div class="table-title">
                <span class="title-font"> Contrato: {{finimp.num_contrato}}</span>
            </div>
            <div class="table-row">
                <div class="table-item">
                    <span class="item-font-gray">Data de desembolso</span>
                    <span class="item-font">{{finimp.dt_op}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Data de vencimento</span>
                    <span class="item-font">{{finimp.dt_vencto}}</span>
                </div>

            </div>
            <div class="table-row">
                <div class="table-item">
                    <span class="item-font-gray">Juros %</span>
                    <span class="item-font">{{finimp.taxa_en}} </span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Moeda </span>
                    <span class="item-font">{{finimp.swift}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Valor de Juros</span>
                    <span class="item-font">{{finimp.encargo}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Valor</span>
                    <span class="item-font">{{finimp.usdFormatado}}</span>
                </div>

            </div>
        </div>
    </div>

    <div class="div" *ngIf="cobrancaArrayImport !== null ">
        <div class="datatable" id="importacao-cobranca" *ngFor="let cob of cobrancaArrayImport">
            <div class="table-title">
                <span class="title-font"> Ref. cliente: {{cob.num_ref}}</span>
            </div>
            <div class="table-row">
                <div class="table-item">
                    <span class="item-font-gray">Ref. banco</span>
                    <span class="item-font">{{cob.num_contrato}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Data de recepção</span>
                    <span class="item-font">{{cob.dt_reg}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Tipo de vencimento</span>
                    <span class="item-font">{{cob.dt_vencto}}</span>
                </div>

            </div>
            <div class="table-row">
                <div class="table-item" style="width: 35% !important;">
                    <span class="item-font-gray">Banco emissor / Banco exportador </span>
                    <span class="item-font">{{cob.nome_banco_emissor}}</span>
                    <span class="item-font" style="margin-top: 4px;">{{cob.nome_exportador}}</span>
                </div>
                <div class="table-item" style="width: 15% !important">
                    <span class="item-font-gray">Moeda</span>
                    <span class="item-font">{{cob.swift}} </span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Valor</span>
                    <span class="item-font">{{cob.usdFormatado}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Saldo</span>
                    <span class="item-font">{{cob.saldoFormatado}}</span>
                </div>

            </div>
        </div>
    </div>

    <div class="div" *ngIf="creditArrayImport !== null ">
        <div class="datatable" id="importacao-cartacredito" *ngFor="let credit of creditArrayImport">
            <div class="table-title">
                <span class="title-font"> Exportador: {{credit.nome_contraparte}}</span>
            </div>
            <div class="table-row">
                <div class="table-item">
                    <span class="item-font-gray">Ref. banco</span>
                    <span class="item-font">{{credit.num_contrato}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Data de abertura</span>
                    <span class="item-font">{{credit.dt_op}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Data de embarque</span>
                    <span class="item-font">{{credit.dt_liq_or}}</span>
                </div>

            </div>
            <div class="table-row">
                <div class="table-item" style="width: 35% !important">
                    <span class="item-font-gray">Banco emissor / Banco avisador </span>
                    <span class="item-font">{{credit.nome_banco_avisador}}</span>
                </div>
                <div class="table-item" style="width:15% !important;">
                    <span class="item-font-gray">Moeda</span>
                    <span class="item-font">{{credit.swift}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Valor da carta de crédito</span>
                    <span class="item-font">{{(credit.saldo_a_utilizar + credit.saldo_utilizado) | number:'1.2-2'}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Saldo utilizado</span>
                    <span class="item-font">{{credit.saldoUtilizadoForm}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Saldo disponível</span>
                    <span class="item-font">{{((credit.saldo_a_utilizar + credit.saldo_utilizado)- credit.saldo_utilizado) | number:'1.2-2' }}</span>
                </div>

            </div>
        </div>
    </div>


    <div class="div" *ngIf="accAceArray !== null ">
        <div class="datatable" id="exportacao-accace" *ngFor="let acc of accAceArray">
            <div class="table-title">
                <span class="title-font"> Contrato: {{acc.num_contrato}}</span>
            </div>
            <div class="table-row">
                <div class="table-item">
                    <span class="item-font-gray">Data de contratação</span>
                    <span class="item-font">{{acc.dt_op}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Data de entrega</span>
                    <span class="item-font">{{acc.dt_vencto}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Data de liquidação</span>
                    <span class="item-font">{{acc.dt_vencto2}}</span>
                </div>

            </div>
            <div class="table-row">
                <div class="table-item" style="width: 18% !important">
                    <span class="item-font-gray">Moeda </span>
                    <span class="item-font">{{acc.swift}}</span>
                    <span class="item-font" style="margin-top: 4px;">BRL</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Valor do contrato</span>
                    <span class="item-font">{{acc.valorContratoUsd}} </span>
                    <span class="item-font" style="margin-top: 4px;">{{acc.valorContratoBrl}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Saldo LAE (ACC)</span>
                    <span class="item-font">{{acc.saldoLaeUsd}}</span>
                    <span class="item-font" style="margin-top: 4px;">{{acc.saldoLaeBrl}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Saldo LE (ACE)</span>
                    <span class="item-font">{{acc.saldoLeUsd}}</span>
                    <span class="item-font" style="margin-top: 4px;">{{acc.saldoLeBrl}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Liquidado</span>
                    <span class="item-font">{{acc.liquidadoUsd}}</span>
                    <span class="item-font" style="margin-top: 4px;">{{acc.liquidadoBrl}}</span>
                </div>

            </div>
        </div>
    </div>


    <div class="div" *ngIf="cobrancaArrayExport !== null ">
        <div class="datatable" id="exportacao-cobranca" *ngFor="let cob of cobrancaArrayExport">
            <div class="table-title">
                <span class="title-font"> Ref. cliente: {{cob.num_ref}}</span>
            </div>
            <div class="table-row">
                <div class="table-item">
                    <span class="item-font-gray">Ref. banco</span>
                    <span class="item-font">{{cob.num_contrato}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Contrato ACE</span>
                    <span class="item-font">{{ cob.vinculo }}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Data de recepção</span>
                    <span class="item-font">{{cob.dt_op}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Data de vencimento</span>
                    <span class="item-font">{{cob.dt_vencto}}</span>
                </div>

            </div>
            <div class="table-row">
                <div class="table-item" style="width: 50% !important">
                    <span class="item-font-gray">Banco importador </span>
                    <span class="item-font">{{cob.nome_importador}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Moeda</span>
                    <span class="item-font">{{cob.swift}} </span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Saldo</span>
                    <span class="item-font">{{cob.saldoFormatado}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="div" *ngIf="creditArrayExport !== null ">
        <div class="datatable" id="exportacao-cartacredito" *ngFor="let credit of creditArrayExport">
            <div class="table-title">
                <span class="title-font"> Carta de crédito: {{credit.cod}}</span>
            </div>
            <div class="table-row">
                <div class="table-item">
                    <span class="item-font-gray">Ref. banco</span>
                    <span class="item-font">{{credit.num_ref}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Situação</span>
                    <span class="item-font">{{credit.tipo_lc}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Data de remessa docs</span>
                    <span class="item-font">{{credit.dt_me}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Data de vencimento</span>
                    <span class="item-font">{{credit.dt_vencto}}</span>
                </div>

            </div>
            <div class="table-row">
                <div class="table-item" style="width: 65% !important">
                    <span class="item-font-gray">Banco emissor / Banco importador </span>
                    <span class="item-font" style="margin-bottom: 2px;">{{credit.nome_banco_emissor}}</span>
                    <span class="item-font">{{credit.nome_importador}}</span>
                </div>
                <div class="table-item" style="width: 15% !important">
                    <span class="item-font-gray">Moeda</span>
                    <span class="item-font">{{credit.swift}} </span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Valor da carta de crédito negociada</span>
                    <span class="item-font">{{(credit.saldo_a_vincular) | number:'1.2-2'}}</span>
                </div>
                <!-- <div class="table-item">
                    <span class="item-font-gray">Saldo Utilizado</span>
                    <span class="item-font">{{credit.saldoUtilizado}}</span>
                </div> -->

            </div>
            <!-- <div class="table-row">
                <div class="table-item">
                    <span class="item-font-gray">Saldo disponível</span>
                    <span class="item-font">{{(credit.saldo_a_vincular - credit.saldo_le) | number:'1.2-2' }}</span>
                </div>
            </div> -->
        </div>
    </div>

    <div class="div" *ngIf="paymentArray !== null ">
        <div class="datatable" id="ordem-pgto" *ngFor="let pay of paymentArray">
            <div class="table-title">
                <span class="title-font"> Ordenante: {{pay.nome_contraparte}}</span>
            </div>
            <div class="table-row">
                <div class="table-item">
                    <span class="item-font-gray">Ref. banco</span>
                    <span class="item-font">{{pay.num_contrato}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Data de recepção</span>
                    <span class="item-font">{{pay.dt_op}}</span>
                </div>
            </div>
            <div class="table-row">
                <div class="table-item">
                    <span class="item-font-gray">Moeda </span>
                    <span class="item-font">{{pay.swift}} </span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Valor</span>
                    <span class="item-font">{{pay.valorFormatado}} </span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Saldo</span>
                    <span class="item-font">{{pay.saldoFormatado}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="div" *ngIf="issuedArray !== null ">
        <div class="datatable" id="garantias-emitidas" *ngFor="let issued of issuedArray">
            <div class="table-title">
                <span class="title-font"> Beneficiário: {{issued.nome_beneficiario}}</span>
            </div>
            <div class="table-row">
                <div class="table-item">
                    <span class="item-font-gray">Ref. banco</span>
                    <span class="item-font">{{issued.num_contrato}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Data de emissão</span>
                    <span class="item-font">{{issued.dt_op}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Data de vencimento</span>
                    <span class="item-font">{{issued.dt_vencto}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Tipo</span>
                    <span class="item-font">{{issued.modalidade | titlecase}}</span>
                </div>

            </div>
            <div class="table-row">
                <div class="table-item" style="width: 50% !important">
                    <span class="item-font-gray">Banco avisador </span>
                    <span class="item-font">{{issued.nome_banco_avisador}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Moeda</span>
                    <span class="item-font">{{issued.swift}} </span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Valor</span>
                    <span class="item-font">{{issued.valorFormatado}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="div" *ngIf="receivedArray !== null ">
        <div class="datatable" id="garantias-recebidas" *ngFor="let received of receivedArray">
            <div class="table-title">
                <span class="title-font"> Ref. banco: {{received.num_contrato}}</span>
            </div>
            <div class="table-row">
                <div class="table-item">
                    <span class="item-font-gray">Data de recepção</span>
                    <span class="item-font">{{received.dt_op}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Data de vencimento</span>
                    <span class="item-font">{{received.dt_vencto}}</span>
                </div>

            </div>
            <div class="table-row">
                <div class="table-item">
                    <span class="item-font-gray">Moeda</span>
                    <span class="item-font">{{received.moeda}} </span>
                </div>
                <div class="table-item">
                    <span class="item-font-gray">Valor</span>
                    <span class="item-font">{{received.valorFormatado}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="card-content">
        <div class="card">
            <div class="html2pdf__page-break"></div>
            <br><br>
            <div class="card-body" #atendimento>
                <img class="icon" src="/assets/png/apoio 1.png" alt="Atendimento" />
                <div class="text">
                    <div class="title">Canais exclusivos</div>
                    <div class="desc">Dúvidas e apoio</div>
                </div>
            </div>
            <div class="card-bottom">
                <p>
                    <b>Corporate, Agro e PMEs</b>
                    (11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
                </p>
                <p>
                    <a href="mailto:relacionamento@bancofibra.com.br">relacionamento@bancofibra.com.br</a>
                </p>
            </div>
        </div>
        <div class="card">
            <div class="card-body" #sac>
                <img class="icon" src="/assets/png/telefone 1.png" alt="Atendimento" />
                <div class="text">
                    <div class="title">SAC</div>
                    <div class="desc">Sugestões, reclamações e elogios</div>
                </div>
            </div>
            <div class="card-bottom">
                <p>
                    <b>Canal de Atendimento ao Cliente</b>
                </p>
                <p>
                    0800 727 0132
                </p>
                <p>
                    <b>Canal exclusivo para deficientes auditivos ou de fala</b>
                </p>
                <p>
                    0800 200 6921
                </p>
            </div>
        </div>
        <div class="card">
            <div class="card-body" #ouvidoria>
                <img class="icon" src="/assets/png/alto-falante.png" alt="Atendimento" />
                <div class="text">
                    <div class="title">Ouvidoria</div>
                    <div class="desc">Demandas não solucionadas</div>
                </div>
            </div>
            <div class="card-bottom">
                <p>
                    0800 727 0132, das 9h às 18h, de segunda a sexta-feira, exceto feriados.<br><br>
                </p>
            </div>
        </div>
    </div>
</div>