<div class="content">
    <div class="group-items">
      <div class="period" *ngIf="!noDataProdutos">
        <div class="label-period">Produtos</div>
        <fibra-form-control-group>
            <fibra-form-control [style.width.px]="'auto'">
              <fibra-dropdown
                class="dropdown-extract-list"
                (change)="filterProduct($event)" 
                [options]="produtos" 
                customClass="select-option extrato" 
                placeholder="Selecione" 
                removeShadown="true">
              </fibra-dropdown>
            </fibra-form-control>
        </fibra-form-control-group>
        <div class="label-period">
          <input
            type="checkbox"
            class="form-check-input"
            id="emissaoCheckbox"
            (change) = "changeFilterRadio()"
          />
          Emissão
        </div>
        <div class="label-period">
          <input
            type="checkbox"
            class="form-check-input"
            id="resgateCheckbox"
            (change) = "changeFilterRadio()"
          />
          Resgate
        </div>
        <div class="label-period"> Período</div>
        <div class="period-div" id="period-div">
          <div class="period-item-unselected" id="seven-div" (click) = "changePeriodFilter('7');getFilterData('7')">
            <span class="period-font-unselected"  id="seven-font"> 7 dias </span>
          </div>
          <div class="period-item-unselected" id="fifteen-div" (click) = "changePeriodFilter('15');getFilterData('15')">
            <span class="period-font-unselected"  id="fifteen-font"> 15 dias </span>
          </div>
          <div class="period-item-unselected"  id="thirthy-div" (click) = "changePeriodFilter('30');getFilterData('30')">
            <span class="period-font-unselected"  id="thirthy-font"> 30 dias</span>
          </div>
          <div class="period-item-unselected"  id="ninethy-div" (click) = "changePeriodFilter('90');getFilterData('90')">
            <span class="period-font-unselected"  id="ninethy-font"> 90 dias</span>
          </div>
          <div class="period-item-unselected"  id="calendar-div" (click) = "changePeriodFilter('calendar')">
            <button (click)="periodModal()" class="btn btn-outline calendar" type="button"><img id="calendar" src="/assets/svg/icon_calendar_gray.svg" alt="icon" ></button>
            <fibra-modal-period-datepicker #content *ngIf="showModalPeriodDatepicker" 
                (change)="changePeriodDate($event)" 
                (closeEvent)="closeEvent($event)" 
                [maxDate]="maxDate"></fibra-modal-period-datepicker>
          </div>
        </div>
      </div>
    </div>
    <div class="lado-a-lado">
      <div class="datatable" *ngIf="loading && !hasError">
        <div class="dataSelected" *ngIf="dataInicio && dataFim">
          <div class="dataSelected-item">
            <span class="dataSelected-font">
              Data filtrada: {{dataInicio | date: 'dd/MM/yyyy'}} até {{dataFim | date: 'dd/MM/yyyy'}}
            </span>
          </div>
        </div>
        <div class="table-title">
          <div class="table-item">
            <span class="item-font">Produto</span>
          </div>
          <div class="table-item-emissao">
            <span class="item-font">Data de emissão</span>
          </div>
          <div class="table-item-vencimento">
            <span class="item-font">Data de Vencimento</span>
          </div>
          <div class="table-item-movimentacao">
            <span class="item-font" >Data da movimentação</span>
          </div>
          <div class="table-item">
            <span class="item-font">Histórico</span>
          </div>
          <div class="table-item">
            <span class="item-font" style="float: right; padding-right: 4vw;">Valor aplicado</span>
          </div>
          <div class="table-item">
            <span class="item-font">Número da nota</span>
          </div>
          <div class="table-item">
  
          </div>
  
  
        </div>
        <div class="table-body" *ngIf="!noData">
          <div class="table-content" *ngFor="let item of extract; let i = index" >
            <div class="table-row" >
              <div class="table-item">
                <span class="item-font">{{item.produto }}</span>
              </div>
              <div class="table-item">
                <span class="item-font">{{item.dat_emissao | date: 'dd/MM/yyyy'}}</span>
              </div>
              <div class="table-item">
                <span class="item-font">{{item.dat_vencto | date: 'dd/MM/yyyy'}}</span>
              </div>
              <div class="table-item">
                <span class="item-font">{{ item.dat_mov | date: 'dd/MM/yyyy'}}</span>
              </div>
              <div class="table-item">
                <span class="item-font">{{item.historico}}</span>
              </div>
              <div class="table-item">
                <span class="item-font" style="float: right; padding-right: 4vw;">R$ {{item.val_aplic | number: '1.2-2' }}</span>
              </div>
              <div class="table-item">
                <span class="item-font">{{item.numero_nota}}</span>
              </div>
              <div class="table-item" *ngIf = "item.url">
                <span class="item-icon">
                  <a class="pdf-link" (click)="downloadFile(item.url)" >
                    <img src="/assets/svg/download-accent-dark.svg" alt="download-accent" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="table-body" *ngIf="noData && !noDataProdutos">
            <fibra-generic-info type="warn" title="Nenhum resultado encontrado."
              message="Não há informações para serem apresentadas.">
            </fibra-generic-info>
        </div>
        <div class="table-body" *ngIf="noDataProdutos">
          <fibra-generic-info type="warn" title="Não há informações para serem apresentadas.">
          </fibra-generic-info>
      </div>
      </div>
    </div>
  
  
    <div class="datatable warning-display" *ngIf="!loading">
      <div class="d-flex justify-content-center position loading-container">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  
    <div class="datatable warning-display" *ngIf="hasError && loading">
      <div class="empty-info">
        <fibra-generic-info type="error" title="Ocorreu um erro."
          message="No momento não foi possível concluir sua pesquisa. Tente novamente.">
        </fibra-generic-info>
      </div>
    </div>
  
  </div>
