<div class="content">
  <div class="title">Transações</div>

  <div class="list-value">
    <div class="box-header">
      <div class="first-stage" [ngClass]="{ active: active == 'first' }" (click)="handleTabs('first')"
        *fibraHasRole="'PAG_BOL.REALIZAR_PAG_BOL'">
        <label class="stage-label">Pagamento de boleto</label>
      </div>
      <div class="second-stage" [ngClass]="{ active: active == 'second' }" (click)="handleTabs('second')" *fibraHasRole="'TRANSF.TRANSF_MSM_TITU,
          TRANSF.TRANSF_OTR_TITU'">
        <label class="stage-label">Transferência entre contas</label>
      </div>
    </div>
    <div *fibraHasRole="'PAG_BOL.REALIZAR_PAG_BOL'">
      <div class="box-body body-payment" *ngIf="active === 'first'">
        <div class="column-options">
          <div class="label-sub-title">Informe o código de barras</div>
          <form [formGroup]="reactiveForm">
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" id="digitableLine" name="reactiveRadioCodeBar" mdbInput
                formControlName="reactiveRadioCodeBar" [value]="true" />
              <label class="form-check-label" for="digitableLine">Linha digitável</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" id="opticalReader" name="reactiveRadioCodeBar" mdbInput
                formControlName="reactiveRadioCodeBar" [value]="false" />
              <label class="form-check-label" for="opticalReader">Leitor ótico</label>
            </div>
          </form>
        </div>

        <div class="column-code-bar" *ngIf="digitableLine">
          <div class="label-sub-title">Digite o código de barras</div>
          <div>
            <input type="text" maxlength="60" placeholder="Código de barras" class="line-code-bar"
              (change)="changeCodeBar($event)" (keyup)="onlyNumbers($event)" onlyNumber>
            <div *ngIf="!codeBarDigitavel" class="box-erro">
              <img src="/assets/png/iconErro.png">
              <label>Número do boleto inválido.</label>
            </div>
          </div>
        </div>
        <div class="column-code-bar" *ngIf="!digitableLine">
          <div class="label-sub-title">
            Posicione o leitor ótico sobre o código de barras e aguarde.
          </div>
          <div>
            <input type="text" placeholder="Aguardando leitura do código de barras" class="line-code-bar" maxlength="60"
              (change)="changeCodeBar($event)" (keyup)="onlyNumbers($event)" onlyNumber />
          </div>
        </div>
      </div>
    </div>

    <div class="box-body body-transfer" *ngIf="active === 'second'">
      <div class="row-options">
        <div class="label-sub-title">Dados do beneficiário</div>
        <form [formGroup]="reactiveForm">
          <div class="form-check form-check-inline" 
            *fibraHasRole="'TRANSF.TRANSF_MSM_TITU'"
          >
            <input type="radio" class="form-check-input" id="sameHolder" name="reactiveRadio" mdbInput
              formControlName="reactiveRadio" (change)="verifyTransferContinue()" [value]="radioTransferSame" />
            <label class="form-check-label" for="sameHolder">Mesma titularidade</label>
          </div>
          <div class="form-check form-check-inline"
            *fibraHasRole="'TRANSF.TRANSF_OTR_TITU'"
          >
            <input type="radio" class="form-check-input" id="otherHolder" name="reactiveRadio"
              (change)="verifyTransferContinue()" mdbInput formControlName="reactiveRadio" [value]="radioTransferOther" />
            <label class="form-check-label" for="otherHolder">Outra titularidade</label>
          </div>
        </form>
      </div>

      <div class="row-data-count">
        <div class="row-holder" *ngIf="sameHolder">
          <div class="col-holder">
            <div class="label-sub-title label-data-sub-title label-same-ownership">
              Nome do Beneficiário
            </div>
            <div class="label-sub-title data-hj-suppress">{{ (data && data.length > 0) ?  data[0].name : '' }}</div>
          </div>
          <div class="col-holder col-cnpj">
            <div class="label-sub-title label-data-sub-title label-same-ownership">
              CNPJ/CPF
            </div>
            <div class="label-sub-title">{{ (data && data.length > 0) ? data[0].modality_account : '' }}</div>
          </div>
        </div>

        <div class="row-holder" *ngIf="!sameHolder">
          <div class="col-holder">
            <div class="label-sub-title label-data-sub-title">
              Número da conta com o dígito
            </div>
            <div>
              <input type="text" placeholder="0000000" [ngModel]="account" class="line-code-bar input-number"
                (keyup)="getAccount($event.target.value); verifyTransferContinue()"
                onkeyup="this.value=this.value.replace(/[^\d]/,'')" maxlength="10" />
            </div>
          </div>
          <div class="col-holder">
            <div class="label-sub-title label-data-sub-title">
              Nome do beneficiário
            </div>
            <div>
              <div class="loading" *ngIf="loadingAccountData">
                <!-- Loading -->
                <div class="d-flex justify-content-center position loading-container">
                  <div class="spinner-border text-primary" role="status"></div>
                </div>
                <span>Buscando beneficiário...</span>
              </div>
              <input *ngIf="!loadingAccountData" type="text" placeholder="Nome completo/Razão social"
                class="line-code-bar input-number" [(ngModel)]="beneficiary" [readonly]=true />
            </div>
          </div>
          <div class="col-holder cnpj-same-holder">
            <div class="label-sub-title label-data-sub-title">CNPJ/CPF</div>
            <div>
              <div class="loading" *ngIf="loadingAccountData">
                <!-- Loading -->
                <div class="d-flex justify-content-center position loading-container">
                  <div class="spinner-border text-primary" role="status"></div>
                </div>
                <span>Buscando CNPJ/CPF...</span>
              </div>
              <input *ngIf="!loadingAccountData" type="text" placeholder="000.000.000/0001-00"
                class="line-code-bar input-cnpj" [(ngModel)]="doc" [readonly]=true [textMask]="{
                  mask: cpfcnpjmask,
                  placeholderChar: '_',
                  guide: false,
                  modelClean: true
                }" />
            </div>
          </div>
        </div>

        <div class="button-receipt">
          <button type="button" class="button-continue" [disabled]="disabledTransferContinue"
            (click)="sendData('holder')">
            Continuar
          </button>
        </div>
      </div>
    </div>

    <div class="button-receipt" *ngIf="active === 'first'">
      <button type="button" class="button-continue" [disabled]="disabledPaymentContinue" (click)="validateBarcode()">
        Continuar
      </button>
    </div>
  </div>
</div>