<div class="content-approval" *ngIf="!showTerm">
  <div class="content-options" *ngIf="!isCadastro">
    <div class="rounded-circle"></div>
    <div class="options-div">
      <span
        [ngClass]="
          seletedTypeView === 'contas' ? 'option-selected' : 'option-unselected'
        "
        style="margin-right: 17px; cursor: pointer"
        (click)="selectedTab('contas')"
        id="adesao"
      >
        Contas</span
      >

      <span class="separator"></span>
      <span
        [ngClass]="
          seletedTypeView === 'aprovacoes'
            ? 'option-selected'
            : 'option-unselected'
        "
        style="margin-right: 17px; margin-left: 16px; cursor: pointer"
        (click)="selectedTab('aprovacoes')"
        id="pendente"
      >
        Aprovações <span class="notif" *ngIf="notif"></span
      ></span>
      <span class="separator"></span>
      <span
        [ngClass]="
          seletedTypeView === 'horario'
            ? 'option-selected'
            : 'option-unselected'
        "
        style="margin-right: 17px; margin-left: 16px; cursor: pointer"
        (click)="selectedTab('horario')"
        id="pendente"
      >
        Horário <span class="notif" *ngIf="notif"></span
      ></span>
    </div>
  </div>

  <!-- CONTAS -->
  <div class="keys-content" *ngIf="seletedTypeView === 'contas'">
    <div class="layer_1 flex-nt">
      <div class="flex-item-1 padding-right-150 title_1">
        Alterando limite das transações da conta abaixo.
      </div>
      <div class="flex-item-1 title_1">Limite máximo diário*</div>
    </div>

    <div class="layer_2 flex-nt">
      <div class="flex-item-1 padding-right-150">
        <fibra-dropdown
          [options]="accounts"
          (change)="accountSelected($event)"
        ></fibra-dropdown>
        <span class="maxLimitAlert" *ngIf="maxLimiteAlert">Seu limite máximo diário foi alterado. Atualize os seus limites.</span>
      </div>
      <div *ngIf="loadingLimit" class="d-flex justify-content-center loading-container spinner-border-sm">
        <div class="spinner-border spinner-border-custom text-primary spinner-border-sm" role="status"></div>
      </div>  
      <div class="flex-item-1 limite-maximo"><span *ngIf="!loadingLimit">{{allowLimit | currency:'BRL'}}</span></div>
    </div>

    <div class="layer_4">
      <fibra-pix-limit-transactional
        [editar]="editCall"
        [saveData]="onSaveData"
        [dataLimits]="dataLimits"
        (flagFormChange)="callBackIfChangeForm($event)"
        (dataFormChange)="getValuesFormOnChange($event)"
        (observeFormStatus)="forStatusObserve($event)"
        (observeFormStatusMin)="forStatusObserveErrorMin($event)"
      ></fibra-pix-limit-transactional>
    </div>

    <div>
      <!-- Valor -->
      <div class="row row-top">
        <div class="col-1" *ngIf="editCall">
          <button class="btnCancelar" (click)="editCancel()">Cancelar</button>
        </div>

        <div class="col-6">
          <button 
          *ngIf="!editCall" 
          class="btnEditarSalvar" 
          [ngClass]="editCall ? 'margin-left' : ''" (click)="editar()">
            Editar
          </button>

          <button 
          *ngIf="editCall"
          class="btnEditarSalvar" 
          [ngClass]="editCall ? 'margin-left' : ''" 
          [disabled]="receivErrorMax || receivErrorMin"
          (click)="saveChangeLimit()"
          >
          Alterar e Salvar
          </button>
          
        </div>
      </div>
      <div style=" margin-bottom: 15px;"><p class="disclaimer">*O limite máximo diário é consumido por todas as transações realizadas no dia.</p></div>
    </div>
  </div>

  <!-- APROVAÇÃO -->
  <div class="keys-content" *ngIf="seletedTypeView === 'aprovacoes'">
    <fibra-limit-approval
    [identificationPage]="'PIX-LIMITE'"
    ></fibra-limit-approval>
  </div>

  <!-- HORARIOS -->
  <div class="keys-content" *ngIf="seletedTypeView === 'horario'">
    <fibra-limit-change-time 
    [changeTime]="editTimeCall"
    [timeNight]="timeNight"
    [timeDay]="timeDay"
    (exitComponent)="readExitComponent($event)"
    (timeNightDayExport)="receiveTimeChange($event)"
    ></fibra-limit-change-time>

    <div class="disclaimerHour">
      <p *ngIf="!editTimeCall">*O horário cadastrado é vigente para todas as transações: TED, Transferência (TEF), Pagamento de Boleto e Transferência Pix.</p>
      <p *ngIf="editTimeCall">*O horário alterado ficará vigente para todas as transações: TED, Transferência (TEF), Pagamento de Boleto e Transferência Pix.</p>
      <p *ngIf="editTimeCall">**Ao solicitar a alteração do horário será necessário aguardar de 24 a 48 horas, após a aprovação de todos os masters, para que seja efetivada.</p>
    </div>

    <div>
      <!-- Buttons -->
      <div class="row row-top-horario">
        <div class="col-1" *ngIf="editTimeCall">
          <button class="btnCancelar" (click)="cancelarTime()">Cancelar</button>
        </div>

        <div class="col-6">
          <button
            class="btn-edit-save-time"
            [ngClass]="!editTimeCall ? '' : 'margin-save'"
            (click)="!editTimeCall ? editTimeLimit() : saveTimeLimit()"
          >
            {{ editTimeCall ? "Alterar e Salvar" : "Editar" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<fibra-toast-error-message
  [color]="typeColorToast"
  (optionYes)="confirmeCancelChange()"
></fibra-toast-error-message>

<fibra-modal-validation
  #content
  (responseValidation)="responseApiModal($event)"
  *ngIf="showModalValidation"
  [apiCallback]="apiCallback"
  [apiPayload]="securityRequest()"
  (closeEvent)="closeModalApi($event)"
  (responseValidation)="securityResponse($event)"
>
</fibra-modal-validation>

<ng-container *ngIf="showModalOtpChangeHourLimit">
    <fibra-modal-validation
    #content
    (responseValidation)="responseApiModal($event)"
    [apiCallback]="apiCallback"
    [apiPayload]="securityRequestToLimitHour()"
    (closeEvent)="closeModalApi($event)"
    (responseValidation)="securityResponseChangeHourLimit($event)"
  >
  </fibra-modal-validation>
</ng-container>
