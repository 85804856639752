import { AfterViewInit, Component, Input } from '@angular/core';
import { IOpenBankingConsent } from '@src/core/domain/openbanking/openbanking.consent.model';
import { timer } from 'rxjs';

@Component({
    selector: 'fibra-openbanking-paymentrequest-redirect',
    templateUrl: './openbanking-paymentrequest-redirect.component.html',
    styleUrls: ['./openbanking-paymentrequest-redirect.component.scss']
})
export class OpenbankingPaymentRequestRedirectComponent implements AfterViewInit {    

    @Input() consent: IOpenBankingConsent;
    @Input() public transactionWasSuccessful: boolean;

    public get title(): string {
        let result = 'Você está sendo redirecionado para a instituição iniciadora de pagamentos.'
        
        if (!this.transactionWasSuccessful) {
            result = `Sua transação não foi realizada. ${result}`;
        } 

        return result;
    }

    ngAfterViewInit(): void {
        var url = decodeURIComponent(this.consent.client_info.client_uri);
        url = url.replace(/u0026/g, '&');

        console.log(url);
        
        timer(2000).subscribe(() => window.location.href = url);
    }
}