import { Component, EventEmitter, Injectable, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors, AbstractControl, FormControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'fibra-transactional-limits',
  templateUrl: './transactional-limits.component.html',
  styleUrls: ['./transactional-limits.component.scss']
})

export class TransactionalLimitsComponent implements OnInit, OnChanges {

  @Input() title?: string;
  @Input() transNocturnal: boolean = true; // Exibir/ocultar limites por transacoes noturnas
  @Input() transacDayTime: boolean = true; // Exibir/ocultar limites por transacoes diurnas
  @Input() dataLimits: Observable<{}> = new Observable<{}>(); // Array com dados dos limites
  @Input() set editar(val: boolean) {
    this.editForm.next(val);
  }
  @Input() saveData: boolean;
  @Input() titleObject?: string;
  @Output() flagFormChange: EventEmitter<boolean> = new EventEmitter();
  @Output() dataFormChange: EventEmitter<any> = new EventEmitter();
  @Output() observeFormStatus: EventEmitter<{}> = new EventEmitter();
  @Output() observeFormStatusMin: EventEmitter<{}> = new EventEmitter();

  limitList: FormGroup;
  editForm: Subject<any>;
  // forValidatorMaxLimit: Subject<any>;
  _controlEditForm: boolean = false;
  limiteData: {};
  maxLimiteAllow: Observable<number> = new Observable<number>();

  constructor(private formBuild: FormBuilder) {
    this.editForm = new Subject();
    // this.forValidatorMaxLimit = new Subject();

    this.limitList = this.formBuild.group({
      tedDiurnoMesma: [Number(''), Validators.required],
      tedDiurnoOutraPJ: [Number(''), Validators.required],
      tedDiurnoOutraPF: [Number(''), Validators.required],
      tedNoturnoMesma: [Number(''), Validators.required],
      tedNoturnoOutraPJ: [Number(''), Validators.required],
      tedNoturnoOutraPF: [Number(''), Validators.required],


      tefDiurnoMesma: [Number(''), Validators.required],
      tefDiurnoOutraPJ: [Number(''), Validators.required],
      tefDiurnoOutraPF: [Number(''), Validators.required],
      tefNoturnoMesma: [Number(''), Validators.required],
      tefNoturnoOutraPJ: [Number(''), Validators.required],
      tefNoturnoOutraPF: [Number(''), Validators.required],

      boletoDiurnoMesma: [Number(''), Validators.required],
      boletoDiurnoOutraPJ: [Number(''), Validators.required],
      boletoDiurnoOutraPF: [Number(''), Validators.required],
      boletoNoturnoMesma: [Number(''), Validators.required],
      boletoNoturnoOutraPJ: [Number(''), Validators.required],
      boletoNoturnoOutraPF: [Number(''), Validators.required]
    });
  }

  ngOnInit() {
    //this.setValueLimit();
    this.verifyIfValueChangedInForm();
    //this.formStatusCheck();

    this.editForm.subscribe(r => {

      //this.limitList.controls['transacaoDiurnaMesma'].setErrors(null)
      if (!r) this.setValueLimit();
      if (r) this.varifydispatchInitialMaxValue(this.limitList.value);
      this._controlEditForm = r;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['dataLimits'] !== 'undefined' && changes['dataLimits'].currentValue) {
      this.dataLimits.subscribe((r) => {

        // Atribui à variavel, os dados de limites vindos do componente pai
        this.limiteData = r;

        // Atribui à variavel, o valor do limite máximo vindo do componente pai
        this.maxLimiteAllow = new Observable<number>((obs) => obs.next(this.limiteData[0].maxLimiteValue));

        this.formStatusCheck();
        //this.forValidatorMaxLimit.next({})
        this.setValueLimit();
      })
    }

    if (typeof changes['saveData'] !== 'undefined' && changes['saveData'].currentValue) {
      this.limitList.setValue(this.limitList.value);
      this._controlEditForm = false;
      this.dataFormChange.emit(this.titleObject ? { title: this.titleObject, ...this.limitList.value } : this.limitList.value);
    }
  }

  verifyIfValueChangedInForm() {
    const formInitialValue = this.limitList.value;
    this.limitList.valueChanges.subscribe(val => {
      let check: boolean = Object.keys(formInitialValue).some(key => formInitialValue[key] !== val[key]);
      this.flagFormChange.emit(check);
    })
  }

  // Seta valores nos inputs
  setValueLimit() {
    let values = {
      tedDiurnoMesma: this.limiteData[0][1].diurnoMesma,
      tedDiurnoOutraPJ: this.limiteData[0][1].diurnoOutraPJ,
      tedDiurnoOutraPF: this.limiteData[0][1].diurnoOutraPF,
      tedNoturnoMesma: this.limiteData[0][1].noturnoMesma,
      tedNoturnoOutraPJ: this.limiteData[0][1].noturnoOutraPJ,
      tedNoturnoOutraPF: this.limiteData[0][1].noturnoOutraPF,


      tefDiurnoMesma: this.limiteData[0][0].diurnoMesma,
      tefDiurnoOutraPJ: this.limiteData[0][0].diurnoOutraPJ,
      tefDiurnoOutraPF: this.limiteData[0][0].diurnoOutraPF,
      tefNoturnoMesma: this.limiteData[0][0].noturnoMesma,
      tefNoturnoOutraPJ: this.limiteData[0][0].noturnoOutraPJ,
      tefNoturnoOutraPF: this.limiteData[0][0].noturnoOutraPF,


      boletoDiurnoMesma: this.limiteData[0][2].diurnoMesma,
      boletoDiurnoOutraPJ: this.limiteData[0][2].diurnoOutraPJ,
      boletoDiurnoOutraPF: this.limiteData[0][2].diurnoOutraPF,
      boletoNoturnoMesma: this.limiteData[0][2].noturnoMesma,
      boletoNoturnoOutraPJ: this.limiteData[0][2].noturnoOutraPJ,
      boletoNoturnoOutraPF: this.limiteData[0][2].noturnoOutraPF,

    }

    console.log(values)

    this.limitList.setValue(values);
  }

  // Dispara um valor inicial ao componente pai do tipo 'max' para controlar o estado do botão 'Alterar e Salvar'
  varifydispatchInitialMaxValue(formValue) {
    this.maxLimiteAllow.subscribe(r => {
      let value = Object.keys(formValue).some(s => formValue[s] > r)
      if (value) this.observeFormStatus.emit({ max: true })
    });
  }

  // Dispara um valor ao componente pai, se houver erro do tipo 'max' em algum input, para controlar o estado do botão 'Alterar e Salvar'
  formStatusCheck() {

    // TED
    this.limitList.get('tedDiurnoMesma').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('tedDiurnoMesma', 'min');
    });

    this.limitList.get('tedDiurnoOutraPJ').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('tedDiurnoOutraPJ', 'min');
    });

    this.limitList.get('tedDiurnoOutraPF').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('tedDiurnoOutraPF', 'min');
    });

    this.limitList.get('tedNoturnoMesma').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('tedNoturnoMesma', 'min');
    });

    this.limitList.get('tedNoturnoOutraPJ').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('tedNoturnoOutraPJ', 'min');
    });

    this.limitList.get('tedNoturnoOutraPF').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('tedNoturnoOutraPF', 'min');
    });

    // TEF
    this.limitList.get('tefDiurnoMesma').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('tefDiurnoMesma', 'min');
    });

    this.limitList.get('tefDiurnoOutraPJ').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('tefDiurnoOutraPJ', 'min');
    });

    this.limitList.get('tefDiurnoOutraPF').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('tefDiurnoOutraPF', 'min');
    });

    this.limitList.get('tefNoturnoMesma').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('tefNoturnoMesma', 'min');
    });

    this.limitList.get('tefNoturnoOutraPJ').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('tefNoturnoOutraPJ', 'min');
    });

    this.limitList.get('tefNoturnoOutraPF').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('tefNoturnoOutraPF', 'min');
    });

    // BOLETO
    this.limitList.get('boletoDiurnoMesma').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('boletoDiurnoMesma', 'min');
    });

    this.limitList.get('boletoDiurnoOutraPJ').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('boletoDiurnoOutraPJ', 'min');
    });

    this.limitList.get('boletoDiurnoOutraPF').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('boletoDiurnoOutraPF', 'min');
    });

    this.limitList.get('boletoNoturnoMesma').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('boletoNoturnoMesma', 'min');
    });

    this.limitList.get('boletoNoturnoOutraPJ').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('boletoNoturnoOutraPJ', 'min');
    });

    this.limitList.get('boletoNoturnoOutraPF').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('boletoNoturnoOutraPF', 'min');
    });
  }

  checkInputsErrorMax(error: string, value: {}) {
    let arr = [];

    Object.keys(this.limitList.controls).forEach(key => {
      arr.push(this.limitList.get(key).hasError(error));
    });

    if (arr.includes(true)) {
      this.observeFormStatus.emit(value);
      return;
    }
    this.observeFormStatus.emit({ max: false });
  }

  checkOnlyInputErrorMin(key: string, error: string) {
    let arr = [];
    let arrAllInputErrorMax = [];
    let arrAllInputErrorMin = [];

    // Verificar todos os Erros MIN
    Object.keys(this.limitList.controls).forEach(key => {
      arrAllInputErrorMin.push(this.limitList.get(key).hasError('min'));
    });

    // Se existe pelo menos um erro MIN em todos os inputs
    let arrAtLeastErrorMin = arrAllInputErrorMin.includes(true);

    if (arrAtLeastErrorMin) {
      console.log('Pelo menos 1 erro minimo');
      this.observeFormStatusMin.emit({ min: true });
      return;
    }

    return this.observeFormStatusMin.emit({ min: false });
  }

  periodVsTransaction(clBk, field: string): void{
    if(clBk < this.limitList.get(field).value){
      this.limitList.get(field).setErrors({max: true})
      this.observeFormStatus.emit({ max: true });
    }

    if(clBk >= this.limitList.get(field).value){
      this.limitList.get(field).setErrors(null)
    }
  }

  transactionVsPeriod(clbk, fieldPeriod, fieldTransaction): void{
    if(clbk > this.limitList.get(fieldPeriod).value){
      this.limitList.get(fieldTransaction).setErrors({max: true})
      this.observeFormStatus.emit({ max: true });
    }

    if(clbk <= this.limitList.get(fieldPeriod).value){
      this.limitList.get(fieldTransaction).setErrors(null)
    }
  }
}
