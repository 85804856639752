<div class="tableDiv">

  <table class="table">
      <thead class="table-header">
        <tr>
          <th scope="col">Seu número</th>
          <th scope="col">Nosso Número</th>
          <th scope="col">
                Data da emissão
          </th>
          <th scope="col">
                Data de envio
          </th>
          <th scope="col">
                Pagador
          </th>
          <th scope="col">
                Valor do título
          </th>
          <th scope="col" *ngIf="instruction">Instruções</th>
          <th scope="col">Status</th>
          <th scope="col">Ver título</th>
        </tr>
      </thead>

    <tbody *ngIf="!hide && !loading" class="p-15">
      <tr *ngFor="let item of transit" [attr.colspan]="1 + 1">

        <td scope="row">{{ item.seu_numero }}</td>
        <td scope="row">{{ item.nosso_numero === '' ? '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' : item.nosso_numero  }}</td>

        <td scope="row">{{ item.data_emissao | date: 'dd/MM/yyyy' }}</td>
        <td scope="row">{{ item.data_envio | date: 'dd/MM/yyyy' }}</td>
        <td class="nowrap">
          <ngb-highlight  data-hj-suppress [result]="item.nome_sacado" [term]="filter.value"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="item.vlr_titulo | currency: 'BRL'" [term]="filter.value"></ngb-highlight>
        </td>
        <td class="util">
          <div [ngClass]="{
              pending: ['ACA', 'ACN', 'ACR', 'AN', 'AR', 'AS', 'CCA', 'CCN', 'CCR', 'CE', 'CH', 'PS'].includes(item.codigo_status_linha),
              authorized:['AC', 'REM'].includes(item.codigo_status_linha),
              refused: ['CPC', 'ECP', 'EL', 'RC', 'RE', 'RET', 'RF'].includes(item.codigo_status_linha)
            }"></div>
          <ngb-highlight [result]="getStatusFormated(item.codigo_status_linha)" [term]="filter.value"></ngb-highlight>
        </td>
        <td>
          <svg (click)="sendItemList(item)" width="20" height="19" viewBox="0 0 20 19" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
              <path
                d="M12.9999 12.3967H14.1987M5.79932 3.99609H10.5993H5.79932ZM5.79932 9.99609H10.5993H5.79932ZM5.79932 6.39549H9.39932H5.79932ZM5.79932 12.3967H9.39932H5.79932ZM12.9999 3.99609H14.1987H12.9999ZM12.9999 9.99609H14.1987H12.9999ZM12.9999 6.39549H14.1987H12.9999Z"
                stroke="#082A4D" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M1 1H19M2.8081 1V17.7928L4.6018 16.597L6.421 17.7964L8.2141 16.597L10.0141 17.7928L11.8075 16.597L13.6069 17.7928L15.4012 16.597L17.2 17.7928V1H2.8081ZM13.6069 17.7964V17.7928V17.7964ZM10.0141 17.7964V17.7928V17.7964Z"
                stroke="#082A4D" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round" />
            </g>
          </svg>
        </td>
      </tr>
    </tbody>

  </table>

  <div class="d-flex justify-content-center position" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div class="empty-info" *ngIf="hide">
    <fibra-generic-info type="warn" title="Nenhum resultado encontrado"
      message="Tente uma nova pesquisa inserindo novos parâmetros.">
    </fibra-generic-info>
  </div>

</div>