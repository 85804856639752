import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { DatePipe } from '@angular/common';
import { LimitChangeTimeComponent } from '../../components/limit-change-time/limit-change-time.component';
import { PixGetBeneficiariesDataRepository } from '@src/data/repository/pix/beneficiaries/pix-get-beneficiaries-repository';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { PixAccountLimitesUseCases } from '@src/core/usecases/pix/pix-limites-account.usecase';
import jwt_decode from "jwt-decode";
import { PostOtpUsecase } from '@src/core/usecases/otp/post-otp.usecase';

@Component({
  selector: 'fibra-pix-limites',
  templateUrl: './pix-limites.component.html',
  styleUrls: ['./pix-limites.component.scss']
})

export class PixLimitesComponent implements OnInit, AfterViewChecked, AfterViewInit {

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  @ViewChild(LimitChangeTimeComponent, null) limitChange: LimitChangeTimeComponent;
  @ViewChild('content', { static: true }) content: HTMLElement;
  @ViewChild('contentReprove', { static: true }) contentReprove: HTMLElement;
  @Input() accountNum: [{}];

  // New implementations
  accounts = [];
  editCall: boolean = false;
  editTimeCall: boolean = false;
  timeNight: number; // = 2000;
  timeDay: number = 1959;
  public seletedTypeView: string = 'contas';
  public showModalValidation: boolean = false;
  formChanged: boolean = false;
  ValueFormOnSave: any;
  onSaveData: boolean = false;
  statusForm: boolean = false;
  allowLimit: number;
  loadingLimit: boolean = false;
  maxLimiteAlert: boolean = false;

  public seletedFilter: string = 'pending';
  public typeColorToast: string;
  public BLUECOLOR = '#6CBED9';
  public REDCOLOR = '#ED6C6C';
  public showTerm = false;
  public isCadastro = false;
  public notif: boolean = false;
  dataLimits: Observable<{}>;
  _unSubscribe: Subscription;
  numberAccountSelected: string;
  apiCallback: number;
  showModalOtpChangeHourLimit: boolean;
  receiveDayTime: number;
  receiveNightTime: number;

  checkErrorMaxPix = [];
  checkErrorMinPix = [];

  receivErrorMax: boolean;
  receivErrorMin: boolean;

  temporaryDataLimit = [];

  formAllErrorsMax: BehaviorSubject<{}>;
  formAllErrorsMin: BehaviorSubject<{}>;
  valuesToPayload: BehaviorSubject<{}>;


  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    public getBeneficiariesService: PixGetBeneficiariesDataRepository,
    private modalService: NgbModal,
    private router: ActivatedRoute,
    private getAccountLimitPIX: PixAccountLimitesUseCases,
  ) {

  }

  ngOnInit() {
    this.formatArrayAccounts(this.router.snapshot.data['resolve'].data);
    //this.dataLimits = this.mountArray(this.newArrayMock.data[0]);

    //Controla a exibição dos erros de formulário
    this.controlShowErros();
    this.mountPayload();
  }

  ngAfterViewInit() {
    if (this.verifyLocalStorage()) {
      this.seletedTypeView = 'horario';
      this.removeLocalStorage();
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  mountArray(arrReq) {

    const maxLimit = this.allowLimit;
    const diurno = this.conditionFilter(arrReq.valores, 'DIURNO', 'MESMA_TITULARIDADE', 'OUTRA_TITULARIDADE_PF', 'OUTRA_TITULARIDADE_PJ');
    const noturno = this.conditionFilter(arrReq.valores, 'NOTURNO', 'MESMA_TITULARIDADE', 'OUTRA_TITULARIDADE_PF', 'OUTRA_TITULARIDADE_PJ');

    let data = [{
      maxLimiteValue: maxLimit,
      title: 'PIX',
      diurnoMesma: 0,
      diurnoOutraPJ: 0,
      diurnoOutraPF: 0,
      noturnoMesma: 0,
      noturnoOutraPJ: 0,
      noturnoOutraPF: 0,
      transacaoDiurnaMesma: 0,
      transacaoDiurnaOutraPJ: 0,
      transacaoDiurnaOutraPF: 0,
      transacaoNoturnaMesma: 0,
      transacaoNoturnaOutraPJ: 0,
      transacaoNoturnaOutraPF: 0
    }];

    data.forEach((el, i) => {
      el.diurnoMesma = diurno[0].valorMaximoTotal;
      el.diurnoOutraPJ = diurno[1].valorMaximoTotal;
      el.diurnoOutraPF = diurno[2].valorMaximoTotal;

      el.noturnoMesma = noturno[0].valorMaximoTotal;
      el.noturnoOutraPJ = noturno[1].valorMaximoTotal;
      el.noturnoOutraPF = noturno[2].valorMaximoTotal;

      el.transacaoDiurnaMesma = diurno[0].valorMaximoTransacao;
      el.transacaoDiurnaOutraPJ = diurno[1].valorMaximoTransacao;
      el.transacaoDiurnaOutraPF = diurno[2].valorMaximoTransacao;

      el.transacaoNoturnaMesma = noturno[0].valorMaximoTransacao;
      el.transacaoNoturnaOutraPJ = noturno[1].valorMaximoTransacao;
      el.transacaoNoturnaOutraPF = noturno[2].valorMaximoTransacao;
    });

    return data;
  }

  conditionFilter(data: any, perido: string, titularidade1: string, titularidade2: string, titularidade3: string) {
    return data.filter(a => a.tipoPeriodo === perido && a.tipoTransacao === 'PIX' && (a.tipoTitularidade === titularidade1 || a.tipoTitularidade === titularidade2 || a.tipoTitularidade === titularidade3));
  }

  verifyLocalStorage(): boolean {
    return (localStorage.getItem('redirectPixLimitsTab') !== null && localStorage.getItem('redirectPixLimitsTab') === 'redirectPixLimitsHorario');
  }

  removeLocalStorage(): void {
    localStorage.removeItem('redirectPixLimits');
    localStorage.removeItem('redirectPixLimitsTab');
  }

  public closeModal(_$event): void {
    this.showModalValidation = false;
    this.modalService.dismissAll();
  }

  public closeModalApi(_$event): void {
    this.showModalValidation = false;
    this.showModalOtpChangeHourLimit = false;
    this.modalService.dismissAll();
  }

  public titleCaseWord(word: string) {
    var splitStr = word.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  public confirmeCancelChange() {
    if(this.editTimeCall) this.editTimeCall = false;
    this.editCall = false;

  }

  handleFilter(type: any) {
    this.seletedFilter = type;
  }

  public securityResponse = value => {
    this.requestSeccess(value);
    this.requestError(value);
  }

  private requestError = (err) => {

    if(err.error && err.error.code === 500){
      this.typeColorToast = '#ED6C6C';
      this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar alterar o limite.', ' Tente novamente.', false, true);
      return;
    }

    if(err.error && err.error.code === 404 && err.error.status === 'error'){
      this.typeColorToast = '#ED6C6C';
      this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar alterar o limite.', ' Tente novamente.', false, true);
      return;
    }
  }

  private requestSeccess = (sc) => {
    if(sc.code === 200 && sc.data.indexOf('solicitada') !== -1){
      this.typeColorToast = '#73B599';
      this.toast.callModalMessage(null, sc.data, null, false, true);     
      this.editTimeCall = false;
      return;   
    }

    if(sc.code === 200 && sc.data.indexOf('pendente') !== -1){
      this.typeColorToast = '#D69D10';
      this.toast.callModalMessage(null, sc.data, null, false, true);     
      this.editTimeCall = false;
      return;   
    }
  }

  public securityResponseChangeHourLimit = value => {
    this.requestSeccessChangeHourLimit(value);
    this.requestErrorChangeHourLimit(value);
  }

  private requestErrorChangeHourLimit = (err) => {

    if(err.error && err.error.code === 500){
      this.typeColorToast = '#ED6C6C';
      this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar alterar o limite.', ' Tente novamente.', false, true);
      return;
    }

    if(err.error && err.error.code === 404 && err.error.status === 'error'){
      this.typeColorToast = '#ED6C6C';
      this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar alterar o limite.', ' Tente novamente.', false, true);
      return;
    }
  }

  private requestSeccessChangeHourLimit = (sc) => {
    if(sc.code === 200 && sc.data.indexOf('solicitada') !== -1){
      this.typeColorToast = '#73B599';
      this.toast.callModalMessage(null, sc.data, null, false, true);     
      this.editTimeCall = false;
      return;   
    }

    if(sc.code === 200 && sc.data.indexOf('pendente') !== -1){
      this.typeColorToast = '#D69D10';
      this.toast.callModalMessage(null, sc.data, null, false, true);     
      this.editTimeCall = false;
      return;   
    }
  }

  public securityRequest = () => {
    let token = sessionStorage.getItem('accessToken');
    const decoded: any = jwt_decode(token);
    const tipoAcesso = localStorage.getItem('acessoMaster') === 'true' ? 1 : 2;

    return {
      nrConta: this.numberAccountSelected,
      codEmpresa: 0,
      emailOperador: decoded.email,
      empresaId: 0,
      nomeSolicitante: decoded.name,
      pix: true,
      limiteDiario: this.allowLimit,
      valores: [
        {
          idTipoTransacao: 1,
          tipoTransacao: "PIX",
          idTipoTitularidade: 1,
          tipoTitularidade: "MESMA_TITULARIDADE",
          idTipoPeriodo: 1,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.ValueFormOnSave.diurnoMesma,
          valorMaximoTransacao: this.ValueFormOnSave.transacaoDiurnaMesma,
        },
        {
          idTipoTransacao: 1,
          tipoTransacao: "PIX",
          idTipoTitularidade: 2,
          tipoTitularidade: "OUTRA_TITULARIDADE_PJ",
          idTipoPeriodo: 1,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.ValueFormOnSave.diurnoOutraPJ,
          valorMaximoTransacao: this.ValueFormOnSave.transacaoDiurnaOutraPJ,
        },
        {
          idTipoTransacao: 1,
          tipoTransacao: "PIX",
          idTipoTitularidade: 3,
          tipoTitularidade: "OUTRA_TITULARIDADE_PF",
          idTipoPeriodo: 1,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.ValueFormOnSave.diurnoOutraPF,
          valorMaximoTransacao: this.ValueFormOnSave.transacaoDiurnaOutraPF,
        },
        {
          idTipoTransacao: 1,
          tipoTransacao: "PIX",
          idTipoTitularidade: 1,
          tipoTitularidade: "MESMA_TITULARIDADE",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.ValueFormOnSave.noturnoMesma,
          valorMaximoTransacao: this.ValueFormOnSave.transacaoNoturnaMesma,
        },
        {
          idTipoTransacao: 1,
          tipoTransacao: "PIX",
          idTipoTitularidade: 2,
          tipoTitularidade: "OUTRA_TITULARIDADE_PJ",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.ValueFormOnSave.noturnoOutraPJ,
          valorMaximoTransacao: this.ValueFormOnSave.transacaoNoturnaOutraPJ,
        },
        {
          idTipoTransacao: 1,
          tipoTransacao: "PIX",
          idTipoTitularidade: 3,
          tipoTitularidade: "OUTRA_TITULARIDADE_PF",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.ValueFormOnSave.noturnoOutraPF,
          valorMaximoTransacao: this.ValueFormOnSave.transacaoNoturnaOutraPF,
        }
      ],
      aprovadores: [
        {
          idPessoa: 0,
          nomeAprovador: decoded.name,
          idTipoAcesso: tipoAcesso,
        }
      ]
    }
  }

  public securityRequestToLimitHour = () => {
    let token = sessionStorage.getItem('accessToken');
    const decoded: any = jwt_decode(token);
    const tipoAcesso = localStorage.getItem('acessoMaster') === 'true' ? 1 : 2;

    return {
      nrConta: this.numberAccountSelected,
      horarioDiurnoInicio: "06:01:00",
      horarioDiurnoFim: this.receiveDayTime+":00",
      horarioNoturnoInicio: this.receiveNightTime+":00",
      horarioNoturnoFim: "06:00:59",
    };
  }

  goBack(): void {
    // Content code
  }

  responseApiModal(_$event): void {
    this.showModalValidation = false;
  }

  backRefused(): void {
    // Content code
  }

  formatArrayAccounts(acc): void {
    acc.forEach(cnt => {
      this.accounts.push({
        indice: cnt.conta.num_indice,
        name: `${cnt.conta.numero} - ${this.titleCaseWord(cnt.conta.desc_conta)}`,
        value: cnt.conta.numero,
        modality_account: cnt.conta.exibicao,
        selected: false
      })
    })
  }

  accountSelected(evt): void {
    this.numberAccountSelected = evt;
    this.loadingLimit = true;

    if (this._unSubscribe) {
      this._unSubscribe.unsubscribe();
    }

    // REQUEST LIMITES PIX    
    this._unSubscribe = this.getAccountLimitPIX.execute(this.numberAccountSelected).subscribe(rs => {

      console.log(rs)
    
      const limitReady: any = rs.data.find((e: any) => e.nrConta === evt && e.tipoAlteracao === 'LIMITE' && e.pix);
      const hourLimit: any = rs.data.find((e: any) => e.nrConta === evt && e.tipoAlteracao === 'HORARIO');

      /* if(typeof limitReady === 'undefined'){
        console.warn('ESSE USUÁRIO NÃO TEM LIMITE DEFAULT! ⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️' ); 
        return;
      } 

      if(typeof hourLimit === 'undefined'){
        console.warn('ESSE USUÁRIO NÃO TEM HORARIO DEFAULT! ⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️' ); 
        return;
      }  */

      if(hourLimit.horarioNoturnoInicio === '20:00:00') this.timeNight = 2000;
      if(hourLimit.horarioNoturnoInicio === '22:00:00') this.timeNight = 2200;
      
      this.loadingLimit = false;

      this.allowLimit = limitReady.limiteDiario;

      this.dataLimits = new Observable<{}>((obs) => {
        obs.next(this.mountArray(limitReady))
      })

    });
  }

  async getAllowLimit() {
    return this.allowLimit
  }

  editar(): void {
    this.editCall = true;
  }

  cancelEdit(): void {
    this.editCall = false;
  }

  editCancel(): void {
    this.typeColorToast = '#ED6C6C';
    this.toast.callModalMessage(null, 'Ao cancelar você perderá tudo que foi preenchido. ', 'Deseja mesmo cancelar?', true, false);
  }

  saveTimeLimit(): void {
    this.apiCallback = 129;
    this.showModalOtpChangeHourLimit = true;
  }

  editTimeLimit(): void {
    this.editTimeCall = true;
  }

  cancelarTime(): void {
    this.typeColorToast = '#ED6C6C';
    this.toast.callModalMessage(null, 'Ao cancelar você perderá tudo que foi preenchido. ', 'Deseja mesmo cancelar?', true, false);
  }

  selectedTab(tab: string): void {
    this.seletedTypeView = tab;
    this.toast.closeModal();
  }

  callBackIfChangeForm(evt: boolean): void {
    this.formChanged = evt
  }

  getValuesFormOnChange(evt): void {
    let addKeyLimitMax = Object.assign(evt, { maxLimiteValue: this.allowLimit });
    this.ValueFormOnSave = addKeyLimitMax;

    this.apiCallback = 125;
    this.securityRequest();
    this.showModalValidation = true;

    this.temporaryDataLimit.push(addKeyLimitMax);

    this.valuesToPayload.next(this.temporaryDataLimit);

    this.dataLimits = new Observable<{}>((obs) => {
      obs.next([addKeyLimitMax])
    })

    setTimeout(() => {
      this.editCall = false;
      this.onSaveData = false;
      this.maxLimiteAlert = false;
    }, 1000);
  }

  saveChangeLimit(): void {
    this.onSaveData = true;
  }

  forStatusObserve(evt): void {
    let max = evt.max;
    if (max) this.statusForm = true;
    if (!max) this.statusForm = false;

    let err = [evt];

    err.forEach((key) => {
      if (key.max) this.checkErrorMaxPix.push(key.max);
      if (!key.max) this.checkErrorMaxPix = [false];
    })

    this.formAllErrorsMax.next([...this.checkErrorMaxPix]);
  }

  forStatusObserveErrorMin(evt): void {
    let err = [evt];

    err.forEach((key) => {
      if (key.min) this.checkErrorMinPix.push(key.min);
      if (!key.min) this.checkErrorMinPix = [false];
    })

    this.formAllErrorsMin.next([...this.checkErrorMinPix]);
  }

  readExitComponent(evt: boolean): void {
    this.editTimeCall = evt;
  }

  getValueErrorMax() {
    return this.formAllErrorsMax.asObservable();
  }

  getValueErrorMin() {
    return this.formAllErrorsMin.asObservable();
  }

  controlShowErros() {
    this.formAllErrorsMax = new BehaviorSubject<[]>([]);
    this.formAllErrorsMin = new BehaviorSubject<[]>([]);

    // Observa os erros do tipo MAX 
    this.getValueErrorMax().subscribe((rs: [{}]) => {
      this.receivErrorMax = rs.includes(true) ? true : false;
    });

    // Observa os erros do tipo Min 
    this.getValueErrorMin().subscribe((rs: [{}]) => {
      this.receivErrorMin = rs.includes(true) ? true : false;

      if (this.receivErrorMin) this.toast.callModalMessage(null, 'Valor inválido. Informe um valor maior que zero.', null, null, true);
      if (!this.receivErrorMin) this.toast.closeModal();
    });
  }

  mountPayload() {
    this.valuesToPayload = new BehaviorSubject<[]>([]);
    this.getValueToPayload().subscribe(r => {
      if (r) {
        console.log('Chamar modal para salvar dados')
      }
    })
  }

  getValueToPayload() {
    return this.valuesToPayload.asObservable();
  }

  receiveTimeChange(evt){
    this.receiveDayTime = evt.tDay;
    this.receiveNightTime = evt.tNight;
  }
}
