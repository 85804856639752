import { Component, OnInit } from '@angular/core';

// model
import { StepperModel } from '@src/core/domain/business-models';
import { SelectorDataShare } from '@src/data/repository/data-share-repository';
@Component({
  selector: 'fibra-page-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.scss'],
})
export class ChargeComponent implements OnInit {
  public step: StepperModel[] = [];
  constructor(private shareSelector: SelectorDataShare) {
    this.step = [
      {
        step: 1,
        name: 'Dados do pagador',
      },
      {
        step: 2,
        name: 'Dados do Título',
      },
    ];
  }

  ngOnInit() {
    this.shareSelector.setValue('charge');
  }
}
