<div id="transferInfo" class="box-content" [formGroup]="transferData">
  <div class="content-same">
    <ng-container *ngIf="typeTransfer === 'favorite'">
      <div class="titulo">
        <div class="div-redondo-1"><span>1</span></div>
        <span class="titulo-text">Dados do beneficiário </span>
      </div>

      <div class="divisor-2"></div>

      <div class="info-beneficiaries">
        <div class="info-box">
          <div class="info-person">
            <div class="initial-letters" [id]="'background'">
              {{ initialLetters(beneficiaryData?.beneficiary.nome_beneficiario) }}
            </div>
            <div class="text">
              <label data-hj-suppress>{{
                beneficiaryData?.beneficiary.nome_beneficiario | titlecase
                }}</label>
              <span>CNPJ:
                {{
                removeMask(beneficiaryData?.beneficiary.num_cpf_cnpj)
                | cpfcnpj
                }}</span>
            </div>
          </div>
          <div class="divisor-3"></div>
          <div class="info-bank">
            <div class="initial-letters" [id]="'background'" *ngIf="beneficiaryData?.quote.cod_banco !== 224">
              {{ initialLetters(beneficiaryData?.quote.nome_banco) }}
            </div>
            <div class="initial-letters" *ngIf="beneficiaryData?.quote.cod_banco === 224">
              <img class="fibra-owner" src="assets/png/icon-owner-fibra.png" alt="Logotipo Banco Fibra" />
            </div>
            <div class="text">
              <label>
                {{ beneficiaryData?.quote.cod_banco }}
                {{ beneficiaryData?.quote.nome_banco }}
              </label>
              <span>AG: {{ beneficiaryData?.quote.num_agencia }} | CC:
                {{ beneficiaryData?.quote.num_conta | account }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="
        (typeTransfer === 'same' && bank?.cod_banco !== 224) ||
        (typeTransfer === 'new' && bank?.cod_banco !== 224)
      ">
      <div class="trocar_banco">
        <div class="bank-info">
          <div class="initial-letters" [id]="'background'" *ngIf="bank && bank?.cod_banco !== 224">
            {{ initialLetters(bank?.nome_banco) }}
          </div>
          <div class="initial-letters" *ngIf="bank && bank?.cod_banco === 224">
            <img class="fibra-owner" src="assets/png/icon-owner-fibra.png" alt="Logotipo Banco Fibra" />
          </div>
          <span class="nome-banco">
            {{ bank?.cod_banco }} {{ bank?.nome_banco }}</span>
        </div>

        <button routerLink="/bank" class="btn-troca">
          <img src="/assets/svg/transf.svg" alt="Ion" />
          Trocar instituição
        </button>
      </div>

      <div class="dados_banco">
        <div class="dados_banco-agencia">
          <label>Agência</label>
          <input type="text" class="form-control" placeholder="0000" (keyup)="changeAgency($event.target.value)"
            onkeyup="this.value=this.value.replace(/[^\d]/,'')" maxlength="4" />
        </div>

        <div class="dados_banco-radio">
          <fibra-radiobutton [label]="'Tipo de conta'" [option]="[
              { option: 'Corrente', value: 'CC' },
              { option: 'Poupança', value: 'PP' }
            ]" (selected)="changeTypeAccount($event, 'tipo_conta')"></fibra-radiobutton>
        </div>

        <div class="dados_numero-banco">
          <label style="margin-bottom: 8px">Número da conta com o dígito sem hífen
          </label>
          <input type="text" class="form-control" placeholder="0000000" (keyup)="changeAccount($event.target.value)"
            onkeyup="this.value=this.value.replace(/[^\d]/,'')" maxlength="10" />
        </div>
      </div>

      <div class="divisor-2"></div>

      <div class="dado-1" *ngIf="typeTransfer === 'same' && bank?.cod_banco !== 224">
        <div class="titulo">
          <div class="div-redondo-1"><span>1</span></div>
          <span class="titulo-text">Dados do beneficiário </span>
        </div>

        <div class="outra_titularidade">
          <div class="titularidade" [class.active]="ownership === 'same'" (click)="changeOwnership('same')"
            *fibraHasRole="'TRANSF.TRANSF_MSM_TIT_OTR_BC'">
            <span class="text_span-titularidade">Mesma titularidade</span>
          </div>
          <div class="titularidade" [class.active]="ownership === 'other'" (click)="changeOwnership('other')"
            *fibraHasRole="'TRANSF.TRANSF_OTR_TIT_BC'">
            <span class="text_span-titularidade">Outra titularidade</span>
          </div>
        </div>

        <div *ngIf="ownership === 'same'">
          <div class="dados_beneficiario">
            <div class="dados_beneficiario-nome">
              <label>Nome do Beneficiário</label>
              <span class="data-hj-suppress">{{ companyData?.name || ' ' }}</span>
            </div>

            <div class="dados_beneficiario-nome">
              <label class="">CNPJ/CPF</label>
              <span class="">{{
                removeMask(companyData?.modality_account) | cpfcnpj
                }}</span>
            </div>
          </div>
        </div>

        <div class="other" *ngIf="ownership === 'other'">
          <div class="dados_banco-beneficiario">
            <label>Nome do beneficiário</label>
            <input type="text" class="form-control" placeholder="Nome completo/Razão social" maxlength="50"
              [ngModel]="beneficiaryName" [ngModelOptions]="{ standalone: true }"
              (keyup)="changeBeneficiaryName($event.target.value)" />
          </div>
          <div class="dados_banco-cpf">
            <label>CNPJ/CPF</label>
            <input type="text" class="form-control" id="cpfCnpj" placeholder="000.000.000/0001-00"
              [ngModel]="beneficiaryCpfCnpj" [ngModelOptions]="{ standalone: true }"
              (keyup)="changeCpfCnpj($event.target.value)" [textMask]="{
                mask: cpfcnpjMask,
                placeholderChar: '_',
                guide: false,
                modelClean: true
              }" />
          </div>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" (click)="setFavorite()" />
          <label class="form-check-label" for="exampleCheck1">Salvar nos favoritos</label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="typeTransfer === 'new' && bank && bank?.cod_banco !== 224">
      <div class="dado-1">
        <div class="titulo aux">
          <div class="div-redondo-1"><span>1</span></div>
          <span class="titulo-text">Dados do beneficiário </span>
        </div>

        <div class="divisor-2" *ngIf="typeTransfer !== 'new'"></div>

        <div class="dados_beneficiario">
          <div class="dados_beneficiario-nome">
            <label>Nome do Beneficiário</label>
            <span class="data-hj-suppress">{{
              beneficiaryDataName || ' '
              }}</span>
            <span class="data-hj-suppress">{{beneficiaryName}}</span>
          </div>

          <div class="dados_beneficiario-nome">
            <label class="">CNPJ/CPF</label>
            <span class="">{{
              removeMask(beneficiaryDataCpfCnpj) | cpfcnpj
              }}</span>
            <span>{{beneficiaryCpfCnpj}}</span>
          </div>
        </div>

        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" (click)="setFavorite()" />
          <label class="form-check-label" for="exampleCheck1">Salvar nos favoritos</label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="typeTransfer !== 'favorite' && bank && bank.cod_banco === 224">
      <div class="trocar_banco_noinfo">
        <div class="initial-letters" [id]="'background'" *ngIf="bank && bank?.cod_banco !== 224">
          {{ initialLetters(bank?.nome_banco) }}
        </div>
        <div class="initial-letters" *ngIf="bank && bank?.cod_banco === 224">
          <img class="fibra-owner" src="assets/png/icon-owner-fibra.png" alt="Logotipo Banco Fibra" />
        </div>
        <span class="nome-banco">
          {{ bank?.cod_banco }} {{ bank?.nome_banco }}</span>
        <button routerLink="/bank" class="btn-troca">
          <img src="/assets/svg/transf.svg" alt="Ion" />
          Trocar instituição
        </button>
      </div>

      <div class="divisor-2" *ngIf="typeTransfer !== 'new'"></div>

      <div class="dado-1">
        <div class="titulo">
          <div class="div-redondo-1"><span>1</span></div>
          <span class="titulo-text">Dados do beneficiário </span>
        </div>
        <!-- <div class="form-check mb-5">
          <input
            type="checkbox"
            class="form-check-input"
            id="exampleCheck1"
            (click)="setFavorite()"
          />
          <label class="form-check-label" for="exampleCheck1"
            >Salvar nos favoritos</label
          >
        </div> -->

        <div class="outra_titularidade">
          <div class="titularidade" [class.active]="ownership === 'same'" (click)="changeOwnership('same')"
            *fibraHasRole="'TRANSF.TRANSF_MSM_TITU'">
            <span class="text_span-titularidade">Mesma titularidade</span>
          </div>
          <div class="titularidade" [class.active]="ownership === 'other'" (click)="changeOwnership('other')"
            *fibraHasRole="'TRANSF.TRANSF_OTR_TITU'">
            <span class="text_span-titularidade">Outra titularidade</span>
          </div>
        </div>

        <div *ngIf="ownership === 'same'">
          <div class="selecionar_conta">
            <div class="dados_beneficiario">
              <div class="dados-mesmo-banco">
                <div class="auxiliar">
                  <div class="dados_beneficiario-nome data-hj-suppress">
                    <label>Nome do Beneficiário</label>
                    <span>{{ companyData?.name || ' ' }}</span>
                  </div>

                  <div class="dados_beneficiario-nome">
                    <label class="">CNPJ/CPF</label>
                    <span class="">{{
                      removeMask(companyData?.modality_account) | cpfcnpj
                      }}</span>
                  </div>
                </div>
                <fibra-slide-account-selector [label]="'Selecione a conta do Beneficiário'" [data]="originAccount(accountData)"
                  (emitAccount)="setAccountOrigin($event)" (emitIndex)="setBlockAccountFibra($event)"
                  [bank]="this.beneficiaryData.quote.cod_banco" [ownership]="this.ownership" [isBeneficiary]="true"
                  [debitAccount]="debitAccount" [isEqualCnpj]="isEqualCnpj">
                </fibra-slide-account-selector>
              </div>
            </div>
          </div>
        </div>

        <div class="other" *ngIf="ownership === 'other'">
          <div class="dados_banco-conta">
            <label>Número da conta com o dígito</label>
            <input type="text" class="form-control" placeholder="0000000000" [ngModel]="beneficiaryAccount"
              [ngModelOptions]="{ standalone: true }" (keyup)="getAccount($event.target.value)" maxlength="10"
              [ngClass]="{
                'account-input': !errorAccoutInput,
                'account-input-warning': errorAccoutInput
              }" />
          </div>
          <div class="dados_banco-cpf">
            <label>Nome do beneficiário</label>
            <div class="loading" *ngIf="loading">
              <!-- Loading -->
              <div class="d-flex justify-content-center position loading-container">
                <div class="spinner-border text-primary" role="status"></div>
              </div>
              <span>Buscando beneficiário...</span>
            </div>

            <span *ngIf="accountFibra" data-hj-suppress>{{
              (accountFibra?.nome_beneficiario | titlecase) || ''
              }}</span>
          </div>
          <div class="dados_banco-cpf">
            <label>CNPJ/CPF</label>
            <div class="loading" *ngIf="loading">
              <!-- Loading -->
              <div class="d-flex justify-content-center position loading-container">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <span>Buscando CNPJ/CPF...</span>
            </div>
            <span *ngIf="accountFibra">{{
              removeMask(accountFibra?.num_cpfcnpj) || '' | cpfcnpj
              }}</span>
          </div>
        </div>
      </div>
      <div class="box-erro">
        <img *ngIf="errorAccoutInput" src="/assets/png/iconErro.png" />
        <label *ngIf="errorAccoutInput">Conta inválida.</label>
      </div>
      <div class="form-check mb-5">
        <input type="checkbox" class="form-check-input" id="exampleCheck1" (click)="setFavorite()" />
        <label class="form-check-label" for="exampleCheck1">Salvar nos favoritos</label>
      </div>
    </ng-container>

    <div class="divisor-2"></div>

    <div class="dado-1">
      <div class="titulo">
        <div class="div-redondo-1"><span>2</span></div>
        <span class="titulo-text">Valor e data</span>
      </div>

      <div class="valor_data">
        <fibra-form-control label="Valor a ser transferido">
          <div class="control-data valor_data-valores">
            <input class="form-control" formControlName="ammount" currencyMask
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="transferValue"
              (keyup)="changeAmmount()" [ngClass]="{ 'valor-zero': !transferValue }" maxlength="19" />
          </div>
        </fibra-form-control>

        <fibra-form-control label="Data de transferência">
          <div class="showToolTip">
            <div class="tooltipView" *ngIf="showDateTooltip">
              <div class="tooltipContent">
                <div class="tooltipTexts">
                  <span class="tooltipTitle">A transferência será realizada no próximo dia útil.</span>
                </div>
                <button class="btn-close" (click)="onClose()">
                  <img src="../../../../assets/img/close.svg" alt="" />
                </button>
              </div>
              <div class="arrowView">
                <div class="arrowDown"></div>
              </div>
            </div>
          </div>

          <fibra-datepicker placement="top" (dateSelected)="changeDate($event)" [date]="date" [minDate]="minDate">
          </fibra-datepicker>
        </fibra-form-control>
        <div class="warning-div-sched" *ngIf="errDates">
          &nbsp;&nbsp;<img class="info-img" src="/assets/svg/icon-info-red.svg" alt="icon info red">
          <span class="warning-font"> {{errDateType}}  </span>
      </div>
      </div>
    </div>

    <div class="divisor-2"></div>

    <div class="dado-1">
      <div class="titulo">
        <div class="div-redondo-1"><span>3 </span></div>
        <span class="titulo-text">Finalidade e descrição</span>
      </div>

      <div class="finalidade_descricao">
        <fibra-form-control label="Finalidade">
          <fibra-purposes-list (selectedPurpose)="changePurposes($event)"></fibra-purposes-list>
        </fibra-form-control>

        <div class="finalidade_descricao-descricao">
          <div class="infos">
            <label>Descrição</label>
            <span class="counter" [ngClass]="{ redCounter: char == 0 }">{{ char }} caracteres</span>
          </div>
          <textarea placeholder="" formControlName="description" [ngClass]="" [(ngModel)]="trasnferDescription" (keyup)="
              changeDescription($event.target.value);
              limitTextarea($event.target.value)
            " [maxLength]="100"></textarea>
        </div>
      </div>
    </div>

    <div class="divisor-2" *ngIf="bank && bank?.cod_banco !== 224"
      [hidden]="this.beneficiaryData?.quote.cod_banco === 224"></div>

    <div class="dado-1">
      <div class="titulo" *ngIf="bank && bank?.cod_banco !== 224"
        [hidden]="this.beneficiaryData?.quote.cod_banco === 224">
        <div class="div-redondo-1"><span>4</span></div>
        <span class="titulo-text">Esta transferência é uma...</span>
      </div>

      <fibra-radiobutton *ngIf="bank && bank?.cod_banco !== 224" [hidden]="this.beneficiaryData?.quote.cod_banco === 224"
        [option]="[
          { option: 'TED', value: 'TED' }
        ]" (selected)="changeTypeAccount($event, 'tipo_transfer')"></fibra-radiobutton>

      <!--<fibra-radiobutton
        *ngIf="bank && bank?.cod_banco !== 224"
        [hidden]="this.beneficiaryData.quote.cod_banco === 224"
        [option]="[
          { option: 'TED', value: 'TED' },
          { option: 'DOC', value: 'DOC' }
        ]"
        (selected)="changeTypeAccount($event, 'tipo_transfer')"
      ></fibra-radiobutton>-->
    </div>

    <div class="divisor-2" *ngIf="
        (bank && bank?.cod_banco !== 224) ||
        typeTransfer === 'favorite' ||
        typeTransfer === 'same'
      "></div>

    <div class="dado-1">
      <div class="titulo">
        <div class="div-redondo-1" *ngIf="this.beneficiaryData?.quote.cod_banco === 224">
          <span>4</span>
        </div>
        <div class="div-redondo-1" *ngIf="(this.beneficiaryData.quote.cod_banco !== 224)">
          <span>5</span>
        </div>
        <span class="titulo-text">Conta para débito</span>
      </div>

      <div class="valor_transf">
        <label>Valor a ser transferido</label>
        <span>{{ transferValue | currency: 'BRL' }}</span>
      </div>
      <fibra-slide-account-selector [label]="'Selecione a conta a ser debitada'" [data]="originAccount(accountData)"
        (emitAccount)="setAccountDebit($event)" [desable]="indexAccount" [bank]="this.beneficiaryData.quote.cod_banco"
        [ownership]="this.ownership" [destiny]="beneficiaryData?.quote.num_conta | account"
        [accountOrigin]="accountOrigin" [isBeneficiary]="false" [debitAccount]="debitAccount"
        [isEqualCnpj]="isEqualCnpj"></fibra-slide-account-selector>
    </div>
    <div class="text_btn">
      <div class="loading" *ngIf="!disclamer">
        <!-- Loading -->
        <div class="d-flex justify-content-center position loading-container">
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
      <span *ngIf="disclamer">
        <!-- Horário e limites:<br /> -->
        <!-- <p *ngFor="let message of disclamerMessages">
          {{ message.label }}
        </p> -->

        {{personType | async}}

          <p>Horário de liquidação</p>
          <p>TEF: 08:30h às 17:30h</p>
          <p>Limites</p>
          <p>Outra titularidade PJ das 06:01h às 19:59h: até R$ 000</p>
          <p>Outra titularidade PJ das 20:00h às 06:00h: até R$ 000</p>
          <p>Outra titularidade PF das 06:01h às 19:59h: até R$ 000</p>
          <p>Outra titularidade PF das 20:00h às 06:00h: até R$ 000</p>


        <br />
      </span>
      <button type="button" class="btn btn-primary btn-continuar" (click)="goNext()" [disabled]="validationInfo()">
        Continuar
      </button>
    </div>
  </div>
</div>