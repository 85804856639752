<fibra-toast-error-message [color]="typToastColor"> </fibra-toast-error-message>
<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>
<div class="content-page" id="content-body body-content" id="body-scroll" *fibraHasRole="'COMP.CONS_COMP'">
  <fibra-breadcrumb infoAccount="Transações" roule="Comprovantes" arrow="false" [downloadDoc]="true" (infoReport)="exportScreen($event)"></fibra-breadcrumb>
  <div class="body-content">

    <div class="filter-list">
      <div class="tabs-list">
        <fibra-tabs-list [options]="options" (itemTab)="tabsSelect($event.idOption)"></fibra-tabs-list>
      </div>
      <fibra-filter funcionalidade="COMP" [receipt]="true" (emitFilter)="setFilter($event)" (emitFilterToast)="verifyCalendar($event)" [isSchedules]="isSchedules"></fibra-filter>
    </div>

    <div class="search-beneficiary input-group mb-3">
        <img
        style="position: relative;
        left: 30px;"
            src="assets/svg/icon_search.svg"
            width="18"
          />
        <input
        style="padding-left: 45px; margin-left: 10px;"
          type="text"
          placeholder="Você pode pesquisar por nome do beneficiário, CPF/CNPJ, nome do banco, n° de compromisso ou ref. lote"
          (keyup)="changeValue($event.target.value)"
        />

        <div class="input-group-append" style="width: 20%;">
          <button
          type="button"
          [disabled]="!searchButtonEnable"
          class="secondary-button button"
          (click)="searchStringCommand()"
        >
          Pesquisar
        </button>
        </div>
        <span style="
          color: #acb5bd !important;
          font-size: 12px;
          margin-top: 5px;
      ">Informar no mínimo 5 caracteres</span>
        
      </div>

    <div class="wraper-list-value">
      <header class="wraper-list-header">
        <div class="items">
            <div class="check-all">
              <input *ngIf="showCheckbox" type="checkbox" class="checkbox" (change)="checked('all')"
                [checked]="shownAll === true" />
            </div>
            <div class="col-transaction">
              <span>Tipo de transação</span>
              <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('tipo_transacao', data)" />
            </div>
            <div class="col-beneficiario">
              <span>Nome do Beneficiário</span>
              <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('NomeBeneficiario', data)" />
            </div>
            <div class="col-bank">
              <span>Banco</span>
              <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('Banco', data)" />
            </div>
            <div class="col-value" style="min-width: 140px">
              <span>Valor</span>
              <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('vlr_transacao', data)" />
            </div>
            <div class="col-account">
              <span>Conta débito</span>
              <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('ContaDebito', data)" />
            </div>
            <div class="col-compromisso">
              <span>N° Compromisso</span>
              <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('cod_transacao', data)" />
            </div>
            <div class="col-lote">
              <span>Ref. Lote</span>
              <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('RefLote', data)" />
            </div>
            <div class="col-status">
              <span>Status</span>
              <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('desc_status', data)" />
            </div>
            <div class="col-action">
            </div>
          </div>
      </header>

      
      <div class="list-value">
        <div class="d-flex justify-content-center align-items-center" style="height: calc(70vh - 130px)" *ngIf="loading">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
        </div>

        <div class="empty-info" *ngIf="hide">
              <fibra-generic-info type="warn" title="Nenhum resultado encontrado."
                message="Tente fazer uma nova pesquisa inserindo novos parâmetros.">
              </fibra-generic-info>
        </div>

        <div *ngIf="!loading"> 
          <div class="panel-group" id="accordion">
            <div class="list-content" *ngFor="let dt of getDates(); index as y">

              <div class="line-dt datas" >
                <div colspan="11">
                  {{dt | date:'d'}} de {{dt | date:'MMMM':'UTC':'pt-br'}} de {{dt | date:'y'}}
                </div>
              </div>

            

              <div class="panel-list" *ngFor="let item of getTransactionByDate(dt); index as i" [ngClass]="{'azul': verificaSelected(i)}">
                  <div class="check-all">
                    <div class="checkbox" *ngIf="showCheckbox">
                      <input type="checkbox" class="checkbox" [id]="item.cod_transacao" [dataId]="item" (change)="checked(i, item)" />
                    </div>
                  </div>
                  <div class="col-transaction">
                    <span class="title-desc">{{
                      tratarTipoTransacao(item?.tipo_transacao) === 'PAGAMENTO'
                      ? 'Pgto. de boleto'
                      : tratarTipoTransacao(item?.tipo_transacao)
                      }}</span>
                    </div>
                  <div class="col-beneficiario" data-hj-suppress>
                    <span class="title-desc">{{ item?.NomeBeneficiario }}</span>
                    <span class="text-desc">CNPJ/CPF: {{ item?.num_cpf_cnpj?.trim()}}</span>
                    <!-- <span *ngIf="item?.tipo_transacao === 'PAGAMENTO'" class="text-desc">CNPJ/CPF: {{ item?.num_cpf_cnpj }}</span> -->
                  </div>
                  <div class="col-bank" style="margin-left:5px">
                    <span>
                      {{ item?.Banco }}
                    </span>
                  </div>
                  <div class="col-value" style="min-width: 140px">
                    <span>
                      {{ item?.vlr_transacao | currency: 'BRL' }}
                    </span>
                  </div>
                  <div class="col-account">
                    <span>
                      {{ item?.ContaDebito }}
                    </span>
                  </div>
                  <div class="col-compromisso">
                    <span>
                      {{ item.nr_Compromisso }}
                    </span>
                  </div>
                  <div class="col-lote">
                    <span>
                      {{ item.RefLote == '' || item.RefLote == null ? '-' : item.RefLote }}
                    </span>
                  </div>


                  <!-- ToolTip -->
                  <div class="col-status">
                    <div class="item-status util">
                      <div *ngIf="item?.desc_status === 'AGENDADO'">
                        <span class="icon-info notification" [appTooltip]="tooltipAll">
                          <div class="scheduled-icon">
                              <div style="margin-left: 15px;">Agendado</div>
                          </div>
                          <div #tooltipAll [ngClass]="
                              i <= 2 ? 'tooltip-custom-down' : 'tooltip-custom'
                            ">
                            <h3>Pagamento agendado</h3>
                            <p>
                              O pagamento será realizado na data agendada,
                              mediante disponibilidade de saldo em conta.
                            </p>
                            <div [ngClass]="i <= 2 ? 'arrow-top' : 'arrow'"></div>
                          </div>
                        </span>
                      </div>

                      <div *ngIf="item?.desc_status === 'LIQUIDADO'">
                        <span class="icon-info notification app-tooltip">
                          <div class="authorized-icon"><div style="margin-left: 15px;">Liquidado</div></div>
                        </span>
                      </div>

                    </div>
                  </div>
                  
                  <div class="col-lote">
                    <div class="item-download">
                      <img src="/assets/svg/download-accent-dark.svg" alt="download-accent"
                        (click)="downloadReceipt(item)" style="cursor: pointer;" />
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fixed-button" *ngIf="multiSelected">
        <button
        type="button"
        class="secondary-button button"
        (click)="multiDownloadPdf()"
      >
        Baixar selecionados
      </button>
      </div>
  </div>

  <section  *ngIf="report" id="body-report">
      <!-- <section   id="body-report"> -->
      <fibra-download-receipt (closeReport)="changeReportView($event)"></fibra-download-receipt>
  </section>
</div>