import { Router } from '@angular/router';
import { TabListDataShare } from '@src/core/usecases/data-share/tab-list-data-share.service';
import { Component, Input, OnInit } from '@angular/core';
import { isArray } from 'util';

@Component({
  selector: 'fibra-card-pendency',
  templateUrl: './card-pendency.component.html',
  styleUrls: ['./card-pendency.component.scss'],
})
export class CardPendencyComponent implements OnInit {
  @Input() dataPendency;
  @Input() dataPendenciesOpenBanking;
  @Input() parcelaAvencer:boolean;
  @Input() parcelaVencida:boolean;
  @Input() parcelaVencendo:boolean;
  @Input() showData: boolean;
  @Input() derivativePendency: boolean;
  @Input() hasNotificationPix: boolean;
  public showButton: boolean;
  constructor(private setTab: TabListDataShare, private router: Router) {}

  ngOnInit() {
  }

  scrollBy(side) {
    if (side === 'right') {
      document.querySelector('#test').scrollBy({
        left: 410,
        behavior: 'smooth',
      });
    }
    if (side === 'left') {
      document.querySelector('#test').scrollBy({
        left: -410,
        behavior: 'smooth',
      });
    }
  }

  linkRedirect() {
    this.setTab.setValue('PENDENTE');
    this.router.navigate(['/transaction-approval']);
  }
}
