<section *ngIf="!loading" class="main">
  <fibra-sidemenu *ngIf="!hideMenu"></fibra-sidemenu>
  <section class="main-container">
    <fibra-impersonate-bar class="impersonate-bar"></fibra-impersonate-bar>
    <section class="main-component-initial fixed-top" *ngIf="!hideMain">
      <section class="navbar-nav user-tablet group-items">
        <div *ngIf="name" class="option-list">
          
          <!--          Esta comentado pois vamos adicionar as funcionalidades-->
          <!-- <div>
            <img src="/assets/svg/search.svg" alt="search" />
          </div> -->
          <!-- <div class="notification">
            <img src="/assets/svg/sino.svg" alt="notification" />
            <div class="alert-circle"></div>
          </div> -->

          <div class="contentButtons">

            <div
            class="hideValues"
            (click)="showValues()"
            *ngIf="toogleShowIconEyes"
          >
            <img
              src="/assets/svg/icon_hide_gray.svg"
              alt="View password"
              *ngIf="!iconEyesOpen"
            />
            <img
              src="/assets/svg/icon_show_gray.svg"
              alt="Hide password"
              *ngIf="iconEyesOpen"
            />
            </div>

            <!-- <div class="nav-item dropdown perfil notify">
              <a
                class="nav-link dropdown-account"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="notification">
                  <img [ngClass]="mockNotification.length > 0 ? 'animate__animated animate__swing' : ''" #sininho src="/assets/svg/sino.svg" alt="notification" />
                  <div #circleAlert class="alert-circle"></div>
                </div>
              </a>

              <div class="dropdown-menu dropDowMenuNotify" aria-labelledby="navbarDropdown">
                <div class="dropdown-item listItens">
                  <p
                    class="dropdown-account shadowBorder"
                  >
                    Notificações
                  </p>
  
                  <div class="contentNotify">
                    <div class="notifyMonth">
                      <p class="month">Setembro</p>
                      <div class="notifyData" #elNtfy fibraNotifyControl (notifyEvent)="callNotifyEvent($event, i)" *ngFor="let nt of mockNotification; let i = index">
                        <div>
                          <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.5434 15.3773C18.5434 15.3221 18.4986 15.2773 18.4434 15.2773H3.28887L6.25014 12.316C6.2892 12.277 6.2892 12.2137 6.25014 12.1746L5.21855 11.143C5.1998 11.1243 5.17436 11.1137 5.14784 11.1137C5.12132 11.1137 5.09588 11.1243 5.07713 11.143L0.699796 15.5204C0.375487 15.8447 0.375487 16.3691 0.699796 16.6934L5.07713 21.0707C5.09588 21.0895 5.12132 21.1 5.14784 21.1C5.17436 21.1 5.1998 21.0895 5.21855 21.0707L6.25014 20.0391C6.2892 20.0001 6.2892 19.9367 6.25014 19.8977L3.28887 16.9364H18.4434C18.4986 16.9364 18.5434 16.8917 18.5434 16.8364V15.3773Z" fill="#495057" stroke="#495057" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.9229 0.929289C17.8838 0.890237 17.8205 0.890237 17.7814 0.929289L16.7498 1.96088C16.7311 1.97963 16.7206 2.00507 16.7206 2.03159C16.7206 2.05811 16.7311 2.08355 16.7498 2.1023L19.7111 5.06357H4.55656C4.50134 5.06357 4.45656 5.10834 4.45656 5.16357V6.62268C4.45656 6.67791 4.50134 6.72268 4.55656 6.72268H19.7111L16.7498 9.68396C16.7311 9.70271 16.7206 9.72814 16.7206 9.75467C16.7206 9.78119 16.7311 9.80662 16.7498 9.82538L17.7814 10.857C17.8205 10.896 17.8838 10.896 17.9229 10.857L22.3002 6.47964C22.6245 6.15533 22.6245 5.63093 22.3002 5.30662L17.9229 0.929289Z" fill="#495057" stroke="#495057" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            
                        </div>
                        <div>
                          <span>{{nt.title}}</span>
                          <span>{{nt.body}}</span>
                          <span>
                            26/10
                            <img class="readYesNo" #imgCtrl src="./assets/svg/circle_red.svg" />
                          </span>
                        </div> 

                        <div class="controlNotify" (click)="$event.preventDefault()">
                          <div class="notifiButtons">
                            <span> 
                              <a (click)="markRead(i, imgCtrl); $event.stopPropagation()">
                                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M1 6.3L6.6 12.1667L17 1.5" stroke="#73B599" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Marcar como lida
                              </a>
                            </span>
                          <span>
                            <a (click)="removeNotify(i, nt.id, elNtfy, circleAlert, sininho); $event.stopPropagation()">
                              <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 11.5009L1.00045 1.5M11 1.50091L1 11.5009L11 1.50091Z" stroke="#ED6C6C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              Remover notificação
                            </a>
                          </span>
                        </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>

          <div class="nav-item dropdown perfil perfil-2">
            <a
              class="nav-link dropdown-account"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Olá, {{ name | titlecase }}
              <svg
                class="bi bi-chevron-down"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
            <div class="dropdown-menu userFunction" aria-labelledby="navbarDropdown">
              <div class="dropdown-item">
                <p
                  class="nav-link dropdown-account"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Olá, {{ name | titlecase }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                    />
                  </svg>
                </p>
                <div class="divisor"></div>
                <p
                  id="navbarDropdown"
                  *fibraHasRole="'MASTER,!PF'"
                  (click)="navAdmin()"
                  class="nav-link dropdown-account"
                >
                  Administração de usuários
                </p>
                <p
                  id="navbarDropdown"
                  (click)="openModal()"
                  class="nav-link dropdown-account"
                >
                  Empresa padrão
                </p>
                <p
                  id="navbarDropdown"
                  *fibraHasRole="'MTZ_APROV.CAD_REGRAS,MTZ_APROV.VISU_REGRAS,MTZ_APROV.EDIT_EXCL_REG,!PF'"
                  (click)="navMatrix()"
                  class="nav-link dropdown-account"
                >
                  Matriz de aprovação
                </p>
                <p
                  id="navbarDropdown"
                  class="nav-link dropdown-account button-logout"
                  (click)="logout()"
                >
                  Sair
                </p>
              </div>
            </div>
            <div class="nav-item">
              <a class="nav-link word">{{ this.avatar }}</a>
            </div>
          </div>

          
        </div>
      </section>
    </section>
    <section class="main-content">
      <router-outlet></router-outlet>
    </section>
  </section>

  <fibra-toast-error-message [color]="colorToast"></fibra-toast-error-message>
</section>

<section class="loading-container" *ngIf="loading">
  <div class="d-flex justify-content-center position">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</section>
