import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@src/shared';
import { CommonModule } from '@angular/common';
import { CustomFormsModule } from '@src/shared/components/';
import { ButtonsModule } from '@src/shared/components/buttons/buttons.module';

// components

import { AccordionComponent } from '@src/presentation/web/components/accordion/accordion.component';
import { MainComponent } from '@src/presentation/web/components/main/main.component';
import { BannerComponent } from '@src/presentation/web/components/banner/banner.component';
import { SideMenuComponent } from '@src/presentation/web/components/sidemenu/sidemenu.component';
import { SideCardComponent } from '@src/presentation/web/components/side-card/side-card.component';
import { SideCardDerivativeComponent } from '@src/presentation/web/components/side-card-derivative/side-card-derivative.component';
import { ListComponent as MovementList } from '@src/presentation/web/components/lists/list.component';
import { InputListComponent } from '@src/presentation/web/components/lists/input/input-list.component';
import { ExitListComponent } from '@src/presentation/web/components/lists/exit/exit-list.component';
import { BreadcrumbComponent } from '@src/presentation/web/components/breadcrumb/breadcrumb.component';
import { DownloadButtonComponent } from '@src/presentation/web/components/download-button/download-button.component';
import { ProfileListComponent } from '@src/presentation/web/components/profile-list/profile-list.component';
import { CompanyListComponent } from '@src/presentation/web/components/company-list/company-list.component';
import { TabsListComponent } from '@src/presentation/web/components/tabs-list/tabs-list.component';
import { StepperComponent } from '@src/presentation/web/components/stepper/stepper.component';
import { StepsComponent } from '@src/presentation/web/components/steps/steps.component';
import { RangeDatepickerComponent } from '@src/presentation/web/components/range-datepicker/range-datepicker.component';
import { DatepickerComponent } from '@src/presentation/web/components/datepicker/datepicker.component';
import { SectionContentComponent } from '@src/presentation/web/components/section-content/section-content.component';
import { SummaryItemComponent } from '@src/presentation/web/components/summary-item/summary-item.component';
import { FormControlComponent } from '@src/presentation/web/components/form-control/form-control.component';
import { FormControlGroupComponent } from '@src/presentation/web/components/form-control-group/form-control-group.component';
import { ChargeFirstStepComponent } from '@src/presentation/web/components/charge-first-step/charge-first-step.component';
import { ChargeSecondStepComponent } from '@src/presentation/web/components/charge-second-step/charge-second-step.component';
import { RadioButtonComponent } from '@src/presentation/web/components/radiobutton/radiobutton.component';
import { ProtestComponent } from '@src/presentation/web/components/protest/protest.component';
import { DuplicatesListComponent } from '@src/presentation/web/components/lists/duplicates/duplicates-list.component';
import { BlockListComponent } from '@src/presentation/web/components/lists/block/block-list.component';
import { AllListComponent } from '@src/presentation/web/components/lists/all/all-list.component';
import { SchedulingListComponent } from '@src/presentation/web/components/lists/scheduling/scheduling-list.component';
import { DownloadArchiveComponent } from '@src/presentation/web/components/download-archive/download-archive.component';
import { ProgressComponent } from '@src/presentation/web/components/download-archive/progress/progress.component';
import { FieldErrorDisplayComponent } from '@src/presentation/web/components/display-error/display-error.component';
import { ListingTabComponent } from '@src/presentation/web/components/listing-tab/listing-tab.component';
import { FilterComponent } from '@src/presentation/web/components/filter/filter.component';
import { FilterCobrancaComponent } from '@src/presentation/web/components/filter-cobranca/filter-cobranca.component';
import { QueryTransitListComponent } from '@src/presentation/web/components/query-transit/query-transit.component';
import { QueryTransitRefusedListComponent as QueryTransitDetailsListComponent } from '@src/presentation/web/components/lists/query-transit-details/query-transit-details.component';
import { QueryTransitRefusedListComponent } from '@src/presentation/web/components/lists/query-transit-refused/query-transit.component';
import { QueryTransitPendingListComponent } from '@src/presentation/web/components/lists/query-transit-pending/query-transit.component';
import { QueryTransitAuthorizedListComponent } from '@src/presentation/web/components/lists/query-transit-authorized/query-transit.component';
import { PorfolioTransitAllListComponent } from '@src/presentation/web/components/lists/portfolio-position-all/query-transit.component';
//import { ContractsComponent } from '@src/presentation/web/components/contracts/contracts.component';
// tslint:disable-next-line: max-line-length
import { PortfolioTransitAuthorizedListComponent } from '@src/presentation/web/components/lists/portfolio-position-authorized/query-transit.component';
// tslint:disable-next-line: max-line-length
import { PortfolioTransitPendingListComponent } from '@src/presentation/web/components/lists/portfolio-position-pending/query-transit.component';
// tslint:disable-next-line: max-line-length
import { PortfolioTransitRefusedListComponent } from '@src/presentation/web/components/lists/portfolio-position-refused/query-transit.component';
import { ModalValidationComponent } from '@src/presentation/web/components/modal-security-validation/modal-validation.component';
import { ModalValidationContractSimulationComponent } from '@src/presentation/web/components/modal-security-validation-contract-simulation/modal-security-validation-contract-simulation.component';
import { ModalDefaultCompanyComponent } from '@src/presentation/web/components/modal-default-company/modal-default-company.component';
import { ModalParcelasProdutosComponent } from '@src/presentation/web/components/modal-parcelas-produtos/modal-parcelas-produtos.component';
import { ModalConfirmationContractComponent } from '@src/presentation/web/components/modal-confirmation-contract/modal-confirmation-contract.component';
import {ModalChequeEmpresaPixComponent} from '@src/presentation/web/components/modal-cheque-empresa-pix/modal-cheque-empresa-pix.component';
import { ModalGenericConfirmationComponent } from '@src/presentation/web/components/modal-generic-confirmation/modal-generic-confirmation.component';
import { InstructionListComponent } from '@src/presentation/web/components/lists/instruction-list/instruction-list.component';
import { FrancesinhaListComponent } from '@src/presentation/web/components/lists/francesinha-list/francesinha-list.component';
import { ListArchiveComponent } from '@src/presentation/web/components/list-archive/list-archive.component';
import { QuickAccessReceiptsComponent } from '@src/presentation/web/components/quick-access-receipts/quick-access-receipts.component';
// tslint:disable-next-line: max-line-length
import { QuickAccessTransactionsComponent } from '@src/presentation/web/components/quick-access-transactions/quick-access-transactions.component';
// import { QuickAccessGoAlongComponent } from '@src/presentation/web/components/quick-access-go-along/quick-access-go-along.component';
import { CardBalanceComponent } from '@src/presentation/web/components/card-balance/card-balance.component';
import { CardPendencyComponent } from '@src/presentation/web/components/card-pendency/card-pendency.component';
import { BalanceExtractListComponent } from '@src/presentation/web/components/balance-list/balance-list.component';
import { BalanceDatailComponent } from '@src/presentation/web/components/balance-datail/balance-datail.component';
import { BeneficitiesTransferListComponent } from '@src/presentation/web/components/beneficities-list/beneficities-list.component';
// tslint:disable-next-line: max-line-length
import { ListBeneficiariesDetailsComponent } from '@src/presentation/web/components/details-list-beneficiaries/details-list-beneficiaries.component';
import { QueryTransitDetailComponent } from './query-transit-detail/query-transit-detail.component';
import { QueryPortfolioDetailComponent } from './query-portfolio-detail/query-portfolio-detail.component';
import { PurposesListComponent } from './purposes-list/purposes-list.component';
import { ListBankComponent } from './list-bank/list-bank.component';
import { ToastErrorMessageComponent } from './toast-error-message/toast-error-message.component';
import { SlideAccountSelectorComponent } from './slide-account-selector/slide-account-selector.component';
import { SlideAccountSelectorPixComponent } from './slide-account-selector-pix/slide-account-selector-pix.component';
import { TransactionInformationComponent } from './transaction-information/transaction-information.component';
import { PaymentRevisionDetail } from './payment-revision-detail/payment-revision-detail.component';
import { ListMatrixComponent } from './list-matrix/list-matrix.component';
import { NewRuleMatrixComponent } from './new-rule-matrix/new-rule-matrix.component';
import { TransferApprovalDetailComponent } from './transfer-approval-detail/transfer-approval-detail.component';
import { PaymentMultipleDetailComponent } from './payment-multiple-detail/payment-multiple-detail.component';
import { ListAdminUserComponent } from './list-admin-user/list-admin-user.component';
import { AdminUserDetailComponent } from './admin-user-detail/admin-user-detail.component';
import { SideCardReadyChangeComponent } from '@src/presentation/web/components/side-card-ready-change/side-card-ready-change.component';

// lib
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ListingInvestimentosTabComponent } from './listing-investimentos-tab/listing-investimentos-tab.component';
import { CardValueInvestmentsComponent } from '@src/shared/components/cards/card-value-investments/card-value-investments.component';
import { ModalRegistratoErrorComponent } from './modal-registrato-error/modal-registrato-error.component';
import { QueryPosicaoCarteiraComponent } from './query-posicao-carteira/query-posicao-carteira.component';
import { ImpersonateBarComponent } from './impersonate-bar/impersonate-bar.component';
import { DownloadBatchPaymentComponent } from './download-batch-payment/download-batch-payment.component';
import { ToastFunctionalityAccessComponent } from '@src/presentation/web/components/toast-functionality-access/toast-functionality-access.component';
import { ServiceChannelsComponent } from '@src/presentation/web/components/service-channels/service-channels.component';
import { DetailsLiquidadoComponent } from './details-liquidado/details-liquidado.component';
import { PixReceiptComponent } from '@src/presentation/web/components/pix-receipt/pix-receipt.component';
import { AcceptDocPageComponent } from './accept-doc/accept-doc.component';
import { AlertComponent } from './alert/alert.component';
import { LimitChangeTimeComponent } from './limit-change-time/limit-change-time.component';
import { TransactionalLimitsComponent } from './transactional-limits/transactional-limits.component';
import { LimitApprovalComponent } from './limit-approval/limit-approval.component';
import { LimitDetailComponent } from './limit-detail/limit-detail.component';
import { PixLimitTransactionalComponent } from './pix-limit-transactional/pix-limit-transactional.component';
import { PixReceiptDevolutionComponent } from './pix-receipt-devolution/pix-receipt-devolution.component';
import { ModalPeriodDatepickerComponent } from '@src/presentation/web/components/modal-period-datepicker/modal-period-datepicker.component';


@NgModule({
  entryComponents: [
    ServiceChannelsComponent
],
  declarations: [
    AccordionComponent,
    BannerComponent,
    CompanyListComponent,
    ProfileListComponent,
    MainComponent,
    SideMenuComponent,
    SideCardComponent,
    SideCardDerivativeComponent,
    MovementList,
    InputListComponent,
    ExitListComponent,
    RangeDatepickerComponent,
    BreadcrumbComponent,
    DownloadButtonComponent,
    TabsListComponent,
    StepperComponent,
    StepsComponent,
    SectionContentComponent,
    SummaryItemComponent,
    FormControlComponent,
    FormControlGroupComponent,
    ChargeFirstStepComponent,
    ChargeSecondStepComponent,
    DatepickerComponent,
    RadioButtonComponent,
    ProtestComponent,
    DownloadArchiveComponent,
    ProgressComponent,
    BlockListComponent,
    SchedulingListComponent,
    AllListComponent,
    FieldErrorDisplayComponent,
    DuplicatesListComponent,
    ListingTabComponent,
    FilterComponent,
    FilterCobrancaComponent,
    QueryTransitListComponent,
    QueryTransitDetailsListComponent,
    QueryTransitRefusedListComponent,
    QueryTransitPendingListComponent,
    QueryTransitAuthorizedListComponent,
    QueryPosicaoCarteiraComponent,
    PorfolioTransitAllListComponent,
    PortfolioTransitAuthorizedListComponent,
    PortfolioTransitPendingListComponent,
    PortfolioTransitRefusedListComponent,
    ModalValidationComponent,
    ModalValidationContractSimulationComponent,
    ModalPeriodDatepickerComponent,
    ModalDefaultCompanyComponent,
    ModalParcelasProdutosComponent,
    ModalConfirmationContractComponent,
    ModalChequeEmpresaPixComponent,
    ModalGenericConfirmationComponent,
    InstructionListComponent,
    FrancesinhaListComponent,
    ListArchiveComponent,
    QuickAccessReceiptsComponent,
    QuickAccessTransactionsComponent,
    
    CardBalanceComponent,
    CardPendencyComponent,
    BalanceExtractListComponent,
    BalanceDatailComponent,
    BeneficitiesTransferListComponent,
    ListBankComponent,
    ListBeneficiariesDetailsComponent,
    QueryTransitDetailComponent,
    QueryPortfolioDetailComponent,
    SlideAccountSelectorComponent,
    SlideAccountSelectorPixComponent,
    PurposesListComponent,
    TransactionInformationComponent,
    ListMatrixComponent,
    PaymentRevisionDetail,
    NewRuleMatrixComponent,
    TransferApprovalDetailComponent,
    ToastErrorMessageComponent,
    PaymentMultipleDetailComponent,
    ListAdminUserComponent,
    AdminUserDetailComponent,
    ListingInvestimentosTabComponent,
    ModalRegistratoErrorComponent,
    SideCardReadyChangeComponent,
    ImpersonateBarComponent,
    DownloadBatchPaymentComponent,
    ToastFunctionalityAccessComponent,
    ServiceChannelsComponent,
    DetailsLiquidadoComponent,
    PixReceiptComponent,
    AcceptDocPageComponent,
    AlertComponent,
    LimitChangeTimeComponent,
    TransactionalLimitsComponent,
    LimitApprovalComponent,
    LimitDetailComponent,
    PixLimitTransactionalComponent,
    PixReceiptDevolutionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CustomFormsModule,
    NgbModule,
    RouterModule,
    ButtonsModule,
    TextMaskModule,
    CurrencyMaskModule,


    NgCircleProgressModule.forRoot({
      radius: 25,
      outerStrokeWidth: 3,
      innerStrokeWidth: 3,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#f3f3f3',
      showSubtitle: false,
      showTitle: false,
      animation: true,
      animationDuration: 300,
      showImage: true,
      showBackground: false,
      space: -3,
      imageHeight: 35,
      imageWidth: 18,
    }),
  ],
  exports: [
    AccordionComponent,
    BannerComponent,
    CompanyListComponent,
    ProfileListComponent,
    MainComponent,
    SideMenuComponent,
    SideCardComponent,
    SideCardDerivativeComponent,
    MovementList,
    InputListComponent,
    ExitListComponent,
    RangeDatepickerComponent,
    BreadcrumbComponent,
    DownloadButtonComponent,
    CustomFormsModule,
    TabsListComponent,
    StepperComponent,
    StepsComponent,
    SectionContentComponent,
    SummaryItemComponent,
    FormControlComponent,
    FormControlGroupComponent,
    ChargeFirstStepComponent,
    ChargeSecondStepComponent,
    DatepickerComponent,
    RadioButtonComponent,
    ProtestComponent,
    ButtonsModule,
    DownloadArchiveComponent,
    ProgressComponent,
    BlockListComponent,
    SchedulingListComponent,
    AllListComponent,
    FieldErrorDisplayComponent,
    TextMaskModule,
    CurrencyMaskModule,
    ListingTabComponent,
    ListingInvestimentosTabComponent,
    FilterComponent,
    FilterCobrancaComponent,
    QueryTransitListComponent,
    QueryTransitDetailsListComponent,
    QueryTransitRefusedListComponent,
    QueryTransitPendingListComponent,
    QueryTransitAuthorizedListComponent,
    QueryPosicaoCarteiraComponent,
    PorfolioTransitAllListComponent,
    PortfolioTransitAuthorizedListComponent,
    PortfolioTransitPendingListComponent,
    PortfolioTransitRefusedListComponent,
    ModalValidationComponent,
    ModalValidationContractSimulationComponent,
    ModalParcelasProdutosComponent,
    ModalConfirmationContractComponent,
    ModalChequeEmpresaPixComponent,
    ModalGenericConfirmationComponent,
    InstructionListComponent,
    FrancesinhaListComponent,
    ListArchiveComponent,
    QuickAccessReceiptsComponent,
    QuickAccessTransactionsComponent,
    CardBalanceComponent,
    CardPendencyComponent,
    BalanceExtractListComponent,
    BalanceDatailComponent,
    BeneficitiesTransferListComponent,
    ListBankComponent,
    ListBeneficiariesDetailsComponent,
    QueryTransitDetailComponent,
    QueryPortfolioDetailComponent,
    SlideAccountSelectorComponent,
    SlideAccountSelectorPixComponent,
    PurposesListComponent,
    TransactionInformationComponent,
    ListMatrixComponent,
    PaymentRevisionDetail,
    NewRuleMatrixComponent,
    TransferApprovalDetailComponent,
    ToastErrorMessageComponent,
    PaymentMultipleDetailComponent,
    ListAdminUserComponent,
    AdminUserDetailComponent,
    CardValueInvestmentsComponent,
    ModalRegistratoErrorComponent,
    SideCardReadyChangeComponent,
    ImpersonateBarComponent,
    DownloadBatchPaymentComponent,
    ToastFunctionalityAccessComponent,
    ServiceChannelsComponent,
    PixReceiptComponent,
    AcceptDocPageComponent,
    AlertComponent,
    LimitChangeTimeComponent,
    TransactionalLimitsComponent,
    LimitApprovalComponent,
    LimitDetailComponent,
    PixLimitTransactionalComponent,
    PixReceiptDevolutionComponent,
    ModalPeriodDatepickerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [NgbActiveModal]
})
export class CustomComponentsModule {}
