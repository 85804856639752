import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { GetGroupDetailsUseCase } from '@src/core/usecases/admin-user/get-group-details.usecase';
import { GetUserDetailsUseCase } from '@src/core/usecases/admin-user/get-user-details.usecase';
import { SetStatusGroupUsecase } from '@src/core/usecases/admin-user/set-status-group.usecase';
import { GetGroupsListUseCase } from '@src/core/usecases/admin-user/get-groups.usecase';
import { GetUsersUseCase } from '@src/core/usecases/admin-user/get-users.usecase';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as Util from '@src/shared/util-common';
import * as $ from 'jquery';
import {
  AdminGroupListEnum,
  AdminGroupListModel,
  AdminListEnum,
  AdminUserListModel,
} from '@src/core/domain/admin-user/admin-user.model';

@Component({
  selector: 'fibra-list-admin-user',
  templateUrl: './list-admin-user.component.html',
  styleUrls: ['./list-admin-user.component.scss'],
})
export class ListAdminUserComponent implements OnInit {
  public hide: boolean;
  public loading: boolean;
  public sorted: boolean;
  public sortedGroup: boolean;
  public indexList;
  public list: Array<AdminListEnum> = [];
  public listGroup: Array<AdminGroupListEnum> = [];
  public active;
  public typeColorToast: string;
  public showAction = false;
  public data;
  public showList = [];
  public showListGroup = [];
  public inputValue: string;
  public checkList = [];
  public checkListGroup = [];
  public showDeleteButton: boolean;
  public checkAll: boolean;
  public multiApproval = true;
  public userDetails: any;
  public groupDetails: any;
  public securityAction: boolean;
  public apiCallback = 0;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  public BLUECOLOR = '#6CBED9';
  public YELLOWCOLOR = '#D69D10';
  public otpParams: any;
  public errorMessage = '';
  public successMessage = '';
  public footerOption: any;
  public textAlertRule: any;
  public countChecked = 0;
  public countCheckedGroup = 0;
  public showModalConfirmation = false;
  public optionGroup: any;
  public idGroup:any;
  public message: string = "Ao confirmar a exclusão, os operadores cadastrados manterão somente as permissões concedidas em outros grupos ou atribuídas individualmente."
  public message2: string = "Deseja prosseguir com a exclusão?";
  public showAprovarReprovarButton: boolean;

  constructor(
    private getAdminUserList: GetUsersUseCase,
    private getAdminGroupList: GetGroupsListUseCase,
    private getUserDetails: GetUserDetailsUseCase,
    private getGroupDetails: GetGroupDetailsUseCase,
    private setStatusGroup: SetStatusGroupUsecase,
    private router: Router,
    private route: ActivatedRoute,
  ) { }
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;
  ngOnInit(): void {
    this.handleTabs('first');
    this.route.params.subscribe(params => {
      // Toast - Group
      if (params && params.status === '0') {
        this.typeColorToast = this.REDCOLOR;
        this.toast.callModalMessage(null, 'Ocorreu um erro ao cadastrar o grupo.', 'Tente novamente por favor.');
        this.handleTabs('second');
      } else if (params && params.status === '1') {
        this.typeColorToast = this.YELLOWCOLOR;
        this.toast.callModalMessage(null, 'Cadastro do grupo pendente.', 'Os demais masters precisam aprovar o cadastro.');
        this.handleTabs('second');
      } else if (params && params.status === '2') {
        this.typeColorToast = this.GREENCOLOR;
        this.toast.callModalMessage(null, 'Grupo cadastrado com sucesso!');
        this.handleTabs('second');
      }
      // Toast - User
      if (params && params.type === 'user') {
        switch (Number(params.status)) {
          case 0:
            this.typeColorToast = this.REDCOLOR;
            break;
          case 1:
            this.typeColorToast = this.YELLOWCOLOR;
            break;
          case 2:
            this.typeColorToast = this.GREENCOLOR;
            break;
        }
        this.toast.callModalMessage(null, params.msg);
        this.handleTabs('first');
      }
      window.history.replaceState({}, document.title, '/' + 'admin-user');
    });
  }

  private requestAdminUserList(): void {
    this.loading = true;
    this.getAdminUserList
      .execute()
      .subscribe(this.requestUserSuccess, this.requestUserError);
  }

  private requestUserSuccess = (value: AdminUserListModel) => {
    this.showList = [];
    this.list = Util.verifyArray(value.data);
    this.hide = Util.isEmpty(this.list);
    this.list.map((e) => {
      const emailAprovador = localStorage.getItem('useremail');
      const obj: any = e;
      obj.lista_aprovadores_pendentes = [];
      obj.isMasterApproved = true;
      obj.aprovadores.map(f => {
        if (f.status === 1) { obj.lista_aprovadores_pendentes.push(f); }
        if (emailAprovador === f.email && f.status === 1) { obj.isMasterApproved = false; }
      });
      obj.checked = false;
      obj.desc_status = obj.status === 1 ? 'Pendente' : obj.status === 2 ? 'Aprovado' : 'Reprovado';
      this.showList.push(obj);
    });
    this.loading = false;
  }

  private requestUserError = (err) => {
    this.hide = Util.isEmpty(this.list);
    this.loading = false;
  }

  private requestGroupUserList(): void {
    this.loading = true;
    this.getAdminGroupList
      .execute()
      .subscribe(this.requestGroupSuccess, this.requestGroupError);
  }

  private requestGroupSuccess = (value: AdminGroupListModel) => {
    this.showListGroup = [];
    this.listGroup = Util.verifyArray(value.data);
    this.hide = Util.isEmpty(this.listGroup);
    this.listGroup.map((e) => {
      const emailAprovador = localStorage.getItem('useremail');
      const obj: any = e;
      obj.lista_aprovadores_pendentes = [];
      obj.isMasterApproved = false;
      obj.lista_aprovadores.map(f => {
        if (f.status === 1) { obj.lista_aprovadores_pendentes.push(f); }
        if (emailAprovador === f.email_aprovador && f.status === 2) { obj.isMasterApproved = true; }
      });
      obj.checked = false;
      this.showListGroup.push(obj);
    });
    this.loading = false;
  }

  private requestGroupError = (err) => {
    this.hide = Util.isEmpty(this.listGroup);
    this.loading = false;
    return console.log(err);
  }

  public toogleIconArrow(index, idRule) {
    this.userDetails = null;
    if (this.indexList !== index) {
      this.indexList = index;
      $('.collapse').removeClass('show');
      $(`.item-icon img`).removeClass('img-arrow');
    }

    document.getElementById(index).classList.toggle('img-arrow');
    $('input').val('');
    $('option').attr('selected', false);

    this.getDetails(idRule);
  }

  public toogleIconArrowGroup(index, idRule) {
    this.groupDetails = null;
    if (this.indexList !== index) {
      this.indexList = index;
      $('.collapse').removeClass('show');
      $(`.item-icon img`).removeClass('img-arrow');
    }

    document.getElementById(index).classList.toggle('img-arrow');
    $('input').val('');
    $('option').attr('selected', false);

    this.getDetailsGroup(idRule);
  }

  public handleTabs(stage) {
    this.active = stage;
    this.loading = true;
    this.inputValue = '';
    this.showDeleteButton = false;
    this.showAprovarReprovarButton = false;
    this.checkAll = false;

    if (stage === 'first') {
      this.list = [];
      this.showList = [];
      this.requestAdminUserList();
      return;
    }

    if (stage === 'second') {
      this.listGroup = [];
      this.showListGroup = [];
      this.requestGroupUserList();
      return;
    }
  }

  public sortList(value) {
    this.sorted = !this.sorted;
    this.showList.sort((a, b) => {
      if (a[value] < b[value]) {
        if (!this.sorted) {
          return -1;
        } else {
          return 1;
        }
      }
      if (a[value] > b[value]) {
        if (!this.sorted) {
          return 1;
        } else {
          return -1;
        }
      }
      return 0;
    });
  }

  public sortListGroup(value) {
    this.sortedGroup = !this.sortedGroup;
    this.showListGroup.sort((a, b) => {
      if (a[value] < b[value]) {
        if (!this.sortedGroup) {
          return -1;
        } else {
          return 1;
        }
      }
      if (a[value] > b[value]) {
        if (!this.sortedGroup) {
          return 1;
        } else {
          return -1;
        }
      }
      return 0;
    });
  }

  public filterList = (value) => {
    if (this.active === 'first') {
      this.filterFirst(value);
    } else {
      this.filterSecond(value);
    }
  }

  public filterFirst = (value) => {
    this.showList = [];
    this.list.map((e) => {
      if (
        (e.nome_pessoa.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          e.email.toLowerCase().indexOf(value.toLowerCase()) > -1)
      ) {
        this.showList.push(e);
      }
    });
    this.hide = Util.isEmpty(this.showList);
  }

  public filterSecond = (value) => {
    this.showListGroup = [];
    this.listGroup.map((e) => {
      if (e.desc_grupo.toLowerCase().indexOf(value.toLowerCase()) > -1) {
        this.showListGroup.push(e);
      }
    });
    this.hide = Util.isEmpty(this.showListGroup);
  }

  public checkedList = (index) => {
    let flag: boolean;
    var aprovaReprova = false;
    this.countChecked = 0;
    this.showList.map((e) => {
      if (e.checked) {
        this.showDeleteButton = true;
        if(e.desc_status == 'Aprovado'){
          aprovaReprova = true;
        }
        flag = true;
        this.countChecked++;
      }
    });
    this.showAprovarReprovarButton = aprovaReprova;
    if (!flag) { 
      this.showDeleteButton = false; 
      this.showAprovarReprovarButton = false;
    }
  }

  public checkedListGroup = (index) => {
    let flag: boolean;
    var aprovaReprova = false;
    this.countCheckedGroup = 0;
    this.showListGroup.map((e) => {
      if (e.checked) {
        this.showDeleteButton = true;
        if(e.status == 2){
          aprovaReprova = true;
        }
        flag = true;
        this.countCheckedGroup++;
      }
    });
    this.showAprovarReprovarButton = aprovaReprova;
    if (!flag) { 
      this.showDeleteButton = false;
      this.showAprovarReprovarButton = false;
    }
  }

  public checkedAll = () => {
    var aprovaReprova = false;
    if (this.active === 'first') {
      this.showList.map((e) => {
        // e.checked = e.desc_status !== 'Aprovado' ? this.checkAll : false;
        if(e.desc_status == 'Aprovado'){
          aprovaReprova = true;
        }
        e.checked = this.checkAll;
      });
      this.countChecked = 0;
    } else {
      this.showListGroup.map((e) => {
        e.checked = this.checkAll;
        if(e.status == 2){
          aprovaReprova = true;
        }
      });
      this.countCheckedGroup = 0;
    }
    this.showAprovarReprovarButton = aprovaReprova;
    this.showDeleteButton = this.checkAll;
  }

  public removeAllCheck = () => {
    this.checkAll = false;
    this.showAprovarReprovarButton = false;
    this.checkedAll();
  }

  public editGroup = item => {
    const groupRoute = {
      page: '/register-group-admin-user',
      params: { editar: true, id: item.cod_grupo, nameGroup: item.desc_grupo }
    };
    this.router.navigate([groupRoute.page, groupRoute.params],  {skipLocationChange: true});
  }

  public editAccount = item => {
    const userRoute = {
      page: '/register-admin-user',
      params: { editar: true, details: JSON.stringify(item) }
    };
    this.router.navigate([userRoute.page, userRoute.params], { skipLocationChange: true });
  }

  public goToView = view => {
    this.router.navigate([view]);
  }

  private getDetails = (id) => {
    const params = {
      cod_pessoa: id,
    };

    this.getUserDetails.execute(params).subscribe((res) => {
      if (res && res.data) {
        this.userDetails = res;
      }
    }, err => {
      console.log('err', err);
    });
  }

  private getDetailsGroup = (id) => {
    const params = {
      cod_pessoa: id,
    };

    this.getGroupDetails.execute(params).subscribe((res) => {
      if (res && res.data) {
        this.groupDetails = res;
      }
    }, err => {
      console.log('err', err);
    });
  }

  public closeModal = () => {
    this.securityAction = false;
    this.loading = false;
  }

  public securityRequest = () => {
    const params = this.otpParams;
    return params;
  }

  public securityResponse = value => {
    if (value.status === 'success') {
      this.requestSuccess(value);
      return;
    }
    this.requestError(value);
  }

  private requestSuccess = res => {
    this.loading = false;
    this.securityAction = false;
    this.typeColorToast = this.BLUECOLOR;
    this.toast.callModalMessage(null, this.successMessage, null, false, true);
    this.removeAllCheck();
    this.handleTabs(this.active);
    this.showAction = false;
  }

  private requestError = (err) => {
    this.loading = false;
    this.securityAction = false;
    this.typeColorToast = this.REDCOLOR;
    this.toast.callModalMessage(null, this.errorMessage, null, false, true);
  }

  public requestDeleteGroup = (cod_grupo) => {
    this.otpParams = cod_grupo;
    this.apiCallback = 16;
    this.securityAction = true;
    this.errorMessage = 'Ocorreu um erro ao excluir o grupo. Tente novamente mais tarde.';
    this.successMessage = 'Grupo excluído com sucesso.';
  }

  public requestDeleteUser = (cod_pessoa) => {
    this.otpParams = cod_pessoa;
    this.apiCallback = 13;
    this.securityAction = true;
    this.errorMessage = 'Ocorreu um erro ao excluir o usuário. Tente novamente mais tarde.';
    this.successMessage = 'Usuário excluido com sucesso.';
  }

  public requestDeleteGroups = () => {
    this.otpParams = {
      ids: []
    };
    this.showListGroup.map(e => {
      if (e.checked) { this.otpParams.ids.push(e.cod_grupo); }
    });
    this.apiCallback = 19;
    this.securityAction = true;
    this.errorMessage = 'Ocorreu um erro ao excluir os grupos. Tente novamente mais tarde.';
    this.successMessage = 'Grupos excluidos com sucesso';
  }

  public requestDeleteUsers = () => {
    this.otpParams = {
      ids: []
    };
    this.showList.map(e => {
      if (e.checked) { this.otpParams.ids.push(e.cod_pessoa); }
    });
    this.apiCallback = 17;
    this.securityAction = true;
    this.errorMessage = 'Ocorreu um erro ao excluir o usuário. Tente novamente mais tarde.';
    this.successMessage = 'Usuários excluidos com sucesso.';
  }

  public requestApproveUser = (id, status) => {
    this.otpParams = {
      pessoa_id_nova: id,
      status,
    };
    this.apiCallback = 18;
    this.securityAction = true;
    this.errorMessage = `Ocorreu um erro ao ${status === 2 ? 'aprovar' : 'reprovar'} o usuário. Tente novamente mais tarde.`;
    this.successMessage = `Usuário ${status === 2 ? 'aprovado' : 'reprovado'} com sucesso.`;
  }

  public requestApproveUsers = () => {
    const emailAprovador = localStorage.getItem('useremail');
    this.otpParams = [];
    this.showList.map(e => {
      if (e.checked) {
        this.otpParams.push({
          pessoa_id_nova: e.cod_pessoa,
          email_aprovador: emailAprovador,
          status: this.multiApproval ? 2 : 3,
        });
      }
    });
    this.apiCallback = 22;
    this.securityAction = true;
    this.errorMessage = `Ocorreu um erro ao ${this.multiApproval ? 'aprovar' : 'reprovar'} os usuários. Tente novamente mais tarde.`;
    this.successMessage = `Usuários ${this.multiApproval ? 'aprovados' : 'reprovados'} com sucesso.`;
  }

  public requestApproveGroup = (id, status) => {
    this.otpParams = {
      grupo_id: id,
      status,
    };
    this.apiCallback = 20;
    this.securityAction = true;
    this.errorMessage = `Ocorreu um erro ao ${status === 2 ? 'aprovar' : 'reprovar'} o grupo. Tente novamente mais tarde.`;
    this.successMessage = `Grupo ${status === 2 ? 'aprovado' : 'reprovado'} com sucesso.`;
  }

  public requestApproveGroups = () => {
    const emailAprovador = localStorage.getItem('useremail');
    this.otpParams = [];
    this.showListGroup.map(e => {
      if (e.checked) {
        this.otpParams.push({
          grupo_id: e.cod_grupo,
          email_aprovador: emailAprovador,
          status: this.multiApproval ? 2 : 3,
        });
      }
    });
    this.apiCallback = 21;
    this.securityAction = true;
    this.errorMessage = `Ocorreu um erro ao ${this.multiApproval ? 'aprovar' : 'reprovar'} os grupos. Tente novamente mais tarde.`;
    this.successMessage = `Grupos ${this.multiApproval ? 'aprovados' : 'reprovados'} com sucesso.`;
  }

  public handleDelete = (value, type, text) => {
    this.footerOption = { value, type };
    this.typeColorToast = this.REDCOLOR;
    this.toast.dismissToast();
    this.toast.callModalMessage(null, text, null, true);
  }

  public handleApproval = (value, type, text) => {
    this.footerOption = { value, type };
    if(this.showAprovarReprovarButton){
      this.typeColorToast = this.REDCOLOR;
      this.toast.dismissToast();
      this.toast.callModalMessage(null, 'Favor selecionar apenas registros com a situação Pendente.', null);
    }else{
      this.typeColorToast = this.BLUECOLOR;
      this.toast.dismissToast();
      this.toast.callModalMessage(null, text, null, true);
    }
  }

  public selectToasterFunction = () => {
    const { type, value } = this.footerOption;
    switch (type) {
      case 'SINGLEUSER':
        this.requestDeleteUser(value);
        break;
      case 'MULTIPLEUSER':
        this.requestDeleteUsers();
        break;
      case 'SINGLEGROUP':
        this.idGroup = value;
        this.optionGroup = type;
        this.callModalConfirmation()
        //this.requestDeleteGroup(value);
        break;
      case 'MULTIPLEGROUP':
        this.optionGroup = type;
        this.callModalConfirmation()
        //this.requestDeleteGroups();
        break;
      case 'APPROVALSINGLEGROUP':
        this.requestApproveGroup(value.id, value.status);
        break;
      case 'APPROVALMULTIPLEGROUP':
        this.requestApproveGroups();
        break;
      case 'APPROVALSINGLEUSER':
        this.requestApproveUser(value.id, value.status);
        break;
      case 'APPROVALMULTIPLEUSER':
        this.requestApproveUsers();
        break;
    }
  }

  public optionNo = () => {};

  public resolveMessage = (value) => {
    const userS = this.countChecked > 1 ? 's' : '';
    const groupS = this.countCheckedGroup > 1 ? 's' : '';
    const approval = this.multiApproval ? 'aprovar' : 'reprovar';
    return value === 'USER'
      ? `Deseja ${approval} o${userS} usuário${userS}\ selecionado${userS}?`
      : `Deseja ${approval} o${groupS} grupo${groupS} selecionado${groupS}?`;
  }

  public callModalConfirmation = () =>{
    this.showModalConfirmation = true;
  }

  public closeModalConf = (value: any) => {
    if(value == 'sim'){
      this.showModalConfirmation = false;
      switch (this.optionGroup) {
        case 'SINGLEGROUP':
          this.requestDeleteGroup(this.idGroup);
          break;
        case 'MULTIPLEGROUP':
          this.requestDeleteGroups();
          break;
      }
    }else if(value == 'cancelar'){
      this.showModalConfirmation = false;
    }
  }
}
