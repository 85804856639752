import {Component, Input, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'fibra-limit-detail',
  templateUrl: './limit-detail.component.html',
  styleUrls: ['./limit-detail.component.scss']
})
export class LimitDetailComponent implements OnInit {

  @Input() public data: any;
  @Input() index;
  @Input() loading;
  @Input() identificationPage: string;

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onClick() {
    
  }
}
