<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <div class="main-divisor"></div>
  <fibra-profile-list></fibra-profile-list>
</div>

<div class="content-page">
  <fibra-breadcrumb
    bottom="-120px"
    infoAccount=""
    roule="Pagamento de boletos"
    arrow="false"
    downloadDoc="false"
  ></fibra-breadcrumb>
  <div class="body-content">
    <div class="listCard">
      <div class="topTexts">
        <span class="title">Status da transação</span>
        <span class="link" (click)="onNav('/transaction-approval')">Acompanhar transações</span>
      </div>
      <div class="list-value">
        <!-- Loading -->
        <div class="d-flex justify-content-center position loading-container" *ngIf="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="empty-info" *ngIf="!loading && error.active">
          <fibra-generic-info
            [type]="error.type"
            [title]="error.title"
            [message]="error.message"
          >
          </fibra-generic-info>
        </div>
        <table class="table" *ngIf="!loading && !error.active">
          <thead>
            <tr class="header-list">
              <th scope="col">
                <div class="text" (click)="sortList('nome_beneficiario')">
                  Nome
                  <span class="icon">
                    <img src="/assets/svg/sort-list.svg" alt="sort-list"/>
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="text" (click)="sortList('num_cpf_cnpj')">
                  CNPJ/CPF
                  <span class="icon">
                    <img src="/assets/svg/sort-list.svg" />
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="text" (click)="sortList('vlr_titulo')">
                  Valor
                  <span class="icon">
                    <img src="/assets/svg/sort-list.svg" />
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="text" (click)="sortList('dt_vencimento')">
                  Data de vencimento
                  <span class="icon">
                    <img src="/assets/svg/sort-list.svg" />
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="text" (click)="sortList('desc_status')">
                  Status
                  <span class="icon">
                    <img src="/assets/svg/sort-list.svg" />
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody style="display: flex;
          flex-direction: column;">
            <tr *ngFor="let item of dataList; index as i">
              <div class="panel-group" id="accordion">
                <div
                  class="panel-list"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  [attr.href]="'#collapse' + i"
                >
                  <td scope="row">
                    <label data-hj-suppress class="label-list list-name">{{item.nome_beneficiario | titlecase}}</label>
                  </td>
                  <td scope="row">
                    <label class="label-list">{{item.num_cpf_cnpj | cpfcnpj}}</label>
                  </td>
                  <td scope="row">
                    <label class="label-list">R$ {{item.vlr_pagamento | number: '1.2-2'}}</label>
                  </td>
                  <td scope="row">
                    <label class="label-list">{{item.dt_vencimento}}</label>
                  </td>
                  <td class="lastTd">
                    <div class="tooltipView" 
                      *ngIf="
                        (item.paymentReturn.desc_etapa_pagamento !== 'AprovadoSucesso' || 
                          (item.paymentReturn.desc_etapa_pagamento === 'AprovadoSucesso' && item.isPayment === false) || (item.paymentReturn.desc_etapa_pagamento === 'AgendadamentoPrevio')
                        ) && item.showTooltip"
                    >
                      <div class="tooltipContent">

                        <div *ngIf="item.paymentReturn.desc_etapa_pagamento === 'AprovadoSucesso' || item.paymentReturn.desc_etapa_pagamento === 'AgendadamentoPrevio'" class="tooltipTexts">
                          <span class="tooltipTitle">Pagamento agendado.</span>
                          <span class="tooltipText">O pagamento será realizado na data agendada, mediante disponibilidade de saldo em conta.</span>
                        </div>
                        <div 
                          *ngIf="
                            item.paymentReturn.desc_etapa_pagamento !== 'AprovadoSucesso' && 
                            item.paymentReturn.desc_etapa_pagamento !== 'EnviadoAprovacaoSemSaldo' && 
                            item.paymentReturn.desc_etapa_pagamento !== 'EnviadoAprovacaoSemAlcada' &&
                            item.paymentReturn.desc_etapa_pagamento !== 'AgendadamentoPrevio'" 
                          class="tooltipTexts"
                        >
                          <span class="tooltipTitleError">Pagamento recusado.</span>
                          <span class="tooltipTextError">Erro no processamento.</span>
                        </div>
                        <div 
                          *ngIf="item.paymentReturn.desc_etapa_pagamento === 'EnviadoAprovacaoSemSaldo'" 
                          class="tooltipTexts"
                        >
                          <span class="tooltipTitle">Pagamento pendente.</span>
                          <span class="tooltipText">Aguardando saldo disponível.</span>
                        </div>
                        <div *ngIf="item.paymentReturn.desc_etapa_pagamento === 'EnviadoAprovacaoSemAlcada'" class="tooltipTexts">
                          <span class="tooltipTitle">Aguardando aprovação</span>
                          <div class="row" *ngIf="item.paymentReturn.aprovadores && item.paymentReturn.aprovadores.length > 0">
                            <div class="tooltipMasters" *ngIf="item.paymentReturn.masters && item.paymentReturn.masters.length > 0">
                              <div class="tooltipRow">
                                <div class="tooltipCircle"></div>
                                <div class="tooltipLine"></div>
                                <span class="tooltipText">{{item.paymentReturn.masters.length > 1 ? 'Masters' : 'Master' }}</span>
                              </div>
                              <div class="tooltipNames" *ngFor="let masters of item.paymentReturn.masters">
                                <span class="tooltipTextGray data-hj-suppress">{{masters.nome_pessoa}}</span>
                              </div>
                            </div> 
                          </div>
                          <div class="row">
                            <div class="tooltipMasters">
                              <div class="tooltipRow">
                                <div class="tooltipCircle"></div>
                                <div class="tooltipLine"></div>
                                <span class="tooltipText">{{item.paymentReturn.operators.length > 1 ? 'Operadores' : 'Operador' }}</span>
                              </div>
                              <div class="tooltipNames" *ngFor="let operators of item.paymentReturn.operators">
                                <span class="tooltipTextGray data-hj-suppress">{{operators.nome_pessoa}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="arrowView">
                        <div class="arrowDown"></div>
                      </div>
                    </div>
                    <div class="row statusRow">
                      <div class="row statusSecondRow">
                        <div 
                          *ngIf="item.paymentReturn.desc_etapa_pagamento !== 'AprovadoSucesso'
                            && item.paymentReturn.desc_etapa_pagamento !== 'EnviadoAprovacaoSemSaldo' 
                            && item.paymentReturn.desc_etapa_pagamento !== 'EnviadoAprovacaoSemAlcada' 
                            && item.paymentReturn.desc_etapa_pagamento !== 'AgendadamentoPrevio'" 
                          class="infoIconError" 
                          (mouseenter)="hoverTooltip(i, true)" 
                          (mouseleave)="hoverTooltip(i, false)"
                        >
                          <span>i</span>
                        </div>
                        <div 
                          *ngIf="
                            (item.paymentReturn.desc_etapa_pagamento !== 'AprovadoSucesso' && item.paymentReturn.desc_etapa_pagamento !== 'AgendadamentoPrevio') && (
                            item.paymentReturn.desc_etapa_pagamento === 'EnviadoAprovacaoSemSaldo' || 
                            item.paymentReturn.desc_etapa_pagamento === 'EnviadoAprovacaoSemAlcada')"  
                          class="infoIcon" 
                          (mouseenter)="hoverTooltip(i, true)" 
                          (mouseleave)="hoverTooltip(i, false)"
                        >
                          <span>i</span>
                        </div>
                        <div *ngIf="item.paymentReturn.desc_etapa_pagamento === 'AprovadoSucesso' && item.isPayment" class="greenCircle" (mouseenter)="hoverTooltip(i, true)" (mouseleave)="hoverTooltip(i, false)"></div>
                        <div *ngIf="(item.paymentReturn.desc_etapa_pagamento === 'AprovadoSucesso' && !item.isPayment) || item.paymentReturn.desc_etapa_pagamento === 'AgendadamentoPrevio'" class="yellowCircle" (mouseenter)="hoverTooltip(i, true)" (mouseleave)="hoverTooltip(i, false)"></div>
                        <label class="label-list">{{
                          item.paymentReturn.desc_etapa_pagamento === 'AprovadoSucesso' && item.isPayment ? 'Liquidado' :
                          (item.paymentReturn.desc_etapa_pagamento === 'AprovadoSucesso' && !item.isPayment) || item.paymentReturn.desc_etapa_pagamento === 'AgendadamentoPrevio' ? 'Agendado' :
                          item.paymentReturn.desc_etapa_pagamento === 'EnviadoAprovacaoSemSaldo' || item.paymentReturn.desc_etapa_pagamento === 'EnviadoAprovacaoSemAlcada' ? 'Pendente' :
                          'Recusado'
                        }}</label>
                      </div>
                      <span class="item-icon">
                        <img
                          id={{i}}
                          src="/assets/svg/arrow_down.svg"
                          class=""
                          alt="Seta para dropdown"
                        />
                      </span>
                    </div>
                  </td>
                </div>
                <div [id]="'collapse' + i" class="panel-collapse collapse in">
                  <fibra-payment-revision-detail [item]="item"></fibra-payment-revision-detail>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Bottom button -->
      <div class="footerList">
        <div class="footerListButton" (click)="onNav('/payment')">
          <span>Novo pagamento de boleto</span>
        </div>
      </div>
    </div>
    
  </div>
</div>
